import { gql } from "@apollo/client";

export const meetingParkingLot = gql`
  query meetindParkingLot($meetingID: Int!) {
    meetingById(id: $meetingID) {
      id
      meetingParkingLotsByMeetingId {
        nodes {
          meetingId
          meetingTopicId
          topic
        }
      }
    }
  }
`;
