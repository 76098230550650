import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Editor,
  Element as SlateElement,
  Transforms,
  createEditor,
} from "slate";
import { Editable, ReactEditor, Slate, useSlate, withReact } from "slate-react";

// import { withHistory } from "slate-history";

import { CustomCheckbox } from "components/CustomCheckbox/CustomCheckbox";
import { Button, Icon, Toolbar } from "./components";

import { DndContext, DragOverlay } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import CustomButton from "components/CustomButtons/Button.js";
import { createPortal } from "react-dom";

// data
import { withNodeId } from "./withNodeId";
// errors
import { LogErrorComponent, useDataForLog } from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

import { ContentContext } from "contexts/ContentContext";

// import _ from "lodash";
import isEqual from "lodash/isEqual";
// icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// style
import { defaultFont, grayColor } from "assets/jss/material-dashboard-react";

import { makeStyles } from "@material-ui/core/styles";
const styles = {
  layout: {
    position: "relative",
    height: "100%",
  },

  content: {
    position: "absolute",
    top: "50px",
    left: "0",
    bottom: "0",
    right: "0px",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarGutter: "stable",
  },
  // layout: {
  //   //------------------------------
  //   display: "flex",
  //   height: "100%",
  //   flexDirection: "column",
  //   // grid-auto-rows: auto 1fr;
  //   // grid-template-columns: repeat(12, 1fr);
  // },

  // content: {
  //   // gridColumn: 4 / 13,
  //   // backgroundColor: "yellow",
  //   /*   since the parent is scrollable on overflow the child can be overflowed as well */
  //   // overflowY: "auto",
  //   minHeight: "0px",
  //   // flexDirection: "row",
  // },

  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  checkbox: {
    paddingRight: "5px",
  },
  dragOverlay: {
    display: "flex",
    alignItems: "center",
  },
  h1: {
    margin: "0px",
  },
  h2: {
    margin: "0px",
  },
  handleIcon: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    // flex: 1,
    color: grayColor[4],
    userSelect: "none",
    // backgroundColor: "orange",
    // backgroundColor: grayColor[8],
    // background: "none",
    border: "none",
    fontSize: "15px",
    display: "inline-flex",
    justifyContent: "center",
    padding: "0px 7px",
    width: "15px",
    cursor: "grab",

    // marginBottom: "5px",
  },
  mainContainer: {
    padding: "10px",
    borderRadius: "5px",
    border: `1px solid ${grayColor[5]}`,
    // maxWidth: "900px",
    height: "100%",
    backgroundColor: "white",
  },
  placeholder: {
    "&:before": {
      content: '"Start typing..."',
      position: "absolute",
      fontWeight: 400,
      color: grayColor[5],
      top: 2,
      left: 20,
      margin: 0,
      cursor: "text",
      width: "100%",
    },
  },
  paragraph: {
    margin: "0px",
  },

  sortable: {
    // padding: "10px 0",
    // backgroundColor: "orange",
    display: "flex",
    transform: "translate3d(0, var(--translate-y, 0), 0)",
    zIndex: "0",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "30px",
    flex: 1,
    // marginRight: "30px",
    // width: "calc(100% - 35px)",
    // backgroundColor: "yellow",
  },
  sortableSelected: {
    backgroundColor: grayColor[9],
  },

  sortableSubContainer: {
    // backgroundColor: "orange",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
  },
  sortableElement: {
    flex: 1,
    paddingLeft: "5px",
    // display: "flex",
    minWidth: "10px",
    // alignItems: "center",
    // padding: "0px 0",
    // height: "80px",
  },

  title: {
    ...defaultFont,
    fontSize: "14px",
    fontWeight: 500,
    paddingLeft: "10px",
    color: grayColor[0],
    marginTop: ".2rem",
  },
  toolbarDisabled: {
    backgroundColor: grayColor[7],
    pointerEvents: "none",
    borderRadius: "5px",
  },
};

const useStyles = makeStyles(styles);

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
const toPx = (value) => (value ? `${Math.round(value)}px` : undefined);

const CustomRichTextEditorMain = forwardRef((props, ref) => {
  // console.log(
  //   "%cStart custom rich text editor",
  //   "background-color: yellow; color: red",
  //   props
  // );

  try {
    const {
      content,
      disabled = false,
      insertOnTop,
      preventTriggerChangeRef,
      sendInfoToParent,
      title,
    } = props;
    // console.log(
    //   "%ccontent en rich text",
    //   "background-color: yellow; color: red",
    //   content
    // );

    const { content: contentState } = useContext(ContentContext);
    const { isSavingRef } = contentState;

    const initialValue = content || [
      {
        id: 1,
        type: "paragraph",
        children: [{ text: "" }],
      },
    ];

    const currentDataRef = useRef(null);
    const tempRef = useRef();

    const classes = useStyles();
    // console.log("content", content);

    // console.log("initialvalue", { content, initialValue });

    const SortableElement = ({
      attributes,
      element,
      children,
      elementToRender,
      level,
    }) => {
      // const classes = useStyles();
      const sortable = useSortable({ id: element.id });

      const [isHovering, setIsHovering] = useState(false);

      const handleMouseOver = () => {
        setIsHovering(true);
      };

      const handleMouseOut = () => {
        setIsHovering(false);
      };

      // console.log("isHovered");
      const lineCount = editor.children.length;
      // console.log("el line count", lineCount);
      const lastLine = editor.children[lineCount - 1];
      // console.log("lastLine", lastLine);
      const lastLineContent = lastLine.children[0].text;
      // const currentRow = ReactEditor.findPath(editor, element)[0] + 1;

      // detect if placeholder
      const currentRow = ReactEditor.findPath(editor, element)[0];
      const currentLine = editor.children[currentRow];
      const isPlaceholder = currentLine?.id === lastLine?.id;
      // console.log("info del la linea", {
      //   editor: editor.children,
      //   lastLine,
      //   lastLineContent,
      //   lineCount,
      //   currentRow,
      //   currentLine,
      //   isPlaceholder,
      // });

      return (
        <div
          {...attributes}
          style={{
            // backgroundColor: "yellow",
            display: "flex",
            flexDirection: "row",
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div
            className={`${classes.sortable} ${
              isHovering && !disabled && classes.sortableSelected
            }`} // "sortable"
            {...sortable.attributes}
            ref={sortable.setNodeRef}
            style={{
              transition: sortable.transition,
              "--translate-y": toPx(sortable.transform?.y),
              pointerEvents: sortable.isSorting ? "none" : undefined,
              opacity: sortable.isDragging ? 0 : 1,
            }}
          >
            <div className={classes.sortableSubContainer}>
              {!disabled && (
                <div
                  style={{
                    // width: "25px",
                    // backgroundColor: "yellow",
                    userSelect: "none",
                    height: "100%",
                  }}
                  contentEditable={false}
                >
                  {!isPlaceholder && (
                    <div
                      disabled={disabled}
                      className={classes.handleIcon}
                      {...(lineCount > 2 ? sortable.listeners : {})}
                    >
                      {isHovering &&
                        lineCount > 2 &&
                        currentRow !== lineCount &&
                        children[0].props.decorations[0]?.placeholder ===
                          undefined && <>⠿</>}
                    </div>
                  )}
                </div>
              )}

              <div className={classes.sortableElement}>
                {/* <h6 style={{ color: "red" }}>{element.id}</h6> */}
                {elementToRender({ element, children })}
              </div>

              <div
                style={{
                  userSelect: "none",
                  height: "100%",
                  // backgroundColor: "blue",
                  display: "flex",
                  alignItems: "center",
                  width: "30px",
                  // padding: "0px 5px",
                }}
                contentEditable={false}
              >
                {isHovering && !isPlaceholder && !disabled && (
                  <CustomButton
                    color="danger"
                    justIcon
                    size="sm"
                    onClick={() => {
                      const path = ReactEditor.findPath(editor, element);
                      const lineCount = editor.children.length;
                      console.log(
                        "%clineCount",
                        "background-color: orange; color: white",
                        { lineCount, path, element }
                      );

                      if (lineCount === 1) {
                        // Empty last line counts as 1
                        // if it's the first line, only remove the description
                        Transforms.insertText(editor, "", {
                          at: [0, 0],
                        });
                      } else {
                        // deletes node
                        Transforms.removeNodes(editor, { at: path });
                      }
                    }}
                  >
                    <DeleteOutlineIcon />
                  </CustomButton>
                )}
              </div>
            </div>
            {/* {isHovering && !hideDeleteIcon && !disabled && (
              <div
                style={{
                  userSelect: "none",
                  height: "100%",
                  backgroundColor: "blue",
                }}
                contentEditable={false}
              >
                <CustomButton
                  color="danger"
                  justIcon
                  size="sm"
                  onClick={() => {
                    const path = ReactEditor.findPath(editor, element);
                    const lineCount = editor.children.length;
                    console.log(
                      "%clineCount",
                      "background-color: orange; color: white",
                      { lineCount, path, element }
                    );

                    if (lineCount === 1) {
                      // Empty last line counts as 1
                      // if it's the first line, only remove the description
                      Transforms.insertText(editor, "", {
                        at: [0, 0],
                      });
                    } else {
                      // deletes node
                      Transforms.removeNodes(editor, { at: path });
                    }
                  }}
                >
                  <DeleteOutlineIcon />
                </CustomButton>
              </div>
            )} */}
          </div>
        </div>
      );
    };

    const renderElementContent = (props) => {
      return <Element {...props} disabled={disabled} />;
    };

    const renderElement = useCallback((props) => {
      // return renderElementContent(props);
      const isTopLevel =
        ReactEditor.findPath(editor, props.element).length === 1;

      return isTopLevel ? (
        <SortableElement {...props} elementToRender={renderElementContent} />
      ) : (
        renderElementContent(props)
      );
    }, []);

    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

    //   const editor = useMemo(() => withReact(createEditor()));
    const useEditor = () =>
      useMemo(() => withNodeId(withReact(createEditor())), []);
    const editor = useEditor();

    // const { selection } = editor;
    // if (selection) {
    //   console.log(
    //     "%cSelection",
    //     "background-color: purple; color: white",
    //     selection
    //   );

    //   const { anchor, focus } = selection;
    //   if (!Node.has(editor, anchor.path) || !Node.has(editor, focus.path)) {
    //     Transforms.deselect(editor);
    //   }
    // }

    useEffect(() => {
      // use to store the current data to be able to detect changes
      // between renders and define when handleChange should execute or not
      // if content is undefined, it means the render is starting and nothing
      // should be stored in currentDataRef.  Therefore, handleChange won't detect this
      // as "change" and will not run
      // console.log(
      //   "%cMounting rich text editor",
      //   "background-color: red; color: white"
      // );

      if (content !== undefined) {
        currentDataRef.current = initialValue;
      }

      // return () => {
      //   console.log(
      //     "%cUN Mounting rich text editor",
      //     "background-color: red; color: white"
      //   );
      // };
    }, []);

    useEffect(() => {
      // loads the information with content changes
      Transforms.deselect(editor); // removes any previous selection that may been, mostly when switching between topis and have a previous selection, add or deleted item
      editor.spellCheck = false;
      editor.children = initialValue;
      editor.onChange();
      // the onChange is triggered, but the filtered set_selection change will not record this as a change,
      // so the data needs to be saved here
      const contentJSON = JSON.stringify(content);
      localStorage.setItem("content", contentJSON);
    }, [content]);

    useEffect(() => {
      // avoid running when initial value is null
      if (insertOnTop !== null && insertOnTop !== undefined) {
        // editor.children = newContent;
        // editor.onChange();

        Transforms.insertNodes(editor, insertOnTop, { at: [0] });
      }
    }, [insertOnTop]);

    const handleChange = (value1) => {
      // prevent executing handle change is this would cause an unnecesary save to db
      if (preventTriggerChangeRef && preventTriggerChangeRef.current === true) {
        console.log(
          "%cPrevented execute handle change in CustomRichTextEditor to avoid unnecesary db saving",
          "background-color: red; color: white"
          // isSavingRef
        );
        preventTriggerChangeRef.current = false;
        isSavingRef.current = false;
        // currentDataRef.current = value1;
        return;
      }

      console.log(
        "%cStarts handle change",
        "background-color: blue; color: white",
        {
          editorChildren: editor.children,
          currentDataRef: currentDataRef.current,
          isSavingRef: isSavingRef?.current,
        }
      );

      const { selection } = editor;
      // console.log("selection", selection);

      // adds or remove a blank line at the end if required
      const lineCount = editor.children?.length;
      // console.log("el line count es", lineCount);
      let lastLine;
      let lastLineContent;
      let penultimateLine;
      let penultimateContent;

      const addLine = () => {
        Transforms.insertNodes(
          editor,
          { type: "paragraph", children: [{ text: "" }] },
          { at: [editor.children.length] }
        );
      };

      const removeLine = () => {
        Transforms.removeNodes(editor, { at: [editor.children.length - 1] });
      };

      const isChanging =
        currentDataRef.current !== null &&
        !isEqual(value1, currentDataRef.current);

      // console.log("***** editor changed", {
      //   // currentDataRef: currentDataRef.current[0]?.children[0].text,
      //   // value: value1[0]?.children[0].text,
      //   currentDataRef: currentDataRef.current,
      //   value: value1,
      //   isEqual: !isEqual(value1, currentDataRef.current),
      //   isChanging,
      // });

      // const emptyLine =
      //   currentDataRef.current[0]?.children[0].text.length === 0 &&
      //   value1[0]?.children[0].text.length === 0;
      // console.log('%cdata', 'background-color: purple; color: white', {lineCount, })

      if (isChanging) {
        // keeps an empty line at the bottom
        switch (lineCount) {
          case 0:
            console.log(
              "%cLine count is 0 .  Dont do anything",
              "background-color: yellow; color: red"
            );

            // don't do anything
            break;

          case 1:
            lastLine = editor.children[lineCount - 1];
            // console.log("lastLine", lastLine);
            lastLineContent = lastLine.children[0].text.trim();
            // console.log("lastLineContent", lastLineContent);

            console.log(
              "%cLine count is 1",
              "background-color: yellow; color: red",
              {
                lineCount,
                lastLine,
                lastLineContent,
                children: editor.children,
              }
            );

            // if last line is not empty, adds a line
            if (lastLineContent !== "") {
              console.log("add line");

              addLine();
            }
            break;
          // penultimateLine = lastLine
          // penultimateContent = penultimateLine.children[0].text;

          default:
            lastLine = editor.children[lineCount - 1];
            lastLineContent = lastLine.children[0].text.trim();
            penultimateLine = editor.children[lineCount - 2];
            penultimateContent = penultimateLine.children[0].text.trim();

            console.log(
              "%cLine count is more than  1",
              "background-color: yellow; color: red",
              {
                lineCount,
                lastLine,
                lastLineContent,
                penultimateLine,
                penultimateContent,
                children: editor.children,
              }
            );

            if (penultimateContent === "" && lastLineContent === "") {
              console.log(
                "%ctwo last lines are empty, delete last line",
                "background-color: orange; color: white"
              );

              removeLine();
            }

            if (penultimateContent !== "" && lastLineContent === "") {
              // console.log("don't do anything");
            }
            if (lastLineContent !== "") {
              console.log(
                "%clast line is not empty, adds line",
                "background-color: orange; color: white"
              );

              addLine();
            }
        }

        // detect if there's no information
        const contentJSON =
          editor.children.length === 1 &&
          editor.children[0].children[0].text.trim() === ""
            ? null
            : editor.children;
        localStorage.setItem("content", contentJSON);
        // console.log("content to be saved", {
        //   value: value1,
        //   children: editor.children,
        // });

        // console.log("sendInfoToParent in richTextEditor", contentJSON);
        sendInfoToParent(contentJSON, editor);
      } else {
        // console.log("***************************** DON'T update db");
        isSavingRef.current = false;
      }
      // remove the value from the initial rendering
      currentDataRef.current = value1;
    };

    // ---------------------------------------- DnD ---------------------------
    const [activeId, setActiveId] = useState(null);

    const handleDragStart = (event) => {
      // disable dragging for placeholder
      // const { active } = event;
      // console.log("---------------active------------", active);
      // const placeholderID = active.id;
      // const array = active.data.current.sortable.items;
      // const lastItemID = array[array.length - 1];
      // console.log("data", { array, placeholderID, lastItemID });

      // if (placeholderID === lastItemID) {
      //   console.log("event", event);
      //   // event.preventDefault(); // Prevent dragging the placeholder
      //   return;
      // }

      if (event.active) {
        clearSelection();
        setActiveId(event.active.id);
      }
    };

    const handleDragEnd = (event) => {
      console.log("handleDragEnd", event);
      const overId = event.over?.id;
      const overIndex = editor.children.findIndex((x) => x.id === overId);
      console.log("length", editor.children.length);
      console.log("overIndex", overIndex);

      if (
        overId !== activeId &&
        overIndex !== -1 &&
        // item moved is not replacing the last line "Start typing"
        editor.children.length !== overIndex + 1
      ) {
        Transforms.moveNodes(editor, {
          at: [],
          match: (node) => node.id === activeId,
          to: [overIndex],
        });
      }

      setActiveId(null);
    };

    const handleDragCancel = () => {
      setActiveId(null);
    };

    const clearSelection = () => {
      ReactEditor.blur(editor);
      Transforms.deselect(editor);
      window.getSelection()?.empty();
    };

    const items = useMemo(
      () => editor.children.map((element) => element.id),
      [editor.children]
    );

    const DragOverlayContent = ({ element }) => {
      // renders what happens when dragging, but not yet released
      // const classes = useStyles();
      const editor = useEditor();
      const [value] = useState([JSON.parse(JSON.stringify(element))]); // clone
      console.log(
        "%cvalue in DragOverlayContent",
        "background-color: yellow; color: red",
        value
      );

      return (
        <div className={classes.dragOverlay}>
          <button className={classes.handleIcon}>⠿</button>
          {/* <Slate editor={editor} value={value}> */}
          <Slate editor={editor} initialValue={value}>
            <Editable renderElement={renderElementContent} />
          </Slate>
        </div>
      );
    };

    const activeElement = editor.children.find((x) => x.id === activeId);

    // ------------------ end dnd ----------------------------------------------------------

    const titleLabel = () => (
      <div className={classes.descriptionContainer}>
        <div className={classes.labelGroup}>
          {title && <p className={classes.title}>{title}</p>}
        </div>
      </div>
    );

    let lastChange = null;

    const MainToolbar = () => {
      return (
        <Toolbar className={disabled && classes.toolbarDisabled}>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          {/* <MarkButton format="code" icon="code" /> */}
          <BlockButton format="checkbox" icon="check_box" />

          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          {/* <BlockButton format="left" icon="format_align_left" />
      <BlockButton format="center" icon="format_align_center" />
      <BlockButton format="right" icon="format_align_right" />
      <BlockButton format="justify" icon="format_align_justify" /> */}
        </Toolbar>
      );
    };

    // Function to move selection to a specific position
    // const moveSelectionToPosition = (path, offset) => {
    //   const { selection } = editor;
    //   console.log("selection", selection);
    //   // Check if selection is available
    //   if (selection) {
    //     // Move the selection to the specified path and offset
    //     const newSelection = {
    //       ...selection,
    //       anchor: { path, offset },
    //       focus: { path, offset },
    //     };

    //     Transforms.select(editor, newSelection);
    //   }
    // };

    // Example function to move selection to the start of the document
    // const focusToStart = () => {
    //   const path = [0, 0]; // Example path
    //   const offset = 0; // Example offset
    //   moveSelectionToPosition(path, offset);
    // };

    return (
      <>
        {title && titleLabel()}

        <div className={classes.mainContainer}>
          {/* <pre>
            {JSON.stringify(
              {
                editorChildren: editor.children,
                // tempContentRef: tempContentRef.current,
              },
              null,
              2
            )}
          </pre> */}
          <Slate
            editor={editor}
            initialValue={initialValue}
            // value={initialValue}
            // onChange={() => console.log("probando onChange")}
            onChange={content !== undefined && handleChange}
          >
            <div
              className={classes.layout}
              ref={ref}
              tabIndex={0} // allows div to be focused
            >
              <MainToolbar />

              <div className={classes.content}>
                <DndContext
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  onDragCancel={handleDragCancel}
                >
                  <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                  >
                    <Editable
                      style={{
                        backgroundColor: disabled ? grayColor[7] : "white",
                        // backgroundColor: "yellow",
                        height: "100%",
                        width: "100%",
                        borderRadius: "5px",
                        margin: "0px",
                        padding: "0px",
                      }}
                      readOnly={disabled}
                      renderElement={renderElement}
                      renderLeaf={renderLeaf}
                      placeholder={disabled ? "" : "Start typing..."}
                      renderPlaceholder={({ children, attributes }) => (
                        //  fix cursor not showing when using flex in container and showing placeholder
                        <>
                          <div
                            {...attributes}
                            style={{
                              opacity: 0,
                              height: 0,
                              position: "relative",
                              zIndex: -1,
                            }}
                          >
                            {children}
                          </div>
                          <div {...attributes}>{children}</div>
                        </>
                      )}
                      spellCheck
                      onKeyDown={(event) => {
                        console.log("key pressed", event.key);
                        isSavingRef.current = true;

                        if (event.key === "Enter") {
                          // Prevent the ampersand character from being inserted.
                          event.preventDefault();

                          console.log("se presionó enter");

                          Transforms.insertNodes(
                            editor,
                            { type: "paragraph", children: [{ text: "" }] }
                            // { at: [editor.children.length] }
                          );

                          // Transforms.select(editor, Editor.end(editor, []));
                          // const lastNode =
                          //   editor.children[editor.children.length - 1];
                          // const lastNodePath = ReactEditor.findPath(
                          //   editor,
                          //   lastNode
                          // );
                          // const endPoint = Editor.end(editor, lastNodePath);

                          // Transforms.select(editor, endPoint);
                        }
                      }}
                    />
                  </SortableContext>
                  {createPortal(
                    <DragOverlay adjustScale={false}>
                      {activeElement && (
                        <DragOverlayContent element={activeElement} />
                      )}
                    </DragOverlay>,
                    document.body
                  )}
                </DndContext>
              </div>
            </div>
          </Slate>
        </div>
      </>
    );
  } catch (error) {
    console.error("Error in rich text edito:", error);
  }
});

export const CustomRichTextEditor = forwardRef((props, ref) => {
  const generalData = useDataForLog(); // context data to populate error log
  return (
    <ErrorBoundary
      FallbackComponent={(error) => (
        <LogErrorComponent
          error={error}
          size="large"
          data={{
            errorLevel: "fatal",
            generalData: { ...generalData },
            otherData: { props },
          }}
        />
      )}
    >
      <CustomRichTextEditorMain ref={ref} {...props} />
    </ErrorBoundary>
  );
});

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  console.log("starts toggle mark");
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  // console.log("starts is markActive");
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element, disabled }) => {
  const editor = useSlate();
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const classes = useStyles();

  // const isEmpty =
  //   editor.children[0].text === "" && editor.children.length === 1;
  // const isEmpty = editor.children[0].text === "";
  // console.log("text", element.children[0].text);

  // console.log("isHovered");
  const lineCount = editor.children.length;
  // console.log("el line count", lineCount);
  const lastLineID = editor.children[lineCount - 1].id;
  const currentLineID = element.id;

  // console.log("children", {
  //   children,
  //   element,
  //   editor,
  //   lastLineID,
  //   currentLineID,
  // });
  const isEmpty = element.children[0].text.trim() === "";

  // console.log("isEmpty", isEmpty);

  const style = { textAlign: element.align };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h3 className={classes.h1} {...attributes}>
          {children}
        </h3>
      );
    case "heading-two":
      return (
        <h4 className={classes.h2} {...attributes}>
          {children}
        </h4>
      );
    case "checkbox":
      return (
        <div className={classes.checkboxContainer}>
          <div className={classes.checkbox}>
            <CustomCheckbox
              value={checkBoxValue}
              setValue={() => setCheckBoxValue(!checkBoxValue)}
            />
          </div>
          <p className={classes.paragraph} {...attributes}>
            {children}
          </p>
        </div>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      // return (
      //   <p className={classes.paragraph} {...attributes}>
      //     {children}
      //   </p>
      // );
      return (
        <div
          className={
            // show "start typing" if not disabled, if last line, if empty and more than one line
            !disabled &&
            lastLineID === currentLineID &&
            isEmpty &&
            lineCount > 1
              ? classes.placeholder
              : classes.paragraph
          }
          {...attributes}
        >
          {children}
        </div>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  // console.log("starts leaf");
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

// const initialValue = [
