import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
// import Button from "@material-ui/core/Button";
import Button from "components/CustomButtons/Button.js";
import PaymentInvoice from "./PaymentInvoice";
import PaymentCard from "./PaymentCard";
import PaymentSubscription from "./PaymentSubscription";
import styles from "assets/jss/material-dashboard-react/views/paymentStyle";
import axios from "axios";
import config from "../../config";
// import { ReactComponent as BeeluaLogo } from "../../assets/img/Beelua.svg";
import Dialog from "components/CustomDialog/CustomDialog";
// data
import { Formik } from "formik";
import { SessionContext } from "../../contexts/SessionContext";
import { axiosOptions } from "functions/Common";

const useStyles = makeStyles(styles);
const steps = [PaymentSubscription, PaymentInvoice, PaymentCard];
// const steps = [PaymentForm, Review];
// const steps = [AddressForm];
// const steps = ["Facturación", "Pago", "Revisa"];

export default function Payment(props) {
  const { visibleStatus, setVisibleStatus, requiresRefetch } = props;
  const { session, setSession } = useContext(SessionContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [modal, setModal] = useState({
    visible: false,
    title: "",
    body: "",
  });
  const [formikValues, setFormikValues] = useState({
    cardExpiryMonth: 0,
    cardExpiryYear: 0,
    cardName: "",
    cardNumber: "",
    cardCVV: "",
    couponCode: "",
    currentSlots: 0,
    legalNumber: "",
    invoiceAddress1: "",
    invoiceAddress2: "",
    invoiceCity: "",
    invoiceCountryID: 0,
    invoiceName: "",
    invoicePobox: "",
    invoiceState: "",
    slots: 0,
    stageID: "",
    subscriptionPeriodID: 0,
    validUntil: "",

    subtotal: 0,
    discounts: 0,
    taxes: 0,
    total: 0,
  });

  // console.log("el token es", token);

  // const [subscriptionNewMut] = useMutation(subscriptionNew, {
  //   onCompleted: () => {
  //     console.log("new subscription created");
  //     window.location.href = "exp://192.168.0.14:19000/--/";
  //   },
  // });

  // useEffect(() => {
  //   setSession({ ...session, token: token });
  // }, [session.token]);

  useEffect(() => {
    console.log("inicia useEffect en Payment");
    // loads the initial info
    // const options = {
    //   url: `${config.url}/api/paymentInfo`,
    //   timeout: config.timeout,

    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     "Content-Type": "application/json",
    //   },

    //   method: "post",
    // };

    const options = axiosOptions({
      url: `${config.url}/api/paymentInfo`,
      method: "post",
    });

    console.log("las opciones en el use Effect son", options);
    axios(options)
      .then(
        // Successful fetch
        (response) => {
          if (response.status === 200) {
            console.log(
              "-------------Successful payment info retrieve------------- ",
              response.data.info
            );
            setFormikValues({
              ...response.data.info,
              subtotal: 0,
              discounts: 0,
              taxes: 0,
              total: 0,
              cardCVV: "",
              couponCode: "",
              slots: "", // response.data.info.currentSlots,
              validUntil:
                response.data.info.validUntil !== null
                  ? response.data.info.validUntil.split("T")[0]
                  : "", // remove time portion
            });
            // const values = {
            //   ...response.data.info,
            //   subtotal: 0,
            //   discounts: 0,
            //   taxes: 0,
            //   total: 0,
            //   cardCVV: "",
            //   couponCode: "",
            //   slots: "", // response.data.info.currentSlots,
            //   validUntil:
            //     response.data.info.validUntil !== null
            //       ? response.data.info.validUntil.split("T")[0]
            //       : "", //remove time portion
            // };
            // PaymentComponent(values);
            setLoading(false);
          } else {
            setLoading(false);
            // can't access
            navigate("/NotAuthorizedPayment");
          }
        }
      )
      // Unsuccessful fetch
      .catch((error) => {
        setLoading(false);
        console.log("error validación token", error);
        navigate("/NotAuthorizedPayment");
      });
  }, []);

  // if (isLoading) {
  //   return <div />;
  // }

  // const isLastStep = () => {
  //   return activeStep === steps.length - 1;
  // };
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmit = (values, formikBag) => {
    console.log("************** submits form");
    const { setSubmitting } = formikBag;
    // if (!isLastStep()) {
    //   setSubmitting(false);
    //   handleNext();
    //   return;
    // }

    // console.log("se sometio el onSubmit", values);
    // console.log(" values on submit", values);

    console.log("variables para mutation", {
      values,
    });

    const options = axiosOptions({
      url: `${config.url}/api/paymentCharge`,
      method: "post",
      data: {
        ...values,
      },
    });

    axios(options)
      .then((response) => {
        if (response.status === 200) {
          switch (response.data.code) {
            case 0:
              console.log("payment processed successfully");
              // successful charge
              setVisibleStatus({ ...visibleStatus, visible: false });
              requiresRefetch(true); // refetch screen in AccountSubscription

              // setModal({ visible: true, title: "Pago procesado exitosamente" });
              setSubmitting(false);

              break;
            case 1:
              // incorrect security code
              console.log("código de seguridad incorrecto");
              setModal({
                visible: true,
                title: "Transaction unsuccesful",
                body: "Check the CCV",
              });
              setSubmitting(false);
              break;
            case 2:
              // card declined
              // console.log("tarjeta denegada");
              setModal({
                visible: true,
                title: "Transaction unsuccesful",
                body: "Card was declined",
              });
              setSubmitting(false);
              break;
            case 3:
              // card expired
              console.log("tarjeta vencida");
              setModal({
                visible: true,
                title: "Transaction unsuccesful",
                body: "Card is expired",
              });
              setSubmitting(false);
              break;
            default:
              console.log("no se pudo procesar");
              setModal({
                visible: true,
                title: "Transaction unsuccesful",
                // body: "La tarjeta está vencida",
              });
              setSubmitting(false);
          }

          // console.log("data returned ", response);
        } else {
          // can't access
          history.push("/NotAuthorizedPayment");
        }
      })
      .catch((error) => {
        console.log("error al momento de pagar", error);
        // props.history.push("/NotAuthorizedPayment");
      });

    // subscriptionNewMut({
    //   variables: {
    //     accountID: accountID,
    //     slots: parseInt(slots),
    //     periodID: subscriptionPeriodID,
    //     invoiceAddress1: invoiceAddress1,
    //     invoiceAddress2: invoiceAddress2,
    //     invoiceCity: invoiceCity,
    //     invoiceCountryID: invoiceCountryID,
    //     invoiceName: invoiceName,
    //     invoicePobox: invoicePobox,
    //     invoiceState: invoiceState,
    //     legalNumber: legalNumber,
    //   },
    // });
  };

  const ActiveStep = steps[activeStep];
  const Buttons = ({ fprops }) => {
    return (
      <div className={classes.buttons}>
        <div style={{ marginRight: "10px" }}>
          <Button
            onClick={() =>
              setVisibleStatus({ ...visibleStatus, visible: false })
            }
            color="primary"
            simple
            // className={classes.button}
            disabled={fprops.isSubmitting}
          >
            Cancel
          </Button>
        </div>
        {activeStep !== 0 && (
          <div style={{ marginRight: "10px" }}>
            <Button
              onClick={handleBack}
              color="primary"
              // className={classes.button}
              disabled={fprops.isSubmitting}
              wired
            >
              Back
            </Button>
          </div>
        )}

        <Button
          // variant="contained"

          color="primary"
          isLoading={fprops.isSubmitting}
          onClick={() => {
            if (Object.keys(fprops.errors).length === 0) {
              if (activeStep === steps.length - 1) {
                fprops.handleSubmit();
              } else {
                handleNext();
              }
            }
          }}
          // className={classes.buttonAction}
        >
          {activeStep === steps.length - 1 ? "Purchase" : "Next"}
        </Button>
      </div>
    );
  };

  const validationSchema = ActiveStep.validationSchema;

  let title = "";

  switch (formikValues.stageID) {
    // case 1:
    //   title = "Cambiar suscripción actual";

    //   break;
    case 2:
    case 3:
    case 6:
    case 7:
      title = "New subscription";

      break;
    case 4:
      title = "Change current subscription";

      break;
    case 5:
      title = "Renew subscription";

      break;
    default:
      title = "";
  }
  console.log("los formikValues en payment son:", formikValues);
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <div className={classes.logo}>
            <h1>logo</h1>
          </div>
        </Toolbar>
      </AppBar> */}
      <main className={classes.layout}>
        {isLoading ? (
          <div />
        ) : (
          <>
            <Formik
              // initialValues={initialValues}
              initialValues={formikValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {(fprops) => {
                return (
                  <>
                    {/* <Typography component="h1" variant="h4" align="center">
              Checkout
            </Typography> */}

                    <h3 className={classes.mainTitle}>{title}</h3>

                    <Stepper
                      activeStep={activeStep}
                      className={classes.stepper}
                    >
                      {steps.map((step, index) => (
                        <Step key={index}>
                          <StepLabel>
                            <h6>{steps[index].label}</h6>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    <>
                      <ActiveStep
                        fprops={fprops}
                        disabled={fprops.isSubmitting}
                      />
                      <Buttons fprops={fprops} />

                      {/* <pre>
                    {JSON.stringify(fprops.values.subscriptionPeriodID)}
                  </pre> */}
                      {/* <pre>{JSON.stringify(fprops.values, null, 2)}</pre> */}

                      {/* <pre>{JSON.stringify(fprops.errors, null, 2)}</pre> */}

                      {/* {Object.keys(fprops.errors).length > 0 && (
                        <h6 className={classes.errorLabel}>
                          Review the errors in the form
                        </h6>
                      )} */}
                    </>
                  </>
                );
              }}
            </Formik>

            {/* <Copyright /> */}
            <Dialog
              center
              visibleStatus={modal}
              setVisibleStatus={setModal}
              title={modal.title}
              iconPreset="error"
              // buttonCloseLabel="OK"
              buttonActionLabel1="OK"
              // buttonActionIcon1={<ShoppingCartOutlinedIcon />}
              buttonActionFunction1={() =>
                setModal({ ...modal, visible: false })
              }
              // buttonActionLoading1={isLoading}
              maxWidth="sm"
            >
              <h6 className={classes.modalBody}>{modal.body}</h6>
            </Dialog>
          </>
        )}
      </main>
    </React.Fragment>
  );

  // }
}
