import React from "react";
// nodejs library to set properties for components
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

// components
import Medal from "../../assets/svgComponent/medal";

import styles from "assets/jss/material-dashboard-react/components/medalStyle.js";

const useStyles = makeStyles(styles);

export default function MedalWithIndicator(props) {
  const classes = useStyles();
  const { rating, indicator, variant, size } = props;

  return (
    <div className={classes.indicatorGroup}>
      <h3
        className={
          classes.indicatorNumber +
          " " +
          (parseFloat(rating) < 7 && classes.alert)
        }
      >
        {rating}
      </h3>
      <SvgIcon style={{ fontSize: size }}>
        <Medal variant={variant} content={rating ? "" : indicator} />
      </SvgIcon>
    </div>
  );
}
