import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Table from "components/Table/Table.js";
import CardBody from "components/Card/CardBody.js";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import ErrorHandler from "components/Error/Error";
// data
import { useQuery } from "@apollo/client";
import { invoiceList } from "graphql/gqlAccountInvoiceList";
import { SessionContext } from "../../contexts/SessionContext";
import { AccountContext } from "contexts/AccountContext";
// style

const styles = {};
const useStyles = makeStyles(styles);

export default function AccountInvoiceList() {
  console.log("starts AccountInvoiceList");
  // const companyID = parseInt(sessionStorage.getItem("companyID"), 10);
  const classes = useStyles();
  const navigate = useNavigate();
  const { session } = useContext(SessionContext);
  const { accountID, userID } = session;

  const { setModal } = useContext(AccountContext);

  // console.log("accountid", accountID);

  const { loading, error, data, refetch } = useQuery(invoiceList, {
    variables: { userID: userID },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const handleView = async (tableRow) => {
    const invoiceID = tableRow[0];
    setModal({ visible: true, name: "invoice", data: { invoiceID } });
  };

  if (loading) {
    return <ActivityIndicator fullscreen />;
  }

  if (error) {
    return <ErrorHandler error={error} btnActionFunction2={() => refetch()} />;
  }

  if (data && data.spInvoiceList) {
    console.log("data", data);
    // convert array of objects to array of arrays
    const dataAsArray = data.spInvoiceList.nodes.map(Object.values);
    // removes column typename included in graphql response
    const dataConverted = dataAsArray.map((prop) => {
      return prop.slice(1, 20);
    });

    return (
      <CardBody fullscreen className={classes.cardBody}>
        <Table
          tableHeaderColor="primary"
          tableHead={["ID", "Date", "Amount", "Actions"]}
          tableData={dataConverted}
          viewClick={(item) => handleView(item)}
          hideNoData

          // editClick={(item) => handleEdit(item)}
          // deleteClick={() => setModal({ visible: true })}
        />
      </CardBody>
    );
  }
}
