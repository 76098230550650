import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";

export const AccountContext = createContext();

export const AccountInfo = (props) => {
  const userQueryRef = useRef([]);

  const initialState = {
    slotsPlan: null,
    slotsFree: null,
    slotsUsed: null, // Calculated field
  };

  const [account, setAccountNotMemo] = useState(initialState);
  const [modal, setModal] = useState({
    visible: false,
    name: "",
    data: {},
  });
  const setAccount = useCallback((newValue) => {
    setAccountNotMemo((prevAccount) => ({
      ...prevAccount,
      ...newValue,
    }));
  }, []);

  useEffect(() => {
    setAccountNotMemo((prevAccount) => ({
      ...prevAccount,
      slotsUsed: prevAccount.slotsPlan - prevAccount.slotsFree,
    }));
  }, [account.slotsPlan, account.slotsFree]);

  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    messageType: "",
    message: "",
  });

  const memoizedValues = useMemo(
    () => ({
      account,
      setAccount,
      showSnackbar,
      setShowSnackbar,
    }),
    [account, setAccount, , showSnackbar, setShowSnackbar]
  );

  return (
    <AccountContext.Provider
      value={{
        ...memoizedValues,
        modal,
        setModal,
        userQueryRef,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
export default AccountInfo;
