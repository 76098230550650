// import classes from "*.module.css";
import React, { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  successColor,
  dangerColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";
// data
import axios from "axios";

// import config from "config";
import { object, string } from "yup";
import { Formik } from "formik";
// custom components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import CustomTooltip from "./CustomTooltip/CustomTooltip";
// icons
import PublishIcon from "@material-ui/icons/Publish";
import CloseIcon from "@material-ui/icons/Close";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const styles = {
  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .12s ease-in-out",
  },
  activeStyle: {
    borderColor: "#2196f3",
  },
  acceptedTitle: {
    marginTop: 10,
    color: successColor[0],
    margin: 0,
    fontWeight: 500,
  },
  acceptedItems: {
    color: successColor[0],
    margin: 0,
    fontWeight: 400,
  },
  buttonFooter: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: "7px",
    flexDirection: "column",
  },
  errorLabel: {
    color: "red",
    lineHeight: "20px",
    textAlign: "center",
  },

  formatText: {
    color: grayColor[5],
    textAlign: "center",
    margin: 0,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.2,
  },
  mainText: {
    marginTop: "10px",
    color: grayColor[2],
    textAlign: "center",
  },
  plusSign: {
    fontSize: "40px",
    margin: "0px",
    lineHeight: "30px",
  },

  rejectedTitle: {
    marginTop: 10,
    color: dangerColor[0],
    margin: 0,
    fontWeight: 500,
  },
  rejectedMessage: {
    color: dangerColor[0],
    margin: 0,
    fontWeight: 400,
  },
  subText: {
    // marginTop: "10px",
    color: grayColor[3],
    textAlign: "center",
    margin: "0px 0px 10px 0px",
    lineHeight: "15px",
  },
};

const useStyles = makeStyles(styles);

export default function Dropzone(props) {
  const {
    maxFiles = 0,
    maxFileSizeMb = 1,
    sendInfoToParent,
    thumbToRemove = {},
    customTriggerComponent,
  } = props;
  const [dropzoneUpload, setDropzoneUpload] = useState(false);
  const [selectedFilesState, setSelectedFilesState] = useState([]);

  const classes = useStyles();

  // this useEffect detects a change in thumbToRemove which comes from
  // the Thumb component on close event
  useEffect(() => {
    if (thumbToRemove?.index !== null) {
      //   console.log("close", thumbToRemove);

      const newArray = [...selectedFilesState];
      newArray.splice(thumbToRemove.index, 1);
      // recalculates if the errors associated with too many files
      const newSelectedFiles = updateMaxFilesError(newArray);
      //   console.log("newArray", newArray);
      setSelectedFilesState(newSelectedFiles);
    }
  }, [thumbToRemove]);

  // updates the selected files array to reflect the files exceeded
  const updateMaxFilesError = (allSelectedFiles) => {
    // console.log(
    //   "%cselectedFiles in updateMaxFilesError",
    //   "background-color: red; color: white",
    //   allSelectedFiles
    // );

    let finalSelectedFiles = [];
    allSelectedFiles.map((item, index) => {
      let newErrors = item.errors || [];
      // remove too many files error if any was previously
      // (later it will be added to the corresponding thumb if required)

      if (newErrors?.length || 0 > 0) {
        const indexToRemove = newErrors.findIndex(
          (item) => item.code === "too-many-files"
        );

        // if there was a previous "too-many-files" error, removes it
        if (indexToRemove !== -1) {
          newErrors.splice(indexToRemove, 1);
        }
      }

      // if max files is defined and exceeding the max number of files
      if (index + 1 > maxFiles && maxFiles !== 0) {
        // adds the too many files error and set accepted to false
        finalSelectedFiles.push({
          ...item,
          accepted: false,
          errors: [
            ...newErrors,
            { code: "too-many-files", meesage: "manually created" },
          ],
        });
      } else {
        finalSelectedFiles.push({
          ...item,
          accepted: newErrors?.length > 0 ? false : true,
          errors: newErrors,
        });
      }
    });

    // send to parent list of accepted files to be uploaded and removes rejected
    const acceptedFilesToUpload = finalSelectedFiles.reduce(
      (fileAcum, file) => {
        if (file.accepted === true) {
          fileAcum.push(file);
        }
        return fileAcum;
      },
      []
    );
    // sendInfoToParent(filteredSelectedFiles);
    sendInfoToParent(acceptedFilesToUpload, allSelectedFiles, maxFileSizeMb);

    // returns all files, accepted and rejected for thumbs container
    return finalSelectedFiles;
  };

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxSize: maxFileSizeMb * 1024 * 1024, //must be in bytes
    onDrop: (acceptedFiles, rejectedFiles) => {
      //   console.log("onDrop", { acceptedFiles, rejectedFiles });

      let acceptedFilesWithPreview = [];
      if (acceptedFiles.length > 0) {
        // adds preview to every file accepted
        acceptedFilesWithPreview = acceptedFiles.map((file) => {
          return {
            // for some reason, the spread operator doesn't work
            name: file.name,
            preview: URL.createObjectURL(file),
            errors: [],
            accepted: true,
            imageFile: file, // whole image fill will be used later to upload
          };
        });

        // console.log("acceptedFilesWithPreview", acceptedFilesWithPreview);
      }

      let rejectedFilesWithPreview = [];
      if (rejectedFiles.length > 0) {
        // console.log(
        //   "%c------------rejectedFiles----------------",
        //   "background-color: purple; color: white",
        //   rejectedFiles
        // );

        // adds preview to every file rejected
        rejectedFilesWithPreview = rejectedFiles.map((item) => {
          return {
            // for some reason, the spread operator doesn't work
            name: item.file.name,
            preview: URL.createObjectURL(item.file),
            accepted: false,
            errors: item.errors,
            imageFile: item.file,
          };
        });
        // console.log("rejectedFilesWithPreview", rejectedFilesWithPreview);
        setSelectedFilesState([
          ...selectedFilesState,
          ...rejectedFilesWithPreview,
        ]);
      }

      let combinedFiles = [
        ...selectedFilesState,
        ...acceptedFilesWithPreview,
        ...rejectedFilesWithPreview,
      ];
      let newSelectedFiles;
      if (maxFiles > 0) {
        newSelectedFiles = updateMaxFilesError(combinedFiles);
      } else {
        newSelectedFiles = [...combinedFiles];
      }

      setSelectedFilesState(newSelectedFiles);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      //   ...(isDragAccept ? acceptStyle : {}),
      //   ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  //   useEffect(() => {
  //     // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //     return () => {
  //       selectedFilesState.forEach((file) => URL.revokeObjectURL(file.preview));
  //     };
  //   }, []);
  const maxFilesLabel =
    maxFiles !== 0 ? `(max ${maxFiles} file${maxFiles > 1 ? "s" : ""}` : "";

  const maxSizeLabel =
    maxFileSizeMb !== 0 ? `, max file size is ${maxFileSizeMb}Mb)` : "";

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {customTriggerComponent !== undefined ? (
          customTriggerComponent(open)
        ) : (
          <div
            className={`${classes.baseStyle} ${
              isDragActive && classes.activeStyle
            }`}
          >
            {dropzoneUpload ? (
              <ActivityIndicator />
            ) : (
              <h1 className={classes.plusSign}>+</h1>
            )}
            <h4 className={classes.mainText}>
              Drop here or click to add files
            </h4>
            <h6 className={classes.subText}>
              {maxFilesLabel}
              {maxSizeLabel}
            </h6>
          </div>
        )}
      </div>

      {/* <div style={{ overflowY: "auto", height: "200px" }}>
        <pre>
          {JSON.stringify(
            {
              selectedFilesState,
            },
            null,
            2
          )}
        </pre>
      </div> */}
    </div>
  );
}
