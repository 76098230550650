import { useContext, useEffect, useState } from "react";
// data
import { useMutation, useQuery } from "@apollo/client";
import { FeedbackContext } from "contexts/FeedbackContext";
import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import {
  infoAttendees,
  infoOrganizerAvg,
} from "graphql/gqlFeedbackFromOrganizerView";
import { updateMeeting } from "graphql/gqlMeeting";
import { tourData } from "utils/tourData";
// @material-ui/core components
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomTooltip from "components/CustomTooltip/CustomTooltip.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { TimerBar } from "./TimerBar";

// errors
import { logError, LogErrorComponent } from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// import Timer from "components/Timer";
// icons

import SaveIcon from "@material-ui/icons/Save";
import Multiface from "assets/svgComponent/multiface";
// styles
import { grayColor } from "assets/jss/material-dashboard-react";
import { formatDateTime } from "functions/Common";

const styles = {
  body: {
    padding: "20px 20px 0px 20px",
    overflow: "auto",
    maxHeight: "40vh",
  },
  avatar: {
    height: "25px",
    width: "25px",
  },
  bodyDividing: {
    margin: "10px 20px 10px 20px",
    paddingBottom: "20px",
    overflow: "hidden",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingTop: "20px",
    paddingRight: "20px",
  },

  commentTotalContainer: {
    margin: "5px 0px",
    padding: "10px 20px 5px 20px",
    borderTop: `1px solid  ${grayColor[6]}`,

    overflow: "auto",
    maxHeight: "200px",
  },
  commentLineContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px 20px 0px",
  },
  commentLabel: {
    color: grayColor[4],
  },
  commentTitleLabel: {
    marginBottom: "30px",
  },
  commentUserContainer: {
    paddingLeft: "15px",
  },
  commentUserLabel: {
    color: grayColor[2],
    margin: 0,
    lineHeight: "unset",
  },
  completedByLabel: {
    color: grayColor[4],
    paddingRight: 10,
  },

  facesContainer: {
    display: "flex",
    alignItems: "center",
  },
  face: {
    paddingLeft: "0px",
  },

  header: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "20px",
    justifyContent: "space-between",
    borderBottom: `1px solid  ${grayColor[6]}`,
    alignItems: "center",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
  },
  itemsContainer: {
    padding: "5px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  typeContainer: {
    borderColor: grayColor[5],
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    padding: "20px",
  },

  notShownContainer: {
    padding: "20px 20px",
    height: "35vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  notShownLabel: {
    color: grayColor[4],
    margin: "10px 0px",
  },

  noFeedbackLabel: {
    color: grayColor[4],
    margin: "30px 0px 10px 0px",
  },
  overallRatingLabel: {
    color: grayColor[4],
  },
  ratingContainer1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px 0px 0px 0px",
  },
  ratingContainer2: {
    display: "flex",
    alignItems: "baseline",
  },
  ratingLabel: {
    fontWeight: 600,
    color: grayColor[2],
    margin: "0px",
  },
  // timeLeftLabel: {
  //   color: grayColor[3],
  //   paddingRight: "5px",
  //   margin: 0,
  // },
  // timeLeftContainer: {
  //   flexDirection: "row",
  //   display: "flex",
  //   alignItems: "center",
  //   height: "45px",

  //   backgroundColor: grayColor[7],
  //   justifyContent: "space-between",
  //   padding: "7px",
  //   borderRadius: "5px",
  //   marginBottom: 15,
  // },
  // timeLeftIndicatorContainer: {
  //   // backgroundColor: "red",
  //   border: "2px solid red",
  //   padding: "3px 10px",
  //   borderRadius: "5px",
  //   minWidth: "100px",
  //   display: "flex",
  //   justifyContent: "center",
  //   backgroundColor: "white",
  //   height: "100%",
  // },
  // timeLeftIndicator: {
  //   color: "red",
  //   textAlign: "center",
  //   margin: 0,
  // },

  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    color: grayColor[3],
  },
  tooltip: {
    marginLeft: "4px",
  },
};

const useStyles = makeStyles(styles);

export const FeedbackFromOrganizerView = () => {
  const { session, setSession } = useContext(SessionContext);
  const { userID, languageID, firstUse } = session;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { createdBy, refreshInfo, statusID } = meeting;

  const { feedback, setFeedback } = useContext(FeedbackContext);
  const {
    endDateFinal,
    dateOrganizerGiveRating,
    expiredFeedbackTime,
    hoursForFeedback,
    canViewRating,
    infoOrganizer,
    endDateFinalRef,
    hoursForFeedbackRef,
    infoAttendeesRef,
  } = feedback;

  // const [infoOrganizerState, setInfoOrganizerState] = useState(
  //   firstUse ? tourData("feedback").organizer : null
  // );
  // const dateAttendeeGiveRating =
  //   infoOrganizerState !== null
  //     ? infoOrganizerState[0]?.dateAttendeeGiveRating
  //     : null;

  const dateAttendeeGiveRating = () => {
    if (firstUse) {
      return tourData("feedback").general.inforOrganizer;
    } else {
      if (infoOrganizer.length > 0) {
        return infoOrganizer[0]?.dateAttendeeGiveRating;
      } else {
        return null;
      }
    }
  };

  // show TimerBar when meeting has ended and both attendee and organizer rating are missing
  return (
    <>
      <TimerBar
        show={
          endDateFinalRef.current !== null &&
          // dateOrganizerGiveRating === null &&
          dateAttendeeGiveRating() === null
        }
      />
      <GridContainer
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <RatingToAttendeesFromOrganizerView />
        <RatingToOrganizerFromOrganizerView />
      </GridContainer>
    </>
  );
};

const RatingToAttendeesFromOrganizerView = () => {
  const classes = useStyles();
  // const generalData = useDataForLog();

  const { session, setSession } = useContext(SessionContext);
  const { languageID, firstUse } = session;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { meetingID } = meeting;

  const { feedback } = useContext(FeedbackContext);
  const {
    endDateFinal,
    endDateFinalRef,
    dateOrganizerGiveRating,
    expiredFeedbackTime,
    infoAttendeesRef,
    refetchFeedback,
  } = feedback;

  // stores the rating information from attendees to organizer

  const initialData = () => {
    if (firstUse) {
      const tourDummyData = tourData("feedback");
      return tourDummyData.attendees;
    }
    console.log("%cfeedback", "background-color: blue; color: white", feedback);

    if (infoAttendeesRef?.current === undefined) {
      return []; //[{ id: 0, name: "", rating: 0, imageUrl: "", comment: "" }];
    } else {
      return infoAttendeesRef.current;
    }
  };

  const [infoAttendeesState, setInfoAttendeesState] = useState(initialData());

  const {
    loading,
    data,
    error,
    refetch: refetchA,
  } = useQuery(infoAttendees, {
    variables: {
      meetingID: meetingID,
      languageID: languageID,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip: firstUse,
  });

  useEffect(() => {
    // sendInfoToParent({ action: "sendToAttendee" });
    setMeeting({
      ...meeting,
      socketSignalData: { action: "sendToAttendee" },
    });
    if (firstUse) return; // don't update infoAttendeesState and stick with the initial values

    if (data !== undefined) {
      infoAttendeesRef.current = data.spMeetingFeedbackAttendees.nodes;
      setInfoAttendeesState(data.spMeetingFeedbackAttendees.nodes);
    }
  }, [data]);

  const [updateMeetingMut] = useMutation(updateMeeting, {
    onCompleted: () => {
      console.log("db update");

      console.log(
        "%cupdate data inside fromOrganizerView",
        "background-color: red; color: white"
      );
      refetchFeedback();
      // refetch();
    },
  });

  // stores the rating information from organizer to attendees

  const Header = () => (
    <div className={classes.header}>
      <h4 className={classes.title}>Rate attendees</h4>
      {endDateFinalRef.current !== null && dateOrganizerGiveRating !== null && (
        <h6 className={classes.completedByLabel}>
          Completed by: {formatDateTime(dateOrganizerGiveRating)}
        </h6>
      )}
    </div>
  );

  const Content = () => {
    return (
      <div className={classes.body}>
        {infoAttendeesState.map((attendee) => {
          return (
            <div key={attendee.id} className={classes.itemsContainer}>
              <h6 className={classes.item}>{attendee.name}</h6>
              <div className={classes.facesContainer}>
                {[1, 2, 3, 4, 5].map((faceRating) => {
                  return (
                    <div key={faceRating} className={classes.face}>
                      <Button
                        onClick={() => {
                          const newArray = infoAttendeesState.map(
                            (attendee2, index2) => {
                              if (attendee.id === attendee2.id) {
                                return {
                                  ...attendee2,
                                  rating: faceRating,
                                };
                              } else {
                                return attendee2;
                              }
                            }
                          );
                          setInfoAttendeesState(newArray);
                        }}
                        color="info"
                        justIcon
                        round
                        disabled={
                          dateOrganizerGiveRating !== null ||
                          expiredFeedbackTime
                        }
                      >
                        <Multiface
                          selected={faceRating === attendee.rating && true}
                          rating={faceRating}
                        />
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const Footer = () => {
    const { modal, setModal } = useContext(FeedbackContext);

    // const generalData = useDataForLog(); // context data to populate error log

    const baseFeedback = {
      meetingId: meetingID,
      ratingDrive: null,
      ratingTime: null,
      ratingExec: null,
      ratingValue: null,
      ratingFromOrganizer: null,
      dateAttendeeGiveRating: null,
      comment: null,
    };
    return (
      <div className={classes.buttonContainer}>
        <Button
          onClick={() => {
            try {
              const uncompleted = infoAttendeesState.filter(
                (item) => item.rating === null
              );

              if (uncompleted.length > 0) {
                setModal({ visible: true, name: "rating-missing" });
              } else {
                const feedbackForUpdate = infoAttendeesState.map((item) => ({
                  ...baseFeedback,
                  id: item.id,
                  ratingFromOrganizer: item.rating,
                }));
                console.log(
                  "feedback for update",
                  JSON.stringify(feedbackForUpdate)
                );

                updateMeetingMut({
                  variables: {
                    meetingID: meetingID,
                    // sectionToUpdate: 6, // update only the feedback
                    feedback: feedbackForUpdate,
                    objectives: null, // for some reason if not provided creates an error
                    topics: null, // for some reason if not provided creates an error
                    actions: null, // for some reason if not provided creates an error
                  },
                });
              }
            } catch (error) {
              logError(error, {
                // generalData: { ...generalData },
                otherData: { infoAttendeesState },
              });
            }
          }}
          color="primary"
          startIcon={<SaveIcon />}
          disabled={dateOrganizerGiveRating !== null || expiredFeedbackTime}
        >
          Save
        </Button>
      </div>
    );
  };
  if (infoAttendeesState.length > 0) {
    return (
      <GridItem lg={5} md={6} sm={6} xs={12}>
        <div id="Feedback-rate-attendees" className={classes.typeContainer}>
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                data={{
                  // generalData: { ...generalData },
                  otherData: { meeting, meetingFeedbackState },
                }}
              />
            )}
          >
            <Header />
          </ErrorBoundary>

          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                data={{
                  // generalData: { ...generalData },
                  otherData: { meeting, infoAttendeesState },
                }}
              />
            )}
          >
            <Content />
          </ErrorBoundary>
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                data={{
                  // generalData: { ...generalData },
                  otherData: { meeting, infoAttendeesState },
                }}
              />
            )}
          >
            <Footer />
          </ErrorBoundary>
        </div>
      </GridItem>
    );
  }
};

const RatingToOrganizerFromOrganizerView = () => {
  const classes = useStyles();
  // const generalData = useDataForLog();

  const { session, setSession } = useContext(SessionContext);
  const { userID, languageID, firstUse } = session;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { createdBy, meetingID, refreshInfo, statusID } = meeting;

  const { feedback, setFeedback } = useContext(FeedbackContext);
  const {
    endDateFinal,
    dateOrganizerGiveRating,
    expiredFeedbackTime,
    hoursForFeedback,
    canViewRating,
    infoOrganizer,
    endDateFinalRef,
    hoursForFeedbackRef,
    infoAttendeesRef,
    refetchFeedback,
  } = feedback;

  const [attendeesComments, setAttendeesComments] = useState([
    // {
    //   id: "",
    //   desc: "",
    //   imagePath: "",
    //   comment: "",
    // },
  ]);

  const { loading, data, error, refetch } = useQuery(infoOrganizerAvg, {
    variables: {
      meetingID: meetingID,
      languageID: languageID,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip: firstUse,
  });

  useEffect(() => {
    if (firstUse) return; // don't update infoAttendeesState and stick with the initial values

    if (data !== undefined) {
      // setInfoOrganizerState(data.spMeetingFeedbackToOrganizerAvg.nodes);
      setFeedback({
        ...feedback,
        infoOrganizer: data.spMeetingFeedbackToOrganizerAvg.nodes,
      });
      setAttendeesComments(data.spMeetingFeedbackComments.nodes);
    }
  }, [data]);

  const CanViewRating = () => {
    const organizerRatingFunc = () => {
      try {
        const organizerRating = infoOrganizer.filter((item) => item.id === 0)[0]
          ?.rating;
        console.log(
          "%corganizerRating",
          "background-color: red; color: white",
          organizerRating
        );

        return organizerRating;
      } catch (error) {
        logError(error, {
          // generalData: { ...generalData },
          otherData: { infoOrganizer },
        });
      }
    };

    const RatingOverall = () => (
      <div className={classes.ratingContainer1}>
        <>
          <div className={classes.ratingContainer2}>
            <h1 className={classes.ratingLabel}>{organizerRatingFunc()}</h1>
            <h4>/5</h4>
          </div>
          <h4 className={classes.overallRatingLabel}>Overall rating</h4>
        </>
      </div>
    );

    const RatingDetails = () => {
      return (
        <div className={[classes.body, classes.bodyDividing].join(" ")}>
          {infoOrganizer.map((criteria) => {
            if ([1, 2, 3, 4].includes(criteria.id)) {
              return (
                <div key={criteria.id} className={classes.itemsContainer}>
                  <div className={classes.itemContainer}>
                    <h6 className={classes.item}>{criteria.desc}</h6>
                    <span>
                      {criteria.tooltip && (
                        <div className={classes.tooltip}>
                          <CustomTooltip tooltipText={criteria.tooltip} />
                        </div>
                      )}
                    </span>
                  </div>

                  <div className={classes.facesContainer}>
                    {[1, 2, 3, 4, 5].map((faceRating) => {
                      return (
                        <div key={faceRating} className={classes.face}>
                          <Button
                            onClick={() => {
                              const newArray = infoOrganizer.map(
                                (criteria2, index2) => {
                                  if (criteria.id === criteria2.id) {
                                    return {
                                      ...criteria2,
                                      rating: faceRating,
                                    };
                                  } else {
                                    return criteria2;
                                  }
                                }
                              );
                              console.log(
                                "%cupdate setInfoOrganizerState in change face in organizer view",
                                "background-color: red; color: white",
                                dataLazyQuery.spMeetingFeedbackToOrganizerUser
                                  .nodes
                              );
                              // setInfoOrganizerState(newArray);
                              setFeedback({
                                ...feedback,
                                infoOrganizer: newArray,
                              });
                            }}
                            color="info"
                            justIcon
                            round
                            disabled
                          >
                            <Multiface
                              selected={
                                faceRating === parseInt(criteria.rating) && true
                              }
                              rating={faceRating}
                            />
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    };

    const AttendeesComments = () => {
      return (
        attendeesComments.length > 0 && (
          <div className={classes.commentTotalContainer}>
            <h5 className={classes.commentTitleLabel}>Comments:</h5>
            {attendeesComments.map((item) => {
              return (
                <div key={item.id} className={classes.commentLineContainer}>
                  <Avatar
                    className={classes.avatar}
                    alt={item.desc}
                    src={item.imagePath}
                  />
                  <div className={classes.commentUserContainer}>
                    <h6 className={classes.commentUserLabel}>{item.desc}</h6>
                    <h6 className={classes.commentLabel}>{item.comment}</h6>
                  </div>
                </div>
              );
            })}
          </div>
        )
      );
    };

    return (
      <>
        {organizerRatingFunc() === undefined ? (
          <h5 className={classes.noFeedbackLabel}>
            None of your attendees gave feedback within the timeframe
          </h5>
        ) : (
          <>
            <ErrorBoundary
              FallbackComponent={(error) => (
                <LogErrorComponent
                  error={error}
                  data={{
                    // generalData: { ...generalData },
                    otherData: { meeting, infoOrganizer },
                  }}
                />
              )}
            >
              <RatingOverall />
            </ErrorBoundary>

            <ErrorBoundary
              FallbackComponent={(error) => (
                <LogErrorComponent
                  error={error}
                  data={{
                    // generalData: { ...generalData },
                    otherData: { meeting, infoOrganizer },
                  }}
                />
              )}
            >
              <RatingDetails />
            </ErrorBoundary>

            <ErrorBoundary
              FallbackComponent={(error) => (
                <LogErrorComponent
                  error={error}
                  data={{
                    // generalData: { ...generalData },
                    otherData: { meeting, attendeesComments },
                  }}
                />
              )}
            >
              <AttendeesComments />
            </ErrorBoundary>
          </>
        )}
      </>
    );
  };

  const CantViewRating = () => {
    return (
      <div className={classes.notShownContainer}>
        <h5 className={classes.notShownLabel}>
          Your rating is hidden. It will be visible when:
        </h5>
        <ul>
          <li className={classes.notShownLabel}>
            <h5>you have rated your attendees</h5>
          </li>
          <li className={classes.notShownLabel}>
            <h5>
              all your attendees have rated you or the time for your attendees
              to complete the feedback has expired
            </h5>
          </li>
        </ul>
      </div>
    );
  };

  if (infoOrganizer !== null) {
    return (
      <GridItem lg={5} md={6} sm={6} xs={12}>
        <div id="Feedback-rate-organizer" className={classes.typeContainer}>
          <div className={classes.header}>
            <h4 className={classes.title}>Rating from your attendees</h4>
          </div>
          {canViewRating ? <CanViewRating /> : <CantViewRating />}
        </div>
      </GridItem>
    );
  }
};
