/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons

import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import EventIcon from "@material-ui/icons/Event";

import ListIcon from "@material-ui/icons/List";
// import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import DoubleArrow from "@material-ui/icons/DoubleArrow";

// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// components sidebar
import Dashboard from "views/Dashboard/Dashboard.js";
import MeetingList from "views/Meetings/MeetingList.js";

import Meeting from "views/Meetings/Meeting/Meeting";

import Calendar from "views/Calendar/Calendar.js";
import Actions from "views/Actions/Actions";
import Todo from "views/Todo/Todo.js";
// components internal

// components navbar user
import Settings from "views/Settings/Settings.js";
import ProfileDetails from "views/Profile/ProfileDetails.js";
import AccountDetails from "views/Account/AccountDetails.js";
import AccountInvoice from "views/Account/AccountInvoice.js";
import HelpDeskTickets from "views/HelpDesk/HelpDeskTickets";

import AccountInfo from "contexts/AccountContext";
import MeetingInfo from "contexts/MeetingContext";
import DashboardInfo from "contexts/DashboardContext";
import ActionsInfo from "contexts/ActionsContext";
import TodoInfo from "contexts/TodoContext";
import HelpDeskInfo from "contexts/HelpDeskContext";

const UserNavbarRoutes = [
  {
    path: "/profile/details",
    name: "Profile",
    rtlName: "",
    icon: LocationOn,
    component: <ProfileDetails />,
    layout: "/app",
    inSidebar: false,
    inNavBar: true,
  },

  {
    path: "/account",
    name: "Account",
    rtlName: "",
    icon: LocationOn,
    component: (
      <AccountInfo>
        <AccountDetails />
      </AccountInfo>
    ),
    layout: "/app",
    inSidebar: false,
    inNavBar: true,
  },

  {
    path: "/settings",
    name: "Settings",
    rtlName: "",
    icon: LocationOn,
    component: <Settings />,
    layout: "/app",
    inSidebar: false,
    inNavBar: true,
  },
  {
    path: "/dashboard",
    name: "Run tour", // if changing the name, navbarRightElements has to change action associated
    rtlName: "",
    icon: LocationOn,
    component: (
      <DashboardInfo>
        <Dashboard />,
      </DashboardInfo>
    ),
    layout: "/app",
    inSidebar: false,
    inNavBar: true,
  },
];

const InternalRoutes = [
  {
    path: "/meeting",
    name: "Meeting",
    rtlName: "قائمة الجدول",
    icon: PeopleAltIcon,
    component: (
      <MeetingInfo>
        <Meeting />
      </MeetingInfo>
    ),
    layout: "/app",
    inSidebar: false,
    inNavBar: false,
  },
];

const otherNavBarRoutes = [
  {
    path: "/helpdesk",
    name: "Help desk",
    rtlName: "",
    icon: LocationOn,
    component: (
      <HelpDeskInfo>
        <HelpDeskTickets />
      </HelpDeskInfo>
    ),
    layout: "/app",
    inSidebar: false,
    inNavBar: false,
  },
];

const SidebarRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: InsertChartIcon,
    component: (
      <DashboardInfo>
        <Dashboard />,
      </DashboardInfo>
    ),
    layout: "/app",
    inSidebar: true,
    inNavBar: false,
  },

  {
    path: "/meetings",
    name: "Meeting list",
    rtlName: "قائمة الجدول",
    icon: PeopleAltIcon,
    component: (
      <MeetingInfo>
        <MeetingList />
      </MeetingInfo>
    ),
    layout: "/app",
    inSidebar: true,
    inNavBar: false,
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "طباعة",
    icon: EventIcon,
    component: <Calendar />,
    layout: "/app",
    inSidebar: true,
    inNavBar: false,
  },
  {
    path: "/actions",
    name: "Actions",
    rtlName: "طباعة",
    icon: DoubleArrow,

    component: (
      <ActionsInfo>
        <Actions />
      </ActionsInfo>
    ),
    layout: "/app",
    inSidebar: true,
    inNavBar: false,
  },
  {
    path: "/todo",
    name: "To do",
    rtlName: "طباعة",
    icon: ListIcon,
    component: (
      <TodoInfo>
        <Todo />,
      </TodoInfo>
    ),
    layout: "/app",
    inSidebar: true,
    inNavBar: false,
  },

  // {
  //   path: "/employees",
  //   name: "Employees",
  //   rtlName: "طباعة",
  //   icon: SupervisedUserCircleIcon,
  //   component: <ConnectCalendarGoogle />,
  //   layout: "/app",
  //   inSidebar: true,
  //   inNavBar: false,
  // },
];

const Routes = [
  ...InternalRoutes,
  ...SidebarRoutes,
  ...UserNavbarRoutes,
  ...otherNavBarRoutes,
];

export default Routes;
