import React, { useState, useContext, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// custom components
import CustomHeader from "components/CustomHeader/CustomHeader.js";
import Snackbar from "components/Snackbar/Snackbar";
import DashboardPersonal from "./DashboardPersonal";
import DashboardCompany from "./DashboardCompany";
import addDays from "date-fns/addDays";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

import {
  defaultFont,
  grayColor,
  dangerColor,
} from "assets/jss/material-dashboard-react.js";

const styles = {
  countDownMain: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: grayColor[7],
    // backgroundColor: "red",
    borderRadius: "5px",
    padding: "0px 10px 0px 10px",
    margin: "0px 5px 0px 5px",
    // height: "100%",
  },
  countDownDescContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: "10px",
  },
  countDownIndicatorLabel: {
    ...defaultFont,
    fontWeight: 600,
    fontSize: "1.15rem",
    color: grayColor[2],
  },
  countDownTypeLabel: {
    ...defaultFont,
    fontWeight: 600,
    fontSize: "0.75rem",
    color: grayColor[4],
  },
  countDownAlert: {
    color: "white",
  },
  countDownAlertBackground: {
    backgroundColor: dangerColor[1],
  },
  dashboardContainer: {
    // backgroundColor: "yellow",
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 170px)",
  },

  localModalBackdrop: {
    backgroundColor: "rgba(0,0,0,0.5)",
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  localModalDialog: {
    padding: "40px",
    backgroundColor: "white",
    // width: "200px",
    // height: "600px",
    borderRadius: "5px",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  localModalTitle: {
    ...defaultFont,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 600,
    fontSize: "1.15rem",
    textAlign: "center",
    paddingBottom: "20px",
  },
  mainContainer: {
    padding: "20px 10px 0px 10px",

    // backgroundColor: "yellow",
    overflowY: "auto",
    height: "calc(100vh - 160px)",
  },
};

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    // console.log("los props en meeting son", props);
    const classes = useStyles();
    // const countDownValues = useCountdown(startDate);
    // const countDownValuesEnd = useCountdown(endDate);

    // const location = useLocation();
    // let headerVariant = location.state.headerVariant;

    // console.log("location.state", location.state);
    // const {} = session;

    const [dateRange, setDateRange] = useState({
      startDate: addDays(new Date(), -90),
      endDate: new Date(),
    });
    const [tabSelected, setTabSelected] = useState(0);
    const [showSnackbar, setShowSnackbar] = useState({
      show: false,
      messageType: "",
      message: "",
    });

    const tabs = [
      {
        tabName: "Personal",
        tabContent: <DashboardPersonal dateRange={dateRange} />,
      },
      {
        tabName: "Company",
        tabContent: <DashboardCompany dateRange={dateRange} />,
      },
    ];

    const DisplayContent = () => {
      const content = tabs.filter((item, index) => index === tabSelected);
      // console.log("tabFiltered", content);

      return content.length === 0 ? null : content[0].tabContent;

      return tabs.map((item, key) => {
        if (key === tabSelected) {
          return (
            <div key={key} className={classes.mainContainer}>
              {item.tabContent}
            </div>
          );
        }
        return (
          <div hidden key={key}>
            {item.tabContent}
          </div>
        );
      });
    };

    return (
      <>
        <Card fullscreen>
          <CustomHeader
            headerColor="white"
            // title="titulo"
            onChangeTab={(tabIndex) => {
              setTabSelected(tabIndex);
            }}
            headerVariant="dashboard"
            // medalsCompleted={medalsCompleted}
            tabs={tabs}
            // cost={countDownValues[0].value >= 1 ? meetingCost : null} //"$1200"

            // menuList={menuList}

            // rightAlignItems={rightButtons()}
            fullscreen
            tabSelected={tabSelected}
            dates={{ startDate: addDays(new Date(), -90), endDate: new Date() }}
            sendInfoToParent={(item) => {
              console.log(item);
              setDateRange(item);
            }}
          />
          {/* use session storage instead of session.  Otherwise both dialogs will show */}
          <CardBody fullscreen>
            <div className={classes.dashboardContainer}>
              <ErrorBoundary
                FallbackComponent={(error) => (
                  <LogErrorComponent
                    error={error}
                    size="large"
                    data={{
                      errorLevel: "fatal",
                      generalData: { ...generalData },
                      otherData: {},
                    }}
                  />
                )}
              >
                <DisplayContent />
              </ErrorBoundary>
            </div>
          </CardBody>
          {/* <Button color="primary" onClick={() => refetchFunc()}>
          Rrfrescar
        </Button> */}
        </Card>

        <Snackbar
          messageType={showSnackbar.messageType}
          message={showSnackbar.message}
          open={showSnackbar.show}
          close={() => setShowSnackbar({ ...showSnackbar, show: false })}
        />
      </>
    );
    // }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
