import { useContext, useState } from "react";

// @material-ui/core components

// core components
import Button from "components/CustomButtons/Button";
import CustomDialog from "components/CustomDialog/CustomDialog";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// data
import axios from "axios";
import config from "config";
import { SessionContext } from "contexts/SessionContext";
import { AccountContext } from "contexts/AccountContext";
import { Formik } from "formik";

import { axiosOptions } from "functions/Common";
import { object, string } from "yup";

// errors
import { LogErrorComponent } from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// icon
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// style
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  dangerColor,
  grayBoxShadow,
  grayColor,
  primaryColor,
} from "assets/jss/material-dashboard-react";

const styles = {
  addBarContainer: {
    borderBottom: `1px solid ${grayColor[6]}`,
    borderTop: `1px solid ${grayColor[6]}`,
    backgroundColor: primaryColor[9],
  },
  alignLeft: {},
  alignCenter: {},
  confirmButtonsContainer: {
    justifyContent: "space-around",
    display: "flex",
    margin: "20px 40px 0px 40px",
  },
  dialogInviteUsersContent: {
    display: "flex",
    flex: 1,
    overflowY: "auto",
    flexDirection: "column",
  },
  fileDetailsLabel: {
    color: grayColor[3],
    marginBottom: 15,
  },
  gridContainer: {
    flex: 1,
    alignItems: "flex-start",
    // borderBottom: `1px solid ${grayColor[7]}`,
  },
  header: {},
  headerContainer: {
    ...grayBoxShadow,
    alignItems: "center",
    backgroundColor: "white",
    // backgroundColor: grayColor[8],
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // marginBottom: "15px",
    padding: "5px 20px 0px 27px",
    transition: "all 600ms ease-in-out",
    borderBottom: `1px solid ${grayColor[6]}`,
  },
  inputFilePicker: {
    display: "none",
  },
  inviteeContainer: {
    // borderRadius: 5,
    // padding: "5px 5px 5px 10px",
    padding: "8px 20px 2px 20px",

    transition: "all 150ms ease 0s",
    margin: "0px 0px 1px 0px",
    borderBottom: `1px solid ${grayColor[6]}`,
  },
  inviteeContainerError: {
    backgroundColor: dangerColor[6],
  },
  inviteesWithErrorsLabel: {
    color: "red",
    textAlign: "center",
    margin: 0,
  },
  modalButtonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
  },
};

const useStyles = makeStyles(styles);

export default function AccountInviteUsers() {
  const classes = useStyles();

  const InviteUserContent = () => {
    console.log("Starts invite user content");
    const { session } = useContext(SessionContext);
    const { accountID, email, languageID, stageID, userName, userID } = session;

    const {
      account,
      setAccount,
      modal,
      setModal,
      userQueryRef,
      showSnackbar,
      setShowSnackbar,
    } = useContext(AccountContext);
    const { slotsFree, slotsPlan, slotsUsed } = account;

    const initialInviteeData = [];
    const [inviteeData, setInviteeData] = useState(initialInviteeData);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalUserInvite, setModalUserInvite] = useState({
      visible: false,
      name: "",
    });
    const [selectedItemHovered, setSelectedItemHovered] = useState(false);
    const [selectedInviteeID, setSelectedInviteeID] = useState(null);
    const [errorState, setErrorState] = useState(null);

    // const { loading, error, data, refetch, fetchMore } = useQuery(
    //   usersSubscription,
    //   {
    //     variables: { ownerID: userID, cursor: null, pageSize: 30 },
    //     notifyOnNetworkStatusChange: true,
    //     fetchPolicy: "network-only",
    //   }
    // );

    const createSchema = (context = {}) =>
      object().shape({
        email: string()
          .test(
            "Slots exceeded",
            "Available slots exceeded",
            (input, array) => {
              const inviteesWithErrors = () => {
                console.log("los errores son: ", context?.errors);
                if (context?.errors || null === null) {
                  console.log("errors are undefined");
                  return 0;
                } else {
                  context?.errors?.reduce((acum, item) => {
                    // finds unique invitee with errors.  Eliminates invitees that have multiple errors
                    const itemExists = acum.filter((item) => item === item.id);
                    itemExists.length === 0 && acum.push(item.id);
                    return acum;
                  }, []).length;
                }
              };
              const inviteesTotal = context?.currentArray?.length || 0;
              const inviteesFinal = inviteesTotal - inviteesWithErrors();

              // if in trial mode, don't limit users that can be invited
              if (stageID !== 2) {
                const newItemCount = input?.length > 0 ? 1 : 0;
                if (slotsFree - inviteesFinal - newItemCount >= 0) {
                  console.log(
                    "%cPASSED: Available slots exceeded",
                    "background-color: green; color: white",
                    {
                      slotsFree,
                      newItemCount,
                      inviteesWithErrors: inviteesWithErrors(),
                      inviteesTotal,
                      inviteesFinal,
                    }
                  );
                } else {
                  console.log(
                    "%cNOT PASSED: Available slots exceeded",
                    "background-color: red; color: white",
                    {
                      slotsFree,
                      newItemCount,
                      inviteedsWithErrors: inviteesWithErrors(),
                      inviteesTotal,
                      inviteesFinal,
                    }
                  );
                }

                return slotsFree - inviteesFinal - newItemCount >= 0;
              } else {
                console.log("stage in trial.  Don't evaluate free slots");
                return true;
              }
            }
          )
          .required()
          .email("Email is not valid")
          .test(
            "AlreadyInvited",
            "Email already in invitation list",
            (input, data) => {
              // console.log("%cDAta", "background-color: red; color: white", {
              //   input,
              //   data,
              //   context,
              // });

              const index = context.currentArray.findIndex(
                (invitee) => invitee.email === input
              );
              if (index === -1) {
                console.log(
                  "%cPASSED: Email NOT already in invitation list",
                  "background-color: green; color: white",
                  {
                    input,
                    index,
                    currentArray: context.currentArray,
                  }
                );
              } else {
                console.log(
                  "%cNOT PASSED: Email already in invitation list",
                  "background-color: red; color: white",
                  {
                    input,
                    index,
                    currentArray: context.currentArray,
                  }
                );
              }

              return index === -1; // if index is not found, returns true
            }
          )
          .test(
            "AlreadyUser",
            "Email already invited and in the user list.",
            (input) => {
              const emailAlredyWithAccess = userQueryRef.current.data.filter(
                (item) => item.email === input
              );

              return emailAlredyWithAccess.length === 0;
            }
          ),

        // .test(
        //   "Daily mail quota reached",
        //   `Daily quota reached (max ${data.spEmailsLeftToSend.nodes[0].maxDay})`,
        //   (input, array) => {
        //     const addedEmailsCount = array.parent.attendees.length;
        //     const dailyQuotaRemain =
        //       data.spEmailsLeftToSend.nodes[0].availableDay;
        //     // console.log("los datos de daily quota", {
        //     //   addedEmailsCount: addedEmailsCount,
        //     //   dailyQuotaRemain: dailyQuotaRemain,
        //     //   remain: dailyQuotaRemain - addedEmailsCount,
        //     // });
        //     return dailyQuotaRemain - addedEmailsCount > 0;
        //   }
        // ),

        firstName: string().max(30, "Max 30 digits"),
        lastName: string().max(30, "Max 30 digits"),
      });

    let errorsTemp = [];
    const executeValidation = async (
      objectToValidate,
      currentInviteeID,
      currentArray,
      currentLoop = 1,
      totalLoops = 1
    ) => {
      console.log(
        "%c---------------Starts executeValidation---------------------",
        "background-color: purple; color: white",
        {
          objectToValidate,
          currentInviteeID,
          currentArray,
          currentLoop,
          totalLoops,
          errorsTemp,
        }
      );

      const currentFieldName = Object.keys(objectToValidate)[0]; //obtains the name of the field to be affected
      if (currentLoop === 1) {
        console.log("%cResets error", "background-color: red; color: white");

        errorsTemp = [];
      }
      const errorIndex = errorsTemp.findIndex(
        (item) =>
          item.id === currentInviteeID && item.fieldName === currentFieldName
      );
      // const newArrayErrors = [...errors];
      const validationSchema = createSchema({
        currentArray: currentArray,
        errors: errorsTemp,
      });

      // Await the execution of validation and handle errors
      try {
        await validationSchema.validate(objectToValidate);
        // removes any previous error
        if (errorIndex !== -1) {
          errorsTemp.splice(errorIndex, 1);
        }
      } catch (error) {
        console.log(
          "%cvalidation NOT passed",
          "background-color: yellow; color: red",
          {
            error,
            errorsTemp,
            currentFieldName,
            errorIndex,
            currentInviteeID,
          }
        );

        // search if an error already exists
        if (errorIndex === -1) {
          //if the id was NOT found, adds a new id with the error
          errorsTemp.push({
            id: currentInviteeID,
            fieldName: currentFieldName,
            message: error.message,
          });
        } else {
          // search if there's already an error for this id and this field
          //if the id was found, updates the error for the fieldName under the same ID
          console.log("error", newArrayErrors);
          errorsTemp[errorIndex].message = error.message;
        }
        // errorsTemp.push(newArrayErrors);
      }
      if (currentLoop === totalLoops) {
        setErrors(errorsTemp);
      }
    };

    const updateField = async (index, updatedProperties, validate) => {
      try {
        console.log(
          "%cupdated properties in updateField",
          "background-color: blue; color: white",
          { updatedProperties }
        );

        // process for state update

        const updatedItems = [...inviteeData];

        // detect if updatedProperties is empty and therefore means the index should be deleted
        // otherwise, it should be updated

        const shouldBeDeleted = Object.keys(updatedProperties).length === 0;
        if (shouldBeDeleted) {
          updatedItems.splice(index, 1);
        } else {
          // updates only the line affected
          updatedItems[index] = {
            ...updatedItems[index],
            ...updatedProperties,
          };
        }

        setInviteeData(updatedItems);

        // if validate is true, validates the whole array
        let acumulatedInvitees = [];
        for (const [index, invitee] of updatedItems.entries()) {
          console.log("valores", {
            acumulatedInvitees,
            invitee,
          });

          const objectToValidate = {
            email: invitee.email,
            firstName: invitee.firstName,
            lastName: invitee.lastName,
          };
          await executeValidation(
            objectToValidate,
            invitee.id,
            acumulatedInvitees,
            index + 1, // current loop
            updatedItems.length // total number of loops
          );

          console.log("validation executed");
          acumulatedInvitees.push(invitee);
        }
        // if (validate) {
        //   const currentInviteeID = inviteeData[index].id;
        //   executeValidation(updatedProperties, currentInviteeID, inviteeData);
        // }

        //--------------------------------- process array for db update ---------------------------------------------
      } catch (error) {
        console.log("el error es", error);
        setErrorState({
          error: error,
          errorExplanation: "Invitee could not be updated",
          data: {
            errorLevel: "critical",
            otherData: {
              updatedProperties,
            },
          },
        });
      }
    };

    const InviteeHeader = () => {
      return (
        <div className={classes.headerContainer}>
          <GridContainer
            spacing={2}
            direction="row"
            classes={{ container: classes.gridContainer }}
          >
            <GridItem xs={4}>
              <h5 className={`${classes.header} ${classes.alignLeft}`}>
                Email
              </h5>
            </GridItem>
            <GridItem xs={3}>
              <h5 className={`${classes.header} ${classes.alignCenter}`}>
                First name (optional)
              </h5>
            </GridItem>
            <GridItem xs={3}>
              <h5 className={`${classes.header} ${classes.alignCenter}`}>
                Last name (optional)
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      );
    };

    const InviteeAddBar = () => {
      // const [addBarValues, setAddBarValues] = useState({
      //   email: null,
      //   firstName: null,
      //   lastName: null,
      //   error: "",
      // });

      const initialValues = {
        email: "",
        firstName: "",
        lastName: "",
      };

      const handleSubmit = (values, actions) => {
        console.log(
          "%cejecuta onSubmit",
          "background-color: red; color: white"
        );

        // const maxID = inviteeData.reduce((max, item) => {
        //   if (item.id > max) {
        //     max = item.id;
        //   } else {
        //     max;
        //   }
        //   return max;
        // }, inviteeData[0].id);

        const maxID = inviteeData.reduce(
          (max, item) => (item.id > max ? item.id : max),
          inviteeData[0].id
        );

        setInviteeData([
          ...inviteeData,
          {
            id: maxID + 1,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
          },
        ]);
      };

      return (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={createSchema({ currentArray: inviteeData })}
          // validationSchema={(values) =>
          //   executeValidation(values, null, inviteeData)
          // }
        >
          {(fprops) => (
            <div
              className={`${classes.inviteeContainer} ${classes.addBarContainer}`}
            >
              <GridContainer
                direction="row"
                classes={{ container: classes.gridContainer }}
                // alignItems="center"
                // justifyContent="center"
              >
                {/* -------------- email ---------------- */}
                <GridItem xs={4}>
                  <CustomMainInput
                    color="transparent"
                    placeholder="Add email"
                    name="email"
                    error={fprops.touched.email && fprops.errors.email}
                    // onChange={fprops.handleChange("email")}
                    onChange={(e) => {
                      fprops.handleChange("email")(e);
                      // fprops.setFieldValue("email", e.target.value);
                      if (e.target.value.length === 0) {
                        fprops.setTouched({
                          ...fprops.touched,
                          email: false,
                        });
                      }
                    }}
                    // onBlur={fprops.handleBlur("email")}
                    onBlur={(e) => {
                      fprops.handleBlur("email")(e);
                      if (e.target.value.length === 0) {
                        fprops.setTouched({
                          ...fprops.touched,
                          email: false,
                        });
                      }
                    }}
                    value={fprops.values.email}
                    variant="input"
                  />
                </GridItem>

                {/* -------------- first name ---------------- */}
                <GridItem xs={3}>
                  <CustomMainInput
                    color="transparent"
                    placeholder="Enter first name"
                    name="firstName"
                    error={fprops.touched.firstName && fprops.errors.firstName}
                    onChange={fprops.handleChange("firstName")}
                    onBlur={fprops.handleBlur("firstName")}
                    value={fprops.values.firstName}
                    variant="input"
                  />
                </GridItem>

                {/* -------------- last name ---------------- */}
                <GridItem xs={3}>
                  <CustomMainInput
                    color="transparent"
                    placeholder="Enter last name"
                    name="lastName"
                    error={fprops.touched.lastName && fprops.errors.lastName}
                    onChange={fprops.handleChange("lastName")}
                    onBlur={fprops.handleBlur("lastName")}
                    value={fprops.values.lastName}
                    variant="input"
                  />
                </GridItem>
                {/* -------------- button add ---------------- */}
                <GridItem
                  xs={2}
                  row
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    color="primary"
                    size="xs"
                    // justIcon
                    onClick={() => fprops.submitForm()}
                  >
                    Add
                  </Button>
                </GridItem>
              </GridContainer>
              {/* <pre>
                {JSON.stringify(
                  {
                    // values: fprops.values,
                    errors: fprops.errors,
                    touched: fprops.touched,
                  },
                  null,
                  2
                )}
              </pre> */}
            </div>
          )}
        </Formik>
      );
    };

    const ButtonsContainer = () => {
      const ImportButton = () => {
        return (
          <div style={{ marginRight: 10 }}>
            <Button
              component="span"
              color="primary"
              // justIcon
              onClick={() =>
                setModalUserInvite({
                  visible: true,
                  name: "fileFormatDetails",
                })
              }
            >
              Import from file
            </Button>
          </div>
        );
      };

      const SendInvitationsButton = () => {
        const inviteesWithErrors = errors.reduce((acum, item) => {
          // finds unique invitee with errors.  Eliminates invitees that have multiple errors
          const itemExists = acum.filter((item) => item === item.id);
          itemExists.length === 0 && acum.push(item.id);
          return acum;
        }, []).length;
        const inviteesTotal = inviteeData?.length || 0;
        const inviteesFinal = inviteesTotal - inviteesWithErrors;

        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              color="primary"
              disabled={inviteesFinal === 0}
              onClick={(item) => {
                setModalUserInvite({
                  visible: true,
                  name: "confirmInvitationSend",
                });
              }}
            >
              {`Send${
                inviteesFinal === 0
                  ? " invitations"
                  : inviteesFinal === 1
                  ? ` 1 invitation`
                  : ` ${inviteesFinal} invitations`
              }`}
            </Button>
            {inviteesWithErrors > 0 && (
              <h6 className={classes.inviteesWithErrorsLabel}>
                {inviteesWithErrors === 1
                  ? ` 1 invitee with error`
                  : ` ${inviteesWithErrors} invitees with error`}
              </h6>
            )}
          </div>
        );
      };

      return (
        <div className={classes.modalButtonContainer}>
          <ImportButton />
          <SendInvitationsButton />
        </div>
      );
    };

    const ModalToShowUserInvite = () => {
      const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log(
          "%cel file es",
          "background-color: red; color: white",
          file
        );
        const reader = new FileReader();

        const parseCSV = (fileContent) => {
          // Split the text into lines
          // const lines = text.split('\n');
          const rows = fileContent.split("\r\n");
          let delimiter = null;
          let error = "";
          let data = [];
          console.log(
            "%cLas row son",
            "background-color: blue; color: white",
            rows
          );

          // defines delimiter Looking at the first few lines
          for (let i = 0; i < Math.min(rows.length, 5); i++) {
            if (rows[i].includes(",")) {
              // console.log("includes a comma");
              // If a line contains a comma, assume comma-separated
              if (rows[i].includes(";")) {
                error = `Character is conflicting with delimiter in row ${i}`;
              } else {
                delimiter = ",";
              }
            } else if (rows[i].includes(";")) {
              // console.log("includes a semicolon");
              // If a line contains a semicolon, assume semicolon-separated

              if (rows[i].includes(",")) {
                error = `Character is conflicting with delimiter in row ${i}`;
              } else {
                delimiter = ";";
              }
            } else {
              // Assumes there's only one column (email) if there not comma or semicolon
              delimiter = null;
              // if (rows[i].length > 0) {
              //   error = "Delimiter not found";
              // }
            }
          }

          // removes empty rows and returns array of objects with consecutive id
          if (error.length === 0) {
            data = rows
              .reduce((acumRow, row, index) => {
                // eliminates empty rows
                if (row.length > 0) {
                  // if no delimiter was detected, assumes only one column and take it as email
                  if (delimiter === null) {
                    acumRow.push({
                      id: index + 1,
                      email: row,
                      firstName: "",
                      lastName: "",
                    });
                    console.log(
                      "%cdelimiter is null.  AcumRow is",
                      "background-color: pink; color: red",
                      { acumRow, row }
                    );
                  } else {
                    console.log(
                      "%cdelimiter is NOT null",
                      "background-color: pink; color: red"
                    );
                    const rowSplit = row.split(delimiter);

                    acumRow.push({
                      id: index + 1,
                      email: rowSplit[0] || "",
                      firstName: rowSplit[1] || "",
                      lastName: rowSplit[2] || "",
                    });
                  }
                }

                return acumRow;

                // console.log("row split", rowSplit);
                // return rowSplit;
              }, [])

              .sort((a, b) => {
                if (a["email"] < b["email"]) {
                  return -1;
                }
                if (a["email"] > b["email"]) {
                  return 1;
                }
                return 0;
              });
          }
          console.log(
            "%cla data procesada es",
            "background-color: red; color: white",
            data
          );

          return { data, error };
        };

        reader.onload = async (event) => {
          try {
            const fileContent = event.target.result;
            let result = [];
            console.log(
              "%cel file es",
              "background-color: blue; color: white",
              file
            );
            if (
              [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
              ].includes(file.type)
            ) {
              console.log("es un archivo de excel");
              const workbook = XLSX.read(fileContent, {
                type: "binary",
              });
              console.log("el workbook es: ", workbook);
              const sheetName = workbook.SheetNames[0];
              console.log("el sheetName es: ", sheetName);
              const sheet = workbook.Sheets[sheetName];
              console.log("el sheet es: ", sheet);
              result = XLSX.utils.sheet_to_json(sheet);

              // result = [];
            } else if (file.type === "text/csv") {
              result = parseCSV(fileContent);
            } else {
              result = null;
            }
            console.log("result", result);
            if (result === null) {
              setModalUserInvite({
                visible: true,
                name: "fileNotImported",
              });
            }
            // start importing data read
            else if (result?.data?.length > 0) {
              // check if there was an error importing the file
              if (result?.error?.length === 0) {
                let acumulatedInvitees = [];
                for (const [index, invitee] of result.data.entries()) {
                  console.log("valores", { acumulatedInvitees, invitee });

                  const objectToValidate = {
                    email: invitee.email,
                    firstName: invitee.firstName,
                    lastName: invitee.lastName,
                  };
                  await executeValidation(
                    objectToValidate,
                    invitee.id,
                    acumulatedInvitees,
                    index + 1, // current loop
                    result.data.length // total number of loops
                  );

                  console.log("validation executed");
                  acumulatedInvitees.push(invitee);
                }

                // loads the data
                setInviteeData(result.data);

                // close the modal
                setModalUserInvite({
                  visible: false,
                  name: "",
                });
              } else {
                console.log(
                  "%cThere was an error importing the file",
                  "background-color: red; color: white",
                  result.error
                );
                setInviteeData([]);
              }
            } else {
              console.log(
                "%cNo records in file",
                "background-color: red; color: white"
              );

              setModalUserInvite({
                visible: true,
                name: "fileEmpty",
              });
              setInviteeData([]);
            }
          } catch (error) {
            console.log("error", error);

            if (
              [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
              ].includes(file.type)
            ) {
              setModal({
                visible: true,
                name: "fileNotImported",
                data: { fileType: "Excel" },
              });
            } else {
              setModal({
                visible: true,
                name: "fileNotImported",
                data: { fileType: "csv" },
              });
            }
          }
        };

        reader.readAsText(file);
      };

      const handleInvite = () => {
        // setIsLoading(true);
        const inviteesWithoutErrors = inviteeData.reduce((acum, item) => {
          // search invitee that don't have errors
          const notFoundInErrors =
            errors.findIndex((errorItem) => errorItem.id === item.id) === -1;
          if (notFoundInErrors) {
            acum.push(item.email);
          }
          return acum;
        }, []);

        const options = axiosOptions({
          url: `${config.url}/api/invitation-send`,
          method: "post",
          data: {
            // emails: ["lau@yll.com"],
            emails: inviteesWithoutErrors,
            accountID: accountID,
            invitedBy: userName,
          },
        });

        axios(options)
          .then(async (response) => {
            console.log(" la response es", response);
            const { code } = response.data;

            switch (code) {
              case 0: // slots exceeded
                setShowSnackbar({
                  show: true,
                  messageType: "error",
                  message: "Invitations not sent.  Slots exceeded",
                });
                break;
              case 1: // new user suscriptions were created (can have emails excluded because already invited)
                // handleSendEmailsInvitations(emailToInvite);

                userQueryRef.current.refetch(); // updates the users' list
                // sendInfoToParent(); // updates the slots indicator in the header
                console.log("try to show snackbar");
                setShowSnackbar({
                  show: true,
                  messageType: "success",
                  message: "Invitations were sent",
                });

                break;
              case 2: // no user subscriptions (can have emails excluded because already invited)
                setShowSnackbar({
                  show: true,
                  messageType: "error",
                  message: "No new invitations were sent",
                });
                break;

              default:
                return null;
            }
            // console.log("axios response", response.data.info);
            // setIsLoading(false);
            setModal({ visible: false, name: "" });
            setModalUserInvite({ visible: false, name: "" });
          })
          .catch((error) => {
            console.log("error is:", error);
            // refetch();
            setIsLoading(false);
            setModal({ visible: false, name: "" });
            setModalUserInvite({ visible: false, name: "" });
            setShowSnackbar({
              show: true,
              messageType: "error",
              message:
                "There was an error.  Verify if all the emails were added.",
            });
            // return <ErrorHandler  error={error}    />;
          });
      };
      // const formRef = useRef();

      switch (modalUserInvite.name) {
        case "fileNotImported": {
          const mainText = () => {
            if (modal?.data?.fileType === "Excel") {
              return "An error ocurred importing Excel file.  Try converting to an older version or save your file as .csv";
            } else {
              return "An error ocurred.";
            }
          };
          return (
            <CustomDialog
              iconPreset="error"
              center
              // fullwidth
              visibleStatus={modal}
              setVisibleStatus={setModal}
              title="File not imported"
              mainText={mainText()}
              buttonCloseLabel="OK"
            />
          );
        }

        case "fileEmpty":
          return (
            <CustomDialog
              iconPreset="error"
              center
              visibleStatus={modalUserInvite}
              setVisibleStatus={setModalUserInvite}
              title="Empty file was imported"
              mainText="No records were found"
              buttonCloseLabel="OK"
            />
          );

        case "confirmInvitationSend":
          return (
            <CustomDialog
              iconPreset="question"
              visibleStatus={modalUserInvite}
              setVisibleStatus={setModalUserInvite}
              title="Do you want to send the invitations?"
              // mainText="D"
              buttonActionLabel1="No"
              // buttonActionColor1="danger"
              buttonActionFunction1={() => {
                setModalUserInvite({ visible: false, name: "" });
              }}
              buttonActionDisabled1={isLoading}
              buttonActionLabel2="Yes"
              buttonActionFunction2={() => handleInvite()}
              buttonActionLoading2={isLoading}
            />
          );

        case "fileFormatDetails":
          return (
            <CustomDialog
              center
              visibleStatus={modalUserInvite}
              setVisibleStatus={setModalUserInvite}
              title="File details"
              // mainText="1. File must have a minimum of 1 column corresponding to email"
              buttonActionLabel1="Cancel"
              // buttonActionColor1="danger"
              buttonActionFunction1={() => {
                setModalUserInvite({ visible: false, name: "" });
              }}
              otherDialogActions={
                <>
                  <input
                    // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    accept=".csv"
                    className={classes.inputFilePicker}
                    id="contained-button-file"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      color="primary"
                      // justIcon
                      // onClick={() => console.log("temp")}
                    >
                      Select file
                    </Button>
                  </label>
                </>
              }
            >
              <div>
                <h4 className={classes.fileDetailsLabel}>
                  1. Minimum of 1 column corresponding to email
                </h4>
                <h4 className={classes.fileDetailsLabel}>
                  2. Maximum of 3 columns corresponding to email, first name and
                  last name
                </h4>
                <h4 className={classes.fileDetailsLabel}>
                  3. Must be saved in a comma delimited format (csv)
                </h4>
              </div>
            </CustomDialog>
          );

        default:
          return null;
      }
    };

    // sendInfoToParent(attendees, () => fprops.handleSubmit);
    // formErrors(fprops.handleSubmit)
    return (
      <>
        <InviteeHeader />

        {/* <InviteeListWithAddBar /> */}
        <div className={classes.dialogInviteUsersContent}>
          {inviteeData?.map((invitee, index) => {
            // console.log("action info", action);

            const inviteeClasses = classNames({
              [classes.inviteeContainer]: true,
              [classes.inviteeContainerError]:
                errors.filter((item) => item.id === invitee.id).length > 0,
            });

            return (
              <div
                key={invitee.id}
                className={inviteeClasses}
                // className={`${classes.inviteeContainer}`}
                onMouseEnter={() =>
                  selectedInviteeID === invitee.id &&
                  setSelectedItemHovered(true)
                }
                onMouseLeave={() =>
                  selectedInviteeID === invitee.id &&
                  setSelectedItemHovered(false)
                }
                onClick={(event) => {
                  // console.log("event", {
                  //   selectedmeetingActionId: selectedmeetingActionId,
                  //   meetingActionId: action.meetingActionId,
                  // });

                  setSelectedInviteeID(invitee.id);

                  // sendInfoToParent(action.meetingActionId);
                }}
              >
                {/* <pre>{JSON.stringify(selectedItemHovered, null, 2)}</pre> */}
                <ErrorBoundary
                  FallbackComponent={(error) => (
                    <LogErrorComponent
                      error={error}
                      data={{
                        location: "Invitee list line",
                        invitee: invitee,
                      }}
                    />
                  )}
                >
                  <GridContainer
                    // spacing={0}
                    direction="row"
                    classes={{ container: classes.gridContainer }}
                    alignItems="flex-start"
                    // justifyContent="center"
                  >
                    {/* -------------- email ---------------- */}
                    <GridItem xs={4}>
                      <CustomMainInput
                        color="transparent"
                        placeholder="Enter email"
                        error={
                          errors.filter(
                            (item) =>
                              item.id === invitee.id &&
                              item.fieldName === "email"
                          )[0]?.message
                        }
                        // value={invitee.email}
                        removeHover={!selectedItemHovered} // remove the input hover to allow line hover
                        onChange={async (event) => {
                          console.log(
                            "%cCahnge",
                            "background-color: red; color: white",
                            event.target.value
                          );

                          updateField(
                            index,
                            {
                              email: event.target.value,
                            },
                            true // validates
                          );
                        }}
                        value={invitee.email}
                        variant="input"
                      />
                    </GridItem>

                    {/* -------------- first name ---------------- */}
                    <GridItem xs={3}>
                      <CustomMainInput
                        color="transparent"
                        error={
                          errors.filter(
                            (item) =>
                              item.id === invitee.id &&
                              item.fieldName === "firstName"
                          )[0]?.message
                        }
                        placeholder="Enter first name"
                        // height="80px"
                        onChange={(event) => {
                          updateField(
                            index,
                            {
                              firstName: event.target.value,
                            },
                            true // validates
                          );
                        }}
                        removeHover={!selectedItemHovered} // remove the input hover to allow line hover
                        value={invitee.firstName}
                        variant="input"
                      />
                    </GridItem>

                    {/* -------------- last name ---------------- */}
                    <GridItem xs={3}>
                      <CustomMainInput
                        color="transparent"
                        error={
                          errors.filter(
                            (item) =>
                              item.id === invitee.id &&
                              item.fieldName === "lastName"
                          )[0]?.message
                        }
                        placeholder="Enter last name"
                        // height="80px"
                        removeHover={!selectedItemHovered} // remove the input hover to allow line hover
                        onChange={(event) => {
                          updateField(
                            index,
                            {
                              lastName: event.target.value,
                            },
                            true // validates
                          );
                        }}
                        value={invitee.lastName}
                        variant="input"
                      />
                    </GridItem>

                    {/* -------------- buttons ---------------- */}
                    <GridItem
                      xs={2}
                      row
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        // disabled={!isOrganizer || [5].includes(statusID)}
                        size="sm"
                        color="danger"
                        justIcon
                        onClick={async () => {
                          updateField(
                            index,
                            {}, // providing empty object will trigger delete
                            true // validates
                          );

                          // console.log("delete", index);
                          // const newArray = [...inviteeData];
                          // newArray.splice(index, 1);
                          // setInviteeData(newArray);

                          // let acumulatedInvitees = [];
                          // for (const [index, invitee] of newArray.entries()) {
                          //   console.log("valores", {
                          //     acumulatedInvitees,
                          //     invitee,
                          //   });

                          //   const objectToValidate = {
                          //     email: invitee.email,
                          //     firstName: invitee.firstName,
                          //     lastName: invitee.lastName,
                          //   };
                          //   await executeValidation(
                          //     objectToValidate,
                          //     invitee.id,
                          //     acumulatedInvitees,
                          //     index + 1, // current loop
                          //     newArray.length // total number of loops
                          //   );

                          //   console.log("validation executed");
                          //   acumulatedInvitees.push(invitee);
                          // }
                        }}
                      >
                        <DeleteOutlineIcon />
                        {/* <Hidden mdUp implementation="css">
  <p className={classes.linkText}>Perfile</p>
</Hidden> */}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </ErrorBoundary>
              </div>
            );
          })}
        </div>
        <InviteeAddBar />

        <ButtonsContainer />
        {/* {modalConfirmInvitationsSend()} */}

        {/* <Button color="primary" onClick={() => refetch()}>
              Rrfrescar
            </Button> */}
        {/* <pre>
          {JSON.stringify(
            {
              // values: inviteeData,
              errors: errors,
            },
            null,
            2
          )}
        </pre> */}
        <ModalToShowUserInvite />
      </>
    );
  };

  return <InviteUserContent />;
}
