// styles
import { makeStyles } from "@material-ui/core/styles";
// MUI components
import Tooltip from "@material-ui/core/Tooltip";
import CustomAvatar from "./CustomAvatar";
// components

// icons
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Remove from "@material-ui/icons/Remove";
// style
// import styles from "assets/jss/material-dashboard-react/components/avatarGroupStyle";
import {
  dangerColor,
  grayColor,
  successColor,
} from "assets/jss/material-dashboard-react";

const styles = {
  avatarGroup: { flexDirection: "row", display: "flex", marginLeft: "9px" },

  // avatar: {
  //   borderColor: "white",
  //   borderWidth: "2px",
  //   borderStyle: "solid",
  //   height: "35px",
  //   width: "35px",
  // },
  counterContainer: {
    borderRadius: "100px",
    backgroundColor: "white",
    borderColor: grayColor[5],
    borderWidth: "1px",
    borderStyle: "solid",
    marginLeft: "-13px",
    zIndex: 1,
    paddingLeft: "8px",
    paddingRight: "8px",
    margin: "0px",
    alignItems: "center",
    display: "flex",
  },
  counterLabel: {
    margin: 0,
    fontWeight: 600,
  },
  iconCheckContainer: {
    height: "20px",
    borderRadius: "80px",
    padding: "3px",
    display: "flex",
    justifyContent: "center",
  },

  iconCheckContainerTrue: {
    backgroundColor: successColor[3],
  },
  iconCheckContainerFalse: {
    backgroundColor: dangerColor[3],
  },
  iconCheckContainerNull: {
    backgroundColor: grayColor[6],
  },
  nameLabel: { marginRight: "5px" },
  tooltipRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2px 0px 2px 0px",
  },
};
const useStyles = makeStyles(styles);

export default function AvatarGroup(props) {
  const { persons, maxIcons, summary, iconSize = "lg" } = props;
  const classes = useStyles();
  // console.log("presons", persons);
  const personsConfirmed =
    persons == null ? [] : persons.filter((item) => item.willAttend === true);

  const AttendanceTooltip = () => {
    const list = persons.map((item) => {
      return (
        <div key={item.id} className={classes.tooltipRow}>
          <h6 className={classes.nameLabel}>{item.name}</h6>
          <div
            className={[
              classes.iconCheckContainer,
              {
                true: classes.iconCheckContainerTrue,
                false: classes.iconCheckContainerFalse,
                null: classes.iconCheckContainerNull,
              }[item.willAttend] || null,
            ].join(" ")}
          >
            {{
              true: <Check style={{ fontSize: 14, color: successColor[1] }} />,
              false: <Clear style={{ fontSize: 14, color: dangerColor[0] }} />,
              null: <Remove style={{ fontSize: 14, color: grayColor[4] }} />,
            }[item.willAttend] || null}
          </div>
        </div>
      );
    });

    console.log("list", list);
    return list;
  };

  return (
    <div className={classes.avatarGroup}>
      {persons !== null &&
        persons.map((person, index) => {
          if (index < maxIcons) {
            return (
              <CustomAvatar key={person.id} person={person} size={iconSize} />
            );
            // return (
            //   <div key={person.id} className={classes.avatarContainer}>
            //     <Tooltip title={person.name} placement="bottom">
            //       <Avatar
            //         className={classes.avatar}
            //         alt={person.name || person.desc}
            //         src={person.imagePath}
            //       />
            //     </Tooltip>
            //   </div>
            // );
          }
        })}
      {summary && (
        <Tooltip
          title={persons === null ? "" : <AttendanceTooltip />}
          placement="bottom"
        >
          <div className={classes.counterContainer}>
            <h6 className={classes.counterLabel}>
              {personsConfirmed.length}/{(persons || []).length}
            </h6>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
