import React, { useState, useContext } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";

// assets
import sound1 from "../../assets/sounds/alert1.wav";
import sound2 from "../../assets/sounds/alert2.wav";

// data
// core components
import CardHeader from "components/Card/CardHeader.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import DateRangeComp from "components/DateRange";
import CustomPopper from "components/CustomPopper";
import CustomPopover from "components/CustomPopover";
// error
import { logError } from "functions/ErrorBoundary";
// icons
import SortIcon from "@material-ui/icons/Sort";
import FeatherIcon from "feather-icons-react";
import Search from "@material-ui/icons/Search";
import ImportExportIcon from "@material-ui/icons/ImportExport";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useCountdown, useThrowAsyncError } from "functions/Hooks";

// styles
import styles from "assets/jss/material-dashboard-react/components/customHeaderStyle";
import { formatCurrency } from "functions/Common";
import { Button, Menu, MenuItem, Popover } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function CustomHeader(props) {
  // console.log("----starts custom header---", props);
  // const { meeting, setMeeting } = useContext(MeetingContext);
  // const { statusID, ratingElements, canViewRating } = meeting;
  // const meetingStatus = "new"; // "in process"
  // const elementsCompleted = [false, false, false, false];
  // const tabNameToIndex = {
  //   general: 0,
  //   subscription: 1,
  //   invoices: 2,
  // };

  const [mustHave, setMustHave] = useState({ started: false, ended: false });

  const classes = useStyles();
  const {
    checkInCode,
    cost,
    dates,
    dateRange,
    endDate,
    filterPopper,
    headerColor,
    headerVariant,
    leftAlignItems,
    menuList,
    onChangeTab,
    onClosePopper,
    plainTabs,
    rightAlignItems,
    rtlActive,
    tabSelected,
    sendInfoToParent,
    showTimeCountDown,
    sortPopper,
    statusID,
    startDate,
    tabs,
    title,
  } = props;

  const parametersSelected = props.parametersSelected || {
    meetingGroupID: 0,
    filterID: 0,
    orderByID: 0,
    searchTerm: "",
  };
  const groupList = props.groupList || [];
  const oneOnOneList = props.oneOnOneList || [];

  const handleChange = (event, value) => {
    // console.log("el nuevo tab", value);
    // setValue(value);
    onChangeTab(value);
  };

  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });
  const cardRight = classNames({
    [classes.cardRight]: true,
  });

  const headerVariantList = {
    // draft
    1: {
      // search: true,
      // view: true,
      // filter: true,
      // sort: true,

      cost: true,
      // checkInCode: false,
      // time: true,
      // menuButton: true,
      // rating: true,
      // showCompleted: false,
    },

    // sent
    2: {
      cost: true,
      checkInCode: true,
      time: true,
    },

    // ongoing
    3: {
      checkInCode: true,
      time: true,
    },

    // completed
    4: {
      cost: true,
      time: true,
      rating: true,
    },
    // cancelled
    5: {
      // cost: true,
    },
    6: {
      // cost: true,
    },

    // overtime
    7: {
      checkInCode: true,
      time: true,
    },

    // late
    8: {
      cost: true,
      checkInCode: true,
      time: true,
    },
    list: {
      search: true,
      // view: true,
      filter: true,
      sort: true,
      // oneOnOne: true,
      // dateRange: true,
    },
    actions: {
      search: true,
      // view: true,
      filter: true,
      sort: true,
      // oneOnOne: true,
      // dateRange: true,
    },
    todo: {
      search: true,
      menuButton: true,
      // view: true,
      // filter: true,
      // sort: true,
    },
    dashboard: {
      dateRange: true,
    },
    clean: {},
    null: {},
  };

  const variant = headerVariantList[headerVariant];

  const popperContainerClasses = classNames({
    [classes.popperMainContainer]: true,
    [classes.popperMainContainerFiltered]: false,
    [classes["primary"]]: "primary",
  });

  const popperIconContainerClasses = classNames({
    [classes.popperIconContainer]: true,
    // [classes.iconOnlyContainer]: value === undefined,
    [classes["primary"]]: "primary",
  });

  const TabsMenu = () => {
    if (tabs !== undefined) {
      return (
        tabs.length > 0 && (
          <Tabs
            id="tourTabsRoot"
            value={tabSelected || 0}
            onChange={handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayNone,
              scrollButtons: classes.displayNone,
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((prop, key) => {
              let icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />,
                };
              }
              return (
                <Tab
                  id={prop.tabName.replace(/ /g, "")} //remove spaces. e.g. Parking lot becomes ParkingLot
                  classes={{
                    root: classes.tabRootButton,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            })}
          </Tabs>
        )
      );
    }
  };

  const sortMenu = () => {
    const [sortSelected, setSortSelected] = useState(1);

    const sortList = [
      {
        id: 1,
        buttonDesc: "Sort A to Z",
        menuDesc: "Sort A to Z",
        dividingLineBelow: false,
        click: () => {
          setSortSelected(1);
        },
      },
      {
        id: 2,
        buttonDesc: "Sort Z to A",
        menuDesc: "Sort Z to A",
        dividingLineBelow: false,
        click: () => {
          setSortSelected(2);
        },
      },
    ];

    // const sortListMinusSelected = sortList.filter((item) => {
    //   if (item.id !== sortSelected) {
    //     return item;
    //   }
    // });

    // extracts the button description from the selected menu item
    const index = sortList.findIndex((item) => item.id === sortSelected);
    const itemSelected = sortList[index];

    // return (
    //   <CustomDropDown
    //     items={sortList}
    //     title={buttonTitle}
    //     wired={true}
    //     startIcon={<SortIcon />}
    //   />
    // );

    return (
      <CustomMainInput
        color="primary"
        dropDownList={sortList}
        // dropDownTitle={dropDownTitle}
        value={itemSelected.id}
        iconStart={<SortIcon />}
        variant="dropdown"
      />
    );
  };

  const filterMenu = () => {
    const [filterSelected, setFilterSelected] = useState(
      parametersSelected.filterID
    );

    const filterList = [
      {
        id: 0,
        buttonDesc: "Show all",
        menuDesc: "Show all",
        dividingLineBelow: false,
        click: () => {
          setFilterSelected(1);
        },
      },
      {
        id: 1,
        buttonDesc: "Last 30 days",
        menuDesc: "Last 30 days",
        dividingLineBelow: false,
        click: () => {
          setFilterSelected(2);
        },
      },
      {
        id: 2,
        buttonDesc: "Last 90 days",
        menuDesc: "Last 90 days",
        dividingLineBelow: false,
        click: () => {
          setFilterSelected(3);
        },
      },
    ];

    // extracts the button description from the selected menu item
    const index = filterList.findIndex(
      (item) => item.id === parametersSelected.filterID
    );
    const itemSelected = filterList[index];

    return (
      <CustomMainInput
        color="primary"
        dropDownList={filterList}
        filtered={itemSelected.id !== 0} // mark as filtered if not All selected
        iconStart={<FeatherIcon size="20" icon="filter" />}
        value={itemSelected.id}
        variant="dropdown"
        dropDownFunction={(item) => {
          console.log("se actualiza el item seleccionado", item);

          sendInfoToParent({ parameter: "dateRange", value: item.id });
        }}
      />
    );
  };

  const filterPopperCustom = () => {
    return (
      <CustomPopover
        onClose={onClosePopper}
        triggerComponent={(handleOpen) => (
          <div
            style={{
              // maxWidth: "100%",
              width: "100%",
              height: "100%", // substracts 18px which is the height of the label
              // pointerEvents: disabled ? "none" : "auto",
            }}
            className={popperContainerClasses}
            onClick={handleOpen}

            // deleteIcon={}
          >
            <div className={popperIconContainerClasses}>
              <FeatherIcon size="20" icon="filter" />
            </div>
          </div>
        )}
      >
        {filterPopper}
      </CustomPopover>
    );
  };

  const groupMenu = () => {
    // console.log(
    //   "%cGroupList",
    //   "background-color: red; color: white",
    //   groupList
    // );
    // extracts the button description from the selected menu item
    const index = groupList.findIndex(
      (item) => item.id === parametersSelected.meetingGroupID
    );
    // console.log("index", index);

    const itemSelected = groupList[index] || { id: 0, menuDesc: "" };
    // console.log("itemSelected", itemSelected);

    return (
      <CustomMainInput
        color="primary"
        // canEdit
        filtered={itemSelected.id !== 0} // mark as filtered if not All selected
        dropDownList={groupList}
        iconStart={<FeatherIcon icon="eye" />}
        value={itemSelected.id}
        variant="dropdown"
        dropDownFunction={(item) => {
          console.log("se actualiza el item seleccionado", item);

          sendInfoToParent({ parameter: "group", value: item.id });
        }}
      />
    );
  };

  const oneOnOneMenu = () => {
    // console.log(
    //   "%cOneOneList",
    //   "background-color: red; color: white",
    //   oneOnOneList
    // );

    // extracts the button description from the selected menu item
    const index = oneOnOneList.findIndex(
      (item) => item.id === parametersSelected.oneOnOneUserID
    );
    // console.log("index", index);

    const itemSelected = oneOnOneList[index] || { id: 0, menuDesc: "" };
    // console.log("itemSelected", itemSelected);

    return (
      <CustomMainInput
        color="primary"
        // canEdit
        filtered={itemSelected.id !== 0} // mark as filtered if not All selected
        dropDownList={oneOnOneList}
        iconStart={<h3 style={{ fontWeight: 500, margin: "0px" }}>1:1</h3>}
        value={itemSelected.id}
        variant="dropdown"
        dropDownFunction={(item) => {
          console.log("se actualiza el item seleccionado", item);

          sendInfoToParent({ parameter: "1on1", value: item.id });
        }}
      />
    );
  };

  const searchInput = () => {
    // console.log(
    //   "parametersSelected en el search input",
    //   parametersSelected === undefined ? "" : parametersSelected.searchTerm
    // );

    return (
      <CustomMainInput
        // key="prueba"
        autoFocus
        // filtered={parametersSelected?.searchTerm?.length > 0} // mark as filtered if not All selected
        width="600px"
        // label="Date"
        // name="search"
        color="primary"
        // height="40px"
        placeholder="Search"
        // width="500px"
        iconStart={<Search />}
        onChange={(info) => {
          console.log("search on change", info);
          // setSearch(info.target.value);
          sendInfoToParent({ parameter: "search", value: info.target.value });
        }}
        // onBlur={fprops.handleBlur("date")}
        value={
          parametersSelected === undefined ? "" : parametersSelected.searchTerm
        }
        variant="input"
      />
    );
  };

  const menuButton = () => {
    return (
      <CustomMainInput
        color="primary"
        // simple
        // wired
        justIcon
        iconStart={<MoreHorizIcon />}
        dropDownList={menuList}
        // dropDownTitle="i"
        // value={null}
        variant="dropdown"
      />
    );
  };

  const costIndicator = () => {
    return (
      <div className={classes.checkInContainer}>
        <div className={classes.checkInCodeIndicator}>
          {formatCurrency.format(cost)}
          <h6 className={classes.checkInLabel}>Cost</h6>
        </div>
      </div>
    );
  };

  const checkInCodeIndicator = () => {
    return (
      <div className={classes.checkInContainer}>
        <h4 className={classes.checkInCodeIndicator}>{checkInCode}</h4>
        <h6 className={classes.checkInLabel}>Check in code</h6>
      </div>
    );
  };

  const TimeIndicator = () => {
    const countDownValues = useCountdown(startDate);
    const countDownValuesEnd = useCountdown(endDate);
    const countdownDisplay = (countDownValues) => {
      const DateTimeDisplay = ({ value, desc, alert }) => {
        const indicatorClasses = classNames({
          [classes.countDownIndicatorLabel]: true,
          [classes.countDownAlert]: alert,
        });
        const typeClasses = classNames({
          [classes.countDownTypeLabel]: true,
          [classes.countDownAlert]: alert,
        });

        return (
          <div className={classes.countDownDescContainer}>
            <h5 className={indicatorClasses}>{Math.abs(value)}</h5>
            <h6 className={typeClasses}>{desc}</h6>
          </div>
        );
      };
      const alertMode = countDownValues[0].value < 0;

      const containerClasses = classNames({
        [classes.countDownMain]: true,
        [classes.countDownAlertBackground]: alertMode,
      });

      const startLabelClasses = classNames({
        [classes.countDownStartLabel]: true,
        [classes.countDownAlert]: alertMode,
      });

      const totalSeconds = countDownValues[0].value;
      // console.log("el total es", totalSeconds);

      // console.log("el valor es", item);
      let labelCountDown;

      if ([1, 2, 8].includes(statusID)) {
        // if meeting hasn't started yet
        if (totalSeconds >= 0) {
          labelCountDown = "Time to start:";
        } else {
          labelCountDown = "Time late:";
        }
      } else {
        // meeting has started
        if (totalSeconds >= 0) {
          labelCountDown = "Time remaining:";
        } else {
          labelCountDown = "Time exceeded:";
        }
      }

      return (
        <div className={containerClasses}>
          <h6 className={startLabelClasses} style={{ lineHeight: "15px" }}>
            {/* {[1, 2].includes(statusID) ? "Starts in:" : "Elapsed:"} */}
            {labelCountDown}
            {/* {[1, 2].includes(statusID)
                ? shouldHaveStarted
                  ? "Overdue start:"
                  : "Starts in:"
                : "Time remaining:"} */}
          </h6>
          {countDownValues.map((item, index) => {
            return (
              <div key={index}>
                {item.desc !== "TOTAL" && (
                  <DateTimeDisplay
                    value={item.value}
                    desc={item.desc}
                    alert={countDownValues[0].value < 0}
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    };
    // console.log("--------countDownValues", countDownValuesEnd[0].value);
    if (countDownValues[0].value <= 0 && mustHave.started === false) {
      setMustHave({ ...mustHave, started: true });
      sendInfoToParent({ ...mustHave, started: true });
    }

    if (countDownValuesEnd[0].value <= 0 && mustHave.ended === false) {
      setMustHave({ ...mustHave, ended: true });
      sendInfoToParent({ ...mustHave, ended: true });
    }

    // play first sound when meeting end time is in 10 minutes
    if (countDownValuesEnd[0].value === 600) {
      new Audio(sound1).play().catch((error) => {
        logError(error, {
          errorLevel: "info",
        });
      });
    }

    // play second sound when meeting should end
    if (countDownValuesEnd[0].value === 0) {
      new Audio(sound2).play().catch((error) => {
        // logError(error, {
        //   errorLevel: "info",
        // });
      });
    }

    return [3, 7].includes(statusID)
      ? countdownDisplay(countDownValuesEnd)
      : [2, 8].includes(statusID)
      ? countdownDisplay(countDownValues)
      : null;
  };

  const dateRangeSelector = () => {
    return (
      <DateRangeComp
        startDate={dates.startDate}
        endDate={dates.endDate}
        sendInfoToParent={(item) => {
          console.log(
            "%cTriggered date",
            "background-color: yellow; color: red"
          );

          sendInfoToParent({
            parameter: "dateRange",
            startDate: item[0].startDate,
            endDate: item[0].endDate,
          });
        }}
      />
    );
  };

  return (
    // <Card plain={plainTabs}>
    <CardHeader color={headerColor} plain={plainTabs}>
      <div className={classes.mainContainer}>
        <div className={classes.cardLeft}>
          {title !== undefined ? (
            <div className={cardTitle}>{title}</div>
          ) : null}
          {<TabsMenu />}
          {variant && variant.search && searchInput()}
        </div>

        <div id="tourIndicators" className={cardRight}>
          {/* {filterPopper !== undefined && filterPopper} */}
          {/* {sortPopper !== undefined && sortPopper} */}
          {leftAlignItems}
          {variant && variant.filter && filterPopper}
          {variant && variant.sort && sortPopper}
          {variant && variant.oneOnOne && oneOnOneMenu()}
          {variant && variant.view && groupMenu()}
          {/* {variant && variant.filter && filterMenu()} */}
          {/* {variant && variant.sort && sortMenu()} */}
          {variant && variant.time && showTimeCountDown === true && (
            <TimeIndicator /> // must be rendered as component and not function.  Otherwise generates a hook order error
          )}
          {variant &&
            variant.checkInCode &&
            checkInCode !== null &&
            checkInCodeIndicator()}
          {variant && variant.cost && cost !== null && costIndicator()}
          {variant &&
            variant.dateRange &&
            dateRange !== null &&
            dateRangeSelector()}

          {/* {variant && variant.showCompleted && </>} */}

          {rightAlignItems}

          {variant && variant.menuButton && menuButton()}

          {/* {hasRoutes && (
          <div className={classes.RoutesGroup}>
            <div className={classes.RoutesTitle}>{RoutesTitle}</div>
            <CustomSwitch checked={RoutesStatus} onChange={RoutesChange} />
          </div>
        )} */}
        </div>
      </div>
    </CardHeader>
    //   <CardBody fullscreen={fullscreen}>
    //     {tabs.map((prop, key) => {
    //       if (key === value) {
    //         return <div key={key}>{prop.tabContent}</div>;
    //       }
    //       return null;
    //     })}
    //   </CardBody>
    // </Card>
  );
}

CustomHeader.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
    "white",
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
  RoutesStatus: PropTypes.bool,
  hasRoutes: PropTypes.bool,
  titleRight: PropTypes.string,
  RoutesTitle: PropTypes.string,
  fullscreen: PropTypes.bool,
};
