import { gql } from "@apollo/client";

export const usersSubscription = gql`
  query usersSubscription($ownerID: Int!, $pageSize: Int!, $cursor: Cursor) {
    spUsersSubscription(ownerId1: $ownerID, first: $pageSize, after: $cursor) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        name
        email
        hasAccess
        userId
        accountId
        useAsMain
        isOwner
        isUser
      }
    }
    spEmailsLeftToSend(userId1: $ownerID) {
      nodes {
        availableDay
        availableMonth
        maxDay
      }
    }
  }
`;

export const invitationsValidation = gql`
  query invitationsValidation($accountID: Int, $emails: [String]) {
    spInvitationsValidation(accountId1: $accountID, email1: $emails) {
      nodes {
        email
        hasAccess
      }
    }
  }
`;

// export const addAccess = gql`
//   mutation addUser($accountID: Int!, $email: String!) {
//     spUsersSubscriptionAdd(input: { email1: $email, accountId1: $accountID }) {
//       results {
//         responseCode
//         responseDesc
//       }
//     }
//   }
// `;

// export const cancelAccess = gql`
//   mutation cancelAccess($accountID: Int!, $email: String!) {
//     spUsersSubscriptionClose(
//       input: { email1: $email, accountId1: $accountID }
//     ) {
//       clientMutationId
//     }
//   }
// `;

export const changeAccess = gql`
  mutation changeAccess($accountID: Int!, $email: String!) {
    spUsersSubscriptionChangeAccess(
      input: { email1: $email, accountId1: $accountID }
    ) {
      clientMutationId
      results {
        code
        desc
        userId
      }
    }
  }
`;
