import {
  grayColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  whiteColor,
  blackColor,
  hexToRgb,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";

const buttonStyle = {
  button: {
    ...defaultFont,

    minHeight: "auto",
    minWidth: "auto",
    overflow: "clip",
    overflowHiddenMargin: "5px",
    // height: "100%",
    backgroundColor: grayColor[5],
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.12)",
    border: "none",
    borderRadius: "3px",
    position: "relative",
    padding: "0px 15px",
    // padding: "8px",
    // margin: ".2rem",
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "none",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    // whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: grayColor[3],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(grayColor[3]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(grayColor[3]) +
        ", 0.2)",
    },
    "& .fab,& .fas,& .far,& .fal, &.material-icons": {
      position: "relative",
      display: "inline-block",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle",
    },

    // "& svg": {
    //   position: "relative",
    //   display: "inline-block",
    //   top: "0",
    //   width: "22px",
    //   height: "22px",
    //   // marginRight: "4px",
    //   verticalAlign: "middle",
    // },
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        marginTop: "0px",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0px",
        top: "0px",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
      },
    },
  },
  link: {
    // "&,&:hover,&:focus": {
    backgroundColor: "transparent",
    color: "red", //grayColor[3],
    boxShadow: "none",
    "&$xs,&$sm,&$sm,&$md,&$lg": {
      padding: "0px",
    },

    "&$primary": {
      color: primaryColor[4],
      backgroundColor: "transparent",
      boxShadow: "none",
      // "&,&:focus,&:hover,&:visited": {
      "&:hover,&:focus": {
        color: primaryColor[4],
        backgroundColor: "transparent",
        boxShadow: "none",
        textDecoration: "underline",
      },
    },

    // "&:hover,&:focus": {
    //   backgroundColor: "transparent",
    //   // color: grayColor[3],
    //   boxShadow: "none",
    //   // padding: "0px",
    //   textDecoration: "underline",

    //   // "&$simple": {
    //   //   borderColor: roseColor[0],
    //   //   color: roseColor[0],
    //   //   "&:hover": {
    //   //     color: whiteColor,
    //   //     backgroundColor: roseColor[2],
    //   //     borderColor: roseColor[2],
    //   //   },
    //   // },
    // },
  },

  // label: {
  //   "&$sm": {
  //     padding: "4px 0px",
  //   },
  //   padding: "8px 0px",
  // },
  leftAlign: { justifyContent: "flex-start", textAlign: "left" },
  rightAlign: { justifyContent: "flex-end", textAlign: "right" },
  white: {
    "&,&:focus,&:hover": {
      backgroundColor: whiteColor,
      color: grayColor[3],
    },
  },
  rose: {
    backgroundColor: roseColor[0],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: roseColor[0],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.2)",
    },
  },
  primary: {
    backgroundColor: primaryColor[4],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(primaryColor[4]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(primaryColor[4]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(primaryColor[4]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: primaryColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(primaryColor[4]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(primaryColor[4]) +
        ", 0.2)",
    },
  },
  gray: {
    backgroundColor: grayColor[4],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[4]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[4]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[4]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: grayColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(grayColor[4]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(grayColor[4]) +
        ", 0.2)",
    },
  },
  info: {
    backgroundColor: infoColor[4],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(infoColor[4]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(infoColor[4]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(infoColor[4]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: infoColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(infoColor[4]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(infoColor[4]) +
        ", 0.2)",
    },
  },
  success: {
    backgroundColor: successColor[1],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(successColor[1]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(successColor[1]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(successColor[1]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: successColor[1],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(successColor[1]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(successColor[1]) +
        ", 0.2)",
    },
  },
  warning: {
    backgroundColor: warningColor[0],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: warningColor[0],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.2)",
    },
  },
  danger: {
    backgroundColor: dangerColor[1],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(dangerColor[1]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(dangerColor[1]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(dangerColor[1]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: dangerColor[1],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[1]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[1]) +
        ", 0.2)",
    },
  },
  simple: {
    "&,&:focus,&:hover,&:visited": {
      color: whiteColor,
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&$rose": {
      "&,&:focus,&:hover,&:visited": {
        color: roseColor[0],
      },
    },
    "&$primary": {
      color: primaryColor[4],

      // "&,&:focus,&:hover,&:visited": {
      "&:hover": {
        // color: primaryColor[4],
        color: primaryColor[4],
        // fontWeight: 600,
        backgroundColor: primaryColor[8],
      },
    },
    "&$gray": {
      color: grayColor[1],

      // "&,&:focus,&:hover,&:visited": {
      "&:hover": {
        // color: primaryColor[4],
        color: grayColor[1],
        // fontWeight: 600,
        backgroundColor: grayColor[7],
      },
    },

    "&$info": {
      "&,&:focus,&:hover,&:visited": {
        color: infoColor[4],
      },
    },
    "&$success": {
      "&,&:focus,&:hover,&:visited": {
        color: successColor[1],
      },
    },
    "&$warning": {
      "&,&:focus,&:hover,&:visited": {
        color: warningColor[0],
      },
    },
    "&$danger": {
      "&,&:focus,&:hover,&:visited": {
        color: dangerColor[1],
      },
    },
    "&$disabled": {
      color: grayColor[4],
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },

  colored: {
    boxShadow: "none",
    fontWeight: 700,
    "&$primary": {
      color: primaryColor[1],
      backgroundColor: primaryColor[7],
      "&:hover,&:focus": {
        // backgroundColor: primaryColor[4],
        boxShadow:
          "0 14px 26px -12px rgba(" +
          hexToRgb(primaryColor[4]) +
          ", 0.42), 0 4px 23px 0px rgba(" +
          hexToRgb(blackColor) +
          ", 0.12), 0 8px 10px -5px rgba(" +
          hexToRgb(primaryColor[4]) +
          ", 0.2)",
      },
    },
    "&$gray": {
      color: grayColor[1],
      backgroundColor: grayColor[6],
      "&:hover,&:focus": {
        // backgroundColor: grayColor[4],
        boxShadow:
          "0 14px 26px -12px rgba(" +
          hexToRgb(grayColor[4]) +
          ", 0.42), 0 4px 23px 0px rgba(" +
          hexToRgb(blackColor) +
          ", 0.12), 0 8px 10px -5px rgba(" +
          hexToRgb(grayColor[4]) +
          ", 0.2)",
      },
    },

    "&$success": {
      color: successColor[1],
      backgroundColor: successColor[5],
      "&:hover,&:focus": {
        // backgroundColor: successColor[4],
        boxShadow:
          "0 14px 26px -12px rgba(" +
          hexToRgb(successColor[4]) +
          ", 0.42), 0 4px 23px 0px rgba(" +
          hexToRgb(blackColor) +
          ", 0.12), 0 8px 10px -5px rgba(" +
          hexToRgb(successColor[4]) +
          ", 0.2)",
      },
    },

    "&$danger": {
      color: dangerColor[1],
      backgroundColor: dangerColor[5],
      "&:hover,&:focus": {
        // backgroundColor: dangerColor[4],
        boxShadow:
          "0 14px 26px -12px rgba(" +
          hexToRgb(dangerColor[4]) +
          ", 0.42), 0 4px 23px 0px rgba(" +
          hexToRgb(blackColor) +
          ", 0.12), 0 8px 10px -5px rgba(" +
          hexToRgb(dangerColor[4]) +
          ", 0.2)",
      },
    },
    // "&$disabled": {
    //   color: grayColor[4],
    //   backgroundColor: "transparent",
    //   boxShadow: "none",
    // },
  },

  transparent: {
    "&,&:focus,&:hover": {
      color: "inherit",
      background: grayColor[8],
      boxShadow: "none",
    },
  },
  test: {
    color: "red",
  },
  disabled: {
    pointerEvents: "none",
    backgroundColor: grayColor[5],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.12)",
  },
  lg: {
    padding: "0.8rem 1.1rem",
    fontSize: "1.1rem",
    // lineHeight: "1.333333",
    borderRadius: "0.2rem",
  },
  md: {
    padding: "0.7rem 1rem",
    fontSize: "0.9rem",
    // lineHeight: "1.333333",
    borderRadius: "0.2rem",
  },
  sm: {
    padding: "0.5rem 0.8rem", // "0.40625rem 1.25rem",
    fontSize: "0.8rem",
    // lineHeight: "1.5",
    borderRadius: "0.2rem",
  },
  xs: {
    padding: "0.4rem", // "0.40625rem 1.25rem",
    fontSize: "0.7rem",
    lineHeight: "14px",
    borderRadius: "0.2rem",
  },
  round: {
    borderRadius: "30px",
  },
  block: {
    width: "100% !important",
    borderColor: "red",
    borderWidth: "3px",
  },

  wired: {
    backgroundColor: "red",
    borderWidth: "1px",
    borderStyle: "solid",
    "&,&:focus,&:hover": {
      // color: whiteColor,
      background: "transparent",
      boxShadow: "none",
    },
    "&$rose": {
      borderColor: roseColor[0],
      color: roseColor[0],
      "&:hover": {
        color: whiteColor,
        backgroundColor: roseColor[2],
        borderColor: roseColor[2],
      },
    },
    "&$primary": {
      borderColor: primaryColor[6],
      color: primaryColor[4],
      "&:hover": {
        color: whiteColor,
        backgroundColor: primaryColor[4],
        borderColor: primaryColor[4],
      },
    },
    "&$info": {
      borderColor: infoColor[4],
      color: infoColor[4],
      "&:hover": {
        color: whiteColor,
        backgroundColor: infoColor[2],
        borderColor: infoColor[2],
      },
    },
    "&$success": {
      borderColor: successColor[1],
      color: successColor[1],
      "&:hover": {
        color: whiteColor,
        backgroundColor: successColor[1],
        borderColor: successColor[1],
      },
    },
    "&$warning": {
      borderColor: warningColor[0],
      color: warningColor[0],
      "&:hover": {
        color: whiteColor,
        backgroundColor: warningColor[2],
        borderColor: warningColor[2],
      },
    },
    "&$danger": {
      borderColor: dangerColor[1],
      color: dangerColor[1],
      "&:hover": {
        color: whiteColor,
        backgroundColor: dangerColor[1],
        borderColor: dangerColor[1],
      },
    },
    "&$gray": {
      borderColor: grayColor[5],
      color: grayColor[5],
      "&:hover": {
        color: whiteColor,
        backgroundColor: grayColor[5],
        borderColor: grayColor[5],
      },
    },
  },

  justIcon: {
    // paddingLeft: "2px",
    // paddingRight: "2px",
    padding: "5px",
    fontSize: "20px",
    // height: "15px",
    backgroundColor: "rgba(0,0,0,0)",
    boxShadow: "none",
    "&$close": {
      backgroundColor: "red",
      // height: "10px",
      // width: "10px",
      borderRadius: "20px",
    },

    "&$primary": {
      color: primaryColor[6],
      "&:hover,&:focus": {
        color: primaryColor[4],
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
      },
      "&$disabled": {
        color: grayColor[4],
      },
    },
    "&$danger": {
      color: dangerColor[3],
      "&:hover,&:focus": {
        color: dangerColor[1],
        backgroundColor: "rgba(0,0,0,0)",

        boxShadow: "none",
      },
      "&$disabled": {
        color: grayColor[4],
      },
    },
    "&$white": {
      color: whiteColor, //grayColor[6],
      "&:hover,&:focus": {
        color: grayColor[4], //"white",
        backgroundColor: "rgba(0,0,0,0)",

        boxShadow: "none",
      },
      "&$disabled": {
        color: grayColor[4],
      },
    },
    "&$gray": {
      color: grayColor[5],
      "&:hover,&:focus": {
        color: grayColor[2],
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
      },
    },
    "&$info": {
      // color: infoColor[6],

      "&:hover,&:focus": {
        color: infoColor[4],
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    },

    // minWidth: "41px",
    // width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px",
    },
    "&$lg": {
      height: "57px",
      minWidth: "57px",
      width: "57px",
      lineHeight: "56px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "32px",
        lineHeight: "56px",
      },
      "& svg": {
        width: "30px",
        height: "30px",
      },
    },
    "&$md": {
      height: "40px",
      minWidth: "40px",
      width: "40px",
      lineHeight: "22px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "22x",
        lineHeight: "22px",
      },
      "& svg": {
        width: "22px",
        height: "22px",
      },
    },
    "&$sm": {
      height: "30px",
      minWidth: "30px",
      width: "30px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "17px",
        lineHeight: "29px",
      },
      "& svg": {
        width: "17px",
        height: "17px",
      },
    },
    "&$xs": {
      height: "20px",
      minWidth: "20px",
      width: "20px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "14px",
        lineHeight: "22px",
      },
      "& svg": {
        width: "14px",
        height: "14px",
      },
    },
    "&$disabled": {
      color: grayColor[5],
      backgroundColor: "transparent",
    },
  },
  close: {
    // backgroundColor: "blue",
    // width: "20px",
    // height: "20px",
  },
  activityIndicator: {
    color: whiteColor,
  },
  rightSpacing: {
    marginRight: "5px",
  },
};

export default buttonStyle;
