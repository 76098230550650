import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";

const tableStyle = (theme) => ({
  addButton: {
    // marginBottom: 10,
  },
  actionButtonGroup: {
    display: "flex",
  },

  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[4],
  },
  dangerTableHeader: {
    color: dangerColor[1],
  },
  successTableHeader: {
    color: successColor[1],
  },
  infoTableHeader: {
    color: infoColor[4],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[3],
  },
  noData: {
    // width: "50%",
    paddingLeft: "2%",
    paddingRight: "5%",
    // flex: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0",
    },
  },

  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
    // height: "83%",
  },
  // tableBody: {
  //   height: "200px",
  // },
  tableHeadCell: {
    color: primaryColor[4],
    ...defaultFont,
    fontSize: "0.9rem",
    fontWeight: 400,
    "& .MuiTableSortLabel-active": {
      fontWeight: 700,
      color: primaryColor[4],
    },
    padding: "5px 16px 5px 16px",
  },

  tableCell: {
    ...defaultFont,
    color: grayColor[2],
    // lineHeight: "1.42857143",
    // padding: "12px 8px",
    // padding: "2px 2px",
    verticalAlign: "middle",
    fontSize: "0.9rem",
    fontWeight: 400,
  },
  tableContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    flex: 1,
    // height: "75%",
    // maxHeight: "300px",
    // backgroundColor: "yellow",
    // overflowX: "auto",
    paddingRight: "10px", // space for the scrollbar
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing(1),
    // overflowX: "auto",
    height: "95%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",

    // backgroundColor: "lightBlue",
  },
  tableHeadRow: {
    height: "40px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableActionButton: {
    padding: 6,
  },
  pagination: {
    color: grayColor[2],
    "& .MuiTablePagination-caption": {
      ...defaultFont,
    },
  },
  subHeader: {
    display: "flex",
    // alignItems: "flex-end",
    alignItems: "center",
    paddingBottom: "10px",
  },
  searchWrapper: {
    // backgroundColor: "yellow",
    display: "flex",
    // height: "58px",
    width: "50%",
    flex: 1,
    minWidth: "10rem",
    paddingRight: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0",
    },
  },

  searchIcon: {
    // marginTop: 5,
    color: grayColor[3],
    marginRight: 5,
  },

  prueba: {
    padding: 2,
  },
});

export default tableStyle;
