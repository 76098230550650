import { gql } from "@apollo/client";

export const ticketDetails = gql`
  query ticketDetails($ticketID: Int, $languageID: String!) {
    allDropDowns: allLanguages(condition: { langShort: $languageID }) {
      nodes {
        priorities: translationHelpdeskPrioritiesByLanguageId {
          nodes {
            id: helpdeskPriorityId
            menuDesc: desc
          }
        }
        categories: translationHelpdeskCategoriesByLanguageId {
          nodes {
            id: helpdeskCategoryId
            menuDesc: desc
          }
        }
      }
    }
    spHelpdeskTicketDetails(helpdeskId1: $ticketID) {
      nodes {
        ticketId
        subject
        priorityId
        conversation
        categoryId
        isClosed
        ownerId
      }
    }
  }
`;

export const ticketHistory = gql`
  mutation ticketHistory(
    $userID: Int
    $statusID: Int
    $ticketID: Int
    $comment: String
    $attachmentPaths: [String]
  ) {
    createHelpdeskHistory(
      input: {
        helpdeskHistory: {
          userId: $userID
          statusId: $statusID
          helpdeskId: $ticketID
          comment: $comment
          attachmentPaths: $attachmentPaths
        }
      }
    ) {
      clientMutationId
    }
  }
`;
