import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// core components
import CustomDialog from "components/CustomDialog/CustomDialog";
import Payment from "views/Payment/Payment";
import AccountInviteUsers from "./AccountInviteUsers";
import Invoice from "./AccountInvoice";
// data
// errors
import { AccountContext } from "contexts/AccountContext";
import { SessionContext } from "contexts/SessionContext";
import { SocketContext } from "contexts/SocketContext";
import { useDataForLog } from "functions/ErrorBoundary";

//icons

// styles
import { makeStyles } from "@material-ui/core/styles";
import {
  dangerColor,
  grayBoxShadow,
  grayColor,
  primaryColor,
} from "assets/jss/material-dashboard-react";

const styles = {
  addBarContainer: {
    borderBottom: `1px solid ${grayColor[6]}`,
    borderTop: `1px solid ${grayColor[6]}`,
    backgroundColor: primaryColor[9],
  },
  alignLeft: {},
  alignCenter: {},
  confirmButtonsContainer: {
    justifyContent: "space-around",
    display: "flex",
    margin: "20px 40px 0px 40px",
  },
  dialogInviteUsersContent: {
    display: "flex",
    flex: 1,
    overflowY: "auto",
    flexDirection: "column",
  },
  fileDetailsLabel: {
    color: grayColor[3],
    marginBottom: 15,
  },
  gridContainer: {
    flex: 1,
    alignItems: "flex-start",
    // borderBottom: `1px solid ${grayColor[7]}`,
  },
  header: {},
  headerContainer: {
    ...grayBoxShadow,
    alignItems: "center",
    backgroundColor: "white",
    // backgroundColor: grayColor[8],
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // marginBottom: "15px",
    padding: "5px 20px 0px 27px",
    transition: "all 600ms ease-in-out",
    borderBottom: `1px solid ${grayColor[6]}`,
  },
  inputFilePicker: {
    display: "none",
  },
  inviteeContainer: {
    // borderRadius: 5,
    // padding: "5px 5px 5px 10px",
    padding: "8px 20px 2px 20px",

    transition: "all 150ms ease 0s",
    margin: "0px 0px 1px 0px",
    borderBottom: `1px solid ${grayColor[6]}`,
  },
  inviteeContainerError: {
    backgroundColor: dangerColor[6],
  },
  inviteesWithErrorsLabel: {
    color: "red",
    textAlign: "center",
    margin: 0,
  },
  modalButtonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
  },
};

const useStyles = makeStyles(styles);

const AccountModals = (props) => {
  const { session, setSession } = useContext(SessionContext);
  const {
    userID,
    userName,
    createdBY: organizerID,
    userCalendarID,
    accountID,
    languageID,
    firstUse,
  } = session;

  const { account, setAccount, modal, setModal } = useContext(AccountContext);
  const { slotsFree, slotsPlan, slotsUsed } = account;

  console.log(
    "%cStarts account modals",
    "background-color: purple; color: white",
    modal
  );
  const { socket } = useContext(SocketContext);

  const {
    changes,
    childContentRef,
    contentChangedRef,
    // isLoading,
    // meetingID,
    // modal,
    // parentEventID,
    refetchMeetingGeneral,
    // setIsLoading,
    setChanges,
    setTabSelected,
    // setModal,
    setShowSnackbar,
    statusID,
  } = props;

  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  const navigate = useNavigate();

  const ModalToShow = () => {
    switch (modal.name) {
      case "planExceeded": {
        return (
          <CustomDialog
            iconPreset="error"
            center
            // fullwidth
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Plan exceeded"
            mainText="Add more slots to your plan"
            buttonActionLabel1="Cancel"
            // buttonActionColor1="danger"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
            }}
            // buttonActionDisabled1={isLoading}
            buttonActionLabel2="Change subscription"
            buttonActionFunction2={() =>
              setModal({ visible: true, name: "upgradePlan" })
            }
            // buttonActionLoading2={isLoading}
            // buttonActionDisabled2={!emailsToInvite.length}
          />
        );
      }

      case "cantRemoveOwner": {
        return (
          <CustomDialog
            iconPreset="error"
            center
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Access to owner can't be removed"
            mainText="If you want to remove this email access, you have to change the account ownership first"
            buttonCloseLabel="OK"
          />
        );
      }

      case "inviteUsers": {
        return (
          <CustomDialog
            // iconPreset="error"
            center
            headerTitle="Invite users"
            visibleStatus={modal}
            maxWidth="md"
            setVisibleStatus={setModal}
          >
            <AccountInviteUsers />
          </CustomDialog>
        );
      }

      case "fileNotImported": {
        const mainText = () => {
          if (modal.data.fileType === "Excel") {
            return "An error ocurred importing Excel file.  Try converting to an older version or save your file as .csv";
          } else {
            return "An error ocurred.";
          }
        };
        return (
          <CustomDialog
            iconPreset="error"
            center
            // fullwidth
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="File not imported"
            mainText={mainText()}
            buttonCloseLabel="OK"
          />
        );
      }

      // case "fileEmpty": {
      //   return (
      //     <CustomDialog
      //       iconPreset="error"
      //       center
      //       // fullwidth
      //       visibleStatus={modal}
      //       setVisibleStatus={setModal}
      //       title="File not imported"
      //       mainText="No records were found"
      //       buttonCloseLabel="OK"
      //       // buttonActionLabel1="OK"
      //       // buttonActionColor1="danger"
      //       // buttonActionFunction1={() => {
      //       //   setModal({ visible: false, name: "" });
      //       // }}
      //     />
      //   );
      // }
      case "upgradePlan": {
        // console.log("pyament", <Payment />);
        // console.log("intenta abrir");
        return (
          <CustomDialog
            // iconPreset="error"
            center
            visibleStatus={modal}
            setVisibleStatus={setModal}

            // title="No se puede cambiar la suscripción"
            // mainText="La suscripción fue cambiada hace menos de un día.  Espera un día e intenta de nuevo"
            // buttonCloseLabel="OK"
          >
            <Payment
              setVisibleStatus={setModal}
              requiresRefetch={(item) => {
                // console.log("requiresRefetch", item);
                // item === true && refetch();
                navigate("/app/account");
              }}
            />
          </CustomDialog>
        );
      }

      case "ChangeSubscription": {
        // console.log("pyament", <Payment />);
        // console.log("intenta abrir");
        return (
          <CustomDialog
            center
            visibleStatus={modal}
            setVisibleStatus={setModal}
          >
            <Payment
              setVisibleStatus={setModal}
              requiresRefetch={(item) => {
                console.log("requiresRefetch", item);
                item === true && refetch();
              }}
            />
          </CustomDialog>
        );
      }

      case "invoice": {
        return (
          <CustomDialog
            headerTitle="Invoice"
            maxWidth="md"
            center
            visibleStatus={modal}
            setVisibleStatus={setModal}
          >
            <Invoice invoiceID={modal.data.invoiceID} />
          </CustomDialog>
        );
      }

      case "confirmInvitationSend":
        return (
          <CustomDialog
            iconPreset="question"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Do you want to resend the invitation?"
            // mainText="D"
            buttonActionLabel1="No"
            // buttonActionColor1="danger"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
            }}
            // buttonActionDisabled1={isLoading}
            buttonActionLabel2="Yes"
            buttonActionFunction2={() => modal.data.sendFunction()}
            // buttonActionLoading2={isLoading}
          />
        );

      default:
        return null;
    }
  };

  return <ModalToShow />;
};

export default AccountModals;
