import { addMinutesToDate, addDaysToDate } from "../functions/Common";

export const tourData = (selected) => {
  const meetingStartDate = addMinutesToDate(new Date(), -60).toISOString();

  const meetingEndDate = new Date().toISOString();

  const data = {
    meetingList: [
      {
        id: -1,
        title: "Reunion sample",
        date: meetingStartDate,
        status_id: 4,
        status: "Completed",
        cost: 258.75,
        attendees: [
          {
            id: "test@gmail.com",
            name: "Test",
            imagePath: "",
            willAttend: null,
          },
        ],
        organizer_id: 1,
        organizer_name: "Organizer",
        end_date_final: meetingEndDate,
        meeting_group_id: 0,
        meeting_group: "-",
        email: null,
        start_date: meetingStartDate,
        end_date: meetingEndDate,
        all_day: false,
        external_event_id: null,
        has_access: true,
        rating: 9.3,
        feedback_status_id: 1,
        feedback_status_tooltip: "",
        parent_event_id: null,
        temp_meeting_id: null,
      },
    ],
    meeting: {
      meetingID: 1,
      variant: "new",
      medalsCompleted: [true, false, false, false],
      checkInCode: "1699",
      canViewRating: false,
      checkedIn: null,
      createdBy: 1,
      finalRating: 3,
      endDate: meetingEndDate,
      endDateFinal: meetingEndDate,
      externalMeetingID: null,
      meetingCost: 45,
      isOrganizer: true,
      location: "",
      meetingTypeID: 1,
      privateAgenda: false,
      ratingElements: [
        {
          __typename: "SpMeetingRatingRecord",
          id: 1,
          desc: "Objectives",
          value: 1,
          showInMeetingStatus: [1, 2, 3, 4, 5],
          tooltip: "Objectives. 1 point if completed",
        },
        {
          __typename: "SpMeetingRatingRecord",
          id: 2,
          desc: "Contents",
          value: 2,
          showInMeetingStatus: [1, 2, 3, 4, 5],
          tooltip: "Contents. 2 points if completed",
        },
        {
          __typename: "SpMeetingRatingRecord",
          id: 3,
          desc: "Results",
          value: 1,
          showInMeetingStatus: [3, 4, 5],
          tooltip: "Results. 1 point if completed",
        },
        {
          __typename: "SpMeetingRatingRecord",
          id: 4,
          desc: "Actions",
          value: 1,
          showInMeetingStatus: [3, 4, 5],
          tooltip: "Actions. 1 point if completed",
        },
        {
          __typename: "SpMeetingRatingRecord",
          id: 5,
          desc: "Feedback",
          value: 2.5,
          showInMeetingStatus: [5],
          tooltip: "Feedback. Up to 5 points",
        },
      ],
      startDate: meetingStartDate,
      startDateFinal: meetingStartDate,
      statusID: 4,
      title:
        "\\xc30d0409030220d9b0c2a112ef026fd241015842c40d4db88a3271448a6da596e42c758c133f759f7bf49f2020bc7f76277f3c61ebb544f0222d49008a35c3d2ea0b5249f7e54b85b32f7ac8b5088ee95b0d",
      userCalendarID: null,
      willAttend: null,
      withObjectives: true,
      withAgenda: true,
      recurrenceTypeID: 0,
    },
    meeting2: {
      spMeetingStatus: {
        nodes: [
          {
            statusId: 4,
          },
        ],
      },
      spMeetingGeneral: {
        nodes: [
          {
            desc: "test",
            isOrganizer: true,
            meetingCost: 258.75,
            finalRating: 3,
            checkedIn: null,
            arrivalDate: null,
            departDate: null,
            willAttend: null,
            canConnectToSocket: false,
            withObjectives: true,
            withAgenda: true,
          },
        ],
      },
      spMeetingDetails: {
        nodes: [
          {
            attendees: [
              {
                id: 4589,
                email: "laurentsobole@yahoo.com",
                name: "Laurent Sobole",
                imagePath:
                  "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=996&t=st=1685979377~exp=1685979977~hmac=58c1c6e0fa9c4f4f8f2987e491bc40292a6f517aff5d3dc0af845353c111c33a",
                status: 0,
                icon1: 3,
                iconTooltip1: "Not responded",
                icon2: 2,
                iconTooltip2: "Not attended",
              },
            ],
            recurrenceTypeId: 0,
          },
        ],
      },
      meetingById: {
        checkinCode: "1699",
        createdBy: 1,
        endDate: "2024-02-17T22:00:00+00:00",
        endDateFinal: "2024-02-17T22:00:00+00:00",
        endDateReminder: "2024-01-16T17:37:19+00:00",
        externalMeetingId: null,
        id: 963,
        location: "",
        meetingTypeId: 1,
        privateAgenda: false,
        startDate: "2024-02-17T16:15:00+00:00",
        startDateFinal: "2024-02-17T16:15:00+00:00",
        title:
          "\\xc30d0409030220d9b0c2a112ef026fd241015842c40d4db88a3271448a6da596e42c758c133f759f7bf49f2020bc7f76277f3c61ebb544f0222d49008a35c3d2ea0b5249f7e54b85b32f7ac8b5088ee95b0d",
        userCalendarId: null,
      },
      spMeetingFeedback: {
        __typename: "SpMeetingFeedbackConnection",
        nodes: [
          {
            canViewRating: false,
          },
        ],
      },
      spMeetingRating: {
        __typename: "SpMeetingRatingConnection",
        nodes: [
          {
            id: 1,
            desc: "Objectives",
            value: 0,
            showInMeetingStatus: [1, 2, 3, 4, 5],
            tooltip: "Objectives. 1 point if completed",
          },
          {
            id: 2,
            desc: "Contents",
            value: 2,
            showInMeetingStatus: [1, 2, 3, 4, 5],
            tooltip: "Contents. 2 points if completed",
          },
          {
            id: 3,
            desc: "Results",
            value: 0,
            showInMeetingStatus: [3, 4, 5],
            tooltip: "Results. 1 point if completed",
          },
          {
            id: 4,
            desc: "Actions",
            value: 1,
            showInMeetingStatus: [3, 4, 5],
            tooltip: "Actions. 1 point if completed",
          },
          {
            id: 5,
            desc: "Feedback",
            value: 0,
            showInMeetingStatus: [5],
            tooltip: "Feedback. Up to 5 points",
          },
        ],
      },
      spSettings: {
        nodes: [
          {
            __typename: "SpSettingsRecord",
            id: 7,
            desc: "useObjectivesResults",
            value: "true",
          },
          {
            __typename: "SpSettingsRecord",
            id: 8,
            desc: "useContent",
            value: "true",
          },
        ],
      },
    },
    meetingDetailsInMeeting: {
      spMeetingStatus: {
        nodes: [
          {
            statusId: 4,
          },
        ],
      },
      spMeetingGeneral: {
        nodes: [
          {
            desc: "test",
            isOrganizer: true,
            meetingCost: 258.75,
            finalRating: 3,
            checkedIn: null,
            arrivalDate: null,
            departDate: null,
            willAttend: null,
            canConnectToSocket: false,
            withObjectives: true,
            withAgenda: true,
          },
        ],
      },
      spMeetingDetails: {
        nodes: [
          {
            attendees: [
              {
                id: 4589,
                email: "laurentsobole@yahoo.com",
                name: "Laurent Sobole",
                imagePath:
                  "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=996&t=st=1685979377~exp=1685979977~hmac=58c1c6e0fa9c4f4f8f2987e491bc40292a6f517aff5d3dc0af845353c111c33a",
                status: 0,
                icon1: 3,
                iconTooltip1: "Not responded",
                icon2: 2,
                iconTooltip2: "Not attended",
              },
            ],
            recurrenceTypeId: 0,
          },
        ],
      },
      meetingById: {
        checkinCode: "1699",
        createdBy: 1,
        endDate: "2024-02-17T22:00:00+00:00",
        endDateFinal: "2024-02-17T22:00:00+00:00",
        endDateReminder: "2024-01-16T17:37:19+00:00",
        externalMeetingId: null,
        id: 963,
        location: "",
        meetingTypeId: 1,
        privateAgenda: false,
        startDate: "2024-02-17T16:15:00+00:00",
        startDateFinal: "2024-02-17T16:15:00+00:00",
        title:
          "\\xc30d0409030220d9b0c2a112ef026fd241015842c40d4db88a3271448a6da596e42c758c133f759f7bf49f2020bc7f76277f3c61ebb544f0222d49008a35c3d2ea0b5249f7e54b85b32f7ac8b5088ee95b0d",
        userCalendarId: null,
      },
      spMeetingFeedback: {
        __typename: "SpMeetingFeedbackConnection",
        nodes: [
          {
            canViewRating: false,
          },
        ],
      },
      spMeetingRating: {
        __typename: "SpMeetingRatingConnection",
        nodes: [
          {
            id: 1,
            desc: "Objectives",
            value: 0,
            showInMeetingStatus: [1, 2, 3, 4, 5],
            tooltip: "Objectives. 1 point if completed",
          },
          {
            id: 2,
            desc: "Contents",
            value: 2,
            showInMeetingStatus: [1, 2, 3, 4, 5],
            tooltip: "Contents. 2 points if completed",
          },
          {
            id: 3,
            desc: "Results",
            value: 0,
            showInMeetingStatus: [3, 4, 5],
            tooltip: "Results. 1 point if completed",
          },
          {
            id: 4,
            desc: "Actions",
            value: 1,
            showInMeetingStatus: [3, 4, 5],
            tooltip: "Actions. 1 point if completed",
          },
          {
            id: 5,
            desc: "Feedback",
            value: 0,
            showInMeetingStatus: [5],
            tooltip: "Feedback. Up to 5 points",
          },
        ],
      },
      spSettings: {
        nodes: [
          {
            __typename: "SpSettingsRecord",
            id: 7,
            desc: "useObjectivesResults",
            value: "true",
          },
          {
            __typename: "SpSettingsRecord",
            id: 8,
            desc: "useContent",
            value: "true",
          },
        ],
      },
    },
    details: {
      title: "Sample meeting title",
      startDate: meetingStartDate,
      startDateFinal: meetingStartDate,
      endDate: meetingEndDate,
      endDateFinal: null,
      allDay: false,
      location: "",
      userGroupsList: [
        {
          id: 103,
          menuDesc: "Flor",
        },
        {
          id: 104,
          menuDesc: "Nuevo",
        },
        {
          id: 107,
          menuDesc: "Probando1",
        },
        {
          id: 101,
          menuDesc: "Todo",
        },
      ],
      meetingGroupId: null,
      attendees: [
        {
          id: 1450,
          email: "attendee1@gmail.com",
          name: "Attendee 1",
          imagePath:
            "https://cdn.hswstatic.com/gif/play/0b7f4e9b-f59c-4024-9f06-b3dc12850ab7-1920-1080.jpg",
          status: 1,
          icon1: 1,
          iconTooltip1: "Confirmed attendance",
          icon2: 1,
          iconTooltip2: "Attended",
        },
        {
          id: 1450,
          email: "attendee2@gmail.com",
          name: "Attendee 2",
          imagePath:
            "https://cdn.hswstatic.com/gif/play/0b7f4e9b-f59c-4024-9f06-b3dc12850ab7-1920-1080.jpg",
          status: 1,
          icon1: 1,
          iconTooltip1: "Confirmed attendance",
          icon2: 1,
          iconTooltip2: "Attended",
        },
      ],
      createdBy: 1,
      meetingTypeID: 1,
      meetingTypeList: [
        {
          id: 1,
          menuDesc: "Group",
        },
        {
          id: 2,
          menuDesc: "1 on 1",
        },
      ],
      recurrenceTypeID: 0,
      separationCount: null,
      maxOccurrences: null,
      weekMonth: null,
      monthYear: null,
      dayWeek: null,
      recurrenceStartDate: null,
      recurrenceEndDate: null,
      dayMonth: null,
    },
    purpose: [
      {
        meetingId: 1,
        objective: "Objective 1",
        result: "The result of objective 1 was ...",
        meetingObjectiveId: 1,
      },
      {
        meetingId: 1,
        objective: "Objective 2",
        result: "The result of objective 2 was ...",
        meetingObjectiveId: 2,
      },
    ],
    content: {
      isSavingRef: { current: false },
      content: null,
      privateNotes: null,
      showAgenda: true,
      hasPreviousActions: true,
      privateAgenda: false,
      withDurations: true,
      selectedItem: { id: 1, index: 0 },
      agendaItems: [
        {
          id: 1,
          meetingID: 1,
          desc: "Topic 1",
          orderBy: 1,
          privateNotes: null,
          details: [
            {
              id: 1,
              type: "paragraph",
              children: [
                {
                  text: "Details of the topic 1 to be discussed",
                },
              ],
            },
            {
              id: "74f318e4-da00-4110-a0f4-a1c049e5d119",
              type: "paragraph",
              children: [
                {
                  text: "",
                },
              ],
            },
          ],
          duration: 30,
          dayID: 1,
          typeID: 1,
        },
        {
          id: 2,
          meetingID: 1,
          desc: "Topic 2",
          orderBy: 2,
          privateNotes: null,
          details: [
            {
              id: 1,
              type: "paragraph",
              children: [
                {
                  text: "Details of the topic 2 to be discussed",
                },
              ],
            },
            {
              type: "paragraph",
              children: [
                {
                  text: "",
                },
              ],
              id: "60f324ea-97da-4729-aa0c-1c92ec388ed5",
            },
          ],
          duration: 20,
          dayID: 1,
          typeID: 1,
        },
      ],
    },
    actions: [
      {
        completed: true,
        deleteDate: null,
        desc: "Meeting action 1",
        dueDate: new Date().toISOString(),
        fromPreviousMeeting: false,
        id: 1137,
        meetingActionId: 1,
        meetingId: 1,
        modifying: false,
        responsibleEmail: "owner1@gmail.com",
        responsibleDesc: "Owner 1",
        originalResponsibleDesc: "owner1@gmail.com",
      },
      {
        completed: false,
        deleteDate: null,
        desc: "Meeting action 2",
        dueDate: null,
        fromPreviousMeeting: false,
        id: 1255,
        meetingActionId: 2,
        meetingId: 1,
        modifying: false,
        responsibleEmail: "owner2@gmail.com",
        responsibleDesc: "Owner 2",
        originalResponsibleDesc: "owner2@gmail.com",
      },

      {
        completed: false,
        deleteDate: null,
        desc: "Meeting action 3",
        dueDate: null,
        fromPreviousMeeting: false,
        id: 1255,
        meetingActionId: 3,
        meetingId: 1,
        modifying: false,
        responsibleEmail: "owner2@gmail.com",
        responsibleDesc: "Owner 2",
        originalResponsibleDesc: "owner2@gmail.com",
      },
      {
        completed: false,
        deleteDate: null,
        desc: "",
        dueDate: null,
        fromPreviousMeeting: false,
        id: 1255,
        meetingActionId: 4,
        meetingId: 1,
        modifying: false,
        responsibleEmail: "",
        responsibleDesc: "",
        originalResponsibleDesc: "",
      },
    ],
    parkingLot: [
      {
        meetingId: 1,
        meetingTopicId: 1,
        topic: "Topic to discuss later 1",
      },
      {
        meetingId: 1,
        meetingTopicId: 2,
        topic: "Topic to discuss later 2",
      },
    ],
    feedback: {
      attendees: [
        {
          id: 1,
          name: "Attendee 1",
          rating: 3,
          comment: null,
        },
        {
          id: 2,
          name: "Attendee 2",
          rating: 4,
          comment: null,
        },
      ],
      general: {
        canViewRating: true,
        dateOrganizerGiveRating: null,
        expiredFeedbackTime: false,
        endDateFinal: new Date().toISOString(),
        endDateFinalRef: {
          current: new Date().toISOString(),
        },
        hoursForFeedback: 12,
        hoursForFeedbackRef: {
          current: 12,
        },
        infoOrganizer: [
          {
            id: 0,
            desc: "Overall",
            rating: "3",
            tooltip: "",
            dateAttendeeGiveRating: null,
          },
          {
            id: 1,
            desc: "Drive to action",
            rating: "4",
            tooltip: "",
            dateAttendeeGiveRating: null,
          },
          {
            id: 2,
            desc: "Time management",
            rating: "3",
            tooltip: "",
            dateAttendeeGiveRating: null,
          },
          {
            id: 3,
            desc: "Execution",
            rating: "5",
            tooltip: "",
            dateAttendeeGiveRating: null,
          },
          {
            id: 4,
            desc: "Value",
            rating: "4",
            tooltip: "",
            dateAttendeeGiveRating: null,
          },
        ],
      },
    },
    actionsList: [
      {
        meetingActionId: 1293,
        toDoId: 164,
        actionName: "Action 1",
        responsibleEmail: "Owner 1",
        responsibleDesc: "Owner 1",
        imagePath: null,
        dueDate: null,
        statusId: 2,
        statusDesc: "Accepted",
        statusTooltip:
          "Action was accepted by owner and incorporated in to do's",
        stepCurrent: 1,
        stepTotal: 3,
        actionCompleted: true,
        completedDate: "1900-01-01T05:36:13.000Z",
        meetingId: 911,
        originalResponsibleDesc: "Owner 1",
        modifying: false,
      },
      {
        meetingActionId: 802,
        toDoId: 124,
        actionName: "Action 2",
        responsibleEmail: "Owner 2",
        responsibleDesc: "Owner 2",
        imagePath:
          "https://main-orquesti3.s3.us-east-2.amazonaws.com/public/profileImages/userID:1-0.20595068739032318",
        dueDate: null,
        statusId: 2,
        statusDesc: "Accepted",
        statusTooltip:
          "Action was accepted by owner and incorporated in to do's",
        stepCurrent: 1,
        stepTotal: 3,
        actionCompleted: true,
        completedDate: "2024-03-20T15:06:40.052Z",
        meetingId: 171,
        originalResponsibleDesc: "Owner 2",
        modifying: false,
      },
      {
        meetingActionId: 581,
        toDoId: null,
        actionName: "Action 3",
        responsibleEmail: "Owner 3",
        responsibleDesc: "Owner 3",
        imagePath:
          "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=996&t=st=1685979377~exp=1685979977~hmac=58c1c6e0fa9c4f4f8f2987e491bc40292a6f517aff5d3dc0af845353c111c33a",
        dueDate: null,
        statusId: 3,
        statusDesc: "Rejected",
        statusTooltip:
          "Action was rejected by owner and not incorporated in to do's",
        stepCurrent: null,
        stepTotal: null,
        actionCompleted: false,
        completedDate: null,
        meetingId: 101,
        originalResponsibleDesc: "Owner 3",
        modifying: false,
      },
    ],
    actionDetails: [
      {
        typeId: 1,
        owner: "Owner 1",
        meetingActionId: 1293,
        description: "Action created",
        date: addDaysToDate(new Date(), -31).toISOString(),
      },
      {
        typeId: 5,
        owner: "John Peters",
        meetingActionId: 1293,
        description: "Meeting 1",
        date: addDaysToDate(new Date(), -15).toISOString(),
      },
      {
        typeId: 5,
        owner: "Rachel Taylor",
        meetingActionId: 1293,
        description: "Meeting 2",
        date: addDaysToDate(new Date(), -7).toISOString(),
      },
      {
        typeId: 4,
        owner: "Owner 1",
        meetingActionId: 1293,
        description: "Action completed",
        date: addDaysToDate(new Date(), -1).toISOString(),
      },
    ],
    todo: [
      {
        id: 93,
        desc: "From meetings",
        orderBy: 1,
        items: [
          {
            id: 130,
            desc: "Action 1",
            orderBy: 1,
            toDoGroupId: 93,
            dueDate: null,
            toDoPriorityId: 4,
            meetingTitle: null,
            meetingDate: null,
            meetingOrganizer: null,
          },
        ],
        locked: true,
        toDoGroupIDDefault: 2,
      },

      {
        id: 93,
        desc: "To do",
        orderBy: 2,
        items: [
          {
            id: 130,
            desc: "Task 1",
            orderBy: 1,
            toDoGroupId: 93,
            dueDate: null,
            toDoPriorityId: 4,
            meetingTitle: null,
            meetingDate: null,
            meetingOrganizer: null,
          },
        ],
        locked: true,
        toDoGroupIDDefault: 2,
      },
      {
        id: 2,
        desc: "Working on",
        orderBy: 3,
        items: [
          {
            id: 113,
            desc: "Task 2",
            orderBy: 1,
            toDoGroupId: 2,
            dueDate: null,
            toDoPriorityId: 1,
            meetingTitle: null,
            meetingDate: null,
            meetingOrganizer: null,
          },
          {
            id: 167,
            desc: "Task 3",
            orderBy: 2,
            toDoGroupId: 2,
            dueDate: null,
            toDoPriorityId: 1,
            meetingTitle: null,
            meetingDate: null,
            meetingOrganizer: null,
          },
        ],
        locked: false,
        toDoGroupIDDefault: 3,
      },
      {
        id: 148,
        desc: "On hold",
        orderBy: 3,
        items: [
          {
            id: 101,
            desc: "Task 4",
            orderBy: 1,
            toDoGroupId: 148,
            dueDate: null,
            toDoPriorityId: 2,
            meetingTitle: null,
            meetingDate: null,
            meetingOrganizer: null,
          },
          {
            id: 102,
            desc: "Task 5",
            orderBy: 2,
            toDoGroupId: 148,
            dueDate: null,
            toDoPriorityId: 3,
            meetingTitle: null,
            meetingDate: null,
            meetingOrganizer: null,
          },
        ],
        locked: false,
        toDoGroupIDDefault: 3,
      },
      {
        id: 3,
        desc: "Done",
        orderBy: 1000,
        items: [
          {
            id: 121,
            desc: "Task 6",
            orderBy: 1,
            toDoGroupId: 3,
            dueDate: null,
            toDoPriorityId: 1,
            meetingTitle: null,
            meetingDate: null,
            meetingOrganizer: null,
          },
        ],
        locked: true,
        toDoGroupIDDefault: 4,
      },
    ],
  };

  return data[selected];
};
