import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// data
import { Formik } from "formik";
import { object, string, ref } from "yup";
import { SessionContext } from "../../contexts/SessionContext";
import axios from "axios";
import { axiosOptions } from "functions/Common";
import config from "../../config";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Dialog from "components/CustomDialog/CustomDialog";
import ErrorHandler from "components/Error/Error";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";

// icons
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";

const styles = {
  cardBody: {
    paddingTop: "40px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
};

const useStyles = makeStyles(styles);

export default function Profile(props) {
  console.log("inicia profile");
  const classes = useStyles();
  const { setSession } = useContext(SessionContext);
  const navigate = useNavigate();
  const [isLoading] = useState(false);
  const [modal, setModal] = useState({ visible: false, name: "" });

  const submitButtonRef = useRef(null);
  const newPassRef = useRef(null);
  const confirmPassRef = useRef(null);

  const handleSubmit = (values, actions) => {
    console.log("Inicia el click de password reset");

    const options = axiosOptions({
      url: `${config.url}/auth/passChangeSignedIn`,
      method: "post",
      data: {
        currentPass: values.currentPass,
        newPass: values.newPass,
      },
    });

    axios(options)
      .then(async (response) => {
        console.log(
          "%cresponse en profile security",
          "background-color: orange; color: white",
          response
        );

        switch (response.data.code) {
          case 0: // pass changed successfully
            actions.resetForm();
            setModal({ visible: true, name: "pass_changed" });
            break;

          case 1: // pass not saved
            setModal({ visible: true, name: "pass_not_changed" });
            break;

          case 2: // current pass incorrect.  pass not changed
            setModal({ visible: true, name: "incorrect_pass" });

            break;
          default:
        }

        // actions.resetForm();
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        return <ErrorHandler error={error} />;
      });
  };

  const ModalToShow = () => {
    switch (modal.name) {
      case "pass_changed": // Password changed successfully
        return (
          <Dialog
            iconPreset="success"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Password changed successfully"
            buttonActionLabel1="OK"
            buttonActionFunction1={() => {
              // history.goBack();
              setModal({ visible: false, name: "" });
            }}
          />
        );
      case "pass_not_changed":
        return (
          <Dialog
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Password could not be changed"
            buttonCloseLabel="OK"
          />
        );
      case "incorrect_pass":
        return (
          <Dialog
            center
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Password was not updated."
            mainText="Current password is incorrect"
            buttonCloseLabel="OK"
          />
        );
      case "no_token":
        return (
          <Dialog
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            center
            title="An error has ocurred."
            mainText="Sign out and sign in again."
            buttonActionLabel1="OK"
            buttonActionFunction1={() => {
              navigate("/");
              setSession(null);
              sessionStorage.clear();
            }}
          />
        );
      case "connection_error": // connection problem
        return <ErrorHandler error={{ message: "Failed to fetch" }} />;
      default:
        return null;
    }
  };

  const handleKeyPress = (event, nextRef, submit) => {
    // console.log("key pressed", event.key);
    if (event.key === "Tab") {
      event.preventDefault();
      nextRef && nextRef.current.focus();
    }

    if (event.key === "Enter") {
      event.preventDefault();
      submit;
    }
  };

  return (
    <>
      <Formik
        initialValues={{ currentPass: "", newPass: "", confirmPass: "" }}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        validationSchema={object().shape({
          currentPass: string()
            .required("Current password is required")
            .max(30, "Maximum 30 characters"),
          newPass: string()
            .required("New password is required")
            .min(8, "Minimum 8 digits")
            .max(30, "Maximum 30 characters"),
          confirmPass: string()
            .oneOf([ref("newPass")], "Passwords don't match")
            .required("Confirm password is required"),
        })}
      >
        {(fprops) => (
          <>
            <CardBody fullscreen className={classes.cardBody}>
              <GridContainer spacing={3}>
                <GridItem xs={4}>
                  <CustomMainInput
                    height="40px"
                    label="Current password"
                    name="currentPass"
                    onBlur={fprops.handleBlur("currentPass")}
                    onChange={fprops.handleChange("currentPass")}
                    onKeyDown={(e) => handleKeyPress(e, newPassRef)}
                    // ref={currentPassRef}
                    type="password"
                    value={fprops.values.currentPass}
                    variant="input"
                    error={
                      fprops.touched.currentPass && fprops.errors.currentPass
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer spacing={3}>
                <GridItem xs={4}>
                  <CustomMainInput
                    error={fprops.touched.newPass && fprops.errors.newPass}
                    height="40px"
                    label="New password"
                    name="newPass"
                    onBlur={fprops.handleBlur("newPass")}
                    onChange={fprops.handleChange("newPass")}
                    onKeyDown={(e) => handleKeyPress(e, confirmPassRef)}
                    refID={newPassRef}
                    type="password"
                    value={fprops.values.newPass}
                    variant="input"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer spacing={4}>
                <GridItem xs={4}>
                  <CustomMainInput
                    error={
                      fprops.touched.confirmPass && fprops.errors.confirmPass
                    }
                    height="40px"
                    label="Confirm new password"
                    name="confirmPass"
                    onBlur={fprops.handleBlur("confirmPass")}
                    onChange={fprops.handleChange("confirmPass")}
                    onKeyDown={(e) => handleKeyPress(e, submitButtonRef)}
                    refID={confirmPassRef}
                    value={fprops.values.confirmPass}
                    variant="input"
                    type="password"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer spacing={6}>
                <GridItem xs={4}>
                  <Button
                    color="primary"
                    startIcon={<SaveOutlinedIcon />}
                    onClick={fprops.handleSubmit}
                    isLoading={isLoading}
                    onKeyDown={(e) =>
                      handleKeyPress(e, null, fprops.handleSubmit())
                    }
                    ref={submitButtonRef}
                  >
                    Change password
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.cardFooter}></CardFooter>
          </>
        )}
      </Formik>
      <ModalToShow />
    </>
  );
}
