import { gql } from "@apollo/client";

export const invoiceList = gql`
  query invoiceList($userID: Int!) {
    spInvoiceList(userId1: $userID) {
      nodes {
        invoiceId
        dateCreated
        netAmount
      }
    }
  }
`;
