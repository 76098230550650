import { useContext, useRef, useState } from "react";
// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import Dropzone from "components/Dropzone";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Thumbnail } from "components/Thumbnail";

// data
import { useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import { axiosOptions } from "functions/Common";
import config from "config";

import { HelpDeskContext } from "contexts/HelpDeskContext";
import { formatDateTime } from "functions/Common";
import { ticketDetails, ticketHistory } from "graphql/gqlHelpDeskHistory";
import { SessionContext } from "../../contexts/SessionContext";
import { SocketContext } from "contexts/SocketContext";

// errors
import { logError, useDataForLog } from "functions/ErrorBoundary";

// icons
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";

// style
import {
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  successColor,
} from "assets/jss/material-dashboard-react";
import classNames from "classnames";
import { YoutubeSearchedForOutlined } from "@material-ui/icons";

const styles = {
  conversationBarContainer: {
    display: "flex",
    // flex: 1,
    backgroundColor: primaryColor[8], // grayColor[6],
    minHeight: "30px",
    padding: "10px",
    flexWrap: "wrap",
    gap: "10px",
    // : "300px",
    // display: "flex",
  },

  conversationBarInput: {
    ...defaultFont,
    fontSize: "13px",
    minWidth: "235px",
    // maxHeight: "50px",
    minHeight: "45px",

    lineHeight: "20px",
    fontWeight: 500,
    resize: "none",
    flex: 1,
    borderRadius: "5px",
    backgroundColor: "white",
    border: "0px",
    padding: "7px 10px 7px 15px",
    // marginRight: "10px",
    "&::placeholder": {
      color: grayColor[5],
      opacity: 1,
    },
  },
  conversationBarNoInputContainer: {
    display: "flex",
    // backgroundColor: "orange",
    // height: "100%",
    alignItems: "flex-end",
    // justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: "10px",
  },
  conversationHistoryAll: {
    // flex: 1,
    // display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    maxHeight: "200px",
    backgroundColor: primaryColor[9],
  },
  conversationMain: {
    borderRadius: "5px",
    border: `1px solid ${grayColor[5]}`,
    // backgroundColor: grayColor[9],
    marginTop: "10px",
    marginRight: "10px",
    // height: "400px",
    // minHeight: "200px",
    // maxHeight: "400px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    // position: absolute,
    // top: 0,
    // bottom: 0,
    // left: 0,
    // minHeight: 0, // for firefox
  },

  conversationLineAlignRight: { display: "flex", justifyContent: "flex-end" },
  conversationLineAlignLeft: { display: "flex", justifyContent: "flex-start" },

  conversationLineImg: {
    display: "block",
    width: "100px",
    height: "100%",
  },
  conversationLineMain: {
    boxShadow: `1px 1px 1px 0px ${grayColor[5]}`,
    // boxShadow: `0px 5px 5px ${grayColor[1]})`,
    borderRadius: "5px",
    // backgroundColor: primaryColor[9],
    maxWidth: "500px",
    minWidth: "300px",
    // width: "85%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "flex-end",
    margin: "10px",
  },
  conversationLineMainLeft: { backgroundColor: successColor[7] },
  conversationLineMainRight: { backgroundColor: infoColor[10] },
  conversationLineComment: {
    color: grayColor[2],
    padding: "5px 10px 5px 10px",
  },
  conversationLineTitleBar: {
    display: "flex",
    justifyContent: "space-between",

    padding: "0px 10px 0px 10px",
    borderRadius: "5px 5px 0px 0px",
  },
  conversationLineTitleBarLeft: {
    backgroundColor: successColor[5],
  },
  conversationLineTitleBarRight: { backgroundColor: infoColor[8] },

  conversationLineTitleLabelLeft: {
    fontSize: "11px",
    color: successColor[1],
    lineHeight: "14px",
  },
  conversationLineTitleLabelRight: {
    fontSize: "11px",
    color: infoColor[4],
    lineHeight: "14px",
  },

  closeButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  dialogContent: {
    overflowY: "hidden",
    paddingTop: "20px",
    paddingBottom: "40px",
  },
  dialogActions: {
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogTitle: { backgroundColor: grayColor[7], padding: "5px 20px" },
  img: {
    display: "block",
    width: "auto",
    height: "100%",
    cursor: "pointer",

    // "&:hover": { filter: "blur(5px)" },
  },
  label: {
    ...defaultFont,
    fontSize: "12px",
    paddingLeft: "10px",
    color: grayColor[0],
    marginTop: ".2rem",
  },
  topBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  thumbsContainer: {
    display: "flex",
    flexDirection: "row",

    overflowX: "auto",
  },

  thumbsContainerConversationBar: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    // height: "30px",
    maxWidth: "235px",
    borderRadius: 5,
    padding: 4,
    backgroundColor: "white",
    marginRight: 10,
  },

  thumb: {
    marginRight: 8,
    padding: "5px 5px 0px 0px",
    backgroundColor: "white",

    display: "inline-flex",
    borderRadius: 2,
    border: `1px solid ${grayColor[7]}`,
    // marginBottom: 8,

    width: 70,
    height: 70,
    padding: 4,
    boxSizing: "border-box",
    // position: "relative",
  },

  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    borderRadius: "5px",
  },
};
const useStyles = makeStyles(styles);

export default function HelpDeskHistory(props) {
  // console.log("starts HelpDeskNew");
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { setVisibleStatus, visibleStatus, ticketID } = props; // const companyID = parseInt(sessionStorage.getItem("companyID"));

    // console.log("ticketID", ticketID);
    const classes = useStyles();
    // const [records, setRecords] = useState([]);
    const { session, setSession } = useContext(SessionContext);
    const { languageID, userID } = session;

    const { socket } = useContext(SocketContext);

    const { helpDesk, setHelpDesk, setShowSnackbar } =
      useContext(HelpDeskContext);
    const { isAdmin } = helpDesk;

    const { loading, data, error, refetch } = useQuery(ticketDetails, {
      variables: { ticketID: ticketID, languageID: languageID },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });

    const [ticketHistoryMut] = useMutation(ticketHistory, {
      onCompleted: () => {
        setVisibleStatus({
          ...visibleStatus,
          visible: false,
        });

        setHelpDesk({ ...helpDesk, updateInfo: new Date() });
      },
    });

    // const handleSubmit = async (values) => {
    //   // console.log("en handle submit");
    //   console.log("------------ en handle submit", {
    //     values: values,
    //   });
    // };

    const spacing = 1;

    if (data && data.allDropDowns) {
      console.log("%cdata", "background-color: red; color: white", data);

      // const initialValues = {
      //   ticketID: data.spHelpdeskTicketDetails.nodes[0].ticketId,
      //   createdDate: data.spHelpdeskTicketDetails.nodes[0].createdDate,
      //   subject: data.spHelpdeskTicketDetails.nodes[0].subject,
      //   details: "details",
      //   priorityID: 1,
      //   categoryID: 1,
      // };
      const {
        ticketId: ticketID,
        subject,
        createdDate,
        priorityId: priorityID,
        categoryId: categoryID,
        isClosed,
        ownerId: ownerID,
      } = data.spHelpdeskTicketDetails.nodes[0];

      const Conversation = () => {
        const textAreaRef = useRef(null);

        const ConversationHistory = () => {
          const conversationArray =
            data.spHelpdeskTicketDetails.nodes[0].conversation;
          return conversationArray.map((item, index) => {
            // console.log(
            //   "%conversationArray",
            //   "background-color: red; color: white",
            //   item
            // );

            const isUser = userID === item.userID;
            const conversationLineAlignClasses = classNames({
              [classes.conversationLineAlignRight]: !isUser,
              [classes.conversationLineAlignLeft]: isUser,
            });

            const conversationLineMainClasses = classNames({
              [classes.conversationLineMain]: true,
              [classes.conversationLineMainRight]: !isUser,
              [classes.conversationLineMainLeft]: isUser,
            });

            const conversationLineTitleBarClasses = classNames({
              [classes.conversationLineTitleBar]: true,
              [classes.conversationLineTitleBarRight]: !isUser,
              [classes.conversationLineTitleBarLeft]: isUser,
            });

            const conversationLineTitleLabelClasses = classNames({
              [classes.conversationLineTitleLabelRight]: !isUser,
              [classes.conversationLineTitleLabelLeft]: isUser,
            });

            const ConversationLine = () => {
              return (
                <div key={item.id} className={conversationLineAlignClasses}>
                  <div className={conversationLineMainClasses}>
                    <div className={conversationLineTitleBarClasses}>
                      <h6 className={conversationLineTitleLabelClasses}>
                        {item.user}
                      </h6>
                      <h6 className={conversationLineTitleLabelClasses}>
                        {formatDateTime(item.createdDate, {
                          helpDeskConversionLine: createdDate,
                        })}
                      </h6>
                    </div>
                    <h6 className={classes.conversationLineComment}>
                      {item.comment}
                    </h6>
                    {(item?.attachmentPaths?.length || 0) > 0 && (
                      <div className={classes.thumbsContainer}>
                        {item.attachmentPaths.map((path, index) => (
                          <Thumb key={index} path={path} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              );
            };

            // evaluates if it0's the last line to see if it should display the closed banner

            const ClosedBanner = () => {
              return (
                <h5
                  style={{
                    width: "100%",
                    backgroundColor: grayColor[7],
                    color: grayColor[2],
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  Closed{"  "}
                  {formatDateTime(item.createdDate, {
                    helpDeskConversionLine: createdDate,
                  })}
                </h5>
              );
            };

            switch (item.statusID) {
              case 1: //unassigned
              case 3: // in progress
              case 5: // replied to user
                return <ConversationLine key={index} />;
              case 6: // blocked
              case 7: // closed not fixed
              case 8: //closed and fixed
                return (
                  <>
                    {conversationArray.length === index + 1 && (
                      <ClosedBanner key={index} />
                    )}
                  </>
                );
            }
          });
        };

        const Thumb = ({ path }) => {
          // console.log("file", file);
          // const [thumbHover, setThumbHover] = useState(false);

          return (
            <div className={classes.thumb}>
              <div className={classes.thumbInner}>
                <img
                  src={path}
                  className={classes.img}
                  onClick={() => window.open(path, "_blank")}
                  //   onMouseOver={() => setThumbHover(true)}
                  //   onMouseOut={() => setThumbHover(false)}

                  // Revoke data uri after image is loaded
                  // onLoad={() => {
                  //   URL.revokeObjectURL(file.preview);
                  // }}
                />
              </div>
            </div>
          );
        };

        const ConversationBar = () => {
          const [newConversationData, setNewConversationData] = useState({
            text: "",
            attachments: [],
          });

          // allows to resize the text height based on content
          // useAutosizeTextArea(
          //   textAreaRef.current,
          //   newConversationData.text,
          //   60
          // );

          const [thumbToRemove, setThumbToRemove] = useState({
            index: null,
            trigger: false,
          });
          const [files, setFiles] = useState({
            all: [],
            toUpload: [],
          });
          const maxFileSizeMb = 2;

          const ThumbnailsContainer = () => {
            if (files.all.length > 0) {
              const errorType = {
                //   "too-many-files": `Can't upload more than ${maxFiles} file${
                //     maxFiles > 1 ? "s" : ""
                //   }`,
                "file-too-large": `File is larger than ${maxFileSizeMb}Mb`,
                "file-invalid-type": "Only images are allowed",
              };

              // return files.all.map((file, index) => {

              // })
              //

              return (
                <div className={classes.thumbsContainerConversationBar}>
                  {files.all.map((file, index) => {
                    console.log(
                      "%cfile",
                      "background-color: red; color: white",
                      file
                    );

                    return (
                      <Thumbnail
                        key={index}
                        hasRightMargin={files.all.length !== index + 1} // removes margin if the last thumbnail
                        widthAndHeight={80}
                        tooltipText={file.name}
                        error={
                          file.errors.length > 0
                            ? errorType[file?.errors[0]?.code]
                            : ""
                        }
                        index={index}
                        path={file.preview}
                        sendInfoToParent={(indexToRemove) => {
                          setThumbToRemove({
                            index: indexToRemove,
                            trigger: !thumbToRemove.trigger, // this trigger is required because the index to delete may not change but the useEffect in dropzone needs to be triggered when this changes
                          });
                        }}
                      />
                    );
                  })}
                </div>
              );
            }
          };

          return (
            <div className={classes.conversationBarContainer}>
              {/* <input
                className={classes.conversationBarInput}
                placeholder="Start typing"
              ></input> */}
              <textarea
                ref={textAreaRef}
                placeholder="Start typing"
                rows={1}
                // disabled={disabled}
                className={classes.conversationBarInput}
                onChange={(e) =>
                  setNewConversationData({
                    ...newConversationData,
                    text: e.target.value,
                  })
                }
                // onBlur={onBlur}
                name="conversation"
                value={newConversationData.text}
              />
              <div className={classes.conversationBarNoInputContainer}>
                <ThumbnailsContainer />

                <Dropzone
                  thumbToRemove={thumbToRemove}
                  sendInfoToParent={(
                    acceptedFilesToUpload,
                    allSelectedFiles
                  ) => {
                    console.log("selected files in HelpDesNew", {
                      acceptedFilesToUpload,
                      allSelectedFiles,
                    });
                    setFiles({
                      all: allSelectedFiles,
                      toUpload: acceptedFilesToUpload,
                    });
                  }}
                  maxFiles={10}
                  maxFileSizeMb={2}
                  customTriggerComponent={(action) => (
                    <Button
                      size="md"
                      color="primary"
                      // justIcon
                      onClick={() => {
                        action;
                      }}
                    >
                      <AttachFileIcon />
                    </Button>
                  )}
                />

                <div
                  style={{
                    display: "flex",
                    // marginLeft: "5px",
                    padding: 0,
                    // backgroundColor: "yellow",
                    height: "46px",
                  }}
                >
                  <Button
                    disabled={newConversationData.text.length === 0}
                    color="primary"
                    // height="54px"
                    onClick={(item) => {
                      // if the current user and the owner are the same, notify support, otherwise support is sending and therefore notifies owner
                      // socket.emit("helpDesk_update", {
                      //   userToNotify: userID === ownerID ? 1 : ownerID,
                      // });

                      ticketHistoryMut({
                        variables: {
                          userID: userID,
                          statusID: isAdmin ? 5 : 3, // if admin sets status as replied to user, otherwise sets back status to in progress
                          ticketID: ticketID,
                          comment: newConversationData.text,
                          attachmentPaths: newConversationData.attachments,
                        },
                      }).then(() => {
                        setShowSnackbar({
                          show: true,
                          message: "Ticket submitted",
                        });
                        // if the current user and the owner are the same, notify support, otherwise support is sending and therefore notifies owner
                        socket.emit("helpDesk_update", {
                          userTo: userID === ownerID ? 1 : ownerID,
                          userFrom: userID === ownerID ? ownerID : 1,
                        });
                        // send email to support, if not admin
                        if (!isAdmin) {
                          const options = axiosOptions({
                            url: `${config.url}/api/sendEmailDirect`,
                            method: "post",
                            data: {
                              to: ["support@orquesti.com"],
                              subject: `Helpdesk ticket #${ticketID} update`,
                              content: `New comment: ${newConversationData.text}`,
                            },
                          });
                          axios(options);
                        }
                      });
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          );
        };

        return (
          <div className={classes.conversationMain}>
            <div className={classes.conversationHistoryAll}>
              <ConversationHistory />
            </div>
            {!isClosed && <ConversationBar />}
          </div>
        );
      };

      return (
        <Dialog
          // classes={{ paper: classes.mainDialog }}
          open={visibleStatus.visible || false}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle
            // className={classes.dialogTitle}
            disableTypography
            classes={{ root: classes.dialogTitle }}
          >
            <GridContainer spacing={spacing}>
              <GridItem xs={12}>
                <div className={classes.topBar}>
                  <h4>Ticket history</h4>
                  <div className={classes.closeButtonContainer}>
                    <Button
                      color="gray"
                      justIcon
                      simple
                      onClick={() => {
                        setVisibleStatus({
                          ...visibleStatus,
                          visible: false,
                        });
                      }}
                    >
                      <CloseIcon style={{ color: grayColor[3] }} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <>
              <GridContainer
                spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
              >
                {/* ---------------------- ticket id -------------------------- */}
                <GridItem xs={3}>
                  <CustomMainInput
                    disabled
                    height="40px"
                    label="Ticket ID"
                    value={ticketID}
                    variant="noInput"
                  />
                </GridItem>
                {/* ---------------------- subject -------------------------- */}
                <GridItem xs={6}>
                  <CustomMainInput
                    disabled
                    height="40px"
                    label="Subject"
                    value={subject}
                    variant="noInput"
                  />
                </GridItem>
                {/* <GridItem xs={6}></GridItem> */}

                {/* ---------------------- created date --------------------------
                <GridItem xs={3}>
                  <CustomMainInput
                    disabled
                    height="40px"
                    label="Created date"
                    value={formatDateTime(createdDate, {
                      helpDeskTicketsHistory: createdDate,
                    })}
                    variant="noInput"
                  />
                </GridItem> */}
                {/* ---------------------- elapsed time --------------------------
                // <GridItem xs={3}>
                //   <CustomMainInput
                //     disabled
                //     height="40px"
                //     label="Elapsed time"
                //     value={formatDateTime(createdDate, {
                //       helpDeskTicketsHistory: createdDate,
                //     })}
                //     variant="noInput"
                //   />
                // </GridItem> */}

                {/* ---------------------- close ticket button -------------------------- */}
                <GridItem xs={3}>
                  <div
                    style={{
                      // backgroundColor: "yellow",
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      marginRight: "10px",
                      // bottom: 0,
                    }}
                  >
                    {!isClosed ? (
                      <Button
                        size="sm"
                        color="primary"
                        onClick={(item) => {
                          ticketHistoryMut({
                            variables: {
                              userID: userID,
                              statusID: 8, // returns status to in progress
                              ticketID: ticketID,
                              comment: null,
                              attachmentPaths: null,
                            },
                          }).then(() => {
                            setShowSnackbar({
                              show: true,
                              message: "Ticket closed",
                            });
                            // if the current user and the owner are the same, notify support, otherwise support is sending and therefore notifies owner
                            socket.emit("helpDesk_update", {
                              userTo: userID === ownerID ? 1 : ownerID,
                              userFrom: userID === ownerID ? ownerID : 1,
                            });
                          });
                        }}
                      >
                        Close ticket
                      </Button>
                    ) : (
                      <h4
                        style={{
                          color: "white",
                          padding: "15px",
                          backgroundColor: grayColor[5],
                          borderRadius: "5px",
                        }}
                      >
                        Closed
                      </h4>
                    )}
                  </div>
                </GridItem>

                <GridItem xs={6}>
                  <CustomMainInput
                    disabled
                    dropDownList={data.allDropDowns.nodes[0].priorities.nodes}
                    height="40px"
                    label="Priority"
                    name="priorityID"
                    value={priorityID}
                    variant="dropdown"
                  />
                </GridItem>
                <GridItem xs={6}>
                  <CustomMainInput
                    disabled
                    dropDownList={data.allDropDowns.nodes[0].categories.nodes}
                    height="40px"
                    label="Category"
                    name="categoryID"
                    value={categoryID}
                    variant="dropdown"
                  />
                </GridItem>

                <GridItem xs={6} alignItems="stretch" alignContent="stretch">
                  {/* ---------------------- details -------------------------- */}

                  <div style={{ height: "100%" }}>
                    {/* <CustomMainInput
                      disabled
                      // height="100px"
                      label="Details"
                      value={details}
                      variant="multiline"
                    /> */}
                  </div>
                </GridItem>

                <GridItem xs={12}>
                  <Conversation />
                </GridItem>
              </GridContainer>
            </>
          </DialogContent>
        </Dialog>
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
