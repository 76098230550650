import React, { useContext, useEffect, useState } from "react";

// data
import useFetch from "functions/useFetch";
import useSaveAgendaItem from "functions/useSaveAgendaItem";
import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import { ContentContext } from "contexts/ContentContext";

import config from "config";
import cloneDeep from "lodash/cloneDeep";

// core components
import { CustomRichTextEditor } from "components/CustomRichTextEditor/CustomRichTextEditor";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// style
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "assets/jss/material-dashboard-react";

const styles = {
  disabled: {
    pointerEvents: "none",
  },
  middleSection: {
    minWidth: "400px",

    backgroundColor: primaryColor[8], //"white",
    display: "flex",
    flexDirection: "column",
    flex: 3,
    borderRadius: "7px",
    // border: `1px solid ${grayColor[6]}`,
    padding: "10px",
    height: "100%",
    overflowY: "auto",
    marginRight: "10px",
  },
  privateNotes: {
    display: "flex",
    flexDirection: "column",

    // height: "calc(65% - 10px)",
    height: "100%",
    marginTop: "10px",
    // marginBottom: "15px",

    minWidth: "50%",
  },
};

const useStyles = makeStyles(styles);

export const AgendaDetails = (props) => {
  const classes = useStyles();

  // const { agendaItems } = props;
  const { session, setSession } = useContext(SessionContext);
  const { firstUse } = session;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { meetingID, statusID, isOrganizer, parentEventID } = meeting;

  const { content, setContent } = useContext(ContentContext);
  const { refreshData, selectedItem } = content;

  // console.log(
  //   "%cloads AgendaDetails",
  //   "background-color: brown; color: white",
  //   {
  //     selectedItem,
  //   }
  // );
  // console.log(
  //   "%cRenders agenda details with props",
  //   "background-color: blue; color: white",
  //   { props, content }
  // );

  const fetchParameters = {
    url: `${config.url}/api/meeting-agenda-item-details`,
    method: "get",
    params: {
      meetingAgendaItemID: selectedItem.id,
      isRepeat: meetingID === null && parentEventID !== null, // this will allow to detect whether it should be fetched from recurrence table or agenda items table
    },
  };

  const { loadingFetch, errorFetch, dataFetch, hasMoreFetch, refetch } =
    useFetch({
      ...fetchParameters,
      params: JSON.stringify(fetchParameters.params), // has be sent as a string and parsed after, otherwise runs into loop because objects are sent a reference which changes every time
      skip: selectedItem.id === null || firstUse,
    });

  // console.log(
  //   "%cdataFetch output",
  //   "background-color: red; color: white",
  //   dataFetch
  // );

  // loads the data with empty values
  const blankDetailsData = {
    details: [
      {
        id: 1,
        type: "paragraph",
        children: [
          {
            text: "",
          },
        ],
      },
    ],
    privateNotes: [
      {
        id: 1,
        type: "paragraph",
        children: [
          {
            text: "",
          },
        ],
      },
    ],
  };

  const [detailsData, setDetailsData] = useState(blankDetailsData);

  useEffect(() => {
    // console.log("dataFetch", dataFetch);
    if (dataFetch !== null && dataFetch?.info?.details !== undefined) {
      const data = {
        privateNotes: dataFetch?.info?.privateNotes, //JSON.parse(dataFetch?.info?.privateNotes),
        details: dataFetch?.info?.details, //JSON.parse(dataFetch?.info?.details),
      };
      // console.log("loads data");

      setDetailsData(data);
    }
    // else {
    //   console.log("loads empty data");
    //   setDetailsData(blankDetailsData);
    // }
  }, [dataFetch]);

  // this is used for example when loading templates.  Since the agenda item id and the selected item don't change
  // fetch wouldn't execute
  useEffect(() => {
    refetch();
  }, [refreshData]);

  // show or hides the agenda depending on the meeting owner's account setting
  // also show or hide when using 1:1 meeting the owner has decided to hide to attendee

  return (
    <div
      // className={`${classes.middleSection} ${
      //   (!isOrganizer || [4, 5, 6].includes(statusID)) && classes.disabled
      // }`}

      className={`${classes.middleSection}`}
    >
      <Details details={detailsData.details} />

      {[3, 4, 5, 6, 7, 8].includes(statusID) && (
        <PrivateNotes privateNotes={detailsData.privateNotes} />
      )}
    </div>
  );
};

const PrivateNotes = (props) => {
  const classes = useStyles();

  const { privateNotes } = props;
  const { saveAgendaItem, tempContentRef } = useSaveAgendaItem();

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { meetingID, parentEventID, statusID } = meeting;

  const { content, setContent } = useContext(ContentContext);
  const { agendaItems, isSavingRef, preventTriggerChangeRef, selectedItem } =
    content;

  return (
    <div className={classes.privateNotes}>
      <CustomRichTextEditor
        // ref={notesRef}
        disabled={![3, 7].includes(statusID)}
        // disabled={false}
        title="Private Notes"
        content={privateNotes}
        sendInfoToParent={(latestInfo) => {
          const currentInfoString = JSON.stringify(privateNotes);
          const latestInfoString = JSON.stringify(latestInfo);

          // avoid updating date if nothing has changed
          const dataChanged = currentInfoString !== latestInfoString;
          // console.log("dataChanged", dataChanged);

          if (dataChanged) {
            console.log(
              "%cData is changing. Save to db",
              "background-color: orange; color: white",
              { currentInfoString, latestInfoString }
            );
            let agendaItemsCopy = cloneDeep(agendaItems);

            agendaItemsCopy[selectedItem.index].privateNotes = latestInfo;
            console.log("agendaItemsCopy", { agendaItemsCopy, selectedItem });
            saveAgendaItem({
              // optional
              newAgendaItems: agendaItemsCopy,
              privateNotes: latestInfo,
              orderBy: agendaItemsCopy[selectedItem.index].orderBy, //order_by
              // required
              parentEventID: parentEventID,
              meetingID: meetingID,
              content: content,
              setContent: setContent,
              operationType: "update",
            });
          } else {
            console.log(
              "%cData has not changed. Dont save to db",
              "background-color: orange; color: red",
              { currentInfoString, latestInfoString }
            );
            isSavingRef.current = false;
          }
        }}
      />
    </div>
  );
};

const Details = (props) => {
  const { details } = props;

  const { saveAgendaItem, tempContentRef } = useSaveAgendaItem();

  const { meeting, setMeeting, savePendingRef } = useContext(MeetingContext);
  const { isOrganizer, meetingID, parentEventID, statusID } = meeting;

  const { content, setContent } = useContext(ContentContext);
  const { agendaItems, isSavingRef, preventTriggerChangeRef, selectedItem } =
    content;
  // console.log(
  //   "%ccontent in Details component",
  //   "background-color: purple; color: white",
  //   details
  // );

  return (
    <>
      <CustomRichTextEditor
        preventTriggerChangeRef={preventTriggerChangeRef}
        disabled={
          !isOrganizer ||
          [4, 5, 6].includes(statusID) ||
          agendaItems.length === 0
        }
        // content={agendaItems[selectedItem.index]?.details}
        content={details}
        sendInfoToParent={(latestInfo) => {
          const currentInfoString = JSON.stringify(details);
          const latestInfoString = JSON.stringify(latestInfo);

          // avoid updating date if nothing has changed
          const dataChanged = currentInfoString !== latestInfoString;
          // console.log("dataChanged", dataChanged);

          if (dataChanged) {
            console.log(
              "%cData is changing. Save to db",
              "background-color: orange; color: white",
              { currentInfoString, latestInfoString }
            );
            let agendaItemsCopy = cloneDeep(agendaItems);

            agendaItemsCopy[selectedItem.index].details = latestInfo;
            saveAgendaItem({
              // optional
              newAgendaItems: agendaItemsCopy,
              details: latestInfo,
              // orderBy: agendaItemsCopy[1].orderBy,
              orderBy: agendaItemsCopy[selectedItem.index].orderBy,
              // required
              parentEventID: parentEventID,
              meetingID: meetingID,
              content: content,
              setContent: setContent,
              operationType: "update",
            });
          } else {
            console.log(
              "%cData has not changed. Dont save to db",
              "background-color: orange; color: red",
              { currentInfoString, latestInfoString }
            );

            isSavingRef.current = false;
          }
        }}
        showMedal
        title="Details"
        // onFocus={handleFocus}
        // onBlur={handleBlur}
      />
    </>
  );
};
