import React, { useState, forwardRef, useImperativeHandle } from "react";
// material-ui components
import Popper from "@material-ui/core/Popper";
import Popover from "@material-ui/core/Popover";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import Button from "components/CustomButtons/Button";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";

const styles = {};

const useStyles = makeStyles(styles);

const CustomPopover = forwardRef((props, ref) => {
  const classes = useStyles();
  const { children, clearAll, triggerComponent, onClose, title } = props;
  const placement = props.placement || "right";
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    console.log("close triggered");

    setPopoverAnchorEl(null);
    onClose && onClose();
  };

  useImperativeHandle(ref, () => ({
    close() {
      handlePopoverClose();
    },
  }));

  return (
    <>
      {triggerComponent(handlePopoverOpen)}

      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        // transition
        // disablePortal
        style={{ overflow: "visible" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            // Your custom styles for the Paper component go here
            overflow: "visible",
            marginTop: "10px",
          },
        }}
      >
        {children}
      </Popover>
    </>
  );
});
export default CustomPopover;
