import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { logError } from "functions/ErrorBoundary";

import { MeetingContext } from "./MeetingContext";

export const MeetingActionsContext = createContext();

export const MeetingActionsInfo = (props) => {
  const { meeting, setMeeting } = useContext(MeetingContext);
  const { meetingID } = meeting;
  const possibleOwnerListRef = useRef([]);

  const blankAction = {
    responsibleDesc: "",
    responsibleEmail: "",
    meetingActionId: 1,
    dueDate: null,
    desc: "",
    meetingId: meetingID,
    list: [], // attendees,
    fromPreviousMeeting: false,
    completed: false,
    deleteDate: null,
    errors: {},
  };

  const blankActionWithNewID = (initialArray) => {
    // let maxID = 1;
    const maxID = initialArray.reduce((group, item, index) => {
      // console.log("group", group);
      // console.log("item", item);

      if (Object.keys(group).length === 0 || item.value > group.value) {
        // return item;
        group = item.meetingActionId;
      }
      return group;
    }, 0);

    const blankItem = { ...blankAction, meetingActionId: maxID + 1 };
    // console.log("blankItem", blankItem);
    return blankItem;
    // return blankItem;
  };

  const ownerDropdownList = (inputValue, possibleOwnersList) => {
    try {
      // in case the input value, replace by empty string

      const filteredValues = possibleOwnerListRef?.current?.filter(
        (item) =>
          (item.name || "").toLowerCase().includes(inputValue.toLowerCase()) ||
          (item.email || "").toLowerCase().includes(inputValue.toLowerCase())
      );
      // console.log("los filtered values", filteredValues);

      return filteredValues;
    } catch (error) {
      logError(error, {
        // generalData: { ...generalData },
        otherData: {
          possibleOwnersList: possibleOwnerListRef?.current,
          inputValue,
        },
      });
    }
  };

  const initialState = {
    actionsState: [],
    ownerDropdownList: ownerDropdownList,
    errorState: null,
    blankActionWithNewID: blankActionWithNewID,
    blankAction: blankAction,
    refreshInfo: new Date(), // use to refresh data when socket signal is received
  };

  const [meetingActions, setMeetingActionsNotMemo] = useState(initialState);

  const setMeetingActions = useCallback((newValue) => {
    setMeetingActionsNotMemo(newValue);
  }, []);

  const memoizedValues = useMemo(
    () => ({
      meetingActions,
      setMeetingActions,
      possibleOwnerListRef,
    }),
    [meetingActions, setMeetingActions]
  );

  // const [meeting, setMeeting] = useState(initialState);

  // const memoizedValues = useMemo((item) => ({ meeting, setMeeting }));

  return (
    <MeetingActionsContext.Provider value={memoizedValues}>
      {props.children}
    </MeetingActionsContext.Provider>
  );
};
export default MeetingActionsInfo;
