import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
export const MeetingContext = createContext();

export const MeetingInfo = (props) => {
  const savePendingRef = useRef(false); // use when changing a repeat meeting and requires how to save the info (current meeting, next meetings or all meetings)

  const initialState = {
    meetingID: 0,
    variant: "new",
    medalsCompleted: [true, false, false, false],
    ratingElements: [],
    refreshInfo: new Date(), // use to refresh data when socket signal is received

    socketSignalData: {}, // use to trigger a socket signal
    withDurations: null,
    withAgenda: null,
  };

  const [meeting, setMeetingNotMemo] = useState(initialState);

  const setMeeting = useCallback((newValue) => {
    setMeetingNotMemo(newValue);
  }, []);

  const updateRating = (ratingElementID, isAchieved) => {
    // updates the rating indicator
    let newRatingElements = meeting.ratingElements.map((item) => ({
      ...item,
    }));
    let objectivesRatingIndex = newRatingElements.findIndex(
      (item) => item.id === ratingElementID
    );
    if (isAchieved) {
      newRatingElements[objectivesRatingIndex].value = 1;
    } else {
      newRatingElements[objectivesRatingIndex].value = 0;
    }

    console.log("newRatingElements", newRatingElements);

    setMeeting({ ...meeting, ratingElements: newRatingElements });
  };

  const memoizedValues = useMemo(
    () => ({
      meeting,
      setMeeting,
      updateRating,
      savePendingRef,
    }),
    [meeting, setMeeting]
  );

  // const [meeting, setMeeting] = useState(initialState);

  // const memoizedValues = useMemo((item) => ({ meeting, setMeeting }));

  return (
    <MeetingContext.Provider value={memoizedValues}>
      {props.children}
    </MeetingContext.Provider>
  );
};
export default MeetingInfo;
