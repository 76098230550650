import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CustomDialog from "components/CustomDialog/CustomDialog";
import TodoDetails from "./TodoDetails";

// data
import { SessionContext } from "contexts/SessionContext";
import { TodoContext } from "contexts/TodoContext";
import { useMutation } from "@apollo/client";
import { undoArchive, restoreArchive } from "graphql/gqlTodoModals";
import axios from "axios";
import { axiosOptions } from "../../functions/Common";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
// icons

// style

const styles = {};
const useStyles = makeStyles(styles);

export default function TodoModals(props) {
  // console.log("starts to do details");
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { todo, setTodo, modal, setModal, setShowSnackbar } =
      useContext(TodoContext);

    const [modalArchived, setModalArchived] = useState({
      visible: false,
      name: "",
      data: {},
    });

    const [restoreArchiveMut] = useMutation(restoreArchive, {
      onCompleted: () => {
        console.log("on completed");
        setModalArchived({ visible: false, name: "" });
        setModal({ visible: false, name: "", data: {} });
        setShowSnackbar({
          show: true,
          message: "Card restored successfully",
        });
        setTodo({
          ...todo,
          updateInfo: new Date(),
          updateArchived: new Date(),
        });
      },
    });

    const [undoArchiveMut] = useMutation(undoArchive, {
      onCompleted: () => {
        setTodo({
          ...todo,
          updateInfo: new Date(),
          updateArchived: new Date(),
        });

        setShowSnackbar({
          show: true,
          message: "Card restored successfully",
        });
      },
    });

    switch (modal.name) {
      case "toDoDetails": {
        console.log("shows tododetails");
        return (
          <TodoDetails
            // visibleStatus={modal}
            // setVisibleStatus={setModal}
            sendInfoToParent={(info) => {
              console.log("info", info);

              switch (info?.action) {
                case "archive":
                  setShowSnackbar({
                    show: true,
                    message: "Card achived successfully",
                    undo: () => {
                      undoArchiveMut({
                        variables: {
                          todoID: modal.data.todoID,
                        },
                      });
                    },
                  });
                  setTodo({
                    ...todo,
                    updateInfo: new Date(),
                    updateArchived: new Date(),
                  });

                  break;
                case "restore":
                  // setModalArchived({
                  //   visible: true,
                  //   name: "confirm-restore-archived",
                  //   data: {
                  //     toDoGroupIDSelected: info.toDoGroupIDSelected,
                  //   },
                  // });

                  modalRepeatEventTypeRef.current.openModal({
                    toDoGroupIDSelected: info.toDoGroupIDSelected,
                  });

                  break;

                default:
                  console.log("ejecuta el refetch");
                  // updates the to do list
                  setTodo({ ...todo, updateInfo: new Date() });
              }
            }}
          />
        );
      }

      case "emptyGroupFirst":
        return (
          <CustomDialog
            iconPreset="forbidden"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="This group must be empty before deleting"
            mainText="First delete or move the tasks that belong to this group"
            buttonCloseLabel="OK"

            // center
          />
        );

      case "notInPlanOwner": {
        return (
          <CustomDialog
            iconPreset="forbidden"
            center
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Plan limit reached"
            mainText={`You can't have more than ${modal.data.todoCapacity} active tasks in the Basic plan.  Your account has to upgrade to have unlimited tasks and unlock all the features`}
            buttonActionLabel1="Cancel"
            // buttonActionColor1="danger"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
            }}
            buttonActionLabel2="Upgrade your plan"
            buttonActionFunction2={() => navigate({ pathname: "/app/account" })}
          />
        );
      }

      case "notInPlanNotOwner": {
        return (
          <CustomDialog
            iconPreset="forbidden"
            center
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Plan limit reached"
            mainText={`You can't have more than ${modal.data.todoCapacity} active tasks in the Basic plan.  Contact your account owner to upgrade and have unlimited tasks`}
            buttonActionLabel1="OK"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
            }}
          />
        );
      }
      case "confirm-restore-archived": {
        return (
          <CustomDialog
            iconPreset="question"
            center
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Do you want to restore this board?"
            mainText={
              // if the the groupID of the selected board matches the selected group id of the list AND it was deleted

              modal.data.listDeleted
                ? "The original list of this board no longer exists.  The board will be restored at the bottom of the first list"
                : "The board will be restored at the bottom of the original list"
            }
            buttonActionLabel1="Cancel"
            // buttonActionColor1="danger"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
            }}
            buttonActionLabel2="Restore board"
            buttonActionFunction2={() => {
              console.log("las variables son", {
                todoID: modal.data.todoID,
                todoGroupID: modal.data.selectedTodoGroupID,
                restoreToBottom: true,
              });

              restoreArchiveMut({
                variables: {
                  todoID: modal.data.todoID,
                  todoGroupID: modal.data.selectedTodoGroupID,
                  restoreToBottom: true,
                },
              });
            }}
          />
        );
      }

      default:
        return null;
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal or critical",
      generalData: { ...generalData },
      // otherData: { modal },
    });
  }
}
