import { grayColor } from "assets/jss/material-dashboard-react";

const cardBodyStyle = {
  cardBody: {
    // backgroundColor: grayColor[7],
    padding: "10px 10px",

    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    // overflow: "auto",
    // maxHeight: "60vh",
    // height: "60vh",
    // paddingBottom: "120px",
  },
  cardNoScroll: { overflowY: "hidden" },
  cardBodyPlain: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  cardBodyProfile: {
    marginTop: "15px",
  },
  cardBodyFullscreen: {
    // marginTop: "15px",
    // minHeight: "75vh",
    // backgroundColor: "red",
    // paddingBottom: "120px",
    height: "100%",
    // position: "relative",
    // height: "100%",
    // flex: 1,
  },
  cardBodyDisabled: {
    pointerEvents: "none",
  },
};

export default cardBodyStyle;
