import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
} from "react";
import { useNavigate } from "react-router-dom";

// custom components
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import AvatarGroup from "components/AvatarGroup/AvatarGroup";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import CustomHeader from "components/CustomHeader/CustomHeader.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import DateRangeComp from "components/DateRange";
import ErrorHandler from "components/Error/Error";
import FilterComponent from "components/FilterComponent";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HelpDeskModals from "./HelpDeskModals";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SortComponent from "components/SortComponent";
import { RatingIndicator } from "components/RatingIndicator";
import Snackbar from "components/Snackbar/Snackbar";

import { Avatar } from "@material-ui/core";
import HelpDeskNew from "./HelpDeskNew";
import HelpDeskHistory from "./HelpDeskHistory";

// data
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ticketsList } from "graphql/gqlHelpDeskTickets";
import { HelpDeskContext } from "contexts/HelpDeskContext";
import { SessionContext } from "../../contexts/SessionContext";
import { SocketContext } from "contexts/SocketContext";

// errors
import {
  // ErrorBoundary,
  fallbackRender,
  errorComponent,
  logError,
  LogErrorComponent,
  useDataForLog,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// icons

// style

import { makeStyles } from "@material-ui/core/styles";
import HelpDeskOpenClosed from "./HelpDeskOpenClosed";

const styles = {};

const useStyles = makeStyles(styles);

export default function HelpDeskTickets() {
  // console.log("starts HelpDeskTickets");
  // console.log("%cStarts Ticket list", "background-color: red; color: white");

  const generalData = useDataForLog(); // context data to populate error log
  try {
    const classes = useStyles();

    const { session, setSession } = useContext(SessionContext);
    const { userID, languageID } = session;

    const { socket } = useContext(SocketContext);

    const {
      helpDesk,
      setHelpDesk,
      modal,
      setModal,
      showSnackbar,
      setShowSnackbar,
      lastTicketDisplayedRef,
    } = useContext(HelpDeskContext);
    const { updateInfo } = helpDesk;

    const navigate = useNavigate();

    const previousDataRef = useRef();

    const { loading, data, error, refetch, fetchMore } = useQuery(ticketsList, {
      variables: {
        userID: userID,
        languageID: languageID,
        cursor: null,
        pageSize: 10,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });

    // const [modal, setModal] = useState({
    //   visible: false,
    //   name: "",
    // });

    useEffect(() => {
      refetch();
    }, [updateInfo]);

    useEffect(() => {
      socket.on("receiving_helpDesk_update", (data) => {
        if (data.userTo === userID) {
          refetch();
        }
      });
    }, [socket]);

    const lastCursorRef = useRef();
    const hasMoreDataRef = useRef(true); // expects to have more data, if not, will not return anything

    // this code allow to detect if the last ticket is displayed and if true, retrieve more data
    const observerLast = useRef();
    const lastTicketDisplayedCallback = useCallback(
      (node) => {
        if (loading) return;
        if (observerLast.current) observerLast.current.disconnect();

        const observerCallback = (entries) => {
          if (entries[0].isIntersecting) {
            // setPageNumber(pageNumber + 1);

            const lastCursor =
              lastCursorRef.current === undefined
                ? data.spHelpdeskListClosed.pageInfo.endCursor
                : lastCursorRef.current;
            // console.log(
            //   "%cHas more data",
            //   "background-color: blue; color: white",
            //   hasMoreDataRef.current
            // );

            // if there's more data, fetches more
            if (hasMoreDataRef.current) {
              console.log(
                "%cfetches more",
                "background-color: orange; color: white"
              );

              fetchMore({
                variables: {
                  cursor: lastCursor,
                },

                // this defines how the new information should be combined with the previous
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  // const hasMoreResults =
                  //   fetchMoreResult.spUsersSubscription.pageInfo.hasNextPage;

                  if (hasMoreDataRef.current) {
                    // after fetching more check if has next page updates ref for next fetchMore

                    hasMoreDataRef.current =
                      fetchMoreResult.spHelpdeskListClosed.pageInfo.hasNextPage;

                    lastCursorRef.current =
                      fetchMoreResult.spHelpdeskListClosed.pageInfo.endCursor;

                    // combines the previous result with the new result
                    const newResult = {
                      ...previousResult,
                      spHelpdeskListClosed: {
                        ...previousResult.spHelpdeskListClosed,
                        nodes: [
                          ...previousResult.spHelpdeskListClosed.nodes,

                          ...fetchMoreResult.spHelpdeskListClosed.nodes,
                        ],

                        // ...fetchMoreResult.usersSubscription.spUsersSubscription
                        //   .nodes[0],
                      },
                    };
                    console.log("results", {
                      previousResult: {
                        ...previousResult.spHelpdeskListClosed.nodes,
                      },
                      newResult: newResult.spHelpdeskListClosed.nodes,
                      fetchMoreResult:
                        fetchMoreResult.spHelpdeskListClosed.nodes,
                    });

                    return newResult;
                  } else {
                    hasMoreDataRef.current = false;
                    return previousResult;
                  }
                },
              });
            }
          }
        };

        const observer = new IntersectionObserver(observerCallback, {
          threshold: 1,
        });
        if (node) observer.observe(node);
      },
      [loading]
    );

    lastTicketDisplayedRef.current = lastTicketDisplayedCallback;

    useEffect(() => {
      setHelpDesk({ ...helpDesk, loading });
    }, [loading]);

    if (error) {
      return (
        <ErrorHandler error={error} btnActionFunction2={() => refetch()} />
      );
    }

    let latestData;
    if (loading === true) {
      if (previousDataRef.current === undefined) {
        // console.log(
        //   "%clatestData 1  previous data ref is undefined",
        //   "background-color: red; color: white",
        //   previousDataRef.current
        // );

        latestData = undefined;
      } else {
        // console.log(
        //   "%clatestData 2 from previous data ref",
        //   "background-color: red; color: white",
        //   previousDataRef.current
        // );

        latestData = previousDataRef.current;
      }
    } else {
      // console.log(
      //   "%clatestData 3 from new data",
      //   "background-color: red; color: white",
      //   data
      // );
      previousDataRef.current = data;
      latestData = data;
    }

    if (latestData !== undefined) {
      // console.log(
      //   "%cLatest data",
      //   "background-color: red; color: white",
      //   latestData
      // );

      const RightButtons = () => {
        return (
          <Button
            onClick={() => setModal({ visible: true, name: "new-ticket" })}
            color="primary"
            // startIcon={<CancelOutlinedIcon />}
          >
            New ticket
          </Button>
        );
      };

      return (
        <>
          <Card fullscreen transparentBackground>
            <CustomHeader
              fullscreen
              // groupList={data && data.spTicketGroupsPerUser.nodes}
              // oneOnOneList={data && data.spTicket1On1ListPerUser.nodes}
              headerColor="white"
              // headerVariant="list"
              // parametersSelected={parameterList}
              rightAlignItems={<RightButtons />}
            />
            <CardBody fullscreen noScroll>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // flex: 1,
                  // height: "100%",

                  // backgroundColor: "yellow",
                  padding: "0px 10px",
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  width: "100%",
                }}
              >
                <HelpDeskOpenClosed
                  selector="open"
                  data={latestData.spHelpdeskListOpen.nodes}
                />
                <HelpDeskOpenClosed
                  selector="closed"
                  data={latestData.spHelpdeskListClosed.nodes}
                />
                {/* {latestData.spHelpdeskListOpen.nodes.length > 0 && (
                  <TicketsOpen />
                )} */}

                {/* <TicketsClosed /> */}
              </div>

              {/* {loading ||
                (isLoading && <ActivityIndicator fullscreen withShade />)} */}
            </CardBody>
          </Card>
          <HelpDeskModals />
          <Snackbar
            messageType={showSnackbar.messageType}
            message={showSnackbar.message}
            open={showSnackbar.show}
            undo={showSnackbar.undo}
            close={() => setShowSnackbar({ ...showSnackbar, show: false })}
          />
        </>
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
