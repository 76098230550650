import { gql } from "@apollo/client";

export const todoPriorities = gql`
  query todoDetails($languageID: String!) {
    allLanguages(condition: { langShort: $languageID }) {
      nodes {
        to_do_priorities: translationToDoPrioritiesByLanguageId {
          nodes {
            id: priorityId
            menuDesc: desc
          }
        }
      }
    }
  }
`;
