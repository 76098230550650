// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
// https://www.toptal.com/software/definitive-guide-to-datetime-manipulation
import { useContext } from "react";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import differenceInDays from "date-fns/differenceInDays";
import compareAsc from "date-fns/compareAsc";

import { es, enUS } from "date-fns/locale";
import locale from "date-fns/locale/en-US";
// import _ from "lodash";
import isString from "lodash/isString";
import capitalize from "lodash/capitalize";
import config from "config";
import { LogErrorComponent } from "functions/ErrorBoundary";
import { useErrorBoundary } from "react-error-boundary";
import axios from "axios";

// ---------------------------------------------------------------------------------------
//                          CONVERT TIME
// ---------------------------------------------------------------------------------------

// converts UTC date to local date keeping the date object
// outputs yyyy-mm-dd hh:mm:ss:sss
// e.g 2021-08-16T16:00:00Z will be 2021-08-16T10:00:00 if offset is -6:00
export const convertLocal = (dateReceivedISO) => {
  console.log("dateReceivedISO", dateReceivedISO);

  const dateConverted = new Date(dateReceivedISO);
  console.log("dateConverted", dateReceivedISO);

  const offset = dateConverted.getTimezoneOffset();
  console.log("offset", offset);

  const dateOutput = new Date();
  console.log("new Date", dateOutput);

  dateOutput.setTime(dateConverted.getTime() - offset - 50);
  console.log("date output", dateOutput);
  return dateOutput;
};

// converts UTC date to local date keeping the ISO format
// outputs as string yyyy-mm-ddThh:mm:ss:sss
// e.g "2021-08-16T16:00:00Z" will be "2021-08-16T10:00:00" if offset is -6:00
export const convertLocalISO = (dateReceivedISO) => {
  const dateConverted = new Date(dateReceivedISO);
  const offset = dateConverted.getTimezoneOffset() * 60000;

  const dateTemp = new Date(
    dateConverted.setTime(dateConverted.getTime() - offset)
  );
  const dateOutput = dateTemp.toISOString().slice(0, -1);
  return dateOutput;
  // if (Platform.OS === "ios") {
  //   const dateConverted = new Date(dateReceivedISO);
  //   const offset = dateConverted.getTimezoneOffset() * 60000;

  //   // substracts two times the offset.  The first is the compensate new Date()
  //   // will move forward to UTC in iOS.  The second will truly offset the timezone
  //   const dateTemp = new Date(
  //     dateConverted.setTime(dateConverted.getTime() - offset - offset)
  //   );
  //   const dateOutput = dateTemp.toISOString().slice(0, -1);
  //   return dateOutput;
  // } else {
  //   const dateConverted = new Date(dateReceivedISO);
  //   const offset = dateConverted.getTimezoneOffset() * 60000;

  //   const dateTemp = new Date(
  //     dateConverted.setTime(dateConverted.getTime() - offset)
  //   );
  //   const dateOutput = dateTemp.toISOString().slice(0, -1);
  //   return dateOutput;
  // }
};

// converts local date to UTC date keeping the ISO format
// outputs as string yyyy-mm-ddThh:mm:ss:sssZ
// e.g "2021-08-16T10:00:00" will be "2021-08-16T16:00:00Z" if offset is -6:00
export const convertLocalToUTC = (dateReceivedISO) => {
  const dateConverted = new Date(dateReceivedISO);
  const dateOutput = dateConverted.toISOString();

  return dateOutput;
};

// receives a date as ISO string or date.  Output as string without Z.
// if no timeOffset is supplied, will convert to UTC
// e.g 2021-08-16T10:00:00 will be 2021-08-16T16:00:00 if offset is -6:00
export const convertToTimezone = (
  dateReceived,
  outputAsString,
  timeOffsetSeconds
) => {
  // const alreadyUTC = false;
  // console.log("el data received es", dateReceived);
  if (dateReceived !== undefined) {
    let dateOutput = null;
    if (isString(dateReceived)) {
      // it's a string

      if (dateReceived.slice(-1) === "Z") {
        // console.log("input is string and utc");
        // input is already utc.
        // (same treatment for iOS and Android)
        dateOutput = new Date(dateReceived);
      } else {
        // input is not UTC
        // if (Platform.OS === "ios") {
        //   // is iOS
        //   dateOutput = new Date(dateReceived);
        // } else {
        //   // console.log("input is string, not UTC and Android");
        //   // is Android
        //   const dateConverted = new Date(dateReceived);
        //   // console.log("dateConverted", dateConverted);
        //   const offset = dateConverted.getTimezoneOffset() * 60000;
        //   // console.log("offset", offset);
        //   dateOutput = new Date();
        //   dateOutput.setTime(dateConverted.getTime() + offset);
        //   // console.log("dateOutput", dateOutput);
        // }

        const dateConverted = new Date(dateReceived);
        const offset = dateConverted.getTimezoneOffset() * 60000;
        dateOutput = new Date();
        dateOutput.setTime(dateConverted.getTime() + offset);
      }
    } else {
      // (same treatment for iOS and Android)
      dateOutput = new Date(dateReceived);
    }

    if (timeOffsetSeconds) {
      dateOutput.setTime(dateOutput.getTime() + timeOffsetSeconds * 1000);
    }

    // output to string if required.  Otherwise, outputs as date
    if (outputAsString === true) {
      // console.log("output as string");
      return dateOutput.toISOString().slice(0, -1);
    }
    // console.log("output as date");
    return dateOutput;
  }
};

export const removeTime = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const convertAMPMTo24 = (timeString) => {
  if (timeString === null) {
    return "00:00";
  } else {
    const lastChar = timeString.slice(-1);
    if (lastChar.toLowerCase() === "m") {
      // // convert to 24 hour time
      // let hoursConverted = parseInt(timeString.substring(0, 2));
      // // detects if am or pm
      // const meridien = timeString.slice(-2).toLowerCase();
      // if (meridien === "pm") {
      //   hoursConverted = hoursConverted + 12;
      // }
      // hoursConverted = hoursConverted.toString().padStart(2, "0"); //padStart adds leading zeros
      // // joins the new hours with only the minute without am/pm
      // const newTime = hoursConverted + timeString.substring(2, 5);
      // return newTime;

      // Input time string
      // var inputTime = "1:15 pm";
      const [time, meridian] = timeString.split(" ");
      const [hours, minutes] = time.split(":");

      let hoursNumber = parseInt(hours);

      // Adjust hours based on meridian (am/pm)
      if (meridian === "pm" && hoursNumber < 12) {
        hoursNumber += 12;
      } else if (meridian === "am" && hoursNumber === 12) {
        hoursNumber = 0;
      }
      // padstart adds a leading zero when hour is single digit
      const timeConverted =
        String(hoursNumber).padStart(2, "0") + ":" + minutes;
      return timeConverted;

      console.log("finalTime:", finalTime);
    } else {
      //don't do anything
      return timeString;
    }
  }
};

export const convert24ToAMPM = (timeString) => {
  const lastChar = timeString.slice(-1);
  if (lastChar.toLowerCase() === "m") {
    // already am/pm. Don't do anything
    return timeString;
  } else {
    let meridiem = "am";
    // convert hours to numbers
    let hoursConverted = parseInt(timeString.substring(0, 2));
    if (hoursConverted >= 12) {
      meridiem = "pm";
      hoursConverted = hoursConverted - 12;
    }

    // convert the hourse to string
    hoursConverted = hoursConverted.toString();

    // joins the new hours with only the minute and am/pm
    const newTime =
      hoursConverted + timeString.substring(2, 5) + " " + meridiem;
    return newTime;
  }
};

// ---------------------------------------------------------------------------------------
//                          FORMAT TIME
// ---------------------------------------------------------------------------------------

// output as 10:00 am

// output dates as Mar 22 mar 2022
export const formatDateCustom = (
  dateReceivedISO,
  formatString,
  errorTracingData
) => {
  try {
    if (dateReceivedISO == null) {
      return null;
    } else {
      const formatDate = capitalize(
        format(parseISO(dateReceivedISO), formatString, { locale: enUS })
      );
      return dateReceivedISO !== undefined ? formatDate : null;
    }
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

export const formatTime = (dateReceivedISO, errorTracingData) => {
  try {
    if (dateReceivedISO !== undefined) {
      const inputDate = new Date(dateReceivedISO);
      const formattedDate = format(inputDate, "h:mm aaa");
      return dateReceivedISO !== undefined ? formattedDate : null;
    }
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// output dates as yyyy-mm-dd
export const formatDate = (dateReceivedISO, errorTracingData) => {
  try {
    if (dateReceivedISO == null) {
      return null;
    } else {
      const inputDate =
        dateReceivedISO == null ? null : new Date(dateReceivedISO);
      const formattedDate = format(inputDate, "d-MMM-yy");
      return dateReceivedISO !== undefined ? formattedDate : null;
    }
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// output dates as Mar 22 mar 2022
export const formatDateShort = (dateReceivedISO, errorTracingData) => {
  try {
    if (dateReceivedISO == null) {
      return null;
    } else {
      const formatDate = capitalize(
        format(parseISO(dateReceivedISO), "eee d MMM yyyy")
      );
      return dateReceivedISO !== undefined ? formatDate : null;
    }
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// output dates as Mar 22 mar 2022
export const formatDateMedium = (dateReceivedISO, errorTracingData) => {
  try {
    if (dateReceivedISO == null) {
      return null;
    } else {
      const inputDate =
        dateReceivedISO == null ? null : new Date(dateReceivedISO);
      const formattedDate = capitalize(
        format(dateReceivedISO, "eee d MMM yyyy", { locale: enUS })
      );
      console.log("formatted date", inputDate);
      return dateReceivedISO !== undefined ? formattedDate : null;
    }
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// output dates as Lunes 8 de Febrero
export const formatDateLong = (dateReceivedISO, errorTracingData) => {
  try {
    const formatDate = capitalize(
      format(parseISO(dateReceivedISO), "EEEE d MMMM yyyy", { locale: enUS })
    );
    return dateReceivedISO !== undefined ? formatDate : null;
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// format date as e.g. 18/2/2021, 9:00 PM
export const formatDateTime = (dateReceived, errorTracingData) => {
  // const { showBoundary } = useErrorBoundary();

  try {
    const dateConverted = new Date(dateReceived);
    let dateOutput = null;
    // const offset = dateConverted.getTimezoneOffset() * 60000;
    // dateConverted.setTime(dateConverted.getTime() + offset);

    dateOutput = format(dateConverted, "d-MMM-yy h:mm aaa");
    return dateReceived == null ? null : dateOutput;
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// format date as e.g. 18/2 9:00 PM
export const formatDateTimeMedium = (dateReceivedISO, errorTracingData) => {
  try {
    const formatDate =
      format(parseISO(dateReceivedISO), "P", { locale: enUS })
        .toString()
        .slice(0, -5) +
      " " +
      format(parseISO(dateReceivedISO), "p", { locale: enUS });
    return dateReceivedISO !== undefined ? formatDate : null;
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// format date as e.g. Mon 8 Apr 2024 8:30am
export const formatDateTimeMedium2 = (dateReceivedISO, errorTracingData) => {
  try {
    const formatDate = capitalize(
      format(parseISO(dateReceivedISO), "eee d MMM yyyy  h:mm aaa", {
        locale: enUS,
      })
    );
    return dateReceivedISO !== undefined ? formatDate : null;
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// format date as e.g. Monday, 8 April 2024 8:30am
export const formatDateTimeMedium3 = (dateReceivedISO, errorTracingData) => {
  try {
    const formatDate = format(
      parseISO(dateReceivedISO),
      "eeee, d LLLL yyyy,  h:mm aaa",
      {
        locale: locale,
      }
    );

    return dateReceivedISO !== undefined ? formatDate : null;
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// format date as e.g. D20
export const formatDayDate = (dateReceivedISO) => {
  try {
    const formatDate =
      capitalize(format(parseISO(dateReceivedISO), "EEEEE", { locale: enUS })) +
      format(parseISO(dateReceivedISO), "d", { locale: enUS });
    return dateReceivedISO !== undefined ? formatDate : null;
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// removes the UTC information and keeps the same time
// doesn't convert with time offset
// e.g 2021-08-16T16:00:00Z will be 2021-08-16T16:00:00 (without the ending Z)
export const formatLocalISO = (dateReceivedISO, errorTracingData) => {
  try {
    let dateOutput = null;
    const dateConverted = new Date(dateReceivedISO);
    const offset = dateConverted.getTimezoneOffset() * 60000;

    // substracts two times the offset.  The first is the compensate new Date()
    // will move forward to UTC in iOS.  The second will truly offset the timezone
    const dateTemp = new Date(
      dateConverted.setTime(dateConverted.getTime() - offset)
    );
    dateOutput = dateTemp.toISOString().slice(0, -1);
    return dateOutput;
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// ---------------------------------------------------------------------------------------
//                          FORMAT NUMBERS
// ---------------------------------------------------------------------------------------

export const formatNumber = (input, errorTracingData) => {
  try {
    switch (input) {
      case undefined:
        return "";
      case null:
        return "";
      default:
        parseFloat(input).toFixed(2);
    }
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

export const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const timeToString = (time, errorTracingData) => {
  try {
    const date = new Date(time);
    return date.toISOString().split("T")[0];
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// ---------------------------------------------------------------------------------------
//                          STRINGS
// ---------------------------------------------------------------------------------------

export const titleCase = (str) =>
  str !== null
    ? str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      )
    : null;

export const sentenceCase = (str) =>
  str !== null ? str.charAt(0).toUpperCase() + str.slice(1) : null;

export const convertStringForSearch = (str) =>
  str !== null
    ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s/g, "") // remove spaces
        .toLowerCase() // convert to lower case
    : "";

export const leadZero = (num, places) => String(num).padStart(places, "0");

// ---------------------------------------------------------------------------------------
//                          OTHER
// ---------------------------------------------------------------------------------------

export const addMonthsToDate = (date, months = 1, errorTracingData) => {
  try {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

export const addDaysToDate = (date, days, errorTracingData) => {
  try {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    // console.log("new date in addDaysToDate", result);
    return result;
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

export const addMinutesToDate = (date, minutes, errorTracingData) => {
  try {
    const result = new Date(date.getTime() + minutes * 60000);
    // result.setDate(result.getTime() + minutes * 60000);
    return result;
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// shows time difference as e.g. 1h 45m
export const timeDifference = (
  date1,
  date2,
  showSign,
  showUntil,
  errorTracingData
) => {
  // console.log("las fechas son", { date1, date2 });
  try {
    if (date1 === null || date2 === null) {
      return null;
    } else {
      const inputDate1 = new Date(date1);
      const inputDate2 = new Date(date2);
      // console.log("las fechas son", { inputDate1, inputDate2 });
      let sign;
      if (inputDate1 > inputDate2 && showSign !== false) {
        sign = "+";
      } else if (inputDate1 < inputDate2 && showSign !== false) {
        sign = "-";
      } else {
        sign = "";
      }

      let delta = Math.abs(inputDate1 - inputDate2) / 1000;

      // calculate (and subtract) whole days
      const days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      const hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      // const minutes =
      //   showMinutes !== false ? leadZero(Math.floor(delta / 60) % 60, 2) : "";

      const minutes = Math.floor(delta / 60) % 60;

      delta -= minutes * 60;

      const seconds = Math.floor(delta) % 60;

      // delta -= seconds * 60;

      // if (days === 0) {
      //   if (minutes === 0 && hours === 0) {
      //     return "0h 00m";
      //   }
      //   if (hours === 0) {
      //     return `${sign}${minutes}m`;
      //   }
      //   return `${sign}${hours}h ${minutes}m`;
      // } else {
      //   return `${sign}${days}d ${hours}h ${minutes}m`;
      // }

      const defaultTimeLeft = () => {
        if (days === 0) {
          if (hours === 0) {
            return `${sign}${minutes}m ${seconds}s`;
          } else {
            return `${sign}${hours}h ${minutes}m ${seconds}s`;
          }
        } else {
          return `${sign}${
            days !== 0 && days + "d"
          } ${hours}h ${minutes}m ${seconds}s`;
        }
      };

      switch (showUntil) {
        case "days":
          if (days === 0) {
            return "0d";
            // return null;
          } else {
            return `${sign}${days}d`;
          }

        case "hours":
          if (days === 0) {
            if (hours === 0) {
              return "0h";
            }
            return `${sign}${hours}h`;
          } else {
            return `${sign}${days}d ${hours}h`;
          }

        case "minutes":
          if (days === 0) {
            if (minutes === 0 && hours === 0) {
              return "0h 00m";
            }
            if (hours === 0) {
              return `${sign}${minutes}m`;
            }
            return `${sign}${hours}h ${minutes}m`;
          } else {
            return `${sign}${days}d ${hours}h ${minutes}m`;
          }

        default:
          return defaultTimeLeft();
      }
    }
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// shows time difference as e.g. 1h 45m
export const daysDifference = (date1, date2, errorTracingData) => {
  try {
    const inputDate1 = new Date(date1);
    const inputDate2 = new Date(date2);

    return differenceInDays(inputDate1, inputDate2);
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

export const numberToTime = (
  seconds,
  version = 1,
  showSign = true,
  errorTracingData = null,
  showElements = { months: true, days: true, hours: true, minutes: true } // can optionally show or hide an element regardless of whether it has values or not
) => {
  /*
  version 1 = formatted as 2:40
  version 2 = formatted as 2h 40m

  */
  try {
    // console.log("seconds", seconds);
    let sign;
    if (seconds > 0) {
      sign = "+";
    } else {
      sign = "";
    }
    /*
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor(seconds / 3600) % 24;
    const minutesInput = seconds / 60;
    const minutes = minutesInput % 60;
*/

    let minutes = Math.floor(seconds / 60);
    seconds %= 60;
    let hours = Math.floor(minutes / 60);
    minutes %= 60;
    let days = Math.floor(hours / 24);
    hours %= 24;
    let months = Math.floor(days / 30); // Average number of days in a month
    days %= 30; // Assuming 30 days in a month

    //const hours = Math.floor(minutesInput / 60);

    switch (version) {
      case 1:
        return `${showSign ? sign : ""}${hours}:${leadZero(minutes, 2)}`;

      case 2:
        //  e.g. 1h 10m
        let monthsString;
        if (months === 0) {
          monthsString = "";
        } else {
          monthsString =
            showElements["months"] === undefined ||
            showElements["months"] === true
              ? `${months}mth `
              : "";
        }

        let daysString;
        if (months === 0 && days === 0) {
          daysString = "";
        } else {
          daysString =
            showElements["days"] === undefined || showElements["days"] === true
              ? `${days}d `
              : "";
        }

        let hoursString;
        if (months === 0 && days === 0 && hours === 0) {
          hoursString = "";
        } else {
          hoursString =
            showElements["hours"] === undefined ||
            showElements["hours"] === true
              ? `${hours}h `
              : "";
        }

        let minutesString;
        minutesString =
          showElements["minutes"] === undefined ||
          showElements["minutes"] === true
            ? `${minutes}m`
            : "";

        let signString;
        if (showSign) {
          signString = `${sign}`;
        } else {
          signString = "";
        }

        // return `${showSign ? sign : ""}${hoursString} ${leadZero(minutes, 2)}m`;
        return `${signString}${monthsString}${daysString}${hoursString}${minutesString}`;
      default:
        return null;
    }
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// compare only the dates without time.  Returns true or false if equal or not
export const compareDatesWithoutTime = (
  dateISO1,
  dateISO2,
  errorTracingData
) => {
  try {
    // // console.log("las fechas son", { date1: dateISO1, date2: dateISO2 });
    // const date1 = new Date(dateISO1);
    // const date2 = new Date(dateISO2);

    // // reset the time information so date only can be compared
    // date1.setHours(0, 0, 0, 0);
    // date2.setHours(0, 0, 0, 0);
    // // console.log("las fechas convertidas son", { fecha1: date1, fecha2: date2 });
    // // console.log("comparación", compareAsc(date1, date2));
    // return compareAsc(date1, date2);

    let getDate1 = new Date(dateISO1);

    let getDate2 = new Date(dateISO2);

    if (getDate1.toDateString() === getDate2.toDateString()) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

export const dateRounded = (dateReceived, errorTracingData) => {
  try {
    const minutes = 1;
    const ms = 1000 * 60 * minutes;

    return new Date(Math.floor(dateReceived.getTime() / ms) * ms);
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

// device time offset in seconds
export const currentUTCTimezoneOffset = new Date().getTimezoneOffset() * -60;

let timeoutId;
export const debounce = (func, wait) => {
  // delays execution of function
  clearTimeout(timeoutId);
  timeoutId = setTimeout(func, wait || 500);
};

export const axiosOptions = (props) => {
  try {
    const { data, url, method, params } = props;

    // // Add a request interceptor
    // axios.interceptors.request.use(
    //   function (config) {
    //     config.errorContext = new Error("Thrown at:");

    //     return config;
    //   },
    //   function (error) {
    //     // Do something with request error
    //     return Promise.reject(error);
    //   }
    // );

    // // Add a response interceptor
    // axios.interceptors.response.use(
    //   function (response) {
    //     // Any status code that lie within the range of 2xx cause this function to trigger
    //     // Do something with response data

    //     return response;
    //   },
    //   function (error) {
    //     // Any status codes that falls outside the range of 2xx cause this function to trigger
    //     // Do something with response error
    //     const originalStackTrace = error.config?.errorContext?.stack;
    //
    //     console.log("**** interceptor error", error);

    //     return Promise.reject(error);
    //   }
    // );

    const content = props.content || null;
    const otherHeaders = props.otherHeaders || {};

    const refreshToken = localStorage.getItem("lkd(9dk"); // refresh token
    const accessToken = sessionStorage.getItem("kjd/afd"); // accessToken
    const userID = parseInt(sessionStorage.getItem("lkal89d97"));

    // console.log(
    //   "%caccessToken",
    //   "background-color: yellow; color: red",
    //   accessToken === null || accessToken === undefined
    //     ? undefined
    //     : `Bearer ${accessToken}`
    // );

    // if data is provided, outputs data, otherwise output params.
    // Params are used with get and data with post
    const paramsOrdata =
      data === undefined ? { params: params } : { data: data };

    // console.log("%caxiosRequest", "background-color: blue; color: white", {
    //   url: url,
    //   timeout: config.timeout,
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //     userID: userID,
    //     refresh: refreshToken,
    //     "Content-Type": content === null ? "application/json" : content,
    //     ...otherHeaders,
    //   },
    //   method: method,
    //   ...paramsOrdata,
    // });

    return {
      url: url,
      timeout: config.timeout,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        userID: userID,
        refresh: refreshToken,
        "Content-Type": content === null ? "application/json" : content,
        ...otherHeaders,
      },
      method: method,
      ...paramsOrdata,
    };
  } catch (error) {
    return <LogErrorComponent error={error} data={errorTracingData} />;
  }
};

export const snakeToCamel = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Base case: if obj is not an object or is null, return it as is
  }

  // If obj is an array, map over its elements and recursively call snakeToCamel on each element
  if (Array.isArray(obj)) {
    return obj.map(snakeToCamel);
  }

  // Create a new object to store the converted keys
  const camelObj = {};

  // Convert each key from snake_case to camelCase and add it to the new object
  for (const [key, value] of Object.entries(obj)) {
    const camelKey = key.replace(/_([a-z])/g, function (match, letter) {
      return letter.toUpperCase();
    });

    // Recursively convert nested objects
    camelObj[camelKey] = snakeToCamel(value);
  }

  return camelObj;
};

export const camelToSnake = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Base case: if obj is not an object or is null, return it as is
  }

  // If obj is an array, map over its elements and recursively call camelToSnake on each element
  if (Array.isArray(obj)) {
    return obj.map(camelToSnake);
  }

  // Create a new object to store the converted keys
  const snakeObj = {};

  // Convert each key from camelCase to snake_case and add it to the new object
  for (const [key, value] of Object.entries(obj)) {
    const snakeKey = key.replace(/[A-Z]/g, function (match) {
      return "_" + match.toLowerCase();
    });

    // Recursively convert nested objects
    snakeObj[snakeKey] = camelToSnake(value);
  }

  return snakeObj;
};
