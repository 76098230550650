import React, { createContext, useState } from "react";
export const DashboardContext = createContext();

export const DashboardInfo = (props) => {
  const initialState = {
    startDate: new Date(),
    endDate: new Date(),
  };
  const [dashboard, setDashboard] = useState(initialState);
  return (
    <DashboardContext.Provider value={{ dashboard, setDashboard }}>
      {props.children}
    </DashboardContext.Provider>
  );
};
export default DashboardInfo;
