import { gql } from "@apollo/client";

export const addMeeting = gql`
  mutation meetingNew($userID: Int!) {
    spMeetingNew(input: { createdBy1: $userID }) {
      meetingIds
    }
  }
`;

// export const addMeeting = gql`
//   mutation addMeeting($accountID: Int!, $userID: Int!) {
//     createMeeting(
//       input: { meeting: { createdBy: $userID, accountId: $accountID } }
//     ) {
//       clientMutationId
//     }
//   }
// `;

export const meetingMisc = gql`
  query meetingList($accountID: Int!, $userID: Int!, $languageID: String) {
    spMeetingGroupsPerUser(userId1: $userID) {
      nodes {
        id
        menuDesc: desc
      }
    }
    spMeeting1On1ListPerUser(userId1: $userID) {
      nodes {
        id
        menuDesc: desc
      }
    }
    spUsersSubscription(userId1: $userID) {
      nodes {
        hasAccess
      }
    }
    spPlanLimits(accountId1: $accountID, userId1: $userID) {
      nodes {
        meetingSlotsCapacity
        meetingSlotsAvailable
        createMeeting
      }
    }
    spMeetingListDropdown(langShort1: $languageID, userId1: $userID) {
      nodes {
        id
        groupsList
        statusList
        attendeesList
        organizersList
        conditionsList
        sortList
      }
    }
  }
`;

// export const meetingList = gql`
//   query meetingList(
//     $userID: Int!
//     $languageID: String
//     $meetingGroupID: Int
//     $filterID: Int
//     $searchTerm: String
//   ) {
//     spMeetingList(
//       langShort1: $languageID
//       meetingGroupId1: $meetingGroupID
//       filterId1: $filterID
//       search1: $searchTerm
//       userId1: $userID
//     ) {
//       nodes {
//         title
//         statusId
//         status
//         id
//         endDateFinal
//         date
//         cost
//         attendees
//         meetingGroup
//         meetingGroupId
//         organizerName
//       }
//     }
//     spMeetingGroupsPerUser(userId1: $userID) {
//       nodes {
//         id
//         menuDesc: desc
//       }
//     }

//     spUsersSubscription(userId1: $userID) {
//       nodes {
//         hasAccess
//       }
//     }
//     spPlanLimits(userId1: $userID) {
//       nodes {
//         createMeeting
//       }
//     }
//   }
// `;
