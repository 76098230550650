import React, { createContext, useState, useRef, useMemo } from "react";

import config from "config";
import { io } from "socket.io-client";

// const storeName = sessionStorage.getItem("storeName");
// const storeID = parseInt(sessionStorage.getItem("storeID"));
// const storeStatus =
//   sessionStorage.getItem("storeStatus") === "true" ? true : false;

// StageID: 0  =>  no subscription
// StageID: 1  =>  under third party subscription
// StageID: 2  =>  in trial period ***inactive***
// StageID: 20  => in basic plan
// StageID: 3  =>  trial period expired, no subscription created ***inactive***
// StageID: 4  =>  active subscription
// StageID: 5  =>  subscription in renewal period
// StageID: 6  =>  subscription expired

// const initialState = {
//   accountID: null, // parseInt(sessionStorage.getItem("asdf98kjs")),
//   languageID: "en",
//   calendarUsed: "google",
//   userCalendarID: null, // user calendar
//   userID: parseInt(sessionStorage.getItem("lkal89d97")),
//   userName: "", // sessionStorage.getItem("peoijad87"),
//   token: sessionStorage.getItem("kjd/afd"),
//   profileUrl: "",
//   stageID: null,
//   timeMode: 1, // 1 =am/pm  2= 24h
// };
export const SessionContext = createContext();

export const SessionInfo = (props) => {
  const [session, setSession] = useState({});

  const signIn = (fprops) => {
    const {
      accountID,
      accessToken,
      // email,
      // imageUrl,
      // firstUse,
      refreshToken,
      userID,
      // userName,
    } = fprops;
    // console.log(
    //   "%ccurrent session",
    //   "background-color: orange; color: white",
    //   session
    // );

    sessionStorage.setItem("kjd/afd", accessToken);
    sessionStorage.setItem("lkal89d97", userID);
    // sessionStorage.setItem("peoijad87", userName);
    sessionStorage.setItem("asdf98kjs", accountID);
    localStorage.setItem("lkd(9dk", refreshToken);

    // setSession({
    //   accountID: accountID,
    //   calendarUsed: "",
    //   email: email,
    //   firstUse: firstUse,
    //   languageID: "en",
    //   profileUrl: imageUrl,
    //   stageID: null,
    //   timeMode: 1, // 1 =am/pm  2= 24h
    //   token: accessToken,
    //   userCalendarID: null, // user calendar
    //   userID: userID,
    //   userName: userName,
    // });
  };

  const memoizedValues = useMemo(
    () => ({
      session,
      setSession,
      signIn,
    }),
    [session, , signIn]
  );

  return (
    <SessionContext.Provider value={memoizedValues}>
      {props.children}
    </SessionContext.Provider>
  );
};
export default SessionInfo;
