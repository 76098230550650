import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// @material-ui/core components
import { Divider } from "@material-ui/core";

// custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomHeader from "components/CustomHeader/CustomHeader.js";
import ErrorHandler from "components/Error/Error";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import DateRangeComp from "components/DateRange";
import SortComponent from "components/SortComponent";
import FilterComponent from "components/FilterComponent";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import { ActionsHeaderAndList } from "./ActionsHeaderAndList";
import { ActionDetails } from "./ActionsDetails";

// data
import { useQuery } from "@apollo/client";
import { SessionContext } from "../../contexts/SessionContext";
import { ActionsContext } from "contexts/ActionsContext";
import axios from "axios";
import config from "config";
import { axiosOptions } from "functions/Common";
import { actionListsDropdown } from "graphql/gqlActions";
import { tourData } from "utils/tourData";

import { v4 as uuidv4 } from "uuid";
import isEqual from "lodash/isEqual";
import useFetch from "functions/useFetch";
import useAxios from "functions/useAxios";
// errors
import {
  // ErrorBoundary,
  fallbackRender,
  errorComponent,
  logError,
  LogErrorComponent,
  useDataForLog,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// icons
import UpdateIcon from "@material-ui/icons/Update";
import TimerOffIcon from "@material-ui/icons/TimerOff";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// style
import format from "date-fns/format";
import "./Actions.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  grayColor,
  primaryColor,
  infoColor,
  successColor,
  dangerColor,
  purpleColor,
} from "assets/jss/material-dashboard-react";
import {
  formatDateTime,
  formatDateShort,
  addDaysToDate,
} from "functions/Common";

import CustomPopper from "components/CustomPopper";
import { cs } from "date-fns/locale";

const styles = {
  actionListAndDetailsContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  actionDetailsContainer: {
    display: "flex",
    flex: 1,
  },
};

const useStyles = makeStyles(styles);

export default function Actions() {
  console.log("%cStarts action list", "background-color: red; color: white");

  const generalData = useDataForLog(); // context data to populate error log

  try {
    const classes = useStyles();

    const { session, setSession } = useContext(SessionContext);
    const { userID, languageID, firstUse } = session;

    const { actions, setActions } = useContext(ActionsContext);
    const { parameterList } = actions;
    const { selectedMeetingActionID } = parameterList;

    const navigate = useNavigate();
    const [updateInfo, setUpdateInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [listsDropdown, setListsDropdown] = useState([]);

    // loads the dropdowns use for filter and sorting
    const { loading, data, error, refetch } = useQuery(actionListsDropdown, {
      variables: { userID, languageID },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      skip: firstUse,
    });

    useEffect(() => {
      // console.log("%cdata", "background-color: yellow; color: red", {
      //   data,
      //   listsDropdown,
      // });

      if (data !== undefined && listsDropdown.length === 0) {
        // loads blank line if there are no actions yet
        console.log(
          "%cupdates the lists",
          "background-color: purple; color: white",
          data.spActionListsDropdown.nodes[0]
        );

        setListsDropdown(data.spActionListsDropdown.nodes[0]);
      }
    }, [data]);
    // const [addActionMut] = useMutation(addAction, {
    //   onCompleted: (data) => {
    //     sessionStorage.setIspActionFiltersListsupdatetem("actionID", data.spActionNew.actionIds[0]);

    //     navigate({
    //       pathname: "/app/action",
    //       search: `id=${data.spActionNew.actionIds[0]}`,
    //     });
    //   },
    // });

    const actionNew = (parentEventID) => {
      // console.log("props", props);
      // const { parent_event_id: parentEventID } = props || {};
      console.log("parentEventID", parentEventID);
      setIsLoading(true);

      const options = axiosOptions({
        url: `${config.url}/api/action-new`,
        method: "post",
        data: {
          userID: userID,
          languageID: languageID,
          parentEventID: parentEventID,
        },
      });

      axios(options)
        .then(async (response) => {
          // if (200 === response.status) {
          // }
          setIsLoading(false);
          console.log("axios response", response.data);

          sessionStorage.setItem("actionID", response.data.info.action_id);

          // navigate({
          //   pathname: "/app/action",
          //   search: `id=${response.data.info.action_id}`,
          // });
          navigate("/app/action", {
            state: { actionID: response.data.info.action_id },
          });
        })
        .catch((error) => {
          console.log("error is:", error);
          return <ErrorHandler error={error} />;
        });
    };

    // console.log("----------------------la data es", data);
    const columnList = [
      {
        id: 1,
        menuDesc: "Owner",
        fieldType: "list",
      },
      { id: 2, menuDesc: "Due date", fieldType: "range" },
      { id: 3, menuDesc: "Owner status", fieldType: "list" },
      { id: 4, menuDesc: "Completed", fieldType: "boolean" },
    ];

    const filterValuesSelector = (filterLine, updateFiltersParent) => {
      console.log(
        "%cruns filterValueSelector with filterLine:",
        "background-color: red; color: white",
        filterLine
      );

      // use to change the type of selector used for displaying the filter values
      switch (filterLine.field) {
        // ---------------- owner ---------------------
        case 1: {
          // console.log(
          //   "%cfiltersLists",
          //   "background-color: red; color: white",
          //   listsDropdown
          // );

          const ownerListModified = listsDropdown.ownersList?.map((item) => {
            // add the selected field
            const isSelected = filterLine.values.includes(item.id);
            return { ...item, selected: isSelected };
          });

          return (
            <CustomMainInput
              dropDownList={ownerListModified}
              placeholder="Select owner"
              dropDownFunction={(valuesSelected) => {
                // creates the new filter line
                const newLineFilter = {
                  ...filterLine,
                  values: valuesSelected,
                };
                // updates all the filters with the new value
                updateFiltersParent(newLineFilter);
              }}
              value={filterLine.values}
              multiselect
              avatar
              includeSearch
              variant="dropdown"
            />
          );
        }
        case 2: // due date
          return (
            <DateRangeComp
              startDate={
                filterLine.values[0] //addDaysToDate(new Date(), -30)
              }
              endDate={
                filterLine.values[1] //addDaysToDate(new Date(), 30)
              }
              placeholder={"Select date range"}
              iconSize={14}
              sendInfoToParent={(item) => {
                console.log(
                  "%cTriggered date",
                  "background-color: yellow; color: red"
                );

                // creates the new filter line
                const newLineFilter = {
                  ...filterLine,
                  values: [item[0].startDate, item[0].endDate],
                };
                // updates all the filters with the new value
                updateFiltersParent(newLineFilter);

                // sendInfoToParent({
                //   parameter: "dateRange",
                //   startDate: item[0].startDate,
                //   endDate: item[0].endDate,
                // });
              }}
            />
          );

        // owner status
        case 3: {
          const ownerStatusModified = listsDropdown.ownersStatus?.map(
            (item) => {
              // add the selected field
              const isSelected = filterLine.values.includes(item.id);
              return { ...item, selected: isSelected };
            }
          );
          return (
            <CustomMainInput
              dropDownList={ownerStatusModified}
              placeholder="Select status"
              dropDownFunction={(valuesSelected) => {
                // creates the new filter line
                const newLineFilter = {
                  ...filterLine,
                  values: valuesSelected,
                };
                // console.log("ddddddddddddddddddddddddd", newLineFilter);
                // updates all the filters with the new value
                updateFiltersParent(newLineFilter);
              }}
              value={filterLine.values}
              multiselect
              variant="dropdown"
            />
          );
        }
        case 4: // action completed

        default:
          return null;
      }
    };

    return (
      <>
        <Card fullscreen>
          <CustomHeader
            dates={{
              startDate: parameterList.startDate,
              endDate: parameterList.endDate,
            }}
            fullscreen
            headerColor="white"
            headerVariant="actions"
            parametersSelected={parameterList}
            filterPopper={
              <FilterComponent
                filterValuesSelector={filterValuesSelector}
                filters={parameterList.filters}
                columnList={columnList}
                listsDropdown={listsDropdown}
                // updateInfo={updateInfo}
                sendInfoToParent={(updatedFilters) => {
                  // reduce the filters to only the one ready, not the one in process (lacking an element)
                  const currentFilters = parameterList.filters.filter(
                    (item) => item.filterLineStatus === "ready"
                  );
                  const newFilters = updatedFilters.filter(
                    (item) => item.filterLineStatus === "ready"
                  );

                  const filtersHaveChanged = !isEqual(
                    currentFilters,
                    newFilters
                  );
                  console.log("filters have changed", {
                    filtersHaveChanged,
                    currentFilters,
                    newFilters,
                  });

                  setActions({
                    ...actions,
                    pageNumber: filtersHaveChanged ? 1 : actions.pageNumber,
                    parameterList: {
                      ...actions.parameterList,
                      filters: updatedFilters,
                    },
                  });
                }}
              />
            }
            sortPopper={
              <SortComponent
                columnList={columnList}
                listsDropdown={listsDropdown}
                // updateInfo={updateInfo}
                sorts={parameterList.sorts}
                sendInfoToParent={(updatedSorts) => {
                  console.log(
                    "%cSend to parent info",
                    "background-color: blue; color: white",
                    updatedSorts
                  );

                  // reduce the filters to only the one ready, not the one in process (lacking an element)
                  const currentSorts = parameterList.filters.filter(
                    (item) => item.sortLineStatus === "ready"
                  );
                  const newSorts = updatedSorts.filter(
                    (item) => item.sortLineStatus === "ready"
                  );

                  const sortsHaveChanged = !isEqual(currentSorts, newSorts);
                  // console.log("filters have changed", {
                  //   filtersHaveChanged,
                  //   currentFilters,
                  //   newFilters,
                  // });

                  setActions({
                    ...actions,
                    pageNumber: sortsHaveChanged ? 1 : actions.pageNumber,
                    parameterList: {
                      ...actions.parameterList,
                      sorts: updatedSorts,
                    },
                  });
                }}
              />
            }
            sendInfoToParent={(item) => {
              console.log("se presionó", item);

              switch (item.parameter) {
                case "dateRange":
                  setActions({
                    ...actions,
                    parameterList: {
                      ...actions.parameterList,
                      startDate: item.startDate,
                      endDate: item.endDate,
                    },
                  });

                  break;

                case "orderBy":
                  setActions({
                    ...actions,
                    parameterList: {
                      ...actions.parameterList,
                      orderByID: item.value,
                    },
                  });
                  break;
                case "search":
                  setActions({ ...actions, searchTerm: item.value });

                  break;
                default:
              }
            }}
          />
          <CardBody fullscreen>
            {/* <pre>{JSON.stringify(parameterList, null, 2)}</pre> */}

            {/* {actions()} */}
            <ActionsLayout />

            {isLoading && <ActivityIndicator fullscreen withShade />}
          </CardBody>
        </Card>
      </>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}

const ActionsLayout = () => {
  const classes = useStyles();
  const { session, setSession } = useContext(SessionContext);
  const { userID, languageID, firstUse } = session;

  const { actions, setActions } = useContext(ActionsContext);
  const { parameterList } = actions;
  const { selectedMeetingActionID } = parameterList;

  // console.log(
  //   "%cLoads actions layout",
  //   "background-color: red; color: white"
  // );
  // by default selects the first row, otherwise 0

  // console.log("dataFetch", dataFetch[0]?.meetingActionId);

  return (
    <div className={classes.actionListAndDetailsContainer}>
      <ActionsHeaderAndList />

      <div className={classes.actionDetailsContainer}>
        <ErrorBoundary
          FallbackComponent={(error) => (
            <LogErrorComponent
              error={error}
              data={{
                location: "Action list",
                userID: userID,
              }}
            />
          )}
        >
          <ActionDetails
            // meetingActionId={dataFetch[0]?.meetingActionId || 0}
            meetingActionId={selectedMeetingActionID}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};
