import { useContext, useState } from "react";
//custom Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// data
import { HelpDeskContext } from "contexts/HelpDeskContext";
import { SessionContext } from "../../contexts/SessionContext";

// errors
import { logError, useDataForLog } from "functions/ErrorBoundary";

// icons

// style
import { makeStyles } from "@material-ui/core/styles";

import { grayBoxShadow, grayColor } from "assets/jss/material-dashboard-react";

const styles = {
  alignCenter: {
    textAlign: "center",
  },
  alignLeft: {
    textAlign: "left",
    // paddingLeft: "15px",
  },
  alignRight: {
    textAlign: "right",
    // paddingRight: "15px",
  },
  headerContainer: {
    ...grayBoxShadow,
    alignItems: "center",
    backgroundColor: "white",
    // backgroundColor: grayColor[8],
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
    padding: "5px 20px 0px 20px",
    transition: "all 600ms ease-in-out",
    borderBottom: `1px solid ${grayColor[6]}`,
  },
  gridContainer: {
    flex: 1,
    // borderBottom: `1px solid ${grayColor[7]}`,
  },
  header: {
    fontSize: "12px",
    padding: "5px 0px",
    fontWeight: 600,
  },
};
const useStyles = makeStyles(styles);

export default function HelpDeskHeader(props) {
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const {} = props;

    const classes = useStyles();
    // const [records, setRecords] = useState([]);
    const { session, setSession } = useContext(SessionContext);
    const { languageID, userID } = session;

    const { helpDesk, setHelpDesk } = useContext(HelpDeskContext);
    const { isAdmin } = helpDesk;

    const columnSizes = isAdmin
      ? [1, 1, 4, 2, 2, 1, 1]
      : [1, 1, 5, 2, 2, null, 1];

    return (
      <div className={classes.headerContainer}>
        <GridContainer
          spacing={2}
          direction="row"
          classes={{ container: classes.gridContainer }}
        >
          <GridItem xs={columnSizes[0]}>
            <h5 className={`${classes.header} ${classes.alignLeft}`}>
              Ticket ID
            </h5>
          </GridItem>

          <GridItem xs={columnSizes[1]}>
            <h5 className={`${classes.header} ${classes.alignCenter}`}>
              Priority
            </h5>
          </GridItem>
          <GridItem xs={columnSizes[2]}>
            <h5 className={`${classes.header} ${classes.alignLeft}`}>
              Subject
            </h5>
          </GridItem>

          <GridItem xs={columnSizes[3]}>
            <h5 className={`${classes.header} ${classes.alignLeft}`}>
              Category
            </h5>
          </GridItem>
          <GridItem xs={columnSizes[4]}>
            <h5 className={`${classes.header} ${classes.alignLeft}`}>Date</h5>
          </GridItem>
          {isAdmin && (
            <GridItem xs={columnSizes[5]}>
              <h5 className={`${classes.header} ${classes.alignCenter}`}>
                User
              </h5>
            </GridItem>
          )}
          <GridItem xs={columnSizes[6]}>
            <h5 className={`${classes.header} ${classes.alignCenter}`}>
              Status
            </h5>
          </GridItem>
        </GridContainer>
      </div>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
