import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

// styles
import {
  grayColor,
  infoColor,
  whiteColor,
} from "assets/jss/material-dashboard-react.js";

// core components
// import styles from "assets/jss/material-dashboard-react/components/switchStyle.js";

const styles = {
  mainContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    // backgroundColor: "yellow"
  },

  titleContainer: {
    float: "left",
    margin: "0px",
    paddingRight: "10px"
    // padding: "10px 10px 10px 0px",
  },
  switchRoot: {
    float: "left",
    width: 35,
    height: 19,
    padding: 0,
    // margin: "12px 25px 12px 0px", //theme.spacing(1),
  },
  switchBase: {
    padding: 1,

    "&$checked": {
      transform: "translateX(17px)",
      color: whiteColor,
      "& + $track": {
        backgroundColor: infoColor[4],
        opacity: 1,
        border: "none",
      },
      "&$disabled + $track": {
        backgroundColor: grayColor[5], // Background color when switch is disabled
      },
    },
    "&$focusVisible $thumb": {
      color: infoColor[4],
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 17,
    height: 17,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: grayColor[3],
    opacity: 1,
    // transition: them.transition.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  disabled: {},
};

const useStyles = makeStyles(styles);

export default function CustomSwitch(props) {
  const classes = useStyles();
  const { onChange, checked, disabled, title, ...rest } = props;

  // console.log("checked", { checked });

  return (
    <div className={classes.mainContainer}>
      <div className={classes.titleContainer}>{title}</div>
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        disabled={disabled}
        classes={{
          root: classes.switchRoot,
          switchBase: `${classes.switchBase} ${disabled && classes.disabled}`,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        checked={checked}
        onChange={onChange}
        // name="checkedB"
        {...rest}
      />
    </div>
  );
}

CustomSwitch.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};
