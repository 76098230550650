import { gql } from "@apollo/client";

// used to revert an actions that was rejected from the owner
export const sendToOwner = gql`
  mutation sendToOwner($meetingActionID: Int!) {
    updateMeetingActionById(
      input: { meetingActionPatch: { accepted: null }, id: $meetingActionID }
    ) {
      clientMutationId
    }
  }
`;

export const actionListsDropdown = gql`
  query actionListsDropdown($userID: Int, $languageID: String) {
    spActionListsDropdown(langShort1: $languageID, userId1: $userID) {
      nodes {
        conditionsList
        ownersList
        ownersStatus
        sortList
      }
    }
  }
`;

export const actionsDetails = gql`
  query actionsDetails($meetingActionId: Int!, $languageID: String) {
    spActionsDetails(
      meetingActionId1: $meetingActionId
      languageId1: $languageID
    ) {
      nodes {
        typeId
        owner
        meetingActionId
        description
        date
      }
    }
  }
`;
