import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDialog from "components/CustomDialog/CustomDialog";
import Button from "components/CustomButtons/Button";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import { RadioButtonsGroup } from "components/Radio";

// data
import { useMutation } from "@apollo/client";
import { axiosOptions, convertLocalToUTC } from "functions/Common";
import axios from "axios";
import config from "config";
import {
  endMeeting,
  deleteMeeting,
  updateMeetingAttendees,
  updateMeetingByID,
} from "graphql/gqlMeetingModals";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";

import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "../../../contexts/SessionContext";
import { SocketContext } from "../../../contexts/SocketContext";

// styles
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "assets/jss/material-dashboard-react";

const styles = {
  confirmButtonsContainer: {
    justifyContent: "space-around",
    display: "flex",
    margin: "20px 40px 0px 40px",
  },
};

const useStyles = makeStyles(styles);

const MeetingModals = (props) => {
  // console.log(
  //   "%cStarts meeting modals",
  //   "background-color: purple; color: white"
  // );

  const { session, setSession } = useContext(SessionContext);
  const {
    userID,
    userName,
    createdBY: organizerID,
    userCalendarID,
    accountID,
    languageID,
    firstUse,
  } = session;
  const { meeting, setMeeting, savePendingRef } = useContext(MeetingContext);
  const {
    externalMeetingID,
    meetingID,
    parentEventID,
    meetingTypeID,
    startDate,
  } = meeting;
  const { socket } = useContext(SocketContext);

  const {
    changes,
    childContentRef,
    contentChangedRef,
    // isLoading,
    // meetingID,
    modal,
    // parentEventID,
    refetchMeetingGeneral,
    // setIsLoading,
    setChanges,
    setTabSelected,
    setModal,
    setShowSnackbar,
    statusID,
  } = props;

  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  const navigate = useNavigate();
  const classes = useStyles();

  const InviteMeeting = () => {
    const [isLoading, setIsLoading] = useState(false);
    const sendInvitations = async () => {
      console.log(
        "%cMeetingID",
        "background-color: red; color: white",
        meetingID
      );

      if (meetingID === null) {
        const response = await childContentRef.current?.triggerSubmit4();
        // setModal({ visible: false, name: "" });
      } else {
        setIsLoading(true);
        const options = axiosOptions({
          url: `${config.url}/api/meeting-save-encrypted`, //send-meeting-updates`,
          method: "post",
          data: {
            meetingID: meetingID, // if null (eg. repeat event) will be converted to latest consecutive
            parentEventID: parentEventID,
            // statusID: 2, // in MS this will trigger from draft to non draft, hence sending the invitations
            statusID: statusID,
            userID: userID,
            confirmMeeting: true,
          },
        });

        axios(options)
          .then(async (response) => {
            // if (200 === response.status) {
            // }

            console.log(
              "%caxios response in send invitations",
              "background-color: blue; color: white",
              response.data
            );

            // setMeeting({ ...meeting, statusID: 2 });
            setModal({ visible: false, name: "" });
            setIsLoading(false);
            refetchMeetingGeneral();
            setShowSnackbar({
              show: true,
              messageType: "success",
              message: "Meeting confirmed and emails sent",
            });
          })
          .catch((error) => {
            setModal({ visible: false, name: "" });
            setIsLoading(false);
            console.log("error");
            setErrorState({
              error: error,
              data: {
                axiosOptions: options,
                errorExplanation:
                  "The invitations may have not been sent properly.",
                userID: userID,
                location: "sendInvitations inside Meeting.js", // added due to lack of stack trace in Axios
              },
            });

            // return <ErrorHandler  error={error}    />;
          });
      }
    };
    return (
      <CustomDialog
        iconPreset="question"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="Do you want to confirm this meeting and send the invitations?"
        buttonActionLabel1="Return"
        // buttonActionColor1="danger"
        buttonActionFunction1={() => {
          setModal({ visible: false, name: "" });
        }}
        buttonActionDisabled1={isLoading}
        buttonActionLabel2="Send invitations"
        buttonActionFunction2={sendInvitations}
        buttonActionLoading2={isLoading}
      />
    );
  };

  const ForgetStartMeeting = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [updateMeetingByIDMut] = useMutation(updateMeetingByID, {
      onCompleted: () => {
        try {
          setShowSnackbar({
            show: true,
            messageType: "success",
            message: "Meeting started",
          });

          refetchMeetingGeneral();
          setModal({ visible: false, name: "" });
          setIsLoading(false);
        } catch (error) {
          logError(error, {
            errorLevel: "critical",
            generalData: { ...generalData },
            otherData: { modalName: modal.name },
          });
        }
      },
    });

    const startMeeting = () => {
      setIsLoading(true);
      updateMeetingByIDMut({
        variables: {
          meetingID: meetingID,
          startDateFinal: new Date(),
        },
      });
    };
    return (
      <CustomDialog
        center
        iconPreset="error"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="It's time to start the meeting"
        mainText="Start time is overdue"
        buttonActionLabel1="Return"
        buttonActionFunction1={() => {
          navigate("/app/meetings");
          setModal({ visible: false, name: "" });
        }}
        buttonActionDisabled1={isLoading}
        buttonActionLabel2="Start meeting"
        buttonActionFunction2={startMeeting}
        buttonActionLoading2={isLoading}
        // center
      />
    );
  };

  const ForgetEndMeeting = () => {
    const originalLoading = {
      anyLoading: false,
      button1: false,
      button2: false,
      button3: false,
    };
    const [isLoading, setIsLoading] = useState(originalLoading);
    const [updateMeetingByIDMut] = useMutation(updateMeetingByID, {
      onCompleted: () => {
        try {
          setShowSnackbar({
            show: true,
            messageType: "success",
            message: "Alert is delayed",
          });

          refetchMeetingGeneral();
          setModal({ visible: false, name: "" });
          setIsLoading(originalLoading);
        } catch (error) {
          logError(error, {
            errorLevel: "critical",
            generalData: { ...generalData },
            otherData: { modalName: modal.name },
          });
        }
      },
    });

    const [endMeetingMut] = useMutation(endMeeting, {
      onCompleted: () => {
        refetchMeetingGeneral(); // repaints the screen
        socket.emit("end_meeting", { meetingID });
        {
          meetingTypeID !== 2
            ? setModal({ visible: true, name: "remember-feedback" })
            : setModal({ visible: false, name: "" });
        }
      },
    });

    const endMeetingPostpone = () => {
      setIsLoading({ ...isLoading, anyLoading: true, button2: true });
      updateMeetingByIDMut({
        variables: {
          meetingID: meetingID,
          endDateReminder: new Date(),
        },
      });
    };

    const endMeetingFunc = () => {
      setIsLoading({ ...isLoading, anyLoading: true, button3: true });
      endMeetingMut({
        variables: {
          meetingID: meetingID,
        },
      });
    };

    return (
      <CustomDialog
        center
        iconPreset="error"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="Don't forget to end the meeting"
        mainText="End time is overdue"
        buttonActionLabel1="Cancel"
        buttonActionFunction1={() => {
          navigate("/app/meetings");
          setModal({ visible: false, name: "" });
        }}
        buttonActionLoading1={isLoading.button1}
        buttonActionDisabled1={isLoading.anyLoading}
        buttonActionLabel2="Need more time"
        buttonActionFunction2={endMeetingPostpone}
        buttonActionLoading2={isLoading.button2}
        buttonActionDisabled2={isLoading.anyLoading}
        buttonActionLabel3="End meeting"
        buttonActionFunction3={endMeetingFunc}
        buttonActionLoading3={isLoading.button3}
        buttonActionDisabled3={isLoading.anyLoading}

        // center
      />
    );
  };

  const ConfirmStartMeeting = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { socket } = useContext(SocketContext);

    const [updateMeetingByIDMut] = useMutation(updateMeetingByID, {
      onCompleted: () => {
        try {
          socket.emit("start_meeting", { meetingID });
          setShowSnackbar({
            show: true,
            messageType: "success",
            message: "Meeting has started",
          });

          refetchMeetingGeneral();
          setModal({ visible: false, name: "" });
          setIsLoading(false);
        } catch (error) {
          logError(error, {
            errorLevel: "critical",
            generalData: { ...generalData },
            otherData: { modalName: modal.name },
          });
        }
      },
    });
    const startMeeting = () => {
      console.log("start meeting");

      setIsLoading(true);
      updateMeetingByIDMut({
        variables: {
          meetingID: meetingID,
          startDateFinal: new Date(),
        },
      });
    };
    return (
      <CustomDialog
        iconPreset="question"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="Do you want to start the meeting?"
        buttonActionLabel1="Return"
        buttonActionFunction1={() => {
          setModal({ visible: false, name: "" });
        }}
        buttonActionDisabled1={isLoading}
        buttonActionLabel2="Start meeting"
        buttonActionFunction2={startMeeting}
        buttonActionLoading2={isLoading}
        // center
      />
    );
  };

  const ConfirmEndMeeting = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [endMeetingMut] = useMutation(endMeeting, {
      onCompleted: () => {
        refetchMeetingGeneral(); // repaints the screen
        socket.emit("end_meeting", { meetingID });
        {
          meetingTypeID !== 2
            ? setModal({ visible: true, name: "remember-feedback" })
            : setModal({ visible: false, name: "" });
        }
      },
    });

    const endMeetingFunc = () => {
      setIsLoading(true);
      endMeetingMut({
        variables: {
          meetingID: meetingID,
        },
      });
    };
    return (
      <CustomDialog
        iconPreset="question"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="Do you want to end the meeting?"
        buttonActionLabel1="Return"
        buttonActionFunction1={() => {
          setModal({ visible: true, name: "" });
        }}
        buttonActionDisabled1={isLoading}
        buttonActionLabel2="End meeting"
        buttonActionFunction2={endMeetingFunc}
        buttonActionLoading2={isLoading}
        // center
      />
    );
  };

  const RememberFeedback = () => {
    return (
      <CustomDialog
        iconPreset="success"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="Meeting has ended.  Remember to give feedback."
        // buttonActionLabel1="Return"
        buttonActionFunction1={() => {
          setModal({ visible: false, name: "" });
        }}
        // buttonActionDisabled1={isLoading}
        buttonActionLabel2="Go to feedback"
        buttonActionFunction2={(item) => {
          setTabSelected(5);
          setModal({ visible: false, name: "" });
        }}
      />
    );
  };

  const ConfirmLeaveMeeting = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [updateMeetingAttendeesMut] = useMutation(updateMeetingAttendees, {
      onCompleted: (data) => {
        try {
          socket.emit("leave_meeting", { userName, meetingID });
          // setIsLoading(false);

          navigate("/app/meetings", {
            state: {
              snackBarInfo: JSON.stringify({
                messageType: "success",
                message: "You have left this meeting",
              }),
            },
          });

          refetchMeetingGeneral();
        } catch (error) {
          setErrorState({
            error: error,
            data: { errorLevel: "fatal", otherData: data },
          });
        }
      },
    });

    const leaveMeeting = () => {
      setIsLoading(true);
      updateMeetingAttendeesMut({
        variables: {
          meetingID: meetingID,
          userID: userID,
          departDate: new Date(),
        },
      });
    };

    return (
      <CustomDialog
        iconPreset="question"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="Do you want to leave this meeting?"
        buttonActionLabel1="Return"
        buttonActionFunction1={() => {
          setModal({ visible: false, name: "" });
        }}
        buttonActionDisabled1={isLoading}
        buttonActionLabel2="Leave meeting"
        buttonActionFunction2={leaveMeeting}
        buttonActionLoading2={isLoading}
        // center
      />
    );
  };

  const ConfirmSendChanges = () => {
    const [isLoading, setIsLoading] = useState(false);

    const sendChanges = () => {
      setIsLoading(true);
      const changesFromContent1 = changes.changesFromContent || null;
      const changesFromDetails1 = changes.changesFromDetails || null;
      const changesFromPurpose1 = changes.changesFromPurpose || null;
      const allUpdates = {
        ...changesFromContent1,
        ...changesFromDetails1,
        ...changesFromPurpose1,
      };

      const options = axiosOptions({
        url: `${config.url}/api/send-meeting-updates`,
        method: "post",
        data: {
          // meetingID: meetingID,
          // meetingStatusID: statusID,
          // allUpdates: JSON.stringify(allUpdates),
          ...changesFromContent1,
          ...changesFromDetails1,
          ...changesFromPurpose1,
          meetingID: meetingID,
          statusID: statusID,
        },
      });

      console.log(
        "%cUpdates options",
        "background-color: red; color: white",
        options
      );

      axios(options)
        .then(async (response) => {
          // if (200 === response.status) {
          // }
          console.log("axios response", response.data);
          //  setShowSnackbar({
          //     show: true,
          //     messageType: "success",
          //     message: ,
          //  });

          // setRefreshInfo(new Date());
          // remove temp changes
          setChanges({});
          setIsLoading(false);
          setModal({ visible: false, name: "" });

          setShowSnackbar({
            show: true,
            messageType: "success",
            message: "Changes were saved and attendees were notified",
          });
        })
        .catch((error) => {
          setIsLoading(false);
          setModal({ visible: false, name: "" });
          console.log("error", error);

          setErrorState({
            error: error,
            data: {
              axiosOptions: options,
              errorExplanation: "Changes were not sent",
              userID: userID,
              location: "sendChanges inside Meeting.js", // added due to lack of stack trace in Axios
            },
          });
        });
    };

    return (
      <CustomDialog
        iconPreset="question"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="Do you want to send the changes?"
        buttonActionLabel1="Return"
        buttonActionFunction1={() => {
          setModal({ visible: false, name: "" });
        }}
        buttonActionDisabled1={isLoading}
        buttonActionLabel2="Send"
        buttonActionFunction2={sendChanges}
        buttonActionLoading2={isLoading}
        // center
      />
    );
  };

  const ConfirmCancelMeeting = () => {
    const [isLoading, setIsLoading] = useState(false);

    const cancelMeeting = (occurrenceRepeatType) => {
      console.log("startDate", { startDate, converted: new Date(startDate) });

      setIsLoading(true);
      const options = axiosOptions({
        url: `${config.url}/api/meeting-save-encrypted`,
        method: "post",
        data: {
          meetingID: meetingID,
          statusID: statusID,
          parentEventID: parentEventID,
          occurrenceRepeatType: occurrenceRepeatType,
          startDate: convertLocalToUTC(`${new Date(startDate)}`),
          // convertLocalToUTC(
          //   `${initialValues.startDate}T${convertAMPMTo24(
          //     initialValues.startTime
          //   )}`
          // );

          userID: userID,
          operationType: "delete",
        },
      });

      axios(options)
        .then(async (response) => {
          navigate("/app/meetings", {
            state: {
              snackBarInfo: JSON.stringify({
                messageType: "success",
                message: "Cancelled successfully",
              }),
            },
          });
        })
        .catch((error) => {
          console.log("exceeds");
          setIsLoading(false);
          setModal({ visible: false, name: "" });
          // setIsLoading(false);
          setErrorState({
            error: error,
            data: {
              axiosOptions: options,
              errorExplanation: "Meeting could not be cancelled",
              userID: userID,
              location: "cancelMeeting inside Meeting.js", // added due to lack of stack trace in Axios
            },
          });
        });
    };

    const options = () => {
      switch (modal.data.occurrenceRepeatType) {
        case 0:
          return {
            title: "Do you want to cancel this meeting?",
            buttonDesc: "Cancel meeting",
          };
        case 1:
          return {
            title: "Do you want to cancel this meeting only?",
            buttonDesc: "Cancel meeting",
          };
        case 2:
          return {
            title: "Do you want to cancel all the meetings in the series?",
            buttonDesc: "Cancel all meetings",
          };

        case 3:
          return {
            title: "Do you want to cancel this meeting and following events?",
            buttonDesc: "Cancel meetings",
          };

        default:
      }
    };
    return (
      <CustomDialog
        iconPreset="question"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title={options().title}
        buttonActionLabel1="Return"
        buttonActionFunction1={() => {
          setModal({ visible: false, name: "" });
        }}
        buttonActionDisabled1={isLoading}
        buttonActionLabel2={options().buttonDesc}
        // buttonActionColor2="danger"
        buttonActionFunction2={() =>
          cancelMeeting(modal.data.occurrenceRepeatType)
        }
        buttonActionLoading2={isLoading}
        // center
      />
    );
  };

  const ConfirmDeleteMeeting = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [deleteMeetingMut] = useMutation(deleteMeeting, {
      onCompleted: () => {
        // deletes from external calendar
        const options = axiosOptions({
          url: `${config.url}/api/delete-event`,
          method: "post",
          data: {
            externalEventID: externalMeetingID,
            userID: userID,
            userCalendarID: userCalendarID,
          },
        });

        axios(options)
          .then(async () => {
            navigate("/app/meetings", {
              state: {
                snackBarInfo: JSON.stringify({
                  messageType: "success",
                  message: "Meeting ended",
                }),
              },
            });
          })
          .catch((error) => {
            setIsLoading(false);

            setErrorState({
              error: error,
              errorExplanation:
                "The event may have not been deleted from the connected external calendar",
              data: {
                axiosOptions: options,
                userID: userID,
                location: "deleteMeetingMut inside MeetingModals", // added due to lack of stack trace in Axios
              },
            });
          });
      },
    });

    const deleteMeetingFunc = () => {
      setIsLoading(true);
      // deletes from db
      deleteMeetingMut({
        variables: {
          meetingID: meetingID,
          cancelDate: new Date(),
        },
      });
    };

    return (
      <CustomDialog
        iconPreset="question"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="Do you want to delete this meeting?"
        buttonActionLabel1="Return"
        buttonActionFunction1={() => {
          setModal({ visible: false, name: "" });
        }}
        buttonActionDisabled1={isLoading}
        buttonActionLabel2="Delete meeting"
        buttonActionColor2="danger"
        buttonActionFunction2={deleteMeetingFunc}
        buttonActionLoading2={isLoading}

        // center
      />
    );
  };

  const CheckIn = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [meetingCode, setMeetingCode] = useState({
      value: "",
      error: "",
    });

    const [updateMeetingAttendeesMut] = useMutation(updateMeetingAttendees, {
      onCompleted: (data) => {
        try {
          console.log("data", data);
          if (data.spMeetingAttendeesUpdate.results[0].updated === false) {
            console.log("code is invalid");
            // invalid meeting check in code
            setMeetingCode({ ...meetingCode, error: "Invalid code" });
          } else {
            // valid meeting check in code
            socket.emit("send_meeting_checkin", { userName, meetingID });
            setIsLoading(false);
            setShowSnackbar({
              show: true,
              messageType: "success",
              message: "Successfully joined the meeting ",
            });

            setModal({ visible: false, name: "" });
            refetchMeetingGeneral();
          }
        } catch (error) {
          console.log("theres an error", error);
          setErrorState({
            error: error,
            data: { errorLevel: "fatal", otherData: data },
          });
        }
      },
    });

    const checkIn = () => {
      // setIsLoading(true);
      // socket.emit("join_meeting", { meetingID });

      updateMeetingAttendeesMut({
        variables: {
          meetingID: meetingID,
          userID: userID,
          checkInCode: meetingCode.value,
        },
      });
    };

    return (
      <CustomDialog
        center
        // iconPreset="question"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="Enter the meeting code"
        mainText="The code is provided by the organizer.  This will record your attendance."
        buttonActionLabel1="Return"
        // buttonActionColor1="danger"
        buttonActionFunction1={() => {
          navigate("/app/meetings");
          setModal({ visible: false, name: "" });
        }}
        buttonActionDisabled1={isLoading}
        buttonActionLabel2="Submit code"
        buttonActionFunction2={checkIn}
        buttonActionLoading2={isLoading}
      >
        <CustomMainInput
          error={meetingCode.error}
          height="40px"
          onChange={(e) => {
            // console.log("starts validation", e.target.value);
            setMeetingCode({ value: e.target.value, error: "" });
          }}
          value={meetingCode.value}
          variant="input"
        />
      </CustomDialog>
    );
  };

  const ConfirmAttendance = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [updateMeetingAttendeesMut] = useMutation(updateMeetingAttendees, {
      onCompleted: (data) => {
        try {
          setIsLoading(false);
          setModal({ visible: false, name: "" });

          refetchMeetingGeneral();
        } catch (error) {
          setIsLoading(false);
          setErrorState({
            error: error,
            data: { errorLevel: "fatal", otherData: data },
          });
        }
      },
    });

    return (
      <CustomDialog
        center
        iconPreset="question"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="Are you going to attend this meeting?"
      >
        <div className={classes.confirmButtonsContainer}>
          <Button
            onClick={() => {
              // navigate("/app/meetings");
              setModal({ visible: false, name: "" });
            }}
            color="primary"
          >
            Confirm later
          </Button>
          <Button
            onClick={() =>
              updateMeetingAttendeesMut({
                variables: {
                  meetingID: meetingID,
                  userID: userID,
                  willAttend: false,
                },
              })
            }
            // isLoading={buttonActionLoading1}
            // disabled={buttonActionLoading1 || buttonActionDisabled1}
            color="danger"
            // startIcon={buttonActionIcon1}
          >
            No
          </Button>
          <Button
            onClick={() =>
              updateMeetingAttendeesMut({
                variables: {
                  meetingID: meetingID,
                  userID: userID,
                  willAttend: true,
                },
              })
            }
            // isLoading={buttonActionLoading1}
            // disabled={buttonActionLoading1 || buttonActionDisabled1}
            color="success"
            // startIcon={buttonActionIcon1}
          >
            Yes
          </Button>
        </div>
      </CustomDialog>
    );
  };

  const RepeatEventType = () => {
    const [isLoading, setIsLoading] = useState(false);

    const optionsList = [
      {
        value: 1,
        label: <h5 style={{ color: grayColor[1] }}>This meeting</h5>,
        function: () => {
          // activityRef.current.show();
          childContentRef.current?.triggerSubmit1();

          // store the value selected in a ref for future use if required, to avoid asking again for the same event
          // repeatSaveTypeRef.current = 1;
        },
      },
      {
        value: 3,
        label: (
          <h5 style={{ color: grayColor[1] }}>This and following meetings</h5>
        ),
        function: () => {
          // activityRef.current.show();
          childContentRef.current?.triggerSubmit3();
          // store the value selected in a ref for future use if required, to avoid asking again for the same event
          // repeatSaveTypeRef.current = 3;
        },
      },
      {
        value: 2,
        label: (
          <h5 style={{ color: grayColor[1] }}>All meetings in the series</h5>
        ),
        function: () => {
          // activityRef.current.show();
          childContentRef.current?.triggerSubmit2();
          // store the value selected in a ref for future use if required, to avoid asking again for the same event
          // repeatSaveTypeRef.current = 2;
        },
      },
    ];

    // by default the function is for the first item selected
    let selectedFunction = optionsList.filter((item) => item.value === 1)[0]
      .function;

    return (
      <CustomDialog
        center
        iconPreset="question"
        visibleStatus={modal}
        setVisibleStatus={setModal}
        title="There are unsaved changes. Since this is a repeating event, select how to apply these changes:"
        buttonActionLabel1="Cancel"
        buttonActionFunction1={() => {
          setModal({ visible: false, name: "" });
        }}
        buttonActionDisabled1={isLoading}
        buttonActionLabel2="Discard changes"
        // buttonActionColor2="danger"
        buttonActionFunction2={() => {
          // reverts changes flag to false
          savePendingRef.current = false;
          setTabSelected(modal.data.selectedTabIndex);
          setModal({ visible: false, name: "" });
        }}
        buttonActionLoading2={isLoading}
        buttonActionLabel3="Apply"
        // buttonActionColor2="danger"
        buttonActionFunction3={() => {
          savePendingRef.current = false;
          selectedFunction();
          setModal({ visible: false, name: "" });
        }}
        buttonActionLoading3={isLoading}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <RadioButtonsGroup
            items={optionsList}
            sendInfoToParent={(value) => {
              console.log(
                "el valor es",
                optionsList.filter((item) => item.value === value)[0].function
              );
              selectedFunction = optionsList.filter(
                (item) => item.value === value
              )[0].function;
            }}
            initialValue={1}
          />
        </div>
      </CustomDialog>
    );
  };

  const ModalToShow = () => {
    switch (modal.name) {
      case "no-attendees":
        return (
          <CustomDialog
            center
            iconPreset="forbidden"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Can't send invitation"
            mainText="No attendees have been defined"
            buttonActionLabel1="OK"
            buttonActionFunction1={() => {
              setSubmitFormFunc({
                ...submitFormFunc,
                newTabIndex: 0,
              });
              setModal({ visible: false, name: "" });
            }}
          />
        );
      case "no-title":
        return (
          <CustomDialog
            center
            iconPreset="forbidden"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Can't send invitation"
            mainText="No title has been defined"
            buttonActionLabel1="OK"
            buttonActionFunction1={() => {
              setTabSelected(0);
              setModal({ visible: false, name: "" });
            }}
          />
        );

      case "invite-meeting":
        return <InviteMeeting />;

      // case "invitations-sent":
      //   return (
      //     <CustomDialog
      //       iconPreset="success"
      //       visibleStatus={modal}
      //       setVisibleStatus={setModal}
      //       title="Meeting confirmed and invitations sent"
      //       buttonCloseLabel="OK"
      //       // center
      //     />
      //   );

      case "forget-start-meeting":
        return <ForgetStartMeeting />;

      case "forget-end-meeting":
        return <ForgetEndMeeting />;

      case "confirm-start-meeting":
        return <ConfirmStartMeeting />;

      case "confirm-end-meeting":
        return <ConfirmEndMeeting />;

      case "remember-feedback":
        return <RememberFeedback />;

      case "confirm-leave-meeting":
        return <ConfirmLeaveMeeting />;

      case "confirm-send-changes":
        return <ConfirmSendChanges />;

      case "confirm-cancel-meeting":
        return <ConfirmCancelMeeting />;

      case "confirm-delete-meeting":
        return <ConfirmDeleteMeeting />;

      case "no-data-or-access":
        return (
          <CustomDialog
            center
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Meeting is Unavailable"
            mainText="This meeting doesn't exist or you don't have access.  To have access you must be an attendee or the organizer."
            buttonActionLabel1="Go to meeting list"
            // buttonActionColor1="danger"
            buttonActionFunction1={() => {
              navigate("/app/meetings");
              setModal({ visible: false, name: "" });
            }}
          />
        );

      case "check-in":
        return <CheckIn />;

      case "confirm-attendance":
        return <ConfirmAttendance />;

      case "repeat-event-type":
        return <RepeatEventType />;

      default:
        return null;
    }
  };

  return <ModalToShow />;
};

export default MeetingModals;
