import React, { useContext } from "react";

import { useGoogleLogin } from "@react-oauth/google";
// import Button from "components/CustomButtons/Button";
import Button from "@material-ui/core/Button";
import axios from "axios";
import config from "../../config";
// material ui
import { SvgIcon } from "@material-ui/core";
// custom components
import ErrorHandler from "components/Error/Error";
// data
import { SessionContext } from "../../contexts/SessionContext";
import { axiosOptions } from "functions/Common";
// icons
// import { ReactComponent as GoogleLogo } from "../../assets/img/Google.svg";
import Google from "assets/svgComponent/google";
// styles
import { makeStyles } from "@material-ui/core/styles";
import {
  grayColor,
  whiteColor,
  hexToRgb,
  blackColor,
  primaryColor,
} from "assets/jss/material-dashboard-react";

const styles = {
  background: {
    backgroundColor: "white",
    height: "32px",
    width: "32px",
    margin: "2px",
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2px",
  },
  button: {
    // ...defaultFont,

    minHeight: "auto",
    minWidth: "auto",
    overflow: "clip",
    overflowHiddenMargin: "5px",
    // height: "100%",
    backgroundColor: primaryColor[4],
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.12)",
    border: "none",
    borderRadius: "3px",
    position: "relative",
    padding: "0px 15px 0px 0px",
    // padding: "8px",
    // margin: ".2rem",
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "none",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    // lineHeight: "1.42857143",
    textAlign: "center",
    // whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: "#4285F4", // primaryColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(grayColor[3]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(grayColor[3]) +
        ", 0.2)",
    },
  },
};

const useStyles = makeStyles(styles);

export default function ConnectCalendarGoogle(props) {
  const classes = useStyles();
  const { session, setSession } = useContext(SessionContext);
  const { signedIn, signOut, afterSignIn } = props;
  const { userID } = session;

  console.log("el after sign in es", afterSignIn);

  // const getGoogleAccessToken = async (authorizationCode) => {
  //   const response = await axios.post(`/api/google/accesstoken`, {
  //     authorizationCode,
  //   });
  //   if (response.data.access_token) {
  //     localStorage.setItem(
  //       "google_access_token",
  //       JSON.stringify(response.data.access_token)
  //     );
  //   }
  //   if (response.data.expiry_date) {
  //     localStorage.setItem(
  //       "google_access_token_expiry_date",
  //       JSON.stringify(response.data.expiry_date)
  //     );
  //   }
  //   return response.data;
  // };
  // const fetchGoogleAccessToken = async (tokenResponse) => {
  //   const accessToken = await getGoogleAccessToken(tokenResponse.code);
  //   if (localStorage.getItem("google_access_token")) {
  //     setGoogleCalendarSync(true);
  //   }
  //   return accessToken;
  // };

  const responseGoogle = (response) => {
    console.log("initial response", response);

    const { code } = response;

    const options = axiosOptions({
      url: `${config.url}/api/google-signin`,
      method: "post",
      data: {
        code: code,
        userID: userID,
        calendarTypeID: 1, // google
      },
    });
    console.log("options are", options);

    axios(options)
      .then(async (response) => {
        // console.log("axios response", response.data.info);

        // const { userCalendarID, isDefaultCalendar } = response.data.info;
        // console.log("es default caelndar", isDefaultCalendar);
        // if (isDefaultCalendar) {
        //   // setSession({ ...session, userCalendarID: id });
        //   sessionStorage.setItem("userCalendarID", userCalendarID);
        // }

        afterSignIn();

        // const { refresh_token: refreshToken } = response.data;
        // const hashedToken = `${await bcrypt.hash(refreshToken, 10)}`;
      })
      .catch((error) => {
        return <ErrorHandler message={error.message} />;
      });
  };

  const handleLogin = useGoogleLogin({
    // onSuccess: fetchGoogleAccessToken,
    onSuccess: responseGoogle,
    onError: (error) => console.log(error),
    flow: "auth-code",
    ux_mode: "popup",
    scope:
      "openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/contacts.readonly",
  });

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          signedIn ? signOut() : handleLogin();
          // signOut();
        }}
        className={classes.button}
      >
        <div className={classes.background}>
          <Google size={40} />
        </div>

        <h5 style={{ paddingLeft: "10px" }}>
          {signedIn ? "Disconnect" : "Sign in with Google"}
        </h5>
      </Button>
    </>
  );
}
