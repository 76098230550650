import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// material ui
// import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";

// custom components
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import Button from "components/CustomButtons/Button.js";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";

// data
import { object, string, date, ref, boolean, array } from "yup";
import { Formik } from "formik";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { v4 as uuidv4 } from "uuid";
import { useQuery } from "@apollo/client";
import { meetingManaged } from "graphql/gqlEventDialog";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// import _ from "lodash";
import isEmpty from "lodash/isEmpty";

// icons
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ScheduleIcon from "@material-ui/icons/Schedule";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CloseIcon from "@material-ui/icons/Close";

// styles
import { makeStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const styles = {
  contentContainer: {
    margin: 0,
    fontWeight: 400,
    padding: "15px 0px 20px 0px",
  },
  detailContainer: {
    flex: 1,
    padding: "10px 0px 10px 10px",
  },
  disabled: {
    pointerEvents: "none",
    backgroundColor: "yellow",
  },
  footerContainer: {
    padding: "0px",
  },
  iconContainer: {
    padding: "20px 0px 0px 0px",
    color: primaryColor[4],
    width: "25px",
  },
  lineContainer: {
    display: "flex",

    flexDirection: "row",
    alignItems: "flex-start",
  },
  mainContainer: {
    width: "400px",
    padding: "30px",
  },
  titleLabel: {
    color: grayColor[2],
    fontSize: "22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
  },
};
const useStyles = makeStyles(styles);

export default function EventDialog(props) {
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  try {
    const classes = useStyles();

    const { title, info, setInfo, sendInfoToParent, deleteEvent } = props;
    const navigate = useNavigate();
    const [allDay, setAllDay] = useState(false);

    console.log("externalMeetingID", info);

    const { loading, error, data, refetch } = useQuery(meetingManaged, {
      variables: { meetingIDString: info.data.id || "" },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });

    if (loading) return <ActivityIndicator fullscreen />;

    console.log("probando");
    if (data && data.spMeetingManaged) {
      const MeetingDialog = () => {
        // meeting created in Orquesti

        return (
          <Dialog
            open={info.visible || false}
            onClose={setInfo}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.mainContainer }}
          >
            <DialogTitle
              id="form-dialog-title"
              disableTypography
              className={classes.titleLabel}
            >
              Meeting
              <Button
                color="gray"
                justIcon
                simple
                onClick={() => setInfo({ ...info, visible: false })}
              >
                <CloseIcon />
              </Button>
            </DialogTitle>
            <DialogContent className={classes.contentContainer}>
              <h5>
                This is a meeting managed in Orquesti. To view or change
                details, you have to go to the meeting section.
              </h5>

              {/* <pre>{JSON.stringify(fprops.values.attendees, null, 2)}</pre> */}
            </DialogContent>
            <DialogActions className={classes.footerContainer}>
              <Button
                color="primary"
                onClick={(item) => {
                  navigate({
                    pathname: "/app/meeting",
                    search: `id=${data.spMeetingManaged.nodes[0]}`,
                  });
                }}
              >
                Go to meeting
              </Button>
            </DialogActions>
          </Dialog>
        );
      };

      const RegularDialog = () => {
        const [addLoading, setAddLoading] = useState(false);
        const [deleteLoading, setDeleteLoading] = useState(false);
        console.log("datos iniciales en event dialog", info.data);
        const initialValues = {
          title: info.data.title,
          // startDate: "",
          startDate: format(info.data.start, "yyyy-MM-dd"),
          endDate: format(info.data.end, "yyyy-MM-dd"),
          allDay: false,
          startTime: isEmpty(info.data)
            ? "00:00"
            : format(info.data.start, "HH:mm"),
          endTime: isEmpty(info.data)
            ? "00:00"
            : format(info.data.end, "HH:mm"),
          // location: info.data.location,
          // attendees: info.data.attendees,
          input: "",
          calendarID: info.data.calendarID,
        };

        const submitForm = (values, actions) => {
          setAddLoading(true);
          console.log("submitted................", { values, actions });

          // console.log("cambiando la fecha", {
          //   sinCambio: values.startDate,
          //   conCambio: new Date(values.startDate),
          //   conParseISO: parseISO(values.startDate),
          // });

          const newObject = {
            id: info.data.id || uuidv4(),
            title: values.title,
            //   location: values.location,
            allDay: values.allDay,
            start: allDay
              ? parse("00:00", "HH:mm", parseISO(values.startDate))
              : parse(values.startTime, "HH:mm", parseISO(values.startDate)),
            end: allDay
              ? parse("00:00", "HH:mm", parseISO(values.endDate))
              : parse(values.endTime, "HH:mm", parseISO(values.startDate)),
            location: values.location,
            attendees: values.attendees, // values.attendees.map((item) => item.email),
            calendarID: values.calendarID,
          };

          console.log("el nuevo objecto es................", newObject);

          sendInfoToParent(newObject);

          // alert(newArray);
          // buttonActionFunction();
        };

        const handleDelete = () => {
          setDeleteLoading(true);
          console.log("la data entes del delete del evento", info);
          deleteEvent(info.data);
        };

        return (
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              //   alert("se sometieron los valores", values);
              //   console.log("values", values);

              submitForm(values, actions);
              actions.resetForm();
            }}
            validationSchema={object().shape({
              title: string().required("Required").max(150, "Max. 150 digits"),
              startDate: date().nullable().required("Required"),
              allDay: boolean(),
              endDate: date()
                .nullable()
                .when("allDay", {
                  is: (allDay) => allDay === true,
                  then: date()
                    .required("required")
                    .min(ref("startDate"), "Must be after start date"),
                }),
              startTime: string().required("Required"),
              endTime: string().test(
                "is-greater",
                "Must be after start time",
                function (value) {
                  const { startTime, allDay } = this.parent;

                  // if allDay is selected, doesn't perform time comparison validation
                  return allDay === true
                    ? true
                    : parse(value, "HH:mm", new Date()) >
                        parse(startTime, "HH:mm", new Date());
                }
              ),
              // location: string().required(),
              // attendees: array().of(
              //   string().email("Digite un correo electrónico válido")
              // ),
              attendees: array().of(
                object().shape({
                  // id: string(),
                  name: string().max(40, "Maximum 40 digits"),
                  email: string()
                    .email("Digite un correo electrónico válido")
                    .max(100, "Maximum 100 digits"),
                })
              ),
              input: string().email("Digite un correo electrónico válido"),
            })}
          >
            {(fprops) => (
              <Dialog
                open={info.visible || false}
                onClose={setInfo}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.mainContainer }}
              >
                <DialogTitle
                  id="form-dialog-title"
                  disableTypography
                  className={classes.titleLabel}
                >
                  {title}
                  <Button
                    color="gray"
                    justIcon
                    simple
                    onClick={() => setInfo({ ...info, visible: false })}
                  >
                    <CloseIcon />
                  </Button>
                </DialogTitle>
                <DialogContent className={classes.contentContainer}>
                  {/* title */}

                  <div className={classes.lineContainer}>
                    <div className={classes.iconContainer}>
                      <DescriptionOutlinedIcon fontSize="small" />
                    </div>
                    <div className={classes.detailContainer}>
                      <CustomMainInput
                        error={fprops.touched.title && fprops.errors.title}
                        height="30px"
                        label="Title"
                        name="title"
                        onBlur={fprops.handleBlur("title")}
                        onChange={fprops.handleChange("title")}
                        placeholder="Title"
                        value={fprops.values.title}
                        variant="input"
                        autoFocus
                        disabled={addLoading || deleteLoading}
                      />
                    </div>
                  </div>
                  {/* date */}
                  <div className={classes.lineContainer}>
                    <div className={classes.iconContainer}>
                      <CalendarTodayIcon fontSize="small" />
                    </div>
                    <div className={classes.detailContainer}>
                      <CustomMainInput
                        error={
                          fprops.touched.startDate && fprops.errors.startDate
                        }
                        height="30px"
                        label={fprops.values.allDay ? "Start date" : "Date"}
                        name="startDate"
                        onBlur={fprops.handleBlur("startDate")}
                        onChange={(date) => {
                          // console.log("el start date es", e);
                          fprops.setFieldValue(
                            "startDate",
                            format(date, "yyyy-MM-dd")
                          );
                          // fprops.setFieldValue("startDate", date);
                        }}
                        //   onChange={fprops.handleChange("startDate")}
                        placeholder={allDay ? "Start date" : "Date"}
                        value={fprops.values.startDate}
                        variant="inputDate"
                        disabled={addLoading || deleteLoading}
                      />
                    </div>
                    {fprops.values.allDay && (
                      <div className={classes.detailContainer}>
                        <CustomMainInput
                          error={
                            fprops.touched.endDate && fprops.errors.endDate
                          }
                          height="30px"
                          label="End Date"
                          name="endDate"
                          onBlur={fprops.handleBlur("endDate")}
                          onChange={(date) =>
                            fprops.setFieldValue(
                              "endDate",
                              format(date, "yyyy-MM-dd")
                            )
                          }
                          placeholder="End date"
                          value={fprops.values.endDate}
                          variant="inputDate"
                          disabled={addLoading || deleteLoading}
                        />
                      </div>
                    )}
                  </div>

                  <div className={classes.lineContainer}>
                    <div className={classes.iconContainer}></div>

                    <Checkbox
                      classes={{ checked: classes.checkbox }}
                      color="default"
                      checked={fprops.values.allDay}
                      onChange={() => {
                        // fprops.setFieldValue("startTime", "00:00");
                        // fprops.setFieldValue("endTime", "00:00");
                        fprops.setFieldValue("allDay", !fprops.values.allDay);
                      }}
                      name="allDay"
                      disabled={addLoading || deleteLoading}
                    />
                    <h6
                      onClick={() =>
                        fprops.setFieldValue("allDay", !fprops.values.allDay)
                      }
                    >
                      All day event
                    </h6>
                  </div>

                  {!fprops.values.allDay && (
                    <div className={classes.lineContainer}>
                      <div className={classes.iconContainer}>
                        <ScheduleIcon fontSize="small" />
                      </div>
                      <div className={classes.detailContainer}>
                        <CustomMainInput
                          error={
                            fprops.touched.startTime && fprops.errors.startTime
                          }
                          height="30px"
                          label="Start time"
                          name="startTime"
                          onBlur={fprops.handleBlur("startTime")}
                          onChange={fprops.handleChange("startTime")}
                          variant="inputTime"
                          value={fprops.values.startTime}
                          disabled={addLoading || deleteLoading}
                        />
                      </div>

                      <div className={classes.detailContainer}>
                        <CustomMainInput
                          error={
                            fprops.touched.endTime && fprops.errors.endTime
                          }
                          height="30px"
                          label="End time"
                          name="endTime"
                          onBlur={fprops.handleBlur("endTime")}
                          onChange={fprops.handleChange("endTime")}
                          variant="inputTime"
                          value={fprops.values.endTime}
                          disabled={addLoading || deleteLoading}
                        />
                      </div>
                    </div>
                  )}

                  {/* <pre>{JSON.stringify(fprops.values, null, 2)}</pre> */}
                </DialogContent>
                <DialogActions className={classes.footerContainer}>
                  {info.data.id !== null && (
                    <Button
                      color="danger"
                      onClick={handleDelete}
                      isLoading={deleteLoading}
                      disabled={addLoading}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={fprops.handleSubmit}
                    isLoading={addLoading}
                    disabled={deleteLoading}
                  >
                    {info.data.id === null ? "Add event" : "Update event"}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Formik>
        );
      };

      // show a meeting dialog if an external calendar is connected and it's a meeting, hence hasMeetingID is not null
      // or if there's no connected calendarID
      return data.spMeetingManaged.nodes[0] ? (
        <ErrorBoundary
          FallbackComponent={(error) => (
            <LogErrorComponent
              error={error}
              size="large"
              data={{
                errorLevel: "fatal",
                generalData: { ...generalData },
                otherData: { props },
              }}
            />
          )}
        >
          <MeetingDialog />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary
          FallbackComponent={(error) => (
            <LogErrorComponent
              error={error}
              size="large"
              data={{
                errorLevel: "fatal",
                generalData: { ...generalData },
                otherData: { props },
              }}
            />
          )}
        >
          <RegularDialog />
        </ErrorBoundary>
      );
    }
  } catch (error) {
    return (
      <ErrorHandler
        error={error}
        //  errorExplanation={errorState.errorExplanation} // only for dialog, not error
        //  errorClear={() => setErrorState()}
        data={{
          errorLevel: "fatal",
          generalData: { ...generalData },
          otherData: {},
        }}
      />
    );
  }
}
