import { gql } from "@apollo/client";

// export const createAccount = gql`
//   mutation createAccount($userID: Int!) {
//     createAccount(input: { account: { ownerId: $userID } }) {
//       clientMutationId
//     }
//   }
// `;

export const retrieveAccount = gql`
  mutation retrieveAccount($userID: Int!) {
    spUserSignIn(input: { userId1: $userID, userAuthProviderId1: 1 }) {
      results {
        accountId
      }
    }
  }
`;
