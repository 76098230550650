import React, { useContext, useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
// custom components
import Button from "components/CustomButtons/Button.js";
import Dialog from "components/CustomDialog/CustomDialog";
// // data
import { SessionContext } from "../../contexts/SessionContext";
import { SocketContext } from "contexts/SocketContext";
import { TourContext } from "contexts/TourContext";
import { useQuery, useMutation } from "@apollo/client";
import { badgeHelpdesk } from "graphql/gqlNavBar";
import { daysDifference } from "functions/Common";

// icons
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import Person from "@material-ui/icons/Person";
// material ui components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
//icons
import Headset from "assets/svgComponent/headset";
// styles
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function NavbarRightElements(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [modal, setModal] = useState({ visible: false, name: "" });
  const { session, setSession } = useContext(SessionContext);
  const { firstUse, stageID, isOwner, userID } = session;

  const { socket } = useContext(SocketContext);

  const { tourState, setTourState } = useContext(TourContext);
  const { moveToStepID, stepID } = tourState;

  const [openNotification, setOpenNotification] = useState(null);

  const { loading, data, error, refetch } = useQuery(badgeHelpdesk, {
    variables: { userID },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip: firstUse,
  });

  const accountMenuRef = useRef();

  const handleClickNotification = (event) => {
    console.log("Tries to open menu", { target: event.currentTarget });
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const handleSignOut = () => {
    navigate("/");
    setSession({});
    sessionStorage.clear();
  };

  useEffect(() => {
    socket.on("receiving_helpDesk_update", (data) => {
      console.log(
        "%cRuns useEffect in navBar",
        "background-color: blue; color: white"
      );
      // if the current user is either support or the current user, updates the badge
      if ([data.userTo, data.userFrom].includes(userID)) {
        refetch();
      }
    });
  }, [socket]);

  const Notifications = () => {
    return (
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          {/* <Notifications className={classes.icons} /> */}
          <span className={classes.notifications}>5</span>
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Mike John responded to your email
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You have 5 new tasks
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You{"'"}re now friend with Andrew
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another Notification
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another One
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    );
  };

  const AccountMenu = () => {
    const [openAccountMenu, setOpenAccountMenu] = useState(null);
    const handleClickAccountMenu = (event) => {
      setOpenAccountMenu(accountMenuRef.current);
    };
    const handleCloseAccountMenu = () => {
      setOpenAccountMenu(null);
    };

    // useEffect(() => {
    //   // update the tab according to the tour step change
    //   console.log("tourState.stepID", moveToStepID);
    //   if (moveToStepID === "tour-end" && stepID !== "tour-end") {
    //     setOpenAccountMenu(accountMenuRef.current);

    //     setTimeout(() => {
    //       setTourState({ ...tourState, stepID: "tour-end" });
    //     }, 1000);
    //   }
    // }, [moveToStepID]);

    //   useEffect(() => {
    //   // console.log("datos en account details tour", { tourActive, stepIndex });
    //   const currentStepID = "account-0";
    //   if (tourActive && tourState.stepID !== currentStepID) {
    //     setTimeout(() => {
    //       setTourState({ ...tourState, stepID: currentStepID });
    //     }, 600);
    //   }
    // }, []);

    // useEffect(() => {
    //   console.log("%cruns useEffect", "background-color: purple; color: white");

    //   if (openAccountMenu !== null && moveToStepID === "tour-end") {
    //     console.log("%cupdateTourState", "background-color: pink; color: red");

    //     setTourState({ ...tourState, stepID: "tour-end" });
    //   }
    // }, [openAccountMenu]);

    return (
      <div className={classes.manager}>
        <Button
          ref={accountMenuRef}
          id="tourEnd"
          size="lg"
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openAccountMenu ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickAccountMenu}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openAccountMenu)}
          anchorEl={openAccountMenu}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openAccountMenu }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper id="tourEnd">
                <ClickAwayListener onClickAway={handleCloseAccountMenu}>
                  <MenuList role="menu">
                    {props.routes.map((prop, key) => {
                      return (
                        <MenuItem
                          // id={prop.name === "Run tour" && "tourEnd"}
                          key={key}
                          onClick={() => {
                            // console.log(
                            //   "ljlkjlkj",
                            //   prop.layout + prop.path.split(":")[0]
                            // );
                            navigate(prop.layout + prop.path.split(":")[0]); // if there's a parameter, removes from the url
                            handleCloseAccountMenu();
                            if (prop.name === "Run tour") {
                              setSession({ ...session, firstUse: true });
                            }
                          }}
                          className={classes.dropdownItem}
                        >
                          {prop.name}
                        </MenuItem>
                      );
                    })}
                    <Divider light />

                    <MenuItem
                      onClick={() => {
                        handleSignOut();
                      }}
                      className={classes.dropdownItem}
                    >
                      Sign out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    );
  };

  // const ModalToShow = () => {
  //   let titleText = "";
  //   let mainText = "";
  //   switch (true) {
  //     case expiryInfo.expiryDays > 1:
  //       titleText = `Suscripción vence en ${expiryInfo.expiryDays} días`;
  //       mainText = "Renuévala para evitar una interrupción del servicio";
  //       break;
  //     case expiryInfo.expiryDays === 1:
  //       titleText = "La suscripción vence en 1 día";
  //       mainText = "Renuévala para evitar una interrupción del servicio";
  //       break;
  //     case expiryInfo.expiryDays === 0:
  //       titleText = "La suscripción vence hoy";
  //       mainText = "Renuévala para evitar una interrupción del servicio";
  //       break;
  //     case expiryInfo.expiryDays < 0:
  //       titleText = "Suscripción vencida";
  //       mainText = "El servicio está detenido";
  //   }

  //   switch (modal.name) {
  //     case "expiry": {
  //       return (
  //         <Dialog
  //           iconPreset="error"
  //           center
  //           visibleStatus={modal}
  //           setVisibleStatus={setModal}
  //           title={titleText}
  //           mainText={mainText}
  //           buttonCloseLabel="Cerrar"
  //           buttonActionLabel1="Administrar suscripción"
  //           buttonActionFunction1={() => {
  //             navigate("/app/account/subscription");
  //             setModal({ visible: false, name: "" });
  //           }}
  //           buttonActionIcon1={<EventAvailableOutlinedIcon />}
  //         />
  //       );
  //     }

  //     default:
  //       return null;
  //   }
  // };

  const HelpDesk = () => {
    return (
      <div className={classes.manager}>
        <Button
          size="md"
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          // aria-owns={openAccountMenu ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={() => navigate("/app/helpdesk")}
          className={classes.buttonLink}
        >
          {data &&
            data.spHelpdeskTicketStatus &&
            data.spHelpdeskTicketStatus.totalCount > 0 && (
              <span className={classes.notifications}>
                {data.spHelpdeskTicketStatus.totalCount}
              </span>
            )}
          {/* <Headset className={classes.icons} /> */}
          <Headset
          // size={18}
          // priorityID={fprops.values.toDoPriorityID || 1}
          />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Help desk</p>
          </Hidden>
        </Button>
      </div>
    );
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      {isOwner && <Subscription />}
      <HelpDesk />
      {/* <Notifications /> */}
      {/* {expiryInfo.showExpiryAlert && <Subscription />} */}
      <AccountMenu />
      {/* <ModalToShow /> */}
    </div>
  );
}

const Subscription = (props) => {
  const navigate = useNavigate();
  const { session, setSession } = useContext(SessionContext);
  const { stageID, subscriptionExpiryDate } = session;
  const classes = useStyles();

  // const [expiryInfo, setExpiryInfo] = useState({
  //   active: true,
  //   expiryDays: 6,
  //   showExpiryAlert: false,
  // });

  // console.log("%cdifference", "background-color: blue; color: white", {
  //   subscriptionExpiryDate,
  //   currentDate: new Date(),
  //   difference: daysDifference(subscriptionExpiryDate, new Date()),
  // });

  const expiryDays =
    subscriptionExpiryDate === null
      ? null
      : daysDifference(subscriptionExpiryDate, new Date());

  const SubscriptionLabel = () => {
    switch (stageID) {
      case 1: // under third party subscription

      case 2: // in trial
        return (
          <>
            {expiryDays > 0 && (
              <div
                className={`${classes.standardContainer} ${classes.attentionContainer}`}
              >
                <h6 className={classes.label}>Trial expires in</h6>
                <h6 className={classes.indicatorNumber}>{expiryDays}</h6>
                <h6 className={classes.label}>
                  {expiryDays > 1 ? "days" : "day"}
                </h6>
              </div>
            )}
            {expiryDays === 0 && (
              <div
                className={`${classes.standardContainer} ${classes.attentionContainer}`}
              >
                <h6 className={classes.label}>Trial expires </h6>
                <h6 className={classes.todayIndicator}>today</h6>
              </div>
            )}
          </>
        );
      case 3: // trial expired
        return (
          <>
            <div
              className={`${classes.standardContainer} ${classes.warningContainer}`}
            >
              {/* <h6 className={classes.todayIndicator}>Servicio detenido.</h6> */}
              <h6 className={classes.label}>Trial expired </h6>
            </div>
          </>
        );
      case 4: // in subscription
      case 5: // subscription short expiry
        return (
          <>
            {expiryDays > 0 && expiryDays <= 10 && (
              <div
                className={`${classes.standardContainer} ${classes.attentionContainer}`}
              >
                <h6 className={classes.label}>Subscription expires in</h6>
                <h6 className={classes.indicatorNumber}>{expiryDays}</h6>
                <h6 className={classes.label}>
                  {expiryDays > 1 ? "days" : "day"}
                </h6>
              </div>
            )}
            {expiryDays === 0 && (
              <div
                className={`${classes.container} ${classes.expiryContainer}`}
              >
                <h6 className={classes.label}>Subscription expires today </h6>
                {/* <h6 className={classes.todayIndicator}>today</h6> */}
              </div>
            )}
          </>
        );
      case 20: // checks if theres a subscription expired // basic
        return (
          <>
            {subscriptionExpiryDate !== null && (
              <div
                className={`${classes.container} ${classes.expiryContainer}`}
              >
                <h6 className={classes.label}>
                  Subscription expired. Using Basic Plan
                </h6>
                {/* <h6 className={classes.label}>Using Basic Plan</h6> */}
              </div>
            )}
          </>
        );

      case 6: // subscription expired
    }
  };

  return (
    <Link component="button" variant="body2">
      <div className={classes.manager} onClick={() => navigate("/app/account")}>
        <SubscriptionLabel />
      </div>
    </Link>
  );
};
