import { useContext, useEffect, useRef, useState } from "react";

// @material-ui/core components

// custom components
import Button from "components/CustomButtons/Button";
import { CustomCheckbox } from "components/CustomCheckbox/CustomCheckbox";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import { DropDownContacts } from "components/DropDownContacts";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { StepperProgress } from "components/StepperProgress";

// data
import { useMutation } from "@apollo/client";
import axios from "axios";
import config from "config";
import { ActionsContext } from "contexts/ActionsContext";
import {
  axiosOptions,
  convertLocalToUTC,
  debounce,
  snakeToCamel,
} from "functions/Common";
import { sendToOwner } from "graphql/gqlActions";
import { demoData } from "utils/demoData";
import { tourData } from "utils/tourData";
import { object, string } from "yup";
import { SessionContext } from "../../contexts/SessionContext";

import useFetch from "functions/useFetch";
// errors
import {
  LogErrorComponent,
  logError,
  useDataForLog,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// icons
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

// style
import { makeStyles } from "@material-ui/core/styles";
import {
  dangerColor,
  grayColor,
  primaryColor,
  purpleColor,
  successColor,
} from "assets/jss/material-dashboard-react";
import format from "date-fns/format";
import { formatDateShort } from "functions/Common";
import "./Actions.css";

const styles = {
  alignCenter: {
    textAlign: "center",
  },
  alignLeft: {
    textAlign: "left",
    paddingLeft: "15px",
  },
  alignRight: {
    textAlign: "right",
    // paddingRight: "15px",
  },

  actionListContainer: { display: "flex", flexDirection: "column", flex: 3 },

  actionsContainer1: {
    // backgroundColor: "yellow",
    scrollbarGutter: "stable",
    overflowY: "auto",
    // height: "100%",
    // height: "calc(100vh - 207px)",
    width: "100%",
    direction: "rtl",
    padding: "25px 0px 25px 5px",
  },
  actionsContainer2: {
    // direction: "rtl",
    direction: "ltr",
  },
  actionLineContainer: {
    borderRadius: 5,
    padding: "10px 5px 10px 10px",
    transition: "all 150ms ease 0s",
  },

  actionLineContainerSelected: {
    // pointerEvents: "none",
    backgroundColor: primaryColor[9],
    // padding: "10px 15px 10px 10px",
    marginRight: "0px",
    border: `0px solid ${grayColor[6]}`,
    borderRadius: "7px 0px 0px 7px",
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",

      // backgroundColor: "#0074D9",
      bottom: "-60px",
      height: "60px",
      width: "30px",
      // left: "0px",
      right: "0px",
      borderTopRightRadius: "30px",
      boxShadow: `0 -30px 0 0 ${primaryColor[9]}` /* This is where the magic happens! */,
    },
    "&:after": {
      content: '""',
      position: "absolute",

      // backgroundColor: "#0074D9",
      top: "-60px",
      height: "60px",
      width: "30px",
      // left: "0px",
      right: "0px",
      borderBottomRightRadius: "30px",
      boxShadow: `0 30px 0 0 ${primaryColor[9]}` /* This is where the magic happens! */,
    },
  },

  actionLineContainerDivider: {
    borderBottom: `1px solid ${grayColor[6]}`,
  },
  emptyContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: grayColor[5],
  },

  gridContainer: {
    flex: 1,
  },

  headerContainer: {
    alignItems: "center",
    // backgroundColor: "orange",
    // backgroundColor: grayColor[8],
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // marginBottom: "15px",
    padding: "5px 10px 0px 20px",
    transition: "all 600ms ease-in-out",
    borderBottom: `1px solid ${grayColor[6]}`,
  },
  header: {
    fontSize: "12px",
    paddingBottom: "10px",
    fontWeight: 600,
  },

  organizerLabel: { flex: 1, textAlign: "center" },

  nameLabel: {
    // backgroundColor: "orange",
  },

  status: {
    borderRadius: "3px",
    padding: "8px",
    textAlign: "center",
    color: "white",
    fontSize: "11px",
  },
  statusAssigned: { backgroundColor: primaryColor[6] },
  statusAccepted: { backgroundColor: successColor[1] },
  statusRejected: { backgroundColor: purpleColor[3] },
  statusCompleted: { backgroundColor: grayColor[4] },
  statusDeleted: { backgroundColor: dangerColor[1] },
};

const useStyles = makeStyles(styles);

export const ActionsHeaderAndList = () => {
  const classes = useStyles();
  return (
    <div className={classes.actionListContainer}>
      <ActionsHeader />

      <ErrorBoundary
        FallbackComponent={(error) => (
          <LogErrorComponent
            error={error}
            data={{
              location: "ActionsList",
              // userID: userID,
            }}
          />
        )}
      >
        <ActionsList />
      </ErrorBoundary>
    </div>
  );
};

const ActionsList = (props) => {
  const classes = useStyles();

  const { actions, setActions } = useContext(ActionsContext);

  const scrollContainerRef = useRef(null); // use to restore scroll position after render if any is scroll has happened
  const scrollPositionRef = useRef(0);

  // useEffect(() => {
  //   // Scroll to the stored position after component updates
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollTop = scrollPositionRef.current;
  //   }
  // }, []);

  let timeoutId;
  const handleScroll = (e) => {
    // this scroll is used to remove the contextual menu in case it's displayed while scrolling

    // indicates the scrolling has started
    clearTimeout(timeoutId);
    // stores the last scrolled position in order to return to this position after render
    scrollPositionRef.current = e.target.scrollTop;
    // mark if the component has scrolled in order to remove the contextual menu if displayed

    setActions({ ...actions, hasScrolled: true });
    // indicates the scrolling has stopped after 500ms
    timeoutId = setTimeout(() => {
      setActions({ ...actions, hasScrolled: false });
    }, 500);
  };

  return (
    <div
      ref={scrollContainerRef}
      onScroll={handleScroll}
      className={classes.actionsContainer1}
    >
      <div className={classes.actionsContainer2}>
        <ActionItems />
      </div>
    </div>
  );
};

const ActionItems = (props) => {
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log

  const { session, setSession } = useContext(SessionContext);
  const { userID, languageID, firstUse } = session;

  const { actions, setActions } = useContext(ActionsContext);
  const { pageNumber, parameterList, searchTerm } = actions;

  const { selectedMeetingActionID, filters, sorts } = parameterList;
  const previousDataRef = useRef();

  const initialData = () => {
    if (firstUse) {
      return tourData("actionsList");
    } else {
      if (previousDataRef.current !== undefined) {
        return previousDataRef.current;
      } else {
        return [];
      }
    }
  };

  const [latestDataState, setLatestDataState] = useState(initialData());

  const [selectedItemHovered, setSelectedItemHovered] = useState(false);

  const fetchParameters = {
    url: `${config.url}/api/actions-list`,
    method: "get",
    params: {
      userID: userID,
      languageID: languageID,
      filter: filters.filter((item) => item.filterLineStatus === "ready"),
      sort: sorts.filter((item) => item.sortLineStatus === "ready"),
      pageSize: 20,
    },
    pageNumber: pageNumber,
  };

  const { loadingFetch, errorFetch, dataFetch, hasMoreFetch } = useFetch({
    ...fetchParameters,
    params: JSON.stringify(fetchParameters.params), // has be sent as a string and parsed after, otherwise runs into loop because objects are sent a reference which changes every time
    skip: firstUse,
  });

  const [sendToOwnerMut] = useMutation(sendToOwner, {
    // onCompleted: () => {
    //   setActions({ ...actions, refreshData: new Date() });
    //   // setUpdateInfo(!updateInfo);
    // },
  });

  // const [errorState, setErrorState] = useState(
  //   errorFetch
  //     ? {
  //         error: errorFetch,
  //         errorExplanation: "Couldn't retrieve action list",
  //         data: {
  //           errorLevel: "fatal",
  //           otherData: { axiosOptions: fetchParameters },
  //         },
  //       }
  //     : null
  // );

  useEffect(() => {
    if (!firstUse && !loadingFetch && dataFetch !== undefined) {
      // if there's no data, useFetch returns null.  if so, returns empty array instead or the values processed
      let modifiedDataFetch =
        userID === 111
          ? demoData("actionsList")
          : dataFetch === null
          ? []
          : dataFetch.map((item) => ({
              ...snakeToCamel(item), //converts all properties e.g. meeting_action_id to meetingActionId
              originalResponsibleDesc: item.responsible_desc, // used when changing responsible and needs to switch back to original value
              modifying: false, // will be used when field blur to perform validations before saving to db
            }));

      setLatestDataState(modifiedDataFetch);

      // selected first action if current selectedMeetingActionID is null and
      // dataFetch returns values
      if (selectedMeetingActionID === null && modifiedDataFetch.length > 0) {
        setActions({
          ...actions,
          parameterList: {
            ...actions.parameterList,
            selectedMeetingActionID: modifiedDataFetch[0].meetingActionId,
          },
        });
      }

      // setActions({...actions, parameterList: {...actions.parameterList, selectedMeetingActionID: modifiedDataFetch[0]}})
    }
  }, [dataFetch]);

  // previousDataRef.current === undefined
  //   ? modifiedDataFetch
  //   : previousDataRef.current;

  // console.log(
  //   "%clatestDataState",
  //   "background-color: blue; color: white",
  //   latestDataState
  // );

  const filteredActions = latestDataState.filter((item) =>
    (item.actionName || "")
      .toLowerCase()
      .includes((searchTerm || "").toLowerCase())
  );

  // console.log("actionListState", actionListState);

  // console.log("filtered actions", filteredActions);

  // previousDataRef.current === undefined
  //   ? modifiedDataFetch
  //   : previousDataRef.current;

  const updateField = (
    meetingActionID,
    updatedProperties,
    withDebounce = false,
    saveToDb = true
  ) => {
    try {
      console.log(
        "%updated properties in updateField",
        "background-color: blue; color: white",
        { updatedProperties, meetingActionID }
      );

      // process for state update

      const newArray = filteredActions.map((item2, index2) => {
        if (meetingActionID === item2.meetingActionId) {
          return {
            ...item2,
            ...updatedProperties,
            errorOwner:
              updatedProperties.errorOwner === undefined
                ? undefined
                : updatedProperties.errorOwner,
          };
        } else {
          return item2;
        }
      });
      previousDataRef.current = newArray;
      // modifiedDataFetch = newArray;

      // const newArray = [...filteredActions];
      // // creates an array with the properties names
      // const propertyNameList = Object.keys(updatedProperties);
      // const propertyName = propertyNameList[1]; // takes the sccond value of the list
      // const propertyValue = updatedProperties[propertyName];
      // // takes the index line of the array and the property name to update the
      // newArray[index][propertyName] = propertyValue;

      console.log(
        "%cEl new array es",
        "background-color: purple; color: white",
        newArray
      );

      setLatestDataState(newArray);
      console.log("filteredActions", filteredActions);
      // return null;

      //--------------------------------- process array for db update ---------------------------------------------

      if (saveToDb === true) {
        const options = axiosOptions({
          url: `${config.url}/api/action-update`,
          method: "post",
          data: {
            ...updatedProperties,
            meetingActionID,
          },
        });

        const updateFunction = () => {
          axios(options)
            .then(
              // Successful fetch
              (response) => {
                // console.log(
                //   "%cResponse",
                //   "background-color: red; color: white",
                //   response
                // );
                // setUpdateInfo(!updateInfo);
              }
            )
            // Unsuccessful fetch
            .catch((err) => {
              console.log(
                "ERROR: En Email Confirmation se presentó el siguiente error: " +
                  err
              );
            });
        };

        if (withDebounce === false) {
          updateFunction();
        } else {
          console.log("%cwith debounce", "background-color: red; color: white");

          debounce(() => updateFunction());
        }
      }
    } catch (error) {
      logError(error, {
        errorLevel: "fatal",
        generalData: { ...generalData },
        otherData: {
          updatedProperties,
          meetingActionID,
        },
      });
      // setErrorState({
      //   error: error,
      //   errorExplanation: "Action could not be updated",
      //   data: {
      //     errorLevel: "critical",
      //     otherData: {
      //       updatedProperties,
      //       meetingActionID,
      //     },
      //   },
      // });
    }
  };

  if (filteredActions.length === 0 && !loadingFetch) {
    return (
      <div className={classes.emptyContainer}>
        <h3>No actions to display</h3>
      </div>
    );
  } else {
    // console.log(
    //   "%cRun again",
    //   "background-color: red; color: white",
    //   filteredActions
    // );
    return filteredActions?.map((action, index) => {
      // console.log("action info", action);

      let statusColor;
      switch (action.statusId) {
        case 1:
          statusColor = classes.statusAssigned;
          break;
        case 2:
          statusColor = classes.statusAccepted;
          break;
        case 3:
          statusColor = classes.statusRejected;
          break;
        case 4:
          statusColor = classes.statusCompleted;
          break;
        case 5:
          statusColor = classes.statusDeleted;
          break;
        default:
      }

      let dropdownListActions = [];

      if (action.statusId === 3) {
        dropdownListActions.push({
          id: 1,
          menuDesc: "Send again to owner",
        });
      }

      return (
        <div
          id="tourActionsLine"
          key={action.meetingActionId}
          className={` ${
            selectedMeetingActionID === action.meetingActionId &&
            classes.actionLineContainerSelected
          } ${classes.actionLineContainer} ${
            classes.actionLineContainerDivider
          }`}
          // className={`${classes.actionLineContainerSelected}`}
          onMouseEnter={() =>
            selectedMeetingActionID === action.meetingActionId &&
            setSelectedItemHovered(true)
          }
          onMouseLeave={() =>
            selectedMeetingActionID === action.meetingActionId &&
            setSelectedItemHovered(false)
          }
          onClick={(event) => {
            setActions({
              ...actions,
              parameterList: {
                ...actions.parameterList,
                selectedMeetingActionID: action.meetingActionId,
              },
            });

            // setSelectedmeetingActionId(action.meetingActionId);

            // sendInfoToParent(action.meetingActionId);
          }}
        >
          {/* <pre>{JSON.stringify(selectedItemHovered, null, 2)}</pre> */}
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                data={{
                  location: "Action list line",
                  userID: userID,
                  actionInfo: action,
                }}
              />
            )}
          >
            <GridContainer
              spacing={2}
              direction="row"
              classes={{ container: classes.gridContainer }}
              alignItems="center"
              justifyContent="center"
            >
              {/* title (show id if in development)*/}
              <GridItem xs={4}>
                <>
                  {/* <h6 className={classes.nameLabel}>
                    {process.env.NODE_ENV === "development" &&
                      action.meetingActionId}
                  </h6> */}

                  <CustomMainInput
                    color="transparent"
                    placeholder="Enter action description"
                    // height="80px"
                    value={action.actionName}
                    removeHover={!selectedItemHovered} // remove the input hover to allow line hover
                    onChange={(event) => {
                      console.log("la descripción nueva es", action);
                      latestDataState[index].title;

                      updateField(
                        action.meetingActionId,
                        {
                          actionName: event.target.value,
                        },
                        true //with Debounce
                      );
                    }}
                    variant="input"
                  />
                </>
              </GridItem>

              {/* owner */}
              <GridItem xs={2}>
                {/* <h5 className={classes.organizerLabel}>
                {action.responsibleDesc}
              </h5> */}

                <OwnerField
                  {...{
                    ...action,
                    index: index,
                    updateField: updateField,

                    selectedItemHovered: selectedItemHovered,
                  }}
                />
              </GridItem>

              {/* created date */}
              {/* <GridItem xs={1}>
              <h5 className={classes.alignCenter}>
                <ErrorBoundary
                  FallbackComponent={(error) => (
                    <LogErrorComponent
                      error={error}
                      data={{
                        location: "Action list line",
                        userID: userID,
                        actionInfo: action,
                      }}
                    />
                  )}
                >
                  {formatDateShort(action.start_date, {
                    actionInfo: action,
                  })}
                </ErrorBoundary>
              </h5>
            </GridItem> */}

              {/* due date */}
              <GridItem xs={2} justifyContent="center">
                <h5 className={classes.alignCenter}>
                  <ErrorBoundary
                    FallbackComponent={(error) => (
                      <LogErrorComponent
                        error={error}
                        data={{
                          location: "Action list line",
                          userID: userID,
                          actionInfo: action,
                        }}
                      />
                    )}
                  >
                    <CustomMainInput
                      color="transparent"
                      alignRight
                      // removeHover={true}
                      removeHover={!selectedItemHovered} // remove the input hover to allow line hover
                      clearable // adds clear icon at the end
                      onChange={(newDate) => {
                        const UTCNewDate = format(newDate, "yyyy-MM-dd");

                        updateField(action.meetingActionId, {
                          dueDate: UTCNewDate,
                        });
                      }}
                      onDeleteDate={() => {
                        updateField(action.meetingActionId, {
                          dueDate: null,
                        });
                      }}
                      // error={item.errors?.dueDate}
                      // error={actionsState[index].errors?.dueDate}
                      value={action.dueDate}
                      variant="inputDate"
                    />
                  </ErrorBoundary>
                </h5>
              </GridItem>

              {/* status */}
              <GridItem xs={1}>
                <CustomTooltip
                  tooltipText={action.statusTooltip}
                  placement="bottom"
                >
                  <h6 className={`${classes.status} ${statusColor}`}>
                    {action.statusDesc}
                    {/* {action.statusId === 3 &&
                  new Date() > new Date(meeting.end_date)
                    ? "Overtime"
                    : meeting.statusId === 2 &&
                      new Date() > new Date(meeting.start_date)
                    ? "Late"
                    : meeting.status} */}
                  </h6>
                </CustomTooltip>
              </GridItem>

              {/* to do progress */}
              <GridItem xs={1}>
                {![2].includes(action.statusId) ||
                action.stepCurrent === null ? null : (
                  <CustomTooltip
                    tooltipText={`This action is currently in the step ${action.stepCurrent} of ${action.stepTotal} of the owner's to do list`}
                    placement="bottom"
                  >
                    <StepperProgress
                      bottomValue={0}
                      currentValue={action.stepCurrent}
                      topValue={action.stepTotal}
                    />
                  </CustomTooltip>
                )}
              </GridItem>

              {/* action completed */}
              <GridItem xs={1}>
                <CustomTooltip
                  tooltipText={
                    action.completedDate
                      ? `Mark as completed ${formatDateShort(
                          action.completedDate
                        )}`
                      : null
                  }
                  // tooltipText={"sdf"}
                  placement="bottom"
                >
                  <CustomCheckbox
                    // disabled
                    value={action.completedDate === null ? false : true}
                    onChange={(e) => {
                      const checked = e.target.checked;

                      // if (checked === true) {
                      const UTCCompletedDate = convertLocalToUTC(new Date());

                      console.log("UTCCompletedDate", UTCCompletedDate);

                      updateField(action.meetingActionId, {
                        completedDate:
                          checked === true ? UTCCompletedDate : null,
                      });
                    }}
                  />
                </CustomTooltip>
              </GridItem>

              {/* actions to perform */}
              <GridItem xs={1}>
                {dropdownListActions.length > 0 && (
                  <div style={{ position: "relative", zIndex: 100 }}>
                    <CustomMainInput
                      color="primary"
                      // dropDownList={[
                      //   { id: 1, menuDesc: "Toggle completed" },
                      //   { id: 2, menuDesc: "Send again to owner" },
                      // ]}
                      dropDownList={dropdownListActions}
                      // disabled={toDoDetailsInfo.archived}
                      justIcon
                      iconStart={
                        <Button color="primary" justIcon>
                          <MoreHorizIcon />
                        </Button>
                      }
                      // value={fprops.values.toDoPriorityID}
                      variant="dropdown"
                      width={35} // button width
                      dropDownFunction={(item) => {
                        switch (item.id) {
                          // send back to owner when previously rejected
                          case 1:
                            sendToOwnerMut({
                              variables: {
                                meetingActionID: action.meetingActionId,
                              },
                            });

                          default:
                        }
                        console.log("action", action);
                        // fprops.setFieldValue("toDoPriorityID", item.id);
                        // fprops.handleChange("toDoPriorityID");
                      }}
                    />
                  </div>
                )}
              </GridItem>
            </GridContainer>
          </ErrorBoundary>

          {/* {errorState && (
            <ErrorHandler
              error={errorState.error}
              data={{
                errorLevel: errorState.data.errorLevel,
                generalData: { ...generalData },
                otherData: { ...errorState.data.otherData },
              }}
              errorExplanation={errorState.errorExplanation} // only for dialog, not error
              errorClear={() => setErrorState()}
            />
          )} */}
        </div>
      );
    });
  }
};

const ActionsHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <GridContainer
        spacing={2}
        direction="row"
        classes={{ container: classes.gridContainer }}
      >
        <GridItem xs={4}>
          <h5 className={`${classes.header} ${classes.alignLeft}`}>Action</h5>
        </GridItem>
        <GridItem xs={2}>
          <h5 className={`${classes.header} ${classes.alignCenter}`}>Owner</h5>
        </GridItem>

        <GridItem xs={2}>
          <h5 className={`${classes.header} ${classes.alignCenter}`}>
            Due date
          </h5>
        </GridItem>
        <GridItem xs={1}>
          <h5 className={`${classes.header} ${classes.alignCenter}`}>
            Owner status
          </h5>
        </GridItem>
        <GridItem xs={1}>
          <h5 className={`${classes.header} ${classes.alignCenter}`}>
            Owner progress
          </h5>
        </GridItem>
        <GridItem xs={1}>
          <h5 className={`${classes.header} ${classes.alignCenter}`}>
            Action completed
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const OwnerField = (props) => {
  const generalData = useDataForLog(); // context data to populate error log

  const { actions, setActions } = useContext(ActionsContext);
  const { hasScrolled } = actions;
  // console.log("%caction", "background-color: red; color: white", props);
  const {
    updateField,
    meetingActionId,
    meetingId,
    responsibleDesc,
    originalResponsibleDesc,

    possibleOwners,
    errorOwner,
    selectedItemHovered,
  } = props;

  const [possibleOwnersUnfiltered, setPossibleOwnersUnfiltered] =
    useState(possibleOwners);

  const [possibleOwnersFiltered, setPossibleOwnersFiltered] =
    useState(possibleOwners);

  const filteredPossibleOwners = (inputValue, index) => {
    try {
      if (inputValue.length === 0) {
        setPossibleOwnersFiltered(possibleOwnersUnfiltered);
      } else {
        const filteredValues = possibleOwnersUnfiltered.filter(
          (item) =>
            (item.name || "")
              ?.toLowerCase()
              .includes(inputValue?.toLowerCase()) ||
            (item.email || "")
              ?.toLowerCase()
              .includes(inputValue?.toLowerCase())
        );

        setPossibleOwnersFiltered(filteredValues);
      }

      // return filteredValues;
    } catch (error) {
      logError(error, {
        generalData: { ...generalData },
        otherData: { possibleOwnersUnfiltered, inputValue },
      });
    }
  };
  // const [showMenu, setShowMenu] = useState(false);
  // console.log(
  //   "%cresponsible owners",
  //   "background-color: red; color: white",
  //   action.responsibleDesc
  // );

  return (
    <CustomMainInput
      color="transparent"
      // removeHover={false}
      removeHover={!selectedItemHovered} // remove the input hover to allow line hover
      // showMenu={showMenu}
      // dropDownList={filteredPossibleOwners(action.responsibleDesc, 0)}
      dropDownList={possibleOwnersFiltered}
      sendInfoToParent={(info) => {
        // if user pressed escape, returns to original state
        if (info === null) {
          updateField(meetingActionId, {
            responsibleDesc: originalResponsibleDesc,
            modifying: false, // returns to original state to avoid triggering validation
          });
        }
      }}
      onFocus={(inputValue) => {
        // console.log("se hizo el onFocus con ", inputValue);
        // changeScrollState(false);
        // setShowMenu(true);
        // updates the list of possible owners
        // meetingPossibleOwnersQry({
        //   variables: {
        //     meetingID: meetingId,
        //   },
        // });
      }}
      // placeholder={
      //   actionName.length === 0 ? "" : "Enter name or email"
      // }
      dropDownInputRender={(item) => {
        return (
          <DropDownContacts
            {...{
              name: item.name,
              email: item.email,
              imagePath: item.imagePath,
            }}
          />
        );
      }}
      // error={actionsState[index].errors?.responsibleDesc}
      onSelected={(item) => {
        // console.log("item selected en onSelected", item);
        updateField(meetingActionId, {
          responsibleDesc: item.name || item.email,
          responsibleEmail: item.email,
          modifying: false,
        });
      }}
      onBlur={(value) => {
        if (props.modifying === true) {
          console.log("starts validation");
          const obj = {
            responsibleEmail: value,
          };
          const yupObj = object().shape({
            responsibleEmail: string()
              .email("Select from the list or type an email")
              .required("A responsible is required"),
          });
          yupObj
            .validate(obj)
            .then(function (value) {
              // if validation is successful
              console.log("successful validation");
              // updateField(
              //   meetingActionId,
              //   {
              //     responsibleDesc: item.responsibleDesc,
              //     responsibleEmail: item.responsibleDesc,
              //     modifying: false,
              //   },
              //   false, // withDebounce
              //   true // save to db
              // );
            })
            .catch(function (err) {
              console.log("el error es", JSON.stringify(err));
              // if there's a validation error

              updateField(
                meetingActionId,
                {
                  errorOwner: err.message,
                },
                false, // withDebounce
                false // save to db
              );
            });
        } else {
          console.log("not modifying");
        }
      }}
      onChange={(value) => {
        console.log("cambio el campo", value);
        filteredPossibleOwners(value);

        // const newArray = filteredActions.map((item2, index2) => {
        //   if (index === index2) {
        //     return {
        //       ...item2,
        //       responsibleDesc: value,
        //     };
        //   } else {
        //     return item2;
        //   }
        // });
        // console.log(
        //   "%cEl new array es",
        //   "background-color: red; color: white",
        //   newArray
        // );

        // setLatestDataState(newArray);

        updateField(meetingActionId, {
          responsibleDesc: value,
          modifying: true,
        });
      }}
      error={errorOwner}
      value={responsibleDesc}
      variant="inputDropdown"
    />
  );
};
