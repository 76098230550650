import React, {
  useContext,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";

// data
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { meetingRating } from "graphql/gqlMeeting";
import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import { ContentContext } from "contexts/ContentContext";
import { axiosOptions } from "functions/Common";
import axios from "axios";
import config from "config";
import cloneDeep from "lodash/cloneDeep";
import { tourData } from "utils/tourData";

// hooks
import useSaveAgenda from "functions/useSaveAgenda";

// core components
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar";
import { AgendaList } from "./AgendaList";
import { AgendaDetails } from "./AgendaDetails";
import { AttendeesMain } from "./AgendaAttendees";
import { AgendaSideMenu } from "./AgendaSideMenu";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// style
import { makeStyles } from "@material-ui/core/styles";
import { grayColor, primaryColor } from "assets/jss/material-dashboard-react";

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },

  noAgendaContainer: {
    height: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    border: `1px solid ${primaryColor[8]}`,
    borderRadius: "10px",
    flex: 1,
    marginRight: "5px",
  },

  rightSection: {
    border: `1px solid ${primaryColor[8]}`,
    borderRadius: "10px",
    flexDirection: "column",
    display: "flex",
    height: "100%",
    flexWrap: "wrap",
  },
  rightSectionNoWrap: {
    flexWrap: "nowrap",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  },
};

const useStyles = makeStyles(styles);

const MeetingContents = forwardRef((props, ref) => {
  // console.log("los props en meeting content", props);

  let location = useLocation();
  const {
    contentChangedFunction,
    contentChangedValue,
    // hideActivityIndicator,
    meetingID,
    parentEventID,
    tempMeetingID,
    updateMeetingCost,
  } = props;

  const classes = useStyles();

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { statusID, isOrganizer, meetingTypeID, startDate, endDate } = meeting;

  const { session, setSession } = useContext(SessionContext);
  const { userID, firstUse } = session;

  const { content, setContent } = useContext(ContentContext);
  const {
    agendaItems,
    blankAgendaItemWithNewID,
    privateAgenda,
    refreshData,
    showAgenda,
  } = content;

  // console.log("%cContent", "background-color: red; color: white", content);

  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  const [sideMenuOpenFromParent, setSideMenuOpenFromParent] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    messageType: "",
    message: "",
  });

  const [changed, setChanged] = useState(true);
  const { saveAgenda } = useSaveAgenda();

  // this ref is used to store the data in the agenda temporarly when accessing a repeating occurrence
  // and saving either for the current meeting, all the series or this and futre

  const durationRefs = useRef([]);
  const titleRefs = useRef([]);
  const detailsRef = useRef();
  const notesRef = useRef();
  const lastInputRef = useRef({ field: "initialTest" });
  const cursorPositionRef = useRef();

  const { loading, data, error, refetch } = useQuery(meetingRating, {
    variables: { meetingID: meetingID || parentEventID },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip: firstUse,
  });

  let initialContent = "";

  let changes = meeting.changesFromContent;

  const loadMeetingContents = () => {
    // console.log("starts useEffect en meeting contents");
    // console.log("%c fetch contents ", "background-color: yellow; color: red");
    // avoid refreshing the data if the data was modified but not saved yet,
    // particularly when change repeating meetings and changing tab without saving how this should be applied (current, all series, following evnets)
    if (contentChangedValue === false) {
      const options = axiosOptions({
        url: `${config.url}/api/meeting-info`,
        method: "get",
        params: {
          meetingID: meetingID || parentEventID,
          userID: userID,
          origin: "content",
          tempMeetingID: tempMeetingID || null,
        },
      });

      axios(options)
        .then(async (response) => {
          console.log("la respuesta en Meeting content es", response.data.info);
          const {
            agendaItems,
            // content,
            // privateNotes,
            showAgenda,
            hasPreviousActions,
            privateAgenda,
          } = response.data.info;

          console.log(
            "%cresponse",
            "background-color: yellow; color: red",
            response.data.info
          );

          // initialContent = JSON.parse(content);
          // console.log("showAgenda", showAgenda);
          if (showAgenda) {
            console.log(
              "%cmeeting.changesFromContent",
              "background-color: orange; color: white",
              meeting.changesFromContent
            );

            if (meeting.changesFromContent !== undefined) {
              // if there's data in the meeting state, it means the data has not been saved yet to the db
              // and it should be loaded from the state and not from the db

              setContent({
                ...content,
                agendaItems: meeting.changesFromContent.agendaItems,
                content: meeting.changesFromContent.content, //JSON.parse(meeting.changesFromContent.content),
                hasPreviousActions: hasPreviousActions,
                privateAgenda: privateAgenda,
                selectedItem: { id: agendaItems[0].id, index: 0 },
                showAgenda: showAgenda,
                parentEventID: parentEventID,
              });
            } else {
              // loads info adding an empty line
              const agendaItemsCopy = cloneDeep(agendaItems);
              const newAgendaItems =
                agendaItemsCopy.length === 0
                  ? []
                  : agendaItemsCopy.map((item) => ({
                      ...item,
                      privateNotes: item.privateNotes,
                      details: item.details,
                      desc: item.desc,
                      duration: item.duration,
                    }));

              // if still in edit mode (not started, completed, cancelled or deleted), adds an empty line to agenda items
              // if (![3, 4, 5, 6].includes(statusID)) {
              //   // add empty line
              //   newAgendaItems.push(
              //     blankAgendaItemWithNewID(newAgendaItems, meetingID)
              //   );
              // }

              // setSelectedItem({ id: newAgendaItems[0].id, index: 0 });
              console.log(
                "%cnewAgendaItems",
                "background-color: blue; color: white",
                { newAgendaItems }
              );

              setContent({
                ...content,
                agendaItems: newAgendaItems,
                hasPreviousActions: hasPreviousActions,
                privateAgenda: privateAgenda,
                selectedItem:
                  newAgendaItems.length === 0
                    ? { id: null, index: null }
                    : { id: newAgendaItems[0].id, index: 0 },
                showAgenda: showAgenda,
              });
            }
          }

          setIsLoading(false);
        })
        .catch((error) => {
          setErrorState({
            error: error,
            errorExplanation: "Couldn't retrieve meeting contents",
            data: { errorLevel: "fatal", otherData: { axiosOptions: options } },
          });
        });
    }
  };

  useEffect(() => {
    try {
      if (firstUse) {
        const tourDummyData = tourData("content");

        setContent(tourDummyData);
        // setContentsState(tourDummyData);
        return;
      }
      // loads info if there's data
      if (data !== undefined) {
        // console.log("updates data");

        loadMeetingContents();
      }
    } catch (error) {
      logError(error, { generalData: { ...generalData }, otherData: data });
    }
  }, [data, refreshData]);

  useImperativeHandle(
    ref,
    () => ({
      triggerSubmit1() {
        console.log(
          "******************************************trigger submit for only the current Event"
        );
        saveAgenda({
          agendaItems: agendaItems,
          occurrenceRepeatType: 1,
          parentEventID: parentEventID, // required for update
          content: content, // required to update content
          meeting: meeting, // required to update content
          setContent: setContent, // required to update meeting
          setMeeting: setMeeting, // required to update meeting
          // selectedItem: { id: parseInt(draggableId), index: destination.index },
          userID: userID, // required for update
          saveRecurrence: true,
        });
        // occurrenceRepeatType = 1;
      },
      triggerSubmit2() {
        console.log(
          "******************************************trigger submit for all series",
          agendaItems
        );
        // saveAgenda({
        //   agendaItems: agendaItems,
        //   occurrenceRepeatType: 2,
        //   showSnackbar: true,
        // });

        saveAgenda({
          agendaItems: agendaItems,
          occurrenceRepeatType: 2,
          parentEventID: parentEventID, // required for update
          content: content, // required to update content
          meeting: meeting, // required to update content
          setContent: setContent, // required to update meeting
          setMeeting: setMeeting, // required to update meeting
          // selectedItem: { id: parseInt(draggableId), index: destination.index },
          userID: userID, // required for update
          saveRecurrence: true,
        });

        // occurrenceRepeatType = 2;
      },
      triggerSubmit3() {
        console.log(
          "******************************************trigger submit this event and following",
          agendaItems
        );
        saveAgenda({
          agendaItems: agendaItems,
          occurrenceRepeatType: 3,
          parentEventID: parentEventID, // required for update
          content: content, // required to update content
          meeting: meeting, // required to update content
          setContent: setContent, // required to update meeting
          setMeeting: setMeeting, // required to update meeting
          // selectedItem: { id: parseInt(draggableId), index: destination.index },
          userID: userID, // required for update
          saveRecurrence: true,
        });
        // occurrenceRepeatType = 3;
      },
    }),
    [content]
  );

  useEffect(() => {
    // if there are no changes, don't setup the listener to prevent moving away

    // console.log(
    //   "%cUse effect start ",
    //   "background-color: red; color: white",
    //   changed
    // );

    const handleBeforeUnload = (event) => {
      if (changed) {
        console.log("handleBeforeUnload");
        const message =
          "You have unsaved changes. Are you sure you want to leave1111?";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    const unlisten = () => {
      // Handle route changes
      // const allowNavigation = window.confirm(
      //   "You have unsaved changes. Are you sure you want to leave?"
      // );
      // if (!allowNavigation) {
      //   history.go(1); // Go back to the previous route
      // }
    };

    // window.addEventListener("beforeunload", handleBeforeUnload);
    window.onbeforeunload = () =>
      "You have unsaved changes. Are you sure you want to leave1111?";

    return () => {
      // window.removeEventListener("beforeunload", handleBeforeUnload);
      window.onbeforeunload = null;
      unlisten(); // Stop listening to route changes when the component unmounts
    };
  }, [location]);

  if (error) {
    return (
      <ErrorHandler
        error={error}
        errorClear={() => setErrorState()}
        retryFunction={() => refetch()}
        data={{
          errorLevel: "fatal",
          generalData: { ...generalData },
          otherData: { message: error.message, meetingID: meetingID },
        }}
      />
    );
  }

  // mark the agenda as not found only when the meeting status is not planned or draft and there are no agenda items
  const agendaNotFound =
    ![0, 1].includes(statusID) && content.agendaItems.length <= 1;

  const NoAgenda = () => {
    return (
      <div className={classes.noAgendaContainer}>
        <h3 style={{ color: grayColor[4] }}>
          No agenda was defined for this meeting
        </h3>
      </div>
    );
  };

  return (
    <>
      <CardBody fullscreen>
        <div className={classes.section}>
          <div className={classes.mainContainer}>
            {agendaNotFound ? (
              <NoAgenda />
            ) : (
              showAgenda &&
              !(meetingTypeID === 2 && !isOrganizer && privateAgenda) && (
                <>
                  <AgendaList parentEventID={parentEventID} />

                  <AgendaDetails />

                  <ErrorBoundary
                    FallbackComponent={(error) => (
                      <LogErrorComponent
                        error={error}
                        data={{
                          errorLevel: "fatal",
                          generalData: { ...generalData },
                          otherData: { meeting },
                        }}
                      />
                    )}
                  >
                    <AgendaSideMenu setShowSnackbar={setShowSnackbar} />
                  </ErrorBoundary>
                </>
              )
            )}

            {[3, 7].includes(statusID) && isOrganizer && (
              <div
                className={`${classes.rightSection} ${
                  showAgenda && classes.rightSectionNoWrap
                }`}
              >
                <ErrorBoundary
                  FallbackComponent={(error) => (
                    <LogErrorComponent
                      error={error}
                      size="large"
                      data={{
                        errorLevel: "fatal",
                        generalData: { ...generalData },
                        otherData: { meeting },
                      }}
                    />
                  )}
                >
                  <AttendeesMain />
                </ErrorBoundary>
              </div>
            )}
          </div>
        </div>
      </CardBody>

      {errorState && (
        <ErrorHandler
          error={errorState.error}
          errorExplanation={errorState.errorExplanation} // only for dialog, not error
          errorClear={() => setErrorState()}
          data={{
            errorLevel: errorState.data.errorLevel,
            generalData: { ...generalData },
            otherData: { ...errorState.data.otherData },
          }}
        />
      )}

      <Snackbar
        messageType={showSnackbar.messageType}
        message={showSnackbar.message}
        open={showSnackbar.show}
        close={() => setShowSnackbar({ ...showSnackbar, show: false })}
      />
    </>
  );
  // }
});

export default MeetingContents;
