import { gql } from "@apollo/client";

export const meetingPossibleOwners = gql`
  query meetingPossibleOwners($meetingID: Int!) {
    spMeetingPossibleOwners(meetingId1: $meetingID) {
      nodes
    }
  }
`;

// export const deleteMeetingAction = gql`
//   mutation deleteMeetingAction($id: Int!, $deleteDate: Datetime) {
//     updateMeetingActionById(
//       input: {
//         meetingActionPatch: { deleteDate: $deleteDate, meetingActionId: null }
//         id: $id
//       }
//     ) {
//       clientMutationId
//     }
//   }
// `;

export const completedAction = gql`
  mutation completedAction($completedDate: Datetime, $meetingActionID: Int!) {
    updateMeetingActionById(
      input: {
        meetingActionPatch: { completedDate: $completedDate }
        id: $meetingActionID
      }
    ) {
      clientMutationId
    }
  }
`;
