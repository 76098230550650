import React, { useContext, useEffect, useState, useCallback } from "react";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import { primaryColor, infoColor } from "assets/jss/material-dashboard-react";
import { useNavigate, useLocation } from "react-router-dom";

// import "react-big-calendar/lib/sass/styles";
import "!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

// material UI
import { makeStyles } from "@material-ui/core/styles";

// custom components

import CustomDialog from "../../components/CustomDialog/CustomDialog";
import EventDialog from "../../components/Calendar/EventDialog";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
// data
import { TourContext } from "contexts/TourContext";
import { SessionContext } from "../../contexts/SessionContext";

import axios from "axios";
import config from "../../config";
import { addDaysToDate, axiosOptions } from "functions/Common";
// import { calendarUsed } from "graphql/gqlCalendar";

// style
import { grayColor } from "assets/jss/material-dashboard-react.js";

const styles = {
  cardBody: {
    paddingTop: "20px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
  objectiveNumber: {
    color: grayColor[4],
    display: "flex",
    marginBottom: "7px",
    width: "30px",
  },
  titleContainer: {
    display: "flex",
    width: "30px",
  },
  titleLabel: {
    color: grayColor[4],
    paddingLeft: "20px",
    paddingRight: "10px",
  },
};
const useStyles = makeStyles(styles);
const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export default function CalendarScreen(props) {
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  const navigate = useNavigate();

  try {
    const classes = useStyles();

    const CalendarCustom = (props) => {
      const { session, setSession } = useContext(SessionContext);
      const { userID, calendarUsed } = session;

      const [isLoading, setIsLoading] = useState(true);
      const [modal, setModal] = useState({
        visible: false,
        name: "",
        data: {},
      });

      const [dateRange, setDateRange] = useState({
        start: startOfWeek(new Date()),
        end: endOfWeek(new Date()),
      });
      const [allEvents, setAllEvents] = useState([]);

      const handleSelectSlot = useCallback(({ start, end }) => {
        console.log("handleSelectSlot");

        const userCalendarID = sessionStorage.getItem("userCalendarID");
        if (userCalendarID === "null" || userCalendarID === null) {
          console.log("userCalendarID1", userCalendarID);
          setModal({
            visible: true,
            name: "no-calendar-connected",
          });
        } else {
          console.log("userCalendarID2", userCalendarID);
          setModal({
            visible: true,
            name: "add-event",
            data: {
              id: null,
              title: "",
              start: start,
              end: end,
              attendees: [],
              location: "",
            },
          });
        }
      }, []);

      const handleSelectEvent = useCallback(
        (event) => {
          console.log("handleSelectEvent", event);
          setModal({
            visible: true,
            name: "change-event",
            data: { ...event },
          });
        },

        []
      );

      // const { defaultDate, scrollToTime } = useMemo(
      //   () => ({
      //     defaultDate: new Date(2015, 3, 12),
      //     scrollToTime: new Date(1970, 1, 1, 6),
      //   }),
      //   []
      // );

      const formats = {
        // remove the times from the events
        eventTimeRangeFormat: () => {
          return "";
        },
      };

      const updateInfo = (start, end) => {
        setIsLoading(true);
        console.log("inicia el update info", { userID, start, end });
        // console.log("el calendario usado es", data.spCalendarUsed.nodes[0]);
        // let options;

        const options = axiosOptions({
          url: `${config.url}/api/list-events`,
          method: "get",
          params: {
            userID: userID,
            startDate: start,
            endDate: end,
          },
        });

        console.log("las opciones list events", options);

        axios(options)
          .then(async (response) => {
            console.log("se obtuvo respuesta", response.data.info);
            // if (200 === response.status) {
            // }
            // console.log("los eventos son", response.data.info.data.items);
            // console.log("los eventos son", response.data.info);
            // const processedEventList = response.data.info;

            const processedEventList = response.data.info.map((event) => {
              return {
                ...event,
                start: new Date(event.start),
                end: new Date(event.end),
                status: 333,
                // allDay: event.start === undefined && true,
              };
            });
            console.log("processedEventList", processedEventList);
            // console.log("eventsList", eventsList);

            setAllEvents(processedEventList);
            setIsLoading(false);
            // const { refresh_token: refreshToken } = response.data;
            // const hashedToken = `${await bcrypt.hash(refreshToken, 10)}`;
          })
          .catch((error) => {
            setIsLoading(false);
            console.log("error", error);
            if (error.response.data.error === "invalid_grant") {
              // console.log("error de invalid grant");
              setModal({
                visible: true,
                name: "invalid-grant",
              });
            } else {
              setErrorState({
                error: error,
                errorExplanation: "There was a problem displaying the events",
                data: {
                  errorLevel: "fatal",
                  otherData: { axiosOptions: options },
                },
              });
            }
          });
      };

      useEffect(() => {
        if (errorState === undefined) {
          updateInfo(dateRange.start, dateRange.end);
        }
      }, []);

      const onSelecting = () => {
        console.log("on Selecting");
      };

      const onRangeChange = (range) => {
        try {
          console.log("el rango de fechas es", range);
          let startDate = null;
          let endDate = null;

          if (Array.isArray(range)) {
            if (range.length === 7) {
              // if week
              startDate = range[0];
              endDate = range[6];
              console.log("el rango es week", {
                start: startDate,
                end: endDate,
              });
              setDateRange({ start: startDate, end: endDate });
              updateInfo(startDate, endDate);
            } else {
              // if day

              startDate = range[0];
              endDate = addDaysToDate(range[0], 1);
              console.log("el rango es day", { range, startDate, endDate });
              setDateRange({ start: startDate, end: endDate });

              // updateInfo(startDate, endDate);
            }
          } else {
            // if month or agenda
            console.log("el rango es indefinido");
            setDateRange(range.start, range.end);

            updateInfo(range.start, range.end);
          }
        } catch (error) {
          logError(error, {
            errorLevel: "critical",
            generalData: { ...generalData },
            otherData: { range },
          });
        }
      };

      const eventPropGetter = useCallback(
        (event, start, end, isSelected) => ({
          // ...(isSelected && {
          //   style: {
          //     backgroundColor: '#000',
          //   },
          // }),
          // ...(moment(start).hour() < 12 && {
          //   className: 'powderBlue',
          // }),
          ...{
            style: {
              backgroundColor: primaryColor[4],
              borderWidth: "1px",
              borderColor: primaryColor[7],
              padding: "3px 0px 0px 10px",
              fontSize: "12px",
            },
          },

          ...(event.statusID === 1 && {
            style: {
              backgroundColor: infoColor[8],
              borderWidth: "0px",
              padding: "5px",
              fontSize: "12px",
              border: `2px dashed  ${infoColor[4]}`,
              color: infoColor[4],
            },
          }),
          // ...(event.statusID === 1 && {
          //   style: {
          //     backgroundColor: infoColor[4],
          //     borderWidth: "0px",
          //     padding: "10px",
          //     fontSize: "12px",
          //     border: `2px dashed white`,
          //     // color: infoColor[4],
          //   },
          // }),
          ...([2, 3, 4, 5, 6, 7, 8].includes(event.statusID) && {
            style: {
              backgroundColor: infoColor[4],
              borderWidth: "0px",
              padding: "5px",
              fontSize: "12px",
            },
          }),
          // ...(event.calendarID === 13 && {
          //   style: {
          //     backgroundColor: infoColor[4],
          //     borderWidth: "0px",
          //     padding: "10px",
          //   },
          // }),
        }),
        []
      );

      const ModalToShow = () => {
        try {
          // const [isLoadingModal, setIsLoadingModal] = useState(false);

          const addOrUpdateEvent = (event) => {
            // setActionLoading(true);
            // let options;
            // let eventExists;
            console.log("el evento es", event);

            const eventExists = allEvents.filter(
              (item) => event.id === item.id && item
            );

            const options = axiosOptions({
              url: `${config.url}/api/create-update-event-external-calendar`,
              method: "post",
              data: {
                title: event.title,
                start: event.start,
                end: event.end,
                location: event.location,
                userID: userID,

                externalEventID: eventExists.length === 0 ? null : event.id,
                // description: "",
                allDay: event.allDay,
              },
            });

            axios(options)
              .then(async (response) => {
                // if (200 === response.status) {
                // }
                console.log("*************axios response", response.data);
                // const { refresh_token: refreshToken } = response.data;
                // const hashedToken = `${await bcrypt.hash(refreshToken, 10)}`;
                // updates the events list
                setModal({ visible: false, name: "" });
                updateInfo(dateRange.start, dateRange.end);
              })

              .catch((error) => {
                console.error("add or update event in Calendar", error);
                switch (error.response.status) {
                  case 403:
                    setModal({
                      visible: true,
                      name: "insuficient-permissions",
                    });
                    break;

                  default:
                    setErrorState({
                      error: error,
                      errorExplanation: "Couln't update the calendar",
                      data: {
                        errorLevel: "critical",
                        otherData: { axiosOptions: options },
                      },
                    });
                }
              });

            // ------------
            // setModal({ visible: false, name: "" });

            // updates remote calendar
          };

          const handleDelete = (event) => {
            // setIsLoadingModal(true);
            console.log("event delete", event);

            const options = axiosOptions({
              url: `${config.url}/api/delete-event`,
              method: "post",
              data: {
                externalEventID: event.id,
                userID: userID,
              },
            });

            axios(options)
              .then(async (response) => {
                // if (200 === response.status) {
                // }
                console.log("axios response", response.data);
                // const { refresh_token: refreshToken } = response.data;
                // const hashedToken = `${await bcrypt.hash(refreshToken, 10)}`;

                setModal({ visible: false, name: "" });
                // updates the events list
                updateInfo(dateRange.start, dateRange.end);
              })
              .catch((error) => {
                switch (error.response.status) {
                  case 401: //unauthorized
                    setModal({ visible: true, name: "unauthorized" });
                    break;
                  default:
                    console.log("handle delete in Calendar", error);
                    // setIsLoadingModal(true);
                    setErrorState({
                      error: error,
                      errorExplanation: "Couldn't delete event",
                      data: {
                        errorLevel: "critical",
                        otherData: { axiosOptions: options },
                      },
                    });
                    setModal({ visible: false, name: "" });
                }
              });
          };

          switch (modal.name) {
            case "add-event":
              return (
                <EventDialog
                  title="Add event"
                  info={modal}
                  setInfo={setModal}
                  deleteEvent={(item) => handleDelete(item)}
                  sendInfoToParent={(event) => addOrUpdateEvent(event)}
                />
              );
            case "change-event":
              return (
                <EventDialog
                  title="Change event"
                  info={modal}
                  setInfo={setModal}
                  deleteEvent={(item) => handleDelete(item)}
                  sendInfoToParent={(event) => addOrUpdateEvent(event)}
                />
              );
            case "delete-event":
              return (
                <CustomDialog
                  visibleStatus={modal}
                  setVisibleStatus={setModal}
                  title="Do you want to remove this event?"
                  buttonActionFunction1={() => handleDelete()}
                  deleteDialog
                />
              );
            case "insuficient-permissions":
              return (
                <CustomDialog
                  iconPreset="forbidden"
                  center
                  visibleStatus={modal}
                  setVisibleStatus={setModal}
                  title="Insuficient permissions to modify the external calendar"
                  mainText="Disconnect and reconnect your external calendar and grant permissions to update your calendar when asked"
                  buttonCloseLabel="OK"
                />
              );
            case "unauthorized":
              return (
                <CustomDialog
                  iconPreset="forbidden"
                  center
                  visibleStatus={modal}
                  setVisibleStatus={setModal}
                  title="Insuficient permissions to modify the external calendar"
                  mainText="Disconnect and reconnect your external calendar and grant permissions to update your calendar when asked"
                  buttonCloseLabel="OK"
                />
              );

            case "no-calendar-connected":
              return (
                <CustomDialog
                  iconPreset="error"
                  center
                  visibleStatus={modal}
                  setVisibleStatus={setModal}
                  title="No calendar connected"
                  mainText={
                    <p>
                      If you want to connect a calendar, go to{" "}
                      <a href="settings">Settings</a>
                    </p>
                  }
                  buttonCloseLabel="OK"
                />
              );

            case "invalid-grant":
              return (
                <CustomDialog
                  iconPreset="error"
                  center
                  visibleStatus={modal}
                  setVisibleStatus={setModal}
                  title="Reconnect your calendar"
                  mainText={
                    <p>
                      Calendar events can&apos;t be displayed until you
                      disconnect and reconnect your calendar in Settings
                    </p>
                  }
                  buttonActionLabel1="Cancel"
                  // buttonActionColor1="danger"
                  buttonActionFunction1={() => {
                    setModal({ visible: false, name: "" });
                  }}
                  buttonActionLabel2="Go to settings"
                  buttonActionFunction2={() => navigate("/app/settings")}
                  buttonActionLoading2={isLoading}
                />
              );

            default:
              return null;
          }
        } catch (error) {
          logError(error, {
            errorLevel: "critical",
            generalData: { ...generalData },
            otherData: { modal },
          });
        }
      };

      console.log("refresca el calendario");
      return (
        <>
          <Calendar
            // defaultDate={defaultDate}
            defaultView={Views.WEEK}
            endAccessor="end"
            events={allEvents}
            eventPropGetter={eventPropGetter}
            formats={formats}
            localizer={localizer}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            onSelecting={onSelecting}
            onRangeChange={onRangeChange}
            startAccessor="start"
            selectable={true}
            // style={{ height: "96%", width: "98%", position: "absolute" }}
            // style={{ height: "200px" }}
          />
          {isLoading === true && <ActivityIndicator fullscreen withShade />}

          <ModalToShow />
        </>
      );
    };

    return (
      <Card fullscreen>
        <CardBody fullscreen className={classes.cardBody}>
          {/* {calendarCustom()} */}
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                size="large"
                data={{
                  errorLevel: "fatal",
                  generalData: { ...generalData },
                  otherData: {},
                }}
              />
            )}
          >
            <CalendarCustom />
          </ErrorBoundary>
          {errorState && (
            <ErrorHandler
              error={errorState.error}
              errorExplanation={errorState.errorExplanation} // only for dialog, not error
              errorClear={() => setErrorState()}
              data={{
                errorLevel: errorState.data.errorLevel,
                generalData: { ...generalData },
                otherData: { ...errorState.data.otherData },
              }}
            />
          )}
        </CardBody>
      </Card>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
