import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
// core components
import Dialog from "components/CustomDialog/CustomDialog";
// data
import { SessionContext } from "../../contexts/SessionContext";

export default function ErrorHandler(props) {
  const { error, location, query } = props;
  const [visible, setVisible] = useState({ visible: true });
  const navigate = useNavigate();
  const { setSession } = useContext(SessionContext);
  const message = error?.message;

  console.log("%cError", "background-color: red; color: white", props);

  const findTerm = (term) => {
    if (message?.includes(term)) {
      return message;
    }
  };
  // return <h1>holalasdfasdf</h1>;

  console.log("el error es: ", { message, location, query });
  switch (message) {
    case findTerm("Failed to fetch"):
      return (
        <Dialog
          iconPreset="error"
          visibleStatus={visible}
          setVisibleStatus={setVisible}
          title="Connection problem"
          mainText="Please check your internet."
          buttonCloseLabel="OK"
          center
        />
      );

    case findTerm("violates foreign key"):
      return (
        <Dialog
          iconPreset="error"
          visibleStatus={visible}
          setVisibleStatus={setVisible}
          title="This operation can't be executed."
          // mainText="Existen datos asociados"
          buttonCloseLabel="OK"
          center
        />
      );
    case findTerm("Received status code 403"):
      return (
        <Dialog
          iconPreset="forbidden"
          visibleStatus={visible}
          setVisibleStatus={setVisible}
          title="Not authorized"
          buttonActionLabel1="Sign in again"
          buttonActionFunction1={() => {
            navigate("/");
            setSession(null);
            sessionStorage.clear();
          }}
          center
        />
      );
    case findTerm("App error"):
      return (
        <Dialog
          iconPreset="error"
          visibleStatus={visible}
          setVisibleStatus={setVisible}
          title="Oops, there's been a problem"
          mainText="Apologies for the inconvenience.  We'll fix it as soon as possible"
          buttonActionLabel1="Return"
          buttonActionFunction1={() => {
            navigate(-1);
          }}
          center
        />
      );

    default:
      return (
        <Dialog
          iconPreset="error"
          visibleStatus={visible}
          setVisibleStatus={setVisible}
          title={
            process.env.NODE_ENV === "development"
              ? `error only displayed in develoment:  ${message}`
              : "Please sign in again"
          }
          buttonActionLabel1="Exit"
          buttonActionFunction1={() => {
            navigate("/");
            setSession(null);
            sessionStorage.clear();
          }}
          center
        />
      );
  }
}
