import { useContext, useEffect, useRef, useState } from "react";

// material ui

// data
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ContentContext } from "contexts/ContentContext";
import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import {
  deleteTemplate,
  getTemplate,
  saveMeetingTemplate,
  templateList,
  updateTemplateName,
} from "graphql/gqlAgendaSideMenu";

import { Formik } from "formik";
import sortBy from "lodash/sortBy";
import { object, string } from "yup";

// core components
import CustomDialog from "components/CustomDialog/CustomDialog";

import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import Button from "components/CustomButtons/Button.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import SideMenu from "components/SideMenu/SideMenu";
// errors
import { ErrorHandler, logError, useDataForLog } from "functions/ErrorBoundary";

// hooks
import useSaveAgenda from "functions/useSaveAgenda";

// icons
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

// style
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "assets/jss/material-dashboard-react";
import { sentenceCase } from "functions/Common";
// import { usePrompt } from "functions/usePrompt";

const styles = {
  sidebarItemLabel: {
    color: grayColor[3],
  },
  sidebarSaveButtonContainer: {
    marginTop: "10px",
  },
  sidebarSavedTemplateContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    width: "100%",
    // backgroundColor: "yellow",
  },
};

const useStyles = makeStyles(styles);

export const AgendaSideMenu = (props) => {
  const generalData = useDataForLog(); // context data to populate error log

  try {
    const { setShowSnackbar } = props;
    const { meeting, setMeeting } = useContext(MeetingContext);
    const { isOrganizer, meetingID, statusID, startDate } = meeting;

    const { content, setContent } = useContext(ContentContext);
    const { showAgenda } = content;

    const [modal, setModal] = useState({
      visible: false,
      name: "",
    });

    if ([1].includes(statusID)) {
      return (
        <>
          {showAgenda && (
            <>
              <SideMenu
                title="Templates"
                stickyContent={<StickyContent setModal={setModal} />}
                scrollableContent={<TemplateList setModal={setModal} />}
              />
              <SideMenuModals
                modal={modal}
                setModal={setModal}
                setShowSnackbar={setShowSnackbar}
              />
            </>
          )}
        </>
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

const TemplateList = (props) => {
  console.log("renders");
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const inputRefs = useRef([]);

    const { setModal } = props;

    const { session, setSession } = useContext(SessionContext);
    const { userID, firstUse } = session;

    const { meeting, setMeeting } = useContext(MeetingContext);
    const { meetingID } = meeting;

    const {
      loading: loadingTL,
      data: dataTL,
      error,
      refetch: refetchTL,
    } = useQuery(templateList, {
      variables: { userID: userID },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      skip: firstUse,
    });

    const [editTemplate, setEditTemplate] = useState({
      visible: false,
      name: "",
      id: null,
      index: null,
    });

    const [updateTemplateNameMut] = useMutation(updateTemplateName, {
      refetchQueries: [
        {
          query: templateList,
          variables: { userID: userID },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setEditTemplate({
          ...editTemplate,
          visible: false,
          name: "",
          id: null,
          index: null,
        });
      },
    });

    useEffect(() => {
      if (editTemplate.visible === true) {
        const element = inputRefs.current[editTemplate.index];
        console.log("change focus", {
          index: editTemplate.index,
          element: element,
          elements: inputRefs,
        });

        element?.focus();

        // move cursor to the end of the field
        // element[editTemplate.index].selectionStart =
        //   element[editTemplate.index]?.value.length;

        // // Move the cursor to the end of the input's value
        // const length = element.value.length;
        // element.setSelectionRange(length, length);
      }
    }, [editTemplate]);

    if (loadingTL) return <ActivityIndicator />;

    if (error) {
      return (
        <ErrorHandler
          error={error}
          errorClear={() => setErrorState()}
          retryFunction={() => refetchTL()}
          data={{
            errorLevel: "fatal",
            generalData: { ...generalData },
            otherData: { message: error.message, meetingID: meetingID },
          }}
        />
      );
    }

    if (dataTL && dataTL.allMeetingTemplates) {
      const savedTemplates = dataTL.allMeetingTemplates.nodes;
      const sortedTemplates = sortBy(savedTemplates, "desc");
      console.log("sortedTemplates", sortedTemplates);

      const SortedTemplates = () =>
        sortedTemplates.map((item, index) => {
          if (editTemplate.visible === true && editTemplate.id === item.id) {
            // when editing the template
            return (
              <div
                key={item.id}
                className={classes.sidebarSavedTemplateContainer}
              >
                <CustomMainInput
                  // label={index === 0 && "Objectives"}
                  // ref={inputRefs.current[index]}
                  refID={(el) => {
                    console.log(
                      "%cen el ref",
                      "background-color: yellow; color: red",
                      el
                    );

                    return (inputRefs.current[index] = el);
                  }}
                  // name="objectives"
                  // autoFocus
                  color="primary"
                  height="36px"
                  placeholder="Type a name"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      updateTemplateNameMut({
                        variables: {
                          templateName: sentenceCase(editTemplate.name),
                          templateID: editTemplate.id,
                        },
                      });
                    }
                  }}
                  onChange={(event) => {
                    setEditTemplate({
                      ...editTemplate,
                      name: event.target.value,
                    });
                  }}
                  value={editTemplate.name}
                  variant="input"

                  // onBlur={(event) => {
                  //   setEditTemplate({
                  //     ...editTemplate,
                  //     visible: false,
                  //     name: "",
                  //     id: null,
                  //   });
                  // }}
                  // error={fprops.touched.name && fprops.errors.name}
                />
                <Button
                  color="danger"
                  justIcon
                  onClick={() => {
                    setEditTemplate({
                      ...editTemplate,
                      visible: false,
                      id: null,
                      name: "",
                      index: null,
                    });
                  }}
                >
                  <CloseIcon />
                </Button>

                <Button
                  color="primary"
                  justIcon
                  onClick={() => {
                    // console.log("ejecuta el updateTemplateName");
                    updateTemplateNameMut({
                      variables: {
                        templateName: sentenceCase(editTemplate.name),
                        templateID: editTemplate.id,
                      },
                    });
                  }}
                >
                  <SaveIcon />
                </Button>
              </div>
            );
          } else {
            // when displaying the template list, not editing
            return (
              <div
                key={item.id}
                className={classes.sidebarSavedTemplateContainer}
              >
                <Button
                  fullWidth
                  color="primary"
                  leftAlign
                  onClick={() => {
                    setModal({
                      visible: true,
                      name: "confirmUseTemplate",
                      data: { templateID: item.id },
                    });
                  }}
                >
                  {item.desc}
                </Button>
                <Button
                  color="primary"
                  justIcon
                  onClick={() => {
                    setEditTemplate({
                      ...editTemplate,
                      visible: true,
                      id: item.id,
                      name: item.desc,
                      index: index,
                    });
                  }}
                >
                  <EditIcon />
                </Button>

                <Button
                  color="danger"
                  justIcon
                  onClick={() => {
                    // setEditTemplate({
                    //   ...editTemplate,
                    //   visible: false,
                    //   name: "",
                    //   id: null,
                    //   index: null,
                    // });
                    setModal({
                      visible: true,
                      name: "confirmDeleteTemplate",
                      data: {
                        templateDesc: item.desc,
                        templateID: item.id,
                      },
                    });
                  }}
                >
                  <DeleteOutlineIcon />
                </Button>
              </div>
            );
          }
        });

      return <SortedTemplates />;
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

const StickyContent = (props) => {
  const { setModal } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classes.sidebarSaveButtonContainer}>
        <Button
          fullWidth
          color="primary"
          onClick={() =>
            setModal({ visible: true, name: "confirmSaveTemplate" })
          }
        >
          Save current as template
        </Button>
      </div>
      {/* <h5 className={classes.sidebarItemLabel}>Saved templates</h5> */}
    </>
  );
};

const SideMenuModals = (props) => {
  const { modal, setModal, setShowSnackbar } = props;
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log

  const { session, setSession } = useContext(SessionContext);
  const { userID } = session;

  const { meeting, setMeeting, updateRating } = useContext(MeetingContext);

  const { content, setContent } = useContext(ContentContext);
  const { agendaItems, parentEventID } = content;

  const { saveAgenda } = useSaveAgenda();

  try {
    const [getTemplateQry] = useLazyQuery(getTemplate, {
      onCompleted: (data) => {
        console.log(
          "%ctemplate data",
          "background-color: red; color: white",
          JSON.parse(data.meetingTemplateById.content)
        );

        // one the template is retrieves, applies to current agenda
        saveAgenda({
          // infoToChange: {
          // },
          agendaItems: JSON.parse(data.meetingTemplateById.content),
          loadingTemplate: true,
          parentEventID: parentEventID,
          content: content,
          meeting: meeting,
          setContent: setContent,
          setMeeting: setMeeting,
          userID: userID,
        });
        setModal({ visible: false, name: "" });
        setShowSnackbar({
          show: true,
          messageType: "success",
          message: "Template loaded successfully",
        });
      },
      fetchPolicy: "network-only",
    });

    const [saveMeetingTemplateMut] = useMutation(saveMeetingTemplate, {
      awaitRefetchQueries: true,
      onCompleted: () => {
        setModal({ visible: false, name: "" });
        setShowSnackbar({
          show: true,
          messageType: "success",
          message: "Template saved",
        });
      },
    });

    const [deleteTemplateMut] = useMutation(deleteTemplate, {
      awaitRefetchQueries: true,
      onCompleted: () => {
        setModal({ visible: false, name: "" });
        setShowSnackbar({
          show: true,
          messageType: "success",
          message: "Template deleted",
        });
      },
    });

    switch (modal.name) {
      case "confirmSaveTemplate": {
        // console.log("intenta abrir");

        const initialValues = {
          title: "",
          description: "",
        };

        const handleSubmit = async (values) => {
          saveMeetingTemplateMut({
            variables: {
              userID: userID,
              desc: sentenceCase(values.title),
              content: JSON.stringify(agendaItems),
              comment: values.description,
            },
          });
        };

        return (
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validationSchema={object().shape({
              title: string().required("Required").max(40, "Maximum 40 digits"),
              description: string().max(150, "Maximum 150 digits"),
            })}
          >
            {(fprops) => (
              <CustomDialog
                // iconPreset="error"
                center
                // maxWidth="sm"
                headerTitle="Save template"
                visibleStatus={modal}
                setVisibleStatus={setModal}
                buttonActionLabel2="Save template"
                buttonActionFunction2={fprops.handleSubmit}
                // buttonActionLoading1={isLoading}
              >
                <GridContainer
                  spacing={3}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="stretch"
                >
                  <GridItem xs={12}>
                    <CustomMainInput
                      // disabled={!isOrganizer || [4, 5, 6].includes(statusID)}
                      // autoFocus
                      error={fprops.touched.title && fprops.errors.title}
                      height="40px"
                      label="Title"
                      name="title"
                      onBlur={fprops.handleBlur("title")}
                      onChange={fprops.handleChange("title")}
                      // placeholder="Title"
                      value={fprops.values.title}
                      variant="input"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomMainInput
                      // disabled={!isOrganizer || [4, 5, 6].includes(statusID)}
                      error={
                        fprops.touched.description && fprops.errors.description
                      }
                      height="100px"
                      label="Description"
                      name="description"
                      onBlur={fprops.handleBlur("description")}
                      onChange={fprops.handleChange("description")}
                      // placeholder="First name"
                      value={fprops.values.description}
                      variant="multiline"
                    />
                  </GridItem>
                </GridContainer>
              </CustomDialog>
            )}
          </Formik>
        );
      }

      case "confirmDeleteTemplate":
        return (
          <CustomDialog
            center
            iconPreset="question"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Do you want to delete this template?"
            mainText={`The template ${modal.data.templateDesc} will be deleted`}
            buttonActionLabel1="Cancel"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
            }}
            // buttonActionDisabled1={isLoading}
            buttonActionLabel2="Delete"
            buttonActionColor2="danger"
            buttonActionFunction2={() => {
              deleteTemplateMut({
                variables: {
                  templateID: modal.data.templateID,
                },
              });
            }}
            // buttonActionLoading2={isLoading}
            // center
          />
        );

      case "confirmUseTemplate":
        return (
          <CustomDialog
            center
            iconPreset="question"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Do you want to use this template?"
            mainText="All current contents will be deleted and replaced by the template"
            buttonActionLabel1="Cancel"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
            }}
            // buttonActionDisabled1={isLoading}
            buttonActionLabel2="Delete contents and use template"
            // buttonActionColor2="danger"
            buttonActionFunction2={() => {
              // console.log("tempalteID", modal.data.templateID);

              getTemplateQry({
                variables: { templateID: modal.data.templateID },
              });
              // setEditTemplate({
              //   ...editTemplate,
              //   visible: false,
              //   name: "",
              //   id: null,
              // });
            }}
            // buttonActionLoading2={isLoading}
            // center
          />
        );

      default:
        return null;
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: { modalName: modal.name },
    });
  }
};
