import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { Formik } from "formik";
import { object, string, ref } from "yup";
import { useTranslation } from "react-i18next";

// material ui components
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Button from "../../components/CustomButtons/Button.js";
// icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// core components
import Dialog from "components/CustomDialog/CustomDialog";
// style
import { makeStyles } from "@material-ui/core/styles";
import {
  infoColor,
  dangerColor,
} from "../../assets/jss/material-dashboard-react";
// data
import config from "../../config";
import axios from "axios";
import { axiosOptions } from "functions/Common.js";
import ErrorHandler from "components/Error/Error";

const useStyles = makeStyles((theme) => ({
  helperError: {
    color: dangerColor[1],
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.warning.dark,
  },

  paper: {
    backgroundColor: "white",
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    paddingLeft: "20%",
    paddingRight: "20%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    height: "100%",
    backgroundColor: "white",
  },
  linksContainer: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  title: {
    color: infoColor[4],
    margin: "30px 0px",
    textAlign: "center",
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const { t } = useTranslation(["main"]);
  const [modal, setModal] = useState({ visible: false, name: "" });
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  console.log("location", location);
  const email = location?.state?.email || null;
  console.log("%cemail is", "background-color: red; color: white", email);

  // if a email is provided when navigating, it means the user received an
  // invitation and it's not listed in the db, therefore navigates to signup
  // const email = location?.state?.email || null;

  const handleSendEmailConfirmation = (email) => {
    console.log("INICIA SEND EMAIL CONFIRMATION");

    const urlToClick = `${config.url}/auth/receiveEmailConfirmation`;

    const emailUser = email;

    const options = axiosOptions({
      url: `${config.url}/auth/sendEmailConfirmation`,
      method: "post",
      data: {
        email: emailUser,
        urlToClick: urlToClick,
        origin: "WebApp",
      },
    });

    console.log(options);
    axios(options)
      .then(
        // Successful fetch
        (response) => {
          console.log("response in handleSendEmailConfirmation", response);
          if (response.data.code === 0) {
            setModal({ visible: true, name: "email_sent" });
          } else {
            alert(response.data.message);
            console.log(
              "PROBLEMA: en Email confirmation: " + response.data.message
            );
          }
        }
      )
      // Unsuccessful fetch
      .catch((err) => {
        setModal({ visible: true, name: "connection_error" });
      });
  };

  const handleSignUp = (values, actions) => {
    const email = values.email.toLowerCase();
    console.log("En SignUp, intenta guardar.  El email es: " + email);
    const options = axiosOptions({
      url: `${config.url}/auth/signUp`,
      method: "post",
      data: {
        email: email,
        pass: values.pass || null,
        firstName: values.firstName || null,
        lastName: values.lastName || null,
        origin: "WebApp",
        authProvider: 1,
        timezone: null, // needs to get timezone from browser and see if it's required
        // tokenProvider: values.tokenProvider || null,
      },
    });

    console.log(options);
    axios(options)
      .then(
        // Successful fetch
        (response) => {
          console.log(response.data);
          if (response.data.code === 0) {
            console.log("En SignUp, envia correo de confirmacion");
            // When not using OAuth, sends the email confirmation
            handleSendEmailConfirmation(email);
            actions.setSubmitting(false);
          } else {
            // User already in database
            // actions.resetForm();
            actions.setSubmitting(false);
            setModal({ visible: true, name: "email_in_db" });
          }
        }
      )
      // Unsuccessful fetch
      .catch((err) => {
        console.log("ERROR: En SignUp se presentó el siguiente error: " + err);
        actions.setSubmitting(false);
        setModal({ visible: true, name: "connection_error" });
      });
  };

  const ModalToShow = () => {
    switch (modal.name) {
      case "email_in_db": // email already in database
        return (
          <Dialog
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title={t("SignUp.EmailAlreadyExists")}
            buttonCloseLabel="OK"
          />
        );
      case "email_sent": // email sent
        return (
          <Dialog
            iconPreset="success"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title={t("SignUp.EmailSent")}
            buttonActionLabel1="OK"
            buttonActionFunction1={() => {
              navigate({ pathname: "/" });
            }}
          />
        );
      case "not_your_email": // email sent
        return (
          <Dialog
            center
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Are you sure you want to change the email?"
            mainText={`An invitation was sent to ${email}.  If you sign up using a different email, you will not be visible for the person who invited you and may not have the same privileges.`}
            buttonCloseLabel="Cancel"
            buttonActionLabel1="Use another email"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
              navigate("/signup", {
                replace: true, // removes the previous useLocation state that held the email info
              });
            }}
            buttonActionColor1="warning"
          />
        );
      case "connection_error": // connection problems
        return (
          <ErrorHandler error={{ message: "Failed to fetch-signed out" }} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* {redirect ? <Redirect from="/SignUp" to="/" exact /> : null} */}

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <h3 className={classes.title}>{t("SignUp.SignUp")}</h3>

        <Formik
          // passes the auth provider,in this case it's storing
          // email and pass instead of using OAuth
          initialValues={{
            firstName: "",
            lastName: "",
            email: email === null ? "" : email,
            pass: "",
            confirmPass: "",
            authProvider: "",
          }}
          onSubmit={(values, actions) => handleSignUp(values, actions)}
          validationSchema={object().shape({
            firstName: string()
              .required(t("SignUp.FirstNameRequired"))
              .max(30, t("SignUp.Max30Char")),
            lastName: string()
              .required(t("SignUp.LastNameRequired"))
              .max(30, t("SignUp.Max30Char")),
            email: string()
              .required(t("SignUp.EmailRequired"))
              .email(t("SignUp.EmailValid"))
              .max(80, t("SignUp.Max80Char")),
            pass: string()
              .required(t("SignUp.PassRequired"))
              .min(8, t("SignUp.PassMinSize"))
              .max(30, t("SignUp.Max30Char")),
            confirmPass: string()
              .oneOf([ref("pass")], t("SignUp.PassNotMatch"))
              .required(t("SignUp.EmailRequired")),
          })}
        >
          {(fprops) => (
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={t("SignUp.FirstName")}
                    name="firstName"
                    size="small"
                    value={fprops.values.firstName}
                    onChange={fprops.handleChange("firstName")}
                    autoComplete="firstName"
                    helperText={
                      fprops.touched.firstName && fprops.errors.firstName
                    }
                    onBlur={fprops.handleBlur("firstName")}
                    FormHelperTextProps={{ className: classes.helperError }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={t("SignUp.LastName")}
                    name="lastName"
                    size="small"
                    value={fprops.values.lastName}
                    onChange={fprops.handleChange("lastName")}
                    autoComplete="lastName"
                    helperText={
                      fprops.touched.lastName && fprops.errors.lastName
                    }
                    onBlur={fprops.handleBlur("lastName")}
                    FormHelperTextProps={{ className: classes.helperError }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={email !== null}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={t("SignUp.Email")}
                    name="email"
                    size="small"
                    value={fprops.values.email}
                    onChange={fprops.handleChange("email")}
                    autoComplete="email"
                    helperText={fprops.touched.email && fprops.errors.email}
                    onBlur={fprops.handleBlur("email")}
                    FormHelperTextProps={{ className: classes.helperError }}
                  />
                  {email !== null && (
                    <Grid item>
                      <Button
                        link
                        color="primary"
                        onClick={(item) => {
                          setModal({ visible: true, name: "not_your_email" });
                        }}
                      >
                        <h6 style={{ margin: "5px 0px 5px 15px" }}>
                          Not your email
                        </h6>
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label={t("SignUp.Password")}
                    type="password"
                    id="password"
                    value={fprops.values.pass}
                    onChange={fprops.handleChange("pass")}
                    autoComplete="current-password"
                    helperText={fprops.touched.pass && fprops.errors.pass}
                    onBlur={fprops.handleBlur("pass")}
                    FormHelperTextProps={{ className: classes.helperError }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label={t("SignUp.ConfirmPassword")}
                    type="password"
                    id="confirmpassword"
                    value={fprops.values.confirmPass}
                    onChange={fprops.handleChange("confirmPass")}
                    autoComplete="current-password"
                    helperText={
                      fprops.touched.confirmPass && fprops.errors.confirmPass
                    }
                    onBlur={fprops.handleBlur("confirmPass")}
                    FormHelperTextProps={{ className: classes.helperError }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    onClick={fprops.handleSubmit}
                    isLoading={fprops.isSubmitting}
                  >
                    {fprops.isSubmitting
                      ? t("Common.Loading")
                      : t("SignUp.SignUpButton")}
                  </Button>
                </Grid>

                <Grid
                  container
                  alignItems="center"
                  className={classes.linksContainer}
                  spacing={0}
                >
                  {email === null && (
                    <Grid item xs>
                      <Link href="/" variant="body2">
                        <h5>
                          {t("SignUp.HasAccount")} {t("SignUp.SignIn")}
                        </h5>
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        <ModalToShow />
      </div>
    </>
  );
}
