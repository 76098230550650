import { useContext, useState } from "react";

// @material-ui/core components

// custom components
import Timeline from "components/Timeline";

// data
import { useQuery } from "@apollo/client";
import { ActionsContext } from "contexts/ActionsContext";
import differenceInSeconds from "date-fns/differenceInSeconds/index";
import { formatDateTimeMedium3, numberToTime } from "functions/Common";
import { actionsDetails } from "graphql/gqlActions";
import { demoData } from "utils/demoData";
import { tourData } from "utils/tourData";
import { SessionContext } from "../../contexts/SessionContext";

// errors

// icons

// style
import { makeStyles } from "@material-ui/core/styles";
import {
  grayColor,
  infoColor,
  primaryColor,
} from "assets/jss/material-dashboard-react";
import "./Actions.css";

const styles = {
  actionDetailsHeaderContainer: {
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: infoColor[8],
    padding: "5px",
  },
  actionDetailsHeaderLabel: {
    // color: "white",
    color: infoColor[4],
    margin: "0px",
  },
  actionDetailsOwnerLabel: {
    color: grayColor[5],
    margin: "0px 5px 0px 0px",
    textAlign: "right",
    fontSize: "11px",
  },

  actionDetailsContentContainer: {
    padding: "5px",
  },

  actionDetailsMainContainer: {
    // marginRight: "5px",
    // backgroundColor: "yellow",
    // border: "1px solid red",
  },
  actionDetailsTimelineLabel: {
    padding: "5px",
    textAlign: "center",
    backgroundColor: primaryColor[8],
    color: primaryColor[4],
    margin: "5px 12px",
    borderRadius: "5px",
    position: "absolute",
    left: "0px",
    right: "0px",
  },
  actionDetailScrollContainer: {
    overflowY: "auto",
    height: "92%",
  },
};

const useStyles = makeStyles(styles);

export const ActionDetails = (props) => {
  const classes = useStyles();

  const [totalActionDuration, setTotalActionDuration] = useState(null);

  return (
    <div
      id="tourActionsDetails"
      style={{
        width: "100%",
        backgroundColor: primaryColor[9],
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          height: "40px",
        }}
      >
        <h5 className={classes.actionDetailsTimelineLabel}>Timeline</h5>
        <h6
          style={{
            color: primaryColor[4],
            fontSize: "11px",
            position: "absolute",
            right: "18px",
          }}
        >
          {totalActionDuration}
        </h6>
      </div>

      <DetailsList
        sendInfoToParent={(duration) =>
          setTotalActionDuration(
            numberToTime(duration, 2, false, null, {
              hours: false,
              minutes: false,
            })
          )
        }
      />
    </div>
  );
  // }
};

const DetailsList = (props) => {
  const classes = useStyles();
  const { sendInfoToParent } = props;
  const { session, setSession } = useContext(SessionContext);
  const { userID, languageID, firstUse } = session;

  const { actions, setActions } = useContext(ActionsContext);
  const { pageNumber, parameterList, searchTerm } = actions;
  const { selectedMeetingActionID, filters, sorts } = parameterList;

  const { loading, data, error, refetch } = useQuery(actionsDetails, {
    variables: { meetingActionId: selectedMeetingActionID, languageID },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip: firstUse || selectedMeetingActionID === null,
  });

  console.log("%cdata", "background-color: gray; color: white", data);
  let initialDate = new Date();
  let itemDate = null;
  let previousDate = null;
  let duration = null;
  let finalDate = null;

  let details;
  if (userID === 111) {
    details = demoData("actionDetails");
  } else if (firstUse) {
    details = tourData("actionDetails");
  } else {
    details = data !== undefined ? data.spActionsDetails.nodes : [];
  }
  console.log("details", details);

  const finalList = details.map((item, index, array) => {
    itemDate = new Date(item.date);
    const detailType = item.typeId;

    if (index === 0) {
      if (detailType === 4) {
        // if it's the first item is completed, then the set this as the previous date.  This will avoid showing a duration between this date and the current date
        previousDate = itemDate;
        finalDate = new Date(itemDate);
      } else {
        // if it's the first item, initial date is the current date
        previousDate = new Date();
        finalDate = new Date();
      }
    } else {
      // if it's NOT the first item, initial date is previous actions' date
      previousDate = new Date(array[index - 1].date);
    }

    duration = differenceInSeconds(previousDate, itemDate);

    // if the first record, calculates the duration between the initial date
    // and the final date or the current date if the action hasn't completed

    // if (index === 0) {
    //   if (detailType === 4) {
    //     finalDate = new Date(itemDate);
    //     console.log("finalDate with itemDate", finalDate);
    //   } else {
    //     finalDate = new Date();
    //     console.log("finalDate with current date", finalDate);
    //   }
    // }

    if (detailType === 1) {
      initialDate = new Date(itemDate);
    }

    const actionCompleted = index === 0 && detailType === 5;

    return (
      <Timeline
        key={index}
        completed={actionCompleted}
        duration={
          // removes last duration when the last movement is the action marked as completed
          actionCompleted
            ? null
            : numberToTime(duration, 2, false, null, {
                hours: false,
                minutes: false,
              })
        }
      >
        <DetailsTemplate {...item} />
      </Timeline>
    );
  });

  // calculates total duration of action
  console.log("dates are", {
    date1: initialDate,
    date2: finalDate,
  });
  const totalActionDuration = differenceInSeconds(finalDate, initialDate);
  sendInfoToParent(totalActionDuration);

  return <div className={classes.actionDetailScrollContainer}>{finalList}</div>;
};

const DetailsTemplate = (props) => {
  const classes = useStyles();
  // console.log("props", props);
  const { date, owner, description } = props;

  return (
    <div className={classes.actionDetailsMainContainer}>
      <div className={classes.actionDetailsHeaderContainer}>
        <h6 className={classes.actionDetailsHeaderLabel}>
          {formatDateTimeMedium3(date)}
        </h6>
      </div>

      <div className={classes.actionDetailsContentContainer}>
        <h5>{description}</h5>

        <h6 className={classes.actionDetailsOwnerLabel}>{owner}</h6>
      </div>
    </div>
  );
};
