import React from "react";

// custom components
import HelpDeskHeader from "./HelpDeskHeader";
import HelpDeskList from "./HelpDeskList";

// errors
import {
  // ErrorBoundary,
  fallbackRender,
  errorComponent,
  logError,
  LogErrorComponent,
  useDataForLog,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

const HelpDeskOpenClosed = (props) => {
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { data, selector } = props;

    let graphQLName = "";
    let title = "";
    if (selector === "closed") {
      title = "Closed tickets";
      graphQLName = "spHelpdeskListClosed";
    } else {
      title = "Open tickets";
      graphQLName = "spHelpdeskListOpen";
    }

    // const filteredTickets = latestData[graphQLName].nodes.filter((item) =>
    //   (item.title || "")
    //     ?.toLowerCase()
    //     .includes((searchTerm || "").toLowerCase())
    // );

    return (
      <>
        <h3>{title}</h3>
        <HelpDeskHeader />
        <HelpDeskList data={data} selector={selector} />
      </>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

export default HelpDeskOpenClosed;
