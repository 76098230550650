import { useContext, useState } from "react";

// @material-ui/core
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// core components
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomTooltip from "components/CustomTooltip/CustomTooltip.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// data
import { Chart } from "components/Chart"; // data
import {
  dashboardCharts,
  dashboardIndicators,
} from "graphql/gqlDashboardPersonal";
import { demoData } from "utils/demoData";
import { SessionContext } from "../../contexts/SessionContext";
// icons
// errors
import {
  ErrorHandler,
  LogErrorComponent,
  useDataForLog,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// style
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "assets/jss/material-dashboard-react.js";

const styles = {
  activityHeaderContainer: {
    minHeight: 120,
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  cardIndicator: {
    color: grayColor[1],
    display: "flex",

    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Quicksand', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    overflowWrap: "normal",
    textDecoration: "none",
    "& small": {
      color: grayColor[2],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardIndicatorMin: {
    paddingBottom: "5px",
    paddingLeft: "5px",
  },
  cardIndicatorContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    marginTop: "15px",
  },
  cardTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    // backgroundColor: "yellow",
    paddingBottom: "20px",
  },
  cardTitle: {
    color: grayColor[3],
    fontWeight: "300",
    margin: "0",

    marginTop: "0",
    paddingTop: "10px",
    // paddingBottom: "10px",
  },
  groupContainer: {
    borderRadius: "5px",
    borderColor: grayColor[5],
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "30px",
    marginBottom: "20px",
  },
  groupLabel: { margin: "0px", paddingBottom: "10px" },
  // mainContainer: {
  //   padding: "20px 10px 0px 10px",

  //   // backgroundColor: "yellow",
  //   overflowY: "auto",
  //   height: "calc(100vh - 180px)",
  // },

  stats: {
    color: grayColor[3],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
};

const useStyles = makeStyles(styles);

export default function DashboardPersonal(props) {
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  const { dateRange } = props;
  // console.log("inicia dashboard personal", dateRange);
  const classes = useStyles();
  // const companyID = parseInt(sessionStorage.getItem("companyID"));
  const { session, setSession } = useContext(SessionContext);
  const { firstUse, userID } = session;

  const { loading, data, error, refetch } = useQuery(dashboardIndicators, {
    variables: { userID: userID },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    // skip: firstUse,
  });
  // console.log("variables for charts", {
  //   userID: userID,
  //   startDate: dateRange.startDate,
  //   endDate: dateRange.endDate,
  // });

  const {
    loading: loadingDC,
    data: dataDC,
    error: errorDC,
    refetch: refetchDC,
  } = useQuery(dashboardCharts, {
    variables: {
      userID: userID,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    // skip: firstUse,
  });

  const Indicators = () => {
    if (loading) return <ActivityIndicator fullscreen />;

    if (error) {
      return (
        <ErrorHandler
          error={error}
          retryFunction={() => refetch()}
          data={{
            errorLevel: "fatal",
            generalData: { ...generalData },
            otherData: { message: error.message },
          }}
        />
      );
    }

    if (data && data.spDashboardIndicators) {
      const componentNames = {
        1: <AttachMoneyIcon />,
        2: <RecordVoiceOverIcon />,
        3: <RecordVoiceOverIcon />,
        4: <AccessTimeIcon />,
      };

      return (
        <GridContainer alignItems="baseline" spacing={5}>
          {data.spDashboardIndicators.nodes.map((item) => {
            return (
              <GridItem key={item.id} xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader stats icon>
                    <CardIcon color={item.color}>
                      {componentNames[item.id]}
                    </CardIcon>
                    <div className={classes.cardTextContainer}>
                      <CustomTooltip
                        tooltipText={item.tooltipText}
                        placement="bottom"
                      >
                        <p className={classes.cardTitle}>
                          {item.desc}
                          <span
                            style={{
                              color: grayColor[4],
                              fontSize: "8px",
                              fontWeight: 600,
                              border: "1px solid",
                              margin: "0px 0px 1px 4px",
                              padding: "0px 4px",

                              // color: "red",
                              // width: "5px",
                              // height: "5px",
                              borderRadius: "50px",
                            }}
                          >
                            ?
                          </span>
                        </p>
                      </CustomTooltip>

                      <div className={classes.cardIndicatorContainer}>
                        <h1 className={classes.cardIndicator}>{item.value}</h1>

                        {item.id === 4 && (
                          <h2
                            className={[
                              classes.cardIndicator,
                              classes.cardIndicatorMin,
                            ].join(" ")}
                          >
                            h
                          </h2>
                        )}
                      </div>
                    </div>
                  </CardHeader>
                  {/* <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Hoy
                    </div>
                  </CardFooter> */}
                </Card>
              </GridItem>
            );
          })}
        </GridContainer>
      );
    }
  };

  const Charts = () => {
    if (loadingDC) return <ActivityIndicator fullscreen />;

    if (errorDC) {
      return (
        <ErrorHandler
          error={errorDC}
          // errorClear={() => setErrorState()}
          retryFunction={() => refetchDC()}
          data={{
            errorLevel: "fatal",
            generalData: { ...generalData },
            otherData: { message: errorDC.message },
          }}
        />
      );
    }

    if (dataDC && dataDC.c1) {
      console.log("data", demoData("dashboard"));
      const dataToUse = userID === 111 ? demoData("dashboard") : dataDC;
      const chartsCostValue = {
        c1: {
          title: "Average meeting cost",
          // data:{dataDC.avgMeetingCost.nodes},
          dataKey1: "value1",
          dataKey2: "value2",
          dataName1: "My avg",
          dataName2: "Company avg",
          xAxisLabels: "dates",
          formatMode: "currency",
          yAxisLegend: "cost",
          titleTooltip: "",
        },

        c2: {
          title: "Average daily time in meetings",
          // data:{dataDC.avgDailyTime.nodes}
          dataKey1: "value1",
          dataKey2: "value2",
          dataName1: "My time avg",
          dataName2: "Company time avg",
          xAxisLabels: "dates",
          formatMode: "hours",
          yAxisLegend: "time",
          titleTooltip: "",
          yAxisMaxValue: 5,
        },
        c3: {
          title: "Average attendees per meeting",
          // data:{dataDC.avgAttendees.nodes}
          dataKey1: "value1",
          dataKey2: "value2",
          dataName1: "My meetings",
          dataName2: "Company meetings",
          xAxisLabels: "dates",
          formatMode: "",
          yAxisLegend: "# attendees",
          titleTooltip: "",
        },
        c4: {
          title: "Average feedback point cost",
          // data:{dataDC.avgRatingCost.nodes}
          dataKey1: "value1",
          dataKey2: "value2",
          dataName1: "My avg",
          dataName2: "Company avg",
          xAxisLabels: "dates",
          formatMode: "currency",
          yAxisLegend: "cost",
          titleTooltip:
            "Meeting cost divided by meeting rating to organizer.  Cost of every meeting rating point",
        },

        c5: {
          title: "Rating received as organizer",
          // data:{dataDC.avgRatingAsOrganizer.nodes},
          dataKey1: "value1",
          dataKey2: "value2",
          dataName1: "My avg",
          dataName2: "Company avg",
          xAxisLabels: "dates",
          formatMode: "",
          yAxisLegend: "rating",
          yAxisMaxValue: 5,
          titleTooltip:
            "Rating received from your attendees when organizing a meeting",
        },

        c6: {
          title: "Rating received as attendee",
          // data:{dataDC.avgRatingAsAttendee.nodes},
          dataKey1: "value1",
          dataKey2: "value2",
          dataName1: "My avg",
          dataName2: "Company avg",
          xAxisLabels: "dates",
          formatMode: "",
          yAxisLegend: "rating",
          yAxisMaxValue: 5,
          titleTooltip:
            "Rating received when attending meeting organized by someone else",
        },
        c7: {
          title: "% of your meetings with completed feedback provided",
          // data:{dataDC.uncompletedFeedbackAsOrganizer.nodes}
          dataKey1: "value1",
          dataKey2: "value2",
          dataName1: "given to your attendees",
          dataName2: "given to the organizer",
          xAxisLabels: "dates",
          formatMode: "percent",
          yAxisLegend: "percent",
          yAxisMaxValue: 1,
          titleTooltip:
            "Percentage of meeting you participated where you didn't give any rating acting as an attendee (did't rate the organizer) or acting as an organizer (didn't rate attendees)",
        },
      };

      const chartGroups = {
        1: {
          name: "COST & VALUE",
          charts: ["c1", "c2", "c3", "c4"],
        },
        2: {
          name: "FEEDBACK",
          charts: ["c5", "c6", "c7"],
        },
      };

      const ChartMain = (props) => {
        // console.log("los props dentro de chart son", props);
        // console.log("el chart es", props.chartProperties?.id);
        // props.chartProperties?.id === "c2" &&
        //   console.log("la data es", dataDC[props.chartProperties?.id]?.nodes);

        return (
          <Chart
            title={props.chartProperties.title}
            data={dataToUse[props.chartProperties?.id]?.nodes}
            dataKey1={props.chartProperties.dataKey1}
            dataKey2={props.chartProperties.dataKey2}
            dataName1={props.chartProperties.dataName1}
            dataName2={props.chartProperties.dataName2}
            xAxisLabels={props.chartProperties.xAxisLabels}
            formatMode={props.chartProperties.formatMode}
            yAxisLegend={props.chartProperties.yAxisLegend}
            yAxisMaxValue={props.chartProperties.yAxisMaxValue}
          />
        );
      };

      // console.log("data", dataDC.uncompletedFeedbackAsOrganizer.nodes);
      return (
        <>
          {Object.keys(chartGroups).map((item) => {
            const chartGroupProperties = chartGroups[item];
            return (
              <div key={item} className={classes.groupContainer}>
                <h2 className={classes.groupLabel}>
                  {chartGroupProperties.name}
                </h2>
                <GridContainer alignItems="baseline" spacing={5}>
                  {Object.keys(chartsCostValue).map((item1) => {
                    // map through the object properties
                    // console.log("el item es", item);
                    const chartProperties = {
                      ...chartsCostValue[item1],
                      id: item1,
                    };

                    // if the current chart is included in the list of this group, displays the chart
                    if (chartGroupProperties.charts.includes(item1)) {
                      return (
                        <GridItem key={item1} xs={12} sm={6}>
                          <ErrorBoundary
                            FallbackComponent={(error) => (
                              <LogErrorComponent
                                error={error}
                                size="large"
                                data={{
                                  errorLevel: "critical",
                                  generalData: { ...generalData },
                                  otherData: {},
                                }}
                              />
                            )}
                          >
                            <ChartMain chartProperties={chartProperties} />
                          </ErrorBoundary>
                        </GridItem>
                      );
                    }
                  })}
                </GridContainer>
              </div>
            );
          })}

          {/* <div className={classes.groupContainer}>
            <h2 className={classes.groupLabel}>FEEDBACK</h2>
            <GridContainer alignItems="baseline" spacing={5}>
              <GridItem xs={12} sm={6}>
                <Chart
                  title="Rating received as organizer"
                  data={dataDC.avgRatingAsOrganizer.nodes}
                  dataKey1="value1"
                  dataKey2="value2"
                  dataName1="My avg"
                  dataName2="Company avg"
                  xAxisLabels="dates"
                  yAxisLegend="rating"
                  yAxisMaxValue={5}
                  titleTooltip="Rating received from your attendees when organizing a meeting"
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Chart
                  title="Rating received as attendee"
                  data={dataDC.avgRatingAsAttendee.nodes}
                  dataKey1="value1"
                  dataKey2="value2"
                  dataName1="My avg"
                  dataName2="Company avg"
                  xAxisLabels="dates"
                  yAxisLegend="rating"
                  yAxisMaxValue={5}
                  titleTooltip="Rating received when attending meeting organized by someone else"
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Chart
                  title="% of your meetings with uncompleted feedback"
                  data={dataDC.uncompletedFeedbackAsOrganizer.nodes}
                  dataKey1="value1"
                  dataKey2="value2"
                  dataName1="given to your attendees"
                  dataName2="given to the organizer"
                  xAxisLabels="dates"
                  formatMode="percent"
                  yAxisLegend="percent"
                  yAxisMaxValue={1}
                  titleTooltip="Percentage of meeting you participated where you didn't give any rating acting as an attendee (did't rate the organizer) or acting as an organizer (didn't rate attendees)"
                />
              </GridItem>
              
            </GridContainer>
          </div> */}
        </>
      );
    }
    // return <h2>No cargo</h2>;
  };

  return (
    <>
      {/* {indicators()} */}
      {/* <Indicators /> */}
      {/* {charts()} */}
      <Charts />
    </>
  );
}
