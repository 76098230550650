import React, { useState } from "react";
import { Formik } from "formik";
import { object, ref, string } from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// material ui components
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
// icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// core components
import Dialog from "components/CustomDialog/CustomDialog";
import Button from "../../components/CustomButtons/Button.js";
// data
import ErrorHandler from "components/Error/Error";
import axios from "axios";
import { axiosOptions } from "functions/Common.js";
import config from "../../config";
import queryString from "query-string";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { infoColor } from "../../assets/jss/material-dashboard-react";

const useStyles = makeStyles((theme) => ({
  helperError: {
    color: "#ff0000",
  },

  avatar: {
    // margin: theme.spacing(1),
    backgroundColor: theme.palette.warning.dark,
  },
  title: {
    color: infoColor[4],
    margin: "10px 0px",
    textAlign: "center",
  },

  paper: {
    backgroundColor: "white",
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",

    // marginTop: "15%",
    // marginBottom: "15%",
    paddingLeft: "20%",
    paddingRight: "20%",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    // height: "100%",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
  },

  container: {
    height: "100%",
    backgroundColor: "white",
  },
}));
export default function PasswordResetSignedOut(props) {
  const classes = useStyles();
  const { t } = useTranslation(["main"]);
  const [modal, setModal] = useState({ visible: false, name: "" });

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangePass = (values, actions) => {
    console.log("inicia el handle change pass");
    const token = queryString.parse(location.search).token;
    console.log("el token es");
    console.log(token);

    console.log(`${config.url}/auth/passChangeSignedOut`);
    console.log(values.newPass);
    try {
      console.log("Inicia el click de password reset");

      const options = axiosOptions({
        url: `${config.url}/auth/passChangeSignedOut`,
        method: "post",
        data: {
          newPass: values.newPass,
        },
      });

      console.log("info a pasar a en handleChange");
      console.log(options);
      axios(options)
        .then(
          // Successful fetch

          (res) => {
            console.log("respuesta de passChangeSignedOut");
            console.log(res);
            switch (res.data.code) {
              case 0: // token not valid
                console.log("En Pass Reset Signed Out el token no es válido");
                actions.setSubmitting(false);
                setModal({ visible: true, name: "invalid_token" });
                break;
              case 1: // Password updated successfully
                console.log(
                  "En Pass Reset Signed Out se actualizó la contraseña"
                );
                actions.setSubmitting(false);
                setModal({ visible: true, name: "pass_changed" });
                break;
              default:
            }
          }
        )
        // Unsuccessful fetch
        .catch((err) => {
          console.log(
            "ERROR: En Pass Reset Signed Out no se actualizar la contraseña: " +
              err
          );
          // if (err.response.status === 401) {
          //   console.log("En Pass Reset acceso no autorizado.");
          //   actions.setSubmitting(false);
          //   setModal({ visible: true, name: "pass_changed" });
          // } else {
          //   actions.setSubmitting(false);
          //   setModal({ visible: true, name: "connection_error" });
          // }
        });
    } catch (error) {
      console.log(error);
      actions.setSubmitting(false);

      setModal({ visible: true, name: "connection_error" });
    }
  };

  const ModalToShow = () => {
    switch (modal.name) {
      case "pass_changed": // Password changed successfully
        return (
          <Dialog
            iconPreset="success"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title={t("PassResetSignedOut.PasswordChanged")}
            buttonActionLabel1="Go to sign in"
            buttonActionFunction1={() => {
              navigate("/");
              setModal({ visible: false, name: "" });
            }}
          />
        );
      case "invalid_token": // token not valid
        return (
          <Dialog
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title={t("PassResetSignedOut.LinkInvalid")}
            buttonCloseLabel="OK"
          />
        );

      case "connection_error": // connection problem
        return (
          <ErrorHandler error={{ message: "Failed to fetch-signed out" }} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* {redirect ? <Redirect from="/" to="/home" exact /> : null} */}

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <h3 className={classes.title}>{t("PassResetSignedOut.Title")}</h3>

        <Formik
          initialValues={{
            newPass: "",
            confirmPass: "",
          }}
          validationSchema={object().shape({
            newPass: string()
              .required(t("PassResetSignedOut.PassRequired"))
              .min(8, t("PassResetSignedOut.PassMinSize"))
              .max(30, "Máximo 30 digitos"),
            confirmPass: string()
              .oneOf([ref("newPass")], t("PassResetSignedOut.PassNotMatch"))
              .required(t("PassResetSignedOut.PassRequired")),
          })}
          onSubmit={(values, actions) => handleChangePass(values, actions)}
        >
          {(fprops) => (
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="newPass"
                    label={t("PassResetSignedOut.NewPassword")} // new password
                    type="password"
                    id="newPass"
                    value={fprops.values.newPass}
                    onChange={fprops.handleChange("newPass")}
                    autoComplete="current-password"
                    onBlur={fprops.handleBlur("newPass")}
                    size="small"
                    helperText={fprops.touched.newPass && fprops.errors.newPass}
                    FormHelperTextProps={{ className: classes.helperError }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmPass"
                    label={t("PassResetSignedOut.ConfirmNewPassword")} // new password
                    type="password"
                    id="confirmPass"
                    value={fprops.values.confirmPass}
                    onChange={fprops.handleChange("confirmPass")}
                    autoComplete="current-password"
                    onBlur={fprops.handleBlur("confirmPass")}
                    size="small"
                    helperText={
                      fprops.touched.confirmPass && fprops.errors.confirmPass
                    }
                    FormHelperTextProps={{ className: classes.helperError }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    onClick={fprops.handleSubmit}
                    isLoading={fprops.isSubmitting}
                  >
                    {fprops.isSubmitting
                      ? t("Common.Loading")
                      : t("PassResetSignedOut.Button")}
                  </Button>
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
            </form>
          )}
        </Formik>
        <ModalToShow />
      </div>
    </>
  );
}
