import React, { useEffect, useState, useContext } from "react";

// data
import { useQuery, useMutation } from "@apollo/client";
import { meetingParkingLot } from "graphql/gqlMeetingParkingLot";
import { updateMeeting } from "graphql/gqlMeeting";
import { debounce } from "functions/Common";
import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import { tourData } from "utils/tourData";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";

import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
// icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// style
import { grayColor } from "assets/jss/material-dashboard-react.js";

const styles = {
  cardBody: {
    paddingTop: "20px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
  helpLabel: {
    color: grayColor[4],
    paddingBottom: "30px",
  },
  mainContainer: {
    paddingLeft: "20px",
  },

  topicNumber: {
    color: grayColor[4],
    display: "flex",
    marginBottom: "7px",
    // width: "30px",
    minWidth: "30px",
  },
  titleContainer: {
    display: "flex",
    width: "30px",
  },
  titleLabel: {
    color: grayColor[4],
    paddingLeft: "60px",
    // paddingRight: "10px",
  },
};

const useStyles = makeStyles(styles);

export default function MeetingParkingLot(props) {
  // console.log("los props en MeetingParkingLot son", props);
  const classes = useStyles();
  const { meetingID } = props;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const {
    session: { firstUse },
  } = useContext(SessionContext);
  const { isOrganizer, statusID } = meeting;

  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  const spacing = 1;

  const ParkingLotMain = () => {
    const [topicsState, setTopicsState] = useState([]);
    const blankTopic = {
      topic: "",
      meetingTopicId: 1,
      meetingId: meetingID,
    };

    const { loading, data, error, refetch } = useQuery(meetingParkingLot, {
      variables: { meetingID: meetingID },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      skip: firstUse,
    });

    const [updateMeetingMut] = useMutation(updateMeeting, {
      refetchQueries: [
        {
          query: meetingParkingLot,
          variables: { meetingID: meetingID },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        console.log("db update");
      },
    });

    useEffect(() => {
      try {
        if (firstUse) {
          const tourDummyData = tourData("parkingLot");
          setTopicsState(tourDummyData);
          return;
        }

        // loads info if there's data
        if (data !== undefined) {
          // loads blank line if there are no topics yet
          if (
            data.meetingById.meetingParkingLotsByMeetingId.nodes.length === 0
          ) {
            setTopicsState([blankTopic]);
          } else {
            setTopicsState([
              ...data.meetingById.meetingParkingLotsByMeetingId.nodes,
            ]);
          }
        }
      } catch (error) {
        logError(error, { generalData: { ...generalData }, otherData: data });
      }
    }, [data]);

    const updateField = (event, index, fieldName) => {
      try {
        // modifies the topic selected and removes the typename
        const newTopics = topicsState.map((item2, index2) => {
          if (index === index2) {
            return {
              ...item2,
              [fieldName]: event.target.value,
              __typename: undefined,
            };
          } else {
            return {
              ...item2,
              __typename: undefined,
            };
          }
        });

        setTopicsState(newTopics);
        console.log("parking lot topics", {
          meetingID: meetingID,

          topics: newTopics,
        });
        debounce(() =>
          updateMeetingMut({
            variables: {
              meetingID: meetingID,
              // sectionToUpdate: 5, // update only the purpose section
              topics: newTopics,
              objectives: null, // for some reason if not provided creates an error
              actions: null, // for some reason if not provided creates an error
            },
          })
        );
      } catch (error) {
        setErrorState({
          error: error,
          errorExplanation: "This parking lot topic could not be updated",
          data: {
            errorLevel: "critical",
            otherData: { event, index, fieldName },
          },
        });
      }
    };

    const addTopic = () => {
      try {
        const newArray = topicsState.map((item) => ({
          ...item,
          __typename: undefined,
        }));
        // get the max value of meetingTopicId
        const maxValue = Math.max(
          ...newArray.map((item) => item.meetingTopicId)
        );

        newArray.push({
          ...blankTopic,
          meetingTopicId: maxValue + 1,
        });

        setTopicsState(newArray);
      } catch (error) {
        setErrorState({
          error: error,
          errorExplanation: "This parking lot topic could not be added",
          data: { errorLevel: "critical", otherData: { topicsState } },
        });
      }
    };

    const deleteTopic = (index) => {
      try {
        //  removes the item
        const newArray1 = topicsState.filter(
          (item, index2) => index2 !== index
        );
        // console.log("el array con la info borrada", newArray1);
        // prepares the array for update (removes typename)
        const newArray2 = newArray1.map((item) => ({
          ...item,
          __typename: undefined,
        }));

        setTopicsState(newArray2);

        updateMeetingMut({
          variables: {
            meetingID: meetingID,
            // sectionToUpdate: 5, // update only the parking lot section
            topics: newArray2,
            objectives: null, // for some reason if not provided creates an error
            actions: null, // for some reason if not provided creates an error
          },
        });
      } catch (error) {
        setErrorState({
          error: error,
          errorExplanation: "This parking lot topic could not be deleted",
          data: {
            errorLevel: "critical",
            otherData: { index, topicsState },
          },
        });
      }
    };

    return (
      <div className={classes.mainContainer}>
        <GridContainer spacing={0} direction="row">
          <GridItem xs={5}>
            <h6 className={classes.titleLabel}>Topic for another meeting</h6>
          </GridItem>
        </GridContainer>

        {/* {data.meetingById.meetingTopicsByMeetingId.nodes.map( */}

        {topicsState.map((item, index) => {
          return (
            <GridContainer
              key={index}
              spacing={spacing}
              direction="row"
              alignItems="end"
            >
              <GridItem xs="auto">
                <div className={classes.topicNumber}>
                  <h5># {index + 1}</h5>
                </div>
              </GridItem>
              <GridItem xs={8} style={{ maxWidth: "600px" }}>
                <CustomMainInput
                  disabled={!isOrganizer || [5].includes(statusID)}
                  // label="Topic"
                  height="40px"
                  onChange={(event) => {
                    updateField(event, index, "topic");
                  }}
                  value={item.topic}
                />
              </GridItem>
              {isOrganizer && (
                <GridItem xs={1}>
                  {/* hide delete button if not organizer */}
                  <Button
                    disabled={[5].includes(statusID)}
                    color="danger"
                    justIcon
                    onClick={() => deleteTopic(index)}
                  >
                    <DeleteOutlineIcon />
                    {/* <Hidden mdUp implementation="css">
  <p className={classes.linkText}>Perfile</p>
</Hidden> */}
                  </Button>
                </GridItem>
              )}
            </GridContainer>
          );
        })}

        <GridContainer spacing={spacing} direction="row">
          <GridItem xs="auto">
            <div className={classes.titleContainer}></div>
          </GridItem>
          <GridItem xs={8}>
            <Button
              color="primary"
              disabled={
                // if there is least one empty line
                (topicsState.length > 0 &&
                  topicsState[topicsState.length - 1].topic === "") ||
                !isOrganizer ||
                [5].includes(statusID)
              }
              simple
              onClick={() => addTopic()}
            >
              + Add topic
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  return (
    <CardBody fullscreen className={classes.cardBody}>
      {/* <h6 className={classes.helpLabel}>
        Parking lot is where you place topics that are important to be discussed
        but not in this meeting
      </h6> */}
      <ErrorBoundary
        FallbackComponent={(error) => (
          <LogErrorComponent
            error={error}
            data={{
              errorLevel: "fatal",
              generalData: { ...generalData },
              otherData: { meeting, setTopicsState },
            }}
          />
        )}
      >
        <ParkingLotMain />
      </ErrorBoundary>
      {errorState && (
        <ErrorHandler
          error={errorState.error}
          data={{
            errorLevel: errorState.data.errorLevel,
            generalData: { ...generalData },
            otherData: { ...errorState.data.otherData },
          }}
          errorExplanation={errorState.errorExplanation} // only for dialog, not error
          errorClear={() => setErrorState()}
        />
      )}
    </CardBody>
  );
}
