/*eslint-disable*/
import React, { useEffect, useState, useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import {
  Drawer,
  Hidden,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

// core components
import NavbarRightElements from "components/Navbars/NavbarRightElements.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import { ReactComponent as OrquestiLogo } from "../../assets/img/OrquestiIsotipo.svg";

// icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SvgIcon from "@material-ui/core/SvgIcon";
// styles
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const { color, logo, image, logoText, routes } = props;
  // console.log("routes in sidebr", routes);

  const [open, setOpen] = useState(false);

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    // console("pureba");
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const sidebarWrapperClasses = classNames({
    [classes.sidebarWrapper]: true,
    [classes.drawerClosed]: !open,
  });

  const drawerClasses = classNames({
    [classes.drawerPaper]: true,
    [classes.drawerPaperRTL]: props.rtlActive,
    [classes.drawerClosed]: !open,
  });

  var Links = () => (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        // console.log(
        //   "%cEs dashboard",
        //   "background-color: red; color: white",
        //   prop.name === "Dashboard"
        // );

        // console.log(
        //   "%cProps drawer links",
        //   "background-color: red; color: white",
        //   { prop, key }
        // );

        var activePro = " ";
        var listItemClasses;
        // if (prop.path === "/upgrade-to-pro") {
        //   activePro = classes.activePro + " ";
        //   listItemClasses = classNames({
        //     [" " + classes[color]]: true,
        //   });
        // } else {

        // defines the button background
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path),
        });

        // }
        const whiteFontClasses = classNames({
          // [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
          [" " + classes.whiteFont]: true,
        });

        // const navLinkClasses = classNames({
        //   [classes.itemText]: true,
        //   [classes.itemLink]: true,
        //   [" " + classes.whiteFont]: true,
        //   [classes.itemTextRTL]: props.rtlActive,
        //   [classes[color]] :
        // });

        return (
          <NavLink
            id={`tour${prop.name.replace(/\s/g, "")}`}
            to={prop.layout + prop.path}
            // style={({ isActive }) => ({ color: isActive ? "red" : "white" })}

            // className={({ isActive }) => (isActive ? "active" : "")}
            className={({ isActive }) =>
              classNames(
                classes.itemText,
                classes.itemLink,
                whiteFontClasses,
                {
                  [classes.itemTextRTL]: props.rtlActive,
                },
                isActive ? classes[color] : classes["transparent"]
              )
            }
            // className={classNames(classes.itemLink)}
            key={key}
          >
            <ListItem button>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive,
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive,
                  })}
                />
              )}
              {open && (
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(
                    classes.itemText,
                    open ? whiteFontClasses : classes.transparentFont,
                    {
                      [classes.itemTextRTL]: props.rtlActive,
                    }
                  )}
                  disableTypography={true}
                />
              )}
            </ListItem>
          </NavLink>
          // <NavLink
          //   to={prop.layout + prop.path}
          //   style={({ isActive }) => ({ color: isActive ? "blue" : "black" })}
          //   // className={({ isActive }) => (isActive ? "active" : "")}
          //   key={key}
          // >
          //   <ListItem button className={classes.itemLink + listItemClasses}>
          //     {typeof prop.icon === "string" ? (
          //       <Icon
          //         className={classNames(classes.itemIcon, whiteFontClasses, {
          //           [classes.itemIconRTL]: props.rtlActive,
          //         })}
          //       >
          //         {prop.icon}
          //       </Icon>
          //     ) : (
          //       <prop.icon
          //         className={classNames(classes.itemIcon, whiteFontClasses, {
          //           [classes.itemIconRTL]: props.rtlActive,
          //         })}
          //       />
          //     )}
          //     <ListItemText
          //       primary={props.rtlActive ? prop.rtlName : prop.name}
          //       className={classNames(classes.itemText, whiteFontClasses, {
          //         [classes.itemTextRTL]: props.rtlActive,
          //       })}
          //       disableTypography={true}
          //     />
          //   </ListItem>
          // </NavLink>
        );
      })}
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <SvgIcon style={{ fontSize: 40 }}>
        <OrquestiLogo />
      </SvgIcon>
    </div>
  );

  return (
    <div
      style={{ position: "absolute", zIndex: 1000, backgroundColor: "yellow" }}
    >
      {/* <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={false}
          classes={{
            paper: drawerClasses,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}

          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <NavbarRightElements />}

            <div className={sidebarWrapperClasses}>
              <Links />
            </div>
          </div>

          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden> */}
      {/* <Hidden smDown implementation="css"> */}
      <Drawer
        onMouseOver={() => setOpen(true)}
        onMouseOut={() => setOpen(false)}
        anchor={props.rtlActive ? "right" : "left"}
        variant="permanent"
        open
        classes={{
          paper: drawerClasses,
        }}
      >
        {brand}

        <div className={sidebarWrapperClasses}>
          <Links />
        </div>

        {image !== undefined ? (
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + image + ")" }}
          />
        ) : null}
      </Drawer>
      {/* </Hidden> */}
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
