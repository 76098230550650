import {
  primaryColor,
  infoColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const paymentStyle = (theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: primaryColor[0],
  },

  buttons: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "flex-end",
  },

  cardStorageLabel: {
    fontSize: 12,
    color: grayColor[5],
  },
  divider: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  errorLabel: {
    fontSize: 12,
    color: "red",
    textAlign: "right",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  labelContainer: { flexDirection: "row" },
  logo: {
    // objectFit: "contain",
    // position: "relative",
    // display: "flex",
    // justifyContent: "center",
    // padding: "20px 50px",
    width: "130px",
    // zIndex: "4",
  },

  listItem: {
    padding: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "space-between",
  },
  // mainTitleContainer: {
  //   backgroundColor: primaryColor[5],
  //   paddingVertical: 50,
  //   borderRadius: 5,
  // },
  mainTitle: {
    textAlign: "center",
    color: "white",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: primaryColor[5],
    borderRadius: 5,
  },
  modalBody: { textAlign: "center" },
  total: {
    fontWeight: 900,
  },
  // title: {
  //   marginTop: theme.spacing(2),
  // },

  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 4),
  },

  subLabel: {
    // fontSize: 10,
    color: grayColor[5],
  },
});
export default paymentStyle;
