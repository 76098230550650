import { gql } from "@apollo/client";

export const infoAttendees = gql`
  query infoAttendees($meetingID: Int!) {
    spMeetingFeedbackAttendees(meetingId1: $meetingID) {
      nodes {
        id
        name
        rating
        imageUrl
        comment
      }
    }
  }
`;

export const infoOrganizerAvg = gql`
  query infoOrganizer($meetingID: Int!, $languageID: String) {
    spMeetingFeedbackToOrganizerAvg(
      languageId1: $languageID
      meetingId1: $meetingID
    ) {
      nodes {
        tooltip
        rating
        id
        desc
        dateAttendeeGiveRating
      }
    }
    spMeetingFeedbackComments(meetingId1: $meetingID) {
      nodes {
        comment
        desc
        id
        imagePath
      }
    }
  }
`;
