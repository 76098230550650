import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// @material-ui/core components
import Card from "components/Card/Card.js";

// custom components
import MeetingActions from "views/Meetings/MeetingActions";
import MeetingDetails from "views/Meetings/MeetingDetails";
import MeetingParkingLot from "views/Meetings/MeetingParkingLot";
import MeetingPurpose from "views/Meetings/MeetingPurpose";
import MeetingContents from "../MeetingContent/MeetingContents";
import MeetingFeedback from "../MeetingFeedback/MeetingFeedback";
import { MeetingHeader } from "./MeetingHeader";
import MemoMeetingContent from "./MeetingMain";
import MeetingModals from "./MeetingModals";

import Snackbar from "components/Snackbar/Snackbar";
// data
import MeetingActionsInfo from "contexts/MeetingActionsContext";
import { addMinutesToDate } from "functions/Common";
import { meetingGeneral } from "graphql/gqlMeeting";
// import { io } from "socket.io-client";
import { useQuery } from "@apollo/client";
import ContentInfo from "contexts/ContentContext";
import FeedbackInfo from "contexts/FeedbackContext";
import { MeetingContext } from "contexts/MeetingContext";
import { TourContext } from "contexts/TourContext";
import { tourData } from "utils/tourData";
import { SessionContext } from "../../../contexts/SessionContext";
import { SocketContext } from "../../../contexts/SocketContext";

// errors
import { ErrorHandler, logError, useDataForLog } from "functions/ErrorBoundary";

// hooks
import { useIsFirstRender } from "functions/useIsFirstRender";

// style

export default function Meeting(props) {
  console.log(
    '%c"*********************** starts meeting ******************************"',
    "background-color: red; color: white"
  );

  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  const isFirstRender = useIsFirstRender();
  // usePrompt2(true);

  try {
    // const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    // const meetingID = parseInt(searchParams.get("id")) || null;
    // console.log("location", location);

    const [meetingID, setMeetingID] = useState(
      location.state.meetingID || null
    );

    const parentEventID = location.state.parentEventID || null; //parseInt(searchParams.get("parent_event_id"));
    const startDateOccurrence = location.state.startDateOccurrence || null;
    const endDateOccurrence = location.state.endDateOccurrence || null;
    const tempMeetingID = location.state.tempMeetingID || null;

    const formDetailsValidRef = useRef(true);
    const attendeesRef = useRef([]);
    const childRef = useRef(null);
    const contentChangedRef = useRef(false);
    // used when accessing future occurrences of a repeat event. It stores the treatment be used (single event, all series or current and future) when it has already been selected once
    const repeatSaveTypeRef = useRef(null);

    // const activityRef = useRef();

    const { session, setSession } = useContext(SessionContext);
    const { socket } = useContext(SocketContext);
    const { meeting, setMeeting } = useContext(MeetingContext);
    const {
      meetingCost,
      meetingTypeID,
      privateAgenda,
      withObjectives,
      withAgenda,
      statusID: statusIDState,
      socketSignalData,
    } = meeting;
    const {
      accountID,
      createdBY: organizerID,
      firstUse,
      languageID,

      userCalendarID,
      userID,
      userName,
    } = session;

    const [meetingCostState, setMeetingCostState] = useState(
      firstUse === true ? 75 : 0
    );

    const navigate = useNavigate();

    const [attendees, setAttendees] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState({
      show: false,
      messageType: "",
      message: "",
    });

    const [refreshInfo, setRefreshInfo] = useState(null);
    const [triggerSubform, setTriggerSubform] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    //-----------------------------------------------------------
    const { tourState, setTourState } = useContext(TourContext);
    const { tourActive, stepID } = tourState;

    const [changes, setChanges] = useState({});
    // const [mustHave, setMustHave] = useState({
    //   started: false,
    //   ended: false,
    // });
    const [modal, setModal] = useState({
      visible: false,
      name: "",
      data: {},
    });
    const [submitFormFunc, setSubmitFormFunc] = useState({
      // function: () => {},

      error: {},
      buttonInvitePress: false,
      buttonSendChangesPress: false,
      buttonSavePress: false,
      newTabIndex: null, // starts with the initial tab index
    });
    const lastTabRef = useRef(null);
    const modalRepeatEventTypeRef = useRef();

    const [tabSelected, setTabSelected] = useState(0);
    // lastTabRef.current === null ? 0 : lastTabRef.current

    //---------------------------------------------------------------------------------
    const paramsMeetingGeneral = {
      meetingID: meetingID || parentEventID,
      parentEventID: meetingID !== null ? null : parentEventID,
      userID: userID,
      accountID: accountID,
    };
    // console.log(
    //   "%cparamsMeetingGeneral",
    //   "background-color: purple; color: white",
    //   paramsMeetingGeneral
    // );

    const { loading, data, error, refetch } = useQuery(meetingGeneral, {
      variables: paramsMeetingGeneral,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      skip: firstUse,
    });

    // const {
    //   loading: loadingMC,
    //   data: dataMC,
    //   error: errorMC,
    //   refetch: refetchMC,
    // } = useQuery(meetingCost, {
    //   variables: { meetingID: meetingID || parentEventID },
    //   notifyOnNetworkStatusChange: true,
    //   fetchPolicy: "network-only",
    // });

    useEffect(() => {
      try {
        if (firstUse) {
          const tourDummyData = tourData("meeting");

          setMeeting(tourDummyData);

          return; // don't run anything
        }

        // loads info if there's data

        if (data !== undefined) {
          console.log(
            "%cdata en useEffect en Meeting",
            "background-color: pink; color: red",
            { paramsMeetingGeneral, data }
          );

          const statusID = data.spMeetingStatus.nodes[0].statusId;
          const isOrganizer = data.spMeetingGeneral.nodes[0].isOrganizer;
          const checkedIn = data.spMeetingGeneral.nodes[0].checkedIn;
          const startDate = data.meetingById.startDate;
          const willAttend = data.spMeetingGeneral.nodes[0].willAttend;
          const canConnectToSocket =
            data.spMeetingGeneral.nodes[0].canConnectToSocket;

          // stores the data as long as information is returned and not restricted by user access
          if (data.spMeetingStatus.nodes.length > 0) {
            // -------------------------attendee rejoin room in case page is refreshed, if aplicable ----------------------------------
            // (if already checkedin, if meeting has not ended, if the meeting status is ongoing, if not organizer and if the socked is active)
            // if (
            //   // checkedIn === true &&
            //   [3, 7].includes(statusID) &&
            //   canConnectToSocket === true &&
            //   isOrganizer === false &&
            //   socket !== null
            // ) {
            //   socket.emit("join_meeting", { meetingID });
            // }

            // ----------------------organizer rejoin room in case page is refreshed, if aplicable ------------------------------
            // if (
            //   startDate !== null &&
            //   canConnectToSocket === true &&
            //   isOrganizer === true &&
            //   socket !== null
            // ) {
            //   socket.emit("join_meeting", { meetingID });
            // }

            // always connect to room if viewing meeting if meeting is confirmed, started, Overtime or late
            if (
              [2, 3, 7, 8].includes(statusID) &&
              canConnectToSocket === true &&
              socket !== null
            ) {
              socket.emit("join_meeting", { meetingID });
            }

            // displays check in code if status is either confirmed or started,
            // only for attendees, if checkin was not previously executed
            // or if up to 15 minutes before meeting starts
            // console.log("data.meetingById.startDate", data.meetingById.startDate);
            // console.log(
            //   "addMinutes",
            //   addMinutesToDate(new Date(data.meetingById.startDate), -15)
            // );
            console.log("status", data.spMeetingStatus.nodes[0].statusId);

            if (
              // [2, 3].includes(data.spMeetingStatus.nodes[0].statusId) &&
              ([3, 7, 8].includes(statusID) &&
                isOrganizer === false &&
                checkedIn === false) ||
              (![3, 7, 8].includes(statusID) &&
                isOrganizer === false &&
                checkedIn === false &&
                addMinutesToDate(new Date(startDate), -15) < new Date())
            ) {
              console.log("time", {
                startDate: addMinutesToDate(new Date(startDate), -15),
                currentTime: new Date(),
                evaluate:
                  addMinutesToDate(new Date(startDate), -15) < new Date(),
              });
              setModal({ visible: true, name: "check-in" });
            }

            // ask for meeting confirmation
            if (
              [1, 2, 8].includes(statusID) &&
              willAttend === null &&
              isOrganizer === false &&
              checkedIn === false
            ) {
              setModal({ visible: true, name: "confirm-attendance" });
            }

            if (
              data.meetingById.startDateFinal === null &&
              [2, 8].includes(statusID) &&
              new Date(startDate) < new Date() &&
              isOrganizer === true
            ) {
              setModal({ visible: true, name: "forget-start-meeting" });
            }

            // console.log("times", {
            //   endDateReminderPlusFive: addMinutesToDate(
            //     new Date(data.meetingById.endDateReminder),
            //     5
            //   ),
            //   endDateReminder: new Date(data.meetingById.endDateReminder),
            //   currentDate: new Date(),
            // });

            if (
              data.meetingById.endDateFinal === null &&
              [3, 7].includes(statusID) &&
              new Date(data.meetingById.endDate) < new Date() &&
              isOrganizer === true &&
              addMinutesToDate(new Date(data.meetingById.endDateReminder), 5) <
                new Date()
            ) {
              setModal({ visible: true, name: "forget-end-meeting" });
            }

            attendeesRef.current =
              data.spMeetingDetails.nodes[0]?.attendees || [];

            setMeeting({
              ...meeting,
              // variant: variant,
              // attendees: data.spMeetingDetails.nodes[0]?.attendees || [],
              checkInCode: data.meetingById.checkinCode,
              canViewRating: data.spMeetingFeedback.nodes[0].canViewRating,
              checkedIn: data.spMeetingGeneral.nodes[0].checkedIn,
              createdBy: data.meetingById.createdBy,
              finalRating: data.spMeetingGeneral.nodes[0].finalRating,
              endDate: endDateOccurrence || data.meetingById.endDate,
              endDateFinal: data.meetingById.endDateFinal,
              externalMeetingID: data.meetingById.externalMeetingId,
              isOrganizer: isOrganizer,
              location: data.meetingById.location,
              meetingCost:
                firstUse === true
                  ? 75
                  : data.spMeetingGeneral.nodes[0].meetingCost,

              meetingID: meetingID,
              meetingTypeID: data.meetingById.meetingTypeId,
              parentEventID: parentEventID,
              privateAgenda: data.meetingById.privateAgenda,
              ratingElements: data.spMeetingRating.nodes,
              startDate: startDateOccurrence || startDate, // if no occurrence start date, takes the start date of the event
              startDateFinal: data.meetingById.startDateFinal,
              statusID: statusID,
              title: data.meetingById.title,
              userCalendarID: data.meetingById.userCalendarId,
              willAttend: willAttend,
              withObjectives: data.spMeetingGeneral.nodes[0].withObjectives,
              withAgenda: data.spMeetingGeneral.nodes[0].withAgenda,
              withDurations: data.spMeetingGeneral.nodes[0].withDurations,
              recurrenceTypeID: data.spMeetingDetails.nodes[0].recurrenceTypeId,

              // medalsCompleted: data.spMeetingGeneral.nodes[0].medals,
              // changesFromPurpose: undefined,
              // changesFromContent: undefined,
              // changesFromDetails: undefined,
            });

            // cost is kept apart from the meeting context to allow changing the value without rendering
            setMeetingCostState(data.spMeetingGeneral.nodes[0].meetingCost),
              console.log("change isLoading to false in useEffect");
            setIsLoading(false);
          } else {
            setModal({ visible: true, name: "no-data-or-access" });
          }
        }

        return () => {
          // console.log("cleaned up");
          // removes any changes not executed
          const {
            changesFromPurpose,
            changesFromDetails,
            changesFromContent,
            ...rest
          } = meeting;
          // console.log("el rest es", { ...rest });
          setMeeting({ ...rest });
        };
      } catch (error) {
        setErrorState({
          error: error,
          errorExplanation: "Could not load the data",
          data: { errorLevel: "fatal", otherData: { data: data } },
        });
      }
    }, [data]);

    useEffect(() => {
      // update the tab according to the tour step change
      // console.log("tourState.stepID", tourState.stepID);
      let newTabIndex;
      switch (tourState.stepID) {
        case "meeting-tabs":
          newTabIndex = 0;
          break;
        case "meeting-details":
          newTabIndex = 0;
          break;
        case "meeting-purpose":
          newTabIndex = 1;
          break;
        case "meeting-content":
          newTabIndex = 2;
          break;
        case "meeting-actions":
          newTabIndex = 3;
          break;
        case "meeting-parking":
          newTabIndex = 4;
          break;
        case "meeting-feedback":
          // case "meeting-feedback-rate-attendees":
          // case "meeting-feedback-rate-organizer":
          newTabIndex = 5;
          break;
        default:
          newTabIndex = 0;
      }

      // console.log("%cData", "background-color: red; color: white", {
      //   newTabIndex,
      //   current: lastTabRef.current,
      // });

      // if changing tab
      if (newTabIndex !== lastTabRef.current) {
        lastTabRef.current = newTabIndex;
        console.log("change tab");
        setTabSelected(newTabIndex);
        // setSubmitFormFunc({ ...submitFormFunc, newTabIndex: newTabIndex });
      }
    }, [tourState.stepID]);

    useEffect(() => {
      try {
        // console.log("starts useEffect for buttonInvitePress");
        // use effect will detect if there's an error when form details is submitted or when the Invite button is pressed

        // submits the form
        // submitFormFunc.function();

        if (submitFormFunc.buttonInvitePress === true) {
          if (Object.keys(submitFormFunc.error).length === 0) {
            // console.log("No error invite", submitFormFunc);
            setModal({ visible: true, name: "invite-meeting" });
          } else {
            // console.log("There was an error.  No invite", submitFormFunc);
            setSubmitFormFunc({
              ...submitFormFunc,
              buttonInvitePress: false,
            });
            // submitFormFunc = { ...submitFormFunc, buttonInvitePress: false };
          }
        }

        if (submitFormFunc.buttonSendChangesPress === true) {
          if (Object.keys(submitFormFunc.error).length === 0) {
            setModal({ visible: true, name: "confirm-send-changes" });
          } else {
            setSubmitFormFunc({
              ...submitFormFunc,
              buttonSendChangesPress: false,
            });
            // submitFormFunc = { ...submitFormFunc, buttonSendChangesPress: false };
          }
        }
      } catch (error) {
        logError(error, {
          errorLevel: " critical",
          generalData: { ...generalData },
          otherData: { submitFormFunc },
        });
      }
    }, [submitFormFunc]);

    // useEffect(() => {
    //   // when changing tabs
    //   try {
    //     if (!isFirstRender) {
    //       // const runValidation = async () => {
    //       //   try {
    //       //     console.log("start");
    //       //     const response = await childRef.current?.triggerValidation();
    //       //     console.log("response", response);
    //       //     if (Object.keys(response).length === 0) {
    //       //       console.log("No error found");
    //       //       setTabSelected(submitFormFunc.newTabIndex);
    //       //     }
    //       //     console.log(
    //       //       "%cErrors were found",
    //       //       "background-color: red; color: white"
    //       //     );
    //       //     setSubmitFormFunc({
    //       //       ...submitFormFunc,
    //       //       newTabIndex: tabSelected,
    //       //     });
    //       //   } catch (error) {
    //       //     console.error("Error");
    //       //   } finally {
    //       //     // Update the loading state
    //       //   }
    //       // };

    //       // runValidation();

    //       //  if (Object.keys(submitFormFunc.error).length === 0) {
    //       //   // console.log("No error in tabs.  Change tab to ", submitFormFunc);

    //       //   setTabSelected(submitFormFunc.newTabIndex);
    //       // } else {
    //       //   // console.log("There was an error.  No change tab", submitFormFunc);
    //       //   setSubmitFormFunc({ ...submitFormFunc, newTabIndex: tabSelected });
    //       // }
    //       // console.log("change is loading to false");
    //       setIsLoading(false);
    //     }
    //   } catch (error) {
    //     logError(error, {
    //       errorLevel: " critical",
    //       generalData: { ...generalData },
    //       otherData: { submitFormFunc },
    //     });
    //   }
    // }, [submitFormFunc.newTabIndex, submitFormFunc.error]);

    useEffect(() => {
      // if refreshing page while in tour, meeting will still be 1 (from location.state)
      // and firstUse will be reset.  Therefore, returns to meetings page to avoid error
      if (meetingID === 1 && !firstUse) {
        navigate("/app/meetings");
      }

      // disconnects on unmount
      return () => {
        socket.emit("disconnect_socket_from_meeting", { userName, meetingID });
      };
    }, []);

    useEffect(() => {
      // console.log("%csocket changed", "background-color: yellow; color: red", {
      //   socket,
      //   data,
      // });

      try {
        // console.log("useEffect on socket change");
        if (data !== undefined) {
          const receivingMeetingCheckinListener = (info) => {
            console.log("----------------receiving_meeting_checkin", info);
            // setRefreshInfo(new Date());
            setMeeting({ ...meeting, refreshInfo: new Date() });
            setShowSnackbar({
              show: true,
              messageType: "success",
              message: `${info.userName} has joined the meeting`,
            });
          };

          const receivingLeaveMeetingListener = (info) => {
            console.log("------------------receiving_leave_meeting data", info);
            // setRefreshInfo(new Date());
            setMeeting({ ...meeting, refreshInfo: new Date() });
            refetch();
            setShowSnackbar({
              show: true,
              messageType: "warning",
              message: `${info.userName} has left the meeting`,
            });
          };

          const receivingEndMeetingListener = (info) => {
            console.log("------------------receiving_end_meeting data", info);
            // setRefreshInfo(new Date());
            setMeeting({ ...meeting, refreshInfo: new Date() });
            refetch();
            setShowSnackbar({
              show: true,
              messageType: "error",
              message: `Meeting has ended`,
            });
          };

          const receivingStartMeetingListener = () => {
            console.log("------------------receiving_start_meeting data");
            console.log("data", data.spMeetingStatus.nodes[0].statusId);

            const statusID = data.spMeetingStatus.nodes[0].statusId;
            const isOrganizer = data.spMeetingGeneral.nodes[0].isOrganizer;
            const checkedIn = data.spMeetingGeneral.nodes[0].checkedIn;
            // setRefreshInfo(new Date());
            // setMeeting({ ...meeting, refreshInfo: new Date() });
            // refetch();

            if (
              // [2, 3].includes(data.spMeetingStatus.nodes[0].statusId) &&
              [2, 3, 7, 8].includes(statusID) &&
              isOrganizer === false &&
              checkedIn === false
            ) {
              setModal({ visible: true, name: "check-in" });
            }

            // setShowSnackbar({
            //   show: true,
            //   messageType: "success",
            //   message: `Meeting has started`,
            // });
          };

          const receivingSendFeedbackToOrganizerListener = (info) => {
            console.log(
              "------------------receiving_feedback_to_organizer",
              info
            );
            // setRefreshInfo(new Date());
            setMeeting({ ...meeting, refreshInfo: new Date() });
            refetch();
            setShowSnackbar({
              show: true,
              messageType: "success",
              message: `Feedback to organizer received`,
            });
          };

          const receivingSendFeedbackToAttendeeListener = (info) => {
            console.log(
              "------------------receiving_feedback_to_attendee",
              info
            );
            // setRefreshInfo(new Date());
            setMeeting({ ...meeting, refreshInfo: new Date() });
            refetch();
            setShowSnackbar({
              show: true,
              messageType: "success",
              message: `Feedback to attendee received`,
            });
          };

          socket.on(
            "receiving_meeting_checkin",
            receivingMeetingCheckinListener
          );
          socket.on("receiving_leave_meeting", receivingLeaveMeetingListener);
          socket.on("receiving_end_meeting", receivingEndMeetingListener);
          socket.on("receiving_start_meeting", receivingStartMeetingListener);
          socket.on(
            "receiving_feedback_to_organizer",
            receivingSendFeedbackToOrganizerListener
          );
          socket.on(
            "receiving_feedback_to_attendee",
            receivingSendFeedbackToAttendeeListener
          );
          socket.on("connect_error", (error) => {
            logError(error, {
              errorLevel: " critical",
              generalData: { ...generalData },
              otherData: {},
            });
          });

          return () => {
            socket.off(
              "receiving_meeting_checkin",
              receivingMeetingCheckinListener
            );
            socket.off(
              "receiving_leave_meeting",
              receivingLeaveMeetingListener
            );
            socket.off("receiving_end_meeting", receivingEndMeetingListener);
            socket.off(
              "receiving_feedback_to_organizer",
              receivingSendFeedbackToOrganizerListener
            );
            socket.off(
              "receiving_feedback_to_attendee",
              receivingSendFeedbackToAttendeeListener
            );
          };
        }
      } catch (error) {
        logError(error, {
          errorLevel: " critical",
          generalData: { ...generalData },
          otherData: {},
        });
      }
    }, [socket, data]);

    useEffect(() => {
      if (socketSignalData !== undefined) {
        // console.log("socketSignalData", socketSignalData);
        const { attendeeName } = socketSignalData;
        switch (socketSignalData.action) {
          case "joinMeeting":
            console.log("socket to update: join meeting", socketSignalData);
            socket.emit("join_meeting", { meetingID });
            break;
          case "leaveMeeting":
            console.log("socket to update: leave meeting", socketSignalData);
            socket.emit("leave_meeting", {
              userName: attendeeName,
              meetingID: meetingID,
            });
            break;
          case "sendToOrganizer":
            console.log("socket to update: sendToOrganizer", socketSignalData);
            socket.emit("send_feedback_to_organizer", {
              organizerID,
              meetingID,
            });
            break;
          case "sendToAttendee":
            console.log("socket to update: sendToAttendee", socketSignalData);
            socket.emit("send_feedback_to_attendee", { meetingID });
            break;

          default:
          // console.log("socket to update other:", socketSignalData);
        }
      }
    }, [socketSignalData]);

    if (error) {
      return (
        <ErrorHandler
          error={error}
          errorClear={() => setErrorState()}
          retryFunction={() => refetch()}
          data={{
            errorLevel: "fatal",
            generalData: { ...generalData },

            otherData: { message: error.message },
          }}
        />
      );
    }

    let dataMeeting;

    if (firstUse) {
      const tourDummyData = tourData("meeting2");

      // console.log(
      //   "%ctourDummyData",
      //   "background-color: orange; color: white",
      //   tourDummyData
      // );

      dataMeeting = tourDummyData;
    } else {
      // console.log(
      //   "%cregular data",
      //   "background-color: orange; color: white",
      //   data
      // );

      dataMeeting = data;
    }
    // console.log("*******************  all the data first load", data);
    const { statusId: statusID } = dataMeeting?.spMeetingStatus?.nodes[0] || {};
    // const meetingTypeID = dataMeeting.meetingById.meetingTypeId;
    // const statusID = dataMeeting.spMeetingStatus.nodes[0].statusId || null;
    // const { checkedIn, isOrganizer, finalRating , departDate, willAttend } =
    //   dataMeeting.spMeetingGeneral.nodes[0];

    const { checkedIn, isOrganizer, finalRating, departDate, willAttend } =
      firstUse
        ? {
            checkedIn: true,
            isOrganizer: true,
            finalRating: 9.2,
            departDate: null,
            willAttend: true,
          }
        : dataMeeting?.spMeetingGeneral?.nodes[0] || {};
    const headerVariant =
      dataMeeting?.spMeetingStatus?.nodes.length === 0 ? null : statusID;

    const sendInfoToHeader = (newChanges) => {
      console.log("changes in sendInfoToHeader", newChanges);
      let newChangesState = {};
      // if the first property is null, removes the property
      if (Object.values(newChanges)[0] === null) {
        newChangesState = { ...changes };
        // retrieves the first property and remove it
        delete newChangesState[Object.keys(newChanges)[0]];
      } else {
        newChangesState = { ...changes, ...newChanges };
      }
      setChanges(newChangesState);
    };

    const tabs = [
      {
        id: 1,
        tabName: "Details",

        tabContent: (
          <MeetingDetails
            // contentChangedFunction={(value) => {
            //   contentChangedRef.current = value;
            // }}
            // contentChangedValue={contentChangedRef.current}
            endDateOccurrence={endDateOccurrence}
            // hideActivityIndicator={(item) => activityRef?.current?.hide()}
            meetingID={meetingID}
            parentEventID={parentEventID}
            ref={childRef}
            sendInfoToHeader={(newChanges) => sendInfoToHeader(newChanges)}
            sendInfoToParent={(info) => {
              console.log("la info en send info to parent", info);

              // attendeesRef.current = info;
            }}
            startDateOccurrence={startDateOccurrence}
            tempMeetingID={tempMeetingID}
            updateMeetingID={(value) => {
              console.log(
                "%cEl meeting id es",
                "background-color: red; color: white",
                value
              );

              // setMeetingID(value);
              // activityRef.current.hide();
              navigate("/app/meeting", {
                state: { meetingID: value },
              });
            }}
            formIsValid={(validStatus) =>
              formDetailsValidRef.current === validStatus
            }
            // updateMeetingCost={(value) => {
            //   setMeetingCostState(value);
            // }}
          />
        ),

        showFutureOccurences: true,
        showInMeetingStatus: [0, 1, 2, 3, 4, 5, 6, 7, 8],
        showInMeetingType: [0, 1, 2],
      },
      {
        id: 2,
        tabName: "Purpose",

        tabContent: (
          <MeetingPurpose
            // ref={childRef}
            meetingID={meetingID}
            parentEventID={parentEventID}
            // contentChanged={contentChangedRef}

            contentChangedFunction={(value) => {
              // console.log(
              //   "%cEl new ref value es",
              //   "background-color: green; color: white",
              //   value
              // );
              contentChangedRef.current = value;
            }}
            contentChangedValue={contentChangedRef.current}
            sendInfoToHeader={(newChanges) => sendInfoToHeader(newChanges)}
            statusID={statusID} // this avoids consuming context inside the component.  This would be feasible but causes unmounting when update meeting context and therefore, loses focus
          />
        ),
        showFutureOccurences: false,
        showInMeetingStatus: [0, 1, 2, 3, 4, 5, 6, 7, 8],
        showInMeetingType: [0, 1],
      },
      {
        id: 3,
        tabName: "Agenda", //meeting.withAgenda ? "Contents" : "Execution",

        tabContent: (
          <ContentInfo>
            <MeetingContents
              contentChangedFunction={(value) => {
                console.log(
                  "%cEl new contentChangedRef es",
                  "background-color: green; color: white",
                  value
                );
                contentChangedRef.current = value;
              }}
              contentChangedValue={contentChangedRef.current}
              meetingID={meetingID}
              parentEventID={parentEventID}
              ref={childRef}
              tempMeetingID={tempMeetingID}
              sendInfoToHeader={(newChanges) => sendInfoToHeader(newChanges)}
              // updateMeetingCost={(value) => {
              //   console.log(
              //     "%cmeetingCost",
              //     "background-color: yellow; color: red",
              //     cost
              //   );

              //   setMeetingCostState(value);
              // }}
            />
          </ContentInfo>
        ),
        showFutureOccurences: true,
        showInMeetingStatus: [0, 1, 2, 3, 4, 5, 6, 7, 8],
        showInMeetingType: [0, 1, 2],
      },
      {
        id: 4,
        tabName: "Actions",
        tabContent: (
          <MeetingActionsInfo>
            <MeetingActions
              meetingID={meetingID || parentEventID}
              attendees={attendeesRef.current} //{meeting.attendees}
            />
          </MeetingActionsInfo>
        ),
        showFutureOccurences: true, // limited by meeting status anyway
        showInMeetingStatus: [3, 4, 7, 8],
        showInMeetingType: [0, 1, 2],
      },
      {
        id: 5,
        tabName: "Parking lot",
        tabContent: (
          <MeetingParkingLot meetingID={meetingID || parentEventID} />
        ),
        showFutureOccurences: true, // limited by meeting status anyway
        showInMeetingStatus: [3, 4, 7, 8],
        showInMeetingType: [0, 1, 2],
      },
      {
        id: 6,
        tabName: "Feedback",
        tabContent: (
          <FeedbackInfo>
            <MeetingFeedback meetingID={meetingID || parentEventID} />
          </FeedbackInfo>
        ),
        showFutureOccurences: true, // limited by meeting status anyway
        showInMeetingStatus: [3, 4, 7, 8],
        showInMeetingType: [0, 1],
      },
    ];

    const variantTabs = tabs.filter(
      (item) =>
        item.showInMeetingStatus.includes(statusID) &&
        item.showInMeetingType.includes(meetingTypeID) &&
        // if the meeting is null will hide purpose for example.  If not meeting not null, will show accordingly
        (meetingID !== null ? true : item.showFutureOccurences) &&
        // if the meeting type is 1:1 and the agenda is hidden from organizer, hides the whole tab for the attendee
        ([2].includes(meetingTypeID) && privateAgenda && !isOrganizer
          ? item.id !== 3
          : true) &&
        // if the setting of account owner of the meeting is to not use objectives, hides the tab
        (withObjectives === false ? item.id !== 2 : true) &&
        // if the status is draft and setting of account owner of the meeting is to not use agenda, hides the tab
        // in any other meeting status, the content will keep showing to display privates notes and attendees check in
        ([1, 2, 8].includes(statusID) && withAgenda === false
          ? item.id !== 3
          : true)
    );

    return (
      <>
        <Card fullscreen>
          <MeetingHeader
            attendeesRef={attendeesRef}
            changes={changes}
            checkedIn={checkedIn}
            childContentRef={childRef} // use to execute functions inside the content with useImperativeHandle
            contentChangedRef={contentChangedRef}
            // cost={[1].includes(meetingTypeID) ? meetingCostState : null}
            cost={[1].includes(meetingTypeID) ? meetingCost : null}
            dataMeeting={dataMeeting}
            departDate={departDate}
            finalRating={finalRating}
            isOrganizer={isOrganizer}
            lastTabIndex={lastTabRef.current}
            headerVariant={headerVariant}
            meetingID={meetingID}
            refetchMeetingGeneral={(item) => refetch(paramsMeetingGeneral)}
            setShowSnackbar={setShowSnackbar}
            setSubmitFormFunc={setSubmitFormFunc}
            setModal={setModal}
            setTabSelected={(tabIndex) => {
              // updates the last tab selected to be able later to detect if there's a change
              lastTabRef.current = tabIndex;
              setTabSelected(tabIndex);
            }}
            // setMustHave={setMustHave} // to track if meeting should have started/ended
            statusID={statusID}
            submitFormFunc={submitFormFunc}
            tabs={tabs}
            tabSelected={tabSelected}
            variantTabs={variantTabs}
            willAttend={willAttend}
          />

          {/* <pre>
            {JSON.stringify(
              {
                changes,
              },
              null,
              2
            )}
          </pre> */}

          {/* using memoized content avoids losing input focus when updating changes  */}
          <MemoMeetingContent
            variantTabs={variantTabs}
            tabSelected={tabSelected}
            isLoading={isLoading}
          />
        </Card>

        <MeetingModals
          changes={changes}
          childContentRef={childRef} // use to execute functions inside the content with useImperativeHandle
          contentChangedRef={contentChangedRef}
          // meetingID={meetingID}
          modal={modal}
          // parentEventID={parentEventID}
          refetchMeetingGeneral={(item) => refetch(paramsMeetingGeneral)}
          setChanges={setChanges}
          setTabSelected={(tabIndex) => {
            // updates the last tab selected to be able later to detect if there's a change
            lastTabRef.current = tabIndex;
            setTabSelected(tabIndex);
          }}
          setModal={setModal}
          setShowSnackbar={setShowSnackbar}
          statusID={statusID}
        />

        {errorState && (
          <ErrorHandler
            error={errorState.error}
            errorExplanation={errorState.errorExplanation}
            errorClear={() => setErrorState()}
            data={errorState.data}
          />
        )}
        <Snackbar
          messageType={showSnackbar.messageType}
          message={showSnackbar.message}
          open={showSnackbar.show}
          close={() => setShowSnackbar({ ...showSnackbar, show: false })}
        />
      </>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
