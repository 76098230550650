import { useState, useRef, useContext } from "react";
// data
import axios from "axios";
import { debounce, axiosOptions } from "functions/Common";
import config from "config";
import { MeetingContext } from "contexts/MeetingContext";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";

// https://www.youtube.com/watch?v=NZKUirTtxcg
export default function useSaveAgendaItem(props, afterSaving) {
  // const [newAgendaItemID, setNewAgendaItemID] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);

  const generalData = useDataForLog(); // context data to populate error log

  const tempContentRef = useRef();

  const saveAgendaItem = (props) => {
    const {
      agendaItemToDelete,
      content,
      dayID,
      desc,
      details,
      duration,
      meetingID,
      newAgendaItems,
      operationType, //add, update or delete
      orderBy,
      parentEventID,
      privateNotes,
      setContent,
      typeID,
      updateRating,
    } = props;

    console.log(
      "%cprops in useSaveAgenda",
      "background-color: gray; color: white",
      props
    );

    const { blankAgendaItemWithNewID, descriptionRefs, isSavingRef } = content;

    let agendaItemsCopy = [...newAgendaItems];
    let newDescriptionRefs = descriptionRefs; // will be used when deleting items to also delete from refs

    const options = axiosOptions({
      url: `${config.url}/api/meeting-agenda-item-save`,
      method: "post",
      data: {
        meetingID: meetingID,
        parentEventID: parentEventID,
        privateNotes: privateNotes,
        desc: desc === "" ? null : desc,
        orderBy: orderBy,
        details: details,
        duration: duration,
        dayID: dayID,
        typeID: typeID,
        agendaItemToDelete: agendaItemToDelete?.id,
      },
    });
    // console.log("options axios", options);
    // debounce(() => {

    const executeAxios = () => {
      axios(options)
        .then(async (response) => {
          isSavingRef.current = false;
          console.log(
            "%c--------------------------------db updated in saveAgendaItem -------------------------------------------",
            "background-color: green; color: yellow",
            {
              parameters: {
                meetingID: meetingID,
                privateNotes: privateNotes,
                desc: desc === "" ? null : desc,
                orderBy: orderBy,
                details: details,
                duration: duration,
                dayID: dayID,
                typeID: typeID,
              },
              response: response,
            }
          );

          switch (operationType) {
            case "retrievePendingActions":
              // update with the newID
              const newItemID = response.data.info.id;
              agendaItemsCopy[0].id = newItemID;

              break;
            case "add":
              const newItem = blankAgendaItemWithNewID(
                agendaItemsCopy,
                meetingID
              );
              // update with the newID
              newItem.id = response.data.info.id;

              // add to the current agenda item list
              console.log("push new item");
              agendaItemsCopy.push(newItem);
              break;
            case "delete":
              if (agendaItemToDelete !== undefined) {
                // delete operation
                console.log(
                  "%cagendaItemToDelete",
                  "background-color: orange; color: white",
                  { agendaItemToDelete }
                );
                // // removes item from the descriptionRefs (used to focus after render)
                const newRefs = [...descriptionRefs.current];
                newRefs.splice(agendaItemToDelete.index, 1);
                console.log("newRefs", newRefs);

                // stores refs to update after the content context
                newDescriptionRefs = { current: newRefs };

                // focus in first item
                descriptionRefs.current[0].focus();

                const index = agendaItemsCopy.findIndex(
                  (item) => item.id === agendaItemToDelete.id
                );
                agendaItemsCopy.splice(index, 1);

                // update the order by sequence to avoid gaps
                agendaItemsCopy = agendaItemsCopy.map((item, index) => ({
                  ...item,
                  orderBy: index + 1,
                }));
              } else {
                console.log(
                  "%cagendaItemToDelete has not been defined",
                  "background-color: red; color: white",
                  { agendaItemToDelete }
                );
              }

              break;
            default:
          }

          const selectedItem = () => {
            if (agendaItemToDelete === undefined) {
              if (operationType === "retrievePendingActions") {
                // if no item has been deleted and retrievePendingActions, selectes first Item
                return { id: agendaItemsCopy[0].id, index: 0 };
              } else {
                // if no item has been deleted and not retrievePendingActions, keep current selected item
                return content.selectedItem;
              }
            } else {
              if (agendaItemsCopy.length === 0) {
                // if there aren't agenda items, selected is null
                return { id: null, index: null };
              } else {
                // if there ar agenda items, selected first item
                return { id: agendaItemsCopy[0].id, index: 0 };
              }
            }
          };

          setContent({
            ...content,
            agendaItems: agendaItemsCopy,
            descriptionRefs: newDescriptionRefs,
            selectedItem: selectedItem(),
          });

          // if (updateRating && newAgendaItems) {
          if (updateRating) {
            console.log("tries to update", newAgendaItems);
            const isAchieved = agendaItemsCopy.length > 0;
            updateRating(2, isAchieved);
          }
          const meetingCost = response.data.info.meeting_cost;

          afterSaving && afterSaving(meetingCost);
        })
        .catch((error) => {
          logError(error, {
            errorLevel: "fatal",
            generalData: { ...generalData },
          });
        });
    };
    // });

    debounce(() => executeAxios());
  };
  return {
    saveAgendaItem,
    tempContentRef,
  };
}
