import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  grayColor,
  successColor,
  dangerColor,
} from "assets/jss/material-dashboard-react.js";

export default function Medal(props) {
  const medalsList = {
    gold: {
      circle: "rgba(255, 226, 36, 1)",
      border: "rgba(209, 153, 0, 1)",
      ribbon: "red",
      contentInvisible: "rgba(0, 0, 0, 0)",
    },
    silver: {
      circle: "rgba(229, 229, 229, 1)",
      border: "rgba(163, 163, 163, 1)",
      ribbon: "red",
      contentInvisible: "rgba(0, 0, 0, 0)",
    },
    bronze: {
      circle: "rgba(236, 192, 152, 1)",
      border: "rgba(194, 112, 36, 1)",
      ribbon: "red",
      contentInvisible: "rgba(0, 0, 0, 0)",
    },
    red: {
      circle: dangerColor[4],
      border: dangerColor[0],
      ribbon: dangerColor[0],
      contentVisible: dangerColor[0],
      contentInvisible: "rgba(0, 0, 0, 0)",
    },
    green: {
      circle: successColor[5],
      border: successColor[0],
      ribbon: successColor[0],
      contentVisible: successColor[0],
      contentInvisible: "rgba(0, 0, 0, 0)",
    },
    unavailable: {
      circle: "rgba(229, 229, 229, 1)",
      border: "rgba(163, 163, 163, 1)",
      ribbon: "rgba(163, 163, 163, 1)",
      contentInvisible: "rgba(0, 0, 0, 0)",
    },
    noMedal: {
      circle: grayColor[6],
      border: grayColor[5],
      ribbon: grayColor[5],
      contentInvisible: "rgba(0, 0, 0, 0)",
    },
    reward: {
      circle: "white",
      border: grayColor[4],
      ribbon: grayColor[4],
      contentVisible: grayColor[4],
      contentInvisible: "rgba(0, 0, 0, 0)",
    },
  };
  const medal = medalsList[props.variant];

  return (
    <SvgIcon>
      {/* <svg
        width="48"
        height="48"
        viewBox="0 0 100 100"
        fill={medal.circle}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2759 33.1035H34.1379V48.0001L24.2069 43.0346L14.2759 48.0001V33.1035Z"
          fill={medal.ribbon}
        />
        <circle
          cx="24.2069"
          cy="18.2069"
          r="17.2069"
          stroke={medal.border}
          strokeWidth="2"
        />
      </svg> */}

      <svg
        width="48"
        height="48"
        viewBox="0 0 100 100"
        fill={medal.circle}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2759 33.1035H34.1379V48.0001L24.2069 43.0346L14.2759 48.0001V33.1035Z"
          fill={medal.ribbon}
        />
        <circle cx="24.2069" cy="18.2069" r="18.2069" fill="white" />
        <circle
          cx="24.2069"
          cy="18.2069"
          r="17.2069"
          stroke={medal.border}
          strokeWidth="2"
        />
        <path
          d="M18 28V24.8128H22.754V10.217H24.5734L18.4695 13.9106V10.2766L23.8397 7H26.5395V24.8128H31V28H18Z"
          fill={
            props.content === "1"
              ? medal.contentVisible
              : medal.contentInvisible
          }
        />
        <path
          d="M17.8631 29V26.2098L24.8274 19.0434C25.7004 18.1427 26.3452 17.3105 26.7619 16.5469C27.1786 15.7832 27.3869 15 27.3869 14.1972C27.3869 13.1986 27.0694 12.4448 26.4345 11.9357C25.7996 11.4266 24.877 11.172 23.6667 11.172C22.6944 11.172 21.7619 11.3483 20.869 11.7007C19.9762 12.0336 19.1032 12.5622 18.25 13.2867L17 10.4671C17.8532 9.72308 18.9048 9.12587 20.1548 8.67552C21.4246 8.22517 22.754 8 24.1429 8C26.4048 8 28.1409 8.4993 29.3512 9.4979C30.5615 10.4965 31.1667 11.9259 31.1667 13.786C31.1667 15.0783 30.8591 16.3119 30.244 17.4867C29.629 18.642 28.6766 19.8559 27.3869 21.1287L21.5833 26.8559V25.8573H32V29H17.8631Z"
          fill={
            props.content === "2"
              ? medal.contentVisible
              : medal.contentInvisible
          }
        />
        <path
          d="M24.2619 29C23.3492 29 22.4365 28.9021 21.5238 28.7063C20.631 28.5105 19.7877 28.2364 18.994 27.8839C18.2202 27.5315 17.5556 27.1105 17 26.621L18.25 23.772C19.1825 24.4573 20.1349 24.9762 21.1071 25.3287C22.0992 25.6615 23.131 25.828 24.2024 25.828C25.4921 25.828 26.494 25.5147 27.2083 24.8881C27.9425 24.242 28.3095 23.3804 28.3095 22.3035C28.3095 21.207 27.9623 20.3259 27.2679 19.6601C26.5734 18.9748 25.621 18.6322 24.4107 18.6322C23.5575 18.6322 22.7738 18.7986 22.0595 19.1315C21.3651 19.4448 20.7401 19.9343 20.1845 20.6H17.6548V8H30.6905V11.1133H21.2857V17.6923H20.3631C20.879 17.007 21.5635 16.4783 22.4167 16.1063C23.2897 15.7343 24.2619 15.5483 25.3333 15.5483C26.6825 15.5483 27.8532 15.8224 28.8452 16.3706C29.8373 16.9189 30.6111 17.6923 31.1667 18.6909C31.7222 19.6699 32 20.8252 32 22.1566C32 23.5077 31.6825 24.7021 31.0476 25.7399C30.4325 26.758 29.5397 27.5608 28.369 28.1483C27.2183 28.7161 25.8492 29 24.2619 29Z"
          fill={
            props.content === "5"
              ? medal.contentVisible
              : medal.contentInvisible
          }
        />
        <g clipPath="url(#clip0_690_27247)">
          <path
            d="M31.7142 10.2856L16.2856 25.7142"
            stroke={
              props.content === "x"
                ? medal.contentVisible
                : medal.contentInvisible
            }
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.2856 10.2856L31.7142 25.7142"
            stroke={
              props.content === "x"
                ? medal.contentVisible
                : medal.contentInvisible
            }
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_690_27247">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(15 9)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
