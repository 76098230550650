import {
  whiteColor,
  infoColor,
  grayColor,
  primaryColor,
} from "assets/jss/material-dashboard-react.js";

const accountInvoiceStyle = (theme) => ({
  amount: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: "right",
    // paddingRight: 20,
  },
  bodyText: {
    fontSize: 14,
    marginTop: 0,
    marginBottom: 0,
  },
  cardTitleWhite: {
    marginTop: 5,
    marginBottom: 5,
  },
  dateLabel: {
    textAlign: "right",
  },

  detailsContainer: {
    display: "block",
    // backgroundColor: "yellow",
    padding: "20px 0px 20px 50px",

    // paddingTop: 20,
    // paddingBottom: 20,
  },
  description: {
    marginTop: 20,
    marginBottom: 20,
  },
  gray: {
    color: grayColor[4],
  },
  headerContainer: {
    marginTop: 40,
  },
  horizontalSpacing: {
    marginBottom: 50,
    height: 0,
  },
  mainContainer: {
    height: "75vh",
    overflowY: "auto",
    padding: "0px 20px 0px 20px",
  },

  orquestiContainer: {
    display: "flex",
    flex: 1,
    // backgroundColor: primaryColor[5],
    border: "1px " + primaryColor[4] + " solid",
    height: "100%",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 100,
  },
  mainText: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 0,
  },

  returnContainer: {
    display: "flex",
    color: "white",
    alignItems: "center",
  },

  returnLabel: {
    // fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5,
    color: whiteColor,
  },
  tableTitle: {
    // marginTop: 80,
    marginBottom: 10,
  },
  tableTitleAmount: {
    // marginTop: 80,
    marginBottom: 10,
    textAlign: "right",
    // paddingRight: 20,
  },
  totalsSection: {
    marginTop: 50,
  },
  verticalDivider: {
    // paddingLeft:20,
    marginLeft: 0,
    marginRight: 20,
  },
  white: {
    color: whiteColor,
  },
});
export default accountInvoiceStyle;
