import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import EN from "./locales/en.json";
import ES from "./locales/es.json";

// const resources = {
//   en: EN,
//   es: ES,
// };

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    debug: true,
    ns: ["main"],
    defaultNS: "main",
    resources: {
      en: EN,
      es: ES,
    },
    // ...resources,
    // interpolation: {
    //   escapeValue: false, // not needed for react as it escapes by default
    // },
  });

export default i18n;
