import React, { useState } from "react";
// core components
import CustomHeader from "components/CustomHeader/CustomHeader.js";
// custom components
import ProfileGeneral from "./ProfileGeneral";
import ProfileSecurity from "./ProfileSecurity";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";

// material UI
import { Snackbar } from "@material-ui/core";
// styles
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  displayContentContainer: {
    height: "100%",
  },
};

const useStyles = makeStyles(styles);

export default function ProfileDetails(props) {
  //   const { userCompanyID, employeeName } = props.location.state;
  const classes = useStyles();
  const [tabSelected, setTabSelected] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    messageType: "",
    message: "",
  });

  const tabs = [
    {
      tabName: "General",
      // tabIcon: Cloud,
      tabContent: <ProfileGeneral />,
    },

    {
      tabName: "Password",
      // tabIcon: Cloud,
      tabContent: <ProfileSecurity />,
    },
  ];

  const displayContent = () => {
    return tabs.map((item, key) => {
      if (key === tabSelected) {
        return (
          <div key={key} className={classes.displayContentContainer}>
            {item.tabContent}
          </div>
        );
      }
      return (
        <div hidden key={key} className={classes.displayContentContainer}>
          {item.tabContent}
        </div>
      );
    });
  };

  return (
    <>
      <Card fullscreen>
        <CustomHeader
          headerColor="white"
          onChangeTab={(tabIndex) => {
            setTabSelected(tabIndex);
          }}
          headerVariant="clean"
          tabs={tabs}
          fullscreen
          tabSelected={tabSelected}
        />
        <CardBody fullscreen>{displayContent()}</CardBody>
      </Card>

      <Snackbar
        messageType={showSnackbar.messageType}
        message={showSnackbar.message}
        open={showSnackbar.show}
        close={() => setShowSnackbar({ ...showSnackbar, show: false })}
      />
    </>
  );
}
