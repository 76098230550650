import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// core components
import CustomHeader from "components/CustomHeader/CustomHeader.js";
import AccountSubscription from "./AccountSubscription";
import AccountInvoiceList from "./AccountInvoiceList";
import Card from "components/Card/Card";
import AccountUsers from "./AccountUsers";
import ErrorHandler from "components/Error/Error";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import AccountModals from "./AccountModals";
import Snackbar from "components/Snackbar/Snackbar";
// data
import { useQuery } from "@apollo/client";
import { usersSubscription } from "graphql/gqlAccount";
import { SessionContext } from "../../contexts/SessionContext";
import { AccountContext } from "contexts/AccountContext";
import { TourContext } from "contexts/TourContext";

// styles
import { makeStyles } from "@material-ui/core/styles";
import { infoColor } from "assets/jss/material-dashboard-react";

const styles = {
  displayContentContainer: {
    height: "100%",
  },

  indicatorNumber: {
    color: infoColor[4],
    margin: "0px 0px 0px 5px",
    fontWeight: 600,
  },
  indicatorLabel: {
    marginLeft: "5px",
    color: infoColor[4],
  },
};

const useStyles = makeStyles(styles);

export default function AccountDetails(props) {
  console.log(
    "%cstarts account details",
    "background-color: green; color: white"
  );

  // --------------STAGES-------------
  // stageID: 0  =>  no subscription
  // stageID: 1  =>  under third party subscription
  // stageID: 2  =>  in trial period ***inactive***
  // stageID: 20  => in basic plan
  // stageID: 3  =>  trial period expired, no subscription created ***inactive***
  // stageID: 4  =>  active subscription
  // stageID: 5  =>  subscription in renewal period
  // stageID: 6  =>  subscription expired

  //   const { userCompanyID, employeeName } = props.location.state;
  const classes = useStyles();
  const location = useLocation();
  const { session } = useContext(SessionContext);
  const { languageID, stageID, userID } = session;

  const { account, setAccount, showSnackbar, setShowSnackbar } =
    useContext(AccountContext);
  // const { } = account;

  // console.log("el invoice id", location.state.tab);
  const initialTabIndex = location.state === null ? 0 : location.state.tab;
  // console.log("initialTabIndex", initialTabIndex);
  const [tabSelected, setTabSelected] = useState(initialTabIndex);

  const { loading, error, data, refetch } = useQuery(usersSubscription, {
    variables: { userID: userID, languageID: languageID },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data !== undefined) {
      setAccount({
        ...account,
        slotsFree: data?.spSubscriptionCurrent.nodes[0].slotsFree,
        slotsPlan: data?.spSubscriptionCurrent.nodes[0].slotsPlan,
      });
    }
  }, [data]);

  const tabs = [
    {
      tabName: "Subscription",
      // tabIcon: Cloud,
      tabContent: <AccountSubscription />,
      hideInStage: [],
    },
    {
      tabName: "Users",
      // tabIcon: Cloud,
      tabContent: (
        <AccountUsers
        // slotsPlan={data?.spSubscriptionCurrent.nodes[0].slotsPlan}
        // slotsFree={data?.spSubscriptionCurrent.nodes[0].slotsFree}
        // sendInfoToParent={(item) => refetch()}
        />
      ),
      hideInStage: [0, 1],
    },
    {
      tabName: "Invoice history",
      // tabIcon: Cloud,
      tabContent: <AccountInvoiceList />,
      hideInStage: [0, 1, 2, 3],
    },
  ];

  const DisplayContent = () => {
    // console.log("isLoading", isLoading);
    const content = tabs.filter(
      (item, index) =>
        index === tabSelected && !item.hideInStage.includes(stageID)
    );
    // console.log("tabFiltered", content);

    return content.length === 0 ? null : content[0].tabContent;

    return tabs.map((item, key) => {
      if (key === tabSelected && !item.hideInStage.includes(stageID)) {
        // key is omitted on purpose to force state values cleanup, in particular search input
        console.log("------ display: ", item.tabName);
        return (
          // <div className={classes.displayContentContainer}>
          //   {item.tabContent}
          // </div>
          <>{item.tabContent}</>
        );
      }
      // return (
      //   <div hidden key={key} className={classes.displayContentContainer}>
      //     {item.tabContent}
      //   </div>
      // );
    });
  };

  return (
    <>
      <Card fullscreen>
        <CustomHeader
          headerColor="white"
          onChangeTab={(tabIndex) => {
            // console.log("selected tab is:", tabIndex);
            setTabSelected(tabIndex);
          }}
          // rightAlignItems={![1].includes(stageID) && <SubscriptionUsage />}
          headerVariant="clean"
          tabs={tabs.filter(
            (item) => !item.hideInStage.includes(stageID) && item
          )}
          fullscreen
          tabSelected={tabSelected}
        />
        {/* <h1 id="tour-element">TOUR</h1> */}
        <DisplayContent />
        <AccountModals />
      </Card>

      <Snackbar
        messageType={showSnackbar.messageType}
        message={showSnackbar.message}
        open={showSnackbar.show}
        close={() => setShowSnackbar({ ...showSnackbar, show: false })}
      />
    </>
  );
}
