import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CustomDialog from "components/CustomDialog/CustomDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import Button from "components/CustomButtons/Button.js";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import SideMenu from "components/SideMenu/SideMenu";

// data
import parseISO from "date-fns/parseISO";
import { useQuery, useMutation } from "@apollo/client";
import {
  todoPriorities,
  todoDetails,
  updateTodo,
} from "graphql/gqlTodoDetails";
import { SessionContext } from "../../contexts/SessionContext";
import { TodoContext } from "contexts/TodoContext";
import { debounce, formatDateCustom } from "../../functions/Common";
import { Formik, useFormikContext } from "formik";
import { object, string, date } from "yup";
import isEqual from "lodash/isEqual";
// import debounce from "lodash/debounce";
import config from "config";
import axios from "axios";
import { axiosOptions } from "../../functions/Common";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
// icons
import Gauge from "assets/svgComponent/gauge";
import CloseIcon from "@material-ui/icons/Close";
import { Restore, Search } from "@material-ui/icons";

// style
import format from "date-fns/format";

import { grayColor, thinBoxShadow } from "assets/jss/material-dashboard-react";

const styles = {
  activityIndicatorContainer: {
    // backgroundColor: "blue",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  archivedMainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "5px",
    paddingRight: "8px",
    marginBottom: "13px",
    ...thinBoxShadow,
  },
  archivedDescContainer: {
    padding: "8px 0px 8px 8px",
    marginRight: "15px", //creates a space without pointer
    // backgroundColor: "yellow",
    width: "100%",
    cursor: "pointer",
  },
  archivedListLabel: {
    color: grayColor[5],
    fontSize: "12px",
    lineHeight: "14px",
  },
  archivedSearchContainer: {
    marginBottom: "10px",
  },
};
const useStyles = makeStyles(styles);

export default function TodoArchived(props) {
  // console.log("starts to do details");
  const { session, setSession } = useContext(SessionContext);
  const { userID, languageID, firstUse } = session;

  const { todo, setTodo, openArchived, setOpenArchived, initialDataRef } =
    useContext(TodoContext);
  const { updateArchived } = todo;

  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    // return <h1>hola</h1>;
    // console.log("carga el sideMenumain");
    // console.log(
    //   "%cCarga el sideMainMenu",
    //   "background-color: red; color: white"
    // );

    // const { sendInfoToParent } = props;
    const [archivedBoards, setArchivedBoards] = useState([]);
    const [loadingArchived, setLoadingArchived] = useState(true);
    const [searchTermArchived, setSearchTermArchived] = useState(null);

    useEffect(() => {
      console.log("%cuse Effect", "background-color: yellow; color: red");

      const options = axiosOptions({
        url: `${config.url}/api/to-do-archived`,
        method: "get",
        params: {
          userID: userID,
          languageID: languageID,
          search: searchTermArchived,
        },
      });

      const axiosFetch = () => {
        console.log("starts axios fetch");
        axios(options)
          .then(async (response) => {
            console.log(
              "--------------axios response archived board",
              response.data
            );
            setArchivedBoards(response.data.info);
            setLoadingArchived(false);
          })
          .catch((error) => {
            setLoadingArchived(false);
            setErrorState({
              error: error,
              errorExplanation: "Couldn't list the archived to do's",
              data: {
                errorLevel: "fatal",
                otherData: { axiosOptions: options },
              },
            });
          });
      };

      // if the search term is not null, it means it's search and therefore applies debounce
      if (searchTermArchived === null) {
        console.log("without debounce");

        axiosFetch();
      } else {
        console.log("with debounce");
        debounce(() => {
          axiosFetch();
        });
      }
      // }
    }, [searchTermArchived, updateArchived]);

    return (
      <>
        {/* {sideMenuOpenFromParent && ( */}
        <SideMenu
          title="Archived boards"
          stickyContent={
            <StickyContent
              setLoadingArchived={setLoadingArchived}
              searchTermArchived={searchTermArchived}
              setSearchTermArchived={setSearchTermArchived}
            />
          }
          scrollableContent={
            <ArchivedBoards
              archivedBoards={archivedBoards}
              loadingArchived={loadingArchived}
            />
          }
          sideMenuOpenFromParent={openArchived}
          setSideMenuOpenFromParent={setOpenArchived}
          // modalVisible={modal?.visible}
          modalVisible={true}
        />

        {/* )} */}
      </>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal or critical",
      generalData: { ...generalData },
      // otherData: { modal },
    });
  }
}

const ArchivedBoards = (props) => {
  const classes = useStyles();
  const { loadingArchived, archivedBoards } = props;

  const { todo, modal, setModal } = useContext(TodoContext);
  const { originalData, currentData, updateInfo } = todo;

  return (
    <>
      {/* {loadingArchived ? (
        <div className={classes.activityIndicatorContainer}>
          <ActivityIndicator fullscreen />
        </div>
      ) : ( */}
      <div>
        {archivedBoards.map((item) => {
          // console.log("en archived item is", item);
          return (
            <div key={item.id} className={classes.archivedMainContainer}>
              <div
                className={classes.archivedDescContainer}
                onClick={() => {
                  console.log("clicked", item);

                  const toDoGroupList = () => {
                    let tempList = currentData.reduce((total, item) => {
                      if (item.toDoGroupIDDefault !== 1) {
                        total.push({
                          id: item.id,
                          menuDesc: item.desc,
                          listDeleted: false,
                        });
                      }

                      return total;
                    }, []);

                    console.log("toDoGroupList", toDoGroupList);

                    // if the current list was deleted, adds to the current active lists
                    if (item.listDeleted) {
                      tempList.push({
                        id: item.toDoGroupID,
                        menuDesc: item.toDoGroup,
                        listDeleted: true,
                      });
                    }

                    console.log(
                      "%ctempList",
                      "background-color: red; color: white",
                      tempList
                    );

                    return tempList;
                  };

                  setModal({
                    visible: true,
                    name: "toDoDetails",
                    data: {
                      todoID: item.id,
                      title: item.desc,
                      dueDate: item.dueDate,
                      selectedTodoGroupID: item.toDoGroupID,
                      archived: true,
                      listDeleted: item.listDeleted,
                      todoGroupList: toDoGroupList(),
                    },
                  });
                }}
              >
                <h5>{item.desc}</h5>
                <h6 className={classes.archivedListLabel}>
                  List: {item.toDoGroup}
                </h6>
              </div>
              {/* <Restore style={{ fontSize: 40 }} /> */}
              <Button
                color="primary"
                justIcon
                size="md"
                // simple
                onClick={() => {
                  // console.log("item", item);
                  setModal({
                    visible: true,
                    name: "confirm-restore-archived",
                    data: {
                      todoID: item.id,
                      selectedTodoGroupID: item.toDoGroupID,
                      restoreToBottom: true,
                    },
                  });

                  // confirmRestoreArchivedRef.current.openModal({
                  //   todoID: item.id,
                  //   selectedTodoGroupID: item.toDoGroupID,
                  //   restoreToBottom: true,
                  // });
                }}
              >
                <Restore />
              </Button>
            </div>
          );
        })}
      </div>
    </>
  );
};

const StickyContent = (props) => {
  const classes = useStyles();

  const { openArchived } = useContext(TodoContext);

  const { searchTermArchived, setSearchTermArchived, setLoadingArchived } =
    props;
  return (
    <div className={classes.archivedSearchContainer}>
      <CustomMainInput
        autoFocus={openArchived}
        color="primary"
        placeholder="Search"
        iconStart={<Search />}
        onChange={(info) => {
          console.log("info.target.value", info.target.value);

          setLoadingArchived(true);
          setSearchTermArchived(info.target.value);
        }}
        // onBlur={fprops.handleBlur("date")}
        value={searchTermArchived}
        variant="input"
      />
    </div>
  );
};
