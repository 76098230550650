import { gql } from "@apollo/client";

export const meetingFeedback = gql`
  query meetingFeedback($meetingID: Int!) {
    spMeetingFeedback(meetingId1: $meetingID) {
      nodes {
        expiredFeedbackTime
        endDateFinal
        dateOrganizerGiveRating
        hoursForFeedback
        canViewRating
      }
    }
  }
`;
