import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  directionRow: { display: "flex", flexDirection: "row" },
};

const useStyles = makeStyles(styles);

export default function GridItem(props) {
  const classes = useStyles();
  const { children, row, alignItems, ...rest } = props;
  return (
    <Grid
      item
      {...rest}
      className={row !== undefined ? classes.directionRow : undefined}
      style={{ alignItems: alignItems || "center" }}
    >
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  children: PropTypes.node,
};
