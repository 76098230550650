import {
  defaultFont,
  infoColor,
  whiteColor,
  primaryColor,
  dangerColor,
} from "assets/jss/material-dashboard-react.js";

import dropdownStyle from "assets/jss/material-dashboard-react/components/dropdownStyle.js";

const headerLinksStyle = (theme) => ({
  ...dropdownStyle(theme),
  availableContainer: {
    backgroundColor: primaryColor[4],
  },

  buttonLink: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      // margin: "30px 15px 0px 15px",
      width: "-webkit-fill-available",
      "& svg": {
        width: "24px",
        height: "30px",
        marginRight: "15px",
        marginLeft: "-15px",
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "24px",
        lineHeight: "30px",
        width: "24px",
        height: "30px",
        marginRight: "15px",
        marginLeft: "-15px",
      },
      "& > span": {
        justifyContent: "flex-start",
        width: "100%",
      },
    },
  },
  container: {
    alignItems: "center",
    borderRadius: 20,
    color: whiteColor,
    display: "flex",
    paddingLeft: 11,
    paddingRight: 11,
    marginLeft: 4,
    marginRight: 4,
    height: 35,
  },

  standardContainer: {
    alignItems: "center",
    borderRadius: 20,
    color: whiteColor,
    display: "flex",
    paddingLeft: 11,
    paddingRight: 11,
    marginLeft: 4,
    marginRight: 4,
    height: 30,
  },

  regularContainer: {
    color: primaryColor[4],
    backgroundColor: primaryColor[9],
  },

  attentionContainer: {
    color: infoColor[4],
    border: `1px solid ${infoColor[7]}`,
    backgroundColor: infoColor[9],
  },

  warningContainer: {
    color: dangerColor[1],
    border: `1px solid ${dangerColor[4]}`,
    backgroundColor: dangerColor[5],
  },

  expiryContainer: {
    backgroundColor: infoColor[4],
  },
  indicatorNumber: {
    lineHeight: "1.2em",
    fontSize: 14,
    fontWeight: 600,
    marginTop: 4,
    marginBottom: 6,
    paddingLeft: 4,
    paddingRight: 4,
  },
  indicatorGroup: {
    alignItems: "center",
    display: "flex",
    paddingLeft: 8,
    paddingRight: 8,
  },
  label: {
    fontSize: 11,
    marginTop: 0,
    marginBottom: 0,
  },
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0px",
  },

  margin: {
    zIndex: "4",
    margin: "0",
  },

  manager: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    display: "inline-block",
  },
  notifications: {
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "0px",
      border: "1px solid " + whiteColor,
      right: "0px",
      fontSize: "9px",
      background: "red",
      color: whiteColor,
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "16px",
      verticalAlign: "middle",
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      ...defaultFont,
      fontSize: "14px",
      marginRight: "8px",
    },
  },
  search: {
    "& > div": {
      marginTop: "0",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 15px !important",
      float: "none !important",
      paddingTop: "1px",
      paddingBottom: "1px",
      padding: "0!important",
      width: "60%",
      marginTop: "40px",
      "& input": {
        color: whiteColor,
      },
    },
  },
  searchButton: {
    [theme.breakpoints.down("sm")]: {
      top: "-50px !important",
      marginRight: "22px",
      float: "right",
    },
  },
  searchIcon: {
    width: "17px",
    zIndex: "4",
  },
  searchWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0",
    },
    display: "inline-block",
  },
  suscriptionWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0",
    },
    display: "inline-block",
  },
  todayIndicator: {
    fontSize: 14,
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    fontWeight: 600,
    textDecoration: "underline",
  },
});

export default headerLinksStyle;
