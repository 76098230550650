import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// @material-ui
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
// custom components
import Button from "components/CustomButtons/Button";
import NavbarRightElements from "./NavbarRightElements";
// icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Menu from "@material-ui/icons/Menu";
// style
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import { grayColor } from "assets/jss/material-dashboard-react";
const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const navBarRoutes = props.routes.filter((item) => item.inNavBar === true);
  // console.log("refresa el header");
  const BackButton = () => {
    const navigate = useNavigate();

    return (
      <Button
        // disabled={!isOrganizer || [5].includes(statusID)}
        color="gray"
        size="md"
        justIcon
        onClick={async () => {
          navigate(-1); // go back one step
        }}
      >
        <ArrowBackIcon />
      </Button>
    );
  };

  function showRouteName() {
    var name;
    props.routes.map((prop) => {
      // console.log("ejecutav busca titulo");
      // the .split(":")[0] removes any parameter from the url
      if (
        window.location.href.indexOf(prop.layout + prop.path.split(":")[0]) !==
        -1
      ) {
        // console.log("si encuentra el titulo", prop.name);
        // verifies if using rtl.  If not, if running tour, avoids placing "Run tour", as defined in routes.js but the "Dashboard" instead
        name = props.rtlActive
          ? prop.rtlName
          : prop.name === "Run tour"
          ? "Dashboard"
          : prop.name;
      }
      return null;
    });
    return name;
  }
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.currentLocationHeader}>
          {/* Here we create navbar brand, based on route name */}
          <BackButton />
          <div className={classes.title}>{showRouteName()}</div>
        </div>
        <Hidden smDown implementation="css">
          <NavbarRightElements routes={navBarRoutes} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
