import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CustomDialog from "components/CustomDialog/CustomDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import Button from "components/CustomButtons/Button.js";
import Dropzone from "components/Dropzone";
import { Thumbnail } from "components/Thumbnail";

// data
import parseISO from "date-fns/parseISO";
import { useQuery, useMutation } from "@apollo/client";
import {
  allDropDowns,
  newTicket,
  updateAttachmentPaths,
} from "graphql/gqlHelpDeskNew";
import { SessionContext } from "../../contexts/SessionContext";
import { HelpDeskContext } from "contexts/HelpDeskContext";
import { Formik } from "formik";
import { mixed, object, string } from "yup";
import Resizer from "react-image-file-resizer";
import { s3AddFile } from "functions/s3Files";
// errors
import { logError, useDataForLog } from "functions/ErrorBoundary";

// icons
import CloseIcon from "@material-ui/icons/Close";

// style

import {
  grayColor,
  primaryColor,
  defaultFont,
} from "assets/jss/material-dashboard-react";

const styles = {
  closeButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  dialogContent: {
    overflowY: "hidden",
    paddingTop: "20px",
    // paddingBottom: "40px",
  },
  dialogActions: {
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogTitle: { backgroundColor: grayColor[7], padding: "5px 20px" },
  label: {
    ...defaultFont,
    fontSize: "12px",
    paddingLeft: "10px",
    color: grayColor[0],
    marginTop: ".2rem",
  },
  topBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  thumbsContainer: {
    display: "flex",
    // backgroundColor: "yellow",
    flexDirection: "row",
    marginTop: 10,
    paddingBottom: 10,
    overflowX: "auto",
  },
};
const useStyles = makeStyles(styles);

export default function HelpDeskNew(props) {
  // console.log("starts HelpDeskNew");
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { setVisibleStatus, visibleStatus, sendInfoToParent } = props; // const companyID = parseInt(sessionStorage.getItem("companyID"));

    const classes = useStyles();
    // const [records, setRecords] = useState([]);
    const { session, setSession } = useContext(SessionContext);
    const { languageID, userID } = session;

    const { helpDesk, setHelpDesk, setShowSnackbar } =
      useContext(HelpDeskContext);

    const [files, setFiles] = useState({
      all: [],
      toUpload: [],
    });

    const [thumbToRemove, setThumbToRemove] = useState({
      index: null,
      trigger: false,
    });
    const maxFileSizeMb = 2;

    // const [dropzoneInfo, setDropzoneInfo] = useState({
    //   maxFileSizeMb: null,
    //   filesAll: [],
    //   filesToUpload: [],

    // });

    const { loading, data, error, refetch } = useQuery(allDropDowns, {
      variables: { languageID: languageID },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

    const formRef = useRef();

    let resetForm;

    const [newTicketMut] = useMutation(newTicket, {
      onCompleted: (data) => {
        console.log("data", data);
        // once the ticket is created, upload files using the just created ticket id
        const newTicketID = data.spHelpdeskNew.results[0].helpdeskId;
        // the number of the conversation line of the newTickeID
        const newTicketHistoryID =
          data.spHelpdeskNew.results[0].helpdeskHistoryId;

        uploadFiles(newTicketID, newTicketHistoryID);
        setVisibleStatus({
          ...visibleStatus,
          visible: false,
        });
        setHelpDesk({ ...helpDesk, updateInfo: new Date() });
      },
    });

    const [updateAttachmentPathsMut] = useMutation(updateAttachmentPaths, {
      // onCompleted: (data) => {
      //   // once the ticket is created, upload files using the just created ticket id
      //   const newTicketID = data.spHelpdeskNew.results.[0].helpdDeskId;
      //   // the number of the conversation line of the newTickeID
      //   const newTicketHistoryID = data.spHelpdeskNew.results.[0].helpdDeskHistoryId;
      //   uploadFiles(newTicketID, newTicketHistoryID);
      //   setVisibleStatus({
      //     ...visibleStatus,
      //     visible: false,
      //   });
      //   sendInfoToParent();
      // },
    });

    const uploadFiles = async (newTicketID, newTicketHistoryID) => {
      // data.append("comms", helpDeskTicketID, filesToUpload[0].path);
      console.log("%cupload files", "background-color: red; color: white", {
        newTicketID,
        newTicketHistoryID,
      });

      //------------------------------------------------
      console.log("inicia upload files");
      let attachmentPaths = [];

      await Promise.all(
        files.toUpload.map(async (file, index) => {
          console.log("--------1-----------", file);
          const resizeFile = (file) =>
            new Promise(async (resolve) => {
              Resizer.imageFileResizer(
                file,
                1920, // Is the maxWidth of the resized new image.
                1080, // Is the maxHeight of the resized new image.
                "JPEG", // Is the compressFormat of the resized new image.
                80, // Is the quality of the resized new image.
                0, // Is the degree of clockwise rotation to apply to uploaded image.
                (uri) => {
                  resolve(uri);
                }, // Is the callBack function of the resized new image URI.
                "blob" // Is the output type of the resized new image.
              );
            });
          console.log("--------2-----------");
          const imageResized = await resizeFile(file.imageFile);
          // console.log("image", image);
          console.log("--------3-----------");
          const contentType = imageResized.type;
          const key = `helpDeskTickets/id-${newTicketID}/${index + 1}`;

          console.log("%cvalues", "background-color: red; color: white", {
            // currentFileUrl: profileUrl,
            file: imageResized,
            contentType: contentType,
            key: key,
          });

          const newFileLocation = await s3AddFile({
            // currentFileUrl: profileUrl,
            file: imageResized,
            contentType: contentType,
            key: key,
          });

          attachmentPaths.push(newFileLocation);

          console.log(
            "%cFile uploaded succesfully to:",
            "background-color: red; color: white",
            newFileLocation
          );
        })
      );
      // console.log("attachment paths", attachmentPaths);
      console.log(
        "%cattachment paths",
        "background-color: orange; color: white",
        attachmentPaths
      );

      // once all the files have been uploading, collects the paths and updates the db with the corresponding conversation
      updateAttachmentPathsMut({
        variables: {
          helpdeskHistoryID: newTicketHistoryID,
          attachmentPaths: attachmentPaths,
        },
      });
    };

    const handleSubmit = async (values, actions) => {
      // console.log("en handle submit");
      console.log("------------ en handle submit", {
        values: values,
      });
      // resetForm = () => {
      //   resetForm();
      // };

      newTicketMut({
        variables: {
          userID: userID,
          subject: values.subject,
          details: values.details,
          priorityID: values.priorityID,
          categoryID: values.categoryID,
        },
      }).then(() => {
        actions.resetForm();
        setShowSnackbar({
          show: true,
          message: "Ticket submitted",
        });
      });
    };

    const spacing = 1;

    const ThumbnailsContainer = () => {
      if (files.all.length > 0) {
        const errorType = {
          //   "too-many-files": `Can't upload more than ${maxFiles} file${
          //     maxFiles > 1 ? "s" : ""
          //   }`,
          "file-too-large": `File is larger than ${maxFileSizeMb}Mb`,
          "file-invalid-type": "Only images are allowed",
        };

        // return files.all.map((file, index) => {

        // })
        //

        return (
          <div className={classes.thumbsContainer}>
            {files.all.map((file, index) => {
              console.log(
                "%cfile",
                "background-color: red; color: white",
                file
              );

              return (
                <Thumbnail
                  key={index}
                  tooltipText={file.name}
                  error={
                    file.errors.length > 0
                      ? errorType[file?.errors[0]?.code]
                      : ""
                  }
                  index={index}
                  path={file.preview}
                  sendInfoToParent={(indexToRemove) => {
                    setThumbToRemove({
                      index: indexToRemove,
                      trigger: !thumbToRemove.trigger, // this trigger is required because the index to delete may not change but the useEffect in dropzone needs to be triggered when this changes
                    });
                  }}
                />
              );
            })}
          </div>
        );
      }
    };

    if (data && data.allLanguages) {
      // console.log(
      //   "%cdata",
      //   "background-color: red; color: white",
      //   data.allLanguages
      // );

      const initialValues = {
        subject: "",
        // dueDate: dueDate == null ? "" : format(new Date(dueDate), "yyyy-MM-dd"),
        details: "",
        priorityID: 4,
        categoryID: null,
      };
      // console.log("----los initial values son", initialValues);

      const schema = object().shape({
        subject: string()
          .nullable()
          .required("Subject is required")
          .max(60, "Max 60 digits"),
        details: string().nullable().required("Details are required"),
        categoryID: mixed()
          .required("Select a year")
          .notOneOf([0], "Select a year"),
      });

      return (
        <>
          <Formik
            innerRef={formRef}
            // initialValue={[]}
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validationSchema={schema}
          >
            {(fprops) => {
              // sendInfoToParent(attendees, () => fprops.handleSubmit);
              // formErrors(fprops.handleSubmit)
              return (
                <Dialog
                  // classes={{ paper: classes.mainDialog }}
                  open={visibleStatus.visible || false}
                  maxWidth="sm"
                  fullWidth={true}
                >
                  <DialogTitle
                    // className={classes.dialogTitle}
                    disableTypography
                    classes={{ root: classes.dialogTitle }}
                  >
                    <GridContainer spacing={spacing}>
                      <GridItem xs={12}>
                        <div className={classes.topBar}>
                          <h4>New ticket</h4>
                          <div className={classes.closeButtonContainer}>
                            <Button
                              color="gray"
                              justIcon
                              simple
                              onClick={() => {
                                // removes any information that was input
                                fprops.resetForm();

                                // close modal
                                setVisibleStatus({
                                  ...visibleStatus,
                                  visible: false,
                                });
                              }}
                            >
                              <CloseIcon style={{ color: grayColor[3] }} />
                            </Button>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </DialogTitle>

                  <DialogContent className={classes.dialogContent}>
                    <>
                      <GridContainer
                        spacing={3}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                      >
                        {/* ---------------------- subject -------------------------- */}
                        <GridItem xs={12}>
                          <CustomMainInput
                            error={
                              fprops.touched.subject && fprops.errors.subject
                            }
                            height="40px"
                            label="Subject"
                            name="subject"
                            onChange={(e) => {
                              fprops.validateField("subject");
                              fprops.handleChange("subject")(e);
                            }}
                            // placeholder="T"
                            value={fprops.values.subject}
                            variant="input"
                          />
                        </GridItem>

                        {/* ---------------------- details -------------------------- */}
                        <GridItem xs={12}>
                          <CustomMainInput
                            error={
                              fprops.touched.details && fprops.errors.details
                            }
                            label="Details"
                            name="details"
                            variant="multiline"
                            height="100px"
                            // placeholder="Subject"
                            value={fprops.values.details}
                            onChange={fprops.handleChange("details")}
                          />
                        </GridItem>

                        {/* ---------------------- priority -------------------------- */}
                        <GridItem xs={12} md={6}>
                          <CustomMainInput
                            error={
                              fprops.touched.priorityID &&
                              fprops.errors.priorityID
                            }
                            dropDownList={
                              data.allLanguages.nodes[0].priorities.nodes
                            }
                            dropDownFunction={(item) => {
                              fprops.setFieldValue("priorityID", item.id);
                            }}
                            height="40px"
                            label="Priority"
                            name="priorityID"
                            value={fprops.values.priorityID}
                            variant="dropdown"
                          />
                        </GridItem>

                        {/* ---------------------- category -------------------------- */}
                        <GridItem xs={12} md={6}>
                          <CustomMainInput
                            error={
                              fprops.touched.categoryID &&
                              fprops.errors.categoryID
                            }
                            dropDownList={
                              data.allLanguages.nodes[0].categories.nodes
                            }
                            dropDownFunction={(item) => {
                              fprops.setFieldValue("categoryID", item.id);
                            }}
                            height="40px"
                            label="Category"
                            name="categoryID"
                            placeholder="Select a category"
                            value={fprops.values.categoryID}
                            variant="dropdown"
                          />
                        </GridItem>

                        {/* ---------------------- attachments -------------------------- */}
                        <GridItem xs={12}>
                          <Dropzone
                            thumbToRemove={thumbToRemove}
                            sendInfoToParent={(
                              acceptedFilesToUpload,
                              allSelectedFiles
                            ) => {
                              console.log("selected files in HelpDesNew", {
                                acceptedFilesToUpload,
                                allSelectedFiles,
                              });
                              setFiles({
                                all: allSelectedFiles,
                                toUpload: acceptedFilesToUpload,
                              });
                            }}
                            maxFiles={10}
                            maxFileSizeMb={maxFileSizeMb}
                          />
                          <ThumbnailsContainer />
                        </GridItem>
                      </GridContainer>

                      {/* <pre>
                    {JSON.stringify(
                      {
                        touched: fprops.touched,
                        errors: fprops.errors,
                        values: fprops.values,
                      },
                      null,
                      2
                    )}
                  </pre> */}
                    </>
                  </DialogContent>

                  <DialogActions className={classes.dialogActions}>
                    <Button
                      color="primary"
                      onClick={(item) => {
                        fprops.submitForm();
                      }}
                    >
                      Submit new ticket
                    </Button>
                  </DialogActions>
                </Dialog>
              );
            }}
          </Formik>
        </>
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
