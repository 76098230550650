import React, { useEffect, useState, useContext, memo, useRef } from "react";
import add from "date-fns/add";
// data
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { infoOrganizerUser } from "graphql/gqlFeedbackFromUserView";
import { updateMeeting } from "graphql/gqlMeeting";
import { SessionContext } from "contexts/SessionContext";
import { MeetingContext } from "contexts/MeetingContext";
import { FeedbackContext } from "contexts/FeedbackContext";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";

import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import CustomTooltip from "components/CustomTooltip/CustomTooltip.js";
import Button from "components/CustomButtons/Button.js";

import { TimerBar } from "./TimerBar";

// errors
import { ErrorBoundary } from "react-error-boundary";

import Multiface from "assets/svgComponent/multiface";
import SaveIcon from "@material-ui/icons/Save";
// styles
import { grayColor } from "assets/jss/material-dashboard-react";
import { formatDateTime } from "functions/Common";

const styles = {
  body: {
    // marginTop: "30px",
    padding: "20px 20px 0px 20px",
    overflow: "auto",
    maxHeight: "40vh",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingTop: "20px",
    paddingRight: "20px",
  },
  completedByLabel: {
    color: grayColor[4],
    paddingRight: 10,
  },

  facesContainer: {
    display: "flex",
    alignItems: "center",
  },
  face: {
    paddingLeft: "0px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "20px",
    justifyContent: "space-between",
    borderBottom: `1px solid  ${grayColor[6]}`,
    alignItems: "center",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
  },
  itemsContainer: {
    padding: "5px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  typeContainer: {
    borderColor: grayColor[5],
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    padding: "20px",
  },

  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    color: grayColor[3],
  },
  tooltip: {
    marginLeft: "4px",
  },
};

const useStyles = makeStyles(styles);

export const FeedbackFromUserView = () => {
  const classes = useStyles();

  const { feedback, setFeedback } = useContext(FeedbackContext);
  const { infoOrganizer, endDateFinalRef } = feedback;

  const dateAttendeeGiveRating = infoOrganizer[0]?.dateAttendeeGiveRating;
  // show TimerBar when meeting has ended and attendee rating is missing
  return (
    <>
      <TimerBar
        show={
          // infoOrganizer.length > 0 &&
          endDateFinalRef.current !== null && dateAttendeeGiveRating === null
        }
      />

      <RatingToOrganizerFromUserView />
    </>
  );
};

const RatingToOrganizerFromUserView = () => {
  const classes = useStyles();

  const { session, setSession } = useContext(SessionContext);
  const { userID, languageID, firstUse } = session;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { createdBy, meetingID, refreshInfo, statusID } = meeting;

  const { feedback, setFeedback } = useContext(FeedbackContext);
  const { expiredFeedbackTime, infoOrganizer, endDateFinalRef } = feedback;

  const [comment, setComment] = useState("");

  const {
    loading,
    data,
    error,
    refetch: refetchOU,
  } = useQuery(infoOrganizerUser, {
    variables: {
      meetingID: meetingID,
      userID: userID,
      languageID: languageID,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip: firstUse,
  });
  const dateAttendeeGiveRating =
    infoOrganizer[0]?.dateAttendeeGiveRating || null;

  useEffect(() => {
    if (data !== undefined) {
      setFeedback({
        ...feedback,
        infoOrganizer: data.spMeetingFeedbackToOrganizerUser.nodes,
      });
      setComment(data.spMeetingFeedbackComments.nodes[0].comment);
    }
  }, [data]);

  const [updateMeetingMut] = useMutation(updateMeeting, {
    onCompleted: () => {
      console.log("db update");
      // emits socket signal
      setMeeting({
        ...meeting,
        socketSignalData: { action: "sendToOrganizer" },
      });
      // sendInfoToParent({ action: "sendToOrganizer" });
    },
  });

  // console.log("%cTEST", "background-color: red; color: white", {
  //   test: dateAttendeeGiveRating !== null || expiredFeedbackTime,
  //   dateAttendeeGiveRating,
  //   expiredFeedbackTime,
  // });

  const Header = () => (
    <div className={classes.header}>
      <h4 className={classes.title}>Rate organizer</h4>

      {endDateFinalRef.current !== null && dateAttendeeGiveRating !== null && (
        <h6 className={classes.completedByLabel}>
          Completed: {formatDateTime(dateAttendeeGiveRating)}
        </h6>
      )}
    </div>
  );

  const Content = () => {
    return (
      <div className={classes.body}>
        {infoOrganizer.map((criteria) => {
          return (
            <ErrorBoundary
              key={criteria.id}
              data={{
                data: {
                  location: "Rating to organizer criteria",
                  meetingInfo: meeting,
                  criteria: criteria,
                },
              }}
            >
              <div className={classes.itemsContainer}>
                <div className={classes.itemContainer}>
                  <h6 className={classes.item}>{criteria.desc}</h6>
                  <span>
                    {criteria.tooltip && (
                      <div className={classes.tooltip}>
                        <CustomTooltip tooltipText={criteria.tooltip} />
                      </div>
                    )}
                  </span>
                </div>
                <div className={classes.facesContainer}>
                  {[1, 2, 3, 4, 5].map((faceRating) => {
                    return (
                      <div key={faceRating} className={classes.face}>
                        <Button
                          onClick={() => {
                            const newArray = infoOrganizer.map(
                              (criteria2, index2) => {
                                if (criteria.id === criteria2.id) {
                                  return {
                                    ...criteria2,
                                    rating: faceRating,
                                  };
                                } else {
                                  return criteria2;
                                }
                              }
                            );

                            setFeedback({
                              ...feedback,
                              infoOrganizer: newArray,
                            });
                          }}
                          color="info"
                          justIcon
                          round
                          disabled={
                            dateAttendeeGiveRating !== null ||
                            expiredFeedbackTime
                          }
                        >
                          <Multiface
                            selected={faceRating === criteria.rating && true}
                            rating={faceRating}
                          />
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </ErrorBoundary>
          );
        })}
      </div>
    );
  };

  const Footer = () => {
    const { modal, setModal } = useContext(FeedbackContext);

    return (
      <div className={classes.buttonContainer}>
        <Button
          onClick={() => {
            try {
              const uncompleted = infoOrganizer.filter(
                (item) => item.rating === null
              );

              if (uncompleted.length > 0) {
                setModal({ visible: true, name: "rating-missing" });
              } else {
                const feedbackForUpdate = infoOrganizer.map((item) => ({
                  ratingId: item.id,
                  value: item.rating,
                }));

                const newInfoOrganizer = [...infoOrganizer];
                newInfoOrganizer[0] = {
                  ...newInfoOrganizer[0],
                  dateAttendeeGiveRating: new Date().toISOString(),
                };
                console.log(newInfoOrganizer);

                setFeedback({
                  ...feedback,
                  infoOrganizer: newInfoOrganizer,
                });

                updateMeetingMut({
                  variables: {
                    meetingID: meetingID,
                    // sectionToUpdate: 7, // update only the feedback to the organizer
                    userID: userID,
                    comment: comment,
                    feedbackToOrganizer: feedbackForUpdate,
                    objectives: null, // for some reason if not provided creates an error
                    topics: null, // for some reason if not provided creates an error
                    actions: null, // for some reason if not provided creates an error
                    feedback: null, // for some reason if not provided creates an error
                  },
                });
              }
            } catch (error) {
              setErrorState({
                error: error,
                errorExplanation: "Couldn't save",
                data: {
                  errorLevel: "critical",
                  otherData: { meetingID, comment, infoOrganizer },
                },
              });
            }
          }}
          color="primary"
          startIcon={<SaveIcon />}
          disabled={dateAttendeeGiveRating !== null || expiredFeedbackTime}
        >
          Save
        </Button>
      </div>
    );
  };

  if (infoOrganizer.length > 0) {
    return (
      <GridItem lg={4} md={5} sm={6} xs={12}>
        <div className={classes.typeContainer}>
          <ErrorBoundary
            data={{
              data: {
                location: "Rating to organizer user",
                meetingInfo: meeting,
                feedback: feedback,
              },
            }}
          >
            <Header />
          </ErrorBoundary>

          <ErrorBoundary
            data={{
              data: {
                location: "Rating to organizer all criterias",
                meetingInfo: meeting,

                infoOrganizer: infoOrganizer,
              },
            }}
          >
            <Content />
          </ErrorBoundary>
          <CustomMainInput
            label="Comment"
            // name="objectives"
            variant="multiline"
            height="100px"
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
            disabled={dateAttendeeGiveRating !== null || expiredFeedbackTime}
          />
          {/* </div> */}

          <ErrorBoundary
            data={{
              data: {
                location: "Rating to organizer user",
                meetingInfo: meeting,
                feedback: feedback,
              },
            }}
          >
            <Footer />
          </ErrorBoundary>
        </div>
      </GridItem>
    );
  }
};
