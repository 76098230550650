import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  forwardRef,
} from "react";

// custom components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// data
import { SessionContext } from "../../contexts/SessionContext";
import { HelpDeskContext } from "contexts/HelpDeskContext";
import { formatDateTime } from "functions/Common";

// errors
import {
  fallbackRender,
  errorComponent,
  logError,
  LogErrorComponent,
  useDataForLog,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
// icons

// style
import { makeStyles } from "@material-ui/core/styles";

import {
  grayColor,
  primaryColor,
  infoColor,
  successColor,
  dangerColor,
  purpleColor,
} from "assets/jss/material-dashboard-react";

const styles = {
  baseIndicator: {
    borderRadius: "3px",
    padding: "6px",
    textAlign: "center",
    color: "white",
    fontSize: "11px",
  },
  gridContainer: {
    flex: 1,
    // borderBottom: `1px solid ${grayColor[7]}`,
  },

  ticketLineContainer: {
    alignItems: "center",
    cursor: "default",
    borderRadius: "5px",

    backgroundColor: "white", //grayColor[8],
    // backgroundColor: "yellow",
    // borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "3px 0px 3px 0px",

    padding: "5px 20px 5px 20px",
    // transition: "all 600ms ease-in-out",
    width: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
    "&:hover,&:focus": {
      backgroundColor: grayColor[9],
      // backgroundColor: primaryColor[10],
    },
  },
  ticketLineDivider: {
    borderBottom: `1px solid ${grayColor[7]}`,
  },

  priorityNormal: { backgroundColor: successColor[2] },
  priorityHigh: { backgroundColor: purpleColor[3] },
  priorityUrgent: { backgroundColor: infoColor[4] },
  priorityEmergency: { backgroundColor: dangerColor[1] },
  // priorityEmergency: {
  //   color: "white",

  //   animationName: "$priorityEmergency",
  //   animationDuration: "700ms",
  //   animationIterationCount: "infinite",
  //   animationDirection: "alternate",
  //   animationTimingFunction: "cubic-bezier(0.5,0,0.5,1)",
  // },
  // "@keyframes priorityEmergency": {
  //   from: {
  //     backgroundColor: dangerColor[1],
  //   },
  //   to: {
  //     backgroundColor: dangerColor[4],
  //   },
  // },

  statusPending: { backgroundColor: primaryColor[6] },
  statusReplied: { backgroundColor: successColor[1] },
  statusClosed: { backgroundColor: grayColor[5] },
};
const useStyles = makeStyles(styles);

const HelpDeskLine = forwardRef((props, ref) => {
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { ticket, isLastTicket } = props;

    const classes = useStyles();
    // const [records, setRecords] = useState([]);
    const { session, setSession } = useContext(SessionContext);
    const { languageID, userID } = session;

    const { helpDesk, setHelpDesk, modal, setModal } =
      useContext(HelpDeskContext);
    const { isAdmin } = helpDesk;

    // console.log(
    //   "%cprops en ticketline",
    //   "background-color: red; color: white",
    //   props
    // );

    const priorityArray = {
      1: classes.priorityEmergency,
      2: classes.priorityUrgent,
      3: classes.priorityHigh,
      4: classes.priorityNormal,
    };
    const priorityColor = priorityArray[String(ticket.priorityId)];

    const statusArray = {
      1: classes.statusPending,
      2: classes.statusReplied,
      3: classes.statusClosed,
    };
    const statusColor = statusArray[String(ticket.statusGroupId)];

    const columnSizes = isAdmin
      ? [1, 1, 4, 2, 2, 1, 1]
      : [1, 1, 5, 2, 2, null, 1];

    return (
      <div
        ref={ref}
        className={`${classes.ticketLineContainer} ${
          !isLastTicket && classes.ticketLineDivider
        }`}
        onClick={(e) => {
          // console.log("e", ticket.id);
          setModal({
            visible: true,
            name: "view-ticket",
            data: { ticketID: ticket.id },
          });

          // navigate("/app/ticket", {
          //   state: { ticketID: ticket.id },
          // });
        }}
      >
        <ErrorBoundary
          FallbackComponent={(error) => (
            <LogErrorComponent
              error={error}
              data={{
                location: "Ticket list line",
                userID: userID,
                ticketInfo: ticket,
              }}
            />
          )}
        >
          <GridContainer
            spacing={2}
            direction="row"
            classes={{ container: classes.gridContainer }}
            alignItems="center"
          >
            {/* ticket id (show id if in development)*/}

            <GridItem xs={columnSizes[0]}>
              <h6 className={classes.nameLabel}>{ticket.id}</h6>
            </GridItem>

            {/* priority */}
            <GridItem xs={columnSizes[1]}>
              <h6 className={`${classes.baseIndicator} ${priorityColor}`}>
                {ticket.priority}
              </h6>
            </GridItem>
            {/* subject (show id if in development)*/}
            <GridItem xs={columnSizes[2]}>
              {/* <h5 className={classes.nameLabel}>{`${
                    userID === 1 ? `${ticket.id}-` : ``
                  } ${ticket.title}`}</h5> */}
              <h6 className={classes.nameLabel}>{ticket.subject}</h6>
            </GridItem>

            <GridItem xs={columnSizes[3]}>
              <h6 className={classes.alignCenter}>{ticket.category}</h6>
            </GridItem>

            <GridItem xs={columnSizes[4]}>
              <h6 className={classes.alignCenter}>
                {formatDateTime(ticket.createdDate, {
                  helpDeskTickets: ticket,
                })}
              </h6>
            </GridItem>
            {/* status */}
            {isAdmin && (
              <GridItem xs={columnSizes[5]}>
                <h6 className={classes.nameLabel}>{ticket.userName}</h6>
              </GridItem>
            )}

            {/* status */}
            <GridItem xs={columnSizes[6]}>
              <h6 className={`${classes.baseIndicator} ${statusColor}`}>
                {ticket.statusGroup}
              </h6>
            </GridItem>
          </GridContainer>
        </ErrorBoundary>
      </div>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
});

export default HelpDeskLine;
