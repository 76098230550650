import { createContext, useContext, useMemo, useState } from "react";
import { SessionContext } from "./SessionContext";

// export const TourContext = createContext({
//   state: initialState,
//   setState: () => undefined,
// });
// TourContext.displayName = "TourContext";

export const TourContext = createContext();

export function TourInfo(props) {
  const { session, setSession } = useContext(SessionContext);

  const initialState = {
    // run: session?.firstUse,
    // stepIndex: 0,
    stepID: "dashboard-0",
    moveToStepID: "", // this is used to indicate a change is required waiting for the destination to mount the target and after that, increment the stepID
    steps: [],
    tourActive: true,
  };

  const [tourState, setTourState] = useState(initialState);

  const value = useMemo(
    () => ({
      tourState,
      setTourState,
    }),
    [setTourState, tourState]
  );

  return <TourContext.Provider value={value} {...props} />;
}

export function useAppContext() {
  const context = useContext(TourContext);

  if (!context) {
    throw new Error("useAppContext must be used within a TourInfo");
  }

  return context;
}
