import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";

const customInputStyle = {
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important",
      // backgroundColor: grayColor[8] + " !important",
    },
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[5] + " !important",
      borderWidth: "1px !important",
    },
    "&:after": {
      borderColor: primaryColor[0],
    },
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor[1],
    },
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor[1],
    },
  },

  inputRoot: {
    ...defaultFont,
    color: grayColor[0] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    // lineHeight: "1.42857143",
    letterSpacing: "unset",
    // padding: "8px 0px",
    // paddingTop: "5px",
    margin: ".2rem",

    // "&:hover": {
    //   backgroundColor: primaryColor[8],
    // },
  },

  helperText: {
    ...defaultFont,
    color: dangerColor[1] + " !important",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "1",
    letterSpacing: "unset",
    marginTop: 3,
    marginBottom: 0,
  },
  formControl: {
    // paddingBottom: "5px",
    margin: "5px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "0",
    zIndex: "2",
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    // pointerEvents: "none",
  },

  marginTop: {
    marginTop: "20px", // original "16px",
  },

  labelContainer: {
    display: "flex",
    alignItems: "center",
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[4] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "0",

    letterSpacing: "unset",
    // padding: "0px 0px",
  },

  smallHeight: {
    padding: "0px",
    height: "30px",
    // padding: "0px 0px 14px 0px",
  },

  mediumHeight: {
    padding: "0px",
    height: "40px",
  },

  largeHeight: {
    padding: "0px",
    height: "50px",
    // padding: "10px 0px 25px 0px",
  },

  labelRootError: {
    color: dangerColor[1],
  },
  labelRootSuccess: {
    color: successColor[1],
  },
  rightAlign: {
    textAlign: "right",
  },
  search: {
    width: "400px",
  },
  tooltip: {
    marginLeft: 4,
    color: "red",
  },

  highlight: {
    fontWeight: "800",
  },
  primary: {
    borderColor: primaryColor[4],
    color: primaryColor[4] + " !important",
    borderWidth: "1px",
  },

  primaryLabel: {
    color: primaryColor[2] + " !important",
  },

  base: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: primaryColor[4],
      borderWidth: "2px",
    },
    // "&:hover .MuiOutlinedInput-notchedOutline": {
    // borderColor: "red",
    // },
    // borderWidth: "1px",
    // borderStyle: "solid",
    // borderColor: primaryColor[4],

    // "& $notchedOutline": {
    //   borderWidth: 0,
    // },
    // "&:hover $notchedOutline": {
    //   borderWidth: "0px",
    // },
    // "&$focused $notchedOutline": {f
    //   borderWidth: 0,
    // },
  },
  baseOutlined: {
    // margin: "0px",

    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: primaryColor[4],
      borderWidth: "2px",
    },
  },
};

export default customInputStyle;
