import { gql } from "@apollo/client";

export const updateMeetingAttendees = gql`
  mutation updateMeetingAttendees(
    $attendeeID: Int!
    $arrivalDate: Datetime
    $arrivalRecordedBy: Int
    $departDate: Datetime
    $departRecordedBy: Int
    $inProcessDate: Datetime
  ) {
    updateMeetingAttendeeById(
      input: {
        meetingAttendeePatch: {
          inProcessDate: $inProcessDate
          departDate: $departDate
          arrivalDate: $arrivalDate
          arrivalRecordedBy: $arrivalRecordedBy
          departRecordedBy: $departRecordedBy
        }
        id: $attendeeID
      }
    ) {
      clientMutationId
    }
  }
`;

export const meetingAttendees = gql`
  query meetingAttendees($meetingID: Int!) {
    spMeetingAttendees(meetingId1: $meetingID) {
      nodes {
        status
        imageUrl
        id
        attendee
        inProcessDate
      }
    }
  }
`;

export const updateEndDate = gql`
  mutation updateEndDate($meetingID: Int!, $endDate: Datetime!) {
    updateMeetingById(
      input: { meetingPatch: { endDate: $endDate }, id: $meetingID }
    ) {
      clientMutationId
    }
  }
`;

export const deleteAgendaItem = gql`
  mutation deleteAgendaItem($agendaItemID: Int!, $meetingID: Int!) {
    deleteMeetingAgendaItemById(input: { id: $agendaItemID }) {
      clientMutationId
      query {
        meetingById(id: $meetingID) {
          meetingAgendaItemsByMeetingId {
            totalCount
          }
        }
      }
    }
  }
`;
