import { addDaysToDate } from "../functions/Common";

export const demoData = (selected) => {
  const initialDate = new Date();

  const data = {
    dashboard: {
      c1: {
        nodes: [
          {
            dates: new Date(addDaysToDate(1, new Date())),
            value1: 120,
            value2: 110,
          },
          {
            dates: addDaysToDate(2, initialDate),
            value1: 95,
            value2: 120,
          },
          {
            dates: addDaysToDate(3, initialDate),
            value1: 110,
            value2: 119,
          },
          {
            dates: addDaysToDate(4, initialDate),
            value1: 103,
            value2: 123,
          },
          {
            dates: addDaysToDate(5, initialDate),
            value1: 137,
            value2: 125,
          },
          {
            dates: addDaysToDate(6, initialDate),
            value1: 122,
            value2: 118,
          },
        ],
      },
      c2: {
        nodes: [
          {
            dates: new Date(addDaysToDate(1, new Date())),
            value1: 2.1,
            value2: 3.3,
          },
          {
            dates: addDaysToDate(2, initialDate),
            value1: 4.0,
            value2: 3.9,
          },
          {
            dates: addDaysToDate(3, initialDate),
            value1: 3.3,
            value2: 3.4,
          },
          {
            dates: addDaysToDate(4, initialDate),
            value1: 3.9,
            value2: 3.0,
          },
          {
            dates: addDaysToDate(5, initialDate),
            value1: 4.3,
            value2: 2.9,
          },
          {
            dates: addDaysToDate(6, initialDate),
            value1: 4.0,
            value2: 3.2,
          },
        ],
      },
      c3: {
        nodes: [
          {
            dates: new Date(addDaysToDate(1, new Date())),
            value1: 3.5,
            value2: 3.3,
          },
          {
            dates: addDaysToDate(2, initialDate),
            value1: 4.0,
            value2: 3.9,
          },
          {
            dates: addDaysToDate(3, initialDate),
            value1: 3.5,
            value2: 4.5,
          },
          {
            dates: addDaysToDate(4, initialDate),
            value1: 3.2,
            value2: 3.0,
          },
          {
            dates: addDaysToDate(5, initialDate),
            value1: 4.0,
            value2: 4.9,
          },
          {
            dates: addDaysToDate(6, initialDate),
            value1: 4.2,
            value2: 4.0,
          },
        ],
      },
      c4: {
        nodes: [
          {
            dates: new Date(addDaysToDate(1, new Date())),
            value1: 10,
            value2: 13,
          },
          {
            dates: addDaysToDate(2, initialDate),
            value1: 7,
            value2: 9,
          },
          {
            dates: addDaysToDate(3, initialDate),
            value1: 13,
            value2: 11,
          },
          {
            dates: addDaysToDate(4, initialDate),
            value1: 8,
            value2: 9.5,
          },
          {
            dates: addDaysToDate(5, initialDate),
            value1: 11,
            value2: 8,
          },
          {
            dates: addDaysToDate(6, initialDate),
            value1: 8,
            value2: 11,
          },
        ],
      },
      c5: {
        nodes: [
          {
            dates: new Date(addDaysToDate(1, new Date())),
            value1: 4.8,
            value2: 4.2,
          },
          {
            dates: addDaysToDate(2, initialDate),
            value1: 4.2,
            value2: 4.5,
          },
          {
            dates: addDaysToDate(3, initialDate),
            value1: 4.3,
            value2: 4.6,
          },
          {
            dates: addDaysToDate(4, initialDate),
            value1: 3.8,
            value2: 4.1,
          },
          {
            dates: addDaysToDate(5, initialDate),
            value1: 3.9,
            value2: 4.5,
          },
          {
            dates: addDaysToDate(6, initialDate),
            value1: 4.5,
            value2: 3.7,
          },
        ],
      },
      c6: {
        nodes: [
          {
            dates: new Date(addDaysToDate(1, new Date())),
            value1: 4.1,
            value2: 4.3,
          },
          {
            dates: addDaysToDate(2, initialDate),
            value1: 3.5,
            value2: 4.0,
          },
          {
            dates: addDaysToDate(3, initialDate),
            value1: 4.1,
            value2: 4.2,
          },
          {
            dates: addDaysToDate(4, initialDate),
            value1: 4.8,
            value2: 4.5,
          },
          {
            dates: addDaysToDate(5, initialDate),
            value1: 4.3,
            value2: 4.0,
          },
          {
            dates: addDaysToDate(6, initialDate),
            value1: 3.9,
            value2: 3.7,
          },
        ],
      },
      c7: {
        nodes: [
          {
            dates: new Date(addDaysToDate(1, new Date())),
            value1: 0.98,
            value2: 0.95,
          },
          {
            dates: addDaysToDate(2, initialDate),
            value1: 0.89,
            value2: 0.92,
          },
          {
            dates: addDaysToDate(3, initialDate),
            value1: 0.97,
            value2: 0.91,
          },
          {
            dates: addDaysToDate(4, initialDate),
            value1: 0.82,
            value2: 0.79,
          },
          {
            dates: addDaysToDate(5, initialDate),
            value1: 0.75,
            value2: 0.86,
          },
          {
            dates: addDaysToDate(6, initialDate),
            value1: 0.79,
            value2: 0.89,
          },
        ],
      },
    },

    actionsList: [
      {
        meetingActionId: 1293,
        toDoId: 164,
        actionName: "Analyze competition pricing",
        responsibleEmail: "demo1@orquesti.com",
        responsibleDesc: "Rachel Silver",
        imagePath: null,
        dueDate: null,
        statusId: 2,
        statusDesc: "Accepted",
        statusTooltip:
          "Action was accepted by owner and incorporated in to do's",
        stepCurrent: 1,
        stepTotal: 3,
        actionCompleted: true,
        completedDate: null,
        meetingId: 911,
        originalResponsibleDesc: "laurentsobole@yahoo.com",
        modifying: false,
      },
      {
        meetingActionId: 802,
        toDoId: 124,
        actionName: "Review cost structure",
        responsibleEmail: "demo1@orquesti.com",
        responsibleDesc: "Rachel Silver",
        imagePath:
          "https://main-orquesti3.s3.us-east-2.amazonaws.com/public/profileImages/userID:1-0.20595068739032318",
        dueDate: null,
        statusId: 2,
        statusDesc: "Accepted",
        statusTooltip:
          "Action was accepted by owner and incorporated in to do's",
        stepCurrent: 2,
        stepTotal: 3,
        actionCompleted: true,
        completedDate: null,
        meetingId: 171,
        originalResponsibleDesc: "Support Orquesti",
        modifying: false,
      },
      {
        meetingActionId: 625,
        toDoId: null,
        actionName: "Research prices",
        responsibleEmail: "laurentsobole@ggggg.com",
        responsibleDesc: "Jeffrey Lloyd",
        imagePath: null,
        dueDate: "2024-07-02T06:00:00.000Z",
        statusId: 3,
        statusDesc: "Rejected",
        statusTooltip:
          "Action was rejected by owner and not incorporated in to do's",
        stepCurrent: null,
        stepTotal: null,
        actionCompleted: true,
        completedDate: null,
        meetingId: 163,

        originalResponsibleDesc: "laurentsobole@ggggg.com",
        modifying: false,
      },
      {
        meetingActionId: 581,
        toDoId: null,
        actionName: "Review packaging materials",
        responsibleEmail: "laurentsobole@yahoo.com",
        responsibleDesc: "Rebeca Montgomery",
        imagePath: null,
        dueDate: null,
        statusId: 3,
        statusDesc: "Rejected",
        statusTooltip:
          "Action was rejected by owner and not incorporated in to do's",
        stepCurrent: null,
        stepTotal: null,
        actionCompleted: false,
        completedDate: null,
        meetingId: 101,
        originalResponsibleDesc: "laurentsobole@yahoo.com",
        modifying: false,
      },
      {
        meetingActionId: 769,
        toDoId: 120,
        actionName: "Negotiate with supplier cost reduction",
        responsibleEmail: "support@orquesti.com",
        responsibleDesc: "Brad Ewans",
        imagePath:
          "https://main-orquesti3.s3.us-east-2.amazonaws.com/public/profileImages/userID:1-0.20595068739032318",
        dueDate: null,
        statusId: 4,
        statusDesc: "Completed",
        statusTooltip: "Action was marked as completed by owner",
        stepCurrent: 0,
        stepTotal: 3,
        actionCompleted: false,
        completedDate: "1900-01-01T05:36:13.000Z",
        meetingId: 175,
        originalResponsibleDesc: "Support Orquesti",
        modifying: false,
      },
      {
        meetingActionId: 1950,
        toDoId: null,
        actionName: "Review transportation costs",
        responsibleEmail: "support@orquesti.com",
        responsibleDesc: "Brad Ewans",
        imagePath:
          "https://main-orquesti3.s3.us-east-2.amazonaws.com/public/profileImages/userID:1-0.20595068739032318",
        dueDate: "2024-08-16T06:00:00.000Z",
        statusId: 1,
        statusDesc: "Waiting response",
        statusTooltip:
          "Action was assigned but has not been accepted or rejected by owner",
        stepCurrent: null,
        stepTotal: null,
        actionCompleted: false,
        completedDate: null,
        meetingId: 1009,
        possibleOwners: [
          {
            email: "en@yahoo.com",
            name: null,
            imagePath: null,
          },
          {
            email: "laurentsobole@yahoo.com",
            name: null,
            imagePath: null,
          },
          {
            email: "support@orquesti.com",
            name: "Support Orquesti",
            imagePath:
              "https://main-orquesti3.s3.us-east-2.amazonaws.com/public/profileImages/userID:1-0.20595068739032318",
          },
        ],
        originalResponsibleDesc: "Support Orquesti",
        modifying: false,
      },
      {
        meetingActionId: 1659,
        toDoId: null,
        actionName: "Estimate launch pricing",
        responsibleEmail: "",
        responsibleDesc: "Rachel Silver",
        imagePath: null,
        dueDate: null,
        statusId: 2,
        statusDesc: "Accepted",
        statusTooltip:
          "Action was assigned but has not been accepted or rejected by owner",
        stepCurrent: null,
        stepTotal: null,
        actionCompleted: false,
        completedDate: null,
        meetingId: 1011,
        possibleOwners: [
          {
            email: "laurentsobole@yahoo.com",
            name: null,
            imagePath: null,
          },
          {
            email: "support@orquesti.com",
            name: "Support Orquesti",
            imagePath:
              "https://main-orquesti3.s3.us-east-2.amazonaws.com/public/profileImages/userID:1-0.20595068739032318",
          },
        ],
        originalResponsibleDesc: "",
        modifying: false,
      },
    ],
    actionDetails: [
      {
        __typename: "SpActionsDetailsRecord",
        typeId: 4,
        owner: "Michael Smith",
        meetingActionId: 1293,
        description: "Action completed",
        date: "2024-07-13T01:02:13.498+00:00",
      },
      {
        __typename: "SpActionsDetailsRecord",
        typeId: 2,
        owner: "Michael Smith",
        meetingActionId: 1293,
        description: "Launch meeting",
        date: "2024-05-14T16:15:00+00:00",
      },
      {
        __typename: "SpActionsDetailsRecord",
        typeId: 3,
        owner: "Michael Smith",
        meetingActionId: 1293,
        description: 'Rachel Silver moved action from "To do" to "Working on"',
        date: "2024-04-25T13:15:00+00:00",
      },

      {
        __typename: "SpActionsDetailsRecord",
        typeId: 1,
        owner: "Michael Smith",
        meetingActionId: 1293,
        description: "Action created",
        date: "2024-03-30T16:15:15+00:00",
      },
    ],
    todo: {
      originalData: [
        {
          id: 277,
          desc: "From meetings",
          orderBy: 1,
          items: [
            {
              id: 1599,
              desc: "New actiondfgdfg",
              orderBy: 1,
              toDoGroupId: 277,
              dueDate: "2024-08-12T00:00:00+00:00",
              toDoPriorityId: 1,
              meetingTitle: "Agree production forecast",
              meetingDate: null,
              meetingOrganizer: "Rachel Silver",
            },
            {
              id: 1667,
              desc: "Analyze competition pricing",
              orderBy: 1,
              toDoGroupId: 277,
              dueDate: "2024-09-18T00:00:00+00:00",
              toDoPriorityId: 1,
              meetingTitle: "Define price strategy",
              meetingDate: "2024-08-05T17:15:00+00:00",
              meetingOrganizer: "Rachel Silver",
            },
            {
              id: 1660,
              desc: "Review cost structure",
              orderBy: 1,
              toDoGroupId: 277,
              dueDate: "2024-08-29T00:00:00+00:00",
              toDoPriorityId: 1,
              meetingTitle: "Define price strategy",
              meetingDate: "2024-08-05T17:15:00+00:00",
              meetingOrganizer: "Rachel Silver",
            },
          ],
          locked: true,
          toDoGroupIDDefault: 1,
        },
        {
          id: 275,
          desc: "To do",
          orderBy: 2,
          items: [],
          locked: true,
          toDoGroupIDDefault: 2,
        },
        {
          id: 274,
          desc: "In progress",
          orderBy: 3,
          items: [
            {
              id: 161,
              desc: "To do 161",
              orderBy: 1,
              toDoGroupId: 274,
              dueDate: null,
              toDoPriorityId: 4,
              meetingTitle: null,
              meetingDate: null,
              meetingOrganizer: null,
            },
          ],
          locked: false,
          toDoGroupIDDefault: 3,
        },
        {
          id: 278,
          desc: "Waiting for review",
          orderBy: 3,
          items: [],
          locked: false,
          toDoGroupIDDefault: 3,
        },
        {
          id: 276,
          desc: "Done",
          orderBy: 1000,
          items: [],
          locked: true,
          toDoGroupIDDefault: 4,
        },
      ],
      currentData: [
        {
          id: 277,
          desc: "From meetings",
          orderBy: 1,
          items: [
            {
              id: 1599,
              desc: "Sign new contract with supplier",
              orderBy: 1,
              toDoGroupId: 277,
              dueDate: "2024-08-12T00:00:00+00:00",
              toDoPriorityId: 1,
              meetingTitle: "Agree production forecast",
              meetingDate: null,
              meetingOrganizer: "Rachel Silver",
            },
            {
              id: 1667,
              desc: "Analyze competition pricing",
              orderBy: 1,
              toDoGroupId: 277,
              dueDate: "2024-09-18T00:00:00+00:00",
              toDoPriorityId: 1,
              meetingTitle: "Define price strategy",
              meetingDate: "2024-08-05T17:15:00+00:00",
              meetingOrganizer: "Rachel Silver",
            },
            {
              id: 1660,
              desc: "Review cost structure",
              orderBy: 1,
              toDoGroupId: 277,
              dueDate: "2024-08-29T00:00:00+00:00",
              toDoPriorityId: 1,
              meetingTitle: "Define price strategy",
              meetingDate: "2024-08-05T17:15:00+00:00",
              meetingOrganizer: "Rachel Silver",
            },
          ],
          locked: true,
          toDoGroupIDDefault: 1,
        },
        {
          id: 275,
          desc: "To do",
          orderBy: 2,
          items: [
            {
              id: 1,
              desc: "Review forecasting",
              orderBy: 1,
              toDoGroupId: 274,
              dueDate: null,
              toDoPriorityId: 1,
              meetingTitle: null,
              meetingDate: null,
              meetingOrganizer: null,
            },
            {
              id: 2,
              desc: "Approve packaging designs",
              orderBy: 1,
              toDoGroupId: 274,
              dueDate: null,
              toDoPriorityId: 1,
              meetingTitle: null,
              meetingDate: null,
              meetingOrganizer: null,
            },
            {
              id: 3,
              desc: "Define product pipeline",
              orderBy: 1,
              toDoGroupId: 274,
              dueDate: null,
              toDoPriorityId: 2,
              meetingTitle: null,
              meetingDate: null,
              meetingOrganizer: null,
            },
          ],
          locked: true,
          toDoGroupIDDefault: 2,
        },
        {
          id: 274,
          desc: "In progress",
          orderBy: 3,
          items: [
            {
              id: 4,
              desc: "Sign contract with supplier",
              orderBy: 1,
              toDoGroupId: 274,
              dueDate: null,
              toDoPriorityId: 4,
              meetingTitle: null,
              meetingDate: null,
              meetingOrganizer: null,
            },
            {
              id: 5,
              desc: "Define distribution strategy",
              orderBy: 1,
              toDoGroupId: 274,
              dueDate: null,
              toDoPriorityId: 2,
              meetingTitle: null,
              meetingDate: null,
              meetingOrganizer: null,
            },
          ],
          locked: false,
          toDoGroupIDDefault: 3,
        },
        {
          id: 278,
          desc: "Waiting for review",
          orderBy: 3,
          items: [
            {
              id: 6,
              desc: "Define job profile",
              orderBy: 1,
              toDoGroupId: 274,
              dueDate: null,
              toDoPriorityId: 3,
              meetingTitle: null,
              meetingDate: null,
              meetingOrganizer: null,
            },
          ],
          locked: false,
          toDoGroupIDDefault: 3,
        },
        {
          id: 276,
          desc: "Done",
          orderBy: 1000,
          items: [
            {
              id: 7,
              desc: "Define production plan with plant",
              orderBy: 1,
              toDoGroupId: 274,
              dueDate: null,
              toDoPriorityId: 1,
              meetingTitle: null,
              meetingDate: null,
              meetingOrganizer: null,
            },
            {
              id: 8,
              desc: "Define products to discontinue",
              orderBy: 1,
              toDoGroupId: 274,
              dueDate: null,
              toDoPriorityId: 3,
              meetingTitle: null,
              meetingDate: null,
              meetingOrganizer: null,
            },
            {
              id: 9,
              desc: "Agree plan to open new channels",
              orderBy: 1,
              toDoGroupId: 274,
              dueDate: null,
              toDoPriorityId: 3,
              meetingTitle: null,
              meetingDate: null,
              meetingOrganizer: null,
            },
          ],
          locked: true,
          toDoGroupIDDefault: 4,
        },
      ],
    },
  };

  return data[selected];
};
