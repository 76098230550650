import { useContext, useEffect, useState } from "react";

// @material-ui/core components

// core components
import Card from "components/Card/Card.js";
import CustomHeader from "components/CustomHeader/CustomHeader.js";
import Snackbar from "components/Snackbar/Snackbar";
import TodoArchived from "./TodoArchived";
import TodoMain from "./TodoMain";
import TodoModals from "./TodoModals";

// data

import axios from "axios";
import { demoData } from "utils/demoData";
import { axiosOptions } from "../../functions/Common";

import config from "config";

import { TodoContext } from "contexts/TodoContext";
import { SessionContext } from "../../contexts/SessionContext";
// errors
import { ErrorHandler, logError, useDataForLog } from "functions/ErrorBoundary";

// style
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  kanbanContainer: {
    // backgroundColor: "red",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "calc(100vh - 170px)",
    marginTop: "20px",
    // flexDirection: "row",
    overflowX: "auto",
    overflowY: "none",

    // scrollbarGutter: "auto",
    width: "calc(100vw - 130px)",
    // width: "100%",
    // paddingBottom: 20,
  },
};
const useStyles = makeStyles(styles);

export default function Todo() {
  console.log("starts to do");
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const classes = useStyles();

    const { session, setSession } = useContext(SessionContext);
    const { userID, languageID, firstUse } = session;

    const {
      todo,
      setTodo,
      showSnackbar,
      setShowSnackbar,
      openArchived,
      setOpenArchived,
      kanbanRef,
      initialDataRef,
    } = useContext(TodoContext);
    const { originalData, currentData, updateInfo } = todo;

    // const [modal, setModal] = useState({
    //   visible: false,
    //   name: "",
    // });

    // const navigate = useNavigate();

    // const confirmRestoreArchivedRef = useRef();
    const [parameterList, setParameterList] = useState({
      searchTerm: "",
    });

    useEffect(() => {
      if (firstUse) {
        return;
      }

      if (userID === 111) {
        const data = demoData("todo");
        console.log(
          "%la data en todo",
          "background-color: yellow; color: red",
          data
        );

        setTodo(data);
        return;
      }
      const options = axiosOptions({
        url: `${config.url}/api/to-do-list`,
        method: "get",
        params: {
          userID: userID,
          languageID: languageID,
          // search:
        },
      });
      console.log(
        "%coptions todo",
        "background-color: blue; color: white",
        options
      );

      axios(options)
        .then(async (response) => {
          console.log(
            "%caxios response",
            "background-color: blue; color: white",
            response.data
          );

          initialDataRef.current = response.data.info; // avoids flickers between render and refreshing data
          setTodo({
            originalData: response.data.info,
            currentData: response.data.info,
          });
        })
        .catch((error) => {
          setErrorState({
            error: error,
            errorExplanation: "Couldn't list the to do's",
            data: {
              errorLevel: "fatal",
              otherData: { axiosOptions: options },
            },
          });
        });
    }, [updateInfo]);

    // const { loading, data, error, refetch } = useQuery(toDoMisc, {
    //   variables: { userID: userID },
    //   // variables: { userID: userID, searchTerm: parameterList.searchTerm },
    //   notifyOnNetworkStatusChange: true,
    //   fetchPolicy: "cache-and-network",
    //   skip: firstUse,
    // });

    // if (error) {
    //   return (
    //     <ErrorHandler
    //       error={error}
    //       errorClear={() => setErrorState()}
    //       retryFunction={() => refetch()}
    //       data={{
    //         generalData: { ...generalData },
    //         otherData: {
    //           message: error.message,
    //           searchTerm: parameterList.searchTerm,
    //         },
    //       }}
    //     />
    //   );
    // }

    // if (loading) {
    //   return null;
    // }

    // console.log("la data", data);

    const menuList = [
      {
        id: 1,
        // buttonDesc: "Sort A to Z",
        menuDesc: "Archived cards",
        dividingLineBelow: false,
        click: () => {
          // setSideMenuOpenFromParent(true);
          setOpenArchived(true);
        },
      },
    ];

    const filteredData = (searchTerm) => {
      console.log("¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡runs filtered data");

      // if there's no parameter, don't apply any filter

      const searchPattern = searchTerm.toLowerCase();
      if (searchTerm.length === 0) {
        initialDataRef.current = originalData; // avoids flickers between render and refreshing data
        setTodo({ ...todo, currentData: originalData }); // resets back to the original data
      } else {
        const dataFiltered = originalData.map((column) => {
          // console.log("la columna es", column);
          const itemsFiltered = column.items.filter((item) => {
            const str = item.desc;
            return str.toLowerCase().includes(searchPattern);
          });

          return { ...column, items: itemsFiltered };
        });

        initialDataRef.current = dataFiltered; // avoids flickers between render and refreshing data
        setTodo({ ...todo, currentData: dataFiltered });
      }
    };

    // if (initialDataRef.current === undefined) {
    //   return <ActivityIndicator fullscreen />;
    // }

    return (
      <Card fullscreen>
        <CustomHeader
          fullscreen
          headerColor="white"
          headerVariant="todo"
          parametersSelected={parameterList}
          sendInfoToParent={(item) => {
            console.log("se presionó", item);
            switch (item.parameter) {
              case "search":
                console.log("search parameter", item.value);
                setParameterList({
                  ...parameterList,
                  searchTerm: item.value,
                });

                filteredData(item.value);
                break;
              default:
                return null;
            }
          }}
          menuList={menuList}
        />
        <div className={classes.kanbanContainer} ref={kanbanRef}>
          <TodoMain />

          {openArchived && <TodoArchived />}
          <TodoModals />

          {errorState && (
            <ErrorHandler
              error={errorState.error}
              errorExplanation={errorState.errorExplanation} // only for dialog, not error
              errorClear={() => setErrorState()}
              data={{
                errorLevel: errorState.data.errorLevel,
                generalData: { ...generalData },
                otherData: { ...errorState.data.otherData },
              }}
            />
          )}
          <Snackbar
            messageType={showSnackbar.messageType}
            message={showSnackbar.message}
            open={showSnackbar.show}
            undo={showSnackbar.undo}
            close={() => setShowSnackbar({ ...showSnackbar, show: false })}
          />
        </div>
      </Card>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
