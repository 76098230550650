import { useContext, useEffect, useState } from "react";

// data
import { FeedbackContext } from "contexts/FeedbackContext";
import { MeetingContext } from "contexts/MeetingContext";
// @material-ui/core components

// core components
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// errors
import { LogErrorComponent } from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// icons

// styles and format
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "assets/jss/material-dashboard-react";
import add from "date-fns/add";
import { timeDifference } from "functions/Common";

const styles = {
  commentTotalContainer: {
    margin: "5px 0px",
    padding: "10px 20px 5px 20px",
    borderTop: `1px solid  ${grayColor[6]}`,

    overflow: "auto",
    height: "100px",
  },
  commentLineContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px 20px 0px",
  },
  commentUserContainer: {
    paddingLeft: "15px",
  },
  commentUserLabel: {
    fontWeight: 600,
    color: grayColor[2],
  },
  commentLabel: {
    color: grayColor[4],
  },
  completedByLabel: {
    color: grayColor[4],
    paddingRight: 10,
  },

  facesContainer: {
    display: "flex",
    alignItems: "center",
  },
  face: {
    paddingLeft: "0px",
  },
  feedbackUnavailableLabel: {
    color: grayColor[4],
    paddingLeft: "30px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "20px",
    justifyContent: "space-between",
    borderBottom: `1px solid  ${grayColor[6]}`,
    alignItems: "center",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
  },
  itemsContainer: {
    padding: "5px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  mainContainer: {
    height: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  typeContainer: {
    borderColor: grayColor[5],
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    padding: "20px",
    // backgroundColor: "orange",

    // maxWidth: "500px",
  },

  notShownContainer: {
    padding: "20px 20px",
    height: "35vh",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  notShownLabel: {
    color: grayColor[4],
    margin: "10px 0px",
  },

  noFeedbackLabel: {
    color: grayColor[4],
    margin: "30px 0px 10px 0px",
  },
  overallRatingLabel: {
    color: grayColor[4],
  },
  ratingContainer1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px 0px 0px 0px",
  },
  ratingContainer2: {
    display: "flex",
    alignItems: "baseline",
  },
  ratingLabel: {
    fontWeight: 600,
    color: grayColor[2],
    margin: "0px",
  },
  timeLeftLabel: {
    color: grayColor[3],
    paddingRight: "5px",
    margin: 0,
  },
  timeLeftContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    height: "45px",

    backgroundColor: grayColor[7],
    // justifyContent: "space-between",
    padding: "7px",
    borderRadius: "5px",
    marginBottom: 15,
  },
  timeLeftIndicatorContainer: {
    // backgroundColor: "red",
    border: "1px solid red",
    padding: "3px 10px",
    borderRadius: "5px",
    minWidth: "100px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    height: "100%",
    marginLeft: "20px",
  },
  timeLeftIndicator: {
    color: "red",
    textAlign: "center",
    margin: 0,
    lineHeight: "unset",
  },

  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    color: grayColor[3],
  },
  tooltip: {
    marginLeft: "4px",
  },
};

const useStyles = makeStyles(styles);

export const TimerBar = ({ show }) => {
  const { meeting, setMeeting } = useContext(MeetingContext);

  if (show) {
    return (
      <GridContainer spacing={4} direction="row" alignItems="flex-start">
        <GridItem xs={12}>
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                data={{
                  // generalData: { ...generalData },
                  otherData: {},
                }}
              />
            )}
          >
            <Timer
              // endDateFinal={endDateFinalRef.current}
              // hoursToGiveFeedback={hoursForFeedbackRef.current}
              sendInfoToParent={(info) => {
                // console.log("preuba");
                info.expired &&
                  meeting.expiredFeedbackTime !== undefined &&
                  setMeeting({
                    ...meeting,
                    expiredFeedbackTime: info.expired,
                  });
              }}
            />
          </ErrorBoundary>
        </GridItem>
      </GridContainer>
    );
  } else {
    return null;
  }
};

const Timer = (props) => {
  const { sendInfoToParent } = props;
  // console.log("render timer with props", props);

  const { feedback, setFeedback } = useContext(FeedbackContext);
  const { endDateFinalRef, hoursForFeedbackRef } = feedback;

  const [timeLeft, setTimeLeft] = useState("");

  const deadline = add(new Date(endDateFinalRef.current), {
    hours: hoursForFeedbackRef.current, //hoursToGiveFeedback,
  });
  // console.log("el deadline es", {
  //   endDateFinal: endDateFinal,
  //   endDateFinalConverted: new Date(endDateFinal),
  //   deadline: deadline,
  // });
  // console.log("el deadline es", deadline);
  // console.log("hoursToGiveFeedback", hoursToGiveFeedback);
  //   const timeLeft =
  const getTime = () => {
    // console.log("runs get time", { deadline });

    if (new Date() > deadline) {
      // console.log("expired");
      setTimeLeft("Expired");
      sendInfoToParent({ expired: true, timeLeft: "Expired" });
    } else {
      // console.log("not expired");
      setTimeLeft(timeDifference(new Date(), deadline, false));
      sendInfoToParent({ expired: false, timeLeft: timeLeft });
    }
  };

  useEffect(() => {
    // run counter only of deadline has not been reached and timeLeft has not been marked as expired
    // // if (new Date() < deadline) {
    // console.log("el deadline es", {
    //   deadline: deadline,
    //   currentTime: new Date(),
    // });
    if (new Date() > deadline) {
      // console.log("esta marcado como expirado");
      setTimeLeft("Expired");
      sendInfoToParent({ expired: true, timeLeft: "Expired" });
      return;
    }

    // console.log("el time leflllll", timeLeft);
    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  return (
    <div style={styles.timeLeftContainer}>
      <h5 style={styles.timeLeftLabel}>Time left to complete:</h5>

      <div style={styles.timeLeftIndicatorContainer}>
        {timeLeft.length === 0 ? (
          <ActivityIndicator size={20} color="red" />
        ) : (
          <h5 style={styles.timeLeftIndicator}>{timeLeft}</h5>
        )}
      </div>
    </div>
  );
};
