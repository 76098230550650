import { useEffect, useState, useContext } from "react";

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days =
    countDown > 0
      ? Math.floor(countDown / (1000 * 60 * 60 * 24))
      : Math.ceil(countDown / (1000 * 60 * 60 * 24));

  const hours =
    countDown > 0
      ? Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      : Math.ceil((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  const minutes =
    countDown > 0
      ? Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
      : Math.ceil((countDown % (1000 * 60 * 60)) / (1000 * 60));
  //   const minutes = Math.floor((countDown / 1000 / 60) % 60);
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  const total = Math.floor(countDown / 1000);

  //   return [
  //   { desc: "TOTAL", value: total },
  //   { desc: "DAYS", value: days },
  //   { desc: "HOURS", value: hours },
  //   { desc: "MINS", value: minutes },
  //   { desc: "SECS", value: seconds },
  // ];
  // console.log("%cTime ", "background-color: red; color: white", {
  //   days,
  //   hours,
  //   minutes,
  //   seconds,
  // });

  let output = [{ desc: "TOTAL", value: total }];
  // avoid showing days in 0
  days !== 0 && output.push({ desc: "DAYS", value: days });
  // avoid showing hours in 0
  hours !== 0 && output.push({ desc: "HOURS", value: hours });
  // output.push({ desc: "DAYS", value: days });
  // output.push({ desc: "HOURS", value: hours });
  output.push({ desc: "MINS", value: minutes });
  output.push({ desc: "SECS", value: seconds });

  return output;
  //   return [days, hours, minutes, seconds];
};

const useThrowAsyncError = () => {
  const [state, setState] = useState();

  return (error) => {
    setState(() => {
      throw error;
    });
  };
};

const useAutosizeTextArea = (textAreaRef, value, maxHeight) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      if (maxHeight) {
        if (scrollHeight > maxHeight) {
          textAreaRef.style.height = maxHeight + "px";
        } else {
          textAreaRef.style.height = scrollHeight + "px";
        }
      } else {
        // We then set the height directly, outside of the render loop
        // Trying to set this with state or a ref will product an incorrect value.
        textAreaRef.style.height = scrollHeight + "px";
      }
    }
  }, [textAreaRef, value]);
};

export { useCountdown, useThrowAsyncError, useAutosizeTextArea };
