import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { infoColor } from "assets/jss/material-dashboard-react.js";

export default function Multiface(props) {
  const colorMain = infoColor[5];

  const selectionOptions = {
    unselected: {
      face: colorMain,
      background: "white",
    },
    selected: {
      face: "white",
      background: colorMain,
    },
  };

  const selection =
    selectionOptions[props.selected === true ? "selected" : "unselected"];

  //   const faceOptions = {
  //     veryUnhappy: true,
  //     unhappy: true,
  //     neutral: true,
  //     happy: true,
  //     veryHappy: true,
  //   };

  //   let face;
  //   switch (props.rating) {
  //     case 1:
  //       face = selectionOptions.veryUnhappy;
  //       break;
  //     case 2:
  //       face = selectionOptions.unhappy;
  //       break;
  //     case 3:
  //       face = selectionOptions.neutral;
  //       break;
  //     case 4:
  //       face = selectionOptions.happy;
  //       break;
  //     case 5:
  //       face = selectionOptions.veryHappy;
  //       break;
  //   }

  return (
    <SvgIcon>
      <svg
        width="38"
        height="38"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="30.5"
          cy="30.5"
          r="29.5"
          fill={selection.background}
          stroke={selection.face}
          strokeWidth="3"
        />
        {/* left eye */}
        <path
          d="M20.5182 26.566C21.2651 24.763 20.4089 22.6959 18.6058 21.9491C16.8028 21.2023 14.7358 22.0585 13.989 23.8615C13.2421 25.6645 14.0983 27.7315 15.9013 28.4784C17.7043 29.2252 19.7714 28.369 20.5182 26.566Z"
          fill={selection.face}
        />
        {/* right eye */}
        <path
          d="M46.2567 27.7131C47.6366 26.3331 47.6366 24.0957 46.2567 22.7158C44.8767 21.3358 42.6393 21.3358 41.2594 22.7158C39.8794 24.0957 39.8794 26.3331 41.2594 27.7131C42.6393 29.093 44.8767 29.093 46.2567 27.7131Z"
          fill={selection.face}
        />
        {/* very happy */}
        <path
          d="M49.2598 41.7397C44.8881 48.0066 38.3192 51.6006 31.2293 51.6006C24.1395 51.6006 17.5706 48.0066 13.1989 41.7397C13.0781 41.5543 13.0028 41.3429 12.9792 41.1228C12.9556 40.9027 12.9844 40.6802 13.0631 40.4734C13.1419 40.2665 13.2684 40.0812 13.4324 39.9326C13.5964 39.7839 13.7932 39.6762 14.0068 39.618C14.4835 39.4149 15.0127 39.3699 15.5169 39.4897C15.9953 39.5876 16.4219 39.8559 16.7174 40.2447C20.3944 45.4772 25.6797 48.4747 31.2293 48.4747C36.7789 48.4747 42.0869 45.4545 45.7337 40.2447C46.0638 39.8683 46.5038 39.6052 46.9917 39.4928C47.4795 39.3803 47.9903 39.4241 48.4519 39.618C48.6654 39.6762 48.8622 39.7839 49.0262 39.9326C49.1902 40.0812 49.3167 40.2665 49.3955 40.4734C49.4743 40.6802 49.503 40.9027 49.4794 41.1228C49.4559 41.3429 49.3806 41.5543 49.2598 41.7397V41.7397Z"
          fill={props.rating === 5 ? selection.face : "transparent"}
        />
        {/* happy */}
        <path
          d="M40.0789 39.5416C39.7982 39.2673 39.4218 39.1129 39.0293 39.1113V39.1113C38.8334 39.1107 38.6393 39.1499 38.459 39.2265C38.2786 39.3031 38.1156 39.4154 37.9798 39.5567C37.0263 40.5945 35.8739 41.43 34.5911 42.0137C33.3083 42.5974 31.9213 42.9173 30.5125 42.9544C29.104 42.917 27.7176 42.5962 26.4359 42.0112C25.1542 41.4261 24.0036 40.5888 23.0526 39.5492C22.7707 39.2748 22.3928 39.1212 21.9993 39.1212C21.6059 39.1212 21.228 39.2748 20.946 39.5492C20.6716 39.8311 20.5181 40.209 20.5181 40.6025C20.5181 40.9959 20.6716 41.3738 20.946 41.6558C22.1716 42.977 23.6512 44.0373 25.2963 44.7729C26.9415 45.5085 28.7182 45.9044 30.52 45.9369C32.3229 45.9041 34.1006 45.5081 35.747 44.7726C37.3933 44.037 38.8743 42.9769 40.1015 41.6558C40.3752 41.3705 40.5261 40.9894 40.5219 40.5941C40.5177 40.1988 40.3586 39.821 40.0789 39.5416V39.5416Z"
          fill={props.rating === 4 ? selection.face : "transparent"}
        />
        {/* neutral */}
        <path
          d="M41.8635 41.1121C41.8635 41.9351 40.8274 42.6222 39.5612 42.6222H23.7984C22.5321 42.6222 21.4961 41.9502 21.4961 41.1121C21.4961 40.274 22.5321 39.6021 23.7984 39.6021H39.5612C40.8274 39.6172 41.8635 40.2891 41.8635 41.1121Z"
          fill={props.rating === 3 ? selection.face : "transparent"}
        />
        {/* unhappy */}
        <path
          d="M40.0864 42.5694C38.8594 41.2479 37.3785 40.1876 35.7321 39.452C34.0857 38.7164 32.3078 38.3206 30.5049 38.2883C28.7031 38.3207 26.9263 38.7166 25.2812 39.4522C23.6361 40.1879 22.1564 41.2481 20.9309 42.5694C20.6565 42.8513 20.5029 43.2292 20.5029 43.6227C20.5029 44.0161 20.6565 44.394 20.9309 44.6759C21.2128 44.9504 21.5908 45.1039 21.9842 45.1039C22.3776 45.1039 22.7555 44.9504 23.0375 44.6759C23.9884 43.6364 25.1391 42.799 26.4208 42.214C27.7025 41.6289 29.0889 41.3081 30.4973 41.2707C31.9069 41.3075 33.2947 41.6281 34.5777 42.2131C35.8607 42.7982 37.0126 43.6358 37.9647 44.6759C38.2441 44.9531 38.6207 45.1102 39.0142 45.1139C39.3038 45.1044 39.5845 45.0118 39.8229 44.8472C40.0613 44.6826 40.2473 44.4528 40.3587 44.1854C40.4702 43.918 40.5023 43.6241 40.4513 43.3389C40.4004 43.0537 40.2684 42.7892 40.0713 42.5769L40.0864 42.5694Z"
          fill={props.rating === 2 ? selection.face : "transparent"}
        />
        {/* very unhappy */}
        <path
          d="M49.5092 44.7598C45.1375 38.5156 38.5686 34.8914 31.4788 34.8914C24.3889 34.8914 17.8276 38.5156 13.4559 44.7598C13.335 44.9447 13.2595 45.1556 13.2354 45.3752C13.2113 45.5949 13.2394 45.8171 13.3174 46.0238C13.3954 46.2305 13.5211 46.4159 13.6842 46.565C13.8473 46.714 14.0433 46.8224 14.2562 46.8815C14.5598 47.0084 14.886 47.0726 15.2151 47.0702C15.4056 47.073 15.5958 47.0527 15.7814 47.0098C16.2614 46.9158 16.6893 46.6467 16.9819 46.2548C20.6288 41.0223 25.9141 38.0248 31.4788 38.0248C37.0434 38.0248 42.3363 41.045 45.9832 46.2548C46.3144 46.6318 46.7558 46.8952 47.245 47.0076C47.7341 47.1201 48.2462 47.076 48.7089 46.8815C48.9218 46.8224 49.1178 46.714 49.2809 46.565C49.444 46.4159 49.5697 46.2305 49.6477 46.0238C49.7256 45.8171 49.7538 45.5949 49.7297 45.3752C49.7056 45.1556 49.6301 44.9447 49.5092 44.7598Z"
          fill={props.rating === 1 ? selection.face : "transparent"}
        />
      </svg>
    </SvgIcon>
  );
}
