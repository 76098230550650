import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

// data
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  calculateSubscription,
  subscriptionPeriodList,
} from "graphql/gqlPayment";
import { object, number } from "yup";
import { SessionContext } from "contexts/SessionContext";

import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import CustomMainInput from "components/CustomMainInput/CustomMainInput";

import { Field } from "formik";
// custom components
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
// import styles from "assets/jss/material-dashboard-react/views/paymentStyle";
// icons

// style
import { grayColor } from "assets/jss/material-dashboard-react";

const styles = {
  divider: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  labelContainer: { flexDirection: "row" },
  labelNumbers: {
    textAlign: "right",
  },

  listItem: {
    // padding: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "space-between",
  },

  total: {
    textAlign: "right",
    fontWeight: 900,
  },

  subLabel: {
    fontSize: 12,
    color: grayColor[5],
  },
};

const useStyles = makeStyles(styles);

const PaymentSubscription = (props) => {
  const { session, setSession } = useContext(SessionContext);
  const { languageID } = session;
  const { stageID, validUntil, accountID, subtotal, discounts, taxes, total } =
    props.fprops.values;

  const { disabled } = props;
  // console.log("los props en review son", props);

  // ---------------------------------------------------
  // const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();
  // const [valuesFromCalc, setValuesFromCalc] = useState({
  //   validUntil: validUntil,
  //   // periodID: 1,
  //   subtotal: 0,
  //   discounts: 0,
  //   taxes: 0,
  //   total: 0,
  // });
  // console.log("los props en valuesFromCalc son", valuesFromCalc);
  const formatCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const [
    calculateSubscriptionQry,
    { loading: loadingCS, error: errorCS, refetch: refetchCS },
  ] = useLazyQuery(
    calculateSubscription,

    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        // console.log("form de formik", FormikProps);

        console.log(
          "los datos después del cambio son",
          data.spSubscriptionCalculate.nodes[0]
        );
        const {
          baseAmount,
          discountAmount,
          // periodDiscountAmount,
          // planDiscountAmount,
          taxAmount,
          validUntil,
          totalAmount,
        } = data.spSubscriptionCalculate.nodes[0];
        props.fprops.setFieldValue("subtotal", baseAmount);
        props.fprops.setFieldValue("taxes", taxAmount);
        props.fprops.setFieldValue("discounts", parseFloat(discountAmount));
        props.fprops.setFieldValue("total", totalAmount);
        props.fprops.setFieldValue("validUntil", validUntil);

        // setValuesFromCalc({
        //   ...valuesFromCalc,
        //   validUntil: validUntil,
        //   subtotal: parseFloat(baseAmount).toFixed(2),
        //   discounts: (
        //     parseFloat(periodDiscountAmount) + parseFloat(planDiscountAmount)
        //   ).toFixed(2),
        //   taxes: parseFloat(taxAmount).toFixed(2),
        //   total: parseFloat(totalAmount).toFixed(2),
        // });
      },
    }
  );

  // useEffect(() => {
  //   setValuesFromCalc({ ...valuesFromCalc, validUntil: validUntil });
  // }, [props]);

  const { loading, error, data, refetch } = useQuery(subscriptionPeriodList, {
    variables: { languageID: languageID },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  if (loading) return <ActivityIndicator fullscreen />;

  if (data && data.spSubscriptionPeriodList) {
    // console.log("la data en Payment Subscription es", data);
    const spacing = 4;
    const labels = [
      {
        id: 1,
        name: "Subtotal",
        value: subtotal,
      },
      {
        id: 2,
        name: "Discounts",
        value: discounts,
      },
      {
        id: 3,
        name: "Taxes",
        value: taxes,
      },
      {
        id: 4,
        name: "Total",
        value: total,
      },
    ];
    return (
      // <div style={{ paddingLeft: "50px" }}>
      <>
        {/* <Typography variant="h6" gutterBottom>
          Order summary
        </Typography> */}

        <GridContainer
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <GridItem xs={12} sm={6}>
            <GridContainer
              spacing={spacing}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <GridItem xs={12} sm={7}>
                <div className={classes.labelContainer}>
                  <h5>
                    {/* {[4, 5].includes(stageID) ? "Add slots" : "Total slots *"} */}
                    {[4].includes(stageID) ? "Add slots" : "Total slots *"}
                  </h5>
                  {/* {[4, 5].includes(stageID) && (
                    <h5 className={classes.subLabel}>
                      Current slot capacity is: {currentSlots}
                    </h5>
                  )} */}
                </div>
              </GridItem>
              <GridItem xs={12} sm={5}>
                <Field name="slots">
                  {({ field, form, meta }) => {
                    return (
                      <CustomMainInput
                        {...field} // retrieves all other, onBlur, onChange, etc
                        error={meta.touched ? meta.error : ""}
                        height="30px"
                        // label="Invoice name"
                        disabled={disabled}
                        name="slots"
                        variant="input"
                        // onFocus={() => {
                        //   form.setFieldValue("slots", "");
                        // }}
                        onBlur={(e) => {
                          field.onBlur(e);

                          console.log("las variables para el calculo son:", {
                            accountID: accountID,
                            slots: parseInt(form.values.slots),
                            couponCode: form.values.couponCode,
                            periodID: form.values.subscriptionPeriodID,
                          });
                          calculateSubscriptionQry({
                            variables: {
                              accountID: accountID,
                              slots: parseInt(form.values.slots),
                              couponCode: form.values.couponCode,
                              periodID: form.values.subscriptionPeriodID,
                            },
                          });
                        }}
                      />
                    );
                  }}
                </Field>
              </GridItem>
            </GridContainer>
            <GridContainer
              spacing={spacing}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <GridItem xs={12} sm={7}>
                <div className={classes.labelContainer}>
                  <h5>Period *</h5>
                  {stageID === 4 && (
                    <h6 className={classes.subLabel}>
                      Period can be changed until renewal period
                    </h6>
                  )}
                </div>
              </GridItem>
              <GridItem xs={12} sm={5}>
                <Field name="subscriptionPeriodID">
                  {({ field, form, meta }) => (
                    <CustomMainInput
                      error={meta.touched ? meta.error : ""}
                      disabled={disabled || stageID === 4}
                      dropDownList={data.spSubscriptionPeriodList.nodes}
                      // onChangeDropDownList={(item) => refetch()}

                      dropDownFunction={(item) => {
                        console.log("dropDownFunction result ", item);
                        form.setFieldValue("subscriptionPeriodID", item.id);
                        console.log(
                          "Values for calculation",

                          {
                            accountID: accountID,
                            slots: parseInt(form.values.slots || 0),
                            couponCode: form.values.couponCode,
                            periodID: item.id,
                          }
                        );
                        calculateSubscriptionQry({
                          variables: {
                            accountID: accountID,
                            slots: parseInt(form.values.slots || 0),
                            couponCode: form.values.couponCode,
                            periodID: item.id,
                          },
                        });
                      }}
                      height="30px"
                      // label="Expiry month"
                      name="subscriptionPeriodID"
                      value={form.values.subscriptionPeriodID}
                      variant="dropdown"
                    />
                  )}
                </Field>
              </GridItem>
            </GridContainer>
            <GridContainer spacing={spacing}>
              <GridItem xs={12} sm={7}>
                <div className={classes.labelContainer}>
                  <h5>Coupon</h5>
                </div>
              </GridItem>
              <GridItem xs={12} sm={5}>
                <Field name="couponCode">
                  {({ field, form, meta }) => {
                    return (
                      <CustomMainInput
                        {...field} // retrieves all other, onBlur, onChange, etc
                        error={meta.touched ? meta.error : ""}
                        height="30px"
                        // label="Invoice name"
                        disabled={disabled}
                        name="couponCode"
                        variant="input"
                        onFocus={() => form.setFieldValue("couponCode", "")}
                        onBlur={(e) => {
                          field.onBlur(e);
                          // form.setFieldValue("subtotal", 23.8);
                          console.log("las variables para el calculo son:", {
                            accountID: accountID,
                            slots: parseInt(form.values.slots),
                            couponCode: form.values.couponCode,
                            periodID: form.values.subscriptionPeriodID,
                          });
                          calculateSubscriptionQry({
                            variables: {
                              accountID: accountID,
                              slots: parseInt(form.values.slots),
                              couponCode: form.values.couponCode,
                              periodID: form.values.subscriptionPeriodID,
                            },
                          });
                        }}
                      />
                    );
                  }}
                </Field>
              </GridItem>
            </GridContainer>

            <GridContainer spacing={spacing}>
              <GridItem xs={12} sm={7}>
                <div className={classes.labelContainer}>
                  <h5>Valid until</h5>
                </div>
              </GridItem>
              <GridItem xs={12} sm={5}>
                <Field name="couponCode">
                  {({ field, form, meta }) => {
                    return (
                      <CustomMainInput
                        {...field} // retrieves all other, onBlur, onChange, etc
                        error={meta.touched ? meta.error : ""}
                        height="30px"
                        // label="Invoice name"
                        disabled
                        name="couponCode"
                        variant="input"
                        value={validUntil}
                      />
                    );
                  }}
                </Field>
              </GridItem>
            </GridContainer>
          </GridItem>

          <Grid
            item
            xs={1}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Divider orientation="vertical" />
          </Grid>

          <GridItem xs={12} sm={5}>
            {labels.map((item, index) => {
              return (
                <GridContainer spacing={spacing} key={item.id}>
                  <GridItem xs={12} sm={7}>
                    <div className={classes.labelContainer}>
                      <h5>{item.name}</h5>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <h5
                      className={
                        item.id === 4 ? classes.total : classes.labelNumbers
                      }
                    >
                      {formatCurrency.format(item.value)}
                    </h5>
                  </GridItem>
                </GridContainer>
              );
            })}
          </GridItem>
          {/* <pre>{JSON.stringify(Field, null, 2)}</pre> */}
        </GridContainer>
      </>
    );
  }
};
export default PaymentSubscription;

PaymentSubscription.label = "Subscription";
// Review.initialValues = {
//   slots: 0,
//   periodID: 0,
// };

PaymentSubscription.validationSchema = object().shape({
  // currentSlots: number(),
  slots: number()
    .integer("Number without decimals")
    .typeError("Only numbers")
    .positive("Only positive number")
    .min(5, "Minimum is 5")

    // .moreThan(5, "Must be at least 5")
    .required("Required")
    // .test("is-greater", "Date can't be in the past", function () {
    //   console.log("el valor es", slots);
    //   // const daysDifference = differenceInCalendarDays(
    //   //   newDate,
    //   //   new Date()
    //   // )

    //   // evaluates if the date is in the future only when status is draft or confirmed.
    //   // when the meeting has started, start date can be in the past
    //   return true;
    // }),
    // .moreThan(ref("currentSlots"), "Debe ser mayor a cantidad actual"),

    .when(["currentSlots", "stageID"], (currentSlots, stageID, schema) => {
      console.log("values", { currentSlots, stageID });
      // if (stageID === 4) {
      //   return schema.test({
      //     test: (slots) => slots > currentSlots,
      //     message: `Must be higher than current slots: ${currentSlots}`,
      //   });
      // }
    }),

  subscriptionPeriodID: number()
    .required("Required")
    .notOneOf([0], "Select a country"),
});
