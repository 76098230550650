import { gql } from "@apollo/client";

export const dashboardCharts = gql`
  query dashboardCharts($userID: Int!, $startDate: Date, $endDate: Date) {
    c1: spDashboardChartsCompany(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 1
    ) {
      nodes {
        desc
        value1
      }
    }
    c2: spDashboardChartsCompany(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 2
    ) {
      nodes {
        desc
        value1
      }
    }
    c3: spDashboardChartsCompany(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 3
    ) {
      nodes {
        desc
        value1
      }
    }
    c4: spDashboardChartsCompany(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 4
    ) {
      nodes {
        desc
        value1
      }
    }
    sumMeetingCost: spDashboardChartsCompany(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 5
    ) {
      nodes {
        dates
        value1
      }
    }
  }
`;

// export const dashboardCharts = gql`
//   query dashboardCharts($userID: Int!, $startDate: Date, $endDate: Date) {
//     topOrganizerRating: spDashboardChartsCompany(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 1
//     ) {
//       nodes {
//         desc
//         value1
//       }
//     }
//     topOrganizerCostPerRating: spDashboardChartsCompany(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 2
//     ) {
//       nodes {
//         desc
//         value1
//       }
//     }
//     topOrganizerUncompletedReview: spDashboardChartsCompany(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 3
//     ) {
//       nodes {
//         desc
//         value1
//       }
//     }
//     topAttendeeUncompletedReview: spDashboardChartsCompany(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 4
//     ) {
//       nodes {
//         desc
//         value1
//       }
//     }
//     sumMeetingCost: spDashboardChartsCompany(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 5
//     ) {
//       nodes {
//         dates
//         value1
//       }
//     }
//   }
// `;
