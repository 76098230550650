import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";

// core components
import HelpDeskListSub from "./HelpDeskListSub";

// data
import { SessionContext } from "../../contexts/SessionContext";
import { HelpDeskContext } from "contexts/HelpDeskContext";

// errors
import { logError, useDataForLog } from "functions/ErrorBoundary";

// icons

// style
import { makeStyles } from "@material-ui/core/styles";

import {
  grayColor,
  primaryColor,
  defaultFont,
} from "assets/jss/material-dashboard-react";

const styles = {
  emptyContainer: {
    // height: "100%",
    // flex: 4,
    // maxHeight: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: grayColor[5],
  },
  nonEmptyContainer: {
    // flex: 1,
    overflowY: "auto",
  },
};
const useStyles = makeStyles(styles);

export default function HelpDeskList(props) {
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { data, selector } = props;

    const { helpDesk } = useContext(HelpDeskContext);
    const { loading } = helpDesk;

    const [errorState, setErrorState] = useState();
    // const { session, setSession } = useContext(SessionContext);
    // const { languageID, userID } = session;

    // const { helpDesk, setHelpDesk } = useContext(HelpDeskContext);
    // const {} = helpDesk;

    if (data.length === 0 && !loading) {
      return (
        <div className={classes.emptyContainer}>
          <h3>No tickets to display</h3>
        </div>
      );
    }

    return (
      <div className={classes.nonEmptyContainer}>
        <HelpDeskListSub data={data} selector={selector} />
      </div>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
