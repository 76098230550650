import { useState, useRef, useContext } from "react";
import cloneDeep from "lodash/cloneDeep";

// data
import axios from "axios";
import { convertLocalToUTC, debounce, axiosOptions } from "functions/Common";
import config from "config";
import { MeetingContext } from "contexts/MeetingContext";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";

// https://www.youtube.com/watch?v=NZKUirTtxcg
export default function useSaveAgenda(props, afterSaving) {
  // const [refreshData, setRefreshData] = useState(false);
  // const [newAgendaItemID, setNewAgendaItemID] = useState(null);
  // const [isProcessing, setIsProcessing] = useState(true);

  const generalData = useDataForLog(); // context data to populate error log

  const tempContentRef = useRef();

  const saveAgenda = (props) => {
    console.log(
      "%cStart save agenda",
      "background-color: red; color: white",
      props
    );
    // console.log("tempContentRef.current", tempContentRef.current);

    // return null;
    const {
      agendaItems,
      content,
      infoToChange,
      loadingTemplate,
      meeting,
      newAgendaItems,
      occurrenceRepeatType,
      parentEventID,
      saveRecurrence,
      selectedItem,
      setContent,
      setMeeting,

      withDurations,
      userID,
    } = props;

    // const { agendaItems } = content;
    const { meetingID, startDate, statusID } = meeting;

    // change ui inmediately.  Not included after db update to wait for fetch
    // console.log(
    //   "%cChange in setContent in useSaveAgenda",
    //   "background-color: blue; color: white",
    //   {
    //     ...content,
    //     ...infoToChange,
    //   }
    // );
    // if loading templates or changing agenda after invitation (has changed), new agendaItems is provided
    // if reordering items, start using withDurations or any othe operation
    let agendaItemsToUse;
    if (agendaItems !== undefined) {
      agendaItemsToUse = [...agendaItems];
    } else {
      agendaItemsToUse = [...content.agendaItems];
    }
    // updates inmediately agenda items, particularly when reordering because fetch would make it jump
    setContent({
      ...content,
      agendaItems: agendaItemsToUse,
    });

    // if withDurations is changed, modifies the meeting context
    if (withDurations !== undefined) {
      setMeeting({ ...meeting, withDurations: withDurations });
    }

    const options = axiosOptions({
      url: `${config.url}/api/meeting-save-encrypted`,
      method: "post",
      data: {
        agendaItems: agendaItemsToUse,
        loadingTemplate: loadingTemplate || false,
        meetingID: meetingID,
        occurrenceRepeatType: occurrenceRepeatType,
        parentEventID: parentEventID,
        statusID: statusID,
        userID: userID,
        withDurations:
          withDurations === undefined ? content.withDurations : withDurations, // use the withDurations modified, otherwise the existing value
        // startDate: convertLocalToUTC(startDate),
        // content: info,
      },
    });
    console.log("options axios", options);

    axios(options)
      .then(async (response) => {
        // refetch();
        console.log(
          "%c----------------db update in save agenda -----------------------",
          "background-color: green; color: yellow",
          response.data
        );
        // hideActivityIndicator();

        const meetingIDReturned = response.data.info.meeting_id;
        if (meetingID !== meetingIDReturned) {
          console.log("updates the meeting id");
          setMeeting({ ...meeting, meetingID: meetingIDReturned });
        }

        // convert to camelCase
        const agendaItemsProcessed =
          response.data.info.agenda_items === null
            ? null
            : response.data.info.agenda_items.map((item, index) => {
                return {
                  ...item,
                  meetingID: item.meeting_id,
                  orderBy: item.order_by,
                  privateNotes: item.private_notes,
                  dayID: item.day_id,
                  typeID: item.type_id,
                };
              });

        console.log("newCotnent", {
          ...content,
          agendaItems: loadingTemplate
            ? agendaItemsProcessed // if loading template, the agenda items change completely
            : agendaItemsToUse,
          withDurations: withDurations,
          selectedItem: selectedItem || content.selectedItem,
        });
        setContent({
          ...content,
          refreshData: loadingTemplate ? new Date() : content.refreshData, // if loading template, force fetching agenda details.  Simple render won't fetch as agenda item id and selected item are not changing
          agendaItems: loadingTemplate
            ? agendaItemsProcessed // if loading template, the agenda items change completely
            : agendaItemsToUse,
          // withDurations:
          //   withDurations === undefined ? content.withDurations : withDurations, // use the withDurations modified, otherwise the existing value
          selectedItem: selectedItem || content.selectedItem,
        });
      })
      .catch((error) => {
        logError(error, {
          errorLevel: "fatal",
          generalData: { ...generalData },
        });
      });

    // if (
    //   meetingID === null &&
    //   occurrenceRepeatType === undefined &&
    //   saveRecurrence === undefined
    // ) {
    //   // when accessing a future occurrence and the occurrenceRepeatType is not provided
    //   // which means it's autosaving.  When saving from the button, the occurrenceRepeatType is provided
    //   // and this part of the if statement is not executed
    //   // refetch();

    //   // if withDurations is changed, modifies the meeting context
    //   if (withDurations !== undefined) {
    //     setMeeting({ ...meeting, withDurations: withDurations });
    //   }
    // } else {

    //   const options = axiosOptions({
    //     url: `${config.url}/api/meeting-save-encrypted`,
    //     method: "post",
    //     data: {
    //       agendaItems: agendaItemsToUse,
    //       loadingTemplate: loadingTemplate || false,
    //       meetingID: meetingID,
    //       occurrenceRepeatType: occurrenceRepeatType,
    //       parentEventID: parentEventID,
    //       statusID: statusID,
    //       userID: userID,
    //       withDurations:
    //         withDurations === undefined ? content.withDurations : withDurations, // use the withDurations modified, otherwise the existing value
    //       // startDate: convertLocalToUTC(startDate),
    //       // content: info,
    //     },
    //   });
    //   console.log("options axios", options);

    //   axios(options)
    //     .then(async (response) => {
    //       // refetch();
    //       console.log(
    //         "%c----------------db update in save agenda -----------------------",
    //         "background-color: green; color: yellow",
    //         response.data
    //       );
    //       // hideActivityIndicator();

    //       const meetingIDReturned = response.data.info.meeting_id;
    //       if (meetingID !== meetingIDReturned) {
    //         console.log("updates the meeting id");
    //         setMeeting({ ...meeting, meetingID: meetingIDReturned });
    //       }

    //       // if withDurations is changed, modifies the meeting context
    //       if (withDurations !== undefined) {
    //         setMeeting({ ...meeting, withDurations: withDurations });
    //       }

    //       // convert to camelCase
    //       const agendaItemsProcessed =
    //         response.data.info.agenda_items === null
    //           ? null
    //           : response.data.info.agenda_items.map((item, index) => {
    //               return {
    //                 ...item,
    //                 meetingID: item.meeting_id,
    //                 orderBy: item.order_by,
    //                 privateNotes: item.private_notes,
    //                 dayID: item.day_id,
    //                 typeID: item.type_id,
    //               };
    //             });

    //       console.log("newCotnent", {
    //         ...content,
    //         agendaItems: loadingTemplate
    //           ? agendaItemsProcessed // if loading template, the agenda items change completely
    //           : agendaItemsToUse,
    //         withDurations: withDurations,
    //         selectedItem: selectedItem || content.selectedItem,
    //       });
    //       setContent({
    //         ...content,
    //         refreshData: loadingTemplate ? new Date() : content.refreshData, // if loading template, force fetching agenda details.  Simple render won't fetch as agenda item id and selected item are not changing
    //         agendaItems: loadingTemplate
    //           ? agendaItemsProcessed // if loading template, the agenda items change completely
    //           : agendaItemsToUse,
    //         withDurations:
    //           withDurations === undefined
    //             ? content.withDurations
    //             : withDurations, // use the withDurations modified, otherwise the existing value
    //         selectedItem: selectedItem || content.selectedItem,
    //       });
    //     })
    //     .catch((error) => {
    //       logError(error, {
    //         errorLevel: "fatal",
    //         generalData: { ...generalData },
    //       });
    //     });
    // }
  };
  return {
    saveAgenda,
    tempContentRef,
  };
}
