// styles
import { makeStyles } from "@material-ui/core/styles";
// MUI components
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

const styles = {
  avatarContainer: { marginLeft: "-14px" },
  avatar: {
    borderColor: "white",
    borderWidth: "2px",
    borderStyle: "solid",
  },
  sm: {
    height: "25px",
    width: "25px",
  },
  md: {
    height: "30px",
    width: "30px",
  },
  lg: {
    height: "35px",
    width: "35px",
  },
};
const useStyles = makeStyles(styles);

export default function CustomAvatar(props) {
  const { person, size = "sm" } = props;
  const classes = useStyles();
  // console.log("presons", persons);
  const sizeClass = () => {
    switch (size) {
      case "sm":
        return classes.sm;
      case "md":
        return classes.md;
      case "lg":
        return classes.lg;
    }
  };

  return (
    <div className={classes.avatarContainer}>
      <Tooltip title={person.name || person.menuDesc} placement="bottom">
        <Avatar
          className={`${classes.avatar} ${sizeClass()}`}
          alt={person.name || person.menuDesc}
          src={person.imagePath}
        />
      </Tooltip>
    </div>
  );
}
