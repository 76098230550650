import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor,
  defaultFont,
  defaultBoxShadow,
} from "assets/jss/material-dashboard-react.js";

const customMainInputStyle = {
  activityListContainer: {
    // paddingTop: "10px",
    display: "flex",
    justifyContent: "center",
    top: 0,
    backgroundColor: "rgba(0,0,0,0.05)",
    position: "absolute",

    width: "100%",
    height: "100%",

    alignItems: "center",
  },
  avatar: {
    borderColor: "white",
    borderWidth: "2px",
    borderStyle: "solid",
    height: "30px",
    width: "30px",
    marginRight: "5px",
  },

  chip: {
    ...defaultFont,
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: "5px",
    marginRight: "5px",
    color: "white",
    "&$primary": {
      backgroundColor: primaryColor[6],
      borderColor: primaryColor[6],
      "&:focus": {
        backgroundColor: primaryColor[6],
        borderColor: primaryColor[6],
      },
    },
  },
  chipIcon: {
    color: "white",
  },
  labelGroup: {
    display: "flex",
    flexDirection: "row",
  },
  datePickerCurrentDate: { color: "red" },
  datePickerPopper: { zIndex: 100 },
  datePickerWrapper: { width: "100%" },
  descriptionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "end",
    // backgroundColor: "lightBlue",
    // height: "25px", // force height in order to have the same with our without the right icon
  },
  divider: {
    marginTop: "5px",
    marginBottom: "5px",
  },

  dropDownItemCustom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "5px 15px",
    margin: "0 5px",
    // backgroundColor: "red",
    borderRadius: "2px",
    WebkitTransition: "all 50ms linear",
    MozTransition: "all 50ms linear",
    OTransition: "all 50ms linear",
    MsTransition: "all 50ms linear",
    transition: "all 50ms linear",
    // display: "block",
    clear: "both",
    fontWeight: "400",
    lineHeight: "1.8",
    // lineHeight: "24px",
    color: grayColor[0],
    whiteSpace: "nowrap",
    // height: "unset",
    height: "45px", // if this value changes,
    minHeight: "unset",
    "&:hover": {
      backgroundColor: grayColor[8],
      // color: whiteColor,
      // ...primaryBoxShadow,
    },
  },
  dropDownEmptyListLabel: {
    ...defaultFont,
    fontSize: "13px",
    padding: "3px 15px",
    margin: "0 5px",
    color: grayColor[0],
  },
  dropDownItem: {
    ...defaultFont,

    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start", // estaba en space-between
    // backgroundColor: "yellow",

    // flexDirection: "column",
    padding: "3px 15px",
    margin: "0 5px",
    borderRadius: "2px",
    WebkitTransition: "all 150ms linear",
    MozTransition: "all 150ms linear",
    OTransition: "all 150ms linear",
    MsTransition: "all 150ms linear",
    transition: "all 150ms linear",
    // display: "block",
    clear: "both",
    fontWeight: "400",
    // lineHeight: "1",
    color: grayColor[0],
    whiteSpace: "nowrap",
    height: "unset",
    minHeight: "unset",
    "&:hover": {
      backgroundColor: primaryColor[8],
      // color: whiteColor,
      // ...primaryBoxShadow,
    },
  },
  dropDownLabel: {
    // display: "flex",
    textAlign: "left",
    flex: 1,
    cursor: "default",

    // avoids text overflow and place ... instead
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",

    // backgroundColor: "yellow",
    // fontSize: "12px",
    lineHeight: "21px",
    margin: "0px",
    padding: "0px 0px 0px 10px", // this is used for example the selectors in the meeting customer header //"0px 0px 0px 10px",
    whiteSpace: "nowrap",
    // lineHeight: "10px",
    "&$primary": {
      color: primaryColor[4],
    },
    "&$gray": {
      color: grayColor[0],
    },
    "&$dropDownPlaceholder": {
      color: grayColor[0],
    },
  },

  dropDownMenuItemLabel: {
    fontSize: "12px",
    color: grayColor[1],
    paddingRight: "15px",
    margin: "5px 0px",
    textOverflow: "ellipsis" /* enables ellipsis */,
    whiteSpace: "nowrap" /* keeps the text in a single line */,
    overflow: "hidden" /* keeps the element from overflowing its parent */,
  },
  dropDownMenuItemContainer: {
    flexDirection: "row",
    alignItems: "end",
    padding: "3px",
    "&:hover": {
      backgroundColor: grayColor[9],
    },
  },
  dropDownMenuItemContainerNew: {
    scrollbarGutter: "stable",
    flexDirection: "row",
    alignItems: "end",
    padding: "3px 20px 3px 3px",
    "&:hover": {
      backgroundColor: grayColor[9],
    },
  },
  dropDownPlaceholder: {},
  dropDownList: {
    padding: "0px",
    // backgroundColor: "red",
    // backgroundColor: "yellow",
    minWidth: "100px",
    margin: "0px",
    // width: "300px",
  },
  dropDownListCustom: {
    overflowY: "auto",
    // minHeight: "200px",
    maxHeight: "420px",
    // minWidth: "350px",

    position: "fixed",

    backgroundColor: "white",
    // display: "flex",
    // flexDirection: "row",
    borderRadius: "5px",
    marginTop: "5px",
    // marginLeft: "-20px",
    // backgroundColor: "orange",
    // position: "absolute",
    zIndex: 100,
    ...defaultBoxShadow,
    // boxShadow:
    //   "0 2px 2px 0 rgba(" +
    //   hexToRgb(grayColor[3]) +
    //   ", 0.14), 0 3px 1px -2px rgba(" +
    //   hexToRgb(grayColor[3]) +
    //   ", 0.2), 0 1px 5px 0 rgba(" +
    //   hexToRgb(grayColor[3]) +
    //   ", 0.12)",
  },
  dropDownTimeListCustom: {
    overflowY: "auto",
    minHeight: "200px",
    maxHeight: "420px",
    minWidth: "150px",
    position: "absolute",
    backgroundColor: "white",
    borderRadius: "5px",
    marginTop: "20px",

    ...defaultBoxShadow,
  },
  dropDownMainContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  dropDownContainer: {
    display: "flex",
    flex: 1,
    width: "100%",
    // backgroundColor: "red",
    flexDirection: "row",
    alignItems: "center",
    margin: "0px",
    padding: "0px",
    cursor: "pointer",
    lineHeight: "24px",
  },
  errorContainer: {
    // height: "0px",
  },
  errorLabel: {
    ...defaultFont,
    fontSize: "10px",
    fontWeight: 500,
    color: "red",
    paddingLeft: "10px",
  },
  iconAddChip: {
    display: "flex",
    marginTop: ".2rem", // required to align with the same as label
    color: primaryColor[6],
    "&:hover": {
      color: primaryColor[3],
    },
  },
  iconEndContainer: {
    display: "flex",
    margin: "0px",
    padding: "0px 0px 0px 0px",
    alignSelf: "center",
    "&$primary": {
      color: primaryColor[4],
    },
    "&$gray": {
      color: grayColor[4],
    },
  },
  iconStartContainer: {
    display: "flex",
    margin: "0px",
    padding: "0px 0px 0px 10px",
    alignItems: "center",
    alignSelf: "center",
    "&$primary": {
      color: primaryColor[4],
    },
    "&$gray": {
      color: grayColor[4],
    },
  },

  iconOnlyContainer: {
    padding: "0px 0px",
  },

  iconCheckContainer: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    // marginRight: "1px",

    borderRadius: "80px",
    // padding: "0px 7px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "stretch",
    flexDirection: "row",
  },
  iconCheckDoubleContainer: {
    width: "40px",
  },

  iconCheckContainerTrue: {
    backgroundColor: successColor[6],
    border: `1px solid white`,
  },
  iconCheckContainerFalse: {
    backgroundColor: dangerColor[6],
    border: `1px solid white`,
  },
  iconCheckContainerNull: {
    backgroundColor: grayColor[7],
    border: `1px solid white`,
  },
  calendar: {
    position: "absolute",
    backgroundColor: "red",
    top: "100%",
  },

  input: {
    ...defaultFont,
    lineHeight: "30px",
    width: "100%",
    border: `1px solid rgba(0, 0, 0, 0)`, // place to avoid jumps when using transparent that also has a border
    borderRadius: "5px",
    boxSizing: "border-box", // used to include border within input dimensions and not added
    fontWeight: 500,
    // borderWidth: "0px",
    backgroundColor: "rgba(0,0,0,0)",
    paddingLeft: "5px",
    paddingRight: "5px",
    // padding: "5px",
    resize: "none", // when using textarea removes resize handle
    // "&:hover": {
    // backgroundColor: "yellow",
    // },
    "&$red": {
      borderWidth: "0px",
      "&::placeholder": {
        color: "red",
        opacity: 1,
      },
    },

    "&$primary": {
      color: primaryColor[5],
      "&:hover": { backgroundColor: primaryColor[9] },
      // "&:focus": { borderWidth: "0px" },
      "&::placeholder": {
        color: primaryColor[5],
        opacity: 1,
      },
    },
    "&$gray": {
      // "&:hover": { backgroundColor: grayColor[8] },
      // "&:focus": { borderWidth: "2px" },
      "&::placeholder": {
        color: grayColor[5],
        opacity: 1,
      },
    },

    "&$transparent": {
      boxSizing: "border-box",
      // backgroundColor: "yellow",
      "&:hover, &:focus": {
        backgroundColor: grayColor[9],
        border: `1px solid ${grayColor[6]}`,
      },
    },
  },
  inputHover: {
    "&$primary": {
      "&:hover": {},
    },
    "&$transparent": {
      "&:hover, &:focus": {
        backgroundColor: "rgba(0,0,0,0)",
        border: `1px solid rgba(0, 0, 0, 0)`,
        cursor: "default",
        // pointerEvents: "none",
      },
    },
  },

  inputMenu: {
    ...defaultFont,

    fontSize: "12px",
    fontWeight: 500,
    borderWidth: "0px",
    // borderRadius: "5px",
    // borderColor: grayColor[7],
    backgroundColor: "rgba(0,0,0,0)",

    marginLeft: "10px",
    marginRight: "10px",
    // paddingBottom: "5px",
    resize: "none", // when using textarea removes resize handle
    "&:focus": {
      // borderWidth: "2px",
      borderWidth: "0px 0px 1px 0px",
      borderColor: grayColor[4],
      backgroundColor: grayColor[9],
    },
    "&::placeholder": {
      color: grayColor[5],
    },
    // "&:hover": {
    //   backgroundColor: grayColor[8],
    //   // color: whiteColor,
    //   // ...primaryBoxShadow,
    // },
    // "&$primary": {
    //   "&:hover": { backgroundColor: primaryColor[9] },
    //   // "&:focus": { borderWidth: "0px" },
    //   "&::placeholder": {
    //     color: primaryColor[5],
    //     opacity: 1,
    //   },
    // },
    // "&$gray": {
    //   // "&:hover": { backgroundColor: grayColor[8] },
    //   // "&:focus": { borderWidth: "2px" },
    //   "&::placeholder": {
    //     color: grayColor[5],
    //     opacity: 1,
    //   },
    // },
  },

  inputUnderline: {
    // borderWidth: "1px",
    // borderStyle: "solid",
    borderWidth: "0px 0px 1px 0px",
    borderColor: grayColor[6],
    borderStyle: "solid",
  },

  inputContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    // backgroundColor: "yellow",
  },

  noInputContainer: {
    display: "flex",
    // flex: 1,
    // alignItems: "center",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },

  label: {
    ...defaultFont,
    fontSize: "12px",
    paddingLeft: "10px",
    color: grayColor[0],
    "&$red": {
      color: "red",
    },
    marginTop: ".2rem",
  },

  mainContainer: {
    // height: "calc(100% - 18px)", // just added, substracts the height of the description box

    display: "flex",
    width: "100%",
    flex: 1,
    flexDirection: "row",
    // maxWidth: "400px",
    alignItems: "stretch",
    padding: "0px 0px",
    // margin: ".2rem",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    backgroundColor: "white",
    // backgroundColor: "orange",

    "&$primary": {
      borderColor: primaryColor[6],
      "&:hover": { backgroundColor: primaryColor[8] },
    },
    "&$gray": {
      borderColor: grayColor[5],
      "&:hover": { backgroundColor: grayColor[8] },
    },
    "&$disabled": {
      borderColor: grayColor[5],
      backgroundColor: grayColor[8],
      // backgroundColor: "orange",
    },
    "&$transparent": {
      borderColor: "rgb(0,0,0,0)",
      backgroundColor: "rgb(0,0,0,0)",
      // "&:hover": {
      //   backgroundColor: "white",
      //   border: `1px solid ${grayColor[7]}`,
      // },

      // backgroundColor: "orange",
    },
  },

  mainContainerFiltered: {
    "&$primary": {
      backgroundColor: primaryColor[8],
    },
  },

  mainContainerInput: {
    display: "flex",
  },

  mainContainerChip: {
    // display: "block",
    flexWrap: "wrap",
    // rowGap: "30px",
    padding: "10px",
  },
  noInputLabel: {
    // display: "flex",
    color: grayColor[2],
    textAlign: "left",
    flex: 1,
    cursor: "default",

    // avoids text overflow and place ... instead
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",

    // backgroundColor: "yellow",
    // fontSize: "12px",
    lineHeight: "24px",
    margin: "0px",
    padding: "0px 10px 0px 10px", // this is used for example the selectors in the meeting customer header //"0px 0px 0px 10px",
    whiteSpace: "nowrap",
    // lineHeight: "10px",
    "&$primary": {
      color: primaryColor[4],
    },
    "&$gray": {
      color: grayColor[0],
    },

    // margin: "0px",
    // fontSize: "12px",
    // fontWeight: 500,
    // borderWidth: "0px",
    // backgroundColor: "rgba(0,0,0,0)",
    // paddingLeft: "10px",
    // paddingRight: "10px",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // color: grayColor[0],
    // "&$primary": {
    //   color: primaryColor[5],
    //   "&:hover": { backgroundColor: primaryColor[9] },
    //   // "&:focus": { borderWidth: "0px" },
    //   "&::placeholder": {
    //     color: primaryColor[5],
    //     // opacity: 1,
    //   },
    // },
    // "&$disabled": {
    //   color: grayColor[4],
    // },
  },

  pickerContainer: {
    // ...defaultFont,
    color: grayColor[1],
    margin: "0px",
    // backgroundColor: "yellow",
    borderWidth: "0px",
    // width: "100%",
    textAlign: "left",
    // height: "100%",
    display: "flex",
    alignItems: "center",
    flex: 1,
    paddingLeft: "10px",
    // alignItems: "stretch",
    "&$pickerPlaceholder": {
      color: grayColor[5],
    },
  },
  pickerPlaceholder: {},
  searchContainer: { margin: "5px 20px 5px 20px" },
  primary: {},
  disabled: {},
  transparent: {},

  red: {
    borderColor: "red",
    borderWidth: "2px",
    color: "red",
  },

  gray: {},
  grayDark: {
    backgroundColor: grayColor[5],
    borderColor: grayColor[5],
  },
  rightMargin: { marginRight: "10px" },

  topContainer: {
    height: "100%", // reactivated, required in attendees meeting details
  },
  test: {
    width: "50px",
    minHeight: "1px",
    backgroundColor: "yellow",
  },
  alignRight: {
    textAlign: "right",
  },
};

export default customMainInputStyle;
