import { gql } from "@apollo/client";
export const ticketsList = gql`
  query ticketsList(
    $userID: Int
    $languageID: String
    $pageSize: Int!
    $cursor: Cursor
  ) {
    spHelpdeskListOpen: spHelpdeskList(
      langShort1: $languageID
      createdBy1: $userID
      statusGroupId1: [1, 2]
    ) {
      nodes {
        category
        categoryId
        createdDate
        id
        priority
        priorityId
        statusGroup
        statusGroupId
        subject
        userId
        userName
      }
    }
    spHelpdeskListClosed: spHelpdeskList(
      langShort1: $languageID
      createdBy1: $userID
      statusGroupId1: [3]
      first: $pageSize
      after: $cursor
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        category
        categoryId
        createdDate
        id
        priority
        priorityId
        statusGroup
        statusGroupId
        subject
        userId
        userName
      }
    }
  }
`;
