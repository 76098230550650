import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const headerStyle = () => ({
  appBar: {
    // backgroundColor: "blue",
    backgroundColor: "transparent",
    flex: 1,
    boxSizing: "border-box",
    boxShadow: "none",
    border: "0",
    borderRadius: "3px",
    borderBottom: "0",
    color: grayColor[1],
    display: "block",
    // marginBottom: "0",
    paddingTop: "10px",
    position: "relative",
    width: "100%",
    zIndex: 500,
    // padding: "10px 0",
    transition: "all 150ms ease 0s",
    // minHeight: "30px",
  },
  container: {
    ...container,
    minHeight: "30px",
    paddingLeft: "30px",
  },
  currentLocationHeader: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    zIndex: 100,
  },
  title: {
    ...defaultFont,
    fontWeight: 400,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "22px",
    borderRadius: "3px",
    textTransform: "none",
    color: grayColor[4], //"inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  appResponsive: {
    top: "8px",
  },
  primary: {
    backgroundColor: primaryColor[4],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[4],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[1],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[1],
    color: whiteColor,
    ...defaultBoxShadow,
  },
});

export default headerStyle;
