import React from "react";
// import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// material ui components
import Avatar from "@material-ui/core/Avatar";
// icons
import FeatherIcon from "feather-icons-react";
// styles
import { makeStyles } from "@material-ui/core/styles";
// custom components
import Button from "../../components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.warning.dark,
  },
  title: {
    color: theme.palette.secondary.main,
    margin: "30px 0px",
  },
}));

export default function EmailConfirmationExpired() {
  // const { t } = useTranslation(["main"]);
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      {/* {redirect ? <Redirect from="/passwordrecovery" to="/" exact /> : null} */}

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          {<FeatherIcon icon="thumbs-up" />}
        </Avatar>
        <h3>Correo electrónico validado</h3>
        <Button color="primary" onClick={() => navigate("/")}>
          Ingresar
        </Button>
      </div>
    </>
  );
}
