import React, { createContext, useState, useContext } from "react";
import config from "config";
import { io } from "socket.io-client";
import { SessionContext } from "../contexts/SessionContext";

export const SocketContext = createContext();
console.log("urlToSocket:", config.urlToSocket);

// let socket = null;
// const socket = () => {
//   const { session } = useContext(SessionContext);
//   console.log("el user id es:", session.userID);
//   const calc = io(config.urlToSocket, {
//     query: { userID: 16 },
//   });
//   return calc;
// };
// const socketResult = socket();
const socket = io(config.urlToSocket);

export const SocketInfo = (props) => {
  // const { session } = useContext(SessionContext);
  // console.log("el user id es:", session.userID);
  console.log("************ el socket en el context es:", socket);
  return (
    <SocketContext.Provider value={{ socket }}>
      {props.children}
    </SocketContext.Provider>
  );
};
export default SocketInfo;
