import { gql } from "@apollo/client";

export const usersSubscription = gql`
  query usersSubscription($userID: Int!, $languageID: String) {
    spSubscriptionCurrent(languageId1: $languageID, userId1: $userID) {
      nodes {
        slotsPlan
        slotsUsed
        slotsFree
      }
    }
  }
`;
