import React, { useState, useEffect, useContext } from "react";

import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import routes from "routes.js";
import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";
import CustomDialog from "components/CustomDialog/CustomDialog";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";

// data
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SessionContext } from "../contexts/SessionContext";
import { SocketContext } from "../contexts/SocketContext";
import config from "config";
import { axiosOptions } from "functions/Common";
import axios from "axios";
import { retrieveAccount } from "graphql/gqlSignedInMain";
// import { socket } from "functions/socket";
// import { io } from "socket.io-client";

//tour
import useTour from "functions/useTour";
import { Step } from "react-joyride";

// style
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

const switchRoutes = (
  <Routes>
    {routes.map((prop, key) => {
      if (prop.layout === "/app") {
        return <Route path={prop.path} element={prop.component} key={key} />;
      }
      return null;
    })}

    {/* <Route path="/app" element={<Navigate replace to="/app/dashboard" />} /> */}

    <Route
      path="/app/account/"
      render={() => <Navigate to="/app/account/general" />}
    />
    <Route exact path="/app" render={() => <Navigate to="/app/dashboard" />} />
  </Routes>
);
// filter routes to only included in sidebar
const sideBarRoutes = routes.filter((item) => item.inSidebar === true);
const useStyles = makeStyles(styles);

// let socket = "";

export default function SignedInMain({ ...rest }) {
  console.log("------------- inicia el signedInMain");
  const navigate = useNavigate();
  const { session, setSession } = useContext(SessionContext);
  const { socket } = useContext(SocketContext);

  const { userID, accountID } = session;
  const tour = useTour({ session, setSession });
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image] = React.useState(bgImage);
  const [color] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [refreshPath, setRefreshPath] = useState(false);

  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  const getRoute = () => {
    return window.location.pathname !== "/app/maps";
  };

  const [modal, setModal] = useState({
    visible: false,
    name: "",
    data: {},
  });

  const [retrieveAccountMut] = useMutation(retrieveAccount, {
    onCompleted: (data) => {
      console.log("la data del retrieveAccount", data);
      setModal({ visible: true, name: "removed-account" });
      // when the owner removes a user, this user has to update the fallback account id in the session context
      setSession({
        ...session,
        accountID: data.spUserSignIn.results[0].accountId,
      });
    },
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // console.log(
  //   "%c----------------Session value:--------------",
  //   "background-color: red; color: white",
  //   session.timeMode
  // );

  const checkIsLoggedIn = () => {
    console.log("%cCheck if loggedIn", "background-color: blue; color: white");

    const options = axiosOptions({
      url: `${config.url}/auth/signIn`,
      method: "post",
    });
    // console.log("check user logged in", options);
    axios(options)
      .then(
        // Successful fetch
        (response) => {
          console.log(
            "%ctriggers can access",
            "background-color: purple; color: white",
            response
          );
          // return null;
          // console.log("la respuesta es", response);
          // assuming response status is 401
          switch (response.data.code) {
            case 0: // token is valid
              console.log(
                "hay token y se validó correctamente.  Usuario tiene acceso------------- ",
                { canAccessInfo: response.data.info, session }
              );
              const {
                accountID,
                email,
                firstName,
                firstUse,
                isOwner,
                imageUrl,
                lastName,
                newAccessToken,
                subscriptionExpiryDate,
                stageID,
                userID,
              } = response.data.info;

              // if a new access token was issued, store in sessionStorage
              newAccessToken !== undefined &&
                sessionStorage.setItem("kjd/afd", newAccessToken);

              setIsLoggedIn(true);
              setSession({
                accountID,
                email,
                firstUse,
                isOwner,
                languageID: "en",
                profileUrl: imageUrl,
                stageID,
                subscriptionExpiryDate,
                timeMode: 1,
                userID,
                userName: `${firstName} ${lastName}`,
              });
              // connects to socket
              socket.connect();
              socket.emit("sign_in", { userID });

              break;

            case 1: // user has no access due to user security
              console.log(
                "-------------no access due to user security-------------"
              );
              setIsLoggedIn(false);
              navigate("/notauthorized");

              break;

            default:
          }
        }
      )
      // Unsuccessful fetch
      .catch((error) => {
        // if ((error.message = "Network Error")) {
        //   console.error("el error es ", error);
        //   setErrorState({ error: error });
        // } else {
        console.log("error response-----", error.response);
        if (error.response !== undefined) {
          switch (error.response.status) {
            case 0: //network error
              // setErrorState({ error: error });
              setErrorState({
                error: error,
                errorExplanation: "Network error",
                // data: { errorLevel: "fatal", otherData: { data: data } },
              });
              break;

            case 401: // token not valid
              navigate("/notauthorized");
              break;

            case 403: // token not supplied
              navigate("/notauthorized");
              break;

            default:
          }
        } else {
          logError(error, {
            errorLevel: "critical",
            generalData: { ...generalData },
            otherData: {},
          });
        }
        // }

        // retrieveToken({ hasAccess: false });
      });
  };

  useEffect(() => {
    checkIsLoggedIn();

    // disconnects on unmount
    return () => {
      console.log("unmounts");
      // socket.disconnect();
      // socket.emit("sign_out");
      socket.emit("sign_out", { userID });
      // socket.off("disconnect");
    };
  }, []);

  useEffect(() => {
    // console.log("use effect on socket change");

    try {
      // console.log("useEffect on socket change");

      const receivingRemovedFromAccountListener = (data) => {
        console.log("--receivingRemovedFromAccountListener", data);
        checkIsLoggedIn();
        retrieveAccountMut({
          variables: { userID: userID },
        });
      };

      const receivingAddToAccountListener = (data) => {
        console.log("-- receivingRemovedFromAccountListener", data);
        checkIsLoggedIn();
      };

      socket.on(
        "receiving_removed_from_account",
        receivingRemovedFromAccountListener
      );

      socket.on("receiving_add_to_account", receivingAddToAccountListener);

      socket.on("connect_error", (error) => {
        logError(error, {
          errorLevel: " critical",
          generalData: { ...generalData },
          otherData: {},
        });
      });

      return () => {
        socket.off(
          "receiving_removed_from_account",
          receivingRemovedFromAccountListener
        );

        socket.off("receiving_add_to_account", receivingAddToAccountListener);
      };
    } catch (error) {
      logError(error, {
        errorLevel: " critical",
        generalData: { ...generalData },
        otherData: {},
      });
    }
  }, [socket]);

  const ModalToShow = () => {
    switch (modal.name) {
      case "removed-account":
        return (
          <CustomDialog
            center
            iconPreset="forbidden"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Your current invitation has been cancelled"
            mainText="You are now using the free Basic plan.  If you want to keep all the current features, consider subscribing"
            buttonCloseLabel="Close"
            // buttonActionLabel1="Close"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
            }}
            buttonActionLabel2="Go to subscription"
            buttonActionFunction2={() => {
              setModal({ visible: false, name: "" });
              navigate("/app/account");
            }}
          />
        );

      default:
        return null;
    }
  };

  const LoggedInComponent = React.memo(() => {
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={sideBarRoutes}
          logoText={"Orquesti"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          sendInfoToParent={() => setRefreshPath(!refreshPath)}
          {...rest}
        />

        <div className={classes.mainPanel} ref={mainPanel}>
          <Navbar
            routes={routes} // if only to show side bar routes then {sideBarRoutes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />

          {/* this shows the page content */}

          {getRoute() ? (
            <div className={classes.content}>
              {/* <div className={classes.container}>{switchRoutes}</div> */}
              {switchRoutes}
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
        </div>

        <ModalToShow />
      </div>
    );
  });

  if (errorState !== undefined) {
    console.log("ingresa al errorHandler");
    return (
      // <ErrorHandler
      //   error={errorState.error}
      //   // data={{ meetingID: meetingID, userID: userID }}
      //   // btnActionFunction1={() => refetch()}
      // />
      <ErrorHandler
        error={errorState.error}
        errorClear={() => setErrorState()}
        data={{
          errorLevel: "fatal",
          generalData: { ...generalData },
        }}
      />
    );
  } else {
    return (
      <>
        {isLoggedIn ? (
          <>
            <LoggedInComponent />
            {tour}
          </>
        ) : (
          <ActivityIndicator fullscreen />
        )}
      </>
    );
  }
}
