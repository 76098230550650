import React, { useContext, useState } from "react";
// core components
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTooltip from "components/CustomTooltip/CustomTooltip.js";
import { Chart } from "components/Chart";
import MedalWithIndicator from "components/MedalWithIndicator/MedalWithIndicator";
// data
import { SessionContext } from "../../contexts/SessionContext";
import { dashboardCharts } from "graphql/gqlDashboardCompany";
// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
// style
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import { useQuery } from "@apollo/client";

// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const styles = {
  activityHeaderContainer: {
    minHeight: 120,
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  cardIndicator: {
    color: grayColor[1],
    display: "flex",

    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Quicksand', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    overflowWrap: "normal",
    textDecoration: "none",
    "& small": {
      color: grayColor[2],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardIndicatorMin: {
    paddingBottom: "5px",
    paddingLeft: "5px",
  },
  cardIndicatorContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    marginTop: "15px",
  },
  cardTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    // backgroundColor: "yellow",
    paddingBottom: "20px",
  },
  cardTitle: {
    color: grayColor[3],
    fontWeight: "300",
    margin: "0",

    marginTop: "0",
    paddingTop: "10px",
    // paddingBottom: "10px",
  },
  groupContainer: {
    borderRadius: "5px",
    borderColor: grayColor[5],
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "30px",
    marginBottom: "20px",
  },
  groupLabel: { margin: "0px", paddingBottom: "20px" },

  rankingContainer: {
    // backgroundColor: "yellow",
    border: "1px solid " + grayColor[5],
    borderRadius: "5px",
    padding: "20px",
    height: "400px",
    overflowY: "auto",
  },
  rankingIndicator: {
    paddingLeft: "10px",
  },
  rankingIndicatorContainer: {
    display: "flex",
    flexDirection: "row",
  },
  rankingRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    borderBottom: "1px solid " + grayColor[7],
    paddingBottom: "10px",
    marginBottom: "10px",
  },

  stats: {
    color: grayColor[3],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  titleContainer: {
    paddingBottom: "10px",
    // fontWeight: 700,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    paddingRight: "5px",
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
};

const useStyles = makeStyles(styles);

export default function DashboardCompany(props) {
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  const { dateRange } = props;
  // console.log("inicia dashboard");
  const classes = useStyles();
  // const companyID = parseInt(sessionStorage.getItem("companyID"));
  const { session, setSession } = useContext(SessionContext);
  const { userID } = session;
  console.log("loading charts------------------");

  const RatingIndicator = (props) => {
    const { rating } = props;

    const ratingRange = [
      { variant: "gold", upperLimit: 11, lowerlimit: 9 },
      { variant: "silver", upperLimit: 9, lowerlimit: 8 },
      { variant: "bronze", upperLimit: 8, lowerlimit: 7 },
      { variant: "noMedal", upperLimit: 7, lowerlimit: 0 },
    ];

    const index = ratingRange.findIndex(
      (item) => rating < item.upperLimit && rating >= item.lowerlimit
    );

    const variant = ratingRange[index];

    if (rating >= 7) {
      return <MedalWithIndicator variant={variant.variant} size={25} />;
    }
  };

  const Charts = () => {
    const {
      loading: loadingDC,
      data: dataDC,
      error: errorDC,
      refetch: refetchDC,
    } = useQuery(dashboardCharts, {
      variables: {
        userID: userID,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });
    if (loadingDC) return <ActivityIndicator fullscreen />;

    if (errorDC) {
      console.error("el error en dashboardComnpany es: ", errorDC);
      return (
        <ErrorHandler
          error={errorDC}
          errorClear={() => setErrorState()}
          retryFunction={() => refetchDC()}
          data={{
            errorLevel: "fatal",
            generalData: { ...generalData },
            otherData: { message: errorDC.message },
          }}
        />
      );
    }

    const charts = {
      c1: {
        name: "Organizer Rating",
        tooltip: "Ranking of organizer by rating received from attendees",
        showMedal: true,
        numberFormat: (number) => number.toFixed(1),
      },
      c2: {
        name: "Organizer cost per rating point",
        tooltip:
          "Ranking of organizer by the cost of each rating point received from attendees",
        showMedal: false,
        numberFormat: (number) => number.toFixed(0),
      },
      c3: {
        name: "Organizer uncompleted feedback to attendees",
        tooltip:
          "Percentage organizer's meetings where feedback was not provided to attendees",
        showMedal: false,
        numberFormat: (number) => number.toFixed(2) * 100 + "%",
      },
      c4: {
        name: "Attendees uncompleted feedback to organizers",
        tooltip:
          "Percentage of attendees's meetings where feedback was not provided to organizer",
        showMedal: false,
        numberFormat: (number) => number.toFixed(2) * 100 + "%",
      },
      // 5: { name: "Total meetings cost", tooltip: "" },
    };

    if (dataDC && dataDC.sumMeetingCost) {
      // console.log("data", dataDC.topOrganizerRating.nodes);

      const ChartMain = (props) => {
        // console.log("los props dentro de chart son", props);
        return (
          <>
            <div className={classes.titleContainer}>
              <h4 className={classes.title}>{props?.chartProperties?.name}</h4>
              <CustomTooltip
                tooltipText={props?.chartProperties?.tooltip}
                size={14}
                placement="bottom"
              />
            </div>
            <div className={classes.rankingContainer}>
              {dataDC[props?.chartProperties?.id].nodes.map((item2, index) => (
                <div key={index} className={classes.rankingRow}>
                  <h5>
                    {index + 1} - {item2.desc}
                  </h5>
                  <div className={classes.rankingIndicatorContainer}>
                    {props?.chartProperties?.showMedal && (
                      <RatingIndicator rating={item2.value1 + 5} />
                    )}
                    <h5 className={classes.rankingIndicator}>
                      {/* {item2.value1.toFixed(1)} */}
                      {props?.chartProperties?.numberFormat(item2.value1)}
                    </h5>
                  </div>
                </div>
              ))}
            </div>
          </>
        );
      };

      return (
        <>
          <div className={classes.groupContainer}>
            <h3 className={classes.groupLabel}>RANKINGS</h3>
            <GridContainer alignItems="baseline" spacing={5}>
              {Object.keys(charts).map((item) => {
                // console.log("el item es", item);
                const chartProperties = { ...charts[item], id: item };
                console.log("los chart properties", chartProperties);
                // return <h3>{chartProperties.name}</h3>;
                return (
                  <GridItem key={item} xs={12} sm={6}>
                    <ErrorBoundary
                      FallbackComponent={(error) => (
                        <LogErrorComponent
                          error={error}
                          size="large"
                          data={{
                            errorLevel: "critical",
                            generalData: { ...generalData },
                            otherData: {},
                          }}
                        />
                      )}
                    >
                      <ChartMain chartProperties={chartProperties} />
                    </ErrorBoundary>
                  </GridItem>
                );
              })}
            </GridContainer>
          </div>
          <div className={classes.groupContainer}>
            <h3 className={classes.groupLabel}>COST & VALUE</h3>
            <GridContainer alignItems="baseline" spacing={5}>
              <GridItem xs={12} sm={6}>
                <Chart
                  title="Total meetings costs"
                  data={dataDC.sumMeetingCost.nodes}
                  dataKey1="value1"
                  // dataKey2="value2"
                  // dataName1="My avg"
                  // dataName2="Company avg"
                  xAxisLabels="dates"
                  formatMode="currency"
                  yAxisLegend="cost"
                />
              </GridItem>
              {/* <GridItem xs={12} sm={6}>
                <Chart
                  title="Rating as attendee"
                  data={dataDC.avgRatingAsAttendee.nodes}
                  dataKey1="value1"
                  dataKey2="value2"
                  dataName1="My avg"
                  dataName2="Company avg"
                  xAxisLabels="dates"
                  yAxisLegend="rating"
                  yAxisMaxValue={5}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Chart
                  title="% meetings with uncompleted feedback"
                  data={dataDC.uncompletedFeedbackAsOrganizer.nodes}
                  dataKey1="value1"
                  dataKey2="value2"
                  dataName1="to attendees"
                  dataName2="to organizer"
                  xAxisLabels="dates"
                  formatMode="percent"
                  yAxisLegend="percent"
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Chart
                  title="Average attendees per meeting"
                  data={dataDC.avgAttendees.nodes}
                  dataKey1="userValue"
                  dataKey2="companyValue"
                  dataName1="My meetings"
                  dataName2="Company meetings"
                  xAxisLabels="dates"
                  yAxisLegend="# attendees"
                />
              </GridItem>  */}
            </GridContainer>
          </div>
        </>
      );
    }
    // return <h3>No cargo</h3>;
  };

  return (
    <>
      {/* {indicators()} */}

      <Charts />
    </>
  );
}
