import { gql } from "@apollo/client";

export const updateMeetingByID = gql`
  mutation updateMeetingByID(
    $meetingID: Int!
    $userCalendarID: Int
    $externalMeetingID: String
    $startDateFinal: Datetime
    $endDateReminder: Datetime
  ) {
    updateMeetingById(
      input: {
        meetingPatch: {
          userCalendarId: $userCalendarID
          externalMeetingId: $externalMeetingID
          startDateFinal: $startDateFinal
          endDateReminder: $endDateReminder
        }
        id: $meetingID
      }
    ) {
      clientMutationId
    }
  }
`;

export const endMeeting = gql`
  mutation endMeeting($meetingID: Int!) {
    spMeetingEnd(input: { meetingId1: $meetingID }) {
      clientMutationId
    }
  }
`;

export const deleteMeeting = gql`
  mutation deleteMeeting($meetingID: Int!, $cancelDate: Datetime!) {
    updateMeetingById(
      input: { meetingPatch: { cancelDate: $cancelDate }, id: $meetingID }
    ) {
      clientMutationId
    }
  }
`;

export const updateMeetingAttendees = gql`
  mutation updateMeetingAttendees(
    $meetingID: Int!
    $userID: Int!
    $departDate: Datetime
    $checkInCode: String
    $willAttend: Boolean
  ) {
    spMeetingAttendeesUpdate(
      input: {
        userId1: $userID
        meetingId1: $meetingID
        departDate1: $departDate
        checkinCode1: $checkInCode
        willAttend1: $willAttend
      }
    ) {
      clientMutationId
      results {
        action
        updated
      }
    }
  }
`;
