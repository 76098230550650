import React, { useState, useContext, useEffect, useCallback } from "react";
import Resizer from "react-image-file-resizer";
// data
import { useQuery, useMutation } from "@apollo/client";

import {
  userProfile,
  updateUserProfile,
  // updateUserURI,
} from "graphql/gqlProfile";
import { Formik, useFormikContext } from "formik";
import { object, string } from "yup";
import { SessionContext } from "contexts/SessionContext";
// import { debounce } from "functions/Common";
import { axiosOptions } from "functions/Common";
import config from "../../config";
import axios from "axios";
// import _ from "lodash";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
// @material-ui/core components

import Avatar from "@material-ui/core/Avatar";
// core components
import ErrorHandler from "components/Error/Error";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import { s3AddFile, s3DeleteFile } from "functions/s3Files";
import Dropzone from "components/Dropzone";
// icons
import EditIcon from "@material-ui/icons/Edit";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutlineOutlined";
// style
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "assets/jss/material-dashboard-react";

const styles = {
  cardFooter: {
    justifyContent: "flex-end",
  },
  avatar: {
    borderColor: "white",
    borderWidth: "2px",
    borderStyle: "solid",
    height: "150px",
    width: "150px",
    backgroundColor: grayColor[7],
  },
  avatarContainer: {
    // backgroundColor: "yellow",
    display: "flex",
    float: "left",
    flexDirection: "column",
    padding: "40px",
    alignItems: "end",
  },
  avatarButtonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  cardBody: {
    paddingTop: "40px",
  },
  inputFilePicker: {
    display: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Profile(props) {
  // console.log("inicia profile");
  const classes = useStyles();

  const [isSaving, setIsSaving] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    messageType: "add",
    message: "",
  });
  const { session, setSession } = useContext(SessionContext);
  const { userID, profileUrl } = session;

  // const companyID = parseInt(sessionStorage.getItem("companyID"));
  // const userAccountID = parseInt(sessionStorage.getItem("userAccountID"));

  // return <h1>hola</h1>;
  const { loading, data, error, refetch } = useQuery(userProfile, {
    variables: {
      userID: userID,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200, // Is the maxWidth of the resized new image.
        200, // Is the maxHeight of the resized new image.
        "JPEG", // Is the compressFormat of the resized new image.
        80, // Is the quality of the resized new image.
        0, // Is the degree of clockwise rotation to apply to uploaded image.
        (uri) => {
          resolve(uri);
        }, // Is the callBack function of the resized new image URI.
        "blob" // Is the output type of the resized new image.
      );
    });

  const updateURI = (props) => {
    const { file } = props;
    // console.log("el local image uri es", localImageUri);

    let options;
    //i there was a previous image, deletes the file
    if (profileUrl && profileUrl.length > 0) {
      console.log("starts file delete");
      // ****************  delete file **************
      const urlSections = profileUrl.split("/");
      const fileToDeleteS3 =
        urlSections[urlSections.length - 2] +
        "/" +
        urlSections[urlSections.length - 1];

      console.log("el file to delete es", fileToDeleteS3);
      // delete file

      options = axiosOptions({
        url: `${config.url}/api/fileDelete`,
        method: "post",
        data: {
          filename: fileToDeleteS3,
        },
      });

      axios(options)
        .then((response) => {
          if (response.status === 200) {
            console.log("imagen borrada exitosamente");
          }
        })
        .catch((err) => {
          console.log("data NO recibida exitosamente:", err);
        });
    }

    // *************** upload file *******************
    console.log("starts file addition");
    const dataToUpload = new FormData();
    const newFileName = "profile-userid-" + userID + ".jpg";
    // console.log("file info", {
    //   name: fileName,
    //   uri: localImageUri,
    // });

    // creates a file with the key "file"
    dataToUpload.append("file", file, newFileName);

    // options = {
    //   url: `${config.url}/api/fileUpload`,
    //   timeout: config.timeout,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     accept: "application/json",
    //     "Accept-Language": "en-US,en;q=0.8",
    //     "Content-Type": `multipart/form-data; boundary=${dataToUpload._boundary}`,
    //   },
    //   method: "post",
    //   // data: { file: dataToUpload, userID: userID },
    //   data: dataToUpload,
    // };

    options = axiosOptions({
      url: `${config.url}/api/fileUpload`,
      method: "post",
      content: `multipart/form-data; boundary=${dataToUpload._boundary}`,
      data: dataToUpload,
      otherHeaders: { "Accept-Language": "en-US,en;q=0.8" },
    });

    console.log("las opciones son ", options);
    axios(options)
      .then((response) => {
        if (response.status === 200) {
          console.log("data recibida exitosamente:");

          updateUserProfileMut({
            variables: {
              userID: userID,
              imageURL: response.data.location,
            },
          });
          setSession({ ...session, profileUrl: response.data.location });
        }
      })
      .catch((err) => {
        console.log("data NO recibida exitosamente:", err);
      });
    setUploadingImage(false);
  };

  const addOrDeleteFile = (props) => {
    const { file } = props;
    // console.log("el local image uri es", localImageUri);

    let options;
    //i there was a previous image, deletes the file
    if (profileUrl && profileUrl.length > 0) {
      console.log("starts file delete");
      // ****************  delete file **************
      const urlSections = profileUrl.split("/");
      const fileToDeleteS3 =
        urlSections[urlSections.length - 2] +
        "/" +
        urlSections[urlSections.length - 1];

      console.log("el file to delete es", fileToDeleteS3);
      // delete file

      options = axiosOptions({
        url: `${config.url}/api/fileDelete`,
        method: "post",
        data: {
          filename: fileToDeleteS3,
        },
      });

      axios(options)
        .then((response) => {
          if (response.status === 200) {
            console.log("imagen borrada exitosamente");
          }
        })
        .catch((err) => {
          console.log("data NO recibida exitosamente:", err);
        });
    }

    // *************** upload file *******************
    console.log("starts file addition");
    const dataToUpload = new FormData();
    const newFileName = "profile-userid-" + userID + ".jpg";
    // console.log("file info", {
    //   name: fileName,
    //   uri: localImageUri,
    // });

    // creates a file with the key "file"
    dataToUpload.append("file", file, newFileName);

    // options = {
    //   url: `${config.url}/api/fileUpload`,
    //   timeout: config.timeout,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     accept: "application/json",
    //     "Accept-Language": "en-US,en;q=0.8",
    //     "Content-Type": `multipart/form-data; boundary=${dataToUpload._boundary}`,
    //   },
    //   method: "post",
    //   // data: { file: dataToUpload, userID: userID },
    //   data: dataToUpload,
    // };

    options = axiosOptions({
      url: `${config.url}/api/fileUpload`,
      method: "post",
      content: `multipart/form-data; boundary=${dataToUpload._boundary}`,
      data: dataToUpload,
      otherHeaders: { "Accept-Language": "en-US,en;q=0.8" },
    });

    console.log("las opciones son ", options);
    axios(options)
      .then((response) => {
        if (response.status === 200) {
          console.log("data recibida exitosamente:");

          updateUserProfileMut({
            variables: {
              userID: userID,
              imageURL: response.data.location,
            },
          });
          setSession({ ...session, profileUrl: response.data.location });
        }
      })
      .catch((err) => {
        console.log("data NO recibida exitosamente:", err);
      });
    setUploadingImage(false);
  };

  // const deleteImage = () => {
  //   // if theres a file, deletes it
  //   console.log("profileUrl", profileUrl);
  //   if (profileUrl && profileUrl.length > 0) {
  //     console.log("starts file delete");
  //     // ****************  delete file **************
  //     const urlSections = profileUrl.split("/");
  //     const fileToDeleteS3 =
  //       urlSections[urlSections.length - 2] +
  //       "/" +
  //       urlSections[urlSections.length - 1];

  //     console.log("el file to delete es", fileToDeleteS3);
  //     // delete file

  //     const options = axiosOptions({
  //       url: `${config.url}/api/fileDelete`,
  //       method: "post",
  //       data: {
  //         filename: fileToDeleteS3,
  //       },
  //     });

  //     axios(options)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           console.log("imagen borrada exitosamente");
  //           updateUserProfileMut({
  //             variables: {
  //               userID: userID,
  //               imageURL: null,
  //             },
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("data NO recibida exitosamente:", err);
  //       });
  //   }
  // };

  // const [updateUserProfileMut] = useMutation(updateUserProfile, {
  //   onCompleted: async (data) => {
  //     // setShowSnackbar({ show: true, messageType: "save" });
  //     // setIsLoading(false);
  //     console.log("updates user profile", data);
  //     // setSession({
  //     //   ...session,
  //     //   fileUrl: newFileLocation,
  //     // });
  //     // await refetch();
  //     setIsSaving(false);
  //   },
  // });
  const [updateUserProfileMut] = useMutation(updateUserProfile);

  const handleSubmit = async (values) => {
    console.log("en handle submit", values);

    await updateUserProfileMut({
      variables: {
        userID: userID,
        firstName: values.firstName,
        lastName: values.lastName,
      },
    });
  };

  if (loading) return <ActivityIndicator fullscreen />;

  if (error) {
    return (
      <ErrorHandler
        error={error}
        message={error.message}
        btnActionFunction2={() => refetch()}
      />
    );
  }

  if (data && data.spUserProfile) {
    const profileData = data.spUserProfile.nodes[0];

    const autoSave = (formik) => {
      const debounceMs = 2000;

      const debouncedSubmit = useCallback(
        debounce(() => {
          return formik.submitForm();
        }, debounceMs),
        [formik.submitForm, debounceMs]
      );

      useEffect(() => {
        // if data has changed and not currently submitting
        if (
          !isEqual(formik.values, profileData) &&
          !formik.isSubmitting &&
          !isSaving
        ) {
          // console.log("************* values have changed ******************", {
          //   values: formik.values,
          //   initialValues: profileData,
          // });
          setIsSaving(true);
          debouncedSubmit();
        } else {
          // console.log("values have NOT changed", {
          //   values: formik.values,
          //   initialValues: profileData,
          // });
        }
      }, [formik.values]);

      return null;
    };

    return (
      <>
        <Formik
          initialValues={profileData}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={object().shape({
            firstName: string()
              .required("Required")
              .max(40, "Maximum 40 digits"),
            lastName: string()
              .required("Required")
              .max(40, "Maximum 40 digits"),
          })}
        >
          {(fprops) => (
            <>
              <CardBody fullscreen className={classes.cardBody}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <GridContainer
                      spacing={3}
                      // direction="row"
                      // justifyContent="flex-start"
                      // alignItems="stretch"
                    >
                      <GridItem xs={12}>
                        <div className={classes.avatarContainer}>
                          {/* <ActivityIndicator /> */}
                          <Avatar
                            classes={{
                              root: classes.avatar,
                            }}
                            // component={<ActivityIndicator />}
                            // alt={person.name}

                            src={
                              uploadingImage === true ? undefined : profileUrl //fprops.values.imageUrl
                            }
                          >
                            {uploadingImage ? <ActivityIndicator /> : null}
                          </Avatar>
                          <div className={classes.avatarButtonsContainer}>
                            <Button
                              color="danger"
                              justIcon
                              onClick={() =>
                                // updateUserProfileMut({
                                //   variables: {
                                //     userID: userID,
                                //     imageURL: null,
                                //   },
                                // })
                                // deleteImage()
                                {
                                  console.log(
                                    "%cDelete file:",
                                    "background-color: red; color: white",
                                    profileUrl
                                  );

                                  s3DeleteFile({ imageUrl: profileUrl });

                                  updateUserProfileMut({
                                    variables: {
                                      userID: userID,
                                      imageURL: null,
                                    },

                                    onCompleted: async (data) => {
                                      refetch();
                                      setSession({
                                        ...session,
                                        profileUrl: null,
                                      });

                                      setIsSaving(false);
                                    },
                                  });
                                }
                              }
                            >
                              <DeleteOutlineIcon />
                            </Button>
                            <input
                              accept="image/*"
                              className={classes.inputFilePicker}
                              id="contained-button-file"
                              type="file"
                              onChange={async (e) => {
                                setUploadingImage(true);
                                const image = e.target.files[0];
                                console.log("image", image);
                                const imageResized = await resizeFile(image);
                                console.log("image", { image, imageResized });

                                const contentType = imageResized.type;
                                const key = `profileImages/userID:${userID}-${Math.random()}`;

                                const newFileLocation = await s3AddFile({
                                  currentFileUrl: profileUrl,
                                  file: imageResized,
                                  contentType: contentType,
                                  key: key,
                                });
                                console.log(
                                  "%crefetches",
                                  "background-color: red; color: white"
                                );

                                console.log(
                                  "%cFile uploaded succesfully to:",
                                  "background-color: red; color: white",
                                  newFileLocation
                                );
                                // update the path in the db
                                updateUserProfileMut({
                                  variables: {
                                    userID: userID,
                                    imageURL: newFileLocation,
                                  },

                                  onCompleted: async (data) => {
                                    // console.log("updates user profile", {
                                    //   data,
                                    //   newFileLocation,
                                    // });
                                    refetch();
                                    setSession({
                                      ...session,
                                      profileUrl: newFileLocation,
                                    });

                                    setIsSaving(false);
                                  },
                                });
                              }}
                            />
                            <label htmlFor="contained-button-file">
                              <Button
                                component="span"
                                color="primary"
                                justIcon
                                // onClick={() => console.log("temp")}
                              >
                                <EditIcon />
                              </Button>
                            </label>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <CustomMainInput
                          // disabled={!isOrganizer || [4, 5].includes(statusID)}
                          error={
                            fprops.touched.firstName && fprops.errors.firstName
                          }
                          // height="40px"
                          label="First name"
                          name="firstName"
                          onBlur={fprops.handleBlur("firstName")}
                          onChange={fprops.handleChange("firstName")}
                          placeholder="First name"
                          value={fprops.values.firstName}
                          variant="input"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <CustomMainInput
                          // disabled={!isOrganizer || [4, 5].includes(statusID)}
                          error={
                            fprops.touched.lastName && fprops.errors.lastName
                          }
                          height="40px"
                          label="Last name"
                          name="lastName"
                          onBlur={fprops.handleBlur("lastName")}
                          onChange={fprops.handleChange("lastName")}
                          placeholder="First name"
                          value={fprops.values.lastName}
                          variant="input"
                        />
                      </GridItem>

                      <GridItem xs={12} sm={6}>
                        <CustomMainInput
                          disabled
                          error={fprops.touched.email && fprops.errors.email}
                          height="40px"
                          label="Email"
                          name="email"
                          onBlur={fprops.handleBlur("email")}
                          onChange={fprops.handleChange("email")}
                          placeholder="First name"
                          value={fprops.values.email}
                          variant="input"
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
              {autoSave(fprops)}
            </>
          )}
        </Formik>
        <Snackbar
          messageType={showSnackbar.messageType}
          open={showSnackbar.show}
          close={() => setShowSnackbar({ ...showSnackbar, show: false })}
        />
      </>
    );
  }
}
