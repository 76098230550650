import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { TableContainer } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";

// core components

import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import Button from "components/CustomButtons/Button.js";
import { CustomCheckbox } from "components/CustomCheckbox/CustomCheckbox";

import CustomSwitch from "components/CustomSwitch/CustomSwitch";
// icons
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Search from "@material-ui/icons/Search";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CropFreeIcon from "@material-ui/icons/CropFree";
// style
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
// data
import { object, string } from "yup";
const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const {
    addClick,
    changeSwitch,
    disabled,
    deleteClick,
    inputError,
    editClick,
    hasCheckboxSelect,
    hideActionIcons,
    hideFirstColumn,
    hideNoData,
    initialInputValue,
    loading,
    noSearch,
    QRClick,
    rowSelected,
    rightElements,
    selectorRowsPerPage,
    tableData,
    tableHeaderColor,
    viewClick,
    tableHead,
  } = props;
  // console.log("--------------starts custom table", tableData);
  // console.log("initialInputValue", initialInputValue);
  const classes = useStyles();
  const pages = selectorRowsPerPage || [10, 20, 30];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [setValue] = useState(null);
  const [selected, setSelected] = useState([]);
  const [searchInput, setSearchInput] = useState(initialInputValue || "");
  const [hasData, setHasData] = useState(true);
  // const [filterFn1, setFilterFn1] = useState({
  //   fn: (items) => {
  //     return items;
  //   },
  // });

  const [filterFn1, setFilterFn1] = useState({
    fn: (items) => {
      if ((initialInputValue || "") === "") return items;
      else {
        const dataFiltered = items.filter((x) =>
          // first slice to take the columns name, city, state and country as search area
          // then converts to a single string and converts to lowercase
          // finally searches the input value
          x.slice(0, 10).toString().toLowerCase().includes(initialInputValue)
        );
        console.log("dataFiltered", dataFiltered);
        dataFiltered.length === 0 ? setHasData(false) : setHasData(true);
        return dataFiltered;
      }
    },
  });

  const [inputErrorState, setInputErrorState] = useState(inputError);

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInput = (e) => {
    const { value } = e.target;
    if (noSearch) {
      // if not a search table, records the name and value of the field
      const schema = object().shape({
        email: string().email("Digite un correo"),
        name: string().required("Se requiere un nombre"),
      });

      schema
        .isValid({
          email: "value",
        })
        .then(function (valid) {
          setValue([value]);
        });

      // setInvalidText("Digite un correo electrónico");
    } else {
      setInputErrorState("");
      setSearchInput(value);
      setFilterFn1({
        fn: (items) => {
          console.log("los items son", { value: value, items: items });
          if (value === "") return items;
          else {
            const dataFiltered = items.filter((x) =>
              // first slice to take the columns name, city, state and country as search area
              // then converts to a single string and converts to lowercase
              // finally searches the input value

              x.slice(0, 10).toString().toLowerCase().includes(value)
            );
            console.log("dataFiltered", dataFiltered);
            dataFiltered.length === 0 ? setHasData(false) : setHasData(true);
            return dataFiltered;
          }
        },
      });
    }
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    // console.log("executes paging and sorting");
    // evaluate if a filter is applied to select filtered data or raw data
    if (filterFn1 === undefined) {
      console.log("filter undefined");
      return stableSort(tableData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage
      );
    } else {
      // console.log("filter defined");
      return stableSort(
        filterFn1.fn(tableData),
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    }
  };

  const NoData = () => {
    return (
      <div className={classes.subHeader}>
        <div className={classes.noData}>
          <h5>No information to display</h5>
        </div>
        <div>
          <Button
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            size={"sm"}
            className={classes.addButton}
            onClick={() => addClick()}
            disabled={loading || false}
          >
            Add
          </Button>
        </div>
      </div>
    );
  };

  const CustomHeader = () => {
    return tableHead !== undefined ? (
      <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
        <TableRow className={classes.tableHeadRow}>
          {tableHead.map((prop, key) => {
            return (
              <TableCell
                className={classes.tableHeadCell}
                sortDirection={orderBy === key ? order : false}
                key={key}
              >
                <TableSortLabel
                  active={orderBy === key}
                  direction={orderBy === key ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(key);
                  }}
                >
                  {prop}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    ) : null;
  };

  const handleRowClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    rowSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const CustomBody = () => {
    return (
      <TableBody>
        {recordsAfterPagingAndSorting().map((prop, key) => {
          // console.log("la fila es", prop);
          // if last column of array has editIcons, don't include again.  If it doesn't, include it
          if (!hideActionIcons) {
            prop[prop.length - 1] !== "actionIcons" && prop.push("actionIcons");
          }

          // store first column id to be able to later navigate, edit, etc
          const tableRowID = prop[0];
          const tableRow = prop;

          // add a checkbox column if required

          // {
          //   if (hasCheckboxSelect) {
          //     if (prop[0] !== true || prop[0] !== false) {
          //       prop.splice(0, 0, checkboxValue);
          //     }
          //   }
          // }
          // hasCheckboxSelect && prop.unshift(checkboxValue);
          // const propNew = [false, ...prop];
          const isItemSelected = isSelected(tableRowID);
          // console.log(
          //   "El row id:" + tableRowID + " está seleccionado " + isItemSelected
          // );
          return (
            <TableRow
              key={key}
              className={classes.tableBodyRow}
              hover={true}
              // onClick={
              //   hasCheckboxSelect &&
              //   ((event) => handleRowClick(event, tableRowID))
              // }
              tabIndex={-1}
              selected={isItemSelected}
            >
              {hasCheckboxSelect && (
                <TableCell className={classes.tableCell}>
                  {/* <Checkbox checked={isItemSelected} /> */}
                  <CustomCheckbox value={isItemSelected} />
                </TableCell>
              )}
              {prop.map((prop, key) => {
                const [switchValue, setSwitchValue] = useState(prop);
                // hideFirstColumn avoids showing the column used as primary key when it's not relevant to user
                if (hideFirstColumn && key === 0) {
                  // console.log("se esconde", prop);
                  return null;
                } else {
                  // console.log("NO se esconde", prop);
                  return (
                    <TableCell className={classes.tableCell} key={key}>
                      {{
                        actionIcons: (
                          <div className={classes.actionButtonGroup}>
                            {viewClick ? (
                              <IconButton
                                aria-label="View"
                                onClick={() => viewClick(tableRow)}
                                className={classes.tableActionButton}
                                disabled={loading || false}
                              >
                                <VisibilityOutlinedIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                            {editClick ? (
                              <IconButton
                                aria-label="Edit"
                                onClick={() => editClick(tableRow)}
                                className={classes.tableActionButton}
                                disabled={loading || false}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                            {deleteClick ? (
                              <IconButton
                                aria-label="Delete"
                                onClick={() => {
                                  // deleteClick(tableRowID, tableRowIndex);
                                  deleteClick(tableRow);
                                }}
                                className={classes.tableActionButton}
                                disabled={loading || false}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                            {QRClick ? (
                              <IconButton
                                aria-label="QR"
                                onClick={() => {
                                  // deleteClick(tableRowID, tableRowIndex);
                                  QRClick(tableRow);
                                }}
                                className={classes.tableActionButton}
                                disabled={loading || false}
                              >
                                <CropFreeIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </div>
                        ),
                        true: (
                          <CustomSwitch
                            disabled={disabled}
                            checked={switchValue}
                            onChange={() => {
                              changeSwitch(tableRow);
                              setSwitchValue(!switchValue);
                            }}
                          />
                        ),
                        false: (
                          <CustomSwitch
                            disabled={disabled}
                            checked={switchValue}
                            onChange={() => {
                              changeSwitch(tableRow);
                              setSwitchValue(!switchValue);
                            }}
                          />
                        ),
                      }[prop] || prop}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const SearchBar = () => (
    <div className={classes.searchWrapper}>
      {/* <CustomInput
        formControlProps={{
          className: classes.search,
        }}
        onChange={handleInput}
        inputProps={{
          placeholder: "Search",
          inputProps: { "aria-label": "Search" },
          startAdornment: (
            <InputAdornment
              className={classes.searchIcon}
              position="start"
            >
              <Search />
            </InputAdornment>
          ),
        }}
      /> */}

      <CustomMainInput
        // key="prueba"
        // error="ho"

        error={inputErrorState}
        autoFocus
        width="400px"
        // label="Date"
        // name="search"
        color="primary"
        height="100%"
        placeholder={"Type to search"}
        // width="500px"
        iconStart={<Search />}
        onChange={handleInput}
        // onBlur={(item) => {
        //   sendInfoToParent(item);
        // }}
        value={searchInput}
        variant="input"
      />
      {recordsAfterPagingAndSorting().length === 0 && addClick && (
        <Button
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          size={"sm"}
          className={classes.addButton}
          onClick={() => addClick(searchInput)}
          disabled={loading || false}
        >
          Add
        </Button>
      )}
    </div>
  );

  // show no data label if not told otherwise and there are no records
  // return !hideNoData && tableData.length === 0 ? (
  return hideNoData || tableData.length > 0 ? (
    <div className={classes.tableResponsive}>
      {/* <div style={{ backgroundColor: "green" }}> */}
      <div className={classes.subHeader}>
        <SearchBar />
        {rightElements}
      </div>
      {/* <div className={classes.tableContainer}> */}
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <CustomHeader />
          <CustomBody />
        </Table>
      </TableContainer>
      {/* </div> */}

      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={tableData.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page"
        className={classes.pagination}
      />
      {/* </div> */}
      {/* {recordsAfterPagingAndSorting} */}
    </div>
  ) : (
    <NoData />
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  loading: PropTypes.bool,
  hideFirstColumn: PropTypes.bool,
  viewClick: PropTypes.func,
  editClick: PropTypes.func,
  deleteClick: PropTypes.func,
  addClick: PropTypes.func,
  buttonName: PropTypes.string,
  noSearch: PropTypes.bool,
  hideNoData: PropTypes.bool,
  hasCheckboxSelect: PropTypes.bool,
  selectorRowsPerPage: PropTypes.array,
};
