import { useContext, useEffect, useState } from "react";
// data
import { useQuery } from "@apollo/client";
import { FeedbackContext } from "contexts/FeedbackContext";
import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import { meetingFeedback } from "graphql/gqlMeetingFeedback";
import { tourData } from "utils/tourData";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CardBody from "components/Card/CardBody.js";
import CustomDialog from "components/CustomDialog/CustomDialog";

import { FeedbackFromOrganizerView } from "./FeedbackFromOrganizerView";
import { FeedbackFromUserView } from "./FeedbackFromUserView";
// errors
import {
  ErrorHandler,
  LogErrorComponent,
  logError,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// styles
import { grayColor } from "assets/jss/material-dashboard-react";

const styles = {
  body: {
    padding: "20px 20px 0px 20px",
    overflow: "auto",
    maxHeight: "40vh",
  },
  cardBody: {
    paddingTop: "40px",
  },
  feedbackUnavailableLabel: {
    color: grayColor[4],
    paddingLeft: "30px",
  },
  mainContainer: {
    height: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
};

const useStyles = makeStyles(styles);

export default function MeetingFeedback(props) {
  console.log(
    "----------------------------  start meetingFeedback ------------------------------------"
  );
  const { meetingID } = props;
  const classes = useStyles();
  const { session, setSession } = useContext(SessionContext);
  const { userID, firstUse } = session;

  const { meeting } = useContext(MeetingContext);
  const { createdBy, refreshInfo } = meeting;

  const { feedback, setFeedback } = useContext(FeedbackContext);
  const { endDateFinalRef, hoursForFeedbackRef } = feedback;

  const [errorState, setErrorState] = useState();

  const { loading, data, error, refetch } = useQuery(meetingFeedback, {
    variables: {
      meetingID: meetingID,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip: firstUse,
  });

  useEffect(() => {
    try {
      console.log("%cRuns useEffect", "background-color: green; color: white");

      if (firstUse) {
        const tourDummyData = tourData("feedback");
        setFeedback(tourDummyData.general);
        // setMeetingFeedbackState(tourDummyData.general);
        return;
      }
      // refresh triggered when socket signal is received
      if (data !== undefined) {
        setFeedback({
          ...feedback,
          ...data.spMeetingFeedback.nodes[0],
          refetchFeedback: refetch, // stores the refetch in the context
        });

        // setMeetingFeedbackState(data.spMeetingFeedback.nodes[0]);
        endDateFinalRef.current = data.spMeetingFeedback.nodes[0].endDateFinal;
        hoursForFeedbackRef.current =
          data.spMeetingFeedback.nodes[0].hoursForFeedback;
      }
    } catch (error) {
      logError(error, {
        // generalData: { ...generalData },
        otherData: { meetingID },
      });
    }
  }, [data, refreshInfo]);

  // if (data && data.spMeetingFeedback) {
  //   lastMeetingFeedbackRef.current = data.spMeetingFeedback.nodes[0];
  // }

  const isOrganizer = createdBy === userID;

  return (
    <>
      <CardBody fullscreen className={classes.cardBody}>
        {/* <h3 style={{ backgroundColor: isOrganizer ? "yellow" : "orange" }}>
          {isOrganizer ? "Is organizer" : "Is attendee"}
        </h3> */}
        <ErrorBoundary
          FallbackComponent={(error) => (
            <LogErrorComponent
              error={error}
              size="large"
              data={{
                errorLevel: "fatal",
                // generalData: { ...generalData },
                otherData: { meeting, feedback },
              }}
            />
          )}
        >
          <FeedbackMain />
        </ErrorBoundary>

        {errorState && (
          <ErrorHandler
            error={errorState.error}
            errorExplanation={errorState.errorExplanation} // only for dialog, not error
            errorClear={() => setErrorState()}
            data={{
              errorLevel: errorState.data.errorLevel,
              // generalData: { ...generalData },
              otherData: { ...errorState.data.otherData },
            }}
          />
        )}
      </CardBody>
      <ModalToShow />
    </>
  );
}

const FeedbackMain = (props) => {
  const classes = useStyles();
  const { session, setSession } = useContext(SessionContext);
  const { userID, languageID, firstUse } = session;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { createdBy, refreshInfo, statusID } = meeting;

  const { feedback, setFeedback } = useContext(FeedbackContext);
  const { canViewRating } = feedback;
  const { modal, setModal } = props;

  if (canViewRating !== null) {
    console.log(
      "%cRenders with this data",
      "background-color: red; color: white",
      feedback
    );

    return (
      <>
        {[3, 7].includes(statusID) ? (
          <div className={classes.mainContainer}>
            <h4 className={classes.feedbackUnavailableLabel}>
              Feedback will be visible once the meeting is marked as ended by
              the organizer
            </h4>
          </div>
        ) : createdBy === userID ? (
          <FeedbackFromOrganizerView modal={modal} setModal={setModal} />
        ) : (
          <FeedbackFromUserView modal={modal} setModal={setModal} />
        )}
      </>
    );
  }
};

const ModalToShow = (props) => {
  // const generalData = useDataForLog(); // context data to populate error log

  const { session, setSession } = useContext(SessionContext);
  const { userID, languageID, firstUse } = session;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { createdBy, refreshInfo, statusID } = meeting;

  const { modal, setModal } = useContext(FeedbackContext);

  try {
    switch (modal.name) {
      case "rating-missing":
        return (
          <CustomDialog
            iconPreset="forbidden"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title={
              createdBy === userID
                ? "All attendees must have a rating"
                : "All criterias must have a rating"
            }
            buttonCloseLabel="OK"
            // center
          />
        );

      default:
        return null;
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      // generalData: { ...generalData },
      otherData: { modal },
    });
  }
};
