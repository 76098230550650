import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/icons
import Alert from "@material-ui/lab/Alert";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
// core components
import Button from "components/CustomButtons/Button.js";

// styles
import styles from "assets/jss/material-dashboard-react/components/snackbarContentStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function CustomSnackbar(props) {
  const classes = useStyles();
  const {
    message,
    messageType,
    close,
    closeNotification,
    icon,
    place,
    open,
    rtlActive,
    undo,
  } = props;
  var action = [];
  const messageClasses = classNames({
    [classes.iconMessage]: icon !== undefined,
  });

  const placeWDefault = place ? place : "bc";

  action = (
    <>
      {undo !== undefined && (
        <Button simple onClick={() => undo()}>
          Undo
        </Button>
      )}

      {close !== undefined && (
        <IconButton
          className={classes.iconButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => close()}
        >
          <Close className={classes.close} />
        </IconButton>
      )}
    </>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: placeWDefault.indexOf("t") === -1 ? "bottom" : "top",
        horizontal:
          placeWDefault.indexOf("l") !== -1
            ? "left"
            : placeWDefault.indexOf("c") !== -1
            ? "center"
            : "right",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={() => close()}
      // ContentProps={{
      //   classes: {
      //     root:
      //       classes.root +
      //       " " +
      //       classes[messageType === "error" ? "danger" : "success"],
      //     message: classes.message,
      //     action: classNames({ [classes.actionRTL]: rtlActive }),
      //   },
      // }}
    >
      <Alert
        onClose={() => close()}
        variant="filled"
        severity={messageType || "success"} // possible options: error(red), warning(orange), info(blue), success(green)
        action={action}
      >
        <div>
          {/* {icon !== undefined ? <props.icon className={classes.icon} /> : null} */}

          <span className={messageClasses}>
            {[message] ||
              {
                save: "Information saved",
                delete: "Information deleted",
                add: "Information added",
                error: "Information not saved",
                sent: "Emails sent",
              }[messageType]}
          </span>
        </div>
      </Alert>
    </Snackbar>
  );
}

Snackbar.propTypes = {
  // message: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "info",
    "success",
    "warning",
    "danger",
    "primary",
    "info",
  ]),
  messageType: PropTypes.oneOf(["edit", "add", "delete", "error"]),
  // close: PropTypes.bool,
  icon: PropTypes.object,
  place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
  open: PropTypes.bool,
  rtlActive: PropTypes.bool,
  close: PropTypes.func,
};
