import React from "react";
import { Routes, Route } from "react-router-dom";
// material ui components
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// core components
import SignIn from "../views/SignedOut/SignIn";
import NotFound from "../views/SignedOut/NotFound.js";
import SignUp from "../views/SignedOut/SignUp";
import PasswordRecovery from "../views/SignedOut/PasswordRecovery";
import PasswordResetSignedOut from "../views/SignedOut/PasswordResetSignedOut";
import EmailConfirmationExpired from "../views/SignedOut/EmailConfirmationExpired";
import EmailConfirmationInvalid from "../views/SignedOut/EmailConfirmationInvalid";
import EmailConfirmationValid from "../views/SignedOut/EmailConfirmationValid";
import NotAuthorized from "../views/SignedOut/NotAuthorized";
import { SvgIcon } from "@material-ui/core";
// import { SignedInRoute } from "../controllers/ProtectedRoute";
// styles
import styles from "assets/jss/material-dashboard-react/layouts/signedOutStyle.js";
// images
import { ReactComponent as OrquestiLogo } from "../assets/img/OrquestiLogotipo.svg";

const useStyles = makeStyles(styles);

export default function SignedOutMain() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Grid container className={classes.container}>
        <Grid item xs={6} className={classes.leftPanel}>
          {/* <div className={classes.leftPanel}>Lineclap</div> */}

          <SvgIcon style={{ fontSize: 250 }}>
            <OrquestiLogo />
          </SvgIcon>
        </Grid>

        <Grid item xs={6} className={classes.rightPanel}>
          <CssBaseline />

          {/* <BrowserRouter> */}
          <Routes>
            {/* <Route path="/home" component={home} /> */}
            <Route path="/signup" element={<SignUp />} />
            <Route path="/passwordrecovery" element={<PasswordRecovery />} />
            <Route path="/emailverified" element={<EmailConfirmationValid />} />
            <Route
              path="/PassResetSignedOut"
              element={<PasswordResetSignedOut />}
            />
            <Route
              path="/EmailConfirmationExpired"
              element={<EmailConfirmationExpired />}
            />
            <Route
              path="/EmailConfirmationInvalid"
              element={<EmailConfirmationInvalid />}
            />
            <Route path="/NotAuthorized" element={<NotAuthorized />} />
            <Route exact path="/invite/:email" element={<SignIn />} />
            <Route exact path="/" element={<SignIn />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          {/* </BrowserRouter> */}
        </Grid>
        {/* <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress size={100} color="inherit" />
        </Backdrop> */}
      </Grid>
    </div>
  );
}
