import { transition } from "assets/jss/material-dashboard-react.js";

const appStyle = (theme) => ({
  wrapper: {
    height: "100%",
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  mainPanel: {
    // backgroundColor: "orange",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginLeft: "75px", // width of sidebar
    ...transition,
    height: "100%",
  },
  content: {
    padding: "15px",
    height: "calc(100% - 68px)", // 67 is the height of the navBar
    // backgroundColor: "purple",
    boxSizing: "border-box",
    // flex: 1,
    // height: "500px",
  },

  map: {
    marginTop: "70px",
  },
});

export default appStyle;
