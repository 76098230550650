import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  grayColor,
  successColor,
  infoColor,
  dangerColor,
} from "assets/jss/material-dashboard-react.js";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > svg": {
//       margin: theme.spacing(2),
//     },
//   },
// }));

export default function Gauge(props) {
  const gaugeList = {
    // low
    1: {
      generalColor: grayColor[5],
      needle1: grayColor[5],
      needle2: "rgba(0, 0, 0, 0)",
      needle3: "rgba(0, 0, 0, 0)",
      needle4: "rgba(0, 0, 0, 0)",
    },
    // normal
    2: {
      generalColor: successColor[1],
      needle1: "rgba(0, 0, 0, 0)",
      needle2: successColor[1],
      needle3: "rgba(0, 0, 0, 0)",
      needle4: "rgba(0, 0, 0, 0)",
    },
    // high
    3: {
      generalColor: infoColor[5],
      needle1: "rgba(0, 0, 0, 0)",
      needle2: "rgba(0, 0, 0, 0)",
      needle3: infoColor[5],
      needle4: "rgba(0, 0, 0, 0)",
    },
    // urgent
    4: {
      generalColor: dangerColor[1],
      needle1: "rgba(0, 0, 0, 0)",
      needle2: "rgba(0, 0, 0, 0)",
      needle3: "rgba(0, 0, 0, 0)",
      needle4: dangerColor[1],
    },
  };
  const gauge = gaugeList[props.priorityID];

  return (
    <SvgIcon style={{ fontSize: props.size }}>
      <svg
        width="22"
        height="26"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11"
          cy="11"
          r="10"
          fill="white"
          stroke={gauge.generalColor}
          strokeWidth="2"
        />
        <circle
          cx="11"
          cy="11"
          r="1.5"
          fill={gauge.generalColor}
          stroke={gauge.generalColor}
        />
        <path
          d="M10 11H6"
          stroke={gauge.needle1}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M10.3274 10.4341L7.14853 8.00615"
          stroke={gauge.needle2}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M11 10L11 6"
          stroke={gauge.needle3}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M12.0688 10.3494L15.331 8.03452"
          stroke={gauge.needle4}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M19 10.9855H17.5C17.5 7.49186 14.9918 4 11 4C2.01847 4 3.03299 15.3958 3.03299 10.9855C3.03299 6.57524 6.60732 3 11.0165 3C15.4257 3 19 6.57524 19 10.9855Z"
          fill={gauge.generalColor}
        />
      </svg>
    </SvgIcon>
  );
}
