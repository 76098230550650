import { gql } from "@apollo/client";

export const settings = gql`
  query settings($accountID: Int!, $userID: Int!) {
    spSettings(accountId1: $accountID, userId1: $userID) {
      nodes {
        id
        desc
        value
      }
    }
    spCalendars(userId1: $userID, connected1: true) {
      nodes {
        id
        calendarDesc
        email
        defaultCalendar
        calendarTypeId
        microsoftAccount
      }
    }
    accountById(id: $accountID) {
      ownerId
    }
  }
`;

export const updateAccountSettings = gql`
  mutation updateAccountSettings($accountID: Int!, $userID: Int!, $data: JSON) {
    spSettingsAccountUpdate(
      input: { userId1: $userID, accountId1: $accountID, settingsArray1: $data }
    ) {
      clientMutationId
    }
  }
`;

// export const calendarSignOut = gql`
//   mutation calendarSignOut($userCalendarID: Int!) {
//     spUserCalendarSignOut(input: { calendarId1: $userCalendarID }) {
//       clientMutationId
//     }
//   }
// `;

export const deleteCalendar = gql`
  mutation deleteCalendar($userCalendarID: Int!) {
    updateUserCalendarById(
      input: {
        userCalendarPatch: { deleted: true, apiRefresh: "" }
        id: $userCalendarID
      }
    ) {
      clientMutationId
    }
  }
`;

export const calendarUpdateDefault = gql`
  mutation calendarUpdateDefault($userCalendarID: Int!, $userID: Int!) {
    spCalendarUpdateDefault(
      input: { userId1: $userID, userCalendarId1: $userCalendarID }
    ) {
      clientMutationId
    }
  }
`;

// export const updateAccountSettings = gql`
//   mutation updateCompanySettings(
//     $companyID: Int!
//     $CommercialName: String
//     $MaxPeopleWaiting: Int
//     $SecondsToCash: Int
//   ) {
//     updateCompanyById(
//       input: {
//         id: $companyID
//         companyPatch: {
//           commercialName: $CommercialName
//           defaultMaxPeopleWaiting: $MaxPeopleWaiting
//           defaultSecondsToCash: $SecondsToCash
//         }
//       }
//     ) {
//       clientMutationId
//     }
//   }
// `;
