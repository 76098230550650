import React, { useState, forwardRef } from "react";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  InputLabel,
  Box,
} from "@material-ui/core";

// styles
import {
  grayColor,
  primaryColor,
  infoColor,
} from "assets/jss/material-dashboard-react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  circle: {
    borderRadius: 20,

    // width: "20px",
    // height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    zIndex: 10,
    // outline: `5px solid ${rgba(0, 0, 0, 0)}`,
  },
  // circleAbsolute: {
  //   position: "absolute",
  //   left: "30px",
  //   borderRadius: 20,
  //   width: "20px",
  //   height: "20px",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   fontSize: "12px",
  // },
  line: {
    position: "absolute",
    backgroundColor: grayColor[6],
    borderWidth: "0px",
    height: "3px",
    // width: "calc(100% - 55px)",
    // left: 0,
    // right: 0,
    zIndex: 1,
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  },
};

const useStyles = makeStyles(styles);

// forwardRef is required so that the Stepper can display a tooltip when required
export const StepperProgress = forwardRef((props, ref) => {
  const { bottomValue, currentValue, topValue } = props;
  const classes = useStyles();
  const circleSize = 20;
  const Circle = (props) => {
    const {
      color = "white",
      backgroundColor = primaryColor[5],
      value = "N/A",
      progressCircle,
    } = props;
    // console.log("color", color);
    return (
      <div
        className={progressCircle ? classes.circle2 : classes.circle}
        style={{
          color: color,
          backgroundColor: backgroundColor,
          width: circleSize,
          height: circleSize,
        }}
      >
        {value}
      </div>
    );
  };

  const leftMargin = `${(currentValue / topValue) * 100}% - ${
    (circleSize / topValue) * currentValue
  }px`;
  const offset = 3;
  // const leftMargin = `${(currentValue / topValue) * 100}%`;
  // console.log("leftMargin", leftMargin);

  return (
    <div {...props} ref={ref} className={classes.mainContainer}>
      <Circle value={bottomValue} />
      <Circle value={topValue} />

      {/* ----- first line -------- */}
      <hr
        className={classes.line}
        style={{
          width: `calc(${leftMargin} - ${circleSize}px - ${offset * 2}px)`,
          left: `calc(${circleSize}px + ${offset}px)`,
        }}
      />
      {/* ----- current value circle -------- */}
      <div
        style={{
          position: "absolute",
          left: `calc(${leftMargin})`,
          zIndex: 10,
        }}
      >
        <Circle value={currentValue} backgroundColor={infoColor[6]} />
      </div>
      {/* ----- second line -------- */}
      <hr
        className={classes.line}
        style={{
          right: `calc(${circleSize}px + ${offset}px`,
          left: `calc(${leftMargin} + ${circleSize}px + ${offset}px)`,
        }}
      />
    </div>
  );
});
