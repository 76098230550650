import React, { useState, useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";
import logger from "./Logger";
import * as Sentry from "@sentry/react";
// data
import { SessionContext } from "contexts/SessionContext";
// material ui components
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import Tooltip from "@material-ui/core/Tooltip";
// custom components
import Dialog from "components/CustomDialog/CustomDialog";
// styles
import { grayColor, dangerColor } from "assets/jss/material-dashboard-react";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  errorLabel: {
    color: "red",
    textAlign: "center",
  },
  logErrorMainContainer: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: dangerColor[6],
  },
  logErrorTextContainer: {
    maxWidth: "600px",
    textAlign: "center",
    color: grayColor[3],
    marginTop: "5px",
  },
};
const useStyles = makeStyles(styles);

// export class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   componentDidCatch(error, info) {
//     // const { session, setSession } = useContext(SessionContext);
//     // const { userID, userName, accountID } = session;
//     // combines the data for the error using the info coming from the error itself and general information stored in context
//     const dataForError = {
//       ...this.props.data,
//       ...this.context,
//     };
//     const errorDetails = {
//       error: error.message,
//       data: dataForError,
//       stack: info.componentStack,
//     };
//     // if the in development, only prints error to console.  In production, sends error to loggly
//     process.env.NODE_ENV === "development"
//       ? console.log("Error details: ", errorDetails)
//       : logger.push(errorDetails);
//     // console.log("the error is", error.message);
//     // console.log("the error stack is", info.componentStack);
//     // console.log("teh data info is ", this.props.data);
//   }

//   render() {
//     // You can render any custom fallback UI
//     return this.state.hasError ? (
//       <div>
//         <h5>Oops,something faild</h5>
//         <button
//           type="button"
//           onClick={() => this.setState({ hasError: false })}
//         >
//           Try again?
//         </button>
//       </div>
//     ) : (
//       this.props.children
//     );
//   }
// }

// ----------------Axios with dialog warning-------------------------
// error coming from axios and not the try catch block

//  setErrorState({
//             error: error,
//             errorExplanation: 'xxx',
//             data: {
//               errorLevel: 'fatal' or 'critical',
//               otherData: {axiosOptions: options},
//             },
//           });

// ----------------Error with dialog warning -------------------------
// error coming from try catch block

// setErrorState({
//   error: error,
//   errorExplanation: "xxx",
//   data: { errorLevel: 'fatal' or 'critical',
//           otherData: data }},
// });

// ----------------Error without warning -------------------------

// logError({data: xxx , error: error})

// ----------------Error boundary for jsx -------------------------

{
  /* <ErrorBoundary
FallbackComponent={(error) => (
  <LogErrorComponent
    error={error}
    data={{
      generalData: generalData,
      otherData: {},
    }}
  />
)}
>  
</ErrorBoundary> */
}

// ----------------Error handler -------------------------

/* <ErrorHandler
          error={errorState.error}
          data={{
            generalData: generalData,
            otherData: {  },
          }}
          errorExplanation={errorState.errorExplanation}
        /> */

export const useDataForLog = () => {
  const { session } = useContext(SessionContext);
  const { userID, accountID, userName } = session;

  const dataForLog = () => ({ userID, accountID, userName });
  return dataForLog();
};

export const logError = (error, data) => {
  console.log("---------------- inicia logError");

  // const { session } = useContext(SessionContext);
  // const { userID, accountID, userName } = session;

  const { userID, accountID, userName } = useDataForLog;
  /*
props structure
-- error
-- data
------ errorLevel ("fatal" , "critical" , "error" , "warning" , "log" , "info" , and "debug")
------ generalData
---------- userID
---------- accountID
---------- userName
------ otherData
*/
  // const { generalData } = data || {};
  const { otherData } = data || {};
  // if (process.env.NODE_ENV === "production") {
  console.log(
    "%cError logged with logError:  ",
    "background-color: red; color: yellow",
    {
      data,
      error,
    }
  );
  if (otherData !== undefined) {
    Sentry.setExtra("data", JSON.stringify(otherData));
  }

  // if (generalData !== undefined) {
  //   Sentry.setTags({
  //     userID: generalData?.userID,
  //     accountID: generalData?.accountID,
  //     userName: generalData?.userName,
  //   });
  // }
  // if (generalData !== undefined) {
  Sentry.setTags({
    userID: userID || "No user defined",
    accountID: accountID || "No account defined",
    userName: userName || "No userName defined",
  });
  // }

  Sentry.captureException(error);
  // } else {
  //   console.log(
  //     "%cError logged with logError:  ",
  //     "background-color: red; color: yellow",
  //     {
  //       data,
  //       error,
  //     }
  //   );

  //   // console.log("error data", data);
  //   // console.log("error", error);
  // }
};

export const LogErrorComponent = (props) => {
  const classes = useStyles();
  const { error, data, size } = props;
  // console.log("los props en logError", error.message);

  // console.log("logsError to Sentry");
  // return a component after logging the error
  logError(error, data);

  const finalSize = () => {
    switch (size) {
      case "small":
        return 22;
      case "medium":
        return 30;
      case "large":
        return 40;
      default:
        return 22;
    }
  };
  const errorMessage =
    "Sorry!, there's an error here and this information can't be displayed.  We are aware of the problem and we'll fix this as soon as possible.";

  const WithTooltip = () => (
    <Tooltip title={errorMessage} placement="bottom">
      <ErrorOutlineOutlinedIcon
        style={{ color: "red", fontSize: finalSize() }}
      />
    </Tooltip>
  );
  const WithoutTooltip = () => (
    <>
      <ErrorOutlineOutlinedIcon
        style={{ color: "red", fontSize: finalSize() }}
      />

      <div className={classes.logErrorTextContainer}>
        <h5>{errorMessage}</h5>
        {process.env.NODE_ENV === "development" ? (
          <>
            <h5 className={classes.errorLabel}>
              This error detail is only displayed in development
            </h5>
            <h5 className={classes.errorLabel}>{error.error.message}</h5>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  return (
    <div className={classes.logErrorMainContainer}>
      {{
        large: <WithoutTooltip />,
      }[size] || <WithTooltip />}
    </div>
  );
};

export const ErrorHandler = (props) => {
  const classes = useStyles();
  const { error } = props;
  const { data } = props || {};
  console.log("ejectura el error handler");

  const [visible, setVisible] = useState({ visible: true });
  const navigate = useNavigate();
  const { session, setSession } = useContext(SessionContext);

  if (error !== undefined) {
    const findTerm = (term) => {
      if (error?.message?.includes(term)) {
        return error.message;
      }
    };

    // console.log("el error es: ", { message, location, query });
    switch (error.message) {
      case findTerm("Network Error"):
      case findTerm("Failed to fetch"):
        return (
          <Dialog
            iconPreset="error"
            visibleStatus={visible}
            setVisibleStatus={setVisible}
            title="Connection problem"
            mainText="Please check your internet and refresh the page."
            // buttonActionLabel1="Retry"
            buttonActionFunction1={
              props.retryFunction !== undefined && props.retryFunction
            }
            center
          />
        );

      case findTerm("violates foreign key"):
        return (
          <Dialog
            iconPreset="error"
            visibleStatus={visible}
            setVisibleStatus={setVisible}
            title="This operation can't be executed."
            // mainText="Existen datos asociados"
            buttonCloseLabel="OK"
            center
          />
        );
      case findTerm("Received status code 403"):
        return (
          <Dialog
            iconPreset="forbidden"
            visibleStatus={visible}
            setVisibleStatus={setVisible}
            title="Not authorized"
            buttonActionLabel1="Sign in again"
            buttonActionFunction1={() => {
              navigate("/");
              setSession(null);
              sessionStorage.clear();
            }}
            center
          />
        );
      default: {
        // logs the error
        logError(error, data);
        return (
          <Dialog
            iconPreset="error"
            visibleStatus={visible}
            setVisibleStatus={setVisible}
            title="Oops, there's been a problem"
            mainText="Apologies for the inconvenience.  We'll fix it as soon as possible"
            buttonActionLabel1="Return"
            buttonActionFunction1={() => {
              // console.log("el error level es", data);
              if (data?.errorLevel === "fatal") {
                navigate(-1);
              }
              props.errorClear();
            }}
            center
          >
            {props.errorExplanation !== undefined && (
              // <div>
              <h6 className={classes.errorLabel}>
                Error details: {props.errorExplanation}
              </h6>
              // </div>
            )}
          </Dialog>
        );
      }

      //   default:
      //     return (
      //       <Dialog
      //         iconPreset="error"
      //         visibleStatus={visible}
      //         setVisibleStatus={setVisible}
      //         title="Please sign in again"
      //         buttonActionLabel1="OK"
      //         buttonActionFunction1={() => {
      //           navigate("/");
      //           setSession(null);
      //           sessionStorage.clear();
      //         }}
      //         center
      //       />
      //     );
    }
  }
};
