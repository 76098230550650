import { gql } from "@apollo/client";

export const allDropDowns = gql`
  query allDropDowns($languageID: String!) {
    allLanguages(condition: { langShort: $languageID }) {
      nodes {
        priorities: translationHelpdeskPrioritiesByLanguageId {
          nodes {
            id: helpdeskPriorityId
            menuDesc: desc
          }
        }
        categories: translationHelpdeskCategoriesByLanguageId(
          orderBy: DESC_ASC
        ) {
          nodes {
            id: helpdeskCategoryId
            menuDesc: desc
          }
        }
      }
    }
  }
`;

export const newTicket = gql`
  mutation spHelpdeskNew(
    $userID: Int
    $details: String
    $categoryID: Int
    $priorityID: Int
    $subject: String
  ) {
    spHelpdeskNew(
      input: {
        createdBy1: $userID
        details1: $details
        helpdeskCategoryId1: $categoryID
        helpdeskPriorityId1: $priorityID
        subject1: $subject
      }
    ) {
      results {
        helpdeskHistoryId
        helpdeskId
      }
    }
  }
`;

export const updateAttachmentPaths = gql`
  mutation updateAttachmentPaths(
    $helpdeskHistoryID: Int!
    $attachmentPaths: [String]
  ) {
    updateHelpdeskHistoryById(
      input: {
        helpdeskHistoryPatch: { attachmentPaths: $attachmentPaths }
        id: $helpdeskHistoryID
      }
    ) {
      clientMutationId
    }
  }
`;
