import { gql } from "@apollo/client";

export const updateMeetingAttendees = gql`
  mutation updateMeetingAttendees(
    $meetingID: Int!
    $userID: Int!
    $departDate: Datetime
    $checkInCode: String
    $willAttend: Boolean
  ) {
    spMeetingAttendeesUpdate(
      input: {
        userId1: $userID
        meetingId1: $meetingID
        departDate1: $departDate
        checkinCode1: $checkInCode
        willAttend1: $willAttend
      }
    ) {
      clientMutationId
      results {
        action
        updated
      }
    }
  }
`;
