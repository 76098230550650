import {
  blackColor,
  whiteColor,
  hexToRgb,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const cardStyle = {
  card: {
    // boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    // backgroundColor: rgba(0, 0, 0, 1),
    background: "white",
    border: "0",
    marginBottom: "30px",
    marginTop: "10px",
    borderRadius: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",

    width: "100%",
    // height: "calc(100% - 60px)", // substracts the marginTop

    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    height: "100%",
  },
  cardTransparentBackground: {
    background: "transparent",
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none",
  },
  cardProfile: {
    marginTop: "30px",
    textAlign: "center",
  },
  cardComms: {
    width: "23%",
    margin: "1%",
    minHeight: "25vh",
  },
  cardChart: {
    "& p": {
      marginTop: "0px",
      paddingTop: "0px",
    },
  },

  cardFullscreen: {
    height: "calc(100% - 10px)", // substracts the marginTop
  },
};

export default cardStyle;
