import { gql } from "@apollo/client";

export const infoOrganizerUser = gql`
  query infoOrganizerUser($meetingID: Int!, $userID: Int, $languageID: String) {
    spMeetingFeedbackToOrganizerUser(
      languageId1: $languageID
      userId1: $userID
      meetingId1: $meetingID
    ) {
      nodes {
        tooltip
        rating
        id
        desc
        dateAttendeeGiveRating
      }
    }
    spMeetingFeedbackComments(meetingId1: $meetingID, userId1: $userID) {
      nodes {
        comment
      }
    }
  }
`;
