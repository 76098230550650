import React from "react";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.warning.dark,
  },
  title: {
    color: theme.palette.secondary.main,
    margin: "30px 0px",
  },
}));

export default function EmailConfirmationExpired() {
  const { t } = useTranslation(["main"]);
  const classes = useStyles();

  return (
    <>
      {/* {redirect ? <Redirect from="/passwordrecovery" to="/" exact /> : null} */}

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <h3>{t("EmailConfirmationExpired.message")}</h3>
      </div>
    </>
  );
}
