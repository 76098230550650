import { forwardRef, useContext, useEffect, useRef, useState } from "react";
// @material-ui/core components
import classNames from "classnames";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// core components

import Button from "components/CustomButtons/Button.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import CustomPopper from "components/CustomPopper";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// data
import { useMutation, useQuery } from "@apollo/client";
import {
  addToDoGroup,
  deleteToDoGroup,
  toDoMisc,
  updateToDoGroup,
  updateToDoGroupName,
} from "graphql/gqlTodoMain";
import { v4 as uuidv4 } from "uuid";

import axios from "axios";
import config from "config";
import { TodoContext } from "contexts/TodoContext";
import { SessionContext } from "../../contexts/SessionContext";
import {
  axiosOptions,
  formatDateTimeMedium2,
  sentenceCase,
  timeDifference,
} from "../../functions/Common";
// errors
import {
  ErrorHandler,
  LogErrorComponent,
  logError,
  useDataForLog,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
// icons
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
// style
import { makeStyles } from "@material-ui/core/styles";

import {
  blackColor,
  dangerColor,
  defaultFont,
  grayColor,
  hexToRgb,
  infoColor,
  primaryColor,
  purpleColor,
  successColor,
} from "assets/jss/material-dashboard-react";

const styles = {
  addColumnContainer: {
    marginLeft: "5px",
  },
  addButtonsContainer: {
    // marginTop: "10px",
    padding: "10px 10px 0px 0px",
    display: "flex",
    justifyContent: "space-between",
  },
  addSectionContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 8px",

    // justifyContent: "center",
    // background: "lightBlue",
  },

  addSectionVisibleToDo: {
    paddingTop: "8px",
    marginTop: "8px",
    borderTop: `1px solid ${dangerColor[4]}`,
  },
  addSectionVisibleInProgress: {
    paddingTop: "8px",
    marginTop: "8px",
    borderTop: `1px solid ${grayColor[6]}`,
  },
  columnContent: {
    overflowY: "auto",

    padding: "0px 8px",
    minHeight: "10px",
  },
  // columnContentRegularHeight: {
  // maxHeight: "calc(100vh - 200px)",
  // },
  // columnContentReducedHeight: {
  // maxHeight: "calc(100vh - 345px)",
  // },
  columnDropableContainer: {
    padding: "10px 0px 10px 0px",
    width: "250px",
    borderRadius: "5px",
    backgroundColor: grayColor[8],
    // backgroundColor: "yellow",
    margin: "0px 0px 10px 10px",
    maxHeight: "100%",
    display: "flex",
    height: "100%",
    flexDirection: "column",

    // overflowY: "hidden",
  },
  columnDropableToDoContainer: {
    backgroundColor: dangerColor[7],
  },
  columnDropableDoneContainer: {
    backgroundColor: successColor[7],
  },

  columnDropableSelectedContainer: {
    backgroundColor: primaryColor[7],
  },

  columnDropableFromMeetingContainer: {
    backgroundColor: purpleColor[8],
  },
  columnHeaderTitleLabel: {
    fontWeight: 600,
    // borderRadius: "5px 5px 0px 0px",
    margin: "0px",
    paddingLeft: "10px",
    // paddingTop: "10px",
    // color: grayColor[1],
    // backgroundColor: grayColor[8],
    textAlign: "left",
    // width: "250px",
  },
  columnHeaderTitleLabelToDo: {
    color: dangerColor[1],
  },
  columnHeaderTitleLabelFromMeeting: {
    color: purpleColor[3],
  },
  columnHeaderTitleLabelInProgress: {
    color: grayColor[3],
  },
  columnHeaderTitleLabelDone: {
    color: successColor[1],
  },
  daysToDueDateLabel: {
    fontSize: 10,
    fontWeight: 600,
    margin: 0,
    // color: grayColor[0],
    border: `1px solid ${grayColor[5]}`,
    backgroundColor: grayColor[7],
    color: grayColor[4],
    borderRadius: "50px",
    padding: "2px 10px",
  },
  inputTitle: {
    ...defaultFont,
    // display: "flex",
    // flex: 1,
    width: "100%",

    fontSize: "12px",
    fontWeight: 500,
    border: "1px solid " + grayColor[5],
    borderRadius: "5px",
    // borderWidth: "0px",
    backgroundColor: "white",
    // backgroundColor: "orange",
    // paddingLeft: "10px",
    // paddingRight: "10px",
    padding: "5px",
    marginRight: "5px",
    marginLeft: "10px",
  },
  itemContainer: {
    borderWidth: "0px",
    borderStyle: "solid",
    borderColor: grayColor[5],
    userSelect: "none",

    margin: "0 0 8px 0",
    minHeight: "42px",
    backgroundColor: "white",
    color: "black",
    borderRadius: "5px",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "column",
    padding: 10,
  },
  itemContainerPriority1: {
    borderWidth: "0px 6px 0px 0px",
    borderColor: grayColor[5],
  },
  itemContainerPriority2: {
    borderWidth: "0px 6px 0px 0px",
    borderColor: successColor[1],
  },
  itemContainerPriority3: {
    borderWidth: "0px 6px 0px 0px",
    borderColor: infoColor[5],
  },
  itemContainerPriority4: {
    borderWidth: "0px 6px 0px 0px",
    borderColor: dangerColor[1],
  },
  itemSelectedContainer: {
    borderWidth: "0px 6px 0px 0px",
    backgroundColor: primaryColor[8],
  },
  kanban2Container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    // backgroundColor: "yellow",
    // width: "800px",
    height: "100%",
  },
  meetingInfoContent: { width: "320px", padding: "10px" },
  meetingInfoContentLabel: { fontWeight: 600 },
  // todoTitleContainer: { padding: 10 },
  todoBottomBar: {
    // borderTop: `1px solid ${grayColor[7]}`,
    // backgroundColor: grayColor[9],
    paddingTop: "5px",
    display: "flex",
    // backgroundColor: "orange",
  },

  todoAcceptRejectBottomBar: {
    borderTop: `1px solid ${grayColor[7]}`,
    marginTop: 10,
    paddingTop: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
    paddingRight: 10,
    // paddingBottom: "10px",
    minHeight: 30, // allow title with or without icons to same height
  },
};
const useStyles = makeStyles(styles);

export default function TodoMain(props) {
  const classes = useStyles();
  // console.log("starts to do details");
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { todo, setTodo, initialDataRef } = useContext(TodoContext);
    const { originalData, currentData: currentDataState } = todo;

    const currentData =
      initialDataRef?.current.length === 0
        ? currentDataState
        : initialDataRef.current;

    const [updateToDoGroupMut] = useMutation(updateToDoGroup);

    const toDoUpdateColumn = (props) => {
      const { columnData } = props;

      const options = axiosOptions({
        url: `${config.url}/api/to-do-update-column`,
        method: "post",
        data: {
          columnData: columnData,
        },
      });
      // console.log("toDoUpdateColumn axios options", options);

      axios(options)
        .then(async (response) => {
          // if (200 === response.status) {
          // }
          console.log("axios response", response.data);
        })
        .catch((error) => {
          console.log("error is:", error);
          return <ErrorHandler error={error} />;
        });
    };

    const onDragEnd = (result, columns) => {
      try {
        // if dropping outside of droppable area
        if (!result.destination) {
          console.log("**************************** no destination");
          return;
        }

        const { source, destination, type } = result;
        // console.log("el source y destionation", { source, destination });

        // detects if dragging column or task
        if (type === "column") {
          // ----------- if dragging a column ------------

          const newArray = [...columns];
          const [removed] = newArray.splice(source.index, 1);
          newArray.splice(destination.index, 0, removed);

          const reorderedColumns = newArray.map((column, index) => {
            return {
              ...column,
              orderBy: index + 2, // adds 2 because the initial column ("to do") order by starts with 2
            };
          });

          const reorderedColumnsForUpdate = newArray.map((column, index) => {
            return {
              id: column.id,
              desc: column.desc,
              order_by: index + 1,
            };
          });
          console.log("reordered columns. Hace el update", reorderedColumns);
          initialDataRef.current = reorderedColumns; // avoids flickers between render and refreshing data
          setTodo({ ...todo, currentData: reorderedColumns });

          updateToDoGroupMut({
            variables: {
              data: reorderedColumnsForUpdate,
            },
          });

          // newArray.splice
        } else {
          // ----------- if dragging a task -----------------

          // get the information from the column where the element is taken
          const sourceColumn = columns.filter(
            (item) => String(item.id) === source.droppableId
          );
          // console.log("sourceColumn", sourceColumn);

          // evaluates if moving item between columns or within the same column
          if (source.droppableId !== destination.droppableId) {
            // ------------------------------------------ moving item between columns ------------------------------------------
            console.log("moving items between columns");
            // extract the destination column info
            const destColumn = columns.filter(
              (item) => String(item.id) === destination.droppableId
            );
            console.log("el destColumn es", destColumn);

            // copy current source items to a constant
            const sourceItems = sourceColumn[0].items;
            // copy current destinations item to a constant
            const destItems = destColumn[0].items;

            console.log("items", {
              sourceItems: sourceItems,
              destItems: destItems,
            });

            // remove item from the current source items list
            const [removed] = sourceItems.splice(source.index, 1);
            // add item to current destination items list
            destItems.splice(destination.index, 0, removed);

            const reorderedDestItems = destItems.map((item, index) => {
              // if (item.id !== null) {
              return {
                ...item,
                orderBy: index + 1,
                toDoGroupId: parseInt(destination.droppableId),
              };
              // }
            });

            const reorderedSourceItems = sourceItems.map((item, index) => {
              return {
                ...item,
                orderBy: index + 1,
              };
            });
            // console.log("update column", [
            //   ...reorderedSourceItems,
            //   ...reorderedDestItems,
            // ]);
            console.log("columns", { sourceColumn, destColumn });

            const newColumns = columns.map((item, index) => {
              if (item.id === sourceColumn[0].id) {
                console.log("source column", {
                  ...sourceColumn[0],
                  items: reorderedSourceItems,
                });
                return {
                  ...sourceColumn[0],
                  items: reorderedSourceItems,
                };
              } else if (item.id === destColumn[0].id) {
                console.log("destination column", {
                  ...destColumn[0],
                  items: reorderedDestItems,
                });
                return {
                  ...destColumn[0],
                  items: reorderedDestItems,
                };
              } else {
                return item;
              }
            });
            console.log("new Columns between columns", newColumns);

            initialDataRef.current = newColumns; // avoids flickers between render and refreshing data
            setTodo({ ...todo, currentData: newColumns });

            toDoUpdateColumn({
              columnData: [...reorderedSourceItems, ...reorderedDestItems],
            });
          } else {
            // ------------------------------------------ moving item within same column ------------------------------------------

            console.log(
              "%cmoving items inside the same column",
              "background-color: blue; color: white",
              sourceColumn[0].items
            );

            const copiedItems = [...sourceColumn[0].items];
            // console.log("the copied items are", copiedItems);
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            // renumbers the order
            const reorderedItems = copiedItems.map((item, index) => {
              return {
                ...item,
                orderBy: index + 1,
              };
            });
            // console.log(
            //   "la info de item movidos dentro de la misma columna",
            //   {
            //     copiedItems,
            //     reorderedItems,
            //   }
            // );

            // updates the columns with the reorderered items
            // const newArray = [...columns];
            // finalArray = newArray.map((item) => {
            //   if (item.id === sourceColumn[0].id) {
            //     return {
            //       ...item,
            //       items: reorderedItems,
            //     };
            //   } else {
            //     return item;
            //   }
            // });
            // finalArray = [{ ...sourceColumn[0], items: reorderedItems }];
            // console.log("reorderedItems", reorderedItems);
            const newColumns = columns.map((item, index) => {
              if (item.id === sourceColumn[0].id) {
                return {
                  ...sourceColumn[0],
                  items: reorderedItems,
                };
              } else {
                return item;
              }
            });

            // console.log("new Columns", newColumns);

            initialDataRef.current = newColumns; // avoids flickers between render and refreshing data
            setTodo({ ...todo, currentData: newColumns });

            toDoUpdateColumn({
              columnData: reorderedItems,
            });
          }
        }
      } catch (error) {
        logError(error, {
          errorLevel: "fatal",
          generalData: { ...generalData },
          otherData: { result, columns },
        });
      }
    };

    return (
      <DragDropContext
        // moves either column or element within column
        // onDragEnd={(result) => onDragEnd(result, data.spToDo.nodes)}
        onDragEnd={(result) => onDragEnd(result, currentData)}
      >
        {/* area where the columns can be changed */}
        <Droppable
          // key={uniqueId()}
          key={uuidv4()}
          droppableId="all-columns"
          direction="horizontal"
          type="column" // differentiate between tasks and columns
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classes.kanban2Container}
            >
              {currentData.map((column, index) => {
                return (
                  // <>
                  <div
                    key={column.id}
                    style={{
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    {currentData.length === index + 1 && <AddColumn />}
                    {/* <AddColumn /> */}
                    <ErrorBoundary
                      key={column.id}
                      FallbackComponent={(error) => (
                        <LogErrorComponent
                          error={error}
                          size="large"
                          data={{
                            errorLevel: "fatal",
                            generalData: { ...generalData },
                            otherData: { column, index },
                          }}
                        />
                      )}
                    >
                      {/* <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        backgroundColor: "lightBlue",
                        margin: 10,
                        userSelect: "none",
                      }}
                    >
                      <h3>Columna {column.desc}</h3>
                    </div> */}
                      <ColumnComponent
                        column={column}
                        index={index}
                        columnsCount={currentData.length}
                      />
                    </ErrorBoundary>
                  </div>
                );
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal or critical",
      generalData: { ...generalData },
      // otherData: { modal },
    });
  }
}

const AddColumn = () => {
  const classes = useStyles();
  const generalData = useDataForLog();
  try {
    const { session, setSession } = useContext(SessionContext);
    const { userID } = session;

    const { todo, setTodo, kanbanRef } = useContext(TodoContext);
    const { currentData } = todo;

    // used to show the temporary add column
    const [addColumn, setAddColumn] = useState({
      visible: false,
      value: "",
    });

    const [addToDoGroupMut] = useMutation(addToDoGroup, {
      onCompleted: () => {
        setTodo({ ...todo, updateInfo: new Date() });
      },
    });

    useEffect(() => {
      if (addColumn.visible) {
        console.log("kanbanRef", kanbanRef);
        kanbanRef.current.scrollLeft = 2000;
      }
    }, [addColumn]);

    return (
      <>
        {!addColumn.visible && (
          <div className={classes.addColumnContainer}>
            <Button
              color="primary"
              justIcon
              simple
              wired
              onClick={() => {
                setAddColumn({
                  ...addColumn,
                  visible: true,
                });
              }}
            >
              <AddIcon />
            </Button>
          </div>
        )}
        {addColumn.visible && (
          <div className={classes.columnDropableContainer}>
            <div className={classes.addSectionContainer}>
              <CustomMainInput
                height="30px"
                variant="input"
                value={addColumn.value}
                placeholder="Enter a name for the new list"
                onChange={(event) =>
                  setAddColumn({
                    ...addColumn,
                    value: event.target.value,
                  })
                }
                // onBlur={fprops.handleBlur("name")}
                // error={fprops.touched.name && fprops.errors.name}
              />

              <div className={classes.addButtonsContainer}>
                <Button
                  color="primary"
                  size="xs"
                  onClick={(e) => {
                    const newColumn = {
                      id: null,
                      desc: addColumn.value,
                      orderBy: null,
                      items: [],
                      locked: false,
                    };

                    const newColumns = currentData;
                    const insertPosition = currentData.length - 1; // insert in the penultimate position, before the done column
                    newColumns.splice(insertPosition, 0, newColumn);

                    console.log("newColumns", newColumns);

                    addToDoGroupMut({
                      variables: {
                        desc: addColumn.value,
                        userID: userID,
                      },
                    });

                    setAddColumn({ ...addColumn, visible: false });
                  }}
                >
                  Add List
                </Button>
                <Button
                  color="gray"
                  justIcon
                  simple
                  onClick={() =>
                    setAddColumn({ ...addColumn, visible: false, value: null })
                  }
                >
                  <CloseIcon />
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      // otherData: { addColumn },
    });
  }
};

const ColumnComponent = (props) => {
  const classes = useStyles();
  const generalData = useDataForLog();

  try {
    // console.log("column props", props);
    const { column, index, columnsCount } = props;
    const [addSectionOpen, setAddSectionOpen] = useState(false);

    // console.log("column", { count: columnsCount, index: index });

    let columnGroup = null;
    if (column.orderBy === 1) {
      columnGroup = "fromMeeting"; // from meeting
    } else if (column.orderBy === 2) {
      columnGroup = "toDo"; // to do
    } else if (columnsCount === index + 1) {
      columnGroup = "done"; // done
    } else {
      columnGroup = "inProgress"; // in progress
    }

    return (
      // this draggable allows to move column position
      <Draggable
        draggableId={String(column.id)}
        index={index}
        // isDragDisabled={["fromMeeting", "toDo", "done"].includes(columnGroup)}
        isDragDisabled={true} // columns are not draggable until fix
      >
        {(provided1) => {
          return (
            <div
              ref={provided1.innerRef}
              {...provided1.draggableProps}
              {...provided1.dragHandleProps}
            >
              <Droppable
                droppableId={String(column.id)}
                key={column.id}
                type="task" // differentiate between task and columns
                isDropDisabled={columnGroup === "fromMeeting"} // avoids dropping in column "from meetings"
              >
                {(provided, snapshot) => {
                  const columnDropableContainerClasses = classNames({
                    [classes.columnDropableContainer]: true,
                    [classes.columnDropableSelectedContainer]:
                      snapshot.isDraggingOver,
                    [classes[
                      `columnDropable${sentenceCase(columnGroup)}Container`
                    ]]: columnGroup,
                  });

                  return (
                    <div
                      // id={
                      //   columnToDo
                      //     ? "tourTodoBoard"
                      //     : columnActionsFromMeeting &&
                      //       "tourTodoFromMeeting"
                      // }
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      id={
                        columnGroup === "toDo"
                          ? "tourTodoBoard"
                          : columnGroup === "fromMeeting" &&
                            "tourTodoFromMeeting"
                      }
                      className={columnDropableContainerClasses}
                    >
                      <ErrorBoundary
                        FallbackComponent={(error) => (
                          <LogErrorComponent
                            error={error}
                            data={{
                              errorLevel: "fatal",
                              generalData: { ...generalData },
                              otherData: {},
                            }}
                          />
                        )}
                      >
                        <ColumnHeader
                          columnGroup={columnGroup}
                          provided1={provided1}
                          // editColumn={editColumn}
                          column={column}
                        />
                      </ErrorBoundary>

                      {/* if the tasks are rendered as a component, when dropping a task outside the column, the to do freezes */}
                      {/* {columnTasks()} */}
                      <ColumnList
                        column={column}
                        columnGroup={columnGroup}
                        provided={provided}
                        addSectionOpen={addSectionOpen}
                        snapshot={snapshot}
                      />

                      <ErrorBoundary
                        FallbackComponent={(error) => (
                          <LogErrorComponent
                            error={error}
                            data={{
                              errorLevel: "fatal",
                              generalData: { ...generalData },
                              otherData: {},
                            }}
                          />
                        )}
                      >
                        <ColumnFooter
                          column={column}
                          columnGroup={columnGroup}
                          setAddSectionOpen={setAddSectionOpen}
                        />
                      </ErrorBoundary>
                    </div>
                  );
                }}
              </Droppable>
            </div>
          );
        }}
      </Draggable>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

const ColumnHeader = (props) => {
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { column, columnGroup, provided1 } = props;

    const { todo, setTodo, modal, setModal } = useContext(TodoContext);

    const columnHeaderTitleLabelClasses = classNames({
      [classes.columnHeaderTitleLabel]: true,
      [classes[`columnHeaderTitleLabel${sentenceCase(columnGroup)}`]]:
        columnGroup,
    });

    const [editColumn, setEditColumn] = useState({
      editMode: false,
      groupID: column.id,
      groupName: column.desc,
    });

    const [updateToDoGroupNameMut] = useMutation(updateToDoGroupName, {
      onCompleted: () => {
        console.log("name changed");
        // refetch();

        setTodo({ ...todo, updateInfo: new Date() });
      },
    });

    const [deleteToDoGroupMut] = useMutation(deleteToDoGroup, {
      onCompleted: () => {
        setTodo({ ...todo, updateInfo: new Date() });
      },
    });

    return (
      <div {...provided1.dragHandleProps} className={classes.titleContainer}>
        {editColumn.editMode ? (
          <input
            className={classes.inputTitle}
            onChange={(e) => {
              // console.log("change title", column);
              setEditColumn({
                ...editColumn,
                groupID: column.id,
                groupName: e.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateToDoGroupNameMut({
                  variables: {
                    groupName: editColumn.groupName,
                    groupID: editColumn.groupID,
                  },
                });
              }
              if (["Enter", "Escape"].includes(e.key)) {
                setEditColumn({
                  ...editColumn,
                  editMode: false,
                });
              }
            }}
            onBlur={() =>
              setEditColumn({
                editMode: false,
                groupID: column.id,
                groupName: column.desc,
              })
            }
            // placeholder={column.desc}
            value={editColumn.groupName}
            autoFocus
          />
        ) : (
          <h5 className={columnHeaderTitleLabelClasses}>{column.desc}</h5>
        )}
        <div className={classes.buttonsContainer}>
          {!column.locked && (
            <div style={{ display: "flex" }}>
              {editColumn.editMode ? (
                <Button
                  color="gray"
                  // size="sm"
                  justIcon
                  onClick={() =>
                    setEditColumn({
                      ...editColumn,
                      editMode: false,
                    })
                  }
                >
                  <CloseIcon />
                </Button>
              ) : (
                <Button
                  color="gray"
                  // size="sm"
                  justIcon
                  onClick={() =>
                    setEditColumn({
                      ...editColumn,
                      editMode: true,
                    })
                  }
                >
                  <EditIcon />
                </Button>
              )}
              <Button
                color="gray"
                // size="xs"
                justIcon
                onClick={() => {
                  if (column.items.length > 0) {
                    setModal({
                      visible: true,
                      name: "emptyGroupFirst",
                      data: { columnID: column.id },
                    });
                  } else {
                    console.log("column to delete", column.id);
                    deleteToDoGroupMut({
                      variables: {
                        toDoGroupID: column.id,
                      },
                    });
                  }
                }}
              >
                <DeleteOutlineIcon />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

const ColumnList = (props) => {
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { addSectionOpen, column, columnGroup, provided, snapshot } = props;
    const { isDraggingOver, draggingFromThisWith, draggingOverWith } = snapshot;

    return (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        className={classes.columnContent}

        // style={{ backgroundColor: "red" }}
        // className={`${classes.columnContent}
        // ${
        //   addSectionOpen
        //     ? classes.columnContentReducedHeight
        //     : classes.columnContentRegularHeight
        // }
        // `}
      >
        {/* ---------------task within the column ------------------*/}
        {column.items.map((item, index2) => (
          <TodoItem
            key={item.id}
            columnGroup={columnGroup}
            index={index2}
            item={item}
          />
        ))}
        {provided.placeholder}
        {/* {(draggingOverWith || draggingFromThisWith) && (
          <div
            style={{
              border: `2px dashed ${draggingOverWith ? "white" : grayColor[3]}`,
              borderRadius: "5px",
              height: draggingOverWith || draggingFromThisWith ? "50px" : "0px",
              margin: "0 0 8px 0",
            }}
          >
            {provided.placeholder}
          </div>
        )} */}
      </div>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

const TodoItem = (props) => {
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log

  try {
    const { columnGroup, item, index } = props;
    const { id, toDoPriorityId, toDoGroupIDDefault } = item;

    const { todo, setTodo, modal, setModal } = useContext(TodoContext);
    const { originalData, currentData } = todo;

    if (id !== null) {
      return (
        <Draggable
          draggableId={"T" + String(id)} // t is added to create unique ids for tasks separated from columns
          index={index}
          isDragDisabled={columnGroup === "fromMeeting"}
        >
          {(provided, snapshot) => {
            const itemContainerClasses = classNames({
              [classes.itemContainer]: true,
              [classes.itemSelectedContainer]: snapshot.isDragging,
              [classes[`itemContainerPriority${toDoPriorityId}`]]:
                toDoPriorityId && columnGroup !== "fromMeeting",
            });
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={itemContainerClasses}
                onClick={() => {
                  const toDoGroupList = () => {
                    let tempList = currentData.reduce((total, item) => {
                      if (toDoGroupIDDefault !== 1) {
                        total.push({
                          id: item.id,
                          menuDesc: item.desc,
                        });
                      }

                      return total;
                    }, []);

                    console.log(
                      "%ctempList",
                      "background-color: red; color: white",
                      tempList
                    );

                    return tempList;
                  };
                  // don't show details if item is inside From meetings column
                  if (columnGroup !== "fromMeeting") {
                    setModal({
                      visible: true,
                      name: "toDoDetails",
                      data: {
                        todoID: item.id,
                        title: item.desc,
                        dueDate: item.dueDate,
                        archived: false,
                        selectedTodoGroupID: item.toDoGroupId,
                        todoGroupList: toDoGroupList(),
                      },
                    });
                  }
                }}
              >
                <div className={classes.todoTitleContainer}>
                  {/* <h5>{`${
                process.env.NODE_ENV ===
                  "development" && `${item.id}-`
              } ${item.desc}`}</h5> */}
                  <h6>{item.desc}</h6>
                  {columnGroup === "fromMeeting" && (
                    // columnActionsFromMeeting
                    <h6>{item.descOrigin}</h6>
                  )}
                </div>

                {/* -----------------  buttons only for action from meeting column ---------------- */}
                <BottomBar columnGroup={columnGroup} dueDate={item.dueDate} />
                <ButtonActions columnGroup={columnGroup} item={item} />
              </div>
            );
          }}
        </Draggable>
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

const ButtonActions = (props) => {
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { columnGroup, item } = props;

    const { session } = useContext(SessionContext);
    const { userID } = session;

    const { todo, setTodo } = useContext(TodoContext);

    const toDoAdd = (props) => {
      // console.log(props)
      const { desc, toDoGroupID, meetingActionID, response } = props;

      const options = axiosOptions({
        url: `${config.url}/api/to-do-add`,
        method: "post",
        data: {
          userID: userID,
          desc: desc,
          toDoGroupID: toDoGroupID,
          meetingActionID: meetingActionID,
          response: response,
        },
      });
      // console.log("axios options", options);

      axios(options)
        .then(async (response) => {
          // if (200 === response.status) {
          // }
          console.log("axios response", response.data);
          // refetch();

          // setTodo({ ...todo, updateInfo: new Date() });
        })
        .catch((error) => {
          console.log("error is:", error);
          return <ErrorHandler error={error} />;
        });
    };

    if (
      columnGroup === "fromMeeting"
      // columnActionsFromMeeting
    ) {
      return (
        <div className={classes.todoAcceptRejectBottomBar}>
          <CustomPopper
            triggerComponent={(handleOpen) => (
              <Button onClick={handleOpen} link size="xs" color="primary">
                Meeting info
              </Button>
            )}
          >
            <MeetingInfoContent item={item} />
          </CustomPopper>
          <div>
            <Button
              onClick={() => {
                toDoAdd({
                  meetingActionID: item.id,
                  response: false,
                });
              }}
              size="xs"
              color="danger"
              rightSpacing
            >
              {/* <h6>Reject</h6> */}
              Reject
            </Button>
            <Button
              onClick={() => {
                toDoAdd({
                  meetingActionID: item.id,
                  response: true,
                });
              }}
              size="xs"
              color="success"
            >
              Accept
            </Button>
          </div>
        </div>
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

const MeetingInfoContent = ({ item }) => {
  const classes = useStyles();
  console.log("el item es", item);

  return (
    <div className={classes.meetingInfoContent}>
      <GridContainer alignItems="baseline">
        <GridItem xs={4}>
          <h6 className={classes.meetingInfoContentLabel}>Organizer:</h6>
        </GridItem>
        <GridItem xs={8}>
          <h6>{item.meetingOrganizer}</h6>
        </GridItem>
      </GridContainer>
      <GridContainer alignItems="baseline">
        <GridItem xs={4}>
          <h6 className={classes.meetingInfoContentLabel}>Subject:</h6>
        </GridItem>
        <GridItem xs={8}>
          <h6>{item.meetingTitle}</h6>
        </GridItem>
      </GridContainer>
      <GridContainer alignItems="baseline">
        <GridItem xs={4}>
          <h6 className={classes.meetingInfoContentLabel}>Date:</h6>
        </GridItem>
        <GridItem xs={8}>
          <h6>{formatDateTimeMedium2(item.meetingDate)}</h6>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const BottomBar = (props) => {
  const { columnGroup, dueDate } = props;
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    if (dueDate !== null) {
      return (
        // !columnActionsFromMeeting
        columnGroup !== "froMeeting" && (
          <div className={classes.todoBottomBar}>
            {/* <div> */}
            <h6 className={classes.daysToDueDateLabel}>
              {timeDifference(dueDate, new Date(), true, "days")}
            </h6>
            {/* </div> */}
          </div>
        )
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

const ColumnFooter = (props) => {
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { column, columnGroup, setAddSectionOpen } = props;
    /* hide the "add section" from the actions from meetings column and the done column */
    if (
      ["toDo", "inProgress"].includes(columnGroup)

      // !columnActionsFromMeeting && !columnDone
    ) {
      return (
        <AddSection
          columnID={column.id}
          columnGroup={columnGroup}
          sendInfoToParent={(visible) => setAddSectionOpen(visible)}
        />
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

const AddSection = ({ columnID, sendInfoToParent, columnGroup }) => {
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const classes = useStyles();
    const { session, setSession } = useContext(SessionContext);
    const { accountID, userID, isOwner, firstUse } = session;

    const { todo, modal, setModal } = useContext(TodoContext);

    const newTaskRef = useRef();
    const addSectionRef = useRef();

    const [addTask, setAddTask] = useState({
      visible: false,
      columnId: null,
      // value: "",
    });

    const { loading, data, error, refetch } = useQuery(toDoMisc, {
      variables: { accountID, userID },
      // variables: { userID: userID, searchTerm: parameterList.searchTerm },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      skip: firstUse,
    });

    // once clicking on add task, inmediately focus on text box
    useEffect(() => {
      if (addTask.visible) {
        newTaskRef.current.focus();
      }
    }, [addTask]);

    // const { columnID } = props;
    // console.log("column", {
    //   addTaskColumn: addTask.columnId,
    //   column: columnID,
    // });
    const NonEditMode = () => {
      return (
        // <div>
        <Button
          color={columnGroup === "toDo" ? "danger" : "gray"}
          // fullWidth
          size="xs"
          colored
          // round
          onClick={() => {
            // console.log("columnid", columnID);
            // sendInfoToParent(true);
            const inPlanLimits =
              data.spPlanLimits?.nodes[0]?.createToDo || false;
            console.log("inPlanLimits", inPlanLimits);

            if (inPlanLimits === true) {
              setAddTask({
                // value: "",
                visible: true,
                columnId: columnID,
              });
            } else {
              if (isOwner) {
                setModal({
                  visible: true,
                  name: "notInPlanOwner",
                  data: {
                    todoCapacity:
                      data.spPlanLimits?.nodes[0]?.toDoSlotsCapacity,
                  },
                });
              } else {
                setModal({
                  visible: true,
                  name: "notInPlanNotOwner",
                  data: {
                    todoCapacity:
                      data.spPlanLimits?.nodes[0]?.toDoSlotsCapacity,
                  },
                });
              }
            }
          }}
        >
          + Add task
        </Button>
        // </div>
      );
    };

    const EditMode = forwardRef((props, ref) => {
      const { todo, setTodo, initialDataRef } = useContext(TodoContext);
      const { originalData, currentData } = todo;

      const [newTaskValue, setNewTaskValue] = useState("");
      const toDoAdd = (props) => {
        // console.log(props)
        const { desc, toDoGroupID, meetingActionID, response } = props;

        const options = axiosOptions({
          url: `${config.url}/api/to-do-add`,
          method: "post",
          data: {
            userID: userID,
            desc: desc,
            toDoGroupID: toDoGroupID,
            meetingActionID: meetingActionID,
            response: response,
          },
        });
        // console.log("axios options", options);

        axios(options)
          .then(async (response) => {
            // if (200 === response.status) {
            // }
            console.log("axios response", response.data);
            // refetch();

            setTodo({ ...todo, updateInfo: new Date() });
          })
          .catch((error) => {
            console.log("error is:", error);
            return <ErrorHandler error={error} />;
          });
      };

      return (
        <div ref={ref} style={{ display: "contents" }}>
          {/* <div className={classes.inputNewTask}> */}
          <CustomMainInput
            // label={index === 0 && "Objectives"}
            // name="objectives"
            refID={newTaskRef}
            key={columnID}
            height="80px"
            variant="multiline"
            value={newTaskValue}
            placeholder="Enter a name for this task"
            onChange={(event) => {
              setNewTaskValue(event.target.value);
            }}
            rightMargin={false}

            // onBlur={fprops.handleBlur("name")}
            // error={fprops.touched.name && fprops.errors.name}
          />
          {/* </div> */}
          <div className={classes.addButtonsContainer}>
            <Button
              disabled={newTaskValue.length === 0}
              color="primary"
              size="xs"
              onClick={(e) => {
                console.log("erro", {
                  userID: userID,
                  desc: addTask.value,
                  toDoGroupID: parseInt(columnID),
                });

                const newColumns = currentData.map((item) => {
                  if (item.id === columnID) {
                    {
                      const newItems = [...item.items];
                      newItems.push({
                        id: 1000,
                        desc: newTaskValue,
                        orderBy: 1000,
                        toDoGroupId: parseInt(columnID),
                        dueDate: null,
                        toDoPriorityId: 1,
                      });
                      return {
                        ...item,
                        items: newItems,
                      };
                    }
                  } else {
                    return item;
                  }
                });
                // console.log("newColumns", newColumns);
                console.log(
                  "%cNewColumns",
                  "background-color: red; color: white",
                  newColumns
                );

                initialDataRef.current = newColumns;
                setTodo({ ...todo, currentData: newColumns });
                toDoAdd({
                  userID: userID,
                  desc: newTaskValue,
                  toDoGroupID: parseInt(columnID),
                });

                setAddTask({ ...addTask, visible: false });
              }}
              width={70}
            >
              Save
            </Button>
            {/* <Button
              color="primary"
              justIcon
              simple
              onClick={() => {
                sendInfoToParent(false);
                setAddTask({ ...addTask, visible: false });
              }}
            >
              <CloseIcon style={{ width: "14px", height: "14px" }} />
            </Button> */}
          </div>
        </div>
      );
    });

    const handleClickOutside = (event) => {
      if (
        addSectionRef.current &&
        !addSectionRef.current.contains(event.target)
      ) {
        setAddTask({ ...addTask, visible: false });
      }
    };

    useEffect(() => {
      if (addTask.visible) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [addTask.visible]);

    if (addTask.visible && columnID === addTask.columnId) {
      const addSectionContainerClasses = classNames({
        [classes.addSectionContainer]: true,

        [classes[`addSectionVisible${sentenceCase(columnGroup)}`]]: columnGroup,
      });

      return (
        <div className={addSectionContainerClasses}>
          <EditMode ref={addSectionRef} />
        </div>
      );
    } else {
      return (
        <div className={classes.addSectionContainer}>
          <NonEditMode />
        </div>
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};
