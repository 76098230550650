import { gql } from "@apollo/client";

export const addInvoice = gql`
  mutation addInvoice(
    $companyID: Int!
    $lineCashCount: Int
    $periodID: Int
    $planID: Int
    $storeCount: Int
  ) {
    spInvoiceCreate(
      input: {
        companyId1: $companyID
        lineCashCount1: $lineCashCount
        periodId1: $periodID
        planId1: $planID
        storeCount1: $storeCount
      }
    ) {
      clientMutationId
    }
  }
`;

// export const subscriptionNew = gql`
//   mutation subscription(
//     $companyID: Int!
//     $lineCashCount: Int
//     $periodID: Int
//     $planID: Int
//     $storeCount: Int
//   ) {
//     spSubscription(
//       input: {
//         companyId1: $companyID
//         newLineCashCount: $lineCashCount
//         newPeriodId: $periodID
//         newPlanId: $planID
//         newStoreCount: $storeCount
//       }
//     ) {
//       results {
//         amountToPay
//         currentLineCashCount
//         currentPlanId
//         daysToExpire
//         description
//         discountAmountLineCash
//         discounts
//         discountAmountPeriod
//         invoicingPeriod
//         lineCashCount
//         newValidFrom
//         newValidUntil
//         nextInvoiceStart
//         nextLineCashLevel
//         nextSubscriptionStart
//         nextTotalDiscount
//         periodId
//         planId
//         planName
//         renewalPeriod
//         storeCount
//         subscriptionChange
//         subscriptionIncrease
//         subscriptionPeriodChangeCode
//         subtotal
//         taxes
//         validUntil
//         waitChangeSubscription
//       }
//     }
//   }
// `;

export const subscriptionCurrent = gql`
  query subscriptionCurrent($userID: Int!, $languageID: String!) {
    spSubscriptionCurrent(languageId1: $languageID, userId1: $userID) {
      nodes {
        periodId
        periodName
        slotsPlan
        slotsUsed
        slotsFree
        stageId
        validUntil
        trialUntil
        daysTrialToExpire
      }
    }
  }
`;
