import axios from "axios";
import { useEffect, useState } from "react";

// https://www.youtube.com/watch?v=NZKUirTtxcg
export default function useFetch(props) {
  // console.log("start useFetch");
  const {
    params,
    endDate,
    url,
    method,
    direction = "down",
    pageNumber,
    pageSize,
    skip, // use to detect when the fetch should not be performed
    // loading = true,
  } = props;

  const paramsParsed = JSON.parse(params);

  const [loadingFetch, setLoadingFetch] = useState(true);
  const [errorFetch, setErrorFetch] = useState(null);
  const [dataFetch, setDataFetch] = useState(null);
  const [hasMoreFetch, setHasMoreFetch] = useState({ up: true, down: true });
  const [refetchData, setRefetchData] = useState(null);

  useEffect(() => {
    // console.log("%cClears data", "background-color: green; color: white");
    // avoid clearing if skip is set to true
    if (skip !== true) {
      setDataFetch(null);
    }
  }, [params]);

  // useEffect(() => {
  //   // skip fetch call if skip is true
  //   if (skip) return;

  //   // Define the async function inside the useEffect
  //   const fetchData = async () => {
  //     try {
  //       setLoadingFetch(true);
  //       setErrorFetch(null);

  //       let cancel;

  //       const options = {
  //         method,
  //         url,
  //         params: {
  //           ...paramsParsed,
  //           pageNumber,
  //           direction,
  //           endDate,
  //           pageSize,
  //         },
  //         // cancelToken: new axios.CancelToken((c) => (cancel = c)), // use to stop previous fetch when calling multiple times e.g. typing search box
  //       };

  //       console.log(
  //         "%c    fetch with these options      ",
  //         "background-color: gray; color: white",
  //         options
  //       );
  //       const res = await axios(options);

  //       console.log(
  //         `%csuccessful new fetch from ${url}`,
  //         "background-color: green; color: white",
  //         {
  //           dataReceived: res.data,
  //           params: {
  //             ...paramsParsed,
  //             pageNumber,
  //             direction,
  //             endDate,
  //             pageSize,
  //           },
  //         }
  //       );

  //       setDataFetch((prevData) => {
  //         if (pageNumber === undefined) {
  //           return res.data;
  //         } else {
  //           if (direction === "down") {
  //             return prevData === null
  //               ? [...res.data?.info]
  //               : [...prevData, ...res.data?.info];
  //           } else {
  //             return prevData === null
  //               ? [...res.data?.info]
  //               : [...res.data?.info, ...prevData];
  //           }
  //         }
  //       });

  //       if (pageNumber === undefined) {
  //         setHasMoreFetch(null);
  //       } else {
  //         if (direction === "down") {
  //           setHasMoreFetch({
  //             ...hasMoreFetch,
  //             down: res.data.info.length > 0,
  //           });
  //         } else {
  //           setHasMoreFetch({
  //             ...hasMoreFetch,
  //             up: res.data.info.length > 0,
  //           });
  //         }
  //       }

  //       setLoadingFetch(false);
  //     } catch (e) {
  //       console.log(
  //         "%cUNsuccessful",
  //         "background-color: red; color: white",
  //         e.message
  //       );

  //       if (axios.isCancel(e)) return;
  //       setErrorFetch(e);
  //     }
  //   };

  //   // Call the async function
  //   fetchData();

  //   // Return the cleanup function
  //   // return () => cancel();
  // }, [params, pageNumber, direction, refetchData]);

  useEffect(() => {
    // skip fetch call if skip is true
    if (skip !== true) {
      try {
        setLoadingFetch(true);
        setErrorFetch(null);

        let cancel;

        const options = {
          method: method,
          url: url,
          params: {
            ...paramsParsed,
            pageNumber: pageNumber,
            direction: direction,
            endDate: endDate,
            pageSize: pageSize,
          },
          cancelToken: new axios.CancelToken((c) => (cancel = c)), // use to stop previous fetch when calling multiple times e.g. typing search box
        };

        console.log(
          "%c    fetch with these options      ",
          "background-color: gray; color: white",
          options
        );

        axios(options)
          .then((res) => {
            console.log(
              `%csuccessful new fetch from ${url}`,
              "background-color: green; color: white",
              {
                dataReceived: res.data,
                params: {
                  ...paramsParsed,
                  pageNumber: pageNumber,
                  direction: direction,
                  endDate: endDate,
                  pageSize: pageSize,
                },
              }
            );

            setDataFetch((prevData) => {
              // console.log(
              //   "%cpageNmb",
              //   "background-color: red; color: white",
              //   pageNumber
              // );

              // if no pagenumber is provided, it means no paging is required
              if (pageNumber === undefined) {
                return res.data;
              } else {
                // joins previously fetch data with new incoming data
                //

                if (direction === "down") {
                  // if the previous data is null (first load) and scrolling down,
                  // returns only the new data, otherwise returns
                  // FIRST the previous data and THEN the new data
                  return prevData === null
                    ? [...res.data?.info]
                    : [...prevData, ...res.data?.info];
                } else {
                  // if the previous data is null (first load) and scrolling up,
                  // returns only the new data, otherwise returns
                  // FIRST the new data and THEN the preivous data
                  return prevData === null
                    ? [...res.data?.info]
                    : [...res.data?.info, ...prevData];
                }
              }
            });

            if (pageNumber === undefined) {
              setHasMoreFetch(null);
            } else {
              if (direction === "down") {
                setHasMoreFetch({
                  ...hasMoreFetch,
                  down: res.data.info.length > 0,
                });
              } else {
                setHasMoreFetch({
                  ...hasMoreFetch,
                  up: res.data.info.length > 0,
                });
              }
            }

            setLoadingFetch(false);
          })
          .catch((e) => {
            console.log(
              "%cUNsuccessful",
              "background-color: red; color: white",
              e.message
            );
            // this ignore a previous request like when typing a search box
            if (axios.isCancel(e)) return;
            setErrorFetch(e);
          });
        // console.log("%cPrueba", "background-color: orange; color: white");
        // setLoadingFetch(false);
        return () => cancel();
      } catch (e) {
        console.log("error", e);
      }
    }
    // else {
    //   setLoadingFetch(false);
    // }
  }, [params, pageNumber, direction, refetchData]);

  const refetch = () => setRefetchData(new Date());
  // console.log("%cdata sent", "background-color: blue; color: white", {
  //   loadingFetch,
  //   errorFetch,
  //   dataFetch,
  //   hasMoreFetch,
  // });
  if (skip !== true) {
    return {
      loadingFetch,
      errorFetch,
      dataFetch,
      hasMoreFetch,
      refetch,
    };
  } else {
    return {
      loadingFetch: false,
      errorFetch: null,
      dataFetch: null,
      hasMoreFetch: false,
      refetch,
    };
  }
}
