const urlToUse =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://api.orquesti.com";

const urlToSocket =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3002"
    : "https://socket.orquesti.com";

// const urlToUse = "https://api.orquesti.com";

// const urlToSocket = "https://socket.orquesti.com";

// const urlToUse = "http://localhost:3001";

// const urlToSocket = "http://localhost:3002";

export default {
  url: urlToUse,
  urlToSocket: urlToSocket,
  timeout: 10000,
  configHeaders: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
