import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import addDays from "date-fns/addDays";
import addWeeks from "date-fns/addWeeks";
import { removeTime } from "functions/Common";

export const ActionsContext = createContext();

export const ActionsInfo = (props) => {
  const endDateCalc = () => {
    const currentDay = new Date().getDay();
    const currentDayAdjusted = currentDay === 0 ? 7 : currentDay;
    //calculates days to add to finish week
    const daysToAdd = 7 - currentDayAdjusted;
    // calculates the date that corresponds to end of the curren week
    const endOfWeek = addDays(new Date(), daysToAdd);

    const startOfWeek = addDays(new Date(), (currentDayAdjusted - 1) * -1);
    const startDate = removeTime(addWeeks(startOfWeek, -1)); // addWeeks(startOfWeek, -1);
    const endDate = removeTime(addWeeks(endOfWeek, 1));

    // console.log("dates", {
    //   currentDay,
    //   currentDayAdjusted,
    //   daysToAdd,
    //   endOfWeek,
    //   startOfWeek,
    //   startDate,
    //   endDate,
    // });
    return { startDate, endDate };
  };

  const initialState = {
    parameterList: {
      filterID: 2,
      orderByID: 0,
      startDate: endDateCalc().startDate,
      endDate: endDateCalc().endDate,
      filters: [],
      sorts: [],
      selectedMeetingActionID: null,
    },
    pageNumber: 1,
    searchTerm: "",
    hasScrolled: false,
    refreshData: new Date(),
  };

  const [actions, setActionsNotMemo] = useState(initialState);

  const setActions = useCallback((newValue) => {
    setActionsNotMemo(newValue);
  }, []);

  const memoizedValues = useMemo(
    () => ({
      actions,
      setActions,
    }),
    [actions, setActions]
  );

  return (
    <ActionsContext.Provider value={memoizedValues}>
      {props.children}
    </ActionsContext.Provider>
  );
};
export default ActionsInfo;
