import { useLocation, useNavigate } from "react-router-dom";
// @material-ui/core components
import { SvgIcon } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";

// core components
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import ErrorHandler from "components/Error/Error";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// data
import { useQuery } from "@apollo/client";
import { invoice } from "graphql/gqlAccountInvoice";

// icon
import { ReactComponent as OrquestiLogo } from "assets/img/OrquestiIsotipo.svg";

// style
import styles from "assets/jss/material-dashboard-react/views/accountInvoiceStyle";
import { formatDateTimeMedium3 } from "functions/Common";
// import Moment from "react-moment";

const useStyles = makeStyles(styles);

export default function Invoice(props) {
  // console.log(sessionStorage.getItem("companyID"));
  const location = useLocation();
  // console.log("el invoice id", location.state);
  // const invoiceID = location.state.invoiceID;
  const { invoiceID } = props;

  // const companyID = parseInt(sessionStorage.getItem("companyID"));
  // const userAccountID = parseInt(sessionStorage.getItem("userAccountID"));
  // console.log("el company ID es", companyID);
  const classes = useStyles();
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(invoice, {
    variables: { invoiceID },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  const InvoiceMain = () => {
    if (loading) return <ActivityIndicator fullscreen />;

    if (error) {
      return (
        <ErrorHandler
          message={error.message}
          btnActionFunction2={() => refetch()}
        />
      );
    }

    if (data && data.allDocumentHeaders) {
      const {
        address1,
        city,
        created,
        email,
        invoiceName,
        legalNumber,
        phone,
        pobox,
        state,
      } = data.allDocumentHeaders.nodes[0];
      const { name: country } =
        data.allDocumentHeaders.nodes[0].countryByCountryId;
      const {
        address: orquestiAddress,
        city: orquestiCity,
        email: orquestiEmail,
        legalNumber: orquestiLegalNumber,
        invoiceName: orquestiInvoiceName,
        phone: orquestiPhone,
        pobox: orquestiPobox,
        state: orquestiState,
      } = data.allDocumentHeaders.nodes[0].companyInfoByInvoicedFrom;

      const { name: orquestiCountry } =
        data.allDocumentHeaders.nodes[0].companyInfoByInvoicedFrom
          .countryByCountryId;

      const InvoiceHeader = () => (
        <GridContainer
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <GridItem xs={12} md={6}>
            <div className={classes.orquestiContainer}>
              <div className={classes.logoContainer}>
                <SvgIcon style={{ fontSize: 80 }}>
                  <OrquestiLogo />
                </SvgIcon>
              </div>
              <div>
                <Divider
                  className={classes.verticalDivider}
                  orientation="vertical"
                  variant="middle"
                />
              </div>
              <div className={classes.detailsContainer}>
                <h4 className={classes.mainText}>
                  {orquestiInvoiceName.toUpperCase()}
                </h4>
                <h6 className={classes.bodyText}>{orquestiLegalNumber}</h6>
                <h6
                  className={classes.bodyText}
                >{`${orquestiAddress}, ${orquestiPobox}`}</h6>
                <h6
                  className={classes.bodyText}
                >{`${orquestiCity}, ${orquestiState}, ${orquestiCountry} `}</h6>
                <h6 className={classes.bodyText}>{orquestiPhone}</h6>
                <h6 className={classes.bodyText}>{orquestiEmail}</h6>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} md={6}>
            <div className={classes.orquestiContainer}>
              <div className={classes.detailsContainer}>
                <h4 className={classes.mainText}>
                  {invoiceName.toUpperCase()}
                </h4>
                <h6 className={classes.bodyText}>{legalNumber}</h6>
                <h6 className={classes.bodyText}>{`${address1}, ${pobox}`}</h6>
                <h6
                  className={classes.bodyText}
                >{`${city}, ${state}, ${country} `}</h6>
                <h6 className={classes.bodyText}>{phone}</h6>
                <h6 className={classes.bodyText}>{email}</h6>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      );

      const InvoiceBody = () => (
        <>
          <GridContainer
            alignItems="flex-start"
            classes={{ container: classes.headerContainer }}
          >
            <GridItem xs={6}>
              <h4>{`INVOICE # ${invoiceID.toString().padStart(8, "0")}`}</h4>
            </GridItem>
            <GridItem xs={6}>
              <h5 className={classes.dateLabel}>
                {/* <Moment format="DD/MM/YYYY">{created}</Moment> */}
                {/* {created} */}
                {formatDateTimeMedium3(created)}
              </h5>
            </GridItem>

            <GridItem xs={12}>
              <Divider className={classes.horizontalSpacing} />
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="center">
            <GridItem xs={10}>
              <h6 className={classes.tableTitle}>DESCRIPTION</h6>
            </GridItem>
            <GridItem xs={2}>
              <h6 className={classes.tableTitleAmount}>AMOUNT</h6>
            </GridItem>
          </GridContainer>
          {data.allDocumentHeaders.nodes[0].documentDetailsByDocumentHeaderId.nodes.map(
            (lineItem) => (
              <GridContainer key={lineItem.id} justifyContent="center">
                <GridItem xs={12}>
                  <Divider />
                </GridItem>
                <GridItem xs={10}>
                  <h5 className={classes.description}>
                    {lineItem.description}
                  </h5>
                </GridItem>

                <GridItem xs={2}>
                  <h6 className={classes.amount}>
                    {formatter.format(lineItem.baseAmount)}
                  </h6>
                </GridItem>
              </GridContainer>
            )
          )}
          <GridContainer justifyContent="center">
            <GridItem xs={12} classes={{ item: classes.totalsSection }}>
              <Divider />
            </GridItem>
            {/* SUBTOTALS */}

            {/* subtotal */}

            <GridItem xs={10}>
              <h6 className={classes.description}>SUBTOTAL</h6>
            </GridItem>
            <GridItem xs={2}>
              <h6 className={classes.amount}>
                {formatter.format(
                  data.allDocumentHeaders.nodes[0].documentDetailsByDocumentHeaderId.nodes.reduce(
                    function (sum, item) {
                      return sum + parseFloat(item.baseAmount);
                    },
                    0
                  )
                )}
              </h6>
            </GridItem>
            {/* <GridItem xs={11}>
              <Divider />
            </GridItem> */}

            {/* discount */}
            <GridItem xs={10}>
              <h6 className={classes.description}>DISCOUNT</h6>
            </GridItem>
            <GridItem xs={2}>
              <h5 className={classes.amount}>
                {formatter.format(
                  data.allDocumentHeaders.nodes[0].documentDetailsByDocumentHeaderId.nodes.reduce(
                    function (sum, item) {
                      return sum + parseFloat(item.discountAmount);
                    },
                    0
                  )
                )}
              </h5>
            </GridItem>
            {/* <GridItem xs={11}>
              <Divider />
            </GridItem> */}

            {/* taxes */}
            <GridItem xs={10}>
              <h5 className={classes.description}>TAXES</h5>
            </GridItem>
            <GridItem xs={2}>
              <h5 className={classes.amount}>
                {formatter.format(
                  data.allDocumentHeaders.nodes[0].documentDetailsByDocumentHeaderId.nodes.reduce(
                    function (sum, item) {
                      return sum + parseFloat(item.taxAmount);
                    },
                    0
                  )
                )}
              </h5>
            </GridItem>
            <GridItem xs={12}>
              <Divider />
            </GridItem>

            {/* total */}
            <GridItem xs={9}>
              <h4 className={classes.description}>TOTAL</h4>
            </GridItem>
            <GridItem xs={3}>
              <h3 className={classes.amount}>
                {formatter.format(
                  data.allDocumentHeaders.nodes[0].documentDetailsByDocumentHeaderId.nodes.reduce(
                    function (sum, item) {
                      return (
                        sum +
                        parseFloat(item.baseAmount) +
                        parseFloat(item.discountAmount) +
                        parseFloat(item.taxAmount)
                      );
                    },
                    0
                  )
                )}
              </h3>
            </GridItem>
          </GridContainer>
        </>
      );

      return (
        <div className={classes.mainContainer}>
          {/* invoice header* /}
          {/* <Orquesti /> */}
          {/* <OrquestiLogo /> */}
          <InvoiceHeader />

          <InvoiceBody />
        </div>
      );
    }
  };

  return <InvoiceMain />;
}
