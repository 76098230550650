const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  leftPanel: {
    background: "linear-gradient(168.65deg, #00084d 47.15%, #3347ff 100%)",
    boxShadow: "7px 0px 30px rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  rightPanel: {
    display: "flex",
    alignItems: "center",
    flexDirection: "Column",
    justifyContent: "center",
    backgrounColor: "white",
  },

  container: {
    height: "100%",
    backgroundColor: "white",
  },

  // mainPanel: {
  //   [theme.breakpoints.up("md")]: {
  //     width: `calc(100% - ${drawerWidth}px)`
  //   },
  //   overflow: "auto",
  //   position: "relative",
  //   float: "right",
  //   ...transition,
  //   maxHeight: "100%",
  //   width: "100%",
  //   overflowScrolling: "touch"
  // },
  // content: {
  //   marginTop: "70px",
  //   padding: "30px 15px",
  //   minHeight: "calc(100vh - 123px)"
  // },
  // container,
  // map: {
  //   marginTop: "70px"
  // }
});

export default appStyle;
