import { gql } from "@apollo/client";

export const saveMeetingTemplate = gql`
  mutation saveMeetingTemplate(
    $userID: Int!
    $desc: String!
    $content: JSON!
    $comment: String
  ) {
    createMeetingTemplate(
      input: {
        meetingTemplate: {
          desc: $desc
          userId: $userID
          content: $content
          comment: $comment
        }
      }
    ) {
      clientMutationId
    }
  }
`;

export const deleteTemplate = gql`
  mutation deleteTemplate($templateID: Int!) {
    deleteMeetingTemplateById(input: { id: $templateID }) {
      clientMutationId
    }
  }
`;

export const templateList = gql`
  query templateList($userID: Int!) {
    allMeetingTemplates(condition: { userId: $userID }) {
      nodes {
        desc
        id
        comment
      }
    }
  }
`;

export const getTemplate = gql`
  query getTemplate($templateID: Int!) {
    meetingTemplateById(id: $templateID) {
      content
    }
  }
`;

export const updateTemplateName = gql`
  mutation updateTemplateName($templateID: Int!, $templateName: String) {
    updateMeetingTemplateById(
      input: { meetingTemplatePatch: { desc: $templateName }, id: $templateID }
    ) {
      clientMutationId
    }
  }
`;
