import { gql } from "@apollo/client";

export const dashboardIndicators = gql`
  query dashboardIndicators($userID: Int!) {
    spDashboardIndicators(userId1: $userID) {
      nodes {
        id
        desc
        value
        color
        tooltipText
      }
    }
  }
`;

export const dashboardCharts = gql`
  query dashboardCharts($userID: Int!, $startDate: Date, $endDate: Date) {
    c1: spDashboardCharts(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 2
    ) {
      nodes {
        dates
        value1
        value2
      }
    }
    c2: spDashboardCharts(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 3
    ) {
      nodes {
        dates
        value1
        value2
      }
    }
    c3: spDashboardCharts(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 4
    ) {
      nodes {
        dates
        value1
        value2
      }
    }
    c4: spDashboardCharts(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 1
    ) {
      nodes {
        dates
        value1
        value2
      }
    }
    c5: spDashboardCharts(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 5
    ) {
      nodes {
        dates
        value1
        value2
      }
    }
    c6: spDashboardCharts(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 6
    ) {
      nodes {
        dates
        value1
        value2
      }
    }
    c7: spDashboardCharts(
      startDate1: $startDate
      userId1: $userID
      endDate1: $endDate
      chartId1: 7
    ) {
      nodes {
        dates
        value1
        value2
      }
    }
  }
`;

// export const dashboardCharts = gql`
//   query dashboardCharts($userID: Int!, $startDate: Date, $endDate: Date) {
//     avgRatingCost: spDashboardCharts(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 1
//     ) {
//       nodes {
//         dates
//         value1
//         value2
//       }
//     }
//     avgMeetingCost: spDashboardCharts(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 2
//     ) {
//       nodes {
//         dates
//         value1
//         value2
//       }
//     }
//     avgDailyTime: spDashboardCharts(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 3
//     ) {
//       nodes {
//         dates
//         value1
//         value2
//       }
//     }
//     avgAttendees: spDashboardCharts(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 4
//     ) {
//       nodes {
//         dates
//         value1
//         value2
//       }
//     }
//     avgRatingAsOrganizer: spDashboardCharts(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 5
//     ) {
//       nodes {
//         dates
//         value1
//         value2
//       }
//     }
//     avgRatingAsAttendee: spDashboardCharts(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 6
//     ) {
//       nodes {
//         dates
//         value1
//         value2
//       }
//     }
//     uncompletedFeedbackAsOrganizer: spDashboardCharts(
//       startDate1: $startDate
//       userId1: $userID
//       endDate1: $endDate
//       chartId1: 7
//     ) {
//       nodes {
//         dates
//         value1
//         value2
//       }
//     }
//   }
// `;
