import { useContext, useState } from "react";
// core components
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomDialog from "components/CustomDialog/CustomDialog";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "components/Snackbar/Snackbar";
import Payment from "views/Payment/Payment";
// data
import { subscriptionCurrent } from "graphql/gqlAccountSubscription";
import { SessionContext } from "contexts/SessionContext";
import { AccountContext } from "contexts/AccountContext";
import { useQuery } from "@apollo/client";
import { NumericFormat } from "react-number-format";

// errors
import { ErrorHandler } from "functions/ErrorBoundary";
// icons
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
// @material-ui/core components
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
// style
import {
  dangerColor,
  defaultFont,
  infoColor,
  primaryColor,
} from "assets/jss/material-dashboard-react";

const styles = {
  cardBody: {
    padding: "40px 40px 0px 40px",
  },

  standardContainer: {
    padding: "10px",
    marginBottom: "20px",
    borderRadius: "10px",
  },

  regularContainer: {
    color: primaryColor[4],
    backgroundColor: primaryColor[9],
  },

  attentionContainer: {
    color: infoColor[4],
    backgroundColor: infoColor[9],
  },

  warningContainer: {
    color: dangerColor[1],
    backgroundColor: dangerColor[5],
  },

  // freeTrialLabel: {
  //   color: infoColor[4],
  //   backgroundColor: infoColor[9],
  //   padding: "10px",
  //   marginBottom: "20px",
  //   borderRadius: "10px",
  // },

  // warningLabel: {
  //   color: "red",
  //   paddingBottom: 10,
  // },
  // infoLabel: {
  //   color: infoColor[4],
  //   paddingBottom: 10,
  // },
  radioLabel: {
    ...defaultFont,
    fontSize: "14px",
    fontWeight: 500,
  },
  mainLabel: {
    fontWeight: 600,
    // paddingBottom: 10,
  },
};

const useStyles = makeStyles(styles);

export default function AccountSubscription(props) {
  // console.log("--------------------------loads subscription");
  const { session, setSession } = useContext(SessionContext);
  const { languageID, stageID, userID } = session;

  const { account, setAccount, modal, setModal } = useContext(AccountContext);
  const { slotsFree, slotsPlan, slotsUsed } = account;

  // const { storeID, storeName } = props;
  const classes = useStyles();

  const [calcData, setCalcData] = useState({});

  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    messageType: "",
    message: "",
  });

  const { loading, data, error, refetch } = useQuery(subscriptionCurrent, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      userID: userID,
      languageID: languageID,
    },
  });

  const spacing = 3;

  function NumericFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale={true}
        isNumericString
        // prefix="$"
      />
    );
  }

  const Buttons = () => {
    let buttonName;
    switch (stageID) {
      case 0:

      case 1:
      case 2:
      case 3:
        buttonName = "Create subscription";
        break;
      case 4:
        buttonName = "Change subscription";
        break;
      case 5:
      case 6:
        buttonName = "Renew subscription";
        break;
      case 20:
        buttonName = "Upgrade plan";
    }

    return (
      <>
        {stageID !== 1 && (
          <div className={classes.mainContainer}>
            <GridContainer spacing={spacing}>
              <GridItem xs={6} sm={4} md={3}>
                <Button
                  color="primary"
                  onClick={() =>
                    setModal({ visible: true, name: "ChangeSubscription" })
                  }
                  startIcon={<EditOutlinedIcon />}
                >
                  {buttonName}
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        )}
      </>
    );
  };

  if (loading) {
    return <ActivityIndicator fullscreen />;
  }

  if (error) {
    return <ErrorHandler error={error} buttonActionClose="OK" />;
  }

  if (data && data.spSubscriptionCurrent) {
    // console.log("la data es", data);
    const {
      periodId,
      slotsPlan,
      slotsFree,
      slotsUsed,
      stageId: stageID,
      validUntil,
    } = data.spSubscriptionCurrent.nodes[0];

    const DisplayLabel = () => {
      switch (stageID) {
        case 0:
          return (
            <div
              className={`${classes.standardContainer} ${classes.warningContainer}`}
            >
              <h4>You have no active subscription</h4>
            </div>
          );
        case 1:
          return (
            <div
              className={`${classes.standardContainer} ${classes.regularContainer}`}
            >
              <h4>
                {/* You have been invited to use Orquesti. If you want to invite
                others to create meetings in your organization, you can create
                your own subscription */}
                You have been invited to use Orquesti. No subscription is
                required.
              </h4>
            </div>
          );
        // case 2:
        //   return (
        //     <div
        //       className={`${classes.standardContainer} ${classes.attentionContainer}`}
        //     >
        //       <h4>Your free trial expires in {daysTrialToExpire} days</h4>
        //     </div>
        //   );

        // case 3:
        //   return (
        //     <div
        //       className={`${classes.standardContainer} ${classes.warningContainer}`}
        //     >
        //       <h4 className={classes.mainLabel}>Free trial has expired</h4>
        //       <h5>Update your subscription.</h5>
        //     </div>
        //   );

        // case 5:
        //   return (
        //     <div
        //       className={`${classes.standardContainer} ${classes.attentionContainer}`}
        //     >
        //       <h4>Your subscription expires soon</h4>
        //     </div>
        //   );

        // case 6:
        //   return (
        //     <div
        //       className={`${classes.standardContainer} ${classes.warningContainer}`}
        //     >
        //       <h4>Your subscription has expired</h4>
        //     </div>
        //   );
        case 20:
          return (
            <div
              className={`${classes.standardContainer} ${classes.regularContainer}`}
            >
              <h4>
                You currently have a basic plan with a capacity of {slotsPlan}{" "}
                active users
              </h4>
            </div>
          );
      }
    };

    const SubscriptionInfo = () => {
      // const handleSubmit = async (values) => {
      //   // updates the non calculated data
      //   setCalcData({
      //     ...calcData,
      //     lineCashCount: parseInt(values.lineCashCount),
      //     periodId: parseInt(values.periodId),
      //     planId: parseInt(values.planId),
      //     storeCount: parseInt(values.storeCount),
      //   });
      //   setIsLoading(false);
      // };

      return (
        <>
          {/* period */}
          <GridContainer alignItems="center" spacing={spacing}>
            <GridItem xs={6} sm={4} md={3}>
              <h6 className={classes.label}>Period</h6>
            </GridItem>
            <GridItem xs={6} sm={4} md={3}>
              <FormControl
                component="fieldset"
                className={classes.radioGroup}
                disabled
              >
                <RadioGroup
                  row
                  aria-label="position"
                  name="periodId"
                  value={String(periodId)} // needs to convert to string to be recognized by radiogroup control
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Monthly"
                    classes={{ label: classes.radioLabel }}
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio color="primary" />}
                    label="Yearly"
                    classes={{ label: classes.radioLabel }}
                  />
                </RadioGroup>
              </FormControl>
            </GridItem>
          </GridContainer>
          {/* date valid until */}

          <GridContainer alignItems="baseline" spacing={spacing}>
            <GridItem xs={6} sm={4} md={3}>
              <h6 className={classes.label}>Valid until</h6>
            </GridItem>
            <GridItem xs={6} sm={4} md={2}>
              <CustomMainInput
                disabled
                readOnly
                height="40px"
                name="validUntil"
                value={validUntil}
                variant="input"
              />
            </GridItem>
          </GridContainer>
          {/* slots in plan */}
          <GridContainer alignItems="baseline" spacing={spacing}>
            <GridItem xs={6} sm={4} md={3}>
              <h6 className={classes.label}>Slots total</h6>
            </GridItem>
            <GridItem xs={6} sm={4} md={2}>
              <CustomMainInput
                disabled
                readOnly
                height="40px"
                name="SlotsPlan"
                value={slotsPlan}
                variant="input"
              />
            </GridItem>
          </GridContainer>

          {/* slots used */}
          <GridContainer alignItems="baseline" spacing={spacing}>
            <GridItem xs={6} sm={4} md={3}>
              <h6 className={classes.label}>Slots used</h6>
            </GridItem>
            <GridItem xs={6} sm={4} md={2}>
              <CustomMainInput
                disabled
                readOnly
                height="40px"
                name="Slots used"
                value={slotsUsed}
                variant="input"
              />
            </GridItem>
          </GridContainer>

          {/* slots available */}
          <GridContainer alignItems="baseline" spacing={spacing}>
            <GridItem xs={6} sm={4} md={3}>
              <h6 className={classes.label}>Slots available</h6>
            </GridItem>
            <GridItem xs={6} sm={4} md={2}>
              <CustomMainInput
                disabled
                readOnly
                height="40px"
                name="SlotsFree"
                value={slotsFree}
                variant="input"
              />
            </GridItem>
          </GridContainer>
        </>
      );
      // }
    };

    const SubscriptionCalculations = () => {
      return (
        <>
          {/* amount to pay */}
          <Divider className={classes.divider} />
          <GridContainer alignItems="baseline">
            <GridItem xs={7} sm={4}>
              <h6 className={classes.labelTotal}>Monto a pagar</h6>
            </GridItem>
            {/* <GridItem xs={false} sm={2}></GridItem> */}
            <GridItem xs={5} sm={2}>
              {loading ? (
                <div className={classes.alignRight}>
                  <ActivityIndicator />
                </div>
              ) : (
                <CustomInput
                  alignRight
                  bold
                  formControlProps={{ fullWidth: true, disabled: true }}
                  inputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  name="payment"
                  size="small"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  // value={calcData.amountToPay}
                  value={data.spSubscription.results[0].amountToPay}
                />
              )}
            </GridItem>
            <GridItem xs={false} sm={4}></GridItem>
          </GridContainer>
          <Divider className={classes.divider} />
        </>
      );
      // }
    };

    return (
      <>
        <CardBody fullscreen className={classes.cardBody}>
          <DisplayLabel />
          {[4, 5, 6].includes(stageID) && <SubscriptionInfo />}
          {calcData.invoicingPeriod === true ||
          calcData.subscriptionChange === true ? (
            <SubscriptionCalculations />
          ) : null}

          <Buttons />
        </CardBody>

        <Snackbar
          messageType={showSnackbar.messageType}
          message={showSnackbar.message}
          open={showSnackbar.show}
          close={() => setShowSnackbar({ ...showSnackbar, show: false })}
        />
      </>
    );
  }
}
