import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CustomDialog from "components/CustomDialog/CustomDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import Button from "components/CustomButtons/Button.js";

// data
import parseISO from "date-fns/parseISO";
import { useQuery, useMutation } from "@apollo/client";
import {
  todoPriorities,
  todoDetails,
  updateTodo,
} from "graphql/gqlTodoDetails";
import { undoArchive, restoreArchive } from "graphql/gqlTodoModals";
import { SessionContext } from "../../contexts/SessionContext";
import { TodoContext } from "contexts/TodoContext";
import { axiosOptions, formatDateCustom } from "../../functions/Common";
import { Formik, useFormikContext } from "formik";
import { object, string, date } from "yup";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import config from "config";
import axios from "axios";

import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
// icons
import Gauge from "assets/svgComponent/gauge";
import CloseIcon from "@material-ui/icons/Close";

// style
import format from "date-fns/format";
// import { en, enUS } from "date-fns/locale";

import {
  grayColor,
  primaryColor,
  defaultFont,
} from "assets/jss/material-dashboard-react";

const styles = {
  archivedLabel: {
    // flex: "display",
    // justifyContent: "flex-end",
    textAlign: "center",
    borderRadius: "5px",
    color: "white",
    backgroundColor: grayColor[5],
    padding: "5px",
  },
  closeButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  changesLabel: { fontSize: "12px", lineHeight: "22px", paddingRight: "5px" },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
  },
  deleteButtonContainer: {
    paddingRight: "10px",
  },
  dialogContent: {
    overflowY: "hidden",
    paddingBottom: "40px",
  },
  dialogTitle: { backgroundColor: grayColor[7], padding: "5px 20px" },
  errorLabel: {
    ...defaultFont,
    fontSize: "10px",
    fontWeight: 500,
    color: "red",
    paddingLeft: "10px",
  },
  fromMeetingLabel: {
    margin: "0px",
    color: grayColor[1],
    fontWeight: 300,
  },
  label: {
    ...defaultFont,
    fontSize: "12px",
    paddingLeft: "10px",
    color: grayColor[0],
    marginTop: ".2rem",
  },
  listLabel: {
    paddingRight: "10px",
    color: grayColor[3],
  },
  listTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  textContent: {
    ...defaultFont,
    fontSize: "14px",
    fontWeight: 500,
    // paddingLeft: "10px",
    color: grayColor[0],
  },
  textContainer: { marginBottom: "15px" },
  topBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleAndErrorContainer: {
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleInput: {
    ...defaultFont,
    fontSize: "18px",
    color: grayColor[1],
    fontWeight: 500,
    padding: "0px",
    borderRadius: "5px",
    width: "100%",
    // boxSizing: "border-box",
  },
  titleInputNotFocused: {
    borderWidth: "0px",
  },
  titleInputFocused: {
    // boxShadow: "0px 0px",
    border: `1px solid ${primaryColor[5]}`,
    backgroundColor: primaryColor[9],
  },
};
const useStyles = makeStyles(styles);

export default function TodoDetails(props) {
  // console.log("starts to do details");
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    // const { setVisibleStatus, visibleStatus, sendInfoToParent } = props; // const companyID = parseInt(sessionStorage.getItem("companyID"));
    const { sendInfoToParent } = props; // const companyID = parseInt(sessionStorage.getItem("companyID"));

    const classes = useStyles();

    const { session, setSession } = useContext(SessionContext);
    const { languageID } = session;

    const { todo, setTodo, modal, setModal, setShowSnackbar, initialDataRef } =
      useContext(TodoContext);

    let archiving = false;

    const [saveState, setSaveState] = useState({
      isSaving: false,
      infoChanged: false,
    });

    const [modalDetails, setModalDetails] = useState({
      visible: false,
      name: "",
      data: {},
    });

    const formRef = useRef();
    const titleRef = useRef(null);
    const [titleFocused, setTitleFocused] = useState(false);

    const { selectedTodoGroupID, todoGroupList, todoID, archived } = modal.data;
    const [updateInfo, setUpdateInfo] = useState(false);
    const [toDoDetailsInfo, setToDoDetailsInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { loading, data, error, refetch } = useQuery(todoPriorities, {
      variables: { languageID: languageID },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

    const [undoArchiveMut] = useMutation(undoArchive, {
      onCompleted: () => {
        setTodo({
          ...todo,
          updateInfo: new Date(),
          updateArchived: new Date(),
        });

        setShowSnackbar({
          show: true,
          message: "Card restored successfully",
        });
      },
    });

    useEffect(() => {
      // setIsLoading(true);
      const options = axiosOptions({
        url: `${config.url}/api/to-do-details`,
        method: "get",
        params: {
          todoID: todoID,
        },
      });
      // console.log("axios options", options);

      axios(options)
        .then(async (response) => {
          console.log("axios response to do details", response.data);
          setIsLoading(false);
          setToDoDetailsInfo(response.data.info);
        })
        .catch((error) => {
          // console.log("error is:", error);
          setIsLoading(false);
          return <ErrorHandler error={error} />;
        });
    }, [updateInfo]);

    const toDoUpdateItem = (props) => {
      const options = axiosOptions({
        url: `${config.url}/api/to-do-update-item`,
        method: "post",
        data: {
          ...props,
        },
      });

      axios(options)
        .then(async (response) => {
          await refetch();

          setSaveState({
            isSaving: false,
            infoChanged: true,
          });

          // refresh main screen with changes
          // (should be changed to only trigger this when there are changes that are displayed in main screen)

          if (modal.name === "confirmDelete" || archiving === true) {
            setModal({
              visible: false,
              name: "",
              data: { todoID },
            });
          }

          // if archiving, show snackbar
          if (archiving === true) {
            setTodo({
              ...todo,
              updateInfo: new Date(),
              updateArchived: new Date(),
            });
            setShowSnackbar({
              show: true,
              message: "Card archived successfully",
              undo: () => {
                undoArchiveMut({
                  variables: {
                    todoID: todoID,
                  },
                });
              },
            });
          } else {
            setTodo({ ...todo, updateInfo: new Date() });
          }
        })
        .catch((error) => {
          setSaveState({
            isSaving: false,
            showIsSaving: false,
            infoChanged: true,
          });
          return <ErrorHandler error={error} />;
        });
    };

    const GroupMenu = ({ fprops }) => {
      const [groupSelected, setGroupSelected] = useState(selectedTodoGroupID);
      // console.log("groupSelected", groupSelected);
      // adds the show all and change from desc to menuDesc
      // console.log("groupList", groupList);
      // let groupListWithAll = groupList.map((item) => ({
      //   id: item.id,
      //   menuDesc: item.desc,
      // }));

      // groupListWithAll = [{ id: 0, menuDesc: "All groups" }, ...groupListWithAll];

      // console.log("groupListWithAll", groupListWithAll);

      // extracts the button description from the selected menu item
      const index = todoGroupList.findIndex(
        (item) => item.id === groupSelected
      );
      // console.log("index", index);

      // const itemSelected = todoGroupList[index] || { id: 0, menuDesc: "" };
      // console.log("itemSelected", itemSelected);

      return (
        <div className={classes.listTitleContainer}>
          <h6 className={classes.listLabel}>List: </h6>
          <CustomMainInput
            color="primary"
            // canEdit
            dropDownList={todoGroupList}
            // iconStart={<FeatherIcon icon="eye" />}
            value={fprops.values.toDoGroupID}
            variant="dropdown"
            dropDownFunction={(item) => {
              console.log("se actualiza el item seleccionado", item);
              fprops.setFieldValue("toDoGroupID", item.id);
              fprops.handleChange("toDoGroupID");
              //   sendInfoToParent({ parameter: "group", value: item.id });
            }}
          />
        </div>
      );
    };

    const handleSubmit = async (values) => {
      toDoUpdateItem({
        todoID: todoID,
        title: values.title,
        description: values.description,
        dueDate: values.dueDate || "", //  === "" ? null : `${values.dueDate}T00:00:000Z`,
        toDoGroupID: values.toDoGroupID,
        toDoPriorityID: values.toDoPriorityID,
      });
    };

    const spacing = 1;

    if (!isLoading && data && data.allLanguages) {
      const {
        toDoPriorityID,
        description,
        createdDate,
        title,
        dueDate,
        fromMeeting,
        toDoGroupID,
      } = toDoDetailsInfo;
      const initialValues = {
        title: title || "",
        // dueDate: dueDate == null ? "" : format(new Date(dueDate), "yyyy-MM-dd"),
        dueDate: dueDate,
        description: description || "",
        toDoPriorityID: toDoPriorityID,
        toDoGroupID: toDoGroupID,
      };
      // console.log("----los initial values son", initialValues);

      const schema = object().shape({
        title: string().nullable().required("Title is required"),
        description: string().nullable(),

        // dueDate: date()
        //   .nullable()
        //   .required("Start date is required")
        //   .test("is-greater", "Date can't be in the past", function (value) {
        //     const daysDifference = differenceInCalendarDays(value, new Date());
        //     console.log("daysDifference", daysDifference);
        //     return daysDifference >= 0 ? daysDifference >= 0 : false; // validation passed
        //   }),
        // toDoPriorityID: number(),
      });

      const autoSave = (formik) => {
        // don't autosave if the board is archived
        if (!archived) {
          const debounceMs = 1000;
          const debouncedSubmit = useCallback(
            debounce(() => {
              return formik.submitForm();
            }, debounceMs),
            [formik.submitForm, debounceMs]
          );

          useEffect(() => {
            // console.log("useEffect 2");
            if (saveState.isSaving === true) {
              debouncedSubmit();
            }
          }, [saveState.isSaving]);

          useEffect(() => {
            // console.log("useEffect 1");
            if (
              !isEqual(formik.values, initialValues) &&
              !formik.isSubmitting &&
              !saveState.isSaving
            ) {
              setSaveState({ ...saveState, isSaving: true });
            }
          }, [formik.values]);

          return null;
        }
      };

      return (
        <>
          <Formik
            innerRef={formRef}
            // initialValue={[]}
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validationSchema={schema}
          >
            {(fprops) => {
              // sendInfoToParent(attendees, () => fprops.handleSubmit);
              // formErrors(fprops.handleSubmit)
              return (
                <Dialog
                  // classes={{ paper: classes.mainDialog }}
                  open={modal.visible || false}
                  maxWidth="sm"
                  fullWidth={true}
                >
                  <DialogTitle
                    // className={classes.dialogTitle}
                    disableTypography
                    classes={{ root: classes.dialogTitle }}
                  >
                    <GridContainer spacing={spacing}>
                      <GridItem xs={12}>
                        <div className={classes.topBar}>
                          <GroupMenu fprops={fprops} />
                          <div className={classes.closeButtonContainer}>
                            <h6 className={classes.changesLabel}>
                              {saveState.isSaving && "Saving changes"}
                            </h6>
                            <Button
                              color="gray"
                              // disabled={saveState.isSaving}
                              disabled={saveState.isSaving}
                              justIcon
                              simple
                              onClick={() => {
                                setModal({ visible: false, name: "" });

                                //refresh the main to do screen

                                // sendInfoToParent();
                              }}
                            >
                              <CloseIcon style={{ color: grayColor[3] }} />
                            </Button>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </DialogTitle>

                  <DialogContent className={classes.dialogContent}>
                    <>
                      <GridContainer spacing={spacing} alignItems="center">
                        <GridItem xs={12}>
                          <div className={classes.titleContainer}>
                            {/* title */}
                            <div className={classes.titleAndErrorContainer}>
                              <input
                                ref={titleRef}
                                className={`${classes.titleInput} ${
                                  titleFocused
                                    ? classes.titleInputFocused
                                    : classes.titleInputNotFocused
                                }`}
                                disabled={archived}
                                onChange={fprops.handleChange("title")}
                                onFocus={(item) => {
                                  console.log("onFocus");
                                  setTitleFocused(true);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    console.log("onEnter");
                                    titleRef.current.blur();
                                  }
                                  if (["Enter", "Escape"].includes(e.key)) {
                                    console.log("onEscape");
                                  }
                                }}
                                onBlur={() => {
                                  if (fprops.errors.title === undefined) {
                                    setTitleFocused(false);
                                  }
                                  console.log("onblur");
                                  fprops.handleBlur("title");
                                }}
                                // placeholder={column.desc}
                                value={fprops.values.title}
                              />

                              <p className={classes.errorLabel}>
                                {fprops.errors.title}
                              </p>
                            </div>

                            {/* <CustomMainInput
                            color="transparent"
                            dropDownList={menuList}
                            justIcon
                            hideCheckSelected
                            iconStart={<MoreHorizIcon />}
                            value={fprops.values.toDoPriorityID}
                            variant="dropdown"
                            dropDownFunction={(item) => {
                              fprops.setFieldValue("toDoPriorityID", item.id);
                              fprops.handleChange("toDoPriorityID");
                            }}
                          /> */}

                            {/* gauge priority */}

                            <CustomMainInput
                              color="transparent"
                              dropDownList={
                                data.allLanguages.nodes[0].to_do_priorities
                                  .nodes
                              }
                              disabled={archived}
                              justIcon
                              iconStart={
                                <Gauge
                                  size={28}
                                  priorityID={fprops.values.toDoPriorityID || 1}
                                />
                              }
                              value={fprops.values.toDoPriorityID}
                              variant="dropdown"
                              dropDownFunction={(item) => {
                                fprops.setFieldValue("toDoPriorityID", item.id);
                                fprops.handleChange("toDoPriorityID");
                              }}
                            />
                          </div>
                          {/* from meeting label */}
                          {fromMeeting?.length > 0 && (
                            <h6 className={classes.fromMeetingLabel}>
                              {fromMeeting}
                            </h6>
                          )}
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        spacing={spacing}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                      >
                        <GridItem xs={12} sm={8} md={7}>
                          <CustomMainInput
                            label="Description"
                            // name="objectives"
                            disabled={archived}
                            variant="multiline"
                            height="400px"
                            value={fprops.values.description || ""}
                            onChange={fprops.handleChange("description")}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={8} md={5}>
                          <GridContainer
                            spacing={3}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                          >
                            <GridItem xs={12}>
                              <CustomMainInput
                                // error={
                                //   fprops.touched.dueDate &&
                                //   fprops.errors.dueDate
                                // }
                                height="40px"
                                disabled={archived}
                                label="Due date"
                                placeholder="dd-mm-yyyy"
                                name="dueDate"
                                // onBlur={fprops.handleBlur("startDate")}
                                onChange={(e) => {
                                  console.log("el cambio es ", e);
                                  fprops.handleChange("dueDate")(
                                    e === null ? "" : e.toISOString()
                                  );
                                }}
                                value={fprops.values.dueDate}
                                variant="inputDate"
                              />
                            </GridItem>
                            <GridItem xs={12}>
                              <CustomMainInput
                                disabled
                                height="40px"
                                label="Created date"
                                value={formatDateCustom(
                                  createdDate,
                                  "dd/MM/yyyy"
                                )}
                                // value={createdDate}
                                variant="noInput"
                              />
                            </GridItem>

                            <GridItem xs={12}>
                              {/* <Button
                            color="danger"
                            // justIcon
                            onClick={() => {
                              console.log("pending");
                            }}
                          >
                            <DeleteOutlineIcon />
                          </Button> */}
                              <div className={classes.buttonsContainer}>
                                <div className={classes.deleteButtonContainer}>
                                  <Button
                                    color="danger"
                                    // startIcon={<CancelOutlinedIcon />}
                                    onClick={() =>
                                      setModalDetails({
                                        visible: true,
                                        name: "confirmDelete",
                                        data: { todoID },
                                      })
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    if (archived) {
                                      console.log(
                                        "restore from archive",
                                        fprops.values.toDoGroupID
                                      );
                                      // restoring archived card
                                      sendInfoToParent({
                                        action: "restore",
                                        toDoGroupIDSelected:
                                          fprops.values.toDoGroupID,
                                      });
                                    } else {
                                      console.log("archives");

                                      archiving = true; // used to trigger actions after update
                                      toDoUpdateItem({
                                        todoID: todoID,
                                        archivedDate: new Date(),
                                      });
                                    }
                                  }}
                                >
                                  {archived ? "Restore" : "Archive"}
                                </Button>
                              </div>
                            </GridItem>
                            <GridItem xs={12}></GridItem>
                            <GridItem xs={12}></GridItem>
                            <GridItem xs={12}></GridItem>
                            <GridItem xs={12}></GridItem>
                            <GridItem xs={12}>
                              {archived && (
                                <h3 className={classes.archivedLabel}>
                                  Archived
                                </h3>
                              )}
                            </GridItem>
                          </GridContainer>
                          {/* <div>
                  <div className={classes.textContainer}>
                    <h6 className={classes.label}>Due date:</h6>
                    <h5 className={classes.textContent}>
                      {FormatDateCustom(
                        createdDate,
                        "eee d MMM yyyy  h:mm aaa"
                      )}
                    </h5>
                  </div>

                  <div className={classes.textContainer}>
                    <h6 className={classes.label}>Create date:</h6>
                    <h5 className={classes.textContent}>
                      {" "}
                      {FormatDateCustom(dueDate, "eee d MMM yyyy")}
                    </h5>
                  </div>
                  <div>
                    <h6 className={classes.label}>Duration:</h6>
                    <h5 className={classes.textContent}>{createdDate}</h5>
                  </div>
                </div> */}
                        </GridItem>

                        {/* <GridItem xs={false} sm={4}></GridItem> */}
                      </GridContainer>

                      {/* <pre>
                    {JSON.stringify(
                      {
                        touched: fprops.touched,
                        errors: fprops.errors,
                        values: fprops.values,
                      },
                      null,
                      2
                    )}
                  </pre> */}

                      {autoSave(fprops)}
                    </>
                  </DialogContent>
                </Dialog>
              );
            }}
          </Formik>
          <TodoDetailsModals
            modalDetails={modalDetails}
            setModalDetails={setModalDetails}
          />
        </>
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}

const TodoDetailsModals = (props) => {
  // console.log("starts to do details");
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { modalDetails, setModalDetails } = props;
    const { todo, setTodo, modal, setModal } = useContext(TodoContext);

    switch (modalDetails.name) {
      case "confirmDelete": {
        const toDoUpdateItem = (props) => {
          const options = axiosOptions({
            url: `${config.url}/api/to-do-update-item`,
            method: "post",
            data: {
              ...props,
            },
          });

          axios(options)
            .then(async () => {
              // refresh main screen with changes
              // (should be changed to only trigger this when there are changes that are displayed in main screen)
              setTodo({
                ...todo,
                updateInfo: new Date(),
                updateArchived: new Date(),
              });

              // closes the modal delete confirmation
              setModalDetails({
                visible: false,
                name: "",
              });

              // closes the modal details
              setModal({
                visible: false,
                name: "",
              });
            })
            .catch((error) => {
              return <ErrorHandler error={error} />;
            });
        };

        const deleteTodo = () => {
          toDoUpdateItem({
            todoID: modalDetails.data.todoID,
            deletedDate: new Date(),
          });
        };

        return (
          <CustomDialog
            iconPreset="question"
            visibleStatus={modalDetails}
            setVisibleStatus={setModalDetails}
            title="Do you want to delete this task?"
            buttonActionLabel1="Cancel"
            buttonActionFunction1={() => {
              setModalDetails({ visible: false, name: "" });
            }}
            // buttonActionDisabled1={isLoading}
            buttonActionLabel2="Delete"
            buttonActionColor2="danger"
            buttonActionFunction2={deleteTodo}
            // buttonActionLoading2={isLoading}
            // center
          />
        );
      }
      default:
        return null;
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal or critical",
      generalData: { ...generalData },
      // otherData: { modal },
    });
  }
};
