import React, { useContext } from "react";
// custom components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import { Field } from "formik";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
// data
import { mixed, object, string } from "yup";
import { useQuery } from "@apollo/client";
import { countryList } from "graphql/gqlPayment";
import { SessionContext } from "contexts/SessionContext";

// the field from formik passes the onChange, onBlue, values, initial values, etc to the custom component
const PaymentInvoice = (props) => {
  const { session, setSession } = useContext(SessionContext);
  const { languageID } = session;
  console.log("language", languageID);
  const { invoiceCountryID } = props.fprops.values;
  const { loading, error, data, refetch } = useQuery(countryList, {
    variables: { languageID: languageID },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  // console.log("los props son", props);

  if (loading) return <ActivityIndicator fullscreen />;

  if (data && data.spCountryList) {
    // const countryList = data.allCountries.nodes[0].map(() => {
    //   return {};
    // });

    return (
      <React.Fragment>
        {/* <Typography variant="h6" gutterBottom>
          Dirección facturación
        </Typography> */}

        <GridContainer
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <GridItem item xs={12} sm={6}>
            <Field name="invoiceName">
              {({ field, form, meta }) => {
                return (
                  // <CustomInput
                  //   labelText="Nombre factura *"
                  //   name="invoiceName"
                  //   // value={form.values.invoiceName}
                  //   // onChange={field.onChange}
                  //   // onBlur={field.onBlur}

                  //   {...field}
                  //   formControlProps={{ fullWidth: true }}
                  //   helperText={meta.touched ? meta.error : ""}
                  // />
                  <CustomMainInput
                    error={meta.touched ? meta.error : ""}
                    height="40px"
                    label="Invoice name"
                    name="invoiceName"
                    variant="input"
                    value={form.values.invoiceName}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    {...field} // retrieves all other, onBlur, onChange, etc
                  />
                );
              }}
            </Field>
          </GridItem>

          {invoiceCountryID === 51 && (
            <GridItem item xs={12} sm={6}>
              <Field name="legalNumber">
                {({ field, form, meta }) => (
                  // <CustomInput
                  //   labelText="ID"
                  //   name="legalNumber"
                  //   {...field}
                  //   // value={form.values.legalNumber}
                  //   // onChange={field.onChange}
                  //   // onBlur={field.onBlur}
                  //   formControlProps={{ fullWidth: true }}
                  //   helperText={meta.touched ? meta.error : ""}
                  // />
                  <CustomMainInput
                    {...field} // retrieves all other, onBlur, onChange, etc
                    error={meta.touched ? meta.error : ""}
                    height="40px"
                    label="ID"
                    name="legalNumber"
                    variant="input"
                  />
                )}
              </Field>
            </GridItem>
          )}

          <GridItem item xs={12} sm={12}>
            <Field name="invoiceAddress1">
              {({ field, form, meta }) => (
                // <CustomInput
                //   labelText="Dirección linea 1"
                //   name="invoiceAddress1"
                //   {...field}
                //   // value={form.values.address1}
                //   // onChange={field.onChange}
                //   // onBlur={field.onBlur}
                //   formControlProps={{ fullWidth: true }}
                //   helperText={meta.touched ? meta.error : ""}
                // />

                <CustomMainInput
                  {...field} // retrieves all other, onBlur, onChange, etc
                  error={meta.touched ? meta.error : ""}
                  height="40px"
                  label="Address"
                  name="invoiceAddress1"
                  variant="input"
                />
              )}
            </Field>
          </GridItem>
          {/* <GridItem item xs={12} sm={6}>
            <Field name="invoiceAddress2">
              {({ field, form, meta }) => (
                <CustomInput
                  labelText="Dirección linea 2 "
                  name="invoiceAddress2"
                  {...field}
                  // value={form.values.address2}
                  // onChange={field.onChange}
                  // onBlur={field.onBlur}
                  formControlProps={{ fullWidth: true }}
                  helperText={meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </GridItem> */}
          <GridItem item xs={12} sm={6}>
            <Field name="invoiceCity">
              {({ field, form, meta }) => (
                // <CustomInput
                //   labelText="Ciudad"
                //   name="invoiceCity"
                //   {...field}
                //   // value={form.values.city}
                //   // onChange={field.onChange}
                //   // onBlur={field.onBlur}
                //   formControlProps={{ fullWidth: true }}
                //   helperText={meta.touched ? meta.error : ""}
                // />

                <CustomMainInput
                  {...field} // retrieves all other, onBlur, onChange, etc
                  error={meta.touched ? meta.error : ""}
                  height="40px"
                  label="City"
                  name="invoiceCity"
                  variant="input"
                />
              )}
            </Field>
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <Field name="invoiceState">
              {({ field, form, meta }) => (
                <CustomMainInput
                  {...field} // retrieves all other, onBlur, onChange, etc
                  error={meta.touched ? meta.error : ""}
                  height="40px"
                  label="State"
                  name="invoiceState"
                  variant="input"
                />
              )}
            </Field>
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <Field name="invoicePobox">
              {({ field, form, meta }) => (
                <CustomMainInput
                  {...field} // retrieves all other, onBlur, onChange, etc
                  error={meta.touched ? meta.error : ""}
                  height="40px"
                  label="Zip code"
                  name="invoicePobox"
                  variant="input"
                />
              )}
            </Field>
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <Field name="invoiceCountryID">
              {({ field, form, meta }) => (
                // <CustomSelect
                //   label="País *"
                //   name="invoiceCountryID"
                //   items={data.spCountryList.nodes}
                //   formControlProps={{ fullWidth: true }}
                //   // {...field}
                //   onChange={field.onChange}
                //   value={form.values.invoiceCountryID}
                //   helperText={meta.touched ? meta.error : ""}
                // />

                <CustomMainInput
                  error={meta.touched ? meta.error : ""}
                  dropDownList={data.spCountryList.nodes}
                  // onChangeDropDownList={(item) => refetch()}

                  dropDownFunction={(item) => {
                    form.setFieldValue("invoiceCountryID", item.id);
                  }}
                  height="40px"
                  label="Country"
                  name="invoiceCountryID"
                  value={form.values.invoiceCountryID}
                  // placeholder="Select"
                  // {...field}
                  variant="dropdown"
                />
              )}
            </Field>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
};

PaymentInvoice.label = "Invoice info";
// AddressForm.initialValues = {
//   invoiceName: "Prueba",
//   legalNumber: "",
//   address1: "",
//   address2: "",
//   city: "",
//   state: "",
//   pobox: "",
// };
PaymentInvoice.validationSchema = object().shape({
  invoiceName: string()
    .nullable()
    .required("Required")
    .max(150, "Max. 150 characters"),
  legalNumber: string()
    .nullable()
    .when("invoiceCountryID", (invoiceCountryID) => {
      if (invoiceCountryID === 51) {
        return string()
          .nullable()
          .required("Required")
          .max(50, "Max. 50 characters");
      }
    }),
  invoiceAddress1: string().nullable().max(150, "Max 150 characters"),
  // invoiceAddress2: string().nullable().max(40, "Máximo 40 digitos"),
  invoiceCity: string().nullable().max(50, "Max. 50 characters"),
  invoiceState: string().nullable().max(50, "Max. 50 characters"),
  invoicePobox: string().nullable().max(50, "Max. 50 characters"),
  invoiceCountryID: mixed()
    .required("Select a country")
    .notOneOf([0], "Select a country"),
});

export default PaymentInvoice;
