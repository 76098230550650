import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import HelpIcon from "@material-ui/icons/Help";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
// style
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    alignRight,
    bold,
    color,
    error,
    formControlProps,
    hasHover = false,
    height = "medium",
    helperText,
    id,
    inputProps,
    inputRef,
    name,
    labelProps,
    labelText,
    onChange,
    onBlur,
    outlined,
    startAdornment,
    success,
    tooltipText,
    type,
    value,
  } = props;
  // console.log("el height es", height);

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
    [" " + classes[height + "Height"]]: height,
  });

  // const underlineClasses = classNames({
  //   [classes.underlineError]: error,
  //   [classes.underlineSuccess]: success && !error,
  //   [classes.underline]: true,
  // });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  });
  const textAlignRight = classNames({
    [" " + classes.rightAlign]: alignRight,
  });
  const highlight = classNames({
    [" " + classes.highlight]: bold,
  });

  const colorClasses = classNames({
    [classes[color]]: color,
  });

  const colorLabelClasses = classNames({
    [classes[color + "Label"]]: color,
  });

  const inputClasses = classNames({
    [" " + classes[height + "Height"]]: height,
  });

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      variant="outlined"
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          variant={outlined ? "outlined" : "filled"}
          {...labelProps}
        >
          <div className={classes.labelContainer}>
            {labelText}
            {tooltipText && (
              <div className={classes.tooltip}>
                <Tooltip title={tooltipText} placement="right">
                  <HelpIcon style={{ fontSize: 15 }} />
                </Tooltip>
              </div>
            )}
          </div>
        </InputLabel>
      ) : null}
      {outlined ? (
        <OutlinedInput
          autoComplete="off"
          classes={{
            root: hasHover && classes.baseOutlined,
            // inputRoot: classes.inputRoot,
            notchedOutline: colorClasses, // borderline
            input: classes.inputRoot + " " + colorLabelClasses + inputClasses,
            disabled: classes.disabled,
            // underline: underlineClasses,
          }}
          id={id}
          label={labelText}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type={type}
          startAdornment={startAdornment}
          value={value}
          {...inputProps}
        />
      ) : (
        <Input
          autoComplete="off"
          classes={{
            root: marginTop,
            input:
              classes.inputRoot +
              textAlignRight +
              highlight +
              " " +
              colorClasses,
            disabled: classes.disabled,
            // underline: underlineClasses,
          }}
          id={id}
          inputRef={inputRef}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type={type}
          startAdornment={startAdornment}
          value={value}
          {...inputProps}
        />
      )}

      <p className={classes.helperText}>{helperText}</p>
      {helperText ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : null}

      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  height: PropTypes.oneOf(["small", "medium", "large"]),
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  helperText: PropTypes.string,
  type: PropTypes.string,
  tooltipText: PropTypes.string,
};
