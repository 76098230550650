import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";

const sidebarStyle = (theme) => ({
  background: {
    position: "absolute",
    zIndex: 1,
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",

    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: "linear-gradient(168.65deg, #00084d 50%, #3347ff 100%)",
      opacity: "1",
    },
  },
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: 20,
    ...boxShadow,
    boxShadow: "10px 0px 10px rgba(0, 0, 0, 0.35)",
    width: drawerWidth,

    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "relative",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "relative",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: 20,
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      // transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },
  drawerPaperRTL: {
    [theme.breakpoints.up("md")]: {
      left: "auto !important",
      right: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      left: "0  !important",
      right: "auto !important",
    },
  },
  logo: {
    objectFit: "contain",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    padding: "20px 20px",
    zIndex: "4",
    height: "80px",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(" + hexToRgb(grayColor[4]) + ", 0.3)",
    },
  },
  logoLink: {
    ...defaultFont,
    textTransform: "uppercase",
    padding: "5px 0",
    display: "block",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: whiteColor,
    },
  },
  logoLinkRTL: {
    textAlign: "right",
  },
  logoImage: {
    width: "30px",
    display: "inline-block",
    maxHeight: "30px",
    marginLeft: "10px",
    marginRight: "15px",
  },
  img: {
    width: "35px",
    top: "22px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0",
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: whiteColor,
    },
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    // margin: "10px 25px 0",
    // margin: "20px",
    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "0px 10px",
    backgroundColor: "transparent",
    ...defaultFont,
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
  },
  itemIconRTL: {
    marginRight: "3px",
    marginLeft: "15px",
    float: "right",
  },
  itemText: {
    ...defaultFont,
    // margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    // display: "none",
    whiteSpace: "nowrap",
  },
  itemTextRTL: {
    textAlign: "right",
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset",
  },

  whiteFont: {
    color: whiteColor,
  },
  purple: {
    backgroundColor: primaryColor[4],
    ...primaryBoxShadow,
    "&:hover,&:focus": {
      backgroundColor: primaryColor[4],
      ...primaryBoxShadow,
    },
  },
  blue: {
    margin: "10px",
    padding: "0px",
    backgroundColor: infoColor[4],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(infoColor[4]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(infoColor[4]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: infoColor[4],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(infoColor[4]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(infoColor[4]) +
        ",.2)",
    },
  },
  green: {
    backgroundColor: successColor[1],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(successColor[1]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(successColor[1]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: successColor[1],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(successColor[1]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(successColor[1]) +
        ",.2)",
    },
  },
  orange: {
    backgroundColor: warningColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(warningColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: warningColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.2)",
    },
  },
  red: {
    backgroundColor: dangerColor[1],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(dangerColor[1]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(dangerColor[1]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: dangerColor[1],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(dangerColor[1]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(dangerColor[1]) +
        ",.2)",
    },
  },
  transparent: {
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ",.2)",

      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(whiteColor) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(whiteColor) +
        ",.2)",
    },
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    // overflow: "auto",
    width: drawerWidth,
    zIndex: "4",
    overflowScrolling: "touch",
  },
  // activePro: {
  //   [theme.breakpoints.up("md")]: {
  //     position: "absolute",
  //     width: "100%",
  //     bottom: "13px",
  //   },
  // },
  drawerClosed: {
    width: "75px",
    overflowY: "clip",

    [theme.breakpoints.up("md")]: {
      width: "75px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "75px",
    },
  },
  transparentFont: {
    color: "transparent",
  },
});

export default sidebarStyle;
