/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import "./wdyr";
import "./instrument";
import React, { Suspense, useEffect } from "react";
import "./i18n";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";

// core components
import SignedInMain from "layouts/SignedInMain.js";

import SignedOutMain from "layouts/SignedOutMain.js";
import "assets/css/material-dashboard-react.css?v=1.9.0";
// data
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import { SessionInfo } from "./contexts/SessionContext";
import { TourInfo } from "contexts/TourContext";
import { SocketInfo } from "./contexts/SocketContext";
// import { createHttpLink } from "apollo-link-http";
import config from "./config";

// const msalInstance = new PublicClientApplication(msalConfig);
// const httpLink = createHttpLink({
//   uri: `${config.url}/graphql`,
//   credentials: "include",
// });

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );
//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

const httpLink = new HttpLink({
  uri: `${config.url}/graphql`,
  // credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const refreshToken = localStorage.getItem("lkd(9dk"); // refresh token
  const accessToken = sessionStorage.getItem("kjd/afd"); // accessToken
  const userID = parseInt(sessionStorage.getItem("lkal89d97"));

  // return the headers to the context so httpLink can read them
  // console.log("el token en authlink", token);
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : null,
      refresh: refreshToken,
      userID: userID,
    },
  };
});

// const errorLink = onError(({ networkError }) => {
//   if (networkError.statusCode === 401) {
//     // console.log("******************************se dio un error en apollo");
//   } else {
//     console.error("se dio el error", networkError);
//   }
// });
const errorLink = onError((error) => {
  console.error("se dio el error", error);
});

const responseLink = new ApolloLink((operation, forward) => {
  // console.log("//////////////// inicia el handleResponse link");

  return forward(operation).map((response) => {
    // if (response.data.date) {
    //   response.data.date = new Date(response.data.date);
    // }
    // console.log(
    //   "------------------- response in handleResponseLink",
    //   JSON.stringify(response)
    // );
    // console.log(
    //   "------------------- response in handleResponseLink2",
    //   operation.getContext()
    // );
    return response;
  });
});

const client = new ApolloClient({
  // uri: `${config.url}/graphql`,
  cache: new InMemoryCache(),
  connectToDevTool: true,
  fetchOptions: {
    mode: "no-cors",
  },
  // link: from([errorLink, httpLink]),
  // link: errorLink.concat(httpLink),
  link: from([authLink, errorLink, responseLink, httpLink]),
  // link: httpLink,
  // defaultOptions: {
  //   watchQuery: {
  //     errorPolicy: "all",
  //   },
  // },
});

// const hist = createBrowserHistory();

// removes all console.log
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// use sentry if in production
// const customBrowserRouter =
//   process.env.NODE_ENV === "production"
//     ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
//     : createBrowserRouter;
const customBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = customBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        exact
        path="/app/*"
        element={
          <SocketInfo>
            <SignedInMain />
          </SocketInfo>
        }
      />
      <Route exact path="/*" element={<SignedOutMain />} />
    </>
  )
);

root.render(
  <Suspense fallback="">
    <SessionInfo>
      <TourInfo>
        <GoogleOAuthProvider clientId="367975069077-qk71bj2gj0f0jibi39ppvolmqdlvfgsk.apps.googleusercontent.com">
          {/* <MsalProvider instance={msalInstance}> */}

          {/* <BrowserRouter history={hist}> */}
          <ApolloProvider client={client}>
            <RouterProvider router={router} />
          </ApolloProvider>

          {/* </MsalProvider> */}
        </GoogleOAuthProvider>
      </TourInfo>
    </SessionInfo>
  </Suspense>
  // document.getElementById("root")
);
