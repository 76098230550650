import React, {
  memo,
  useContext,
  useEffect,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

// data

import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  meetingAttendees,
  updateMeetingAttendees,
} from "graphql/gqlMeetingContents";
import { meetingRating } from "graphql/gqlMeeting";
import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import { ContentContext } from "contexts/ContentContext";
import {
  debounce,
  axiosOptions,
  addMinutesToDate,
  numberToTime,
  formatDate,
} from "functions/Common";
import { Formik } from "formik";
import { object, string } from "yup";
import axios from "axios";
import config from "config";
import differenceInSeconds from "date-fns/differenceInSeconds";
import add from "date-fns/add";
import sortBy from "lodash/sortBy";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";
import { tourData } from "utils/tourData";

// core components
import CustomDialog from "components/CustomDialog/CustomDialog";
import { CustomCheckbox } from "components/CustomCheckbox/CustomCheckbox";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import SideMenu from "components/SideMenu/SideMenu";
// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// hooks
import { useRouteChanged } from "functions/useRouteChanged";

// icons
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

// style
import format from "date-fns/format";
import { sentenceCase } from "functions/Common";
import { makeStyles } from "@material-ui/core/styles";
import {
  grayColor,
  defaultFont,
  boxShadow,
  transition,
  primaryColor,
  dangerColor,
  infoColor,
} from "assets/jss/material-dashboard-react";
import { HighlightRounded } from "@material-ui/icons";
// import { usePrompt } from "functions/usePrompt";

const styles = {
  attendeesMainContainer: {
    // marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    // flex: 1,
    height: "60%",

    // backgroundColor: "lightBlue",
    // paddingTop: "5px",
  },
  attendeeFullHeight: {
    height: "100%",
  },

  attendeeButtonGroup: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "yellow",
    justifyContent: "flex-end",
  },
  attendeeButtonContainer: {
    width: "100px",
    marginLeft: "10px",
    display: "flex",
    justifyContent: "flex-end",
  },

  attendeeInProcessContainer: { display: "flex", alignItems: "center" },
  attendeeLabelGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  attendeeInProcessLabel: { marginRight: "5px" },
  // attendeeLabel: { marginLeft: "5px" },
  attendeeLabelDisabled: { color: grayColor[5] },
  attendeesList: {
    // borderWidth: "1px",
    // borderStyle: "solid",
    borderRadius: "5px",
    backgroundColor: "white",
    borderColor: grayColor[5],
    display: "flex",
    flex: 1,
    flexDirection: "column",
    // padding: "15px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  attendeeRow: {
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    padding: "10px",
    padding: "5px 10px 5px 10px",
  },
  attendeeRowDivider: {
    borderBottom: `1px solid ${grayColor[7]}`,
  },
  attendeesTitleLabel: {
    ...defaultFont,
    fontSize: "14px",
    fontWeight: 500,
    borderRadius: "10px 10px 0px 0px",
    // paddingLeft: "10px",
    color: grayColor[0],
    // marginTop: ".2rem",
    padding: "10px",
    backgroundColor: primaryColor[8],
  },
};

const useStyles = makeStyles(styles);

export const AttendeesMain = () => {
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log

  const [newMeetingAttendees, setNewMeetingAttendees] = useState([]);
  const [dataForSocket, setDataForSocket] = useState({});

  const { session, setSession } = useContext(SessionContext);
  const { firstUse } = session;

  const { meeting, setMeeting, updateRating } = useContext(MeetingContext);
  const { meetingID, meetingTypeID, refreshInfo, statusID } = meeting;

  const { content, setContent } = useContext(ContentContext);
  const { parentEventID, showAgenda } = content;

  const {
    loading: loadingMA,
    data: dataMA,
    error: errorMA,
    refetch: refetchMA,
  } = useQuery(meetingAttendees, {
    variables: { meetingID: meetingID || parentEventID },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network",
    skip: firstUse,
  });

  const timerDuration = 5;

  useEffect(() => {
    try {
      // console.log("inicia useEffect de dataMA");

      // finds in the current stat if inProcess, isPlaying and initial time are present
      // if so, adds to the corresponding attendee and if not, adds the default values

      if (dataMA !== undefined) {
        // console.log("intenta crear new array");
        const newAttendees = dataMA.spMeetingAttendees.nodes.map(
          (dbAttendee) => {
            // const attendeeInState = newMeetingAttendees.filter(
            //   (stateAttendee) => stateAttendee.id === dbAttendee.id
            // );

            // console.log("attendeeInState", {
            //   name: dbAttendee.attendee,
            //   inProcessDateOriginal: dbAttendee.inProcessDate,
            //   inProcessDateProcessed: new Date(dbAttendee.inProcessDate),
            //   currentDate: new Date(),
            //   remainingTime: differenceInSeconds(
            //     new Date(dbAttendee.inProcessDate),
            //     new Date()
            //   ),
            // });

            const remainingTime = Math.max(
              differenceInSeconds(
                new Date(dbAttendee.inProcessDate),
                new Date()
              ),
              0
            );

            return {
              ...dbAttendee,
              // inProcess: attendeeInState.inProcess,
              isPlaying: remainingTime > 0 && true,
              // remainingTime: attendeeInState[0].remainingTime,
              remainingTime:
                remainingTime === 0 ? timerDuration : remainingTime,
            };
          }
        );
        // console.log("attendees", {
        //   received: dataMA,
        //   newArray: newAttendees,
        // });

        // console.log("new array of attendees", newAttendees);
        setNewMeetingAttendees(newAttendees);
      }
    } catch (error) {
      logError(error, {
        errorLevel: "fatal",
        generalData: { ...generalData },
        otherData: { ...dataMA },
      });
    }
  }, [dataMA]);

  useEffect(() => {
    // console.log("------se realiza un refresh de la info de attendees");
    refetchMA();
    // refetch();
  }, [refreshInfo]);

  let newArray;
  // let attendeeName;
  // let actionType

  const [updateMeetingAttendeesMut] = useMutation(updateMeetingAttendees, {
    awaitRefetchQueries: true,
    onCompleted: () => {
      refetchMA();
    },
  });

  const onButtonPress = (attendee) => {
    console.log("attendee", attendee);

    if ([1, 3].includes(attendee.status)) {
      console.log("stops the timer");
      // stops the inProcess and resets the timer
      newArray = newMeetingAttendees.map((item) => {
        if (item.id === attendee.id) {
          return {
            ...item,
            isPlaying: false,
            remainingTime: timerDuration,
          };
        } else {
          return item;
        }
      });
    } else {
      console.log("starts processing");
      // starts processing
      newArray = newMeetingAttendees.map((item) => {
        if (item.id === attendee.id) {
          return {
            ...item,
            isPlaying: true,
            remainingTime: timerDuration,
          };
        } else {
          return item;
        }
      });
    }
    setNewMeetingAttendees(newArray);

    switch (attendee.status) {
      case 0: // check in
        setDataForSocket({
          ...dataForSocket,
          action: "joinMeeting",
          attendeeName: attendee.attendee,
        });
        updateMeetingAttendeesMut({
          variables: {
            attendeeID: attendee.id,
            arrivalDate: new Date(),
            inProcessDate: add(new Date(), { seconds: timerDuration }),
          },
        });

        break;
      case 1: // check in in progress
        setDataForSocket({});
        updateMeetingAttendeesMut({
          variables: {
            attendeeID: attendee.id,
            arrivalDate: null,
            inProcessDate: null,
          },
        });
        break;
      case 2: // check out
        setDataForSocket({
          action: "leaveMeeting",
          attendeeName: attendee.attendee,
        });
        updateMeetingAttendeesMut({
          variables: {
            attendeeID: attendee.id,
            departDate: new Date(),
            inProcessDate: add(new Date(), { seconds: timerDuration }),
          },
        });
        break;
      case 3: // check out in progress
        setDataForSocket({});
        updateMeetingAttendeesMut({
          variables: {
            attendeeID: attendee.id,
            departDate: null,
            inProcessDate: null,
          },
        });
        break;
      case 4: // checked out
    }
  };

  const Timer = ({ attendee }) => {
    // console.log("el attendee en timer", attendee);
    const renderTime = ({ remainingTime }) => {
      // if (remainingTime === 0) {
      //   processAction(attendee);
      // }

      return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
      );
    };

    return (
      <CountdownCircleTimer
        size={27}
        strokeWidth={3}
        isPlaying={attendee.isPlaying}
        initialRemainingTime={attendee.remainingTime}
        duration={timerDuration}
        colors={[dangerColor[1]]}
        // colorsTime={[10, 0]}

        onComplete={() => {
          console.log("timer complete");

          if (["joinMeeting", "leaveMeeting"].includes(dataForSocket.action)) {
            // sendInfoToParent(dataForSocket);
            setMeeting({ ...meeting, socketSignalData: dataForSocket });
            setDataForSocket({});
          }

          refetchMA();
          return { shouldRepeat: false };
        }}
      >
        {renderTime}
      </CountdownCircleTimer>
    );
  };

  return (
    <div
      className={`${classes.attendeesMainContainer} ${
        !showAgenda && classes.attendeeFullHeight
      }`}
    >
      <p className={classes.attendeesTitleLabel}>Attendees</p>
      <div className={classes.attendeesList}>
        {dataMA &&
          dataMA.spMeetingAttendees &&
          newMeetingAttendees.map((item, index) => (
            <div
              key={item.id}
              className={`${classes.attendeeRow} ${
                newMeetingAttendees.length > index + 1 &&
                classes.attendeeRowDivider
              }`}
            >
              <GridContainer spacing={1} direction="row" alignItems="center">
                <GridItem xs={6}>
                  <div className={classes.attendeeLabelGroup}>
                    {/* <Avatar
                          // className={classes.avatar}
                          // alt={item.attendee}
                          src={item.imageUrl}
                          style={{ height: "25px", width: "25px" }}
                        /> */}
                    <h6
                    // className={`${
                    //   [0, 1, 4].includes(item.status) &&
                    //   classes.attendeeLabelDisabled
                    // }`}
                    >
                      {item.attendee || item.email}
                    </h6>
                  </div>
                </GridItem>

                <GridItem xs={6}>
                  <div className={classes.attendeeButtonGroup}>
                    {[1, 3].includes(item.status) && (
                      <div className={classes.attendeeInProcessContainer}>
                        {/* <h6 className={classes.attendeeInProcessLabel}>
                        
                          In process
                        </h6> */}
                        <ErrorBoundary
                          FallbackComponent={(error) => (
                            <LogErrorComponent
                              error={error}
                              data={{
                                generalData: { ...generalData },
                                otherData: { item },
                              }}
                            />
                          )}
                        >
                          <Timer attendee={item} />
                        </ErrorBoundary>
                      </div>
                    )}
                    <div className={classes.attendeeButtonContainer}>
                      <Button
                        disabled={item.status === 4}
                        fullWidth
                        color={
                          {
                            0: "success",
                            1: "primary",
                            2: "danger",
                            3: "primary",
                            4: "gray",
                          }[item.status] || 4
                        }
                        onClick={() => onButtonPress(item)}
                        // block
                        size="xs"
                      >
                        {{
                          0: "Check in",
                          1: "Cancel",
                          2: "Check out",
                          3: "Cancel",
                          4: "Left",
                        }[item.status] || 4}
                      </Button>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              {/* {meeting.attendees.length > index + 1 && (
                    <hr className={classes.divider} />
                  )} */}
            </div>
          ))}
      </div>
    </div>
  );
};
