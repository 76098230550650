import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { v4 as uuidv4 } from "uuid";
export const ContentContext = createContext();

export const ContentInfo = (props) => {
  // this prevents the rich text editor to trigger the handleChange
  // otherwise, for example changing agenda item list would trigger saving to db
  // including when selecting an empty line
  const preventTriggerChangeRef = useRef(true); // by default is true to avoid saving when loading rich text editor
  const isSavingRef = useRef(false);

  const descriptionRefs = useRef([]);
  const tempRef = useRef(null);
  // const lastDescIndexRef = useRef(null);

  const initialState = {
    agendaItems: [],
    blankAgendaItemWithNewID: (initialArray, meetingID) => {
      const blankAgendaItem = {
        id: uuidv4(),
        meetingID: meetingID,
        desc: null,
        orderBy: 1,
        privateNotes: null,
        details: null,
        duration: 30,
        dayID: 1,
        typeID: 1,
      };

      const maxID = initialArray.reduce((group, item, index) => {
        if (Object.keys(group).length === 0 || item.value > group.value) {
          group = item.orderBy;
        }
        return group;
      }, 0);
      const blankItem = { ...blankAgendaItem, orderBy: maxID + 1 };

      return blankItem;
    },
    descriptionRefs,
    hasPreviousActions: false,
    isLoading: false,
    isSavingRef,
    preventTriggerChangeRef,
    privateAgenda: false,
    privateNotes: null,
    refreshData: new Date(),
    selectedItem: { id: null, index: null },
    showAgenda: null,
    // lastDescIndexRef,
    // firstUse ? { id: 1, index: 0 } : { id: null, index: null },
    // revisar si debería ser ({ id: agendaItems[0].id, index: 0 });
  };

  const [content, setContentNotMemo] = useState(initialState);

  const setContent = useCallback((newValue) => {
    setContentNotMemo(newValue);
  }, []);

  const memoizedValues = useMemo(
    () => ({
      content,
      setContent,
    }),
    [content, setContent]
  );

  return (
    <ContentContext.Provider value={memoizedValues}>
      {props.children}
    </ContentContext.Provider>
  );
};
export default ContentInfo;
