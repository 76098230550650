import { gql } from "@apollo/client";

export const toDoMisc = gql`
  query todo($accountID: Int!, $userID: Int!) {
    spPlanLimits(accountId1: $accountID, userId1: $userID) {
      nodes {
        createToDo
        toDoSlotsAvailable
        toDoSlotsCapacity
      }
    }
  }
`;

export const updateToDoGroup = gql`
  mutation updateToDoGroup($data: JSON) {
    spToDoGroupUpdate(input: { toDoGroupArray: $data }) {
      clientMutationId
    }
  }
`;

export const addToDoGroup = gql`
  mutation addToDoGroup($desc: String, $userID: Int) {
    spToDoGroupAdd(input: { desc1: $desc, userId1: $userID }) {
      clientMutationId
    }
  }
`;

export const updateToDoGroupName = gql`
  mutation updateToDoGroupName($groupName: String, $groupID: Int!) {
    updateToDoGroupById(
      input: { toDoGroupPatch: { desc: $groupName }, id: $groupID }
    ) {
      clientMutationId
    }
  }
`;

export const deleteToDoGroup = gql`
  mutation deleteToDoGroup($toDoGroupID: Int) {
    spToDoGroupDelete(input: { toDoGroupId1: $toDoGroupID }) {
      clientMutationId
    }
  }
`;
