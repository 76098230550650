import React from "react";
import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { infoColor } from "../../assets/jss/material-dashboard-react";
import Button from "../../components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    paddingLeft: "20%",
    paddingRight: "20%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },

  title: {
    color: infoColor[4],
    margin: "30px 0px",
    textAlign: "center",
  },
}));

export default function NotAuthorized(props) {
  const navigate = useNavigate();
  // const { t } = useTranslation(["main"]);
  const classes = useStyles();
  const redirectHome = () => {
    sessionStorage.clear();
    navigate("/");
  };
  return (
    <>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} noValidate>
            <h3 className={classes.title}>Please sign in again</h3>

            <Button
              type="submit"
              fullWidth
              color="primary"
              onClick={redirectHome}
            >
              Go to sign in
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
}
