import { gql } from "@apollo/client";

export const calculateSubscription = gql`
  query calculateSubscription(
    $periodID: Int
    $slots: Int!
    $accountID: Int!
    $couponCode: String!
  ) {
    spSubscriptionCalculate(
      accountId1: $accountID
      periodId1: $periodID
      slots1: $slots
      couponCode1: $couponCode
    ) {
      nodes {
        baseAmount
        discountAmount
        taxAmount
        validUntil
        totalAmount
      }
    }
  }
`;

export const countryList = gql`
  query countryList($languageID: String!) {
    spCountryList(languageId1: $languageID) {
      nodes {
        id
        menuDesc
      }
    }
  }
`;

export const subscriptionPeriodList = gql`
  query subscriptionPeriodList($languageID: String!) {
    spSubscriptionPeriodList(languageId1: $languageID) {
      nodes {
        id
        menuDesc: name
      }
    }
  }
`;

// export const subscriptionNew = gql`
//   mutation subscriptionNew(
//     $periodID: Int
//     $slots: Int!
//     $accountID: Int!
//     $email: String
//     $invoiceAddress1: String
//     $invoiceAddress2: String
//     $invoiceCity: String
//     $invoiceCountryID: Int!
//     $invoiceName: String!
//     $invoicePobox: String
//     $invoiceState: String
//     $legalNumber: String
//   ) {
//     spSubscriptionNew(
//       input: {
//         accountId1: $accountID
//         periodId1: $periodID
//         slots1: $slots
//         email1: $email
//         invoiceAddress11: $invoiceAddress1
//         invoiceAddress21: $invoiceAddress2
//         invoiceCity1: $invoiceCity
//         invoiceCountryId1: $invoiceCountryID
//         invoiceName1: $invoiceName
//         invoicePobox1: $invoicePobox
//         invoiceState1: $invoiceState
//         legalNumber1: $legalNumber
//       }
//     ) {
//       clientMutationId
//     }
//   }
// `;
