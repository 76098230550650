import React, { forwardRef } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
// core components

import { primaryColor } from "assets/jss/material-dashboard-react.js";

const styles = {
  checkbox: {
    color: primaryColor[4],
  },
  checkboxContainer: { paddingRight: "5px", display: "flex" },

  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // backgroundColor: "yellow",
    paddingRight: "5px",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

// export default function CustomCheckbox(props) {
export const CustomCheckbox = forwardRef((props, ref) => {
  const classes = useStyles();
  const { color, value, onChange, size, text, ...rest } = props;

  return (
    <div {...props} ref={ref} className={classes.mainContainer}>
      <div className={classes.checkboxContainer}>
        <Checkbox
          style={{ padding: 0 }}
          size={size || "small"}
          classes={{ checked: classes.checkbox }}
          color="primary"
          checked={value}
          //   labelStyle={{ color: "orange" }}
          onChange={onChange}
          {...rest}
        />
      </div>
      {text}
    </div>
  );
});

CustomCheckbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "info", "gray"]),
};
