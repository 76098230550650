import { useCallback, useRef, forwardRef } from "react";
import { DateRangePicker } from "react-date-range";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import CustomMainInput from "./CustomMainInput/CustomMainInput";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Button from "components/CustomButtons/Button";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { boxShadow } from "assets/jss/material-dashboard-react";

// core components
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import CustomPopover from "./CustomPopover";

// data
import { v4 as uuidv4 } from "uuid";
import { debounce } from "functions/Common";

// icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import FeatherIcon from "feather-icons-react";

// style
import classNames from "classnames";
import { primaryColor } from "assets/jss/material-dashboard-react.js";

const styles = {
  filterMainContainer: {
    minWidth: "600px",
  },
  popperMainContainer: {
    // height: "calc(100% - 18px)", // just added, substracts the height of the description box

    display: "flex",
    width: "100%",
    flex: 1,
    flexDirection: "row",
    alignItems: "stretch",
    padding: "0px 0px",
    margin: "0px 10px 0px 0px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",

    "&$primary": {
      borderColor: primaryColor[6],
      "&:hover": { backgroundColor: primaryColor[8] },
    },
  },
  popperMainContainerFiltered: {
    "&$primary": {
      backgroundColor: primaryColor[8],
    },
  },

  popperIconContainer: {
    display: "flex",
    color: "red",
    padding: "0px 10px 0px 10px",
    alignItems: "center",
    alignSelf: "center",
    "&$primary": {
      color: primaryColor[4],
    },
    // "&$gray": {
    //   color: grayColor[4],
    // },
  },
  primary: {},
};
const useStyles = makeStyles(styles);

const updateFilters = (props) => {
  const { filterLine, action, filters, sendInfoToParent } = props;
  const newArray = [...filters];

  console.log(
    "%cStarts updatefilters with filterLine provided",
    "background-color: yellow; color: red",
    props
  );
  let shouldApply = null;
  let newFilters;

  //if no filter line is provided, it means it's clearing all
  if (filterLine === null && action === "remove") {
    // if (action === "remove") {
    console.log("%cno filters", "background-color: gree; color: white");

    newFilters = [];
    shouldApply = true;
  } else {
    // run through all items in current filters and search for the line that is currently been updated or removed
    // evaluate if the line provided is in process or ready, which would trigger and update of the info
    newFilters = newArray.reduce((filterAcum, line) => {
      console.log("%cline", "background-color: orange; color: white", {
        line: filterLine,
        value: filterLine.values[0],
        test1: (filterLine.values[0] || null) === null,
        test2: (filterLine.values[1] || null) === null,

        test3: filterLine.values.length === 0,
      });

      let filterLineStatus = "";
      switch (filterLine.fieldType) {
        case "range":
          if (
            (filterLine.values[0] || null) === null ||
            (filterLine.values[1] || null) === null ||
            filterLine.values.length === 0
          ) {
            filterLineStatus = "in process";
          } else {
            filterLineStatus = "ready";
          }

          break;

        case "boolean":
          if (filterLine.values.length === 0) {
            filterLineStatus = "ready";
          } else {
            filterLineStatus = "in process";
          }
          break;

        default:
          if (filterLine.values.length === 0) {
            filterLineStatus = "in process";
          } else {
            filterLineStatus = "ready";
          }
      }

      // detects the line that has to be updated/removed
      if (line.id === filterLine.id) {
        // console.log("line to remove");

        if (action !== "remove") {
          // add line
          filterAcum.push({
            ...filterLine,
            filterLineStatus: filterLineStatus,
          });
        } else {
          // console.log("remove line");
        }
      } else {
        // line is not updated/removed, therefore, adds the current filters
        filterAcum.push({ ...line, filterLineStatus: filterLineStatus });
      }

      return filterAcum;
    }, []);

    console.log(
      "%cupdated filters",
      "background-color: red; color: white",
      newFilters
    );
  }
  sendInfoToParent(newFilters);
};

// placed outside filter component to avoid input box losing focus
const FilterPopper = (props, ref) => {
  const {
    filters,
    filterValuesSelector,
    columnList,
    listsDropdown,
    sendInfoToParent,
    closePopover,
    parentKey,
  } = props;
  // console.log(
  //   "%cfilters en filterpopper",
  //   "background-color: orange; color: white",
  //   props.filters
  // );

  const classes = useStyles();

  const columnListFiltered = (currentField) => {
    // console.log(
    //   "%cEl current field es",
    //   "background-color: purple; color: white",
    //   currentField
    // );

    const result = columnList.filter((column) => {
      const include = filters.some((inFilters) => {
        // console.log("%ctests in some", "background-color: blue; color: white", {
        //   column: column.id,
        //   inFilters: inFilters,
        //   currentField: currentField,
        //   test1: inFilters.field !== column.id,
        //   test2: inFilters.field === currentField,
        //   final:
        //     inFilters.field === column.id || inFilters.field === currentField,
        // });

        return (
          inFilters.field === column.id // || inFilters.field === currentField
        );
      });

      const isCurrentField =
        currentField !== undefined && currentField === column.id;

      // console.log("%cLa columna es", "background-color: green; color: white", {
      //   columnID: column.id,
      //   include: !include,
      //   isCurrentField: isCurrentField,
      // });

      return !include || isCurrentField;
    });
    // console.log("%cresult", "background-color: red; color: white", result);

    return result;
  };

  const conditionListFiltered = (fieldType, field) => {
    // console.log(
    //   "%cprops de conditionLIstFiltered",
    //   "background-color: red; color: white",
    //   { fieldType, field }
    // );

    // filters the conditions depending on the field selected
    let finalConditionsList = listsDropdown.conditionsList?.filter((obj) =>
      obj.appliesTo.includes(fieldType)
    );

    if (fieldType === "boolean") {
      const fieldName = columnList
        .filter((item) => item.id === field)[0]
        .menuDesc.toLowerCase();

      finalConditionsList = finalConditionsList.map((item) => {
        return { id: item.id, menuDesc: item.menuDesc + " " + fieldName };
      });
    }
    // console.log(
    //   "%cfinalConditionsList",
    //   "background-color: red; color: white",
    //   finalConditionsList
    // );

    return finalConditionsList;
  };

  const updateFiltersParent = useCallback(
    (filterLine) => {
      // console.log("filters en updateFiltersParent", localProps);
      // Your logic here
      updateFilters({ ...props, filterLine: filterLine });
    },
    [props]
  );

  // console.log("%cdata", "background-color: red; color: white", {
  //   lastField: filters[filters.length - 1]?.field === null,
  //   noMoreFilters: columnListFiltered().length === 0,
  // });

  // console.log("%cdisabled", "background-color: red; color: white", {
  //   test1: filters[filters.length - 1]?.field === null,
  //   test2: columnListFiltered().length === 0,
  // });

  return (
    <div
      className={classes.filterMainContainer}
      style={{
        padding: "12px",
        // backgroundColor: "yellow",
      }}
    >
      <GridContainer alignItems="center" style={{ marginBottom: "5px" }}>
        <GridItem xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5>Filters</h5>
            <Button
              size="xs"
              color="primary"
              // justIcon
              simple
              onClick={
                () => {
                  closePopover();

                  updateFilters({
                    ...props,
                    filterLine: null,
                    action: "remove",
                  });
                }
                // setParameterList({ ...parameterList, filters: [] });
                // updateFilters([]);
              }
            >
              Clear all
            </Button>
          </div>
        </GridItem>
      </GridContainer>
      {filters.map((filterLine, index) => {
        return (
          <GridContainer
            alignItems="stretch"
            key={filterLine.id}
            style={{
              maxWidth: "1600px",
              marginTop: "10px",
            }}
          >
            {/* ------------ field --------------- */}
            <GridItem xs={4}>
              <CustomMainInput
                dropDownList={columnListFiltered(filterLine.field)}
                placeholder="Select filter"
                dropDownFunction={(item) => {
                  console.log("el item es", item);
                  const newLineFilter = {
                    ...filterLine,
                    field: item.id,
                    fieldType: item.fieldType,
                    conditionID: conditionListFiltered(
                      item.fieldType,
                      item.id
                    )[0].id,
                    values: [],
                  };
                  console.log("el newLineFilter es", newLineFilter);
                  // updates all the filters with the new value
                  updateFilters({ ...props, filterLine: newLineFilter });
                }}
                // height="30px"
                value={filterLine.field}
                variant="dropdown"
              />
            </GridItem>
            {/* ----------condition -------------------  */}
            <GridItem xs={filterLine.fieldType === "boolean" ? 7 : 3}>
              {filterLine.field !== null && (
                <CustomMainInput
                  dropDownList={conditionListFiltered(
                    filterLine.fieldType,
                    filterLine.field
                  )}
                  dropDownFunction={(item) => {
                    console.log(
                      "%cItem",
                      "background-color: red; color: white",
                      { item: item }
                    );

                    // creates the new filter line
                    const newLineFilter = {
                      ...filterLine,

                      conditionID: item.id,
                    };
                    // updates all the filters with the new value
                    // updateFilters(newLineFilter);
                    updateFilters({ ...props, filterLine: newLineFilter });
                  }}
                  // height="30px"
                  value={filterLine.conditionID}
                  variant="dropdown"
                />
              )}
            </GridItem>

            {/* ----------value -------------------  */}
            {/* will be hidden if value is boolean */}
            {filterLine.fieldType !== "boolean" && (
              <GridItem xs={4}>
                {filterLine.field !== null &&
                  filterValuesSelector(filterLine, updateFiltersParent)}
              </GridItem>
            )}

            {/* ----------delete filter line -------------------  */}
            <GridItem xs={1} row alignItems="center" justifyContent="center">
              <Button
                size="xs"
                color="danger"
                justIcon
                onClick={() =>
                  // updateFilters(filterLine, "remove")
                  updateFilters({
                    ...props,
                    filterLine: filterLine,
                    action: "remove",
                  })
                }
              >
                <DeleteOutlineIcon style={{ fontSize: 20 }} />
              </Button>
            </GridItem>
          </GridContainer>
        );
      })}

      <GridContainer alignItems="baseline" style={{ marginTop: "10px" }}>
        <GridItem xs={12}>
          <Button
            color="primary"
            // disabled={filters[filters.length - 1]?.field === null}
            disabled={
              filters[filters.length - 1]?.field === null ||
              columnListFiltered().length === 0
            }
            // disabled={columnListFiltered()}
            size="xs"
            onClick={() => {
              console.log("%cClick", "background-color: red; color: white");

              const newFilter = {
                id: uuidv4(),
                field: null,
                fieldType: null,
                conditionID: 1,
                values: [],
                operator: null,
                filterLineStatus: "in processs",
              };
              // sendInfoToParent({ filters: [...filters, newFilter] });
              // sendInfoToParent({
              //   filters: [...filters, newFilter],
              //   shouldUpdate: false,
              // });
              sendInfoToParent([...filters, newFilter]);
            }}
          >
            {columnListFiltered().length === 0
              ? "No more filters"
              : "Add filter"}
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const FilterComponent = (props) => {
  const {
    filters,
    filterValuesSelector,
    columnList,
    listsDropdown,
    sendInfoToParent,

    parentKey,
  } = props;
  // console.log(
  //   "%cstarts rendering filter component",
  //   "background-color: yellow; color: red",
  //   props
  // );

  const classes = useStyles();

  const forceCloseRef = useRef();

  const popperContainerClasses = classNames({
    [classes.popperMainContainer]: true,
    // mark as filterd if boolean is selected or any other field has a value
    [classes.popperMainContainerFiltered]:
      filters.filter(
        (item) =>
          (item.fieldType !== "boolean" && item.values.length > 0) ||
          item.fieldType === "boolean"
      ).length > 0,

    [classes["primary"]]: "primary",
  });

  const popperIconContainerClasses = classNames({
    [classes.popperIconContainer]: true,
    // [classes.iconOnlyContainer]: value === undefined,
    [classes["primary"]]: "primary",
  });

  const removeUncompleteFilters = () => {
    // remove line that were selected but values where defined
    const newArray = [...filters];

    const finalArray = newArray.filter(
      (item) =>
        (item.fieldType !== "boolean" && item.values.length > 0) ||
        item.fieldType === "boolean"
    );
    // console.log("updated filters", finalArray);
    // setParameterList({ ...parameterList, filters: finalArray });
    // sendInfoToParent({ filters: finalArray, shouldUpdate: true });
    sendInfoToParent(finalArray);
  };

  return (
    <CustomPopover
      ref={forceCloseRef}
      onClose={() => removeUncompleteFilters()}
      title="Filters"
      clearAll={() =>
        // updateFilters(null, "remove", props)
        updateFilters({ ...props, filters: [], action: "remove" })
      }
      triggerComponent={(handleOpen) => (
        <div
          style={{
            // maxWidth: "100%",
            width: "100%",
            height: "100%", // substracts 18px which is the height of the label
            // pointerEvents: disabled ? "none" : "auto",
          }}
          className={popperContainerClasses}
          onClick={handleOpen}

          // deleteIcon={}
        >
          <div className={popperIconContainerClasses}>
            {/* <FeatherIcon size="20" icon="filter" /> */}
            <FeatherIcon size="20" icon="filter" />
          </div>
        </div>
      )}
    >
      <FilterPopper
        {...props}
        closePopover={() => {
          // this allows to run a function that is inside CustomPopover from button inside FilterPopper
          forceCloseRef.current?.close();
        }}
      />
    </CustomPopover>
  );
};

export default FilterComponent;
