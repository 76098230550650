import React from "react";
import {
  AreaChart,
  Area,
  Label,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

// components
import CustomTooltip from "./CustomTooltip/CustomTooltip";

// styles
import {
  grayColor,
  primaryColor,
  infoColor,
} from "assets/jss/material-dashboard-react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  mainContainer: { paddingTop: "30px", paddingBottom: "20px" },
  title: {
    // paddingTop: "50px",
    paddingLeft: "25px",
    paddingBottom: "35px",
    paddingRight: "5px",
    margin: "0px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
  },
};

const useStyles = makeStyles(styles);

export const Chart = (props) => {
  const {
    data,
    title,
    dataKey1,
    dataKey2,
    dataName1,
    dataName2,
    titleTooltip,
    xAxisLabels,
    yAxisLegend,
    formatMode,
    yAxisMaxValue,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      {/* <CustomTooltip tooltipText={titleTooltip} placement="bottom"> */}
      <div className={classes.titleContainer}>
        <h4 className={classes.title}>{title}</h4>
        <CustomTooltip
          tooltipText={titleTooltip}
          size={14}
          placement="bottom"
        />
      </div>
      {/* </CustomTooltip> */}

      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorKey2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={primaryColor[4]} stopOpacity={0.8} />
              <stop offset="95%" stopColor={primaryColor[4]} stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorKey1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={infoColor[5]} stopOpacity={0.8} />
              <stop offset="95%" stopColor={infoColor[5]} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey={dataKey1}
            stroke={primaryColor[4]}
            fillOpacity={0.6}
            fill="url(#colorKey2)"
            strokeWidth={4}
            dot={{ stroke: primaryColor[4], strokeWidth: 4 }}
            name={dataName1}
          />
          {dataKey2 && (
            <Area
              type="monotone"
              dataKey={dataKey2}
              stroke={infoColor[5]}
              fillOpacity={0.6}
              fill="url(#colorKey1)"
              strokeWidth={4}
              dot={{ stroke: infoColor[5], strokeWidth: 4 }}
              name={dataName2}
            />
          )}
          {dataName1 && (
            <Legend
              verticalAlign="bottom"
              height={36}
              iconSize={10}
              iconType="circle"
              formatter={(value) => (
                <span style={{ fontSize: "80%" }}>{value}</span>
              )}
            />
          )}

          <CartesianGrid
            // width={5}
            stroke={grayColor[7]}
            // strokeDasharray="5"
            vertical={false}
          />
          <XAxis dataKey={xAxisLabels} hide />
          <YAxis
            domain={[
              0,

              yAxisMaxValue === undefined ? "dataMax+5" : yAxisMaxValue,
            ]}
            tickCount={yAxisMaxValue === 5 ? 6 : 5}
            tickFormatter={(value) => {
              switch (formatMode) {
                case "currency":
                  return "$" + value;
                case "minutes":
                  return value + "min";
                case "hours":
                  return value + "h";
                case "percent":
                  return value * 100 + "%";
                default:
                  return value;
              }
            }}
          >
            <Label
              angle={-90}
              value={yAxisLegend}
              position="insideLeft"
              style={{
                textAnchor: "middle",
                fontSize: "80%",
                fill: grayColor[4],
              }}
            />
          </YAxis>
          <Tooltip
            formatter={(value) => {
              switch (formatMode) {
                case "currency":
                  return "$" + value;
                case "minutes":
                  return value + " min";
                case "percent":
                  return value * 100 + "%";
                default:
                  return value;
              }
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
