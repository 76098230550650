import React, { useEffect, useState, useContext, useMemo } from "react";

// import _ from "lodash";
import isEqual from "lodash/isEqual";

// data
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import config from "config";
import { useQuery } from "@apollo/client";
import { meetingRating } from "graphql/gqlMeeting";
import { axiosOptions, debounce } from "functions/Common";
import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import { tourData } from "utils/tourData";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import MedalWithIndicator from "components/MedalWithIndicator/MedalWithIndicator.js";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
  // ErrorBoundary,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
// import ErrorHandler from "components/Error/Error";

// icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// style
import { grayColor } from "assets/jss/material-dashboard-react.js";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";

const styles = {
  cardBody: {
    paddingTop: "40px",
    paddingLeft: "40px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
  noObjectivesContainer: {
    height: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    border: `1px solid ${grayColor[6]}`,
    borderRadius: "10px",
    flex: 1,
    marginRight: "5px",
  },
  objectiveNumber: {
    color: grayColor[4],
    display: "flex",
    marginBottom: "7px",
    width: "30px",
  },
  titleContainer: {
    display: "flex",
    width: "30px",
    alignItems: "center",
  },
  titleLabel: {
    color: grayColor[4],
    paddingLeft: "40px",
  },
};

const useStyles = makeStyles(styles);

export default function MeetingPurpose(props) {
  const classes = useStyles();
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  // console.log("los props en purpose son", props);
  const { meetingID, sendInfoToHeader, statusID } = props;

  const ObjectivesMain = () => {
    const { session, setSession } = useContext(SessionContext);
    const { firstUse } = session;

    const [hasData, setHasData] = useState(false);

    const spacing = 3;

    const NoObjectives = () => {
      return (
        <div className={classes.noObjectivesContainer}>
          <h3 style={{ color: grayColor[4] }}>
            No objectives were defined for this meeting
          </h3>
        </div>
      );
    };

    // if (isLoading) return <ActivityIndicator fullscreen />;
    // const objectivesNotFound = objectivesState.length === 0;

    return (
      <>
        {[3, 4, 5, 6, 7].includes(statusID) && !hasData && !firstUse ? (
          <NoObjectives />
        ) : (
          <>
            <ObjectivesHeader spacing={spacing} />
            <ObjectivesDetails
              meetingID={meetingID}
              sendInfoToHeader={sendInfoToHeader}
              spacing={spacing}
              sendInfoToParent={(value) => setHasData(value)}
            />
          </>
        )}
      </>
    );
  };

  return (
    <CardBody fullscreen>
      <ErrorBoundary
        FallbackComponent={(error) => (
          <LogErrorComponent
            error={error}
            size="large"
            data={{
              errorLevel: "fatal",
              generalData: { ...generalData },
              otherData: { meeting },
            }}
          />
        )}
      >
        <ObjectivesMain />
      </ErrorBoundary>

      {errorState && (
        <ErrorHandler
          error={errorState.error}
          data={{
            errorLevel: errorState.data.errorLevel,
            generalData: { ...generalData },
            otherData: { ...errorState.data.otherData },
          }}
          errorExplanation={errorState.errorExplanation} // only for dialog, not error
          errorClear={() => setErrorState()}
        />
      )}
    </CardBody>
  );
}

const ObjectivesHeader = ({ spacing }) => {
  const classes = useStyles();
  return (
    <GridContainer spacing={0} direction="row">
      <GridItem xs="auto">
        <div className={classes.titleContainer}></div>
      </GridItem>
      <GridItem xs={4}>
        <div className={classes.titleContainer}>
          <h6 className={classes.titleLabel}>Objectives</h6>
          <CustomTooltip
            tooltipText="Adding objectives will grant 1 point towards meeting's final rating"
            placement="bottom-start"
          >
            <div>
              <MedalWithIndicator variant="reward" size={20} indicator="1" />
            </div>
          </CustomTooltip>
        </div>
      </GridItem>
      <GridItem xs={4}>
        <div className={classes.titleContainer}>
          <h6 className={classes.titleLabel}>Result</h6>
          <CustomTooltip
            tooltipText="Completing results will grant 1 point towards meeting's final rating"
            placement="bottom-start"
          >
            <div>
              <MedalWithIndicator variant="reward" size={20} indicator="1" />
            </div>
          </CustomTooltip>
        </div>
      </GridItem>
    </GridContainer>
  );
};

const ObjectivesDetails = (props) => {
  console.log(
    "--------------------Starts objectives details-------------------------"
  );
  const classes = useStyles();
  const { meetingID, sendInfoToParent, sendInfoToHeader, spacing } = props;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { parentEventID, statusID, isOrganizer } = meeting;
  const { session, setSession } = useContext(SessionContext);
  const { userID, firstUse } = session;

  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  const [isLoading, setIsLoading] = useState(!firstUse); // if firstUse, loading is false, otherwise true as it will fetch data
  const blankObjective = {
    objective: "",
    result: "",
    meetingObjectiveId: 1,
    meetingId: meetingID,
  };

  const [objectivesState, setObjectivesState] = useState([blankObjective]);

  let initialObjectives = [];

  // search for the highest meetingObjectiveID and adds 1
  const blankObjectiveWithNewID = (initialArray) => {
    // let maxID = 1;
    const maxID = initialArray.reduce((group, item, index) => {
      // console.log("group", group);
      // console.log("item", item);

      if (Object.keys(group).length === 0 || item.value > group.value) {
        // return item;
        group = item.meetingObjectiveId;
      }
      return group;
    }, 0);

    const blankItem = { ...blankObjective, meetingObjectiveId: maxID + 1 };
    // console.log("blankItem", blankItem);
    return blankItem;
    // return blankItem;
  };

  useEffect(() => {
    console.log("----mounts---");
    if (firstUse) {
      const tourDummyData = tourData("purpose");

      setObjectivesState(tourDummyData);
      return;
    }

    const options = axiosOptions({
      url: `${config.url}/api/meeting-info`,
      method: "get",
      params: {
        meetingID: meetingID,
        userID: userID,
        origin: "purpose",
      },
    });

    axios(options)
      .then(async (response) => {
        // console.log("la respuesta es", response.data.info);
        // const { content, privateNotes } = response.data.info;

        response.data.info.length === 0 && sendInfoToParent(false);

        initialObjectives = response.data.info;
        if (
          [2, 8].includes(statusID) &&
          meeting.changesFromPurpose !== undefined
        ) {
          // if there's data in the meeting state, it meetings the data has not been saved yet to the db
          // and it should be loaded from the state and not from the db
          setObjectivesState(meeting.changesFromPurpose.objectives.new);
          // console.log("changesFromPuros", meeting.changesFromPurpose.objectives);
        } else {
          // loads info

          [0, 1, 2].includes(statusID)
            ? setObjectivesState([
                ...response.data.info,
                blankObjectiveWithNewID(response.data.info),
              ])
            : setObjectivesState([...response.data.info]);
          // }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorState({
          error: error,
          errorExplanation: "Could not load the objectives",
          data: {
            errorLevel: "fatal",
            otherData: { axiosOptions: options },
          },
        });
      });

    return () => {
      console.log("----UN mounts---");
    };
  }, []);

  let changes = {};

  const storeChanges = (newObjectives) => {
    try {
      // removes the typename
      const newObjectivesForUpdate = newObjectives.map(
        ({ __typename, ...rest }) => {
          return rest;
        }
      );

      console.log("newObjectivesForUpdate", newObjectivesForUpdate);
      // if in status sent and only for the objectives track any change that would require attendees notifications
      if ([2, 8].includes(statusID)) {
        // const initialObjectives =
        //   data.meetingById.meetingObjectivesByMeetingId.nodes;
        // compare if initial objectives array is equal to current
        if (isEqual(newObjectives, initialObjectives)) {
          console.log("arrays are equal");

          delete changes.objectives;
        } else {
          console.log("arrays are NOT equal");
          changes = {
            ...changes,
            objectives: {
              current: initialObjectives,
              new: newObjectivesForUpdate,
            },
          };
        }

        console.log("los changes son", changes);
        // setMeeting({
        //   ...meeting,
        //   changesFromPurpose:
        //     Object.keys(changes).length === 0 ? undefined : changes,
        // });
        let newRatingElements;

        sendInfoToHeader({ changesFromPurpose: changes });
      } else {
        const options = axiosOptions({
          url: `${config.url}/api/meeting-save-encrypted`,
          method: "post",
          data: {
            meetingID: meetingID,
            parentEventID: parentEventID,
            statusID: statusID,
            objectives: {
              new: JSON.stringify(newObjectivesForUpdate),
            },
            userID: userID,
          },
        });

        debounce(() =>
          axios(options)
            .then(async (response) => {
              // refetch();
              console.log(
                "-------------------------------------------db update"
              );
            })
            .catch((error) => {
              logError(error, {
                errorLevel: "critical",
                generalData: { ...generalData },
                // otherData: { ...data },
              });
            })
        );
      }

      // updates the rating indicator
      let newRatingElements = meeting.ratingElements.map((item) => ({
        ...item,
      }));
      let objectivesRatingIndex = newRatingElements.findIndex(
        (item) => item.id === 1
      );
      if (newObjectivesForUpdate.length === 0) {
        newRatingElements[objectivesRatingIndex].value = 0;
      } else {
        newRatingElements[objectivesRatingIndex].value = 1;
      }

      console.log("newRatingElements", newRatingElements);

      setMeeting({ ...meeting, ratingElements: newRatingElements });

      // setMeeting({ ...meeting, test: "ljklkjlkjlk" });
    } catch (error) {
      logError(error, {
        errorLevel: "fatal",
        generalData: { ...generalData },
        otherData: {},
      });
    }
  };

  const deleteObjective = (index) => {
    try {
      //  removes the item
      const newObjectives = objectivesState.filter(
        (item, index2) => index2 !== index
      );

      setObjectivesState(
        newObjectives.length === 0
          ? [blankObjectiveWithNewID(objectivesState)]
          : newObjectives
      );
      const newArray = cloneDeep(newObjectives);
      newArray.pop();
      // store temp data when changes might be applied to meeting after is has been sent
      storeChanges(newArray);
    } catch (error) {
      setErrorState({
        error: error,
        errorExplanation: "The objective could not be deleted",
        data: {
          errorLevel: "critical",
          otherData: { index, objectivesState },
        },
      });
    }
  };

  const updateField = (updatedProperties, index) => {
    try {
      // modifies the objective selected and removes the typename
      // const newObjectives = objectivesState.map((item2, index2) => {
      //   if (index === index2) {
      //     return {
      //       ...item2,
      //       [fieldName]: event.target.value,
      //     };
      //   } else {
      //     return item2;
      //   }
      // });

      let newObjectives = cloneDeep(objectivesState);
      const lastIndex = newObjectives.length - 1;
      newObjectives = newObjectives.reduce((group, item, index2) => {
        let newItem;

        if (index === index2) {
          console.log("indexes", {
            1: newObjectives[lastIndex].objective.length !== 0,
            2: newObjectives[lastIndex].result.length !== 0,
            3: updatedProperties?.objective?.length !== 0,
            4: updatedProperties?.result?.length !== 0,
            5: lastIndex <= index2 - 1,
          });
          if (
            // adds the line if the item selected:

            // is updating an objective
            updatedProperties?.objective?.length > 0 ||
            // is updating a result only when there's already an objective
            (updatedProperties?.result?.length > 0 &&
              item.objective.length > 0) ||
            // if the objective or result is removed from a line that's not the last line or the line before, adds the line
            ((updatedProperties?.objective?.length === 0 ||
              updatedProperties?.result?.length === 0) &&
              index2 <= lastIndex - 2) ||
            // if the result is deleted but hte objective has data, adds the line
            (item.objective.length > 0 &&
              updatedProperties?.result?.length === 0)

            //   ||
            // // if the last line is empty and the if current line is before the last and it's also empty too, don't add the line
            // (newObjectives[lastIndex].objective.length !== 0 &&
            //   newObjectives[lastIndex].result.length !== 0 &&
            //   updatedProperties?.objective?.length !== 0 &&
            //   updatedProperties?.result?.length !== 0 &&
            //   lastIndex <= index2 - 1)
          ) {
            newItem = {
              ...item,
              ...updatedProperties,
            };

            console.log(
              `%c#: ${index2 + 1} updates item selected`,
              "background-color: blue; color: white",
              newItem
            );

            group.push(newItem);
          } else {
            console.log(
              `%c#: ${index2 + 1} don't add item selected`,
              "background-color: red; color: white"
            );
          }
        } else {
          // evaluates if the other lines are empty.  If so, remove them and only keeps the ones with a description
          if (
            item.objective?.length > 0 ||
            item.result.length > 0 ||
            // if a line already has an empty desc but it's not the last line, adds the line
            (item.objective.length === 0 && lastIndex !== index2)
          ) {
            newItem = {
              ...item,
            };
            console.log(
              `%c#: ${index2 + 1} updates item not selected`,
              "background-color: green; color: white",
              newItem
            );

            group.push(newItem);
          } else {
            console.log(
              `%c#: ${index2 + 1} don't add item not selected`,
              "background-color: red; color: white"
            );
          }
        }

        // }
        return group;
      }, []);

      [0, 1, 2].includes(statusID)
        ? setObjectivesState([
            ...newObjectives,
            blankObjectiveWithNewID(objectivesState),
          ])
        : setObjectivesState([...newObjectives]);

      // store changes either directly in db or temp if meeting has already been created
      // and these will be stored temp waiting to be sent manually in batch
      storeChanges(newObjectives);
    } catch (error) {
      setErrorState({
        error: error,
        errorExplanation: "Objective could not be updated",
        data: {
          errorLevel: "critical",
          otherData: {
            updatedProperties: updatedProperties,
            index: index,
            objectivesState: objectivesState,
          },
        },
      });
    }
  };

  return (
    <>
      {objectivesState.map((item, index) => {
        return (
          <GridContainer
            key={index}
            spacing={spacing}
            direction="row"
            alignItems="center"
          >
            <GridItem xs="auto">
              <div className={classes.objectiveNumber}>
                <h5># {index + 1}</h5>
              </div>
            </GridItem>
            <GridItem xs={4}>
              <CustomMainInput
                disabled={!isOrganizer || [3, 4, 5, 6, 7].includes(statusID)}
                height="40px"
                onChange={(event) => {
                  // updateField(event, index, "objective");
                  updateField(
                    { objective: event.target.value },

                    index
                    // true, // remove error
                    // true, // withDebounce
                    // true // save to db
                  );
                }}
                value={item.objective}
              />
            </GridItem>
            <GridItem xs={4}>
              <CustomMainInput
                disabled={[1, 2, 8].includes(statusID) && isOrganizer}
                height="40px"
                onChange={(event) => {
                  // updateField(event, index, "result");
                  updateField(
                    { result: event.target.value },

                    index
                    // true, // remove error
                    // true, // withDebounce
                    // true // save to db
                  );
                }}
                value={item.result}
              />
            </GridItem>
            {[0, 1, 2].includes(statusID) &&
              objectivesState.length - 1 !== index && (
                <GridItem xs={1}>
                  <Button
                    size="md"
                    disabled={!isOrganizer || [4, 5, 6].includes(statusID)}
                    color="danger"
                    justIcon
                    onClick={() => deleteObjective(index)}
                  >
                    <DeleteOutlineIcon fontSize="large" />
                    {/* <Hidden mdUp implementation="css">
<p className={classes.linkText}>Perfile</p>
</Hidden> */}
                  </Button>
                </GridItem>
              )}
          </GridContainer>
        );
      })}
      {errorState && (
        <ErrorHandler
          error={errorState.error}
          data={{
            errorLevel: errorState.data.errorLevel,
            generalData: { ...generalData },
            otherData: { ...errorState.data.otherData },
          }}
          errorExplanation={errorState.errorExplanation} // only for dialog, not error
          errorClear={() => setErrorState()}
        />
      )}
    </>
  );
};
