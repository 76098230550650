// styles
import { makeStyles } from "@material-ui/core/styles";
import { grayColor, infoColor } from "assets/jss/material-dashboard-react";

const styles = {
  box: {
    // border: "1px solid red",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
    borderRadius: "5px",
    backgroundColor: "#fff",

    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    // minHeight: "100px",
    margin: "10px 0px",

    //controls the rhombus
    "&:before": {
      content: "' '",
      backgroundColor: infoColor[5],
      // boxShadow: "1px -1px 1px rgba(0, 0, 0, 0.2)",
      boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.1)",
      position: "absolute",
      left: "-7.5px",
      top: "10px",
      transform: "rotate(-135deg)",
      width: "15px",
      height: "15px",
    },
  },
  box2: {
    zIndex: 0,
    // padding: "5px",
  },
  boxAndDuration: {
    width: "100%",
  },
  circle: {
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0px",
  },
  durationLabel: {
    textAlign: "center",
    color: grayColor[4],
  },
  line: {
    borderLeft: `1px solid ${grayColor[4]}`,
    width: "3px",
  },
  linesAndDotMain: {
    // backgroundColor: "green",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    minHeight: "100px",

    margin: "0px 20px 0px 20px",
  },
  mainContainer: {
    // height: "100%",
    width: "100%",
    display: "flex",
    paddingRight: "10px",
    // border: "1px solid red",
  },

  verticalLine1WithoutDuration: {
    height: "10px",
  },
  verticalLine2WithoutDuration: {
    // total height minus first line length, circle height and margins
    height: "calc(100% - 10px - 10px - 10px - 15px)",
  },
  verticalLine1WithDuration: {
    height: "32px",
  },
  verticalLine2WithDuration: {
    // total height minus first line length, circle height and margins
    height: "calc(100% - 32px - 10px - 10px - 15px)",
  },
};

const useStyles = makeStyles(styles);

const Timeline = (props) => {
  const {
    circleSize = "15px",
    circleColor = grayColor[5],
    duration,
    children,
    completed,
  } = props;
  const classes = useStyles();

  const LinesAndDot = () => {
    const Circle = () => {
      return (
        <div
          className={classes.circle}
          style={{
            height: circleSize,
            width: circleSize,
            backgroundColor: circleColor,
          }}
        ></div>
      );
    };

    const VerticalLine1 = () => {
      return (
        <div
          className={`${classes.line} ${
            completed
              ? classes.verticalLine1WithoutDuration
              : classes.verticalLine1WithDuration
          }`}
        ></div>
      );
    };

    const VerticalLine2 = () => {
      return (
        <div
          className={`${classes.line} ${
            completed
              ? classes.verticalLine2WithoutDuration
              : classes.verticalLine2WithDuration
          }`}
        ></div>
      );
    };

    return (
      <div className={classes.linesAndDotMain}>
        <VerticalLine1 />
        <Circle />
        <VerticalLine2 />
      </div>
    );
  };

  // const BoxAndArrow = () => {
  //   return <div className={classes.box}></div>;
  // };

  // return <h5>hola</h5>;

  return (
    <div className={classes.mainContainer}>
      <LinesAndDot />
      <div className={classes.boxAndDuration}>
        <h6 className={classes.durationLabel}>{duration}</h6>
        <div className={classes.box}>
          <div className={classes.box2}>{children}</div>
        </div>
      </div>
    </div>
  );
};
export default Timeline;
