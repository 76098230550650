import React, { useRef } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// core components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/CustomButtons/Button.js";
import Divider from "@material-ui/core/Divider";
// icons
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import BlockIcon from "@material-ui/icons/Block";
import FeatherIcon from "feather-icons-react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CloseIcon from "@material-ui/icons/Close";
// styles
import styles from "assets/jss/material-dashboard-react/components/customDialogStyle.js";

const useStyles = makeStyles(styles);

export default function CustomDialog(props) {
  // console.log(
  //   "%cstarts customDialog",
  //   "background-color: brown; color: yellow"
  // );

  const classes = useStyles();
  const {
    visibleStatus,
    setVisibleStatus,
    headerTitle,
    icon,
    iconPreset,
    title,
    mainText,
    center,
    children,
    buttonCloseLabel,
    buttonActionLabel1,
    buttonActionFunction1,
    buttonActionDisabled1,
    buttonActionLoading1,
    buttonActionIcon1,
    buttonActionColor1,
    buttonActionLabel2,
    buttonActionFunction2,
    buttonActionDisabled2,
    buttonActionLoading2,
    buttonActionIcon2,
    buttonActionColor2,
    buttonActionLabel3,
    buttonActionFunction3,
    buttonActionDisabled3,
    buttonActionLoading3,
    buttonActionIcon3,
    buttonActionColor3,
    deleteDialog,
    divider,
    maxWidth,
    otherDialogActions,
  } = props;

  const labelClasses = classNames({
    [" " + classes.center]: center,
  });

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <div>
      <Dialog
        classes={{ paper: classes.mainDialog }}
        open={visibleStatus.visible || false}
        maxWidth={maxWidth || false}
        fullWidth={maxWidth ? true : false}
        onClose={handleClose}
        disableEscapeKeyDown
      >
        {deleteDialog ? (
          <DialogTitle className={classes.icon + " " + classes.delete}>
            <WarningRoundedIcon style={{ fontSize: 40 }} />
          </DialogTitle>
        ) : headerTitle ? (
          <div className={classes.headerContainer}>
            <h5 className={classes.headerTitle}>{headerTitle}</h5>
            <Button
              color="gray"
              // disabled={saveState.isSaving}
              disabled={false}
              justIcon
              simple
              onClick={() => {
                setVisibleStatus({
                  ...visibleStatus,
                  visible: false,
                });
              }}
            >
              <CloseIcon style={{ fontSize: 40 }} />
            </Button>
          </div>
        ) : (
          // </DialogTitle>
          // <DialogTitle className={classes.icon + " " + classes.delete}>
          //   <WarningRoundedIcon style={{ fontSize: 40 }} />
          // </DialogTitle>
          // icon && <DialogTitle className={classes.icon}>
          //             {icon}
          // </DialogTitle>

          (icon || iconPreset) && (
            <DialogTitle className={classes.icon}>
              {icon}
              {
                {
                  forbidden: <BlockIcon fontSize="large" />,
                  success: <FeatherIcon icon="thumbs-up" />,
                  error: <ErrorOutlineIcon fontSize="large" />,
                  question: <HelpOutlineIcon fontSize="large" />,
                }[iconPreset]
              }
            </DialogTitle>
          )
        )}

        <DialogContent className={classes.dialogContent}>
          {title && (
            <>
              <h6 className={classes.title + labelClasses}>{title}</h6>
              {divider && <Divider />}
            </>
          )}

          <DialogContentText className={classes.mainText + labelClasses}>
            {mainText}
          </DialogContentText>
          {children}
        </DialogContent>
        {(deleteDialog ||
          buttonCloseLabel ||
          buttonActionLabel1 ||
          buttonActionLabel2 ||
          buttonActionLabel3) && (
          <DialogActions className={classes.dialogActions}>
            {deleteDialog ? (
              <>
                <Button
                  onClick={() =>
                    setVisibleStatus({ ...visibleStatus, visible: false })
                  }
                  disabled={buttonActionLoading1}
                  color="primary"
                  // startIcon={<CancelOutlinedIcon />}
                >
                  Cancel
                </Button>
                <Button
                  onClick={buttonActionFunction1}
                  isLoading={buttonActionLoading1}
                  disabled={buttonActionLoading1 || buttonActionDisabled1}
                  color="danger"
                  // startIcon={<SaveOutlinedIcon />}
                >
                  OK
                </Button>
              </>
            ) : (
              <>
                {buttonCloseLabel && (
                  <Button
                    onClick={() => {
                      setVisibleStatus(false);
                    }}
                    color="primary"
                    // startIcon={<CancelOutlinedIcon />}
                  >
                    {buttonCloseLabel}
                  </Button>
                )}
                {buttonActionLabel1 && (
                  <Button
                    onClick={buttonActionFunction1}
                    isLoading={buttonActionLoading1}
                    disabled={buttonActionLoading1 || buttonActionDisabled1}
                    color={buttonActionColor1 || "primary"}
                    startIcon={buttonActionIcon1}
                  >
                    {buttonActionLabel1}
                  </Button>
                )}
                {buttonActionLabel2 && (
                  <Button
                    onClick={buttonActionFunction2}
                    isLoading={buttonActionLoading2}
                    disabled={buttonActionLoading2 || buttonActionDisabled2}
                    color={buttonActionColor2 || "primary"}
                    startIcon={buttonActionIcon2}
                  >
                    {buttonActionLabel2}
                  </Button>
                )}
                {buttonActionLabel3 && (
                  <Button
                    onClick={buttonActionFunction3}
                    isLoading={buttonActionLoading3}
                    disabled={buttonActionLoading3 || buttonActionDisabled3}
                    color={buttonActionColor3 || "primary"}
                    startIcon={buttonActionIcon3}
                  >
                    {buttonActionLabel3}
                  </Button>
                )}
                {otherDialogActions}
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

CustomDialog.propTypes = {
  icon: PropTypes.object,
  visibleStatus: PropTypes.object,
  setVisibleStatus: PropTypes.func,
  title: PropTypes.string,
  mainText: PropTypes.string,
  center: PropTypes.bool,
  children: PropTypes.node,
  buttonCloseLabel: PropTypes.string,
  buttonActionLabel1: PropTypes.string,
  buttonActionFunction1: PropTypes.func,
  buttonActionDisabled1: PropTypes.bool,
  buttonActionLoading1: PropTypes.bool,
  buttonActionLabel2: PropTypes.string,
  buttonActionFunction2: PropTypes.func,
  buttonActionDisabled2: PropTypes.bool,
  buttonActionLoading2: PropTypes.bool,
  deleteDialog: PropTypes.bool,
  divider: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]),
  iconPreset: PropTypes.oneOf(["forbidden", "success", "error", "question"]),
};
