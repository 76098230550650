import React, { useContext, useEffect, useState, useRef } from "react";

// data
import { useMutation } from "@apollo/client";
import { SessionContext } from "../../../contexts/SessionContext";
import { MeetingContext } from "contexts/MeetingContext";
import { updateMeetingAttendees } from "graphql/gqlMeetingHeader";

// custom components
import Button from "components/CustomButtons/Button";
import CustomHeader from "components/CustomHeader/CustomHeader";
import CustomDropDown from "components/CustomDropDown/CustomDropDown";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import { RatingIndicator } from "components/RatingIndicator";
import MedalWithIndicator from "components/MedalWithIndicator/MedalWithIndicator";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// icons
import CheckIcon from "@material-ui/icons/Check";

//styles
import {
  defaultFont,
  grayColor,
  successColor,
  dangerColor,
} from "assets/jss/material-dashboard-react.js";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  attendanceButtonContainer: {
    marginLeft: "10px",
  },
  buttonHeader: {
    paddingLeft: "5px",
    display: "flex",
    flexDirection: "row",
  },
  confirmButtonsContainer: {
    justifyContent: "space-around",
    display: "flex",
    margin: "20px 40px 0px 40px",
  },
  finalRatingContainer: {
    display: "flex",
    flexDirection: "row",

    border: `1px solid ${grayColor[5]}`,
    borderRadius: "5px",
    padding: "0px 7px 0px 7px ",
    marginRight: "5px",
    height: "100%",
  },
  finalRatingIndicator: {
    color: grayColor[1],
    paddingRight: "5px",
  },

  medal: { padding: "0px 3px 0px 3px" },
  medalsIndicator: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    border: `1px solid ${grayColor[5]}`,
    borderRadius: "5px",
    padding: "0px 7px 0px 7px ",
    marginRight: "5px",
    height: "100%",
  },

  statusHeaderLabel: { color: "white", padding: "8px", borderRadius: "5px" },
  statusCompleted: { backgroundColor: successColor[1] },
  statusCancelled: { backgroundColor: dangerColor[1] },
  statusDeleted: { backgroundColor: dangerColor[1] },

  willAttendLabel: {
    color: grayColor[3],
    marginLeft: "15px",
  },
};
const useStyles = makeStyles(styles);

export const MeetingHeader = (props) => {
  const {
    attendeesRef,
    changes,
    childContentRef,
    checkedIn,

    cost,
    dataMeeting,
    departDate,
    finalRating,
    headerVariant,
    isOrganizer,
    lastTabIndex,
    meetingID,
    refetchMeetingGeneral,
    setShowSnackbar,
    setModal,
    // setMustHave,
    setSubmitFormFunc,
    setTabSelected,
    statusID,
    submitFormFunc,
    tabs,
    tabSelected,
    variantTabs,
    willAttend,
  } = props;

  const { meeting, setMeeting, savePendingRef } = useContext(MeetingContext);
  const { session, setSession } = useContext(SessionContext);
  const { firstUse } = session;

  // console.log(
  //   "%cdataMeeting",
  //   "background-color: yellow; color: red",
  //   dataMeeting
  // );

  return (
    <>
      <CustomHeader
        checkInCode={
          !firstUse &&
          (isOrganizer && [1].includes(meeting.meetingTypeID)
            ? dataMeeting?.meetingById?.checkinCode
            : null)
        }
        // checkInCode={
        //   meeting.isOrganizer && [1].includes(meeting.meetingTypeID)
        //     ? meeting.checkinCode
        //     : null
        // }
        cost={cost}
        endDate={!firstUse && dataMeeting?.meetingById.endDate}
        // endDate={meeting.endDate}
        fullscreen
        headerColor="white"
        headerVariant={headerVariant}
        onChangeTab={async (tabIndex) => {
          // if not changing tabs, don't do anything
          if (lastTabIndex === tabIndex) {
            return null;
          }
          // if it's a repeating event and the content has changed, it requires to apply for current event, all series or current and following events

          // when placed in the details tab and before starting the meeting, validate before changing no another tab
          // if (lastTabRef.current === 0 && [0, 1, 2].includes(statusID)) {
          //   const reopenModalsponse = await childContentRef.current?.triggerValidation();
          //   console.log(
          //     "%cLa response es",
          //     "background-color: blue; color: white",
          //     response
          //   );

          //   if (Object.keys(response).length !== 0) {
          //     console.log(
          //       "%cErrors were found.  Can't change tab",
          //       "background-color: red; color: white"
          //     );
          //     return null;
          //   }
          // }
          //   console.log("No error found", submitFormFunc);

          if (savePendingRef.current === true) {
            // open modal and provides the destination tab in case the changes are discarded
            // modalRepeatEventTypeRef.current.openModal({
            //   selectedTabIndex: tabIndex,
            // });
            setModal({
              visible: true,
              name: "repeat-event-type",
              data: {
                selectedTabIndex: tabIndex,
              },
            });
          } else {
            setTabSelected(tabIndex);
          }
        }}
        leftAlignItems={
          <Medals
            dataMeeting={dataMeeting}
            finalRating={finalRating}
            headerVariant={headerVariant}
            statusID={statusID}
          />
        }
        rightAlignItems={
          <RightButtons
            attendeesRef={attendeesRef}
            changes={changes}
            childContentRef={childContentRef}
            checkedIn={checkedIn}
            departDate={departDate}
            isOrganizer={isOrganizer}
            meetingID={meetingID}
            refetchMeetingGeneral={refetchMeetingGeneral}
            setShowSnackbar={setShowSnackbar}
            setModal={setModal}
            setSubmitFormFunc={setSubmitFormFunc}
            statusID={statusID}
            submitFormFunc={submitFormFunc}
            tabSelected={tabSelected}
            willAttend={willAttend}
          />
        }
        sendInfoToParent={(item) => {
          // set if start date has begun or ending date is over
          // setMustHave(item);
        }}
        showTimeCountDown={[1].includes(meeting.meetingTypeID)}
        startDate={!firstUse && dataMeeting?.meetingById?.startDate}
        statusID={
          statusID
          //   dataMeeting?.spMeetingStatus?.nodes.length === 0
          //     ? null
          //     : dataMeeting?.spMeetingStatus?.nodes[0].statusId
        }
        tabs={firstUse ? tabs : variantTabs} // if firstUse, show all tabs unfiltered
        tabSelected={tabSelected}
      />
      {/* <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <pre>
          {JSON.stringify(
            {
              meetingID: meetingID,
              changes,
            },
            null,
            2
          )}
        </pre>
      </div> */}
    </>
  );
};

const Medals = (props) => {
  const classes = useStyles();
  const { dataMeeting, headerVariant, statusID } = props;
  const { meeting, setMeeting } = useContext(MeetingContext);
  const { session, setSession } = useContext(SessionContext);
  const { userID, firstUse } = session;

  // const ratingElements = dataMeeting?.spMeetingRating?.nodes;
  const ratingElements = meeting.ratingElements;

  const { canViewRating } = firstUse
    ? { canViewRating: true }
    : dataMeeting?.spMeetingFeedback?.nodes[0] || {};

  const { finalRating } = firstUse
    ? {
        finalRating: 9.2, // dummy rating for tour
      }
    : dataMeeting?.spMeetingGeneral?.nodes[0] || {};

  // show only the medals when meeting is not ready with rating
  const MedalsIndicator = () => {
    // console.log("%cData", "background-color: red; color: white", {
    //   ratingElements,
    // });
    return (
      <>
        {ratingElements !== undefined &&
          ratingElements.map((item) => {
            const found = item.showInMeetingStatus.find(
              (i) => i === headerVariant
            );

            // evaluates if any of the medals should be displayed depending on the meeting stage

            // if (item.id !== 5) {
            return (
              <CustomTooltip
                key={item.id}
                tooltipText={item.tooltip}
                placement="bottom"
              >
                <div className={classes.medal}>
                  <MedalWithIndicator
                    variant={
                      found === undefined
                        ? "unavailable"
                        : parseFloat(item.value) !== 0 // medalsCompleted[item.id - 1]
                        ? "green"
                        : "red"
                    }
                    size={20}
                    indicator={
                      found === undefined
                        ? ""
                        : parseFloat(item.value) !== 0 // medalsCompleted[item.id - 1]
                        ? item.value
                        : "x"
                    }
                  />
                </div>
              </CustomTooltip>
            );
            // }
          })}
      </>
    );
  };

  let finalIndicator;
  // show medals or rating if meeting type is group
  if ([1].includes(meeting.meetingTypeID)) {
    if ([1, 2, 3, 7, 8].includes(statusID) || firstUse) {
      finalIndicator = (
        <div className={classes.medalsIndicator}>
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                data={{
                  location: "MedalsIndicator inside Meeting.js",
                  userID: userID,
                  ratingElements: ratingElements,
                }}
              />
            )}
          >
            <MedalsIndicator />
          </ErrorBoundary>
        </div>
      );
    } else if (statusID === 4) {
      finalIndicator = (
        <div className={classes.finalRatingContainer}>
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                data={{
                  location: "MedalsIndicator inside Meeting.js",
                  userID: userID,
                  ratingElements: ratingElements,
                  canViewRating: canViewRating,
                }}
              />
            )}
          >
            <RatingIndicator
              rating={finalRating}
              canViewRating={canViewRating}
              ratingElements={ratingElements}
            />
          </ErrorBoundary>
        </div>
      );
    } else {
      finalIndicator = null;
    }
  } else {
    finalIndicator = null;
  }

  return finalIndicator;
};

const RightButtons = (props) => {
  const classes = useStyles();
  const {
    attendeesRef,
    changes,
    childContentRef,
    checkedIn,
    isOrganizer,
    departDate,
    meetingID,
    refetchMeetingGeneral,
    setShowSnackbar,
    setModal,
    setSubmitFormFunc,
    statusID,
    submitFormFunc,
    tabSelected,
    willAttend,
  } = props;
  const { meeting, setMeeting, savePendingRef } = useContext(MeetingContext);
  const { session, setSession } = useContext(SessionContext);
  const { userID } = session;

  const CancelButtonMenu = () => {
    const cancelOptions = [
      {
        id: 1,

        menuDesc: "This meeting",
        dividingLineBelow: false,
        click: () => {
          setModal({
            visible: true,
            name: "confirm-cancel-meeting",
            data: {
              occurrenceRepeatType: 1,
            },
          });
        },
      },
      {
        id: 3,

        menuDesc: "This and following meetings",
        dividingLineBelow: false,
        click: () => {
          setModal({
            visible: true,
            name: "confirm-cancel-meeting",
            data: {
              occurrenceRepeatType: 3,
            },
          });
        },
      },
      {
        id: 2,

        menuDesc: "All events in the series",
        dividingLineBelow: false,
        click: () => {
          setModal({
            visible: true,
            name: "confirm-cancel-meeting",
            data: {
              occurrenceRepeatType: 2,
            },
          });
        },
      },
    ];
    // show dropdown if there's any recurrence

    if ([0, 1, 2, 8].includes(statusID) && isOrganizer) {
      return (
        <div className={classes.buttonHeader}>
          {meeting.recurrenceTypeID === 0 ? (
            <Button
              onClick={() =>
                setModal({
                  visible: true,
                  name: "confirm-cancel-meeting",
                  data: {
                    occurrenceRepeatType: 0,
                  },
                })
              }
              color="danger"
            >
              Cancel
            </Button>
          ) : (
            <CustomDropDown
              items={cancelOptions}
              title="Cancel"
              color="danger"
            />
          )}
        </div>
      );
    }
  };

  const saveOptions = [
    {
      id: 1,
      menuDesc: "This meeting",
      dividingLineBelow: false,
      click: async () => {
        if (attendeesRef.current.length === 0) {
          setModal({
            visible: true,
            name: "no-attendees",
          });
        } else if (meeting.title === null) {
          setModal({
            visible: true,
            name: "no-title",
          });
        } else {
          // activityRef.current.show();

          await childContentRef.current?.triggerSubmit1();
          savePendingRef.current = false;
          // repeatSaveTypeRef.current = 1;

          // navigate("/app/meetings");
          setShowSnackbar({
            show: true,
            messageType: "success",
            message: "Changes saved",
          });
        }
      },
    },
    {
      id: 3,

      menuDesc: "This and following meetings",
      dividingLineBelow: false,
      click: async () => {
        if (attendeesRef.current.length === 0) {
          setModal({
            visible: true,
            name: "no-attendees",
          });
        } else if (meeting.title === null) {
          setModal({
            visible: true,
            name: "no-title",
          });
        } else {
          // activityRef.current.show();
          await childContentRef.current?.triggerSubmit3();
          savePendingRef.current = false;
          // repeatSaveTypeRef.current = 3;

          navigate("/app/meetings", {
            state: {
              snackBarInfo: JSON.stringify({
                messageType: "success",
                message: "Changes saved",
              }),
            },
          });
        }
      },
    },
    {
      id: 2,

      menuDesc: "All events in the series",
      dividingLineBelow: false,
      click: async () => {
        if (attendeesRef.current.length === 0) {
          setModal({
            visible: true,
            name: "no-attendees",
          });
        } else if (meeting.title === null) {
          setModal({
            visible: true,
            name: "no-title",
          });
        } else {
          // activityRef.current.show();
          await childContentRef.current?.triggerSubmit2();
          savePendingRef.current = false;
          setShowSnackbar({
            show: true,
            messageType: "success",
            message: "Changes saved",
          });
          // repeatSaveTypeRef.current = 3;
          // navigate("/app/meetings");
        }
      },
    },
  ];

  const SaveButtonMenu = () => {
    if (meetingID === null && isOrganizer) {
      return (
        <div className={classes.buttonHeader}>
          <CustomDropDown items={saveOptions} title="Save" color="primary" />
        </div>
      );
    }
  };

  const StartButtonMenu = () => {
    if ([2, 8].includes(statusID) && isOrganizer) {
      return (
        <Button
          onClick={() =>
            setModal({ visible: true, name: "confirm-start-meeting" })
          }
          color="primary"
          // startIcon={<CancelOutlinedIcon />}
        >
          Start
        </Button>
      );
    }
  };

  const NotifyChangesButton = () => {
    if (
      // check if the changes object is empty.
      // Can't be null because when adding an removing properties, the object is left empty but not null
      Object.keys(changes).length !== 0 &&
      isOrganizer
    ) {
      return (
        <Button
          onClick={() => {
            setSubmitFormFunc({
              ...submitFormFunc,
              buttonSendChangesPress: true,
            });
          }}
          color="primary"
          style={{ marginLeft: "5px" }}
        >
          Save changes and notify
        </Button>
      );
    }
  };

  const InviteButtonMenu = () => {
    if ([1].includes(statusID) && isOrganizer) {
      return (
        <div className={classes.buttonHeader}>
          <Button
            onClick={async () => {
              if (tabSelected === 0) {
                const response =
                  await childContentRef.current?.triggerValidation();
                console.log("la response", response);

                if (Object.keys(response).length === 0) {
                  console.log("No error invite", submitFormFunc);
                  setModal({ visible: true, name: "invite-meeting" });
                }
              }
            }}
            color="primary"
          >
            Invite
          </Button>
        </div>
      );
    }
  };

  const ConvertToDraftButtonMenu = () => {
    if ([0].includes(statusID) && isOrganizer) {
      return (
        <div className={classes.buttonHeader}>
          <Button
            onClick={async () => {
              const saveItem = saveOptions.filter((item) => item.id === 1);
              console.log("saveItem", saveItem);
              saveItem[0].click();
            }}
            color="primary"
          >
            Convert to draft
          </Button>
        </div>
      );
    }
  };

  const EndButtonMenu = () => {
    if ([3, 7].includes(statusID) && isOrganizer) {
      return (
        <div className={classes.buttonHeader}>
          <Button
            onClick={() =>
              setModal({ visible: true, name: "confirm-end-meeting" })
            }
            color="danger"
            // startIcon={<CancelOutlinedIcon />}
          >
            End
          </Button>
        </div>
      );
    }
  };

  const LeaveButtonMenu = () => {
    if ([3, 7].includes(statusID) && !isOrganizer && checkedIn) {
      return (
        <div className={classes.buttonHeader}>
          <Button
            disabled={departDate !== null}
            onClick={() =>
              setModal({ visible: true, name: "confirm-leave-meeting" })
            }
            color={departDate === null ? "danger" : "gray"}
          >
            {departDate === null ? "Leave" : "left"}
          </Button>
        </div>
      );
    }
  };

  const YesNoButtonMenu = () => {
    const [updateMeetingAttendeesMut] = useMutation(updateMeetingAttendees, {
      onCompleted: () => {
        refetchMeetingGeneral();
      },
    });

    if ([2, 8].includes(statusID) && !checkedIn && !isOrganizer) {
      return (
        <div className={classes.buttonHeader}>
          <h5 className={classes.willAttendLabel}>Will attend?</h5>
          <div className={classes.attendanceButtonContainer}>
            <Button
              onClick={() =>
                updateMeetingAttendeesMut({
                  variables: {
                    meetingID: meetingID,
                    userID: userID,
                    willAttend: false,
                  },
                })
              }
              color="danger"
              endIcon={willAttend === false && <CheckIcon />}
            >
              No
            </Button>
          </div>
          <div className={classes.attendanceButtonContainer}>
            <Button
              onClick={() =>
                updateMeetingAttendeesMut({
                  variables: {
                    meetingID: meetingID,
                    userID: userID,
                    willAttend: true,
                  },
                })
              }
              color="success"
              endIcon={willAttend === true && <CheckIcon />}
            >
              Yes
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {statusID === 4 && (
        <h5
          className={`${classes.statusHeaderLabel} ${classes.statusCompleted}`}
        >
          Completed
        </h5>
      )}
      {statusID === 5 && (
        <h5
          className={`${classes.statusHeaderLabel} ${classes.statusCancelled}`}
        >
          Cancelled
        </h5>
      )}
      {statusID === 6 && (
        <h5 className={`${classes.statusHeaderLabel} ${classes.statusDeleted}`}>
          Deleted
        </h5>
      )}

      {/* <div className={classes.buttonHeader}>
            <Button
              onClick={() =>
                console.log("el ref es", repeatSaveTypeRef.current)
              }
              color="primary"
            >
              TEST
            </Button>
          </div> */}

      <StartButtonMenu />
      <NotifyChangesButton />
      <SaveButtonMenu />
      <InviteButtonMenu />
      <ConvertToDraftButtonMenu />
      <CancelButtonMenu />

      <EndButtonMenu />
      <LeaveButtonMenu />
      <YesNoButtonMenu />
    </>
  );
};
