import {
  infoColor,
  dangerColor,
  defaultFont,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const customDialogStyle = {
  center: {
    textAlign: "center",
  },

  delete: {
    color: dangerColor[1],
  },
  dialogActions: {
    justifyContent: "center",
    padding: "0px 20px 40px 20px",
  },
  dialogContent: {
    padding: "0px 40px 20px 40px",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    padding: "7px 10px 7px 15px",
    backgroundColor: grayColor[7],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: "20px",
  },
  headerTitle: {
    margin: "0px",
    // fontWeight: 600,
    color: grayColor[3],
  },
  icon: {
    color: infoColor[4],
    display: "flex",
    justifyContent: "center",
    paddingTop: 40,
    paddingBottom: 5,
  },
  mainDialog: {
    // padding: "20px",
    overflowY: "hidden", // addesd 5/5/2024
  },
  mainText: {
    ...defaultFont,
    margin: 5,
    fontSize: "0.9rem",
    fontWeight: 400,
    // backgroundColor: "yellow",
    maxWidth: "500px",
  },
  title: {
    ...defaultFont,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 600,
    fontSize: "1.15rem",
  },
};

export default customDialogStyle;
