import React, { useState, useEffect, useRef } from "react";

// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// core components
import Button from "components/CustomButtons/Button.js";

// icons
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Close from "@material-ui/icons/Close";

// style
import { makeStyles } from "@material-ui/core/styles";
import {
  grayColor,
  defaultFont,
  boxShadow,
  transition,
  primaryColor,
  dangerColor,
} from "assets/jss/material-dashboard-react";

const styles = {
  sidebarChevronButton: {
    // position: "absolute",
    backgroundColor: primaryColor[4],
    alignItems: "center",
    borderRadius: "50px",
    color: grayColor[5],
    display: "flex",
    height: "40px",
    justifyContent: "center",
    marginRight: "15px",
    paddingRight: "3px",
    width: "40px",
    ...boxShadow,
  },
  sidebarItemLabel: {
    color: grayColor[3],
  },
  sidebarMainContainer: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    right: 10,
    ...transition,
  },
  sidebarMainContainerClosed: {
    marginRight: "-270px",
    ...transition,
  },
  sidebarSaveButtonContainer: {
    marginBottom: "20px",
  },
  sidebarSavedTemplateContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    width: "100%",
    // backgroundColor: "red",
  },
  sidebarSubContainer: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "orange",
    backgroundColor: grayColor[9],
    padding: "15px 0px",
    height: "calc(80vh)",
    width: "250px",
    borderRadius: "5px",
    ...boxShadow,
  },
  sidebarStickyContainer: {
    // backgroundColor: "purple",
    padding: "0px 15px",
  },
  sidebarScrollableContainer: {
    position: "relative",
    // height: "100%",
    flex: 1,
    // backgroundColor: "blue",
  },
  sidebarScrollableSubContainer: {
    position: "absolute",
    top: "0px",
    bottom: "0px",
    left: "0px",
    padding: "5px 15px 0px 15px",
    overflowY: "auto",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    // backgroundColor: "yellow",
  },
  sidebarTitle: {
    fontWeight: 600,
  },

  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderBottom: `1px solid ${grayColor[5]}`,
    paddingBottom: "10px",
    marginBottom: "10px",
  },
  titleLabel: {
    color: grayColor[4],
    paddingLeft: "20px",
  },
};

const useStyles = makeStyles(styles);

export default function SideMenu(props) {
  // console.log("Starts SideMenu component");
  // console.log(
  //   "%cStarts SideMenu component",
  //   "background-color: green; color: white"
  // );

  const {
    modalVisible,
    title,
    scrollableContent,
    stickyContent,

    sideMenuOpenFromParent,
    setSideMenuOpenFromParent,
  } = props;
  const refOne = useRef(null);
  console.log(
    "%cprops en SideMenu",
    "background-color: orange; color: white",
    props
  );

  const classes = useStyles();

  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  // const { sendInfoToParent } = props;
  const [sideMenuOpenFromChild, setSideMenuOpenFromChild] = useState(
    sideMenuOpenFromParent
  );
  useEffect(() => {
    // console.log("use effect en side menu", {
    //   sideMenuOpenFromChild,
    //   sideMenuOpenFromParent,
    // });
    if (sideMenuOpenFromParent) {
      setSideMenuOpenFromChild(true);
    } else {
      setSideMenuOpenFromChild(false);
    }
  }, [sideMenuOpenFromParent]);

  useEffect(() => {
    // event listeners

    //if the modal is visible, avoid click outside function
    !modalVisible &&
      document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      {
        sideMenuOpenFromParent === undefined
          ? setSideMenuOpenFromChild(false)
          : setSideMenuOpenFromParent(false);
      }
    }
  };

  const ButtonSideMenu = () => {
    return (
      <div
        style={styles.sidebarChevronButton}
        onClick={() => setSideMenuOpenFromChild(!sideMenuOpenFromChild)}
        onBlur={() => console.log("side bar blur")}
      >
        {sideMenuOpenFromChild ? (
          <ChevronRightIcon fontSize="large" style={{ color: "white" }} />
        ) : (
          <ChevronLeftIcon fontSize="large" style={{ color: "white" }} />
        )}
      </div>
    );
  };

  // console.log(
  //   "menu status",
  //   sideMenuOpenFromParent === undefined
  //     ? sideMenuOpenFromChild
  //     : sideMenuOpenFromParent
  // );

  const MainContent = () => {
    const classes = useStyles();
    return (
      <>
        <div className={classes.sidebarStickyContainer}>
          <div className={classes.titleContainer}>
            <h5 className={classes.sidebarTitle}>{title}</h5>
            {sideMenuOpenFromParent !== undefined && (
              <Button
                color="primary"
                size="xs"
                simple
                onClick={() => {
                  setSideMenuOpenFromParent(false);
                  setSideMenuOpenFromChild(false);
                }}
              >
                <Close style={{ fontSize: 22 }} />
              </Button>
            )}
          </div>
        </div>
        {/* the follow two divs allow to have scroll with dynamic height */}
        <div className={classes.sidebarScrollableContainer}>
          <div className={classes.sidebarScrollableSubContainer}>
            <ErrorBoundary
              FallbackComponent={(error) => (
                <LogErrorComponent
                  error={error}
                  size="large"
                  data={{
                    errorLevel: "critical",
                    generalData: { ...generalData },
                    //  otherData: {},
                  }}
                />
              )}
            >
              {scrollableContent}
            </ErrorBoundary>
          </div>
        </div>

        <div className={classes.sidebarStickyContainer}>{stickyContent}</div>
      </>
    );
  };

  return (
    <div
      ref={refOne}
      style={
        sideMenuOpenFromChild
          ? styles.sidebarMainContainer
          : {
              ...styles.sidebarMainContainer,
              ...styles.sidebarMainContainerClosed,
            }
      }
    >
      {sideMenuOpenFromParent === undefined && <ButtonSideMenu />}

      <div className={classes.sidebarSubContainer}>
        {
          // only renders when displayed.  This avoid querying template list
          sideMenuOpenFromChild && <MainContent />
        }
      </div>
      {errorState && (
        <ErrorHandler
          error={errorState.error}
          errorExplanation={errorState.errorExplanation} // only for dialog, not error
          errorClear={() => setErrorState()}
          data={{
            errorLevel: errorState.data.errorLevel,
            generalData: { ...generalData },
            otherData: { ...errorState.data.otherData },
          }}
        />
      )}
    </div>
  );
}
