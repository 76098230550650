import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";

// custom components
import HelpDeskLine from "./HelpDeskLine";
// data
import { SessionContext } from "../../contexts/SessionContext";
import { HelpDeskContext } from "contexts/HelpDeskContext";

// errors
import { logError, useDataForLog } from "functions/ErrorBoundary";

// icons

// style
import { makeStyles } from "@material-ui/core/styles";

import {
  grayColor,
  primaryColor,
  defaultFont,
} from "assets/jss/material-dashboard-react";

const styles = {
  loadingLabel: {
    fontStyle: "italic",
    color: grayColor[4],
    fontWeight: 400,
  },
  loadingContainer: {
    borderRadius: "5px",
    // width: "400px",
    // position: "absolute",
    bottom: "0px",
    // top: "0px",
    height: "30px",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    backgroundColor: grayColor[6],
    margin: "5px 0px",
  },
};
const useStyles = makeStyles(styles);

export default function HelpDeskListSub(props) {
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { data, selector } = props;

    // const [records, setRecords] = useState([]);
    const { session, setSession } = useContext(SessionContext);
    const { languageID, userID } = session;

    const { helpDesk, setHelpDesk, lastTicketDisplayedRef } =
      useContext(HelpDeskContext);
    const {} = helpDesk;

    if (selector === "closed") {
      const TicketLineRefSelector = (props) => {
        const { ticket, isLastTicket } = props;

        if (isLastTicket) {
          return (
            <HelpDeskLine
              ref={lastTicketDisplayedRef}
              ticket={ticket}
              isLastTicket={isLastTicket} // allows to show or remove dividing lines
            />
          );
        } else {
          return <HelpDeskLine ticket={ticket} isLastTicket={isLastTicket} />;
        }
      };

      return data.map((ticket, index) => {
        const isLastTicket = data.length === index + 1;

        return (
          <div key={ticket.id}>
            <TicketLineRefSelector
              ticket={ticket}
              isLastTicket={isLastTicket}
            />
            {isLastTicket && <HelpDeskLoading />}
          </div>
        );
      });
    } else {
      return data.map((ticket, index) => {
        return <HelpDeskLine key={ticket.id} ticket={ticket} />;
      });
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}

const HelpDeskLoading = () => {
  const classes = useStyles();
  const { helpDesk, setHelpDesk } = useContext(HelpDeskContext);
  const { loading } = helpDesk;

  return (
    <div className={classes.loadingContainer}>
      <h6 className={classes.loadingLabel}>
        {loading ? "Loading more..." : "No more tickets"}
      </h6>
    </div>
  );
};
