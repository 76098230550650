import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from "react";
import { tourData } from "utils/tourData";
import { SessionContext } from "./SessionContext";

export const HelpDeskContext = createContext();

export const HelpDeskInfo = (props) => {
  const { session, setSession } = useContext(SessionContext);
  const { userID } = session;
  const lastTicketDisplayedRef = useRef();

  const initialState = {
    loading: false,
    updateInfo: new Date(),
    isAdmin: [1].includes(userID), // shows different options if logged on as support
  };

  const [helpDesk, setHelpDeskNotMemo] = useState(initialState);
  const [modal, setModal] = useState({
    visible: false,
    name: "",
    data: {},
  });

  const setHelpDesk = useCallback((newValue) => {
    setHelpDeskNotMemo(newValue);
  }, []);

  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    messageType: "",
    message: "",
  });

  const memoizedValues = useMemo(
    () => ({
      helpDesk,
      setHelpDesk,
    }),
    [helpDesk, setHelpDesk]
  );

  return (
    <HelpDeskContext.Provider
      value={{
        ...memoizedValues,
        modal,
        setModal,
        showSnackbar,
        setShowSnackbar,
        lastTicketDisplayedRef,
      }}
    >
      {props.children}
    </HelpDeskContext.Provider>
  );
};
export default HelpDeskInfo;
