import { useContext, useEffect, useState } from "react";

// core components
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import ConnectCalendarGoogle from "components/Calendar/ConnectCalendarGoogle";
import ConnectCalendarMicrosoft from "components/Calendar/ConnectCalendarMicrosoft.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomDialog from "components/CustomDialog/CustomDialog";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "components/Snackbar/Snackbar";

// data
import { useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import config from "config";
import { SessionContext } from "contexts/SessionContext";
import { Formik } from "formik";
import { axiosOptions, debounce } from "functions/Common";
import {
  calendarUpdateDefault,
  settings,
  updateAccountSettings,
} from "graphql/gqlSettings";
import { number, object } from "yup";
import packageJson from "../../../package.json";

// errors
import {
  ErrorHandler,
  LogErrorComponent,
  logError,
  useDataForLog,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
// icons
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
// style
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "assets/jss/material-dashboard-react";
import styles from "assets/jss/material-dashboard-react/views/settingsStyle";

const useStyles = makeStyles(styles);

export default function Settings(props) {
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  const appVersion = packageJson.version;

  try {
    console.log("inicia settings");

    const { session, setSession } = useContext(SessionContext);
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingExternalCalendars, setIsLoadingExternalCalendars] =
      useState(false);
    const [modal, setModal] = useState({
      visible: false,
      name: "",
    });
    const [showSnackbar, setShowSnackbar] = useState({
      show: false,
      messageType: "",
      message: "",
    });
    const { accountID, userID } = session;
    const [initialData, setInitialData] = useState({
      hourCost: null,
      hoursToFeedback: null,
    });

    const { loading, data, error, refetch } = useQuery(settings, {
      variables: { accountID: accountID, userID: userID },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

    // const [calendarSignOutMut] = useMutation(calendarSignOut, {
    //   onCompleted: () => {
    //     console.log("hizo el calendar signout");
    //     refetch();
    //   },
    // });

    useEffect(() => {
      if (data !== undefined) {
        // console.log("la data converted es", dataConverted);

        const accountData = data.spSettings.nodes;

        // converts from array to object
        const dataConverted = {};
        accountData.map((item) => {
          if (["true", "false"].includes(item.value)) {
            dataConverted[item.desc] = item.value === "true"; // converts true/false strings to boolean
          } else {
            dataConverted[item.desc] = item.value;
          }
        });

        console.log("dataConverted", dataConverted);
        setInitialData(dataConverted);
      }
    }, [data]);

    // const [deleteCalendarMut] = useMutation(deleteCalendar, {
    //   onCompleted: () => {
    //     refetch();
    //   },
    // });

    const [calendarUpdateDefaultMut] = useMutation(calendarUpdateDefault, {
      onCompleted: () => {
        refetch();
      },
    });

    if (loading) return <ActivityIndicator fullscreen />;

    if (error) {
      return (
        <ErrorHandler
          error={error}
          errorClear={() => setErrorState()}
          retryFunction={() => refetch()}
          data={{
            errorLevel: "fatal",
            generalData: { ...generalData },
            otherData: { message: error.message },
          }}
        />
      );
    }

    if (data && data.spSettings) {
      console.log("-------------en data", data);
      const isOwner = data.accountById.ownerId === userID;
      console.log("isOwner", isOwner);

      // store the userCalendarID in the session or deletes if there's no calendar linked
      if (data.spCalendars) {
        if (data.spCalendars.nodes.length > 0) {
          data.spCalendars.nodes.map((item) => {
            console.log("/*/*/*/*/*/*/*/*/*/*/*");
            item.defaultCalendar === true
              ? sessionStorage.setItem("userCalendarID", item.id)
              : sessionStorage.removeItem("userCalendarID");
          });
        } else {
          sessionStorage.removeItem("userCalendarID");
        }
      } else {
        sessionStorage.removeItem("userCalendarID");
      }

      const AccountSettingsContent = ({ name, index }) => {
        const [updateAccountSettingsMut] = useMutation(updateAccountSettings, {
          refetchQueries: [
            {
              query: settings,
              variables: { accountID: accountID, userID: userID },
            },
          ],
          onCompleted: () => {
            console.log("update completed");
            setShowSnackbar({
              show: true,
              messageType: "success",
              message: "Settings saved",
            });
            setIsLoading(false);
          },
        });

        const handleSubmit = async (values) => {
          // console.log("handlesubmit", values);
          // console.log("hourCost", values.hourCost);
          const newArray = [];
          newArray.push({ id: 1, value: values.hoursToFeedback });
          newArray.push({ id: 2, value: values.hourCost });
          newArray.push({
            id: 7,
            value: values.useObjectivesResults.toString(),
          });
          newArray.push({ id: 8, value: values.useContent.toString() });
          // console.log("final", JSON.stringify(newArray));

          // setIsLoading(true);
          // const { commercialName, defaultMaxPeopleWaiting, defaultSecondsToCash } =
          //   values;

          console.log("data for mutation", {
            accountID: accountID,
            userID: userID,
            data: newArray,
          });

          setInitialData(values);

          updateAccountSettingsMut({
            variables: {
              accountID: accountID,
              userID: userID,
              data: newArray,
            },
          });
        };

        const AccountSettingsMain = () => {
          return (
            <Formik
              initialValues={initialData}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
              validationSchema={object().shape({
                hourCost: number()
                  .typeError("Only number")
                  .required("Required")
                  .positive("Only positive numbers")
                  .integer("No decimals"),

                hoursToFeedback: number()
                  .typeError("Only number")
                  .required("Required")
                  .positive("Only positive numbers")
                  .integer("No decimals")
                  .max(48, "Max is 48 hours"),
              })}
            >
              {(fprops) => (
                <div key={index} className={classes.section}>
                  <h4 className={classes.sectionLabel}>{name}</h4>
                  <div className={classes.sectionSub}>
                    <GridContainer alignItems="center" spacing={spacing}>
                      <GridItem xs={7} sm={6} md={5}>
                        <h5>Hour cost per person</h5>
                      </GridItem>
                      <GridItem xs={3} sm={2} md={1}>
                        <CustomMainInput
                          alignRight
                          error={
                            fprops.touched.hourCost && fprops.errors.hourCost
                          }
                          height="40px"
                          // label="Invoice name"
                          name="hourCost"
                          onBlur={fprops.handleBlur("hourCost")}
                          // onChange={fprops.handleChange("hourCost")}
                          onChange={(e) => {
                            fprops.setFieldValue("hourCost", e.target.value);
                            debounce(() => fprops.handleSubmit());
                          }}
                          rightMargin={false}
                          value={fprops.values.hourCost}
                          variant="input"
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer alignItems="center" spacing={spacing}>
                      <GridItem xs={7} sm={6} md={5}>
                        <h5>Hours to provide feedback after meeting end</h5>
                      </GridItem>
                      <GridItem xs={3} sm={2} md={1}>
                        <CustomMainInput
                          alignRight
                          error={
                            fprops.touched.hoursToFeedback &&
                            fprops.errors.hoursToFeedback
                          }
                          height="40px"
                          name="hoursToFeedback"
                          onBlur={fprops.handleBlur("hoursToFeedback")}
                          // onChange={fprops.handleChange("hoursToFeedback")}
                          onChange={(e) => {
                            fprops.setFieldValue(
                              "hoursToFeedback",
                              e.target.value
                            );
                            debounce(() => fprops.handleSubmit());
                          }}
                          rightMargin={false}
                          value={fprops.values.hoursToFeedback}
                          variant="input"
                        />
                      </GridItem>
                    </GridContainer>

                    {/* use Objectives and results */}
                    <GridContainer alignItems="center" spacing={spacing}>
                      <GridItem xs={7} sm={6} md={5}>
                        <h5>Use objectives and results</h5>
                      </GridItem>
                      <GridItem xs={3} sm={2} md={1}>
                        <CustomSwitch
                          checked={fprops.values.useObjectivesResults}
                          onChange={(e) => {
                            fprops.setFieldValue(
                              "useObjectivesResults",
                              e.target.checked
                            );
                            fprops.handleSubmit();
                          }}
                        />
                      </GridItem>
                    </GridContainer>

                    {/* use contents */}
                    <GridContainer alignItems="center" spacing={spacing}>
                      <GridItem xs={7} sm={6} md={5}>
                        <h5>Use agenda</h5>
                      </GridItem>
                      <GridItem xs={3} sm={2} md={1}>
                        <CustomSwitch
                          checked={fprops.values.useContent}
                          onChange={(e) => {
                            fprops.setFieldValue(
                              "useContent",
                              e.target.checked
                            );
                            fprops.handleSubmit();
                          }}
                        />
                      </GridItem>
                    </GridContainer>

                    {/* <GridContainer alignItems="flex-start">
                      <GridItem xs={4}>
                        <pre>
                          {JSON.stringify(
                            {
                              values: fprops.values,
                            },
                            null,
                            2
                          )}
                        </pre>
                      </GridItem>
                    </GridContainer> */}

                    {/* <GridContainer
                      alignItems="center"
                      // justifyContent="flex-end"
                      spacing={spacing}
                    >
                      <GridItem xs={10} sm={8} md={6}>
                        <div className={classes.buttonContainer}>
                          <Button
                            color="primary"
                            startIcon={<SaveOutlinedIcon />}
                            onClick={fprops.handleSubmit}
                            isLoading={isLoading}
                          >
                            Save account settings
                          </Button>
                        </div>
                      </GridItem>
                    </GridContainer> */}
                  </div>
                </div>
              )}
            </Formik>
          );
        };

        return (
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                data={{
                  errorLevel: "fatal",
                  generalData: { ...generalData },
                  otherData: {},
                }}
              />
            )}
          >
            <AccountSettingsMain />
          </ErrorBoundary>
        );
      };

      const Calendars = ({ name, index }) => {
        const initialCalendar = data.spCalendars.nodes.filter(
          (item) => item.defaultCalendar === true
        );

        // if there's no initial external calendar, place null
        const [defaultCalendar, setDefaultCalendar] = useState(
          initialCalendar.length === 0 ? null : initialCalendar[0].id
        );

        const calendarsDropDown = data.spCalendars.nodes.reduce(
          (filtered, item) => {
            if (item.id !== null) {
              // if (item.defaultCalendar) {
              //   setDefaultCalendar(item.id);
              // }
              filtered.push({
                id: item.id,
                menuDesc: `${item.calendarDesc} / ${item.email}`,
              });
            }
            return filtered;
          },
          []
        );

        console.log("calendar dropdown", calendarsDropDown);
        // const deleteCalendar = (item2, calendarIndex2) => {
        //   // console.log("calendar to delete is", {
        //   //   item: item2.id,
        //   //   index: calendarIndex2,
        //   // });

        //   deleteCalendarMut({
        //     variables: {
        //       userCalendarID: item2.id,
        //     },
        //   });
        //   setDefaultCalendar(item2.id);
        // };
        const calendarsData = data.spCalendars.nodes;

        const calendarSignOut = (props) => {
          const {
            calendarTypeId,
            defaultCalendar,
            id,
            email,
            microsoftAccount,
          } = props;
          console.log("props in claendar signout", props);
          // check if current calendar is default.
          // If other calendars are available,
          // force to choose another default calendar before deleting the current calendar
          console.log("Calendars data in signout", calendarsData);
          if (calendarsData.length > 1 && defaultCalendar) {
            setModal({ visible: true, name: "CantDeleteDefault" });
          } else {
            setIsLoadingExternalCalendars(true);
            if (defaultCalendar === true) {
              console.log("eliminar el session storage");
              // setSession({ ...session, userCalendarID: null });
              sessionStorage.setItem("userCalendarID", null);
            }

            console.log("desconecta el calendario");
            // calendarSignOutMut({
            //   variables: {
            //     userCalendarID: id,
            //   },
            // });

            switch (calendarTypeId) {
              case 1: // google
                const options = axiosOptions({
                  url: `${config.url}/api/google-signout`,
                  method: "post",
                  data: {
                    userCalendarID: id,
                  },
                });

                axios(options)
                  .then(async (response) => {
                    console.log("axios response", response.data);
                    setIsLoadingExternalCalendars(false);
                    refetch();
                  })
                  .catch((error) => {
                    console.log("error is:", error);
                    setIsLoadingExternalCalendars(false);
                    return <ErrorHandler error={error} />;
                  });

                break;
              case 2: {
                // microsoft
                const options = axiosOptions({
                  url: `${config.url}/api/microsoft-signout`,
                  method: "post",
                  data: {
                    email: email,
                    microsoftAccount: microsoftAccount,
                    userCalendarID: id,
                  },
                });

                axios(options)
                  .then(async (response) => {
                    console.log("axios response", response.data);
                    setIsLoading(false);
                    refetch();
                  })
                  .catch((error) => {
                    console.log("error is:", error);
                    setIsLoading(false);
                    return <ErrorHandler error={error} />;
                  });

                break;
              }
              default:
            }
          }
          // return (  );
        };

        const CalendarsMain = () => {
          return (
            <div key={index} className={classes.section}>
              <h4 className={classes.sectionLabel}>{name}</h4>
              <div className={classes.sectionSub}>
                <GridContainer
                  alignItems="center"
                  spacing={spacing}
                  direction="row"
                >
                  <GridItem xs={5} sm={4} md={3}>
                    <h5>Add Google calendar</h5>
                  </GridItem>
                  <GridItem xs={4}>
                    <div className={classes.buttonContainer}>
                      <ConnectCalendarGoogle afterSignIn={() => refetch()} />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  alignItems="center"
                  spacing={spacing}
                  direction="row"
                >
                  <GridItem xs={5} sm={4} md={3}>
                    <h5>Add Microsoft calendar</h5>
                  </GridItem>
                  <GridItem xs={4}>
                    <div className={classes.buttonContainer}>
                      <ConnectCalendarMicrosoft afterSignIn={() => refetch()} />
                    </div>
                  </GridItem>
                </GridContainer>

                {calendarsData.length > 1 && (
                  <GridContainer
                    alignItems="center"
                    spacing={spacing}
                    direction="row"
                  >
                    <GridItem xs={5} sm={4} md={3}>
                      <h5>Default Calendar</h5>
                    </GridItem>
                    <GridItem xs={4}>
                      <CustomMainInput
                        // error={
                        //   fprops.touched.meetingGroupID &&
                        //   fprops.errors.meetingGroupID
                        // }
                        dropDownList={calendarsDropDown}
                        onChangeDropDownList={(item) =>
                          console.log("se seleccionó", item)
                        }
                        dropDownFunction={(item) => {
                          console.log("new calendar default is", item.id);
                          // setSession({ ...session, userCalendarID: item.id });
                          sessionStorage.setItem("userCalendarID", item.id);
                          calendarUpdateDefaultMut({
                            variables: {
                              userCalendarID: item.id,
                              userID: userID,
                            },
                          });
                        }}
                        height="40px"
                        // label="Meeting group"
                        name="defaultCalendar"
                        onBlur={() => {
                          console.log("on blue");
                        }}
                        // placeholder="Add group"
                        value={defaultCalendar}
                        variant="dropdown"
                      />
                    </GridItem>
                  </GridContainer>
                )}

                {calendarsData.length > 0 &&
                  calendarsData.map((item, calendarIndex) => {
                    return (
                      <GridContainer
                        key={calendarIndex}
                        alignItems="center"
                        spacing={spacing}
                        direction="row"
                      >
                        <GridItem xs={6} sm={5} md={4}>
                          <h5>
                            {`${item.calendarDesc} ${
                              item.id === null
                                ? " not connected"
                                : " connected to "
                            } ${item.email || ""}`}
                          </h5>
                        </GridItem>
                        <GridItem xs={3}>
                          <div className={classes.buttonContainer}>
                            {{
                              Google: (
                                <ConnectCalendarGoogle
                                  signedIn={item.email !== null}
                                  signOut={() => {
                                    // console.log(item);
                                    calendarSignOut(item);
                                  }}
                                  loading={isLoadingExternalCalendars}
                                />
                              ),
                              Microsoft: (
                                <ConnectCalendarMicrosoft
                                  signedIn={item.email !== null}
                                  signOut={() => {
                                    console.log(item);
                                    calendarSignOut(item);
                                  }}
                                  loading={isLoadingExternalCalendars}
                                />
                              ),
                            }[item.calendarDesc] || null}
                          </div>
                        </GridItem>
                        {/* <GridItem xs={2}>
                      <Button
                        color="primary"
                        justIcon
                        onClick={() => deleteCalendar(item, calendarIndex)}
                      >
                        <DeleteOutlineIcon />
  
                      </Button>
                    </GridItem> */}
                      </GridContainer>
                    );
                  })}
              </div>
            </div>
          );
        };

        return (
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                size="large"
                data={{
                  errorLevel: "critical",
                  generalData: { ...generalData },
                  otherData: {},
                }}
              />
            )}
          >
            <CalendarsMain />
          </ErrorBoundary>
        );
      };

      const ModalToShow = () => {
        console.log("tries to show modal", modal.name);

        switch (modal.name) {
          case "CantDeleteDefault": {
            return (
              <CustomDialog
                iconPreset="error"
                center
                visibleStatus={modal}
                setVisibleStatus={setModal}
                title="Can´t delete default calendar"
                mainText="Change the current default calendar and try again"
                buttonCloseLabel="OK"
              />
            );
          }
          default:
            return null;
        }
      };

      const spacing = 3;

      const sections = [
        "Account Settings",
        "User settings",
        "External calendars",
      ];
      console.log("rerenders");

      return (
        <>
          <Card>
            {/* <CardHeader color="primary"> */}
            {/* <h4 className={classes.cardTitleWhite}>Settings</h4> */}
            {/* </CardHeader> */}
            <CardBody>
              <div className={classes.mainContainer}>
                <div>
                  {sections.map((item, index) => {
                    // hide the account settings if not the owner of the account in use
                    if (!isOwner && item === "Account Settings") {
                      return null;
                    } else {
                      return (
                        <div key={index}>
                          {{
                            0: (
                              <ErrorBoundary
                                FallbackComponent={(error) => (
                                  <LogErrorComponent
                                    error={error}
                                    size="large"
                                    data={{
                                      errorLevel: "critical",
                                      generalData: { ...generalData },
                                      otherData: {},
                                    }}
                                  />
                                )}
                              >
                                <AccountSettingsContent
                                  name={item}
                                  index={index}
                                />
                              </ErrorBoundary>
                            ),
                            1: null,

                            2: (
                              <ErrorBoundary
                                FallbackComponent={(error) => (
                                  <LogErrorComponent
                                    error={error}
                                    size="large"
                                    data={{
                                      errorLevel: "critical",
                                      generalData: { ...generalData },
                                      otherData: {},
                                    }}
                                  />
                                )}
                              >
                                <Calendars name={item} index={index} />
                              </ErrorBoundary>
                            ),
                          }[index] || null}
                        </div>
                      );
                    }
                  })}
                </div>
                <h6 style={{ textAlign: "right", color: grayColor[4] }}>
                  {appVersion}
                </h6>
              </div>
            </CardBody>
          </Card>
          <ModalToShow />

          {errorState && (
            <ErrorHandler
              error={errorState.error}
              errorExplanation={errorState.errorExplanation} // only for dialog, not error
              errorClear={() => setErrorState()}
              data={{
                errorLevel: errorState.data.errorLevel,
                generalData: { ...generalData },
                otherData: { ...errorState.data.otherData },
              }}
            />
          )}

          <Snackbar
            messageType={showSnackbar.messageType}
            message={showSnackbar.message}
            open={showSnackbar.show}
            close={() => setShowSnackbar({ ...showSnackbar, show: false })}
          />
        </>
      );
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
