import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
// import _ from "lodash";
import isEqual from "lodash/isEqual";

// data

import { updateMeeting } from "graphql/gqlMeeting";

import axios from "axios";
import config from "config";
import differenceInDays from "date-fns/differenceInDays";
import differenceInMinutes from "date-fns/differenceInMinutes";
import compareAsc from "date-fns/compareAsc";
import debounce from "lodash/debounce";
import { Formik, FieldArray, useFormikContext } from "formik";
import { array, object, string, date, number, ref } from "yup";

import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import { tourData } from "utils/tourData";
// @material-ui/core components

import Checkbox from "@material-ui/core/Checkbox";
import CustomDialog from "components/CustomDialog/CustomDialog";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import { Avatar, List } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import { RadioButtonsGroup } from "components/Radio";

// icons

// style
import classNames from "classnames";
import format from "date-fns/format";
import differenceInSeconds from "date-fns/differenceInSeconds";
import add from "date-fns/add";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import roundToNearestMinutes from "date-fns/roundToNearestMinutes";
import parseISO from "date-fns/parseISO";

import {
  addMonthsToDate,
  addMinutesToDate,
  formatDateTime,
  formatTime,
  timeDifference,
  compareDatesWithoutTime,
  convertToTimezone,
  convertLocal,
  convertLocalISO,
  currentUTCTimezoneOffset,
  addDaysToDate,
  convertLocalToUTC,
  axiosOptions,
  debounce as debounceCustom,
  convertAMPMTo24,
} from "functions/Common";
// error management
import {
  logError,
  ErrorHandler,
  // ErrorBoundary,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// styles
import { makeStyles } from "@material-ui/core/styles";
import {
  grayColor,
  defaultFont,
  primaryColor,
} from "assets/jss/material-dashboard-react";
import TodoDetails from "views/Todo/TodoDetails";
import { cs } from "date-fns/locale";

const styles = {
  cardFooter: {
    justifyContent: "flex-end",
  },
  cardBody: {
    paddingTop: "40px",
  },
  allDayContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  dropDownCustomContainer: {
    minWidth: "250px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    pointerEvents: "none",
    margin: "5px 0px 5px 0px",
  },
  dropDownTextsContainer: {
    display: "flex",
    flexDirection: "column",
    // marginBottom: "5px",
    margin: "0px 0px 0px 15px",
  },
  dropDownMainText: {
    fontSize: "14px",
    margin: "0px",
    lineHeight: "15px",
  },
  dropDownSubText: {
    color: grayColor[3],
    fontSize: "12px",
    margin: "0px",
  },
  maxOccurrencesInput: {
    ...defaultFont,
    "&::placeholder": {
      color: "red",
    },
    height: "26px",
    fontWeight: 400,
    border: "0px",
    // backgroundColor: "rgba(0,0,0,0)",
    backgroundColor: primaryColor[9],
    borderRadius: "5px",
    width: "50px",
    textAlign: "center",
    paddingRight: "5px",
    margin: "2px 3px 2px 0px",

    color: grayColor[1],
  },
  maxOccurrencesInputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",

    // padding: "0px 10px",

    borderRadius: "5px",
    border: `1px solid ${grayColor[5]}`,
    padding: "0px 8px 0px 4px",
    // width: "110px",
  },
  maxOccurrencesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  label: {
    ...defaultFont,
    fontSize: "12px",
    paddingLeft: "10px",
    color: grayColor[0],
    "&$red": {
      color: "red",
    },
    marginTop: ".2rem",
  },
  mainContainer: {
    // height: "calc(100% - 18px)", // just added, substracts the height of the description box
    flexWrap: "wrap",
    backgroundColor: grayColor[8],
    display: "flex",
    // width: "40px",
    flex: 1,
    // flexDirection: "column",
    // maxWidth: "400px",
    alignItems: "stretch",
    padding: "5px 10px",
    margin: "5px 10px 0px 0px",
    // margin: ".2rem",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    // backgroundColor: "white",
    borderColor: grayColor[5],
  },
  red: {},
  variationLabelMain: { margin: "0px 10px 0px 0px" },
  variationLabelGreen: { fontWeight: 600, color: "green" },
  variationLabelRed: { fontWeight: 600, color: "red" },
  variationLabelGray: { fontWeight: 600 },

  weekDaysBase: {
    alignItems: "center",
    backgroundColor: grayColor[7],
    borderRadius: "50px",
    color: grayColor[1],
    display: "flex",
    justifyContent: "center",
    height: "38px",
    margin: "0px 4px",
    width: "38px",
  },
  weekDaysSelected: {
    color: "white",
    backgroundColor: primaryColor[4],
  },
};

const useStyles = makeStyles(styles);

const MeetingDetails = forwardRef((props, ref) => {
  console.log(
    "%cStarting meeting details",
    "background-color: yellow; color: red"
  );

  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  const attendeeInputRef = useRef();

  const formRef = useRef();
  // this ref is used to compare against the previous values stored in formik which not
  // necesarily are the same as the initial values.
  const previousDetailsInfoRef = useRef();
  // data that needs to be stored in between renders that is affected by blinking in formik
  const previousDataRef = useRef();
  // data that needs to be stored in between renders that are not affected by blinking in formik when refreshing (e.g. dropdownlist)
  const otherDataRef = useRef();
  // console.log("----------los props en details son", props);
  // this ref is used to store the calculated temp meeting id so it can be fetched again if either date of time is changed

  let occurrenceRepeatType = 2;

  // 0- updating main event
  // 1-updates the current event only as exception
  // 2-updates all the series
  // 3-updates the current and following occurrences

  let sendInvitations = false;

  const {
    sendInfoToHeader,
    contentChangedValue = false,
    contentChangedFunction,
    endDateOccurrence,
    formIsValid,
    // hideActivityIndicator,
    meetingID,
    parentEventID,

    sendInfoToParent,
    startDateOccurrence,
    tempMeetingID,
    updateMeetingCost,
    updateMeetingID,
    // submitHandlerParent,
  } = props;
  const { meeting, setMeeting, savePendingRef } = useContext(MeetingContext);
  const { session, setSession } = useContext(SessionContext);

  // console.log("los props en meeting details", props);

  const { accountID, timeMode, userID, firstUse } = session;

  const classes = useStyles();

  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    messageType: "",
    message: "",
  });

  // const [updateInitialData, setUpdateInitialData] = useState(false);

  const spacing = 3;

  const FormikComponent = (props) => {
    const {
      email: emailProp,
      userID: userIDProp,
      statusID,
      isOrganizer,
      withDurations,
      withAgenda,
    } = props;
    // console.log(
    //   "%cStarts formik component with previous data",
    //   "background-color: lightBlue; color: black",
    //   previousDataRef.current
    // );
    // const [avoidAutosave, setAvoidAutosave] = useState(false);

    const [modal, setModal] = useState({
      visible: false,
      name: "",
      data: {},
    });
    const [isLoading, setIsLoading] = useState(true);
    const [detailsInfo, setDetailsInfo] = useState(null);
    const [contacts, setContacts] = useState({
      inputForUnfiltered: "",
      filtered: [],
      unfiltered: [],
    });
    const [isLoadingContacts, setIsLoadingContacts] = useState(false);

    useEffect(() => {
      console.log(
        "%cRuns initial use effect with savePendingRef:",
        "background-color: lightBlue; color: blue",
        savePendingRef.current
      );

      if (firstUse) {
        const tourDummyData = tourData("details");
        console.log(
          "%cLoads tour data in details info",
          "background-color: red; color: white"
        );

        setDetailsInfo(tourDummyData);
        setIsLoading(false);
        return;
      }
      // avoid refreshing the data if the data was modified but not saved yet,
      // particularly when change repeating meetings
      // and changing tab without saving how this should be applied (current, all series, following evnets)
      if (savePendingRef.current === false) {
        // if (otherDataRef.current === undefined) {
        const options = axiosOptions({
          url: `${config.url}/api/meeting-info`,
          method: "get",
          params: {
            meetingID: meetingID || parentEventID,
            tempMeetingID: tempMeetingID || null,
            origin: "details",
          },
        });

        axios(options)
          .then(async (response) => {
            const { details } = response.data.info;
            const data = {
              title: details.title,
              startDate: startDateOccurrence || details.start_date,
              startDateFinal: details.start_date_final,
              endDate: endDateOccurrence || details.end_date,
              endDateFinal: details.end_date_final,
              allDay: details.all_day,
              location: details.location,
              meetingGroupId: details.meeting_group_id,
              attendees: details.attendees,
              meetingTypeID: details.meeting_type_id,
              meetingTypeList: details.meeting_type_list,
              recurrenceTypeID: details.recurrence_type_id,
              separationCount: details.separation_count,
              maxOccurrences: details.max_occurences,
              weekMonth: details.week_month,
              monthYear: details.month_year,
              dayWeek: details.day_week,
              recurrenceStartDate: details.recurrence_start_date,
              recurrenceEndDate: details.recurrence_end_date,
              dayMonth: details.day_month,
            };

            otherDataRef.current = {
              createdBy: details.created_by,
              meetingTypeList: details.meeting_type_list,
              userGroupsList: details.user_groups_list,
              recurrenceTypeList: details.recurrence_type_list,
            };

            console.log(
              "%cLoads data in details info in useEffect",
              "background-color: brown; color: yellow",
              data
            );
            setDetailsInfo(data);
            setIsLoading(false);
          })
          .catch((error) => {
            setErrorState({
              error: error,
              errorExplanation: "Couldn't retrieve meeting contents",
              data: {
                errorLevel: "fatal",
                otherData: { axiosOptions: options },
              },
            });
          });
        // }
      }
    }, []);

    // if (detailsInfo !== null) {
    // console.log("details info", detailsInfo);

    const {
      title,
      startDate,
      startDateFinal,
      endDate,
      endDateFinal,
      allDay,
      location,
      attendees,
      meetingGroupId,
      meetingTypeID,
      meetingTypeList,
      recurrenceTypeID,
      separationCount,
      maxOccurrences,
      weekMonth,
      monthYear,
      dayWeek,
      recurrenceStartDate,
      recurrenceEndDate,
      dayMonth,
      recurrenceTypeList,
    } = detailsInfo || {};
    // console.log(
    //   "%cDetails info",
    //   "background-color: red; color: white",
    //   detailsInfo
    // );

    const recurrenceTypeNames = [
      { id: 1, menuDesc: "day" },
      { id: 2, menuDesc: "week" },
      { id: 3, menuDesc: "month" },
      { id: 4, menuDesc: "year" },
    ];

    const weekDays = [
      { id: 1, desc: "Mon", long: "Monday" },
      { id: 2, desc: "Tue", long: "Tuesday" },
      { id: 3, desc: "Wed", long: "Wednesday" },
      { id: 4, desc: "Thu", long: "Thursday" },
      { id: 5, desc: "Fri", long: "Friday" },
      { id: 6, desc: "Sat", long: "Saturday" },
      { id: 7, desc: "Sun", long: "Sunday" },
    ];

    // calculate, first, second, etc day of month
    function nthDate(date) {
      let dayname = date.toLocaleString("en", { weekday: "long" });
      // https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
      let nth = Math.ceil(date.getDate() / 7);
      nth += ["st", "nd", "rd"][((((nth + 90) % 100) - 10) % 10) - 1] || "th";
      return `${nth} ${dayname}`;
    }

    const RecurrenceString = (props) => {
      const { formikBag, setModal } = props;
      const {
        recurrenceTypeID,
        separationCount,
        dayWeek,
        dayMonth,
        startDate,
        recurrenceEndDate,
        maxOccurrences,
      } = formikBag.values;

      const frequency = () => {
        const frequencyLabel = recurrenceTypeNames.filter(
          (item) => item.id === recurrenceTypeID
        )[0]?.menuDesc;
        if (separationCount === 1) {
          return frequencyLabel;
        } else {
          return ` ${separationCount} ${frequencyLabel}s`;
        }
      };

      const addAnd = (string) => {
        const lastIndex = string.lastIndexOf(",");
        // console.log("lastIndex", lastIndex);
        // console.log("string", string);
        const arr = string.split("");
        // console.log(arr);

        arr[lastIndex] = " and ";

        return arr.join("");
      };

      const on = () => {
        switch (recurrenceTypeID) {
          case 2: {
            // weekly
            const daysList = dayWeek.map((dayID) => {
              console.log(
                "%cdayWeek",
                "background-color: red; color: white",
                weekDays[dayID - 1]
              );
              // returns the weekday long description
              return ` ${weekDays[dayID - 1].long}`;
            });
            return `on ${addAnd(daysList.toString())} `;
          }

          case 3: // monthly
            if (dayMonth !== 0) {
              return `on day ${dayMonth}`;
            } else {
              return `on the ${nthDate(
                convertToTimezone(startDate) //if function is not used an only supplied a new Date(), will this timestamp minus the time offset
              )}`;
            }

          case 4: // yearly
            const day = parseInt(convertToTimezone(startDate).getDate());
            let monthName = convertToTimezone(startDate).toLocaleString("en", {
              month: "long",
            });

            if (dayMonth !== 0) {
              return `on day ${monthName} ${day}`;
            } else {
              return `on the ${nthDate(
                convertToTimezone(startDate) //if function is not used an only supplied a new Date(), will this timestamp minus the time offset
              )} of ${monthName}`;
            }

          default:
            return "";
        }
      };

      const until = () => {
        if (recurrenceEndDate || null === null) {
          if (maxOccurrences === 0) {
            return "without end date";
          } else {
            return `until ${maxOccurrences} occurrences`;
          }
        } else {
          return `until ${format(
            addDaysToDate(recurrenceEndDate, -1),
            "dd-MMM-yy"
          )}`;
        }
      };

      return recurrenceTypeID || 0 === 0 ? (
        ""
      ) : (
        <h6>{`Every ${frequency()} ${on()} ${until()}`}</h6>
      );
    };

    const RecurrenceDialog = (props) => {
      const [validationErrorOccurrence, setValidationErrorOccurrence] =
        useState("");
      const [validationErrorDayWeek, setValidationErrorDayWeek] = useState("");

      console.log(
        "%cValues en recurrence dialog",
        "background-color: yellow; color: red",
        props
      );

      const { formikBag, setModal, data } = props;
      // set recurring values in case null

      const recurrenceTypeIDDefault = () => {
        switch (formikBag.values.recurrenceTypeID) {
          case 0:
            return 1;

          case 5:
            if (data.currentRecurrenceTypeID === 0) {
              return 1;
            } else {
              return data.currentRecurrenceTypeID;
            }

          default:
            formikBag.values.recurrenceTypeID;
        }
      };

      const formikBagProcessed = {
        ...formikBag.values,

        recurrenceTypeID: recurrenceTypeIDDefault(),

        separationCount: formikBag.values.separationCount || 1, // interval
        maxOccurrences: formikBag.values.maxOccurrences || 0, // numberOfOccurrences
        weekMonth: formikBag.values.weekMonth, // index
        monthYear: formikBag.values.monthYear || 0, // month
        dayWeek: formikBag.values.dayWeek || 0, // daysOfWeek
        recurrenceStartDate:
          formikBag.values.recurrenceStartDate || formikBag.values.startDate,
        recurrenceEndDate:
          formikBag.values.recurrenceEndDate === null
            ? null
            : addDaysToDate(formikBag.values.recurrenceEndDate, 1), // end_date. No end date as default

        recurrenceEndDate: formikBag.values.recurrenceEndDate, // end_date. No end date as default

        dayMonth: formikBag.values.dayMonth || 0, // daysOfMonth
      };

      // console.log("props", props);

      console.log(
        "%cformikBagProcessed",
        "background-color: red; color: white",
        formikBagProcessed
      );

      const [dialogTempValues, setDialogTempValues] =
        useState(formikBagProcessed);

      const endRecurrenceInitialValue = () => {
        if (dialogTempValues.recurrenceEndDate !== null) {
          return 2;
        } else {
          if (dialogTempValues.maxOccurrences === 0) {
            return 1;
          } else {
            return 3;
          }
        }
      };

      //after selecting the radio group, update this value in order to dim or not some of the values
      const [endRecurrenceSelection, setEndRecurrenceSelection] = useState(
        endRecurrenceInitialValue
      );

      function createArray(N) {
        let newArr = [];
        for (let i = 1; i <= N; i++) {
          newArr.push({
            id: i,
            menuDesc: i,
          });
        }
        return newArr;
      }

      const monthSelectorList = () => {
        const day = parseInt(
          convertToTimezone(dialogTempValues.startDate).getDate()
        );

        return [
          { id: 1, menuDesc: `Monthly on day ${day}` },
          {
            id: 2,
            menuDesc: `Monthly on the ${nthDate(
              convertToTimezone(dialogTempValues.startDate) //if function is not used an only supplied a new Date(), will this timestamp minus the time offset
            )}`,
          },
        ];
      };

      const yearSelectorList = () => {
        const day = parseInt(
          convertToTimezone(dialogTempValues.startDate).getDate()
        );
        let monthName = convertToTimezone(
          dialogTempValues.startDate
        ).toLocaleString("en", { month: "long" });

        return [
          { id: 1, menuDesc: `Yearly on ${monthName} ${day}` },
          {
            id: 2,
            menuDesc: `Yearly on the ${nthDate(
              convertToTimezone(dialogTempValues.startDate) //if function is not used an only supplied a new Date(), will this timestamp minus the time offset
            )} of ${monthName}`,
          },
        ];
      };

      const RecurrenceEndDate = () => {
        return (
          <CustomMainInput
            disabled={endRecurrenceSelection !== 2}
            calendarLockDate={dialogTempValues.recurrenceStartDate}
            height="40px"
            width="110px"
            // label="Until"
            // name="startDate"
            // onBlur={fprops.handleBlur("startDate")}
            onChange={(date) => {
              console.log(
                "new recurrence end date",
                convertLocalToUTC(
                  format(addDaysToDate(date, 1), "yyyy-MM-dd'T00:00:00'")
                )
                // convertLocalToUTC("2021-11-11T00:00:00")
              );

              setDialogTempValues({
                ...dialogTempValues,
                recurrenceEndDate: convertLocalToUTC(
                  format(addDaysToDate(date, 1), "yyyy-MM-dd'T00:00:00'")
                ),
              });
            }}
            placeholder={format(
              addMonthsToDate(
                convertToTimezone(dialogTempValues.recurrenceStartDate),
                3
              ),
              "dd/MM/yyyy"
            )}
            // value={"2023-09-15"}
            value={
              dialogTempValues.recurrenceEndDate === null
                ? null
                : format(
                    addDaysToDate(dialogTempValues.recurrenceEndDate, -1),
                    "yyyy-MM-dd'T00:00:00'"
                  )
            } // defaults to null because first render is undefined, hence generates an error
            variant="inputDate"
          />
        );
      };

      const RecurrencesOcurrences = () => {
        const validateOccurrencesInput = async (value) => {
          const obj = {
            occurrenceInput: value,
          };

          const yupObj = object().shape({
            occurrenceInput: number()
              .nullable()
              .typeError("Please enter a valid number")
              .required("Number is required")
              .positive("Number must be positive"),
          });
          return yupObj
            .validate(obj)
            .then(function (value) {
              // if validation is successful
              setValidationErrorOccurrence("");
              return true;
            })
            .catch(function (err) {
              console.log("error de validacion", err.message);
              setValidationErrorOccurrence(err.message);
              return false;
            });
        };

        return (
          <div className={classes.maxOccurrencesContainer}>
            <div
              className={classes.maxOccurrencesInputContainer}
              style={{
                backgroundColor:
                  endRecurrenceSelection === 3 ? "white" : grayColor[8],
              }}
            >
              <input
                // autoFocus
                disabled={endRecurrenceSelection !== 3}
                type="number"
                // disabled={disabled}
                min={0}
                onChange={async (e) => {
                  console.log("el valro es", e.target.value < 1);
                  if ((e.target.value || 1) < 1) {
                    console.log("value cant be null");
                  } else {
                    setDialogTempValues({
                      ...dialogTempValues,
                      maxOccurrences: e.target.value,
                    });
                    await validateOccurrencesInput(e.target.value);
                  }

                  // const valid = await validateOccurrencesInput(e.target.value);

                  // if (valid === true) {
                  //   console.log("is valid with value", e.target.value);
                  //   setDialogTempValues({
                  //     ...dialogTempValues,
                  //     maxOccurrences: e.target.value,
                  //   });
                  // }
                }}
                onBlur={async (e) => {
                  console.log(
                    "%cOnBlurInput",
                    "background-color: red; color: white",
                    e.target.value
                  );
                  // await validateOccurrencesInput(e.target.value);

                  // dialogTempValues.maxOccurrences === null &&
                  //   setDialogTempValues({
                  //     ...dialogTempValues,
                  //     maxOccurrences: 1,
                  //   });
                }}
                // onChange={(e) => console.log(item)}
                className={classes.maxOccurrencesInput}
                value={dialogTempValues.maxOccurrences}
              />
              <h6
                style={{
                  margin: "0px",
                  // backgroundColor: "orange",
                  color:
                    endRecurrenceSelection === 3 ? grayColor[1] : grayColor[5],
                }}
              >
                {dialogTempValues.maxOccurrences === 1
                  ? "occurence"
                  : "occurences"}
              </h6>
            </div>
            {endRecurrenceSelection === 3 && (
              <h6 style={{ color: "red" }}>{validationErrorOccurrence}</h6>
            )}
          </div>
        );
      };

      const recurrenceEndOptions = [
        {
          value: 1,
          label: <h6 style={{ color: grayColor[1], width: "85px" }}>No end</h6>,
        },
        {
          value: 2,
          label: <h6 style={{ color: grayColor[1], width: "85px" }}>On</h6>,
          control: <RecurrenceEndDate />,
        },
        {
          value: 3,
          label: <h6 style={{ color: grayColor[1], width: "85px" }}>After</h6>,
          control: <RecurrencesOcurrences />,
        },
      ];

      const validateDayWeek = async (value) => {
        const obj = {
          dayWeek: value,
        };

        const yupObj = object().shape({
          dayWeek: array().of(
            number().test("containsNonZero", "Select at least one day", () => {
              console.log(
                "%cValue in validateDayWeek",
                "background-color: red; color: white",
                value
              );
              // if the frequency is weekly, must not contain a zero value
              if (dialogTempValues.recurrenceTypeID === 2) {
                return value && value.some((num) => num !== 0);
              } else {
                return true;
              }
            })
          ),
        });
        return yupObj
          .validate(obj)
          .then(function (value) {
            // if validation is successful
            setValidationErrorDayWeek("");
            return true;
          })
          .catch(function (err) {
            console.log("error de validacion", err.message);
            setValidationErrorDayWeek(err.message);
            return false;
          });
      };

      return (
        <GridContainer
          spacing={2}
          style={{
            alignItems: "baseline",
          }}
        >
          <GridItem xs={4}>
            <h6>Repeat every </h6>
          </GridItem>
          <GridItem xs={8} row>
            <CustomMainInput
              alignRight
              dropDownList={createArray(20)}
              dropDownFunction={(item) => {
                setDialogTempValues({
                  ...dialogTempValues,
                  separationCount: item.id,
                });
              }}
              height="40px"
              width="60px"
              value={dialogTempValues.separationCount}
              variant="dropdown"
            />

            <CustomMainInput
              alignRight
              dropDownList={recurrenceTypeNames}
              dropDownFunction={(item) => {
                // reset value to defaults when changing frequency
                const changingValues = {
                  recurrenceTypeID: item.id,

                  // dayWeek: [2].includes(item.id)
                  //   ? dialogTempValues.dayWeek
                  //   : [0],
                  dayMonth: [3, 4].includes(item.id)
                    ? convertToTimezone(dialogTempValues.startDate).getDate()
                    : 0,
                  // weekMonth: [3].includes(item.id)
                  //   ? dialogTempValues.weekMonth
                  //   : null,

                  monthYear: [4].includes(item.id)
                    ? convertToTimezone(dialogTempValues.startDate).getMonth() +
                      1
                    : 0,
                };

                setDialogTempValues({
                  ...dialogTempValues,
                  ...changingValues,
                });
              }}
              height="40px"
              width="90px"
              value={dialogTempValues.recurrenceTypeID}
              variant="dropdown"
            />
          </GridItem>
          {dialogTempValues.recurrenceTypeID === 2 && (
            <GridItem xs={12} row>
              {weekDays.map((item) => (
                <h6
                  key={item.id}
                  className={`${classes.weekDaysBase} ${
                    dialogTempValues.dayWeek?.includes(item.id)
                      ? classes.weekDaysSelected
                      : ""
                  }`}
                  onClick={() => {
                    console.log("item clicked", item);
                    // remove any previous error
                    setValidationErrorDayWeek("");
                    // removes the zero value in case it's available
                    const newArray = dialogTempValues.dayWeek?.filter(
                      (arrayItem) => arrayItem !== 0
                    );

                    //if item already in array, removes it, otherwise adds

                    if (dialogTempValues.dayWeek?.includes(item.id)) {
                      // if item is already in array
                      const newArray2 = newArray.filter(
                        (arrayItem) => arrayItem !== item.id
                      );
                      console.log("includes", newArray);

                      setDialogTempValues({
                        ...dialogTempValues,
                        dayWeek: newArray2,
                      });
                    } else {
                      // adds item
                      setDialogTempValues({
                        ...dialogTempValues,
                        dayWeek: [...newArray, item.id],
                      });
                    }
                  }}
                >
                  {item.desc}
                </h6>
              ))}
            </GridItem>
          )}
          {dialogTempValues.recurrenceTypeID === 2 && (
            <GridItem xs={12}>
              <h6 style={{ color: "red", margin: "0px" }}>
                {validationErrorDayWeek}
              </h6>
            </GridItem>
          )}
          {/* --------------------mode ------------------------- */}
          {dialogTempValues.recurrenceTypeID === 3 && (
            <>
              <GridItem xs={4}>
                <h6>Mode </h6>
              </GridItem>

              <GridItem xs={8} row>
                <CustomMainInput
                  dropDownList={monthSelectorList()}
                  dropDownFunction={(item) => {
                    console.log("item", item);
                    if (item.id === 1) {
                      // on specific day of month
                      const changingValues = {
                        dayMonth: convertToTimezone(
                          dialogTempValues.startDate
                        ).getDate(),
                        // dayWeek: null,
                        // weekMonth: null,
                      };

                      console.log("changing values", changingValues);

                      setDialogTempValues({
                        ...dialogTempValues,
                        ...changingValues,
                      });
                    } else {
                      // on ordinal position within month. e.g. third Friday
                      const changingValues = {
                        dayMonth: 0,
                        dayWeek: [
                          convertToTimezone(
                            dialogTempValues.startDate
                          ).getDay(),
                        ],
                        weekMonth: Math.ceil(
                          convertToTimezone(
                            dialogTempValues.startDate
                          ).getDate() / 7
                        ),
                      };

                      setDialogTempValues({
                        ...dialogTempValues,
                        ...changingValues,
                      });
                    }
                  }}
                  height="40px"
                  // width="200px"
                  value={dialogTempValues.dayMonth !== 0 ? 1 : 2}
                  variant="dropdown"
                />
              </GridItem>
            </>
          )}

          {dialogTempValues.recurrenceTypeID === 4 && (
            <>
              <GridItem xs={4}>
                <h6>Mode </h6>
              </GridItem>

              <GridItem xs={8}>
                <CustomMainInput
                  dropDownList={yearSelectorList()}
                  dropDownFunction={(item) => {
                    console.log("item", item);
                    if (item.id === 1) {
                      // on specific day of month
                      const changingValues = {
                        dayMonth: convertToTimezone(
                          dialogTempValues.startDate
                        ).getDate(),
                        dayWeek: [0],
                        weekMonth: 0,
                      };

                      setDialogTempValues({
                        ...dialogTempValues,
                        ...changingValues,
                      });
                    } else {
                      // on ordinal position within month. e.g. third Friday
                      const changingValues = {
                        dayMonth: 0,
                        dayWeek: [
                          convertToTimezone(
                            dialogTempValues.startDate
                          ).getDay(),
                        ],
                        weekMonth: Math.ceil(
                          convertToTimezone(
                            dialogTempValues.startDate
                          ).getDate() / 7
                        ),
                      };
                      console.log(
                        "%cThe changing values are",
                        "background-color: red; color: white",
                        changingValues
                      );

                      setDialogTempValues({
                        ...dialogTempValues,
                        ...changingValues,
                      });
                    }
                  }}
                  height="40px"
                  width="300px"
                  value={dialogTempValues.dayMonth !== 0 ? 1 : 2}
                  variant="dropdown"
                />
              </GridItem>
            </>
          )}
          {/* -------------------------------------------------------- from  ---------------------------------- */}
          <GridItem xs={4}>
            <h6>From</h6>
          </GridItem>

          <GridItem xs={8} row>
            <CustomMainInput
              height="40px"
              onChange={(date) => {
                // console.log("e", e);
                setDialogTempValues({
                  ...dialogTempValues,
                  recurrenceStartDate: format(date, "yyyy-MM-dd"),
                });
              }}
              // placeholder={fprops.values.allDay ? "Start date" : "Date"}
              value={dialogTempValues.recurrenceStartDate || null} // defaults to null because first render is undefined, hence generates an error
              variant="inputDate"
            />
          </GridItem>

          <GridItem xs={4} row alignItems="flexStart">
            <h6>Until</h6>
          </GridItem>
          <GridItem xs={8} row alignItems="flexStart">
            <RadioButtonsGroup
              items={recurrenceEndOptions}
              sendInfoToParent={(value) => {
                setEndRecurrenceSelection(value);

                switch (value) {
                  case 1:
                    setDialogTempValues({
                      ...dialogTempValues,
                      recurrenceEndDate: null,
                      maxOccurrences: 0,
                    });

                    break;

                  case 2:
                    setDialogTempValues({
                      ...dialogTempValues,
                      recurrenceEndDate: format(
                        addMonthsToDate(
                          convertToTimezone(
                            addDaysToDate(
                              dialogTempValues.recurrenceStartDate,
                              1
                            )
                          ),
                          3
                        ),
                        "yyyy-MM-dd"
                      ),
                      maxOccurrences: 0,
                    });

                    break;
                  case 3:
                    setDialogTempValues({
                      ...dialogTempValues,
                      recurrenceEndDate: null,
                      maxOccurrences: formikBag.values.maxOccurrences, //dialogTempValues.maxOccurrences,
                    });
                    break;

                  default:
                }
              }}
              initialValue={endRecurrenceInitialValue()}
            />
          </GridItem>

          <GridItem xs={12} row justifyContent="center">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "10px",
                // backgroundColor: "yellow",
                width: "160px",
                justifyContent: "space-around",
              }}
            >
              <Button
                onClick={() => {
                  // console.log("recurrence", formikBag.values.recurrenceTypeID);
                  formikBag.setFieldValue(
                    "recurrenceTypeID",
                    formikBag.initialValues.recurrenceTypeID
                  );
                  setModal({ visible: false, name: "" });
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  const isValid = await validateDayWeek(
                    dialogTempValues.dayWeek
                  );
                  if (!isValid) {
                    console.log("Not valid");
                  } else {
                    // if there are no errors in occurrences count, proceeds to save

                    if (validationErrorOccurrence === "") {
                      formikBag.setValues({
                        ...formikBag.values,
                        recurrenceTypeID: dialogTempValues.recurrenceTypeID,
                        separationCount: dialogTempValues.separationCount,
                        maxOccurrences: parseInt(
                          dialogTempValues.maxOccurrences
                        ),
                        // weekMonth: [3].includes(
                        //   dialogTempValues.recurrenceTypeID
                        // )
                        //   ? dialogTempValues.weekMonth
                        //   : 0,
                        weekMonth: dialogTempValues.weekMonth,
                        monthYear: dialogTempValues.monthYear,
                        dayWeek: dialogTempValues.dayWeek,
                        recurrenceStartDate:
                          dialogTempValues.recurrenceStartDate,
                        recurrenceEndDate: dialogTempValues.recurrenceEndDate,
                        dayMonth: dialogTempValues.dayMonth,
                        // dayMonth: [3, 4].includes(
                        //   dialogTempValues.recurrenceTypeID
                        // )
                        //   ? dialogTempValues.dayMonth
                        //   : 0,
                      });
                      setModal({ visible: false, name: "" });
                    }
                  }
                }}
                color="primary"
              >
                Save
              </Button>
            </div>
          </GridItem>

          {/* {process.env.NODE_ENV === "development" && (
            <GridContainer alignItems="flex-start">
              <GridItem xs={12}>
                <pre>
                  {JSON.stringify(
                    {
                      touched: fprops.touched,

                      // recurrenceTypeID: dialogTempValues.recurrenceTypeID,
                      // separationCount: dialogTempValues.separationCount,
                      // maxOccurrences: dialogTempValues.maxOccurrences,
                      // weekMonth: dialogTempValues.weekMonth,
                      // monthYear: dialogTempValues.monthYear,
                      // dayWeek: dialogTempValues.dayWeek,
                      // dayMonth: dialogTempValues.dayMonth,
                      // recurrenceStartDate: dialogTempValues.recurrenceStartDate,
                      // recurrenceEndDate: dialogTempValues.recurrenceEndDate,
                      // startDate: dialogTempValues.startDate,
                    },
                    null,
                    2
                  )}
                </pre>
              </GridItem>
            </GridContainer>
          )} */}
        </GridContainer>
      );
    };

    const modalToShow = (formikBag) => {
      switch (modal.name) {
        // case "forbidden-external-calendar":
        //   return (
        //     <CustomDialog
        //       center
        //       iconPreset="forbidden"
        //       visibleStatus={modal}
        //       setVisibleStatus={setModal}
        //       title="Could not update the external calendar"
        //       mainText="Orquesti doesn't have permissions to update the external calendar.  Reconnect the external calendar and allow permissions."
        //       buttonCloseLabel="OK"
        //     />
        //   );
        case "cant-switch-1:1-attendees-max":
          return (
            <CustomDialog
              center
              iconPreset="forbidden"
              visibleStatus={modal}
              setVisibleStatus={setModal}
              title="Can't change meeting type"
              mainText="1 on 1 meeting can't have more than one attendee."
              buttonCloseLabel="OK"
            />
          );

        case "custom-recurrence":
          return (
            <CustomDialog
              center
              visibleStatus={modal}
              setVisibleStatus={setModal}
              headerTitle="Repeat"
              maxWidth="sm"
            >
              <RecurrenceDialog
                formikBag={formikBag}
                setModal={setModal}
                data={modal.data}
              />
            </CustomDialog>
          );

        default:
          return null;
      }
    };

    const timeFormat = timeMode === 1 ? "hh:mm aaa" : "HH:mm";

    const processedValues = {
      title: title || "",
      startDate:
        startDate == null
          ? null //format(new Date(), "yyyy-MM-dd")
          : format(new Date(startDate), "yyyy-MM-dd"),

      endDate:
        endDate == null
          ? format(new Date(), "yyyy-MM-dd")
          : format(new Date(endDate), "yyyy-MM-dd"),

      allDay: allDay || false,

      startTime:
        startDate == null
          ? null
          : // format(
            //   roundToNearestMinutes(new Date(), {
            //     nearestTo: 30,
            //     roundingMethod: "ceil",
            //   }),
            //   timeFormat
            // )
            format(new Date(startDate), timeFormat),
      startDateFinal:
        startDateFinal == null
          ? null
          : // format(
            //   roundToNearestMinutes(new Date(), {
            //     nearestTo: 30,
            //     roundingMethod: "ceil",
            //   }),
            //   timeFormat
            // )
            format(new Date(startDateFinal), timeFormat),

      endTime:
        endDate == null
          ? null
          : // format(
            //   roundToNearestMinutes(add(new Date(), { hours: 1 }), {
            //     nearestTo: 30,
            //     roundingMethod: "ceil",
            //   }),
            //   timeFormat
            // )
            format(new Date(endDate), timeFormat),
      endDateFinal:
        endDateFinal == null
          ? format(
              roundToNearestMinutes(add(new Date(), { hours: 1 }), {
                nearestTo: 30,
                roundingMethod: "ceil",
              }),
              timeFormat
            )
          : format(new Date(endDateFinal), timeFormat),
      location: location || "",
      attendees: attendees || [],
      meetingGroupID: meetingGroupId,
      attendeeInput: "",
      meetingTypeID: meetingTypeID,
      meetingTypeList: meetingTypeList,
      recurrenceTypeID: recurrenceTypeID,
      separationCount: separationCount,
      maxOccurrences: maxOccurrences,
      weekMonth: weekMonth,
      monthYear: monthYear,
      dayWeek: dayWeek,
      recurrenceStartDate: recurrenceStartDate,
      recurrenceEndDate: recurrenceEndDate,
      dayMonth: dayMonth,
      parentEventID: parentEventID,
    };

    // if this is the first, will be undefined, hence loads the processedValues.  Otherwise, keeps the current data in the ref
    const initialValues =
      previousDataRef.current === undefined
        ? processedValues // coming from detailsInfo
        : previousDataRef.current;

    // if there's data in the meeting state and the data has not been saved yet to the db
    // it should be loaded from the state and not from the db
    // let initialValues = values;
    console.log(
      "%cset previousDetailsInfoRef",
      "background-color: orange; color: white",
      previousDetailsInfoRef.current
    );

    previousDetailsInfoRef.current = initialValues;

    if (!isLoading) {
      // console.log("%cValues", "background-color: green; color: white", {
      //   isLoading,
      //   initialValues,
      // });
      previousDataRef.current = processedValues; // this will populate with the initial values regardess of whether it has made any changes yet
    }

    const meetingDetailsSave = (variablesToUpdate, values, actions) => {
      console.log("starts saving to db", variablesToUpdate);
      // console.log(
      //   "%cstarts saving to db",
      //   "background-color: red; color: white",
      //   { variablesToUpdate, values, actions, meetingID }
      // );

      const options = axiosOptions({
        url: `${config.url}/api/meeting-save-encrypted`,
        method: "post",
        data: {
          ...variablesToUpdate,
          meetingID: meetingID, // if null (eg. repeat event) will be converted to latest consecutive
          // parentEventID: meeting.parentEventID,
          userID: userID,
          statusID: statusID,
          isDraft: true,
        },
      });

      // console.log("axiosOptions", options);
      console.log(
        "%cstarts saving to db",
        "background-color: red; color: white",
        options
      );

      axios(options)
        .then(async (response) => {
          console.log(
            "%cSaved successfully",
            "background-color: green; color: white",
            {
              dataReceived: response.data,
              values: values,
              previousDetailsInfoRef: previousDetailsInfoRef,
            }
          );

          //update the cost in the header
          setMeeting({
            ...meeting,
            meetingCost: response.data.info.meeting_cost,
          });
          // updateMeetingCost(response.data.info.meeting_cost);

          if (
            values.meetingTypeID !==
            previousDetailsInfoRef.current?.meetingTypeID
          ) {
            console.log(
              "%cCambia meeting type",
              "background-color: blue; color: white",
              {
                current: previousDetailsInfoRef.current?.meetingTypeID,
                new: values.meetingTypeID,
              }
            );
            setMeeting({
              ...meeting,
              meetingTypeID: values.meetingTypeID,
            });
          }
          // console.log("sendInvitations", sendInvitations);
          // if (sendInvitations === true) {
          //   setShowSnackbar({
          //     show: true,
          //     messageType: "success",
          //     message: "Meeting confirmed and emails sent",
          //   });
          // }

          if (meetingID === null && response.data.info.new_meeting === true) {
            console.log(
              "%cNew meeting ID",
              "background-color: red; color: white",
              response.data.info.meeting_id
            );
            // will cause blinking
            updateMeetingID(response.data.info.meeting_id);
          }

          // resets the formik form to remove all touched fields.  The second argument "false" prevents from run validation.
          // This will allow to show errors on the corresponding fields
          actions.setTouched({}, false);
        })
        .catch((error) => {
          console.log(
            "%cSe dio un error",
            "background-color: red; color: white"
          );

          setErrorState({
            error: error,
            errorExplanation: "Couldn't save the meeting details",
            data: {
              errorLevel: "fatal",
              otherData: { axiosOptions: options },
            },
          });
        });
    };

    const storeChanges = (fieldList) => {
      let changes = {};
      try {
        fieldList.map((item) => {
          // processes changes differently if it's an array or not, e.g. attendees
          if (Array.isArray(item.valueNew)) {
            if (isEqual(item.valueNew, item.valueCurrent)) {
              console.log("array is NOT different");
              // if arrays are equal, remove from changes
              // delete changes[item.field];
              // if arrays are equal, send values under "new" tag
              changes = {
                ...changes,
                [item.field]: {
                  // current: item.valueCurrent, // .map((item) => item.email),
                  new: item.valueNew,
                },
              };
            } else {
              // console.log("array is different", {
              //   original: item.valueCurrent,
              //   nuevo: item.valueNew,
              // });
              changes = {
                ...changes,
                [item.field]: {
                  current: item.valueCurrent, // .map((item) => item.email),
                  new: item.valueNew,
                },
              };
            }
          } else {
            // if the the value of the current field is equal to the value given, it means there's no change and the property is deleted.
            // Otherwise, the property is added

            if (item.valueCurrent === item.valueNew) {
              console.log("field are NOT different");

              // delete changes[item.field];
              // if values are equal, send values under "new" tag
              changes = {
                ...changes,
                [item.field]: {
                  // current: item.valueCurrent,
                  new: item.valueNew,
                },
              };
            } else {
              console.log("field are different", {
                original: item.valueCurrent,
                nuevo: item.valueNew,
              });
              changes = {
                ...changes,
                [item.field]: {
                  current: item.valueCurrent,
                  new: item.valueNew,
                },
              };
            }
          }
        });

        debounceCustom(() => {
          if (Object.keys(changes).length === 0) {
            console.log("no changes");
            sendInfoToHeader({ changesFromDetails: null });
          } else {
            sendInfoToHeader({ changesFromDetails: changes });
          }
        }, 250);
      } catch (error) {
        logError(error, {
          generalData: { ...generalData },
          otherData: { changes: changes, fieldList: fieldList },
        });
      }
    };

    const schema = object().shape({
      title: string()
        .required("Title is required")
        .max(100, "Characters exceeded.  Max. 100"), //.required("Title is required"),
      location: string(),
      startDate: date()
        .nullable()
        .required("Start date is required")
        .test("is-greater", "Date can't be in the past", function (value) {
          // console.log("input", value);
          const daysDifference = differenceInCalendarDays(value, new Date());

          // evaluates if the date is in the future only when status is draft or confirmed.
          // when the meeting has started, start date can be in the past

          return (statusID === 1 && isOrganizer) ||
            ([2, 8].includes(statusID) && isOrganizer && daysDifference >= 0) // if the alert to start the meeting is showing, hide the "Date in the past" validation
            ? daysDifference >= 0
            : true; // validation passed
        }),
      attendeeInput: string(),
      //   .email("Email is not valid")
      //   .test("Unique", "Email is already in the list", (emailTyped, data) => {
      //     const emailAlreadyInList =
      //       data.parent.attendees.filter((item) => item.email === emailTyped)
      //         .length > 0;

      //     return !emailAlreadyInList;
      //   })
      //   .test("SelfInvite", "Can't add your own email", (emailTyped) => {
      //     return emailTyped !== emailProp;
      //   }),

      attendees: array().min(1, "Must invite at least one person"),

      endDate: date().nullable(),
      // .min(ref("startDate"), "End date can't be before start date"),

      startTime: string()
        .nullable()
        .required("Start time is required")
        .matches(
          /^((1[0-2]|0?[0-9]):[0-5]?[0-9]\s?([apAP][mM]))$/,
          "Not a valid time. e.g. 8:15 am "
        ) // when using am/pm
        // .matches(/^[0-9][0-9][:][0-9][0-9]$/, "Not a valid time ") // when using 24h

        .test(
          "in-future",
          "Time must be in the future",
          function (value, value2) {
            // const isFocused = document.activeElement === startTimeRef.current;

            // console.log("los valores son", { value, value2 });
            // console.log("digitos", value.length !== 5);

            // evaluates if the time length is 5 digits (e.g. 19:50), otherwise will THIS validation
            // other validations might fail
            // if (value.length === 5) {
            const { startTime, startDate } = this.parent;

            const startDateNoTime = new Date(startDate)
              .toISOString()
              .substring(0, 10);

            const currentStartDate = `${startDateNoTime}T${convertAMPMTo24(
              startTime
            )}`;
            // console.log(
            //   "%cCurrent start date",
            //   "background-color: yellow; color: red",
            //   currentStartDate
            // );

            const timeDifference = differenceInSeconds(
              new Date(currentStartDate),
              new Date()
            );

            // console.log(
            //   "%cValidates start time",
            //   "background-color: green; color: white",
            //   { time: timeDifference > 0 }
            // );

            // evaluates in the time is in the future only when status is draft or confirmed.
            // when the meeting has started, start time will be in the past

            return (statusID === 1 && isOrganizer) ||
              ([2, 8].includes(statusID) && isOrganizer && timeDifference > 0) // if the alert to start the meeting is showing, hide the "Date in the past" validation
              ? timeDifference > 0
              : true; // validation passed
            // } else {
            //   return true;
            // }
          }
        ),

      endTime: string()
        .nullable()
        .required("End time is required")
        .matches(
          /^((1[0-2]|0?[0-9]):[0-5]?[0-9]\s?([apAP][mM]))$/,
          "Not a valid time "
        ) // when using am/pm
        // .matches(/^[0-9][0-9][:][0-9][0-9]$/, "Not a valid time ") // when using 24h

        .test(
          "in-future",
          "Must be after start time",
          function (value, value2) {
            const { startTime, allDay } = this.parent;

            // const end = `2020-01-01T${value}`;
            // const start = `2020-01-01T${startTime}`;
            // console.log(
            //   "%cStarts end time test",
            //   "background-color: purple; color: white",
            //   {
            //     start: startTime,
            //     end: value,
            //     endConverted: `2020-01-01T${convertAMPMTo24(value)}`,
            //     startConverted: `2020-01-01T${convertAMPMTo24(startTime)}`,
            //   }
            // );
            const end = new Date(
              convertLocalToUTC(`2020-01-01T${convertAMPMTo24(value)}`)
            );

            const start = new Date(
              convertLocalToUTC(`2020-01-01T${convertAMPMTo24(startTime)}`)
            );

            // console.log(
            //   "%cStarts end time test",
            //   "background-color: red; color: white",
            //   { end, start }
            // );

            const timeDifference = differenceInSeconds(
              new Date(end),
              new Date(start)
            );
            // console.log("Validates end time", {
            //   startTime: startTime,
            //   timeDifference: timeDifference,
            //   timeDifferenceNan: isNaN(timeDifference),
            //   allDay: allDay,
            //   timeEnd: new Date(end),
            //   timeStart: new Date(start),
            // });

            // if (allDay) {
            //   return true;
            // } else {
            //   return timeDifference > 0;
            // }

            if (allDay === true) {
              console.log("valid", true);

              return true;
            } else if (withDurations === true) {
              return true;
            } else if (isNaN(timeDifference)) {
              // console.log("valid", true);
              //if the start time had an error, bypass validation of end time
              return true;
            } else {
              // console.log("valid", timeDifference > 0);
              return timeDifference > 0;
            }
          }
        ),
    });

    let returnedExternalMeetingID = null;

    const handleSubmit = async (values, actions, initialValues) => {
      console.log(
        "%c**********  Starts handle submit with these values ***********",
        "background-color: orange; color: white",
        {
          values: values,
          actions: actions,
          initialValues: initialValues,
          dataForMutation: {
            variables: {
              title: values.title,
              meetingID: meetingID,
              externalMeetingID: returnedExternalMeetingID,
            },
          },
          formRef: formRef.current,
          previousDetailsInfoRef: previousDetailsInfoRef.current,
          previousDataRef: previousDataRef.current,
          otherDataRef: otherDataRef.current,
        }
      );

      try {
        // store the latest data again in ref to incorporate the changes made (initially store when rendering the formik component)
        previousDataRef.current = values;
        // previousDetailsInfoRef.current = values;

        // typing in the attendeeInput, avoid saving until added to list and attendeeInput is blank again
        if (values.attendeeInput !== "") {
          console.log("-------------- stops ----------------");
          return null;
        }

        const variablesNewValues = {
          // if no attendees are in the array, returns undefined, hence the variable is not sent
          attendees: values.attendees.map((item) => item.email),
          allDay: values.allDay,
          endDate: convertLocalToUTC(
            `${values.endDate}T${convertAMPMTo24(values.endTime)}`
          ),
          externalMeetingID: returnedExternalMeetingID,
          location: values.location,
          meetingGroupID: values.meetingGroupID,
          meetingTypeID: values.meetingTypeID,
          parentEventID: values.parentEventID,
          startDate: convertLocalToUTC(
            `${values.startDate}T${convertAMPMTo24(values.startTime)}`
          ),
          title: values.title,

          // recurring data
          occurrenceRepeatType: occurrenceRepeatType,
          recurrenceTypeID: values.recurrenceTypeID,
          dayMonth: values.dayMonth,
          dayWeek: values.dayWeek,
          maxOccurrences: values.maxOccurrences,
          monthYear: values.monthYear, //meetingID === null ? undefined : values.monthYear,
          recurrenceEndDate: values.recurrenceEndDate,
          recurrenceStartDate: values.recurrenceStartDate,
          separationCount: values.separationCount,
          weekMonth: values.weekMonth, //meetingID === null ? undefined : values.weekMonth,
        };
        // adds detail of "new" and "current" (if required when both values are different)
        const variableProcessed = Object.entries(variablesNewValues).map(
          ([name, value]) => {
            // processes changes differently if it's an array or not, e.g. attendees
            let current = initialValues[name]; // initialize the current value with the initial info
            if (Array.isArray(value)) {
              if (name === "attendees") {
                // extract an array of emails and replace the current initialised value
                current = initialValues[name].map((item) => item.email);
              }

              // compares the new array ("value") with the current ("current")
              if (isEqual(value, current)) {
                console.log("array is NOT different");

                // if arrays are equal, send values under "new" tag only without the "current" tag
                return {
                  [name]: { new: value },
                };
              } else {
                // if arrays are different, send both "new" and "current" values
                return {
                  [name]: { new: value, current: current },
                };
              }
            } else {
              // if the the value of the current field is equal to the value given, it means there's no change and the property is deleted.
              // Otherwise, the property is added

              switch (name) {
                case "startDate":
                  current = convertLocalToUTC(
                    `${initialValues.startDate}T${convertAMPMTo24(
                      initialValues.startTime
                    )}`
                  );

                  break;

                case "endDate":
                  current = current = convertLocalToUTC(
                    `${initialValues.endDate}T${convertAMPMTo24(
                      initialValues.endTime
                    )}`
                  );
                  break;

                default:
              }

              if (value === current) {
                // if values are equal, send values under "new" tag only without the "current" tag
                return {
                  [name]: { new: value },
                };
              } else {
                // if values are different, send both "new" and "current" values
                return {
                  [name]: { new: value, current: current },
                };
              }
            }
          }
        );

        // converts from an array of objects to just an object
        const variableFinal = variableProcessed.reduce((accum, item) => {
          return { ...accum, ...item };
        }, {});

        // if there are changes after the meeting was created but before starting
        // store the changes so they can be communicated to attendees.  Saving to database will occur when
        // pressing the send changes button
        if ([2, 8].includes(statusID)) {
          debounceCustom(() => {
            if (Object.keys(variableFinal).length === 0) {
              console.log("no changes");
              sendInfoToHeader({ changesFromDetails: null });
            } else {
              sendInfoToHeader({ changesFromDetails: variableFinal });
            }
          }, 250);
        } else {
          meetingDetailsSave(variableFinal, values, actions);
        }
        return "completed";
      } catch (error) {
        logError(error, {
          generalData: { ...generalData },
          otherData: {
            values: values,
            actions: actions,
            initialValues: initialValues,
            dataForMutation: {
              variables: {
                title: values.title,
                meetingID: meetingID,
                externalMeetingID: returnedExternalMeetingID,
              },
            },
          },
        });
      }
    };

    const autoSave = (formik) => {
      try {
        // console.log(
        //   "%cStarts autosave",
        //   "background-color: blue; color: white",
        //   {
        //     formikValues: formik.values,
        //     previousData: previousDetailsInfoRef.current,
        //   }
        // );

        // const formikContext = useFormikContext();
        // console.log(
        //   "%cFormik context",
        //   "background-color: red; color: white",
        //   formik
        // );

        if (meetingID === null) {
          // console.log("meeting id is null", {
          //   isEqual: isEqual(formik.values, previousDetailsInfoRef.current),
          //   formikValues: formik.values,
          //   previousInfo: previousDetailsInfoRef.current,
          // });

          // if there's a change in a repeat meeting
          if (
            isEqual(formik.values, previousDetailsInfoRef.current) === false
          ) {
            savePendingRef.current = true;
          }
          // avoid continue
          return null;
        }

        const debounceMs = 1000;

        const debouncedSubmit = useCallback(
          debounce(async () => {
            console.log(
              "%c******** Form submitted in autosave, runs validation *************",
              "background-color: red; color: white"
            );

            // formik.submitForm() or formik.handleSubmit() don't work because will set all fields to touched hence displaying errors for untouched fields
            // validateForm don't set fields to touched
            const errors = await formik.validateForm();

            // If there are no errors, submit the form
            if (Object.keys(errors).length === 0) {
              formik.submitForm();
            } else {
              // Handle errors if necessary, but don't set fields to touched
              console.log("Validation errors:", errors);
            }
          }, debounceMs),
          [formik.submitForm, debounceMs]
        );

        useEffect(() => {
          const anyFieldTouched = Object.keys(formik.touched).length > 0;
          console.log("anyFieldTouched", {
            anyFieldTouched,
            hasChanged:
              isEqual(formik.values, previousDetailsInfoRef.current) === false,
            attendeeInput: formik.values.attendeeInput === "",
            recurrenceTypeId: formik.values.recurrenceTypeID !== 5,
            values1: formik.values,
            values2Ref: previousDetailsInfoRef.current,
          });
          if (
            // avoid submit form when this is the first load as it would trigger validation.
            // if some fields don't pass validation, this would highlight errors without having even touched the screen
            // previousDataRef.current !== undefined &&
            (anyFieldTouched ||
              isEqual(formik.values, previousDetailsInfoRef.current) ===
                false) &&
            formik.values.attendeeInput === "" &&
            formik.values.recurrenceTypeID !== 5
            // && avoidAutosave === false
          ) {
            // this will trigger validation
            debouncedSubmit();
          }
          previousDetailsInfoRef.current = formik.values;
        }, [debouncedSubmit, formik.values]);

        return null;
      } catch (error) {
        logError(error, {
          generalData: { ...generalData },
          otherData: {
            formikValues: formik.values,
            initialValues: initialValues,
          },
        });
      }
    };

    const getContactList = (searchTerm, emailsToExclude) => {
      // console.log("get contact list.  Exclude these emails", {
      //   searchTerm,
      //   emailsToExclude,
      // });
      if (searchTerm.length === 0) {
        // if no searchTerm, clears the list
        // setContactList([]);
        setContacts({
          inputForUnfiltered: "",
          filtered: [],
          unfiltered: [],
        });
      } else {
        setIsLoadingContacts(true);

        const options = axiosOptions({
          url: `${config.url}/api/list-contacts`,
          method: "get",
          params: {
            userID: userIDProp,
            accountID: accountID,
            searchTerm: searchTerm,
          },
        });

        debounceCustom(() => {
          axios(options)
            .then((response) => {
              // if (200 === response.status) {
              // }
              // console.log("axios response", response.data);
              const contactList = response.data.info;
              console.log("axios response to contact list", contactList);

              let contactsAfterExclusion = [];
              if (
                emailsToExclude !== undefined ||
                emailsToExclude?.length > 0
              ) {
                contactsAfterExclusion = contactList.filter(
                  (item) => !emailsToExclude.includes(item.email)
                );
              } else {
                contactsAfterExclusion = contactList;
              }
              // console.log("contact state", {
              //   inputForUnfiltered: searchTerm,
              //   filtered: contactsAfterExclusion,
              //   unfiltered: contactsAfterExclusion,
              // });

              setContacts({
                inputForUnfiltered: searchTerm,
                filtered: contactsAfterExclusion,
                unfiltered: contactsAfterExclusion,
              });
              setIsLoadingContacts(false);
              // return response.data.info;
            })
            .catch((error) => {
              console.log("error is:", error);
              setIsLoadingContacts(false);
              switch (error.response.status) {
                case 403: // forbidden
                  break;
                default:
                  logError(error, {
                    generalData: { ...generalData },
                    otherData: {
                      axiosOptions: axiosOptions,
                    },
                  });
              }
              return null;
            });
        }, 1000);
      }
    };

    const filterList = async (props) => {
      // console.log(
      //   "%cStarts filter list",
      //   "background-color: red; color: white"
      // );

      try {
        // filter list first gets the contact list using the input value
        // after that, if the returned values are higher than the paging limit,
        // will only filter the returned List otherwise gets the contact list with a more detailed input value

        const { inputValue, emailsToExclude } = props;
        // retrieves the original contact list

        // console.log("values after exclusion", valuesAfterExclusion);
        // console.log("data", { ...contacts, newInput: inputValue });
        const inputForUnfilteredLength = contacts.inputForUnfiltered.length;
        const differentInitialCharacters =
          contacts.inputForUnfiltered !==
          inputValue.slice(0, inputForUnfilteredLength);
        const sameLength = inputForUnfilteredLength === inputValue.length;
        // console.log("root compare", {
        //   differentInitialCharacters,
        //   sameLength,
        // });

        if (
          inputValue === "" ||
          contacts.inputForUnfiltered.length > inputValue.length ||
          (differentInitialCharacters && sameLength) ||
          contacts.unfiltered.length >= 10 ||
          contacts.unfiltered.length === 0
        ) {
          // if the contactList holds the same number of records of the api pageSize, it means there are more records that need to be fetched
          // therefore returns the original contact list

          // console.log(
          //   "fetch again.  number of contacts: ",
          //   contacts.unfiltered.length
          // );

          getContactList(props.inputValue, emailsToExclude);
          // setFilteredList(valuesAfterExclusion);
        } else {
          console.log(
            "filters.  number of contacts unfiltered: ",
            contacts.unfiltered.length
          );

          // if the contactList holds less records than the api pageSize, it means there are no more records for the searchTerm
          // therefore, don't fetch again and just filters
          // const filteredValues = data.spMeetingActions.nodes[index].list.filter(
          const filteredValues = contacts.unfiltered.filter(
            (item) =>
              (item.name || "")
                .toLowerCase()
                .includes(inputValue.toLowerCase()) ||
              (item.email || "")
                .toLowerCase()
                .includes(inputValue.toLowerCase())
          );

          // const filteredValues = contacts.unfiltered.filter((item) =>
          //   (item.name || "").toLowerCase().includes("ad")
          // );

          // console.log("filteredValues", filteredValues);
          // return filteredValues;
          // setFilteredList(filteredValues);
          setContacts({
            ...contacts,
            filtered: filteredValues,
          });
        }
      } catch (error) {
        logError(error, {
          generalData: { ...generalData },
          otherData: { props: props },
        });
      }
    };

    const variationStartLabelContainerClasses = classNames({
      [classes.variationLabelMain]: true,
      [classes.variationLabelRed]: meeting.startDate < meeting.startDateFinal,
      [classes.variationLabelGray]: meeting.startDate >= meeting.startDateFinal,
    });

    const variationEndLabelContainerClasses = classNames({
      [classes.variationLabelMain]: true,
      [classes.variationLabelRed]: meeting.endDate < meeting.endDateFinal,
      [classes.variationLabelGreen]: meeting.endDate >= meeting.endDateFinal,
    });

    const Variations = (props) => {
      const { label, plannedDate, realDate } = props;
      // console.log("los props son", props);
      // console.log(
      //   "difference",
      //   compareDatesWithoutTime(new Date(realDate), new Date(plannedDate))
      // );

      return (
        <div className={classes.topContainer}>
          <p className={classes.label}>{label}</p>
          <div className={classes.mainContainer}>
            <h6 className={classes.variationLabelMain}>
              {realDate === null
                ? "Pending"
                : compareDatesWithoutTime(
                    new Date(realDate),
                    new Date(plannedDate)
                  )
                ? formatTime(realDate)
                : formatDateTime(realDate)}
            </h6>
            <h6
              className={
                label === "Started"
                  ? variationStartLabelContainerClasses
                  : variationEndLabelContainerClasses
              }
            >
              {timeDifference(realDate, plannedDate, true, "minutes")}
            </h6>
          </div>
        </div>
      );
    };

    // show activity indicator for the initial mount only.
    if (isLoading === true && previousDataRef.current === undefined) {
      return <ActivityIndicator fullscreen />;
    }

    return (
      <>
        <Formik
          innerRef={formRef}
          // important if for example a draft meeting has an old date, this will trigger validation false
          initialValues={initialValues}
          onSubmit={(values, actions) =>
            // if the the validation succeeds, continues to handle submit.
            handleSubmit(values, actions, initialValues)
          }
          validationSchema={schema}
          enableReinitialize
          // is false because when switching from/to allDay, it validates all changed field separately
          // and generates momentarily an error when validating endTime.
          //  this parameters forces to use validateField manually where necesary.  Most fields will validate automatically on autosave

          validateOnMount={false}
          validateOnChange={true} // will validate inmediately when there's a change
          validateOnBlur={true} // will validate inmediately when there's a blur
        >
          {(fprops) => {
            // console.log(
            //   "%cLoads formik",
            //   "background-color: orange; color: red",
            //   { touched: fprops.touched, values: fprops.values }
            // );

            useImperativeHandle(
              ref,
              () => ({
                triggerSubmit1() {
                  console.log(
                    "******************************************trigger submit for only the current Event"
                  );
                  occurrenceRepeatType = 1;
                  sendInvitations = false;
                  fprops.submitForm();
                },
                triggerSubmit2() {
                  console.log(
                    "******************************************trigger submit for all series"
                  );
                  occurrenceRepeatType = 2;
                  sendInvitations = false;
                  fprops.submitForm();
                },
                triggerSubmit3() {
                  console.log(
                    "******************************************trigger submit this event and following"
                  );
                  occurrenceRepeatType = 3;
                  sendInvitations = false;
                  fprops.submitForm();
                },
                triggerSubmit4() {
                  console.log(
                    "******************************************trigger submit for invitations with repeating ocurrence (updates the current event only as exception)"
                  );
                  occurrenceRepeatType = 1;
                  sendInvitations = true;
                  fprops.submitForm();
                },
                async triggerValidation() {
                  console.log(
                    "******************************************trigger submit for invitations with no repeating ocurrence"
                  );
                  occurrenceRepeatType = 0;
                  sendInvitations = true;
                  // set all fields to touched.  This will allow make visible all the errors.
                  const touchedFields = Object.keys(initialValues).reduce(
                    (acc, key) => {
                      acc[key] = true;
                      return acc;
                    },
                    {}
                  );

                  fprops.setTouched(touchedFields);

                  const validated = await fprops.validateForm();
                  return validated;
                },
              }),
              []
            );

            const addChip = async (push, newChip) => {
              try {
                console.log(
                  "%cstarts onAddChip",
                  "background-color: green; color: white"
                );

                // evaluates if there are errors in the input
                if (
                  fprops.errors.attendeeInput !== undefined
                  // removed because it wouldn't allow adding email when there's nothing in the attendees
                  // && fprops.errors.attendees === undefined
                ) {
                  console.log(" cant add");

                  return null;
                }

                console.log(
                  "there are no errors in attendeeInput",
                  fprops.errors.attendeeInput
                );
                fprops.setFieldValue("attendeeInput", "");
                push(newChip); // adds to the attendee list

                if (meetingID === null) {
                  // if there's a change
                  if (
                    isEqual(fprops.values, previousDetailsInfoRef.current) ===
                    false
                  ) {
                    savePendingRef.current = true;
                  }
                  // avoid continue
                  return null;
                }

                sendInfoToParent([
                  ...detailsInfo.attendees,
                  {
                    ...newChip,
                  },
                ]);

                filterList({
                  inputValue: "",
                  emailsToExclude: [],
                });
                // }
              } catch (error) {
                logError(error, {
                  generalData: { ...generalData },
                  otherData: { newChip },
                });
              }
            };

            const calcNewEndTime = (newStartTime) => {
              // const { newStartTime } = props;
              console.log("newStartTime", newStartTime);

              // calculates the new end date based on the original duration
              const currentStartDate =
                detailsInfo.startDate !== null &&
                new Date(detailsInfo.startDate);
              console.log("currentStartDate", {
                currentStartDate,
              });

              const currentEndDate =
                detailsInfo.endDate !== null && new Date(detailsInfo.endDate);
              console.log("currentEndDate", {
                currentEndDate,
                convertTo24: convertAMPMTo24(newStartTime),
              });

              console.log(
                "%cdatedddadfadf",
                `${initialValues.startDate}T${convertAMPMTo24(newStartTime)}`
              );

              const newStartDate = new Date(
                convertLocalToUTC(
                  `${initialValues.startDate}T${convertAMPMTo24(newStartTime)}`
                )
              );
              console.log("newStartDate", {
                newStartDate,
              });
              const duration = differenceInMinutes(
                currentEndDate,
                currentStartDate
              );
              console.log("duration", {
                duration,
              });

              const newEndDate = addMinutesToDate(newStartDate, duration);
              console.log("newEndDate", {
                newEndDate,
              });

              const newEndTime = format(newEndDate, timeFormat);
              console.log("newEndTime", {
                newEndTime,
              });

              // console.log("item selected en onSelected", {
              //   newStartTime,
              //   detailsInfo,
              //   currentEndDate,
              //   currentStartDate,
              //   newStartDate,
              //   newEndDate,
              //   newEndTime,
              //   duration,
              // });

              return newEndTime;
            };

            const calcNewStartTime = (newEndTime) => {
              // const { newStartTime } = props;
              console.log("newEndTime", newEndTime);

              // calculates the new end date based on the original duration
              const currentStartDate =
                detailsInfo.startDate !== null &&
                new Date(detailsInfo.startDate);
              console.log("currentStartDate", {
                currentStartDate,
              });

              const currentEndDate =
                detailsInfo.endDate !== null && new Date(detailsInfo.endDate);
              console.log("currentEndDate", {
                currentEndDate,
                convertTo24: convertAMPMTo24(newEndTime),
              });

              const newEndDate = new Date(
                convertLocalToUTC(
                  `${initialValues.endDate}T${convertAMPMTo24(newEndTime)}`
                )
              );
              console.log("newEndDate", {
                newEndDate,
              });

              const duration = differenceInMinutes(
                currentEndDate,
                currentStartDate
              );
              console.log("duration", {
                duration,
              });

              const newStartDate = addMinutesToDate(newEndDate, duration * -1);
              console.log("newStartDate", {
                newStartDate,
              });

              const newStartTime = format(newStartDate, timeFormat);
              console.log("newStartTime", {
                newStartTime,
              });

              // console.log("item selected en onSelected", {
              //   newStartTime,
              //   detailsInfo,
              //   currentEndDate,
              //   currentStartDate,
              //   newStartDate,
              //   newEndDate,
              //   newEndTime,
              //   duration,
              // });

              return newStartTime;
            };

            // console.log("%cDATES", "background-color: red; color: white", {
            //   currentDate: new Date(),
            //   substract: addDaysToDate(new Date(), -1),
            // });

            // console.log(
            //   "%cDetailsInfo",
            //   "background-color: red; color: white",
            //   detailsInfo
            // );

            return (
              <>
                <GridContainer
                  spacing={spacing}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  // touched
                  style={{ maxWidth: "1400px" }}
                >
                  <GridItem xs={7} style={{ maxWidth: "700px" }}>
                    <GridContainer spacing={spacing} alignItems="flex-start">
                      {/*-------------------------------- title --------------------------------*/}
                      <GridItem xs={12}>
                        <CustomMainInput
                          disabled={
                            !isOrganizer || [3, 4, 5, 6, 7].includes(statusID)
                          }
                          error={
                            // fprops.touched.title &&
                            fprops.errors.title
                          }
                          height="40px"
                          label="Title"
                          name="title"
                          // onBlur={fprops.handleBlur("title")}
                          onChange={(e) => {
                            fprops.handleBlur("title");
                            fprops.validateField("title");
                            fprops.handleChange("title")(e);
                          }}
                          placeholder="Title"
                          value={fprops.values.title}
                          variant="input"
                          // onFocus={(item) => {
                          //   console.log("se enfocó", document.activeElement);
                          //   console.log("se enfocó2", attendeeInputRef.current);
                          // }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <GridContainer
                          spacing={spacing}
                          style={{
                            alignItems: "end",
                          }}
                        >
                          {/*-------------------------------- start date --------------------------------*/}
                          <GridItem xs={12} md={fprops.values.allDay ? 4 : 6}>
                            <CustomMainInput
                              disabled={
                                !isOrganizer ||
                                [3, 4, 5, 6, 7].includes(statusID)
                              }
                              error={
                                // fprops.touched.startDate &&
                                fprops.errors.startDate
                              }
                              height="40px"
                              label={
                                fprops.values.allDay ? "Start date" : "Date"
                              }
                              name="startDate"
                              onBlur={fprops.handleBlur("startDate")}
                              onChange={(e) => {
                                console.log(
                                  "%con change",
                                  "background-color: blue; color: white",
                                  e.getDay()
                                );

                                if (e !== null) {
                                  if (!fprops.values.allDay) {
                                    fprops.setFieldValue(
                                      "endDate",
                                      format(e, "yyyy-MM-dd")
                                    );
                                  }

                                  if (fprops.values.recurrenceTypeID !== 0) {
                                    fprops.setFieldValue("dayWeek", [
                                      e.getDay(),
                                    ]);
                                  }

                                  fprops.handleChange("startDate")(
                                    format(e, "yyyy-MM-dd")
                                  );
                                }
                              }}
                              placeholder={
                                fprops.values.allDay ? "Start date" : "Date"
                              }
                              value={fprops.values.startDate}
                              variant="inputDate"
                            />
                          </GridItem>
                          {/* -------------------------------- end date --------------------------------*/}
                          {fprops.values.allDay && (
                            <GridItem xs={12} md={fprops.values.allDay ? 4 : 6}>
                              <CustomMainInput
                                disabled={
                                  !isOrganizer ||
                                  [3, 4, 5, 6, 7].includes(statusID)
                                }
                                error={
                                  // fprops.touched.endDate &&
                                  fprops.errors.endDate
                                }
                                height="40px"
                                label="End Date"
                                name="endDate"
                                onBlur={fprops.handleBlur("endDate")}
                                onChange={(e) => {
                                  // console.log(
                                  //   "%cDates",
                                  //   "background-color: orange; color: white",
                                  //   {
                                  //     startDate: new Date(fprops.values.startDate),
                                  //     endDate: e,
                                  //     compare:
                                  //       new Date(fprops.values.startDate) > e,
                                  //   }
                                  // );
                                  // if the end date falls before the start date, adjust the start date to equal the end date
                                  if (new Date(fprops.values.startDate) > e) {
                                    fprops.setFieldValue(
                                      "startDate",
                                      format(e, "yyyy-MM-dd")
                                    );
                                  }
                                  fprops.handleChange("endDate")(
                                    format(e, "yyyy-MM-dd")
                                  );
                                }}
                                placeholder="End date"
                                value={fprops.values.endDate}
                                variant="inputDate"
                              />
                            </GridItem>
                          )}
                          {/*-------------------------------- all day -------------------------------- */}
                          {fprops.values.meetingTypeID !== 2 && (
                            <GridItem xs={12} md={fprops.values.allDay ? 4 : 6}>
                              <div className={classes.allDayContainer}>
                                <Checkbox
                                  disabled={
                                    !isOrganizer ||
                                    [3, 4, 5, 6, 7].includes(statusID)
                                  }
                                  classes={{ checked: classes.checkbox }}
                                  color="default"
                                  checked={fprops.values.allDay}
                                  onChange={(e) => {
                                    // new end date

                                    if (e.target.checked === true) {
                                      // when marking as checked
                                      const newEndDate = addDaysToDate(
                                        new Date(
                                          `${fprops.values.startDate}T00:00:00`
                                        ),
                                        1
                                      );
                                      fprops.setFieldValue(
                                        "endDate",
                                        format(newEndDate, "yyyy-MM-dd")
                                      );
                                      fprops.setFieldValue("endTime", "00:00");
                                      fprops.setFieldValue(
                                        "startTime",
                                        "00:00"
                                      );
                                      fprops.setFieldValue(
                                        "allDay",
                                        e.target.checked
                                      );
                                    } else {
                                      // when unchecking
                                      console.log(
                                        "%cUnchecking",
                                        "background-color: purple; color: white"
                                      );
                                      const newEndTime = format(
                                        roundToNearestMinutes(
                                          add(new Date(), { hours: 1 }),
                                          {
                                            nearestTo: 30,
                                            roundingMethod: "ceil",
                                          }
                                        ),
                                        "HH:mm"
                                      );
                                      // fprops.setFieldValue("endTime", newEndTime);
                                      const newStartTime = format(
                                        roundToNearestMinutes(new Date(), {
                                          nearestTo: 30,
                                          roundingMethod: "ceil",
                                        }),
                                        "HH:mm"
                                      );
                                      // fprops.setFieldValue(
                                      //   "startTime",
                                      //   newStartTime
                                      // );
                                      // fprops.setFieldValue(
                                      //   "endDate",
                                      //   fprops.values.startDate
                                      // );
                                      // fprops.setFieldValue(
                                      //   "allDay",
                                      //   e.target.checked
                                      // );
                                      fprops.setValues({
                                        ...fprops.values,
                                        startTime: newStartTime,
                                        endTime: newEndTime,
                                        endDate: fprops.values.startDate,
                                      });
                                    }

                                    // fprops.setFieldValue(
                                    //   "allDay",
                                    //   e.target.checked
                                    // );

                                    fprops.handleChange("allDay")(e);
                                  }}
                                  name="allDay"
                                />
                                <h6>All day event</h6>
                              </div>
                            </GridItem>
                          )}
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={12}>
                        <GridContainer
                          spacing={spacing}
                          style={{
                            alignItems: "baseline",
                          }}
                        >
                          {/*-------------------------------- start time --------------------------------*/}
                          {!fprops.values.allDay && (
                            <GridItem xs={4}>
                              <CustomMainInput
                                // refID={startTimeRef}
                                disabled={
                                  !isOrganizer ||
                                  [3, 4, 5, 6, 7].includes(statusID) ||
                                  fprops.values.startTime === null
                                }
                                error={
                                  // fprops.touched.startTime &&
                                  fprops.errors.startTime
                                }
                                label="Start time"
                                onSelected={(newStartTime) => {
                                  // console.log("On Selected");
                                  // const newEndTime = calcNewEndTime(
                                  //   newStartTime.value
                                  // );

                                  fprops.handleChange("startTime")(
                                    newStartTime.value
                                  );

                                  // automatically set the end time with the current duration
                                  const newEndTime = calcNewEndTime(
                                    newStartTime.value
                                  );
                                  fprops.handleChange("endTime")(newEndTime);
                                }}
                                onChange={(newStartTime) => {
                                  // console.log("On Change", newStartTime);

                                  fprops.handleChange("startTime")(
                                    newStartTime
                                  );

                                  // automatically set the end time with the current duration
                                  const newEndTime = calcNewEndTime(
                                    newStartTime.value
                                  );
                                  fprops.handleChange("endTime")(newEndTime);
                                }}
                                value={fprops.values.startTime}
                                variant="inputTime"
                              />
                            </GridItem>
                          )}

                          {/*-------------------------------- end time --------------------------------*/}

                          {!fprops.values.allDay && (
                            <GridItem xs={4}>
                              <CustomMainInput
                                disabled={
                                  !isOrganizer ||
                                  [3, 4, 5, 6, 7].includes(statusID) ||
                                  fprops.values.startDate === null ||
                                  (withAgenda && withDurations)
                                }
                                error={
                                  // fprops.touched.endTime &&
                                  fprops.errors.endTime
                                }
                                label="End time"
                                onSelected={(newEndTime) => {
                                  fprops.handleChange("endTime")(
                                    newEndTime.value
                                  );
                                }}
                                onChange={(value) => {
                                  fprops.handleChange("endTime")(value);
                                }}
                                value={fprops.values.endTime}
                                variant="inputTime"
                              />
                              {withAgenda && withDurations && (
                                <h6 style={{ margin: 0 }}>
                                  End time controlled by agenda
                                </h6>
                              )}
                            </GridItem>
                          )}

                          {/*-------------------------------- repeat -------------------------------- */}
                          <GridItem xs={12}>
                            <GridContainer>
                              <GridItem xs={12} md={6} lg={3}>
                                <CustomMainInput
                                  // disabled={
                                  //   !isOrganizer || [3,4, 5, 6].includes(statusID)
                                  // }
                                  // onBlur={fprops.handleBlur("recurrenceTypeID")}
                                  error={
                                    // fprops.touched.recurrenceTypeID &&
                                    fprops.errors.recurrenceTypeID
                                  }
                                  // dropDownList={userGroupsList}
                                  dropDownList={
                                    otherDataRef.current?.recurrenceTypeList
                                  }
                                  dropDownFunction={(item) => {
                                    // console.log(
                                    //   "%cItem",
                                    //   "background-color: red; color: white",
                                    //   item
                                    // );
                                    fprops.setFieldValue(
                                      "recurrenceTypeID",
                                      item.id
                                    );

                                    // these are the neutral recurrence details that will be a used as base if changes are made
                                    let recurrenceDetails = {
                                      recurrenceTypeID: null, // type
                                      separationCount: 1, // interval
                                      maxOccurrences: 0, // numberOfOccurrences
                                      weekMonth: 0, //null, // index
                                      monthYear: 0, // month
                                      dayWeek: [0], //0, // daysOfWeek
                                      recurrenceStartDate:
                                        fprops.values.recurrenceStartDate ||
                                        fprops.values.startDate,
                                      recurrenceEndDate: null, // end_date. No end date as default
                                      dayMonth: 0, // daysOfMonth
                                    };
                                    switch (item.id) {
                                      case 0: // No repeat
                                        recurrenceDetails = {
                                          ...recurrenceDetails,
                                          recurrenceTypeID: 0,
                                        };
                                        fprops.setValues({
                                          ...fprops.values,
                                          ...recurrenceDetails,
                                        });
                                        break;
                                      case 1: // daily
                                        recurrenceDetails = {
                                          ...recurrenceDetails,
                                          recurrenceTypeID: 1,
                                        };
                                        fprops.setValues({
                                          ...fprops.values,
                                          ...recurrenceDetails,
                                        });
                                        break;

                                      case 2: // weekly
                                        recurrenceDetails = {
                                          ...recurrenceDetails,
                                          recurrenceTypeID: 2,

                                          dayWeek: [
                                            // new Date(
                                            //   fprops.values.startDate
                                            // ).getDay(),

                                            convertToTimezone(
                                              fprops.values.startDate
                                            ).getDay(),
                                          ],
                                        };
                                        fprops.setValues({
                                          ...fprops.values,
                                          ...recurrenceDetails,
                                        });
                                        break;

                                      case 3: // monthly
                                        // on the same date
                                        recurrenceDetails = {
                                          ...recurrenceDetails,
                                          recurrenceTypeID: 3,
                                          dayMonth:
                                            // fprops.values.startDate.slice(-2),
                                            convertToTimezone(
                                              fprops.values.startDate
                                            ).getDate(),
                                        };
                                        fprops.setValues({
                                          ...fprops.values,
                                          ...recurrenceDetails,
                                        });
                                        break;

                                      case 4: // yearly
                                        recurrenceDetails = {
                                          ...recurrenceDetails,
                                          recurrenceTypeID: 4,
                                          dayMonth:
                                            // fprops.values.startDate.slice(-2)
                                            convertToTimezone(
                                              fprops.values.startDate
                                            ).getDate(),
                                          monthYear:
                                            // fprops.values.startDate.substring(5, 7)
                                            convertToTimezone(
                                              fprops.values.startDate
                                            ).getMonth() + 1,
                                        };

                                        fprops.setValues({
                                          ...fprops.values,
                                          ...recurrenceDetails,
                                        });
                                        break;

                                      case 5: // custom
                                        // set the initial values of the dialog as daily

                                        setModal({
                                          visible: true,
                                          name: "custom-recurrence",
                                          data: {
                                            currentRecurrenceTypeID:
                                              fprops.values.recurrenceTypeID,
                                          },
                                        });

                                        break;
                                      default:
                                    }
                                    // console.log(
                                    //   "%cRecurrenceDetails",
                                    //   "background-color: pink; color: white",
                                    //   recurrenceDetails
                                    // );
                                  }}
                                  height="40px"
                                  label="Repeat"
                                  name="recurrenceTypeID"
                                  // onRemovingSelection={(item) =>
                                  //   console.log("pending")
                                  // }
                                  // placeholder="Add group"
                                  value={fprops.values.recurrenceTypeID}
                                  // value={null}
                                  variant="dropdown"
                                  // onFocus={() => console.log("se enfocó group")}
                                />
                              </GridItem>
                            </GridContainer>
                            <RecurrenceString formikBag={fprops} />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      {/*-------------------------------- final start date --------------------------------*/}
                      {[3, 4, 7].includes(statusID) && (
                        <GridItem xs={4}>
                          <Variations
                            label="Started"
                            plannedDate={meeting.startDate}
                            realDate={meeting.startDateFinal}
                            // realDate={detailsInfo?.startDateFinal}
                          />
                        </GridItem>
                      )}

                      {/*-------------------------------- final end date --------------------------------*/}
                      {[3, 4, 7].includes(statusID) && (
                        <GridItem xs={4}>
                          <Variations
                            label="Ended"
                            plannedDate={meeting.endDate}
                            realDate={meeting.endDateFinal}
                          />
                        </GridItem>
                      )}

                      {/*--------------------------------  location --------------------------------*/}
                      <GridItem xs={12} md={6}>
                        <CustomMainInput
                          disabled={
                            !isOrganizer || [3, 4, 5, 6, 7].includes(statusID)
                          }
                          error={
                            // fprops.touched.location &&
                            fprops.errors.location
                          }
                          height="40px"
                          label="Location"
                          name="location"
                          onBlur={fprops.handleBlur("location")}
                          onChange={fprops.handleChange("location")}
                          placeholder="Add location"
                          value={fprops.values.location}
                          variant="input"
                        />
                      </GridItem>
                      {/*-------------------------------- meeting type -------------------------------- */}
                      {/* {process.env.NODE_ENV === "development" && (
                        <GridItem xs={12} md={6} lg={3}>
                          <CustomMainInput
                            disabled={![1].includes(statusID)}
                            error={
                              fprops.touched.meetingTypeID &&
                              fprops.errors.meetingTypeID
                            }
                            dropDownList={otherDataRef.current?.meetingTypeList}
                           
                            dropDownFunction={(item) => {
                              // updates if the selection changes
                              if (item.id !== fprops.values.meetingTypeID) {
                                // if selecting on on one and there are more than one attendee already
                                if (
                                  item.id === 2 &&
                                  fprops.values.attendees.length > 1
                                ) {
                                  console.log("cant change to 1 on 1");
                                  setModal({
                                    visible: true,
                                    name: "cant-switch-1:1-attendees-max",
                                  });
                                } else {
                                  console.log("-----------update field");
                                  fprops.setFieldValue(
                                    "meetingTypeID",
                                    item.id
                                  );
                                }
                              } else {
                                console.log("no selection change");
                              }
                            }}
                            height="40px"
                            label="Meeting type"
                            name="meetingTypeID"
                            placeholder="Add group"
                            value={fprops.values.meetingTypeID}
                            // value={null}
                            variant="dropdown"
                            // onFocus={() => console.log("se enfocó group")}
                          />
                        </GridItem>
                      )} */}

                      {/*-------------------------------- meeting group -------------------------------- */}
                      {otherDataRef.current?.createdBy === userIDProp &&
                        fprops.values.meetingTypeID !== 2 && (
                          <GridItem xs={12} md={6} lg={3}>
                            <CustomMainInput
                              canEdit
                              error={
                                // fprops.touched.meetingGroupID &&
                                fprops.errors.meetingGroupID
                              }
                              dropDownList={
                                otherDataRef.current?.userGroupsList
                              }
                              dropDownFunction={(item) => {
                                console.log(
                                  "%cdropDownFunction",
                                  "background-color: red; color: white",
                                  { item: item, newList: item.newList }
                                );
                                if (item.newList !== undefined) {
                                  otherDataRef.current.userGroupsList =
                                    item.newList;
                                }

                                // detects if the meeting group affected was selected
                                if (fprops.values.meetingGroupID === item.id) {
                                  // if it was selected and the action is removed, deletes
                                  if (item.action === "removed") {
                                    console.log("item removed from formik");
                                    fprops.setFieldValue(
                                      "meetingGroupID",
                                      null
                                    );
                                  }
                                } else {
                                  fprops.setFieldValue(
                                    "meetingGroupID",
                                    item.id
                                  );
                                }
                              }}
                              height="40px"
                              label="Meeting group"
                              name="meetingGroupID"
                              // onRemovingSelection={(item) =>
                              //   console.log("pending")
                              // }
                              placeholder="Add group"
                              value={fprops.values.meetingGroupID}
                              // value={null}
                              variant="dropdown"
                              // onFocus={() => console.log("se enfocó group")}
                            />
                          </GridItem>
                        )}
                    </GridContainer>
                  </GridItem>

                  {/*-------------------------------- attendees -------------------------------- */}
                  <GridItem
                    xs={5}
                    style={{
                      maxWidth: "700px",
                      // backgroundColor: "yellow",
                      // padding: "5px",
                    }}
                  >
                    <FieldArray name="attendees">
                      {({ push, remove }) => (
                        // <AttendeeField props={props} fprops={fprops} />
                        <CustomMainInput
                          autoFocus //focus again in attendee input after entered a previous attendee
                          chiplist={fprops.values.attendees}
                          chipColor="primary"
                          disabled={
                            !isOrganizer || [3, 4, 5, 6, 7].includes(statusID)
                          }
                          dropDownList={contacts.filtered}
                          dropDownInputRender={(item) => (
                            <div className={classes.dropDownCustomContainer}>
                              <Avatar
                                className={classes.avatar}
                                alt={item.name}
                                // src={item.imagePath}
                                style={{
                                  height: "35px",
                                  width: "35px",
                                }}
                              />

                              <div className={classes.dropDownTextsContainer}>
                                <h5 className={classes.dropDownMainText}>
                                  {item.name || item.email}
                                </h5>
                                <h6 className={classes.dropDownSubText}>
                                  {item.email}
                                </h6>
                              </div>
                            </div>
                          )}
                          error={
                            // fprops.touched.attendeeInput &&
                            fprops.errors.attendees
                          }
                          isLoading={isLoadingContacts}
                          label="Attendees"
                          name="attendees"
                          onAddChip={(value) => {
                            addChip(push, {
                              id: null,
                              name: value,
                              email: value,
                            });
                          }}
                          onChange={fprops.handleChange("attendees")}
                          onChangeInput={(inputValue) => {
                            try {
                              fprops.handleChange("attendeeInput")(inputValue);

                              // console.log("los valores on change", inputValue);
                              // fprops.setTouched({});
                              filterList({
                                inputValue: inputValue,
                                emailsToExclude: fprops.values.attendees.map(
                                  (item) => item.email
                                ),
                              });
                              // removes the error when the field is empty
                              // if (fprops.values.attendeeInput === "") {
                              //   // this case happens when a user triggers the error when enters a value that's not an email.
                              //   // when deleting the whole field the error doesn't disappear automatically
                              //   fprops.setErrors({
                              //     attendeeInput: undefined,
                              //   });
                              // }
                            } catch (error) {
                              logError(error, {
                                generalData: { ...generalData },
                                otherData: {},
                              });
                            }
                          }}
                          onRemoveChip={async (removedIndex) => {
                            try {
                              console.log(
                                "index of email to remove",
                                removedIndex
                              );

                              remove(removedIndex);

                              // when accessing a future occurrence, don't submit form.  Waiting for button Save selection press
                              if (meetingID === null) {
                                // if there's a change
                                if (
                                  isEqual(
                                    fprops.values,
                                    previousDetailsInfoRef.current
                                  ) === false
                                ) {
                                  savePendingRef.current = true;
                                }
                                // avoid continue
                                return null;
                              }
                              // removes the attendee from the array of objects
                              const newAttendees =
                                fprops.values.attendees.filter(
                                  (attendee, index) => index !== removedIndex
                                );
                              sendInfoToParent(newAttendees);
                            } catch (error) {
                              logError(error, {
                                generalData: { ...generalData },
                                otherData: { item },
                              });
                            }
                            //----------------------------------------------------------------------------------------
                            try {
                              console.log("email to remove", removedIndex);

                              remove(removedIndex);

                              // when accessing a future occurrence, don't submit form.  Waiting for button Save selection press
                              if (meetingID === null) {
                                // if there's a change
                                if (
                                  isEqual(
                                    fprops.values,
                                    previousDetailsInfoRef.current
                                  ) === false
                                ) {
                                  savePendingRef.current = true;
                                }
                                // avoid continue
                                return null;
                              }

                              let newAttendees;
                              // removes the attendee from the array of objects
                              newAttendees = fprops.values.attendees.filter(
                                (attendee, index) => index !== removedIndex
                              );
                              console.log("new Attendees", newAttendees);
                              // setMeeting({
                              //   ...meeting,
                              //   attendees: newAttendees,
                              // });

                              sendInfoToParent(newAttendees);
                            } catch (error) {
                              logError(error, {
                                generalData: { ...generalData },
                                otherData: { removedIndex },
                              });
                            }
                          }}
                          onSelected={(item) => {
                            try {
                              // remove any previous error

                              // this function is triggered when an item from the list is selected
                              console.log("item selected en onSelected", item);

                              // fprops.validateField("attendeeInput");
                              //                                   fprops.validateForm();

                              const obj = {
                                responsibleEmail: item.email,
                              };
                              const yupObj = object().shape({
                                responsibleEmail: string().email(
                                  "Select from the list or type an email"
                                ),
                              });
                              yupObj
                                .validate(obj)
                                .then(function (value) {
                                  // if validation is successful
                                  console.log("successful validation");
                                  push(item);
                                  fprops.setFieldValue("attendeeInput", "");

                                  // process the attendees from an array of objects to an array
                                  const currentAttendees =
                                    fprops.values.attendees.map(
                                      (item) => item.email
                                    );
                                  console.log(
                                    "los current attendees",
                                    currentAttendees
                                  );

                                  const initialAttendees =
                                    fprops.values.attendees.map(
                                      (item) => item.email
                                    );

                                  // include the last added attendee to the list
                                  const newAttendees = [
                                    ...currentAttendees,
                                    item.email,
                                  ];
                                  console.log(
                                    "los new attendees",
                                    newAttendees
                                  );

                                  // saves to db automatically unless the status is sent.
                                  if ([2, 8].includes(statusID)) {
                                    storeChanges([
                                      {
                                        field: "attendees",
                                        valueNew: newAttendees,
                                        valueCurrent: initialAttendees,
                                      },
                                    ]);
                                  } else {
                                    sendInfoToParent(newAttendees);
                                  }
                                })
                                .catch(function (err) {
                                  console.log(
                                    "el error es",
                                    JSON.stringify(err)
                                  );
                                  // places the selected email in the attendee input box
                                  fprops.setFieldValue(
                                    "attendeeInput",
                                    item.email
                                  );

                                  fprops.setTouched({
                                    attendeeInput: true,
                                  });
                                });
                            } catch (error) {
                              logError(error, {
                                generalData: { ...generalData },
                                otherData: { item },
                              });
                            }
                          }}
                          placeholder={
                            [3, 4, 5, 6, 7].includes(statusID)
                              ? ""
                              : "Type a name or email"
                          }
                          refID={attendeeInputRef}
                          showChipIcon={
                            meeting.isOrganizer &&
                            [2, 3, 4, 4, 5, 7].includes(statusID)
                          }
                          value={fprops.values.attendeeInput}
                          validateInput={async (value) => {
                            const obj = {
                              attendeeInput: value,
                            };

                            const yupObj = object().shape({
                              attendeeInput: string()
                                .email("Email is not valid")
                                .test(
                                  "Unique",
                                  "Email is already in the list",
                                  (emailTyped, data) => {
                                    const emailAlreadyInList =
                                      fprops.values.attendees.filter(
                                        (item) => item.email === emailTyped
                                      ).length > 0;

                                    return !emailAlreadyInList;
                                  }
                                ),
                              // .test(
                              //   "SelfInvite",
                              //   "Can't add your own email",
                              //   (emailTyped) => {
                              //     return emailTyped !== emailProp;
                              //   }
                              // ),
                            });
                            return yupObj
                              .validate(obj)
                              .then(function (value) {
                                // if validation is successful

                                return true;

                                // return { valid: true, message: "" };
                              })
                              .catch(function (err) {
                                return err.message;
                                // return { valid: false, message: err.message };
                              });
                          }}
                          variant="chip"
                        />
                      )}
                    </FieldArray>
                  </GridItem>
                </GridContainer>

                {/* <GridContainer alignItems="flex-start">
                  <GridItem xs={12}>
                    wont' save if there's an error in the attendee input or an error elsewhere that's not the attendee.  
                    This avoids showing the error when attendee is being typed since the error triggers inmediately, although not showing because not yet touched (which happens onBlur)
                    {((fprops.errors.attendeeInput !== undefined &&
                      fprops.touched.attendeeInput !== undefined) ||
                      (Object.keys(fprops.errors).length > 0 &&
                        fprops.errors.attendeeInput === undefined &&
                        fprops.touched.attendeeInput === undefined)) && (
                      <h4
                        style={{ color: "red" }}
                      >{`Changes won't be saved until errors are fixed`}</h4>
                    )}
                  </GridItem>
                </GridContainer> */}

                {modalToShow(fprops)}
                {autoSave(fprops)}

                {/* <Button
                color="primary"
                onClick={() => fprops.setFieldValue("attendees", [])}
              >
                Submit
              </Button> */}
                {/* {process.env.NODE_ENV === "development" && ( */}

                {/* <GridContainer alignItems="flex-start">
                  <GridItem xs={4}>
                    <pre>
                      {JSON.stringify(
                        {
                          values: fprops.values,
                          // errors: fprops.errors,
                          // dirty: fprops.dirty,
                          // touched: fprops.touched,
                          // values: values,
                          // changesFromDetails: meeting.changesFromDetails,
                          // meetingID: meetingID,
                          // startDate: fprops.values.startDate,
                          // recurrenceTypeID: fprops.values.recurrenceTypeID,
                          // separationCount: fprops.values.separationCount,
                          // maxOccurrences: fprops.values.maxOccurrences,
                          // weekMonth: fprops.values.weekMonth,
                          // monthYear: fprops.values.monthYear,
                          // dayWeek: fprops.values.dayWeek,
                          // recurrenceStartDate:
                          //   fprops.values.recurrenceStartDate,
                          // recurrenceEndDate: fprops.values.recurrenceEndDate,
                          // dayMonth: fprops.values.dayMonth,
                        },
                        null,
                        2
                      )}
                    </pre>
                  </GridItem>
                  <GridItem xs={4}>
                    <pre>
                      {JSON.stringify(
                        {
                          previousDataRef: previousDataRef.current,
                        },
                        null,
                        2
                      )}
                    </pre>
                  </GridItem>
                  <GridItem xs={4}>
                    <pre>
                      {JSON.stringify(
                        {
                          previousDetailsInfoRef:
                            previousDetailsInfoRef.current.recurrenceTypeID,
                        },
                        null,
                        2
                      )}
                    </pre>
                  </GridItem>
                </GridContainer> */}
              </>
            );
          }}
        </Formik>
      </>
    );
    // }
  };

  return (
    <>
      {/* <Button
        onClick={() =>
          setShowSnackbar({
            show: true,
            messageType: "success",
            message: "Meeting confirmed and emails sent",
          })
        }
        color="primary"
      >
        test
      </Button> */}

      <>
        <CardBody fullscreen className={classes.cardBody}>
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                size="large"
                data={{
                  errorLevel: "fatal",
                  generalData: { ...generalData },
                }}
              />
            )}
          >
            <FormikComponent
              userID={session.userID}
              email={session.email}
              statusID={meeting.statusID}
              isOrganizer={meeting.isOrganizer}
              withAgenda={meeting.withAgenda}
              withDurations={meeting.withDurations}
            />
          </ErrorBoundary>
        </CardBody>
      </>

      {errorState && (
        <ErrorHandler
          error={errorState.error}
          errorClear={() => setErrorState()}
          data={{
            errorLevel: errorState.errorLevel,
            generalData: { ...generalData },
            otherData: errorState.data,
          }}
          errorExplanation={errorState.errorExplanation}
        />
      )}
      <Snackbar
        messageType={showSnackbar.messageType}
        message={showSnackbar.message}
        open={showSnackbar.show}
        close={() => setShowSnackbar({ ...showSnackbar, show: false })}
      />
    </>
  );
});

// MeetingDetails.whyDidYouRender = true;

export default MeetingDetails;
