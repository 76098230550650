import { Element } from "slate";
import { v4 as uuidv4 } from "uuid";

// export const makeNodeId = () => nanoid(16);
export const makeNodeId = () => uuidv4();

export const assignIdRecursively = (node) => {
  if (Element.isElement(node)) {
    node.id = makeNodeId();
    node.children.forEach(assignIdRecursively);
  }
};

export const withNodeId = (editor) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    if (operation.type === "insert_node") {
      assignIdRecursively(operation.node);
      return apply(operation);
    }

    if (operation.type === "split_node") {
      operation.properties.id = makeNodeId();
      return apply(operation);
    }

    return apply(operation);
  };

  return editor;
};
