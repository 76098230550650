import React from "react";
import isEqual from "lodash/isEqual";

const MeetingMain = (props) => {
  // console.log(
  //   "%cRenders display content",
  //   "background-color: yellow; color: red",
  //   props
  // );

  const { variantTabs, tabSelected, isLoading } = props;

  const content = variantTabs.filter((item, index) => index === tabSelected);

  // if (content.length === 0 || isLoading) {

  if (content.length === 0) {
    return null;
  } else {
    return <>{content[0].tabContent}</>;
  }
};

// avoids rerendering the display content if the tab hasn't changed
const areEqual = (prevProps, nextProps) => {
  // console.log("%cLos props", "background-color: blue; color: yellow", {
  //   prevProps,
  //   nextProps,
  //   // equalTabSelected: isEqual(prevProps.tabSelected, nextProps.tabSelected),
  //   isEqualAll: isEqual(prevProps, nextProps),
  //   isEqualTab: isEqual(prevProps.tabSelected, nextProps.tabSelected),
  //   isEqualVariants: isEqual(prevProps.variantTabs, nextProps.variantTabs),
  //   isEqualLoading: isEqual(prevProps.isLoading, nextProps.isLoading),
  // });

  // will re render,if the tab changes, isLoading changes to false and when
  // the variants are loaded.  Can't be compare variantes prev and next because
  // it triggers a change when input is made and render is executed again

  const equal =
    prevProps.tabSelected === nextProps.tabSelected &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.variantTabs.length > 0;
  // const equal = isEqual(prevProps, nextProps);
  // console.log("%cAre equal", "background-color: blue; color: white", equal);

  return equal;
};
const MemoMeetingContent = React.memo(MeetingMain, areEqual);

export default MemoMeetingContent;
