import { gql } from "@apollo/client";

export const undoArchive = gql`
  mutation undoArchive($todoID: Int!) {
    updateToDoById(input: { toDoPatch: { archivedDate: null }, id: $todoID }) {
      clientMutationId
    }
  }
`;

export const restoreArchive = gql`
  mutation restoreArchive(
    $todoID: Int!
    $todoGroupID: Int
    $restoreToBottom: Boolean
  ) {
    spToDoRestoreArchived(
      input: {
        toDoId1: $todoID
        toDoGroupId1: $todoGroupID
        restoreToBottom1: $restoreToBottom
      }
    ) {
      clientMutationId
    }
  }
`;
