import { useContext, useEffect } from "react";

// data
import { useMutation, useQuery } from "@apollo/client";
import {
  completedAction,
  meetingPossibleOwners,
} from "graphql/gqlMeetingActions";

// data
import axios from "axios";
import config from "config";
import { MeetingActionsContext } from "contexts/MeetingActionsContext.js";
import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { axiosOptions, debounce } from "functions/Common";
import cloneDeep from "lodash/cloneDeep";
import { date, object, string } from "yup";
import { tourData } from "../../utils/tourData.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { CustomCheckbox } from "components/CustomCheckbox/CustomCheckbox";
import CustomMainInput from "components/CustomMainInput/CustomMainInput.js";
import { DropDownContacts } from "components/DropDownContacts";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import MedalWithIndicator from "components/MedalWithIndicator/MedalWithIndicator.js";

// errors
import {
  ErrorHandler,
  LogErrorComponent,
  logError,
  useDataForLog,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// style
import { grayColor } from "assets/jss/material-dashboard-react.js";
import format from "date-fns/format";

const styles = {
  actionContainer: {
    display: "flex",
    width: "30px",
  },
  actionLabel: {
    color: grayColor[4],
    paddingLeft: "20px",
    paddingRight: "10px",
  },
  buttonContainer: {
    padding: "0px 1px",
    margin: "0px 1px",
    display: "flex",
    alignItems: "stretch",
    // backgroundColor: "yellow",
  },

  cardBody: {
    paddingTop: "20px",
  },
  dropDownCustomContainer: {
    minWidth: "250px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    pointerEvents: "none",
  },
  dropDownTextsContainer: {
    display: "flex",
    flexDirection: "column",
    // marginBottom: "5px",
    margin: "5px 0px 5px 15px",
  },
  dropDownMainText: {
    fontSize: "14px",
    margin: "0px",
  },
  dropDownSubText: {
    color: grayColor[3],
    fontSize: "12px",
    margin: "3px 0px 0px 0px",
    lineHeight: "10px",
  },
  objectiveNumber: {
    color: grayColor[4],
    display: "flex",
    marginBottom: "7px",
    width: "30px",
  },
  titleLabel: {
    fontWeight: 600,
  },
  titleContainer: {
    padding: "0px 0px 50px 20px",
  },
};

const useStyles = makeStyles(styles);

export default function MeetingActions(props) {
  console.log("starts meeting actions con props", props);
  const classes = useStyles();

  const { meeting, setMeeting } = useContext(MeetingContext);
  // const { variant, isOrganizer, parentEventID, statusID, meetingTypeID } =
  //   meeting;

  const { meetingActions, setMeetingActions } = useContext(
    MeetingActionsContext
  );
  const { errorState, actionsState } = meetingActions;

  const { attendees, meetingID } = props;

  // const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log

  return (
    <CardBody fullscreen className={classes.cardBody}>
      <ErrorBoundary
        FallbackComponent={(error) => (
          <LogErrorComponent
            error={error}
            data={{
              errorLevel: "fatal",
              generalData: { ...generalData },
              otherData: { meetingActions },
            }}
          />
        )}
      >
        <MeetingActionsMain />
      </ErrorBoundary>
      {errorState && (
        <ErrorHandler
          error={errorState.error}
          data={{
            errorLevel: errorState.data.errorLevel,
            generalData: { ...generalData },
            otherData: { ...errorState.data.otherData },
          }}
          errorExplanation={errorState.errorExplanation} // only for dialog, not error
          // errorClear={() =>
          //   // setErrorState()
          //   setMeetingActions({
          //     ...meetingActions,
          //     errorState: null,
          //   })
          // }
        />
      )}
      {/* {
        <pre>
          {JSON.stringify(
            {
              state: actionsState[0],

              // dueDate: actionsState.dueDate,
            },
            null,
            2
          )}
        </pre>
      } */}
      {/* <Button
        color="primary"
        onClick={() => setUpdateInitialData(!updateInitialData)}
      >
        refresh
      </Button> */}
    </CardBody>
  );
}

const MeetingActionsMain = () => {
  const classes = useStyles();
  const generalData = useDataForLog(); // context data to populate error log
  const { meetingActions, setMeetingActions, possibleOwnerListRef } =
    useContext(MeetingActionsContext);

  console.log(
    "%cMeetingActionsMain",
    "background-color: blue; color: white",
    meetingActions
  );
  try {
    // const [meetingList, setMeetingList] = useState([]);

    const { session, setSession } = useContext(SessionContext);
    const { userID, firstUse } = session;

    const { meeting, setMeeting } = useContext(MeetingContext);
    const { meetingID } = meeting;

    const { actionsState, blankAction, blankActionWithNewID, refreshInfo } =
      meetingActions;

    // const blankAction = {
    //   responsibleDesc: "",
    //   responsibleEmail: "",
    //   meetingActionId: 1,
    //   dueDate: null,
    //   desc: "",
    //   meetingId: meetingID,
    //   list: attendees,
    //   fromPreviousMeeting: false,
    //   completed: false,
    //   deleteDate: null,
    //   errors: {},
    // };

    // const [updateInitialData, setUpdateInitialData] = useState(false);

    // const [actionsState, setActionsState] = useState([blankAction]);

    const { loading, data, error, refetch } = useQuery(meetingPossibleOwners, {
      variables: { meetingID: meetingID },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      skip: firstUse,
    });

    // const blankActionWithNewID = (initialArray) => {
    //   // let maxID = 1;
    //   const maxID = initialArray.reduce((group, item, index) => {
    //     // console.log("group", group);
    //     // console.log("item", item);

    //     if (Object.keys(group).length === 0 || item.value > group.value) {
    //       // return item;
    //       group = item.meetingActionId;
    //     }
    //     return group;
    //   }, 0);

    //   const blankItem = { ...blankAction, meetingActionId: maxID + 1 };
    //   // console.log("blankItem", blankItem);
    //   return blankItem;
    //   // return blankItem;
    // };

    useEffect(() => {
      try {
        // loads info if there's data
        if (data !== undefined) {
          // setMeetingList(data.spMeetingPossibleOwners.nodes[0]);
          // setMeetingActions({
          //   ...meetingActions,
          //   possibleOwnerListRef: data.spMeetingPossibleOwners.nodes[0],
          // });

          possibleOwnerListRef.current = data.spMeetingPossibleOwners.nodes[0];
        }
      } catch (error) {
        logError(error, { generalData: { ...generalData }, otherData: data });
      }
    }, [data]);

    useEffect(() => {
      if (firstUse) {
        const tourDummyData = tourData("actions");
        console.log(
          "%cset actions state 1",
          "background-color: orange; color: white",
          tourDummyData
        );

        setMeetingActions({
          ...meetingActions,
          actionsState: tourDummyData,
        });
        return;
      }

      // console.log("----------------------use effect on load");
      const options = axiosOptions({
        url: `${config.url}/api/meeting-info`,
        method: "get",
        params: {
          meetingID: meetingID,
          origin: "actions",
        },
      });

      axios(options)
        .then(async (response) => {
          console.log("la respuesta es", response.data.info);
          const data = response.data.info.map((item) => ({
            ...item,
            originalResponsibleDesc: item.responsibleDesc,
            modifying: false, // will be used when field blur to perform validations before saving to db
          }));

          // loads a blank line when there are no previous actions.  Otherwise, loads the meeting actions available
          if (data.length === 0) {
            // setActionsState([blankAction]);
            console.log(
              "%cset actions state 2",
              "background-color: orange; color: white",
              [blankAction]
            );

            setMeetingActions({
              ...meetingActions,
              actionsState: [blankAction],
            });
          } else {
            // adds a blank record and assigns the count + 1 as the new meetingActionId

            // setActionsState([
            //   ...data,
            //   blankActionWithNewID(data),
            // ]);
            console.log(
              "%cset actions state 3",
              "background-color: orange; color: white",
              [...data, blankActionWithNewID(data)]
            );

            setMeetingActions({
              ...meetingActions,
              actionsState: [...data, blankActionWithNewID(data)],
            });
          }

          // setIsLoading(false);
        })
        .catch((error) => {
          // setErrorState({
          //   error: error,
          //   errorExplanation: "Could not load the actions",
          //   data: {
          //     errorLevel: "fatal",
          //     otherData: { axiosOptions: options },
          //   },
          // });
          setMeetingActions({
            ...meetingActions,
            errorState: {
              error: error,
              errorExplanation: "Could not load the actions",
              data: {
                errorLevel: "fatal",
                otherData: { axiosOptions: options },
              },
            },
          });
        });
    }, [refreshInfo]);

    // console.log("el action state es", actionsState);

    // const ownerDropdownList = (inputValue, index) => {
    //   try {
    //     // in case the input value, replace by empty string

    //     const filteredValues = meetingList.filter(
    //       (item) =>
    //         (item.name || "")
    //           .toLowerCase()
    //           .includes(inputValue.toLowerCase()) ||
    //         (item.email || "")
    //           .toLowerCase()
    //           .includes(inputValue.toLowerCase())
    //     );

    //     return filteredValues;
    //   } catch (error) {
    //     logError(error, {
    //       generalData: { ...generalData },
    //       otherData: { meetingList, inputValue },
    //     });
    //   }
    // };

    if (error) {
      return (
        <ErrorHandler
          error={error}
          errorClear={() =>
            // setErrorState()
            setMeetingActions({
              ...meetingActions,
              errorState: null,
            })
          }
          retryFunction={() => refetch()}
          data={{
            errorLevel: "fatal",
            generalData: { ...generalData },
            otherData: { message: error.message, meetingID: meetingID },
          }}
        />
      );
    }

    if (firstUse || (data && data.spMeetingPossibleOwners)) {
      const sections = [
        { id: 1, desc: "Actions from previous meeting" },
        { id: 2, desc: "New actions from this meeting" },
      ];

      const hasPreviousActions = actionsState.findIndex(
        (item) => item.fromPreviousMeeting === true
      );
      // console.log("hasPreviousActions", hasPreviousActions);

      // creates the sections from this and from previouw meeting
      return sections.map((section) => {
        if (
          (section.id === 1 && hasPreviousActions !== -1) ||
          section.id === 2
        ) {
          return (
            <MeetingActionsSection
              key={section.id}
              section={section}
              hasPreviousActions={hasPreviousActions}
            />
          );
          // return (
          //   <div key={title.id} className={classes.titleContainer}>
          //     {hasPreviousActions !== -1 && (
          //       <h4 className={classes.titleLabel}>{title.desc}</h4>
          //     )}

          //     <MeetingActionsHeader title={title} />
          //     <MeetingActionsLine
          //       meetingID={meetingID}
          //       title={title}
          //       actionsState={actionsState}
          //       setActionsState={setActionsState}
          //       ownerDropdownList={ownerDropdownList}
          //       errorState={errorState}
          //       setErrorState={setErrorState}
          //       blankActionWithNewID={blankActionWithNewID}
          //     />
          //   </div>
          // );
        }
      });
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: { meetingActions },
    });
  }
};

const MeetingActionsSection = ({ section, hasPreviousActions }) => {
  const classes = useStyles();

  // const { meeting, setMeeting } = useContext(MeetingContext);
  // const { meetingID, meetingTypeID } = meeting;

  return (
    <div className={classes.titleContainer}>
      {hasPreviousActions !== -1 && (
        <h4 className={classes.titleLabel}>{section.desc}</h4>
      )}

      <MeetingActionsHeader section={section} />

      <MeetingActionsLine section={section} />
    </div>
  );
};

const MeetingActionsHeader = ({ section }) => {
  const classes = useStyles();
  const { meeting, setMeeting } = useContext(MeetingContext);
  const { meetingTypeID } = meeting;

  return (
    <GridContainer spacing={0} direction="row" style={{ maxWidth: "1200px" }}>
      {/* ----------- action ----------------- */}
      <GridItem xs={6}>
        <div className={classes.actionContainer}>
          <h6 className={classes.actionLabel}>Action</h6>
          {section.id === 2 && meetingTypeID !== 2 && (
            <MedalWithIndicator variant="reward" size={20} indicator="1" />
          )}
        </div>
      </GridItem>
      {/* ----------- responsible ----------------- */}
      <GridItem xs={3}>
        {/* <div className={classes.titleContainer}> */}
        <h6 className={classes.actionLabel}>Owner</h6>
        {/* </div> */}
      </GridItem>
      {/* ----------- due date ----------------- */}
      <GridItem xs={2}>
        {/* <div className={classes.titleContainer}> */}
        <h6 className={classes.actionLabel}>Due date</h6>
        {/* </div> */}
      </GridItem>
      {/* ----------- created data ----------------- */}
      {/* <GridItem xs={2}>
    <h6 className={classes.actionLabel}>Created date </h6>
  </GridItem> */}
      {/* ----------- completed ----------------- */}
      {section.id === 1 && (
        <GridItem xs={1} style={{ display: "flex", justifyContent: "center" }}>
          <h6 className={classes.actionLabel}>Completed</h6>
        </GridItem>
      )}
    </GridContainer>
  );
};

const MeetingActionsLine = (props) => {
  const generalData = useDataForLog(); // context data to populate error log

  const {
    // actionsState,
    // setActionsState,
    // ownerDropdownList,
    // meetingID,
    section,
    // errorState,
    // setErrorState,
    // blankActionWithNewID,
  } = props;
  const { session, setSession } = useContext(SessionContext);
  const { userID, firstUse } = session;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { isOrganizer, meetingID, parentEventID, statusID } = meeting;

  const { meetingActions, setMeetingActions, possibleOwnerListRef } =
    useContext(MeetingActionsContext);
  const {
    actionsState,
    blankActionWithNewID,
    ownerDropdownList,
    possibleOwnerList,
  } = meetingActions;

  const updateField = (
    updatedProperties,
    index,
    removeError = true,
    withDebounce = false,
    saveToDb = true
  ) => {
    try {
      console.log(
        "%cupdateField starts with",
        "background-color: red; color: white",
        {
          updatedProperties,
          index,
          removeError,
          withDebounce,
          saveToDb,
          actionsState,
        }
      );

      console.log("updatedProperties", updatedProperties);
      let newActions = cloneDeep(actionsState);

      const lastIndex = newActions.length - 1;
      newActions = newActions.reduce((group, item, index2) => {
        console.log("-------------------info item loop-------------------", {
          group: group,
          desc: item.desc,
          toUpdate: updatedProperties,
        });

        let newItem;

        if (index === index2) {
          console.log("indexes", {
            last: lastIndex,
            current: index2,
          });
          console.log(
            "%cdue date length",
            "background-color: blue; color: white",
            updatedProperties?.dueDate === null
          );

          // if this conditions are met, each line will be included, otherwise removed
          if (
            // lastIndex !== index2 &&
            updatedProperties?.desc?.length > 0 ||
            updatedProperties?.responsibleDesc?.length > 0 ||
            updatedProperties?.responsibleEmail?.length > 0 ||
            updatedProperties?.deleteDate?.length > 0 ||
            // updatedProperties?.dueDate?.length > 0 ||
            // if removing the responsible but there's a description
            (item.desc.length > 0 &&
              updatedProperties?.responsibleDesc?.length === 0) ||
            // if removing the responsible but there's a description
            (item.desc.length > 0 &&
              updatedProperties?.responsibleEmail?.length === 0) ||
            // if removing the due date but there's a description
            (item.desc.length > 0 && updatedProperties?.dueDate === null) ||
            // if changing the due date and there's a description
            (item.desc.length > 0 && updatedProperties?.dueDate?.length > 0) ||
            // if the desc is removed from a line that's not the last line or the line before, adds the line
            (updatedProperties?.desc?.length === 0 &&
              index2 <= lastIndex - 2) ||
            // if the last line is empty and the if current line selected is before the last and it's also empty too, don't add the line
            (newActions[lastIndex].desc.length !== 0 &&
              updatedProperties?.desc?.length !== 0 &&
              lastIndex <= index2 - 1)
          ) {
            newItem = {
              ...item,
              ...updatedProperties,
              errors: removeError === true ? undefined : item.error,
            };

            console.log(
              `%c#: ${index2 + 1} updates item selected`,
              "background-color: blue; color: white",
              newItem
            );

            group.push(newItem);
          } else {
            console.log(
              `%cLine #: ${index2 + 1} don't add item selected`,
              "background-color: red; color: white"
            );
          }
        } else {
          // evaluates if the other lines are empty.  If so, remove them and only keeps the ones with a description
          if (
            item.desc?.length > 0 ||
            item.responsibleDesc?.length > 0 ||
            item.responsibleEmail?.length > 0 ||
            // if a line already has an empty desc but it's not the last line, adds the line
            (item.desc.length === 0 && lastIndex !== index2)
          ) {
            newItem = {
              ...item,
              errors: removeError === true ? undefined : item.error,
            };
            console.log(
              `%cLine #: ${index2 + 1} updates item not selected`,
              "background-color: green; color: white",
              newItem
            );

            group.push(newItem);
          } else {
            console.log(
              `%cLine #: ${index2 + 1} don't add item not selected`,
              "background-color: red; color: white"
            );
          }
        }

        // }
        return group;
      }, []);

      //--------------------------------- process array for state ---------------------------------------------
      let newActionsClone1 = cloneDeep(newActions);
      // removes the deleted line only for state (not used to update db)
      const newActionsForState = newActionsClone1.filter(
        (item) => item.deleteDate === null
      );
      console.log(
        "%cset actions state 4",
        "background-color: orange; color: white",
        [...newActionsForState, blankActionWithNewID(newActionsForState)]
      );
      // setActionsState([
      //   ...newActionsForState,
      //   blankActionWithNewID(newActionsForState),
      // ]);
      setMeetingActions({
        ...meetingActions,
        actionsState: [
          ...newActionsForState,
          blankActionWithNewID(newActionsForState),
        ],
      });

      console.log("new actions after reduce", newActions);

      //--------------------------------- process array for db update ---------------------------------------------
      let canUpdateDb = false;
      let newActionsClone2 = cloneDeep(newActions);

      // removes some properties in preparation for info required for update
      const newActionsForUpdate = newActionsClone2.map((item) => {
        if (
          !canUpdateDb &&
          item.desc.length > 0
          // && item.responsibleEmail.length > 0
        ) {
          canUpdateDb = true;
        }

        return {
          ...item,

          meetingId: item.meetingId,
          desc: item.desc,
          responsibleEmail: item.responsibleEmail,
          responsibleDesc: item.responsibleDesc,
          dueDate:
            item.dueDate === null ? null : item?.dueDate?.substring(0, 10),
          meetingActionId: item.meetingActionId,
          deleteDate: item.deleteDate === null ? null : item.deleteDate,
          modifying: canUpdateDb && saveToDb === true ? false : item.modifying,
          list: undefined,
          errors: undefined,
          originalResponsibleDesc: undefined,
          __typename: undefined,
        };
      });

      // if there were previously records and now, all were removed, allows to update db
      if (actionsState.length - 1 > newActionsClone2.length) {
        canUpdateDb = true;
      }

      console.log(
        "%cnewActionsForUpdate",
        "background-color: red; color: white",
        { newActionsForUpdate, canUpdateDb, saveToDb }
      );

      // console.log("requires update", { canUpdateDb, saveToDb });
      // don't send update to db if the action is empty or the responsible
      if (canUpdateDb === true && saveToDb === true) {
        const options = axiosOptions({
          url: `${config.url}/api/meeting-save-encrypted`,
          method: "post",
          data: {
            meetingID: meetingID,
            parentEventID: parentEventID,
            actions: newActionsForUpdate,
            userID: userID,
            statusID: statusID,
          },
        });

        const executeAxios = () => {
          axios(options)
            .then(async (response) => {
              console.log(
                "-------------------------------------------db update --------------------------------"
              );
            })
            .catch((error) => {
              logError(error, {
                errorLevel: "critical",
                generalData: { ...generalData },
                otherData: { axiosOptions: options },
              });
            });
        };

        // debounce delays the execution of saving
        if (withDebounce === false) {
          executeAxios();
        } else {
          debounce(() => executeAxios());
        }
      }
    } catch (error) {
      console.log(
        "%cset actions state 5",
        "background-color: orange; color: white",
        actionsState
      );
      setMeetingActions({
        ...meetingActions,
        errorState: {
          error: error,
          errorExplanation: "Action could not be updated",
          data: {
            errorLevel: "critical",
            otherData: {
              updatedProperties: updatedProperties,
              index: index,
              actionsState: actionsState,
            },
          },
        },
      });

      // setErrorState({
      //   error: error,
      //   errorExplanation: "Action could not be updated",
      //   data: {
      //     errorLevel: "critical",
      //     otherData: {
      //       updatedProperties: updatedProperties,
      //       index: index,
      //       actionsState: actionsState,
      //     },
      //   },
      // });
    }
  };

  const [completedActionMut] = useMutation(completedAction, {
    onCompleted: () => {
      // updates the actions state
    },
  });

  // const schema = object().shape({
  //   responsibleEmail: string()
  //     .email("Select from the list or type an email (action not saved)")
  //     .required("A responsible is required (action not saved)"),
  //   desc: string().required(
  //     "Action description is required (action not saved)"
  //   ),
  //   dueDate: date()
  //     .nullable()

  //     .test("is-greater", "Date can't be in the past", function (newDate) {
  //       console.log("el valor es", newDate);
  //       const daysDifference = differenceInCalendarDays(newDate, new Date());

  //       // evaluates if the date is in the future only when status is draft or confirmed.
  //       // when the meeting has started, start date can be in the past
  //       return daysDifference >= 0;
  //     }),
  // });

  return actionsState.map((item, index) => {
    // console.log("dates", {
    //   originalDate: item.dueDate,
    //   newDate: new Date(item.dueDate),
    //   formatted: format(new Date(item.dueDate), "yyyy-MM-dd"),
    //   parseISO: format(parseISO(item.dueDate), "yyyy-MM-dd"),
    // });

    return (
      <div key={index}>
        {((section.id === 1 && item.fromPreviousMeeting) ||
          (section.id === 2 && !item.fromPreviousMeeting)) && (
          <GridContainer
            spacing={2}
            // direction="row"
            alignItems="center"
            style={{
              maxWidth: "1200px",
            }}
          >
            {/* ----- action ------ */}
            <GridItem xs={6}>
              {/* {process.env.NODE_ENV === "development" && <h5>{item.id}</h5>} */}
              <CustomMainInput
                disabled={
                  !isOrganizer ||
                  [4, 5].includes(statusID) ||
                  item.fromPreviousMeeting
                }
                placeholder="Enter action description"
                // height="80px"
                value={item.desc}
                onChange={(event) => {
                  console.log("onChange event", event.target.value);

                  updateField(
                    { desc: event.target.value, modifying: true }, // updatedProperties
                    index,
                    false, // remove error
                    true, // withDebounce
                    true // saveToDb
                  );
                }}
                onBlur={(value) => {
                  console.log("executes on blur 2");

                  console.log("item selected", {
                    responsible: item.responsibleDesc,
                    desc: item.desc,
                  });

                  if (item.modifying === true) {
                    console.log("starts validation", item);
                    const obj = {
                      responsibleEmail: item.responsibleDesc,
                      actionDesc: item.desc,
                    };
                    const yupObj = object().shape({
                      // responsibleEmail: string().required(
                      //   "A responsible is required"
                      // ),
                      actionDesc: string().required(
                        "Action description is required (action not saved)"
                      ),
                    });
                    yupObj
                      .validate(obj)
                      .then(function (value) {
                        // if validation is successful
                        console.log("successful validation");
                      })
                      .catch(function (err) {
                        console.log("el error es", JSON.stringify(err));
                        // if there's a validation error
                        const newArray = actionsState.map((item, index2) => {
                          if (index2 === index) {
                            return {
                              ...item,
                              errors: {
                                ...item.errors,
                                responsibleDesc: err.message,
                              },
                            };
                          } else {
                            return item;
                          }
                        });
                        // setActionsState(newArray);
                        console.log(
                          "%cset actions state 7",
                          "background-color: orange; color: white",
                          newArray
                        );
                        setMeetingActions({
                          ...meetingActions,
                          actionsState: newArray,
                        });
                      });
                  } else {
                    console.log("not modifying");
                  }
                }}
                variant="input"
              />
            </GridItem>

            {/* ----- owner ------ */}
            <GridItem xs={3}>
              <CustomMainInput
                // height="80px"
                // disabled
                disabled={
                  !isOrganizer ||
                  [4, 5].includes(statusID) ||
                  item.desc.length === 0 ||
                  item.fromPreviousMeeting
                }
                // dropDownList={
                //   firstUse ? null : ownerDropdownList(item.responsibleDesc, 0)
                // }
                dropDownList={
                  firstUse
                    ? null
                    : ownerDropdownList(
                        item.responsibleDesc,
                        possibleOwnerListRef.current
                      )
                }
                sendInfoToParent={(info) => {
                  console.log(
                    "%csendinfoToParent",
                    "background-color: green; color: white",
                    {
                      item: item,
                      info: info,
                      original: {
                        desc: item.responsibleDesc,
                        email: item.responsibleEmail,
                      },
                    }
                  );
                  // if user pressed escape, returns to original state
                  if (info === null) {
                    updateField(
                      {
                        responsibleDesc: item.originalResponsibleDesc,
                        modifying: false, // returns to original state to avoid triggering validation
                      },
                      index
                    );
                  }
                }}
                placeholder={
                  item.desc.length === 0 ? "" : "Enter name or email"
                }
                dropDownInputRender={(item) => (
                  <DropDownContacts
                    {...{
                      name: item.name,
                      email: item.email,
                      imagePath: item.imagePath,
                    }}
                  />
                )}
                error={actionsState[index].errors?.responsibleDesc}
                onSelected={(item) => {
                  console.log("onSelected", item);
                  updateField(
                    {
                      responsibleDesc: item.name || item.email,
                      responsibleEmail: item.email,
                      modifying: false,
                    },
                    index
                  );
                }}
                // onBlur={(value) => {
                //   console.log(
                //     "%cBlur in owner  with selected item is:",
                //     "background-color: green; color: white",
                //     item
                //   );

                //   if (item.modifying === true) {
                //     // console.log("starts validation", item);
                //     console.log(
                //       "%cstarts validation in owner blur",
                //       "background-color: lightgreen; color: green"
                //     );

                //     const obj = {
                //       responsibleEmail: item.responsibleDesc,
                //     };
                //     const yupObj = object().shape({
                //       responsibleEmail: string()
                //         .email(
                //           "Select from the list or type an email (action not saved)"
                //         )
                //         .required(
                //           "A responsible is required (action not saved)"
                //         ),
                //     });
                //     yupObj

                //       .validate(obj)
                //       .then(function (value) {
                //         // if validation is successful
                //         console.log("successful validation");
                //         updateField(
                //           {
                //             responsibleDesc: item.responsibleDesc,
                //             responsibleEmail: item.responsibleDesc,
                //             modifying: false,
                //           },
                //           index,
                //           true, //remove error default
                //           false, //withDebounce default
                //           true // saveToDb default
                //         );
                //       })
                //       .catch(function (err) {
                //         console.log("el error es", JSON.stringify(err));
                //         // if there's a validation error
                //         const newArray = actionsState.map((item, index2) => {
                //           if (index2 === index) {
                //             return {
                //               ...item,
                //               errors: {
                //                 ...item.errors,
                //                 responsibleDesc: err.message,
                //               },
                //             };
                //           } else {
                //             return item;
                //           }
                //         });
                //         // setActionsState(newArray);
                //         setMeetingActions({
                //           ...meetingActions,
                //           actionsState: newArray,
                //         });
                //       });
                //   } else {
                //     console.log(
                //       "%cnot validating in owner blur because not modifying",
                //       "background-color: red; color: green"
                //     );
                //   }
                // }}
                onChange={(value) => {
                  console.log(
                    "%cowner onChange",
                    "background-color: yellow; color: red",
                    { value, item }
                  );

                  // displays list of filtered matching items
                  ownerDropdownList(value, possibleOwnerListRef.current);

                  const obj = {
                    responsibleEmail: value,
                  };
                  const yupObj = object().shape({
                    responsibleEmail: string().email(
                      "Select from the list or type an email (action not saved)"
                    ),
                    // .required("A responsible is required (action not saved)"),
                  });
                  yupObj

                    .validate(obj)
                    .then(function () {
                      // if validation is successful
                      console.log("successful validation on change");

                      updateField(
                        {
                          responsibleDesc: value,
                          responsibleEmail: value,
                          modifying: true,
                        },
                        index,
                        true, // remove error
                        true, // withDebounce
                        true // saveToDb
                      );
                    })
                    .catch(function (err) {
                      // console.log("el error es", JSON.stringify(err));
                      // if there's a validation error
                      const newArray = actionsState.map((item, index2) => {
                        if (index2 === index) {
                          return {
                            ...item,
                            responsibleDesc: value,
                            responsibleEmail: value,
                            errors: {
                              ...item.errors,
                              responsibleDesc: err.message,
                            },
                          };
                        } else {
                          return item;
                        }
                      });

                      console.log(
                        "%cnew Array",
                        "background-color: orange; color: white",
                        newArray
                      );
                      console.log(
                        "%cset actions state 9",
                        "background-color: orange; color: white",
                        newArray
                      );
                      // setActionsState(newArray);
                      setMeetingActions({
                        ...meetingActions,
                        actionsState: newArray,
                      });
                    });

                  // ownerDropdownList(value, possibleOwnerListRef.current);
                  // updateField(
                  //   { responsibleDesc: value, modifying: true },
                  //   index,
                  //   true, // remove error
                  //   false, // withDebounce
                  //   false // saveToDb
                  // );
                }}
                onFocus={(inputValue) => {
                  ownerDropdownList(inputValue, possibleOwnerListRef.current);
                }}
                // value={item.responsibleDesc || item.responsibleEmail}
                value={item.responsibleDesc}
                variant="inputDropdown"
              />
            </GridItem>
            {/* ----- due date ------ */}
            <GridItem xs={2}>
              <CustomMainInput
                disabled={
                  !isOrganizer ||
                  [4, 5].includes(statusID) ||
                  item.desc.length === 0 ||
                  item.fromPreviousMeeting
                }
                clearable // adds clear icon at the end
                // height="80px"
                onChange={(newDate) => {
                  // console.log(
                  //   "%cDate",
                  //   "background-color: green; color: white",
                  //   {
                  //     date: newDate,
                  //     format: format(newDate, "yyyy-MM-dd"),
                  //     convertLocaltoUTC: convertLocalToUTC(
                  //       "2023-09-01T00:00:00"
                  //     ),
                  //   }
                  // );
                  console.log(
                    "%cnew date",
                    "background-color: yellow; color: red",
                    newDate
                  );

                  const obj = {
                    dueDate: newDate,
                  };
                  const yupObj = object().shape({
                    startDate: date()
                      .nullable()

                      .test(
                        "is-greater",
                        "Date can't be in the past",
                        function () {
                          console.log("el valor es", newDate);
                          const daysDifference = differenceInCalendarDays(
                            newDate,
                            new Date()
                          );

                          // evaluates if the date is in the future only when status is draft or confirmed.
                          // when the meeting has started, start date can be in the past
                          return daysDifference >= 0;
                        }
                      ),
                  });
                  yupObj
                    .validate(obj)
                    .then(function (value) {
                      // if validation is successful
                      console.log("successful validation");
                      updateField(
                        {
                          dueDate: `${format(newDate, "yyyy-MM-dd")}T00:00:00`,

                          // dueDate: "2023-09-03",
                        },

                        index
                      );
                    })
                    .catch(function (err) {
                      console.log("el error es", JSON.stringify(err));
                      // if there's a validation error
                      const newArray = actionsState.map((item, index2) => {
                        if (index2 === index) {
                          return {
                            ...item,

                            errors: {
                              ...item.errors,
                              dueDate: err.message,
                            },
                          };
                        } else {
                          return item;
                        }
                      });
                      console.log(
                        "%cset actions state 10",
                        "background-color: orange; color: white",
                        newArray
                      );
                      // setActionsState(newArray);
                      setMeetingActions({
                        ...meetingActions,
                        actionsState: newArray,
                      });
                    });
                }}
                // error={item.errors?.dueDate}
                error={actionsState[index].errors?.dueDate}
                value={item.dueDate}
                // value={
                //   item.dueDate === null
                //     ? ""
                //     : format(item.dueDate, "yyyy-MM-dd")
                // }
                onDeleteDate={() => {
                  // console.log(
                  //   "ljñlkjñlkjñlkj",
                  //   actionsState[index]
                  // );
                  // const newActionsState = [...actionsState];
                  // newActionsState[index] = {
                  //   ...newActionsState[index],
                  //   dueDate: null,
                  // };
                  // setActionsState(newActionsState);

                  // console.log("new item", newActionsState);

                  updateField(
                    {
                      dueDate: null,
                    },

                    index
                  );
                }}
                variant="inputDate"
              />
            </GridItem>

            {/* ----- created date ------ */}
            {/* <GridItem xs={2}>
        <CustomMainInput
          height="40px"
          // value={format(new Date(item.dueDate), "dd/MM/yyyy")}
          value={item.fromPreviousMeeting}
          variant="noInput"
        />
      
      </GridItem> */}

            {/* ----- completed ------ */}
            {section.id === 1 && (
              <GridItem xs={1} row alignItems="center" justifyContent="center">
                {/* <div className={classes.buttonContainer}> */}
                <CustomCheckbox
                  disabled={!isOrganizer || [5].includes(statusID)}
                  value={item.completed}
                  onChange={() => {
                    const newArray = actionsState.map((item2) => {
                      if (item2.id === item.id) {
                        console.log("los datos son", {
                          item2: item2.id,
                          item: item.id,
                        });
                        return {
                          ...item2,
                          completed: !item.completed,
                        };
                      } else {
                        return item2;
                      }
                    });
                    console.log(
                      "%cset actions state 1",
                      "background-color: orange; color: white",
                      newArray
                    );
                    // setActionsState(newArray);
                    setMeetingActions({
                      ...meetingActions,
                      actionsState: newArray,
                    });

                    completedActionMut({
                      variables: {
                        meetingActionID: item.id,
                        completedDate: item.completed ? null : new Date(),
                      },
                    });
                  }}
                />
                {/* </div> */}
              </GridItem>
            )}

            {/* ----- delete button ------ */}
            {section.id === 2 &&
              isOrganizer &&
              section.desc !== "" &&
              actionsState.length - 1 !== index && (
                <GridItem
                  xs={1}
                  row
                  alignItems="center"
                  justifyContent="center"
                >
                  {/* <div className={classes.buttonContainer}> */}
                  <Button
                    disabled={!isOrganizer || [5].includes(statusID)}
                    size="md"
                    color="danger"
                    justIcon
                    onClick={() =>
                      // deleteAction({ id: item.id, index: index })

                      updateField(
                        {
                          meetingActionID: null,
                          deleteDate: format(new Date(), "yyyy-MM-dd"),
                        },

                        index,
                        true, // remove error
                        true, // withDebounce
                        true // save to db
                      )
                    }
                  >
                    <DeleteOutlineIcon />
                    {/* <Hidden mdUp implementation="css">
  <p className={classes.linkText}>Perfile</p>
</Hidden> */}
                  </Button>
                  {/* </div> */}
                </GridItem>
              )}
          </GridContainer>
        )}
      </div>
    );
  });
};
