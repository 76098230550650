import React, {
  memo,
  useContext,
  useEffect,
  useState,
  useMemo,
  useImperativeHandle,
  useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DragHandle,
} from "react-beautiful-dnd";

// material ui
import CircularProgress from "@material-ui/core/CircularProgress";

import Checkbox from "@material-ui/core/Checkbox";
// data

import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { deleteAgendaItem, updateEndDate } from "graphql/gqlMeetingContents";
import { meetingRating } from "graphql/gqlMeeting";
import { MeetingContext } from "contexts/MeetingContext";
import { SessionContext } from "contexts/SessionContext";
import { ContentContext } from "contexts/ContentContext";

// custom hooks
import useSaveAgendaItem from "functions/useSaveAgendaItem.js";
import useSaveAgenda from "functions/useSaveAgenda";

import {
  axiosOptions,
  addMinutesToDate,
  formatDate,
  numberToTime,
  debounce,
} from "functions/Common";
import { Formik } from "formik";
import { object, string } from "yup";
import axios from "axios";
import config from "config";
import differenceInSeconds from "date-fns/differenceInSeconds";
import add from "date-fns/add";
import sortBy from "lodash/sortBy";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";
import { tourData } from "utils/tourData";

// core components
import CustomDialog from "components/CustomDialog/CustomDialog";
import { CustomCheckbox } from "components/CustomCheckbox/CustomCheckbox";

import MedalWithIndicator from "components/MedalWithIndicator/MedalWithIndicator";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import Button from "components/CustomButtons/Button.js";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import SideMenu from "components/SideMenu/SideMenu";
// errors
import {
  logError,
  ErrorHandler,
  useDataForLog,
  LogErrorComponent,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// style
import format from "date-fns/format";
import { sentenceCase } from "functions/Common";
import { makeStyles } from "@material-ui/core/styles";
import {
  grayColor,
  defaultFont,
  boxShadow,
  transition,
  primaryColor,
  dangerColor,
  infoColor,
} from "assets/jss/material-dashboard-react";
import { HighlightRounded, SmokeFree } from "@material-ui/icons";
// import { usePrompt } from "functions/usePrompt";

const styles = {
  agendaFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    padding: "10px 15px 10px 10px",
    // backgroundColor: "yellow",
    borderTop: `1px solid ${grayColor[6]}`,
    // marginRight: "10px",
  },

  agendaFooterTotalDurationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  agendaFooterEndTimeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  agendaFooterLabel: {
    margin: "0px",
  },
  agendaFooterValue: {
    fontWeight: 600,
    margin: "0px 0px 0px 10px ",
  },
  agendaItemInput: {
    ...defaultFont,
    fontSize: "14px",
    fontWeight: 500,
    border: `0px solid ${grayColor[8]}`,
    borderRadius: "5px",
    backgroundColor: primaryColor[10],
    padding: "5px",
    "&::placeholder": {
      color: grayColor[5],
    },
    marginRight: "8px",
  },
  agendaItemHandle: {
    display: "flex",
    alignItems: "center",
    color: grayColor[5],
    fontSize: "16px",
    marginRight: "5px",
    width: "13px",
    // backgroundColor: "green",
  },
  agendaItemMainContainer: {
    display: "flex",
    // backgroundColor: "orange",
    width: "100%",

    flexDirection: "row",
    alignItems: "stretch",
    marginBottom: "10px",
    // pointerEvents: "none",
  },
  agendaItemSubContainer: {
    // userSelect: "none",
    // ...boxShadow,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: "100px",
    // margin: "5px",
    borderRadius: "7px",
    border: `1px solid ${grayColor[6]}`,
    // backgroundColor: "yellow",
    backgroundColor: "white",
    padding: "10px 0px 10px 10px",
    width: "100%",
    marginRight: "15px",
  },
  agendaItemSubContainerSelected: {
    // backgroundColor: "yellow",
    backgroundColor: primaryColor[8],
    padding: "10px 15px 10px 10px",
    marginRight: "0px",
    border: `0px solid ${grayColor[6]}`,
    borderRadius: "7px 0px 0px 7px",
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",

      // backgroundColor: "#0074D9",
      bottom: "-60px",
      height: "60px",
      width: "30px",
      // left: "0px",
      right: "0px",
      borderTopRightRadius: "30px",
      boxShadow: `0 -30px 0 0 ${primaryColor[8]}` /* This is where the magic happens! */,
    },
    "&:after": {
      content: '""',
      position: "absolute",

      // backgroundColor: "#0074D9",
      top: "-60px",
      height: "60px",
      width: "30px",
      // left: "0px",
      right: "0px",
      borderBottomRightRadius: "30px",
      boxShadow: `0 30px 0 0 ${primaryColor[8]}` /* This is where the magic happens! */,
    },
  },
  agendaItemDurationAndTimeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    // padding: "5px 7px",
    paddingRight: "15px",
    marginRight: "8px",
    // backgroundColor: "green",
    borderWidth: "0px 1px 0px 0px",
    borderStyle: "solid",
    borderColor: grayColor[5],
  },
  agendaItemDurationAndTimeContainerSelected: {
    borderColor: "white",
  },
  agendaItemTimeContainer: {
    display: "flex",
    alignItems: "center",

    // backgroundColor: grayColor[6],
    // borderWidth: "0px 1px 0px 0px",
    // borderStyle: "solid",
    // borderColor: grayColor[5],
  },
  agendaItemInputDisabledPointer: {
    pointerEvents: "none",
  },
  agendaItemInputDisabledColors: {
    backgroundColor: grayColor[8],
    color: grayColor[4],
  },
  agendaItemDurationInput: {
    width: "50px",
    textAlign: "right",
  },
  agendaItemTitleInput: {
    resize: "none",
    height: "100%",
    minWidth: "15vw",
    width: "100%",
    zIndex: 5,

    // width: "40px",
    // textAlign: "center",
  },
  agendaItemTrashContainer: {
    padding: "0px",
    width: "35px",
    // backgroundColor: "yellow",
    zIndex: 10,
  },
  agendaItemDurationLabel: {
    ...defaultFont,
    fontSize: "16px",
    fontWeight: 700,
    paddingLeft: "4px",
  },
  agendaItemTimeLabel: { width: "60px", textAlign: "center" },
  agendaItemTitleLabel: {
    minWidth: "15vw",
    width: "100%",
    paddingLeft: "10px",
    backgroundColor: grayColor[8],
    textAlign: "left",
    height: "100%",
    zIndex: 5,
    borderRadius: "5px",
    marginRight: "8px",
    // color: "white",
  },
  agendaItemVerticalBar: {
    width: "0px",
    border: `1px solid ${grayColor[4]}`,
    height: "85%",
    transform: "scaleX(0.5)",
  },

  agendaListHeader: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "lightBlue",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "15px",
    marginRight: "15px",

    // minWidth: "400px",
  },
  agendaListHeaderDurationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // backgroundColor: "green",
  },
  agendaListHeaderPendingContainer: {
    marginRight: "20px",
  },
  agendaListMainContainer: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "purple",
    flex: 2,
    overflowY: "auto",
  },
  agendaListRightItemsContainer: {
    alignItems: "end",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "2px",
    // backgroundColor: "orange",
    height: "32px", // to avoid dynamic height when right buttons appears/disappears
  },
  agendaListSubContainer1: {
    display: "flex",
    // flexDirection: "yellow",
    // backgroundColor: "yellow",
    flex: 1,
    direction: "rtl",
    padding: "25px 0px",
    overflowY: "auto",
  },

  agendaListSubContainer2: {
    flexDirection: "column",
    display: "flex",

    width: "100%",
    direction: "ltr",
  },
  agendaListTitleContainer: {
    display: "flex",
    width: "30px",
    alignItems: "center",
    // paddingBottom: "10px",
  },
  agendaListTitleLabel: {
    color: grayColor[4],
    paddingLeft: "20px",
    fontSize: "12px",
    margin: "0px",
  },
};

const useStyles = makeStyles(styles);

export const AgendaList = (props) => {
  try {
    const { content, setContent } = useContext(ContentContext);

    const classes = useStyles();
    return (
      <div className={classes.agendaListMainContainer}>
        <AgendaListHeader />
        <AgendaListContent />
        {/* <div
          style={{
            height: "200px",
            overflowY: "auto",
            backgroundColor: "lightBlue",
          }}
        >
          <pre>
            {JSON.stringify(
              {
                ids: content.agendaItems.map((item) => item.id),
                selected: content.selectedItem,

                // tempContentRef: tempContentRef.current,
              },
              null,
              2
            )}
          </pre>
        </div> */}
        {/* {isLoading === true && <h6>Saving...</h6>} */}
        <AgendaListFooter />
      </div>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
};

export const AgendaListHeader = () => {
  const classes = useStyles();

  const { session, setSession } = useContext(SessionContext);
  const { userID } = session;

  const { meeting, setMeeting, updateRating, savePendingRef } =
    useContext(MeetingContext);
  const { meetingID, meetingTypeID, parentEventID, statusID, withDurations } =
    meeting;

  const { content, setContent } = useContext(ContentContext);
  const { agendaItems, hasPreviousActions } = content;

  const [insertOnTopState, setInsertOnTopState] = useState(null);

  const { saveAgendaItem, tempContentRef } = useSaveAgendaItem();
  const { saveAgenda } = useSaveAgenda();

  const retrievePendingActions = () => {
    const options = axiosOptions({
      url: `${config.url}/api/meeting-info`,
      method: "get",
      params: {
        meetingID: meetingID || parentEventID,
        origin: "actions",
      },
    });

    axios(options)
      .then(async (response) => {
        console.log("la respuesta es", {
          response: response.data.info,
          meetingID: meetingID,
          parentEventID: parentEventID,
        });
        // console.log("CurrentContentsState", contentsState);

        const lineHeaders = ["Action: ", "Responsible: ", "Due Date: ", " "];

        let details = [];
        let id = 0;
        response.data.info.map((agendaItem, index) => {
          console.log(
            "%cagenda item #",
            "background-color: blue; color: white",
            index + 1
          );

          lineHeaders.reduce((group, headerItem, index2) => {
            console.log("group", group);
            console.log("item", headerItem);

            let lineValue;
            switch (index2) {
              case 0: // Action
                console.log(
                  "%cLoads action",
                  "background-color: green; color: white",
                  agendaItem.desc
                );

                lineValue = agendaItem.desc;
                break;

              case 1: // Responsible
                console.log(
                  "%cLoads responsibleDesc",
                  "background-color: green; color: white",
                  agendaItem.responsibleDesc
                );
                lineValue =
                  agendaItem.responsibleDesc || agendaItem.responsibleEmail;
                break;
              case 2: // Due date
                console.log(
                  "%cLoads dueDate",
                  "background-color: green; color: white",
                  agendaItem.dueDate
                );
                lineValue = formatDate(agendaItem.dueDate);
                break;
              default:
                console.log(
                  "%cLoads empty line",
                  "background-color: green; color: white"
                );
                lineValue = " ";
            }

            if (lineValue !== null) {
              id = id + 1;

              const line = {
                id: id,
                type: "paragraph",
                children: [
                  {
                    text: `${headerItem}${lineValue}`,
                    bold: index2 === 0 ? "True" : undefined,
                  },
                ],
              };

              // group.push(line);
              details.push(line);
              // group = line;
            }
            return group;
          }, []);
        });

        console.log(
          "%cDetails",
          "background-color: red; color: white",
          details
        );

        const currentAgendaItems = [...agendaItems];
        const lastMeetingActions = {
          id: uuidv4(), //temporary number. Will be updated when saved to db
          meetingID: 961,
          desc: "Actions from previous meeting",
          orderBy: 0,
          privateNotes: null,
          details: details,
          duration: 30,
          dayID: 1,
          typeID: 1,
        };

        const newAgendaItems = [lastMeetingActions];
        newAgendaItems.push(...agendaItems);

        const reorderedAgendaItems = newAgendaItems.map((item, index) => {
          return { ...item, orderBy: item.orderBy + 1 };
        });

        console.log(
          "%creorderedAgendaItems",
          "background-color: red; color: white",
          reorderedAgendaItems
        );

        saveAgendaItem({
          // optional
          desc: "Follow up actions from previous meeting",
          details: details,
          newAgendaItems: reorderedAgendaItems,
          orderBy: 0,
          updateRating: updateRating, // function to update rating
          // required
          parentEventID: parentEventID,
          meetingID: meetingID,
          content: content,
          setContent: setContent,
          operationType: "retrievePendingActions", // ???
        });
      })
      .catch((error) => {
        logError(error, {
          errorLevel: "fatal",
          generalData: { ...generalData },
          otherData: {
            params: {
              meetingID: meetingID || parentEventID,
              origin: "actions",
            },
          },
        });
      });
  };

  return (
    <div className={classes.agendaListHeader}>
      <div className={classes.agendaListTitleContainer}>
        <h4 className={classes.agendaListTitleLabel}>Agenda</h4>

        {meetingTypeID !== 2 && (
          <CustomTooltip
            tooltipText="Adding an agenda will grant 2 points towards meeting's final rating"
            placement="bottom-start"
          >
            <div>
              <MedalWithIndicator variant="reward" size={20} indicator="2" />
            </div>
          </CustomTooltip>
        )}
      </div>

      <div className={classes.agendaListRightItemsContainer}>
        {hasPreviousActions && [1].includes(statusID) && (
          <div className={classes.agendaListHeaderPendingContainer}>
            <Button
              color="primary"
              size="xs"
              // justIcon
              onClick={() => {
                retrievePendingActions();
              }}
            >
              Add pending actions
            </Button>
          </div>
        )}
        <div className={classes.agendaListHeaderDurationContainer}>
          <CustomSwitch
            title="With durations"
            disabled={![0, 1, 2, 8].includes(statusID)}
            checked={withDurations}
            onChange={() => {
              // changing the savePending will allow to trigger modal if changing tab without saving
              // if (meetingID === null) {
              //   savePendingRef.current = true;
              // }

              saveAgenda({
                withDurations: !withDurations,

                // occurrenceRepeatType: occurrenceRepeatType,

                parentEventID: parentEventID,
                // showSnackbar: showSnackbar,
                content: content,
                meeting: meeting,
                setContent: setContent,
                setMeeting: setMeeting,

                userID: userID,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const AgendaListContent = () => {
  const classes = useStyles();

  const { session, setSession } = useContext(SessionContext);
  const { userID } = session;

  const { meeting, setMeeting, updateRating } = useContext(MeetingContext);
  const { isOrganizer, meetingID, parentEventID, statusID } = meeting;

  const { content, setContent } = useContext(ContentContext);
  const {
    agendaItems,
    blankAgendaItemWithNewID,

    preventTriggerChangeRef,
    isLoading,
    meetingAgendaItemID,

    descriptionRefs,
  } = content;

  const generalData = useDataForLog(); // context data to populate error log
  const { saveAgenda } = useSaveAgenda();
  const { saveAgendaItem } = useSaveAgendaItem();

  const addNewItemRef = useRef(false);

  let cumulativeDuration = 0;
  let cumulativeDurationsArray = [];
  const onDragEnd = (result) => {
    try {
      // console.log("on drag end columns son", columns);
      console.log("onDragEnd", result);
      const { source, destination, draggableId } = result;

      // if dropping outside of droppable area
      if (!destination) {
        console.log("**************************** no destination");
        return;
      }

      const copiedItems = cloneDeep(agendaItems);

      const [removed] = copiedItems.splice(source.index, 1);

      copiedItems.splice(destination.index, 0, removed);

      // renumbers the orderBy field
      const reorderedItems = copiedItems.map((item, index) => {
        return {
          ...item,
          orderBy: index + 1,
        };
      });

      console.log(
        "%creorderedItems",
        "background-color: blue; color: lightBlue",
        { reorderedItems }
      );

      // empties the lastInputRef to avoid re focus to either title or duration in the use effect when selectedItem changes
      // lastInputRef.current = {};
      preventTriggerChangeRef.current = true;
      saveAgenda({
        agendaItems: reorderedItems,
        selectedItem: { id: parseInt(draggableId), index: destination.index },
        parentEventID: parentEventID, // required for update
        content: content, // required to update content
        meeting: meeting, // required to update content
        setContent: setContent, // required to update meeting
        setMeeting: setMeeting, // required to update meeting
        userID: userID, // required for update
      });
    } catch (error) {
      logError(error, {
        errorLevel: "fatal",
        generalData: { ...generalData },
        otherData: { result, agendaItems: agendaItems },
      });
    }
  };

  useEffect(() => {
    if (addNewItemRef.current) {
      const lastIndex = descriptionRefs.current.length - 1;
      console.log("los refs son", { descriptionRefs, lastIndex });
      descriptionRefs?.current[lastIndex]?.focus();
      addNewItemRef.current = false;
    }
  }, [content.agendaItems]);

  return (
    <div className={classes.agendaListSubContainer1}>
      <div className={classes.agendaListSubContainer2}>
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  width: "100%",
                  padding: snapshot.isDraggingOver && "10px 0px 10px 0px",
                  borderRadius: "10px",
                  backgroundColor: snapshot.isDraggingOver && grayColor[6], // color of the whole column when item is picked
                }}
                {...provided.droppableProps}
              >
                {agendaItems.map((item, index) => {
                  // Constructs an array of cumulative durations.
                  // Passing the cumulative durations to the child only passes the last calculated cumulative and not the one for every item
                  // excludes item.id that are string, hence the temp "add line"
                  // if (typeof item.id === "number") {
                  cumulativeDuration = cumulativeDuration + item.duration;
                  cumulativeDurationsArray.push(cumulativeDuration);

                  return (
                    <Draggable
                      key={item.id}
                      draggableId={String(item.id)}
                      index={index}
                    >
                      {(provided1) => {
                        // agendaItemRefs.current[index] =
                        //   "agendaItem" + index;
                        return (
                          <AgendaItem
                            cumulativeDurationsArray={cumulativeDurationsArray}
                            index={index}
                            item={item}
                            provided1={provided1}
                          />
                        );
                      }}
                    </Draggable>
                  );
                })}
                {/* <h2>I am a droppable!</h2> */}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <AddTopic addNewItemRef={addNewItemRef} />
      </div>
    </div>
  );
};

export const AgendaListFooter = () => {
  const classes = useStyles();
  const { meeting, setMeeting } = useContext(MeetingContext);
  const { startDate, withDurations } = meeting;

  const { content, setContent } = useContext(ContentContext);
  const { agendaItems } = content;

  let cumulativeDuration = 0;
  agendaItems.map((item) => {
    // avoids considering the duration of the temp "add line"
    if (typeof item.id === "number") {
      cumulativeDuration = cumulativeDuration + parseInt(item.duration);
    }
  });

  const endTime = format(
    new Date(addMinutesToDate(new Date(startDate), cumulativeDuration)),
    "h:mm aaa"
  );

  // cumulativeDurationsArray.push(cumulativeDuration);
  if (withDurations === true) {
    return (
      <div className={classes.agendaFooter}>
        {/* <div style={{ width: "130px", lineHeight: "17px" }}> */}
        <div className={classes.agendaFooterTotalDurationContainer}>
          <h4 className={classes.agendaFooterLabel}>Duration:</h4>
          <h4 className={classes.agendaFooterValue}>
            {numberToTime(cumulativeDuration * 60, 2, false)}
          </h4>
        </div>
        <div className={classes.agendaFooterEndTimeContainer}>
          <h4 className={classes.agendaFooterLabel}>End time:</h4>
          <h4 className={classes.agendaFooterValue}>{endTime}</h4>
        </div>
        {/* </div> */}
      </div>
    );
  }
};

const AddTopic = ({ addNewItemRef }) => {
  const { meeting, updateRating } = useContext(MeetingContext);
  const { isOrganizer, meetingID, parentEventID, statusID } = meeting;

  const { content, setContent } = useContext(ContentContext);
  const { agendaItems, descriptionRefs } = content;

  const { saveAgendaItem } = useSaveAgendaItem();

  if (isOrganizer && [0, 1, 2].includes(statusID)) {
    return (
      <div style={{ padding: "0px 30px" }}>
        <Button
          // disabled={statusID === 0}
          color="primary"
          fullWidth
          onClick={() => {
            addNewItemRef.current = true;

            saveAgendaItem({
              // optional
              newAgendaItems: agendaItems,
              // blankAgendaItemWithNewID: blankAgendaItemWithNewID,
              updateRating: updateRating, // function to update rating
              descriptionRefs: descriptionRefs,
              desc: "",
              // required
              parentEventID: parentEventID,
              meetingID: meetingID,
              content: content,
              setContent: setContent,
              operationType: "add",
            });
          }}
        >
          Add topic
        </Button>
      </div>
    );
  }
};

const AgendaItem = (props) => {
  // the box of the agenda item with duration, time and title
  const [modal, setModal] = useState({
    visible: false,
    name: "",
  });

  const { cumulativeDurationsArray, index, item, provided1 } = props;

  return (
    <>
      <AgendaItemDetail
        cumulativeDurationsArray={cumulativeDurationsArray}
        index={index}
        item={item}
        provided1={provided1}
        setModal={setModal}
      />
      <AgendaItemModal modal={modal} setModal={setModal} />
    </>
  );
};

const AgendaItemDetail = (props) => {
  const classes = useStyles();

  const { cumulativeDurationsArray, index, item, provided1, setModal } = props;

  const { meeting, setMeeting, updateRating } = useContext(MeetingContext);
  const { isOrganizer, statusID, withDurations } = meeting;

  const { content, setContent } = useContext(ContentContext);
  const {
    agendaItems,
    blankAgendaItemWithNewID,
    preventTriggerChangeRef,
    isLoading,
    selectedItem,
  } = content;

  const handleContainerOnClick = () => {
    // allows to click and change selected item only the item changes, is the organizer and the status is either completed, cancelled or deleted
    // on the other cases, the item selected will be controlled when focusing either the duration or the description
    if (
      selectedItem !== item.id &&
      isOrganizer &&
      [4, 5, 6].includes(statusID)
    ) {
      console.log(
        "%cfocus on min div",
        "background-color: red; color: white",
        item
      );

      setContent({
        ...content,
        selectedItem: { id: item.id, index: index },
      });
    }
  };

  return (
    <div
      ref={provided1.innerRef}
      {...provided1.draggableProps}
      className={classes.agendaItemMainContainer}
      style={{
        ...provided1.draggableProps.style,
      }}
    >
      {/* <h5>{item.id}</h5> */}
      <Handle index={index} item={item} provided1={provided1} />

      <div
        onClick={handleContainerOnClick}
        className={`${classes.agendaItemSubContainer} ${
          selectedItem.index === index && classes.agendaItemSubContainerSelected
        } ${
          selectedItem.index !== index &&
          isLoading &&
          classes.agendaItemInputDisabledPointer
        }`}
      >
        {withDurations && (
          <Duration
            index={index}
            item={item}
            cumulativeDurationsArray={cumulativeDurationsArray}
          />
        )}
        <Description item={item} index={index} />

        {/* show trash if organizer, in draft or confirmed or if not the last that should be the empty line */}
        {isOrganizer && [0, 1, 2, 8].includes(statusID) && (
          <div className={classes.agendaItemTrashContainer}>
            <Button
              // disabled={statusID === 0}
              // color={selectedItem.index === index ? "white" : "danger"}
              color={"danger"}
              justIcon
              size="md"
              onClick={() => {
                console.log("item to delete", item.id);
                setModal({
                  visible: true,
                  name: "confirmDeleteAgendaItem",
                  data: { agendaItemID: { id: item.id, index: index } }, // index will be used to delete the item from the descriptionRefs
                });
              }}
            >
              <DeleteOutlineIcon />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const Handle = (props) => {
  const classes = useStyles();
  const { index, item, provided1 } = props;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const { isOrganizer, statusID } = meeting;

  const { content, setContent } = useContext(ContentContext);
  const { agendaItems, preventTriggerChangeRef } = content;

  return (
    <div className={classes.agendaItemHandle} {...provided1.dragHandleProps}>
      {isOrganizer && [0, 1, 2, 3, 7, 8].includes(statusID) && "⠿"}
    </div>
  );
};

const Duration = (props) => {
  const classes = useStyles();
  const { cumulativeDurationsArray, index, item } = props;
  const { duration, orderBy } = item;

  const { meeting, setMeeting } = useContext(MeetingContext);
  const {
    isOrganizer,
    meetingID,
    parentEventID,
    statusID,
    startDate,
    updateRating,
  } = meeting;

  const { content, setContent } = useContext(ContentContext);

  const {
    agendaItems,
    isLoading,
    preventTriggerChangeRef,
    selectedItem,

    isSavingRef,
  } = content;

  const afterSaving = (meetingCost) => {
    console.log("%cnewItemID", "background-color: orange; color: white", {
      meetingCost,
    });

    // updateMeetingCost(meetingCost);
    setMeeting({ ...meeting, meetingCost });
    preventTriggerChangeRef.current = true; // prevents rich text editor to trigger handleChange and saving to db
  };
  const { saveAgendaItem } = useSaveAgendaItem({}, afterSaving);

  const [updateEndDateMut] = useMutation(updateEndDate);

  const handleKeyDownInputTime = async (e) => {
    // console.log("handleKeyDownInput with value", e);
    const { key } = e;
    let nextIndexCount = 0;
    // console.log("charcode", key);
    if (key === ".") {
      e.preventDefault();
    }
  };

  const handleDurationOnChange = (e) => {
    // evaluates if positioned in the temp "add line".  if so, it means there's no description yet and item hasn't been
    // created in the db yet.  Therefore, only stores duration in state but doesn't save in db.
    if (typeof selectedItem.id === "number") {
      // let durationSum = 0;
      // agendaItems.map((item, index2) => {
      //   if (index === index2) {
      //     durationSum = durationSum + parseInt(e.target.value);
      //   } else {
      //     durationSum = durationSum + item.duration;
      //   }
      // });

      // const newEndDate = addMinutesToDate(new Date(startDate), durationSum);

      // updateEndDateMut({
      //   variables: {
      //     meetingID: meetingID,
      //     endDate: newEndDate,
      //   },
      // });

      // reflect the change in duration inmediately
      let agendaItemsCopy = cloneDeep(agendaItems);
      agendaItemsCopy[index].duration = parseInt(e.target.value);

      // can't be inside saveAgendaItem as it cannot wait for debounce and db update
      setContent({
        ...content,
        agendaItems: agendaItemsCopy,
      });

      saveAgendaItem({
        // optional
        newAgendaItems: agendaItemsCopy,
        duration: e.target.value,
        orderBy: orderBy,
        // required
        parentEventID: parentEventID,
        meetingID: meetingID,
        content: content,
        setContent: setContent,
        operationType: "update",
      });
    } else {
      // update the duration in the context
      let agendaItemsCopy = cloneDeep(agendaItems);
      agendaItemsCopy[index].duration = e.target.value;
      // console.log("dddddddddd", agendaItemsCopy);

      setContent({
        ...content,
        agendaItems: agendaItemsCopy,
      });
    }
  };

  const handleDurationOnBlur = (e) => {
    // if duration is left blank, place 0 when loosing focus
    if (e.target.value === "") {
      e.preventDefault();
      console.log("value", e.target.value);
      let agendaItemsCopy = cloneDeep(agendaItems);
      agendaItemsCopy[index].duration = 0;
      // console.log("dddddddddd", agendaItemsCopy);

      setContent({
        ...content,
        agendaItems: agendaItemsCopy,
      });
    }

    // if (e.target.value === ""){

    // } else {

    // }

    //       return (  );
  };

  const handleDurationOnClick = () => {
    // lastInputRef.current = {
    //   field: "duration",
    //   index: index,
    // };

    if (selectedItem.id !== item.id) {
      console.log(
        "%cfocus on duration",
        "background-color: orange; color: white"
      );
      // this prevents the rich text editor to trigger the handleChange
      // otherwise, for example changing agenda item list would trigger saving to db
      // including when selecting an empty line
      preventTriggerChangeRef.current = true;
      if (isSavingRef.current === false) {
        setContent({
          ...content,
          selectedItem: { id: item.id, index: index },
        });
      }
    }
  };

  const timeLabel = format(
    addMinutesToDate(new Date(startDate), cumulativeDurationsArray[index]),
    "h:mm aaa"
  );

  return (
    <div
      onClick={handleDurationOnClick}
      className={`${classes.agendaItemDurationAndTimeContainer} ${
        selectedItem.index === index &&
        classes.agendaItemDurationAndTimeContainerSelected
      }`}
    >
      {/* this container forms the line with the duration and the "min" label */}

      {isOrganizer && [1, 2, 8].includes(statusID) && (
        <div className={classes.agendaItemTimeContainer}>
          <input
            // value={value.duration}
            value={duration}
            type="number"
            min={0}
            pattern="[0-9]{10}"
            // placeholder={item.duration}

            className={`${classes.agendaItemInput} ${
              classes.agendaItemDurationInput
            } ${
              selectedItem.index !== index &&
              isLoading &&
              classes.agendaItemInputDisabledColors
            }`}
            onChange={handleDurationOnChange}
            onKeyDown={handleKeyDownInputTime}
            onBlur={handleDurationOnBlur}
          />

          <h6 className={classes.agendaItemDurationLabel}>min</h6>
        </div>
      )}

      {![1, 2, 8].includes(statusID) && (
        <h6 className={classes.agendaItemDurationLabel}>{duration} min</h6>
      )}
      {/* displays the time at which every point should start */}
      {![1, 2, 8].includes(statusID) && (
        <h6 className={classes.agendaItemTimeLabel}>{timeLabel}</h6>
      )}
    </div>
  );
};

const Description = (props) => {
  const classes = useStyles();

  const { index, item } = props;
  const { desc: description, orderBy } = item;

  const { meeting, setMeeting, updateRating } = useContext(MeetingContext);
  const { isOrganizer, meetingID, parentEventID, startDate, statusID } =
    meeting;

  const { content, setContent } = useContext(ContentContext);
  const {
    agendaItems,
    preventTriggerChangeRef,
    isLoading,
    selectedItem,
    descriptionRefs,
    isSavingRef,
  } = content;

  // const lastInputRef = useRef(null);

  const afterSaving = (currentIndex) => {
    console.log("%cnewItemID", "background-color: orange; color: white");

    // preventTriggerChangeRef.current = true; // prevents rich text editor to trigger handleChange and saving to db
  };

  const { saveAgendaItem } = useSaveAgendaItem({}, afterSaving);

  const handleDescriptionOnFocus = () => {
    // lastInputRef.current = { field: "title", index: index };
    console.log("description focus", {
      selectedItem,
      item,
      isSavingRef: isSavingRef.current,
    });
    if (isSavingRef.current === false) {
      if (selectedItem.id !== item.id) {
        console.log(
          "%cfocus on description",
          "background-color: orange; color: white",
          { isSavingRef, preventTriggerChangeRef, id: item.id, index: index }
        );

        // this prevents the rich text editor to trigger the handleChange
        // otherwise, for example changing agenda item list would trigger saving to db
        // including when selecting an empty line
        preventTriggerChangeRef.current = true;
        // if currently saving because the rich text editor is debounced, don't permit switching to another topic
        // otherwise, data would be saved to new topic with the previous topic details
        // if (isSavingRef.current === false) {
        setContent({
          ...content,
          selectedItem: { id: item.id, index: index },
        });
        // }
      }
    }
  };

  const handleDescriptionOnChange = (e) => {
    console.log(
      "%con description Change",
      "background-color: blue; color: white"
    );

    let agendaItemsCopy = cloneDeep(agendaItems);
    console.log("update line");
    agendaItemsCopy[index].desc = e.target.value;

    console.log("update state to", agendaItemsCopy);

    preventTriggerChangeRef.current = true;
    // can't be inside saveAgendaItem as it cannot wait for debounce and db update
    setContent({
      ...content,
      agendaItems: agendaItemsCopy,
    });
    debounce(() => {
      saveAgendaItem({
        // optional
        newAgendaItems: agendaItemsCopy,
        desc: e.target.value,
        duration: agendaItemsCopy[index].duration,
        orderBy: orderBy,
        // required
        parentEventID: parentEventID,
        meetingID: meetingID,
        content: content,
        setContent: setContent,
        operationType: "update",
      });
    });
  };

  if ([0, 1, 2, 3, 7, 8].includes(statusID)) {
    if (isOrganizer) {
      return (
        <textarea
          disabled={isSavingRef.current}
          ref={(el) => (descriptionRefs.current[index] = el)}
          placeholder={"Start typing..."}
          onFocus={handleDescriptionOnFocus}
          className={`${classes.agendaItemInput} ${
            classes.agendaItemTitleInput
          }  ${
            selectedItem.index !== index &&
            isLoading &&
            classes.agendaItemInputDisabledColors
          }`}
          onChange={handleDescriptionOnChange}
          value={description || ""}
        />
      );
    } else {
      return <h5 className={classes.agendaItemTitleLabel}>{description}</h5>;
    }
  } else {
    return <h5 className={classes.agendaItemTitleLabel}>{description}</h5>;
  }
};

const AgendaItemModal = (props) => {
  const generalData = useDataForLog(); // context data to populate error log

  const { modal, setModal } = props;

  const { meeting, setMeeting, updateRating } = useContext(MeetingContext);
  const { meetingID, parentEventID } = meeting;

  const { content, setContent } = useContext(ContentContext);
  const { agendaItems, selectedItem } = content;

  const { saveAgendaItem } = useSaveAgendaItem();

  try {
    switch (modal.name) {
      case "confirmDeleteAgendaItem":
        return (
          <CustomDialog
            center
            iconPreset="question"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Do you want to delete this agenda item?"
            buttonActionLabel1="Cancel"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
            }}
            // buttonActionDisabled1={isLoading}
            buttonActionLabel2="Delete"
            buttonActionColor2="danger"
            buttonActionFunction2={() => {
              saveAgendaItem({
                // optional
                newAgendaItems: agendaItems,
                agendaItemToDelete: modal.data.agendaItemID,
                updateRating: updateRating, // function to update rating
                // required
                parentEventID: parentEventID,
                meetingID: meetingID,
                content: content,
                setContent: setContent,
                operationType: "delete",
              });

              setModal({ visible: false, name: "" });
            }}
          />
        );

      default:
        return null;
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: { modalName: modal.name },
    });
  }
};
