import React, { useState, useContext } from "react";
// import Button from "components/CustomButtons/Button";
import Button from "@material-ui/core/Button";
import config from "../../config";
// material ui
import { SvgIcon } from "@material-ui/core";
// data
import { SessionContext } from "../../contexts/SessionContext";
import { axiosOptions } from "functions/Common";
import axios from "axios";

// custom components
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";

// icons
import { ReactComponent as MicrosoftLogo } from "../../assets/img/Microsoft.svg";
// styles
import { makeStyles } from "@material-ui/core/styles";
import {
  grayColor,
  whiteColor,
  hexToRgb,
  blackColor,
  primaryColor,
} from "assets/jss/material-dashboard-react";

const styles = {
  activityIndicator: {
    paddingLeft: "10px",
    // backgroundColor: "yellow",
    display: "flex",
    alignItems: "center",
  },
  background: {
    backgroundColor: "white",
    height: "32px",
    width: "32px",
    margin: "2px",
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2px",
  },

  button: {
    // ...defaultFont,

    minHeight: "auto",
    minWidth: "auto",
    overflow: "clip",
    overflowHiddenMargin: "5px",
    // height: "100%",
    backgroundColor: primaryColor[4],
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[3]) +
      ", 0.12)",
    border: "none",
    borderRadius: "3px",
    position: "relative",
    padding: "0px 15px 0px 0px",
    // padding: "8px",
    // margin: ".2rem",
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "none",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    // lineHeight: "1.42857143",
    textAlign: "center",
    // whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: primaryColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(grayColor[3]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(grayColor[3]) +
        ", 0.2)",
    },
  },
  backgroundDisabled: {
    backgroundColor: grayColor[3],
  },
};
const useStyles = makeStyles(styles);

export default function ConnectCalendarMicrosoft(props) {
  const classes = useStyles();
  const { session, setSession } = useContext(SessionContext);
  const { signedIn, signOut, loading } = props;
  const { userID } = session;
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = () => {
    setIsLoading(true);
    console.log("intenta el sign in de microsoft");
    window.location.href = `${config.url}/api/microsoft-signin?user_id=${userID}`;
    // window.location.href = `${config.url}/api/test`;

    // const options = axiosOptions({
    //   url: `${config.url}/api/microsoft-signin?user_id=${userID}`,
    //   method: "get",
    //   // params: {
    //   //   userID: userID,
    //   // },
    // });
    // console.log("options are", options);

    // axios(options)
    //   .then(async (response) => {
    //     // if (200 === response.status) {
    //     // }
    //     console.log("axios response", response.data.info);
    //   })
    //   .catch((error) => {
    //     console.log("error");
    //   });
  };
  return (
    <>
      <Button
        // isLoading={isLoading}
        // color="primary"
        onClick={() => (signedIn ? signOut() : handleLogin())}
        className={`${classes.button} ${
          (isLoading || loading) && classes.backgroundDisabled
        }`}
      >
        <div className={classes.background}>
          <SvgIcon style={{ fontSize: 20 }}>
            <MicrosoftLogo />
          </SvgIcon>
        </div>

        <h5 style={{ paddingLeft: "10px" }}>
          {signedIn ? "Disconnect" : "Sign in with Microsoft"}
        </h5>
        <div className={classes.activityIndicator}>
          {(isLoading || loading) && <ActivityIndicator />}
        </div>
      </Button>
    </>
  );
  // }
}
