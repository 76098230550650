import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// custom components
import Dialog from "components/CustomDialog/CustomDialog";
import Button from "../../components/CustomButtons/Button.js";
import ErrorHandler from "components/Error/Error";
// data
import config from "../../config";
import axios from "axios";
import { axiosOptions } from "functions/Common.js";
import { SessionContext } from "../../contexts/SessionContext";
import { Formik } from "formik";
import { object, string } from "yup";
import useFetch from "functions/useFetch.js";
// icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import FacebookIcon from "@material-ui/icons/Facebook";
// material ui components
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
// styles
import {
  grayColor,
  infoColor,
  dangerColor,
} from "../../assets/jss/material-dashboard-react";
import { makeStyles } from "@material-ui/core/styles";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator.js";

const useStyles = makeStyles((theme) => ({
  helperError: {
    color: dangerColor[1],
  },

  avatar: {
    // margin: theme.spacing(1),
    backgroundColor: infoColor[4],
  },
  paper: {
    backgroundColor: "white",
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",

    // marginTop: "15%",
    // marginBottom: "15%",
    paddingLeft: "20%",
    paddingRight: "20%",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    // height: "100%",
    // marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    height: "100%",
    backgroundColor: "white",
  },
  title: {
    color: infoColor[4],
    margin: "10px 0px",
    textAlign: "center",
  },
  textSeparator: {
    marginTop: 10,
    marginBottom: 10,
    color: grayColor[6],
    textAlign: "center",
  },
  linksContainer: {
    paddingRight: 8,
    paddingLeft: 8,
  },
}));

export default function SignIn() {
  console.log("%cStarts signin", "background-color: yellow; color: red");

  const { t } = useTranslation(["main"]);
  // const { t } = useTranslation();
  const classes = useStyles();
  const { signIn } = useContext(SessionContext);
  const params = useParams();
  const emailParam = params.email || "";
  // console.log(
  //   "%cParams",
  //   "background-color: red; color: white",
  //   emailParam.length
  // );

  const [email, setEmail] = useState("");
  const [modal, setModal] = useState({ visible: false, name: "" });

  const navigate = useNavigate();

  const fetchParameters = {
    url: `${config.url}/auth/search-email`,
    method: "get",
    params: {
      email: emailParam,
    },
    skip: emailParam.length === 0, // avoid fetching, although the hook always runs
  };

  const { loadingFetch, errorFetch, dataFetch } = useFetch({
    ...fetchParameters,
    params: JSON.stringify(fetchParameters.params), // has be sent as a string and parsed after, otherwise runs into loop because objects are sent a reference which changes every time
  });

  const handleSendEmailConfirmation = (email) => {
    console.log("INICIA SEND EMAIL CONFIRMATION");

    const urlToClick = `${config.url}/auth/receiveEmailConfirmation`;

    const emailUser = email;

    const options = axiosOptions({
      url: `${config.url}/auth/sendEmailConfirmation`,
      method: "post",
      data: {
        email: emailUser,
        urlToClick: urlToClick,
        origin: "WebApp",
      },
    });
    axios(options)
      .then(
        // Successful fetch
        (response) => {
          if (response.data.code === 0) {
            setModal({ visible: true, name: "email_sent" });
          } else {
            alert(response.data.message);
            console.log(
              "PROBLEMA: en Email confirmation: " + response.data.message
            );
          }
        }
      )
      // Unsuccessful fetch
      .catch((err) => {
        setModal({ visible: true, name: "connection_error" });
      });
  };

  const handleSignIn = (values, actions) => {
    console.log("inicia signin");
    // console.log(process.env.NODE_ENV);
    if (actions !== undefined) {
      actions.setSubmitting(true);
    }
    const email = values.email.toLowerCase().trim();

    const options = axiosOptions({
      url: `${config.url}/auth/signIn`,
      method: "post",
      data: {
        email: values.email.toLowerCase().trim(),
        pass: values.pass,
        origin: "WebApp",
      },
    });

    axios(options)
      .then(
        // Successful fetch
        (response) => {
          console.log("el response", { request: options, response: response });
          console.log(
            "%csign in response",
            "background-color: orange; color: white",
            response
          );

          switch (response.data.code) {
            case 0: // Valid credentials
              // Verifies if it has access to this app

              (async () => {
                const {
                  accessToken,
                  accountID,
                  email,
                  firstUse,
                  firstName,
                  imageUrl,
                  isOwner,

                  lastName,
                  refreshToken,
                  stageID,
                  subscriptionExpiryDate,
                  userID,
                } = response.data.info;
                sessionStorage.setItem("kjd/afd", accessToken);

                const token = response.data.info.accessToken;
                console.log(
                  "En SignIn, se dio acceso y el token devuelto es:   " + token
                );
                console.log("En SignIn, props   ", response.data.info);
                try {
                  console.log("En SignIn, inicia el proceso para ingresar");

                  if (actions !== undefined) {
                    actions.setSubmitting(false);
                  }

                  await signIn({
                    accessToken: accessToken,
                    accountID: accountID !== null ? parseInt(accountID) : null,
                    email: email,
                    firstUse: firstUse,
                    imageUrl: imageUrl,
                    isOwner: isOwner,
                    refreshToken: refreshToken,
                    stageID: parseInt(stageID),
                    subscriptionExpiryDate: subscriptionExpiryDate,
                    userID: parseInt(userID),
                    userName: `${firstName} ${lastName}`,
                    // accountName: `${firstName} ${lastName}`,
                    // email: email,
                    // hasAccess: true,
                    // userName: `${firstName} ${lastName}`,
                    // userSettings: userSettingsAdjusted,
                  });

                  if (firstUse) {
                    navigate("/app/dashboard");
                  } else {
                    navigate("/app/meetings");
                  }
                } catch (error) {
                  console.log(
                    "En SignIn, no pudo hacer guardado en Asynctorage"
                  );
                  actions.resetForm();
                  actions.setSubmitting(false);
                }
              })();

              // setThisTokenNOTASYNC(response.data.accessToken);
              // console.log(response.data.accessToken);
              // props.navigation.navigate("Store");
              break;
            case 1: // Incorrect credentials
              setModal({ visible: true, name: "incorrect_credentials" });
              actions.resetForm();
              actions.setSubmitting(false);
              break;
            case 2: // Email not confirmed
              setEmail(email);
              setModal({ visible: true, name: "email_not_confirmed" });
              actions.resetForm();
              actions.setSubmitting(false);
              break;
            default:
              setModal({ visible: true, name: "not_authorized" });
              actions.resetForm();
              actions.setSubmitting(false);
          }
        }
      )

      // Unsuccessful fetch
      .catch((err) => {
        console.log("error en signin", err);
        actions.resetForm();
        actions.setSubmitting(false);
        setModal({ visible: true, name: "connection_error" });
      });
  };

  const ModalToShow = () => {
    switch (modal.name) {
      case "not_authorized": // not authorized
        return (
          <Dialog
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Not authorized"
            buttonCloseLabel="OK"
          />
        );
      case "incorrect_credentials": // incorrect credentials
        return (
          <Dialog
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title={"Email or password is incorrect"}
            buttonCloseLabel="OK"
          />
        );
      case "email_not_confirmed": // email not confirmed
        return (
          <Dialog
            center
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Email has not been verified"
            mainText="Log into your email account and search for the email we sent. If you can't find it, we can send it again"
            buttonCloseLabel="Cancel"
            buttonActionLabel1="Resend verification email"
            buttonActionFunction1={() => {
              handleSendEmailConfirmation(email);
              setModal({ visible: false, name: "" });
            }}
          />
        );

      case "not_your_email": // email sent
        return (
          <Dialog
            center
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="Are you sure you want to change the email?"
            mainText={`An invitation was sent to ${email}.  If you sign up using a different email, you will not be visible for the person who invited you and may not have the same privileges.`}
            buttonCloseLabel="Cancel"
            buttonActionLabel1="Use another email"
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
              navigate("/signup", {
                replace: true, // removes the previous useLocation state that held the email info
              });
            }}
            buttonActionColor1="warning"
          />
        );
      case "email_sent": // email sent
        return (
          <Dialog
            iconPreset="success"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title="We have sent you an email to recover your access"
            buttonCloseLabel="OK"
          />
        );

      case "connection_error": // connection problems
        return (
          <ErrorHandler error={{ message: "Failed to fetch-signed out" }} />
        );
      default:
        return null;
    }
  };

  // const handleGoogleSignIn = async () => {};
  // const handleFacebookSignIn = async () => {};

  // useEffect(() => {
  //   console.log("useEffect, dataFetch", dataFetch);
  // }, [dataFetch]);

  if (loadingFetch === true) {
    return <ActivityIndicator />;
  }

  // if (data !== null)
  console.log("data", { loadingFetch, errorFetch, dataFetch });

  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     event.preventDefault();
  //     handleSubmit()
  //   }
  // };

  if (dataFetch?.code === 0 || emailParam.length === 0) {
    // if the user exists or no email was provided, returns the signin screen
    return (
      <>
        {/* {redirect ? <Redirect from="/" to="/home" exact /> : null} */}
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <h3 className={classes.title}>{t("SignIn.SignIn")}</h3>

          <Formik
            // passes the auth provider,in this case it's storing
            // email and pass instead of using OAuth
            initialValues={{
              email: emailParam === undefined ? "" : emailParam,
              pass: "",
            }}
            onSubmit={(values, actions) => handleSignIn(values, actions)}
            validationSchema={object().shape({
              email: string()
                .required(t("SignIn.EmailRequired"))
                .email(t("SignIn.EmailValid"))
                .max(50, "Máximo 50 digitos"),
              pass: string().required(t("SignIn.PassRequired")),
            })}
          >
            {(fprops) => (
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      disabled={emailParam.length > 0}
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label={t("SignIn.Email")}
                      name="email"
                      value={fprops.values.email}
                      onChange={fprops.handleChange("email")}
                      autoComplete="email"
                      helperText={fprops.touched.email && fprops.errors.email}
                      onBlur={fprops.handleBlur("email")}
                      size="small"
                      FormHelperTextProps={{ className: classes.helperError }}
                    />
                    {emailParam.length > 0 && (
                      <Grid item>
                        <Button
                          link
                          color="primary"
                          onClick={(item) => {
                            // setModal({ visible: true, name: "not_your_email" });

                            // setModal({ visible: false, name: "" });
                            navigate("/", {
                              replace: true, // removes the previous useLocation state that held the email info
                            });
                          }}
                        >
                          <h6 style={{ margin: "5px 0px 5px 15px" }}>
                            Not your email
                          </h6>
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="current-password"
                      FormHelperTextProps={{ className: classes.helperError }}
                      fullWidth
                      helperText={fprops.touched.pass && fprops.errors.pass}
                      id="password"
                      label={t("SignIn.Password")}
                      name="password"
                      onBlur={fprops.handleBlur("pass")}
                      onChange={fprops.handleChange("pass")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          fprops.handleSubmit();
                        }
                      }}
                      required
                      size="small"
                      type="password"
                      value={fprops.values.pass}
                      variant="outlined"
                    />{" "}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      color="primary"
                      onClick={fprops.handleSubmit}
                      isLoading={fprops.isSubmitting}
                    >
                      {fprops.isSubmitting
                        ? t("Common.Loading")
                        : t("SignIn.SignInButton")}
                    </Button>
                  </Grid>

                  <Grid container className={classes.linksContainer}>
                    <Grid item xs>
                      <Link href="/passwordrecovery" variant="body2">
                        <h5>{t("SignIn.PasswordRecovery")}</h5>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/signup" variant="body2">
                        <h5>{t("SignIn.SignUp")}</h5>
                      </Link>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <h4 className={classes.textSeparator}>
                      {t("SignIn.OrUsing")}
                    </h4>
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    <Button
                      fullWidth
                      color="primary"
                      onClick={handleFacebookSignIn}
                      // isLoading={fprops.isSubmitting}
                      // startIcon={<FacebookIcon />}
                      startIcon={<FacebookIcon />}
                    >
                      Facebook
                    </Button>
                  </Grid> */}
                  <Grid item xs={12}>
                    {/* <pre>
                      {JSON.stringify(
                        {
                          values: fprops.values,
                        },
                        null,
                        2
                      )}
                    </pre> */}

                    {/* <div id="googleSignInDiv"></div> */}
                    {/* <Button
                      fullWidth
                      color="primary"
                      onClick={handleGoogleSignIn}
                      // isLoading={fprops.isSubmitting}
                      startIcon={<GoogleIcon />}
                    >
                      Google
                    </Button> */}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
        <ModalToShow />
      </>
    );
  } else {
    navigate("/signup", {
      state: { email: emailParam },
    });

    // navigate(`/signup/?email=${emailParam}`);

    //if the user doesn't exist, navigate to the signup page
    // return <h3>User doesnt exist</h3>;
  }
}
