import React, { useEffect, useState } from "react";

import add from "date-fns/add";
import { timeDifference } from "functions/Common";
import ActivityIndicator from "./ActivityIndicator/ActivityIndicator";
import CustomTooltip from "./CustomTooltip/CustomTooltip";
import MedalWithIndicator from "components/MedalWithIndicator/MedalWithIndicator";

// styles
import { grayColor } from "assets/jss/material-dashboard-react";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  finalRatingIndicator: {
    color: grayColor[1],
    paddingRight: "5px",
  },

  pendingIndicator: {
    color: grayColor[3],
    paddingRight: "10px",
    margin: 0,
    lineHeight: "10px",
  },
  pendingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    alignItems: "center",
  },
  ratingContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  tooltipRatingItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100px",
  },
};

const useStyles = makeStyles(styles);

export const RatingIndicator = (props) => {
  const classes = useStyles();
  const { rating, canViewRating = true, ratingElements, size } = props;
  // const { canViewRating } = props || true;
  const initialRating = parseFloat(rating);
  // console.log("initialRating", initialRating);

  const ratingRange = [
    { variant: "gold", upperLimit: 11, lowerlimit: 9 },
    { variant: "silver", upperLimit: 9, lowerlimit: 8 },
    { variant: "bronze", upperLimit: 8, lowerlimit: 7 },
    { variant: "noMedal", upperLimit: 7, lowerlimit: 0 },
  ];

  const index = ratingRange.findIndex(
    (item) =>
      initialRating < item.upperLimit && initialRating >= item.lowerlimit
  );

  // console.log("el index del rating es", { index, size });

  const variant = ratingRange[index];

  const medalSizeCalc = () => {
    switch (size) {
      case "sm":
        return 15;
      default:
        return 23;
    }
  };
  // console.log("rating elements");

  if (canViewRating) {
    return (
      <CustomTooltip
        tooltipText={
          <>
            {ratingElements === undefined
              ? null
              : ratingElements.map((item, index) => (
                  <div
                    key={index}
                    className={classes.tooltipRatingItemContainer}
                  >
                    <h6>{item.desc}</h6>
                    <h6>{item.value}</h6>
                  </div>
                ))}
          </>
        }
        placement="bottom"
      >
        <div className={classes.ratingContainer}>
          <h4
            style={{ fontSize: size === "sm" ? "14px" : "20px" }}
            className={classes.finalRatingIndicator}
          >
            {rating}
          </h4>
          <MedalWithIndicator
            variant={variant.variant}
            size={medalSizeCalc()}
          />
        </div>
      </CustomTooltip>
    );
  } else {
    return (
      <CustomTooltip
        tooltipText="Rating will be available after feedback is completed or time has expired"
        placement="bottom"
      >
        <div className={classes.ratingContainer}>
          <div className={classes.pendingContainer}>
            <h6 className={classes.pendingIndicator}>Rating</h6>
            <h6 className={classes.pendingIndicator}>Pending</h6>
          </div>
          <MedalWithIndicator
            variant={variant.variant}
            size={medalSizeCalc()}
          />
        </div>
      </CustomTooltip>
    );
  }
};
