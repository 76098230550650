import { gql } from "@apollo/client";

export const subscriptionUsage = gql`
  query subscriptionUsage($companyID: Int, $lineAreaID: Int) {
    spSubscriptionUsage(companyId1: $companyID, lineAreaId1: $lineAreaID) {
      nodes {
        lineCashAvailable
        storesAvailable
      }
    }
    spCompanyActive(companyId1: $companyID) {
      nodes {
        active
        expiryDays
        showExpiryAlert
      }
    }
  }
`;
export const badgeHelpdesk = gql`
  query badgeHelpdesk($userID: Int) {
    spHelpdeskTicketStatus(userId1: $userID, countAlert1: true) {
      totalCount
    }
  }
`;
