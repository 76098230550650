import {
  hexToRgb,
  whiteColor,
  infoColor,
  grayColor,
  primaryColor,
  dangerColor,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";

const customHeaderStyle = {
  // nuevo
  cardLeft: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    height: "100%",
    // justifyContent: "flex-end",
    // backgroundColor: "orange",
  },

  // nuevo
  cardRight: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    // height: "50px",
    height: "100%",
    justifyContent: "flex-end",
  },

  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px",
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important",
  },
  costIndicator: {
    color: grayColor[3],
    margin: "0px",
    paddingLeft: "15px",
    paddingRight: "15px",
    // lineHeight: "45px",
  },
  checkInContainer: {
    border: `1px solid ${grayColor[5]}`,
    // backgroundColor: grayColor[7],
    borderRadius: "5px",
    padding: "0px 10px 0px 10px",
    height: "100%",
    justifyContent: "flexStart",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginRight: "5px",

    // maxHeight: "40px",
  },
  checkInLabel: {
    color: grayColor[4],
    margin: "0px",
    fontSize: "8px",
    lineHeight: "10px",
    textAlign: "center",
  },
  checkInCodeIndicator: {
    margin: "0px",
    fontSize: "20px",
    // fontWeight: 600,
    color: grayColor[2],
    lineHeight: "28px",
    // padding: "1px",
  },
  countDownMain: {
    display: "flex",
    flexDirection: "row",
    border: `1px solid ${grayColor[5]}`,
    // backgroundColor: grayColor[7],
    // backgroundColor: "red",
    borderRadius: "5px",
    padding: "0px 10px 0px 10px",
    margin: "0px 5px 0px 5px",
    height: "100%",
    alignItems: "center",
    // backgroundColor: grayColor[7],
  },
  countDownDescContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px 2px",
    height: "100%",
  },
  countDownIndicatorLabel: {
    ...defaultFont,
    fontWeight: 600,
    fontSize: "1.15rem",
    color: grayColor[2],
    lineHeight: "25px",
  },
  countDownTypeLabel: {
    ...defaultFont,
    fontWeight: 400,
    fontSize: "0.55rem",
    color: grayColor[4],
  },
  countDownStartLabel: {
    color: grayColor[3],
    // lineHeight: "8px",
    textAlign: "center",
    maxWidth: "65px",
    marginRight: "5px",
  },
  countDownAlert: {
    color: "white",
  },
  countDownAlertBackground: {
    backgroundColor: dangerColor[1],
  },

  timeIndicator: {
    color: grayColor[3],
    margin: "0px",
    paddingLeft: "15px",
    paddingRight: "15px",
    // lineHeight: "45px",
  },

  displayNone: {
    display: "none !important",
  },

  pendingIndicator: {
    color: grayColor[1],
    fontSize: "10px",
    textAlign: "center",
    paddingRight: "5px",
    margin: "0px",
  },
  mainContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    // backgroundColor: "yellow",
  },

  popperMainContainer: {
    // height: "calc(100% - 18px)", // just added, substracts the height of the description box

    display: "flex",
    width: "100%",
    flex: 1,
    flexDirection: "row",
    // maxWidth: "400px",
    alignItems: "stretch",
    padding: "0px 0px",
    margin: "0px 10px 0px 0px",
    // margin: ".2rem",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    // backgroundColor: "white",
    // backgroundColor: "orange",

    "&$primary": {
      borderColor: primaryColor[6],
      "&:hover": { backgroundColor: primaryColor[8] },
    },
    // "&$gray": {
    //   borderColor: grayColor[5],
    //   "&:hover": { backgroundColor: grayColor[6] },
    // },
    // "&$disabled": {
    //   borderColor: grayColor[5],
    //   backgroundColor: grayColor[8],
    //   // backgroundColor: "orange",
    // },
    // "&$transparent": {
    //   borderColor: "rgb(0,0,0,0)",

    //   // backgroundColor: "orange",
    // },
  },
  popperMainContainerFiltered: {
    "&$primary": {
      backgroundColor: primaryColor[8],
    },
  },

  popperIconContainer: {
    display: "flex",
    color: "red",
    padding: "0px 10px 0px 10px",
    alignItems: "center",
    alignSelf: "center",
    "&$primary": {
      color: primaryColor[4],
    },
    // "&$gray": {
    //   color: grayColor[4],
    // },
  },
  primary: {},

  // medal: { padding: "0px 1px" },

  menuButton: {
    color: grayColor[4],
    borderColor: grayColor[4],
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "6px 0px 6px 0px",
    borderRadius: 5,
  },
  RoutesGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  RoutesTitle: {
    float: "left",
    padding: "0px 10px 0px 0px",
  },
  searchIcon: {
    // marginTop: 5,
    color: primaryColor[6],
    // justifyContent: "center",
    // flex: 1,
    // marginRight: -5,
  },
  tabsRoot: {
    position: "relative",
    // float: "left", // nuevo
    // display: "inline", // nuevo
    minHeight: "unset !important",
    overflowX: "visible",
    "& $tabRootButton": {
      fontSize: "0.875rem",
    },
  },
  tabRootButton: {
    position: "relative",
    textTransform: "none",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "10px 15px",
    borderRadius: "3px",
    lineHeight: "24px",
    border: "0 !important",
    // color: whiteColor + " !important",
    color: grayColor[0],
    marginLeft: "4px",
    "&:last-child": {
      marginLeft: "0px",
    },
  },
  tabSelected: {
    // backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.2)",
    backgroundColor: "rgba(" + hexToRgb(infoColor[4]) + ", 1)",
    color: whiteColor,
    transition: "0.2s background-color 0.1s",
  },
  tabWrapper: {
    ...defaultFont,
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    fontWeight: "500",
    // fontSize: "12px",
    marginTop: "1px",
    "& > svg,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0 !important",
    },
  },
  // nuevo
  titleRight: {
    float: "left",
    // marginLeft: "15px",
    // marginTop: "0",
    // marginBottom: "0px",
    minHeight: "auto",
    margin: "0px 0px 0px 8px",
    fontWeight: 400,
  },
  tooltipRatingItemContainer: {
    margin: "0px",
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    // color: "red",
    width: "100px",
  },
};

export default customHeaderStyle;
