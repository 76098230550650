import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import CustomMainInput from "./CustomMainInput/CustomMainInput";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Button from "components/CustomButtons/Button";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { boxShadow } from "assets/jss/material-dashboard-react";

// core components
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import CustomPopover from "./CustomPopover";

// data
import { v4 as uuidv4 } from "uuid";

// icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ImportExportIcon from "@material-ui/icons/ImportExport";

// style
import classNames from "classnames";
import { primaryColor } from "assets/jss/material-dashboard-react.js";

const styles = {
  bottomBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sortMainContainer: {
    minWidth: "400px",
  },
  popperMainContainer: {
    // height: "calc(100% - 18px)", // just added, substracts the height of the description box

    display: "flex",
    width: "100%",
    flex: 1,
    flexDirection: "row",
    // maxWidth: "400px",
    alignItems: "stretch",
    padding: "0px 0px",
    margin: "0px 10px 0px 0px",
    // margin: ".2rem",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    // backgroundColor: "white",
    // backgroundColor: "orange",

    "&$primary": {
      borderColor: primaryColor[6],
      "&:hover": { backgroundColor: primaryColor[8] },
    },
    // "&$gray": {
    //   borderColor: grayColor[5],
    //   "&:hover": { backgroundColor: grayColor[6] },
    // },
    // "&$disabled": {
    //   borderColor: grayColor[5],
    //   backgroundColor: grayColor[8],
    //   // backgroundColor: "orange",
    // },
    // "&$transparent": {
    //   borderColor: "rgb(0,0,0,0)",

    //   // backgroundColor: "orange",
    // },
  },
  popperMainContainerSorted: {
    "&$primary": {
      backgroundColor: primaryColor[8],
    },
  },

  popperIconContainer: {
    display: "flex",
    color: "red",
    padding: "0px 10px 0px 10px",
    alignItems: "center",
    alignSelf: "center",
    "&$primary": {
      color: primaryColor[4],
    },
    // "&$gray": {
    //   color: grayColor[4],
    // },
  },
  primary: {},
};
const useStyles = makeStyles(styles);

const SortComponent = (props) => {
  const { sorts, columnList, listsDropdown, sendInfoToParent, rightElements } =
    props;
  // console.log("%cprops", "background-color: red; color: white", props);

  const classes = useStyles();

  const popperContainerClasses = classNames({
    [classes.popperMainContainer]: true,
    [classes.popperMainContainerSorted]:
      sorts.filter((item) => item.sortOrder !== null).length > 0, // exclude lines that don't have a sort order selected
    [classes["primary"]]: "primary",
  });

  const popperIconContainerClasses = classNames({
    [classes.popperIconContainer]: true,
    // [classes.iconOnlyContainer]: value === undefined,
    [classes["primary"]]: "primary",
  });

  // exclude already selected columns
  const columnListFiltered = (currentField) => {
    // console.log(
    //   "%cEl current field es",
    //   "background-color: purple; color: white",
    //   currentField
    // );

    const result = columnList.filter((column) => {
      const include = sorts.some((inSorts) => {
        // console.log("%ctests in some", "background-color: blue; color: white", {
        //   column: column.id,
        //   inFilters: inFilters,
        //   currentField: currentField,
        //   test1: inFilters.field !== column.id,
        //   test2: inFilters.field === currentField,
        //   final:
        //     inFilters.field === column.id || inFilters.field === currentField,
        // });

        return (
          inSorts.field === column.id // || inFilters.field === currentField
        );
      });

      const isCurrentField =
        currentField !== undefined && currentField === column.id;

      // console.log("%cLa columna es", "background-color: green; color: white", {
      //   columnID: column.id,
      //   include: !include,
      //   isCurrentField: isCurrentField,
      // });

      return !include || isCurrentField;
    });
    // console.log("%cresult", "background-color: red; color: white", result);

    return result;
  };

  const SortPopper = () => {
    const updateSorts = (sortLine, action) => {
      const newArray = [...sorts];
      console.log(
        "%cStarts updatefilters with sortLine provided",
        "background-color: yellow; color: red",
        { sortLine, action, newArray }
      );

      let newSorts;
      if (sortLine === null && action === "remove") {
        newSorts = [];
      } else {
        newSorts = newArray.reduce((sortAcum, line) => {
          // console.log("%cacum", "background-color: red; color: white", filterAcum);
          // console.log("%cline", "background-color: red; blue: white", sortLine);
          console.log("%cLine", "background-color: green; color: white", {
            line: line.id,
            sortLine: sortLine.id,
          });

          // update if there's a sort line that can't has missing data to execute sort
          let sortLineStatus = "";
          if (sortLine.sortOrder === null) {
            // if a field has not a sort order set yet
            sortLineStatus = "in process";
          } else {
            sortLineStatus = "ready";
          }

          // detects the line that has to be updated/removed
          if (line.id === sortLine.id) {
            console.log("line to remove");

            if (action !== "remove") {
              sortAcum.push({ ...sortLine, sortLineStatus: sortLineStatus });
            } else {
              console.log("remove line");
            }
          } else {
            // line is not updated/removed, therefore, adds the current filters
            sortAcum.push({ ...line, filterLineStatus: sortLineStatus });
          }

          return sortAcum;
        }, []);
      }

      console.log("newSorts", newSorts);
      sendInfoToParent(newSorts);
    };

    return (
      <div
        className={classes.sortMainContainer}
        style={{
          padding: "12px",
        }}
      >
        <GridContainer alignItems="center" style={{ marginBottom: "5px" }}>
          <GridItem xs={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>Sort</h5>
              <Button
                size="xs"
                color="primary"
                // justIcon
                simple
                onClick={
                  () => {
                    updateSorts(null, "remove");
                  }
                  // setParameterList({ ...parameterList, filters: [] });
                  // updateFilters([]);
                }
              >
                Clear all
              </Button>
            </div>
          </GridItem>
        </GridContainer>
        {sorts.map((sortLine, index) => {
          return (
            <GridContainer
              alignItems="stretch"
              key={sortLine.id}
              style={{
                maxWidth: "1600px",
                marginTop: "10px",
              }}
            >
              {/* ------------ field --------------- */}
              <GridItem xs={6}>
                <CustomMainInput
                  dropDownList={columnListFiltered(sortLine.field)}
                  placeholder="Select sort"
                  dropDownFunction={(item) => {
                    // console.log("item", item);
                    // creates the new filter line
                    const newLineSort = {
                      ...sortLine,
                      field: item.id,
                      sortOrder: null,
                    };
                    // updates all the filters with the new value
                    updateSorts(newLineSort);
                  }}
                  value={sortLine.field}
                  variant="dropdown"
                />
              </GridItem>
              {/* ----------sort order -------------------  */}
              <GridItem xs={5}>
                {sortLine.field !== null && (
                  <CustomMainInput
                    dropDownList={listsDropdown.sortList}
                    placeholder="Select sort order"
                    dropDownFunction={(item) => {
                      console.log(
                        "%cItem",
                        "background-color: red; color: white",
                        { item: item }
                      );

                      // creates the new sort line
                      const newLineSort = {
                        ...sortLine,
                        sortOrder: item.id,
                      };
                      // updates all the filters with the new value
                      updateSorts(newLineSort);
                    }}
                    height="40px"
                    value={sortLine.sortOrder}
                    variant="dropdown"
                  />
                )}
              </GridItem>

              {/* ----------delete filter line -------------------  */}
              <GridItem xs={1} row alignItems="center" justifyContent="center">
                <Button
                  size="xs"
                  color="danger"
                  justIcon
                  onClick={() => updateSorts(sortLine, "remove")}
                >
                  <DeleteOutlineIcon style={{ fontSize: 20 }} />
                </Button>
              </GridItem>
            </GridContainer>
          );
        })}

        <GridContainer alignItems="baseline" style={{ marginTop: "10px" }}>
          <GridItem xs={12}>
            <div className={classes.bottomBarContainer}>
              <Button
                color="primary"
                // disabled={sorts[sorts.length - 1]?.field === null}
                disabled={
                  sorts[sorts.length - 1]?.field === null ||
                  columnListFiltered().length === 0
                }
                size="xs"
                onClick={() => {
                  const newSort = {
                    id: uuidv4(),
                    field: null,
                    sortOrder: null,
                    sortLineStatus: "in processs",
                  };

                  sendInfoToParent([...sorts, newSort]);
                  // setParameterList({
                  //   ...parameterList,
                  //   filters: [...sorts, newFilter],
                  // });
                }}
              >
                {columnListFiltered().length === 0
                  ? "No more sort"
                  : "Add sort"}
              </Button>

              {rightElements}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const removeUncompleteSorts = () => {
    // remove line that were selected but values where defined
    const newArray = [...sorts];

    const finalArray = newArray.filter((item) => item.sortOrder !== null);
    // console.log("updated filters", finalArray);
    // setParameterList({ ...parameterList, filters: finalArray });
    sendInfoToParent(finalArray);
  };

  return (
    <CustomPopover
      onClose={() => removeUncompleteSorts()}
      triggerComponent={(handleOpen) => (
        <div
          style={{
            // maxWidth: "100%",
            width: "100%",
            height: "100%", // substracts 18px which is the height of the label
            // pointerEvents: disabled ? "none" : "auto",
          }}
          className={popperContainerClasses}
          onClick={handleOpen}

          // deleteIcon={}
        >
          <div className={popperIconContainerClasses}>
            {/* <FeatherIcon size="20" icon="filter" /> */}
            <ImportExportIcon />
          </div>
        </div>
      )}
    >
      <SortPopper />
    </CustomPopover>
  );
};

export default SortComponent;
