import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import CustomMainInput from "./CustomMainInput/CustomMainInput";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Button from "components/CustomButtons/Button";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { boxShadow } from "assets/jss/material-dashboard-react";

import format from "date-fns/format";

import startOfYear from "date-fns/startOfYear";
import isSameDay from "date-fns/isSameDay";
import subMonths from "date-fns/subMonths";

const styles = {
  calendarElement: {
    // right: "25px",
    // // transform: "translateX(-50%)",
    // top: "60px",
    border: "1px solid #ccc",

    ...boxShadow,
  },
};
const useStyles = makeStyles(styles);

const DateRangeComp = (props) => {
  const {
    height,
    startDate,
    endDate,
    sendInfoToParent,
    position,
    iconSize,
    months = 1,
    placeholder,
  } = props;
  const classes = useStyles();
  const [calendarPosition, setCalendarPosition] = useState({});

  // date state
  const [range, setRange] = useState([
    {
      startDate: startDate, //addDays(new Date(), -90),
      endDate: endDate, //new Date(),
      key: "selection",
    },
  ]);

  // open close
  const [open, setOpen] = useState(false);
  const refMainInput = useRef();

  // get the target element to toggle
  const refOne = useRef(null);
  useEffect(() => {
    // console.log("change in refMainInput");
    setCalendarPosition({
      left: `${(
        refMainInput?.current?.offsetLeft +
        refMainInput?.current?.offsetWidth -
        330
      ).toString()}px`,
      top: `${(
        refMainInput?.current?.offsetTop +
        refMainInput?.current?.offsetHeight +
        5
      ).toString()}px`,
    });
  }, [refMainInput]);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    // console.log("refOne.current", refOne.current);
    // console.log("e.target", e.target);
    // console.log("contains", refOne.current.contains(e.target));
    // console.log("hideOnClickOutside", {
    //   current: refOne.current,
    //   target: e.target,
    // });
    if (refOne.current && !refOne.current.contains(e.target)) {
      // console.log(
      //   "%cEjecuta el hideOnClick",
      //   "background-color: red; color: white",
      //   {
      //     current: refOne.current,
      //     target: e.target,
      //   }
      // );

      // sendInfoToParent(range);
      setOpen(false);
    }
  };

  // console.log("positi", refMainInput?.current?.offsetRight);
  return (
    <>
      {/* <input
        value={`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(
          range[0].endDate,
          "MM/dd/yyyy"
        )}`}
        readOnly
        className="inputBox"
        onClick={() => setOpen((open) => !open)}
      /> */}

      <CustomMainInput
        refID={refMainInput}
        color="primary"
        height={height}
        onClick={() => setOpen(!open)}
        iconStart={<CalendarTodayIcon style={{ fontSize: iconSize || 20 }} />}
        placeholder={placeholder || "Undefined placeholder"}
        value={
          range[0].startDate === undefined
            ? null
            : `${format(range[0].startDate, "MMM d, yyyy")} to ${format(
                range[0].endDate,
                "MMM d, yyyy"
              )}`
        }
        variant="noInput"
      />

      <div
        ref={refOne}
        style={{
          position: "absolute",
          left: calendarPosition.left,
          top: calendarPosition.top,
          zIndex: "100",
        }}
      >
        {open && (
          // <div style={{ height: "300px", backgroundColor: "yellow" }}>
          //   <h5>prueba</h5>
          // </div>
          <DateRangePicker
            weekStartsOn={1}
            onChange={(item) => {
              // console.log("el tiem es", item);
              setRange([item.selection]);

              // if the start date and endDate are different, assumes range has been selected and therefore closes the calendar
              if (item.selection.startDate !== item.selection.endDate) {
                console.log(
                  "%cOn change",
                  "background-color: blue; color: white"
                );

                // setOpen(false);
                sendInfoToParent([item.selection]);
              }
            }}
            editableDateInputs={true}
            // moveRangeOnFirstSelection={false}
            // retainEndDateOnFirstSelection={true}
            ranges={range}
            months={months}
            scroll={{ enabled: true }}
            direction="vertical"
            style={{ right: "25px" }}
            className={classes.calendarElement}
            inputRanges={[]} // hides the days to date label
            staticRanges={[
              {
                label: "Last 3 months",
                range: () => ({
                  startDate: subMonths(new Date(), 3),
                  endDate: new Date(),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: "Last 6 months",
                range: () => ({
                  startDate: subMonths(new Date(), 6),
                  endDate: new Date(),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: "Last 9 months",
                range: () => ({
                  startDate: subMonths(new Date(), 9),
                  endDate: new Date(),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: "Last 12 months",
                range: () => ({
                  startDate: subMonths(new Date(), 12),
                  endDate: new Date(),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: "Year to date",
                range: () => ({
                  startDate: startOfYear(new Date()),
                  endDate: new Date(),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
            ]}
          />
        )}
      </div>
    </>
  );
};

export default DateRangeComp;
