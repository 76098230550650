import React, { forwardRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "assets/jss/material-dashboard-react/components/buttonStyle.js";

const useStyles = makeStyles(styles);

const RegularButton = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    block,
    children,
    className,
    color,
    colored,
    disabled,
    endIcon,
    fullWidth = false,
    isLoading,
    justIcon,
    leftAlign,
    link,
    muiClasses,
    rightAlign,
    rightSpacing,
    round,
    simple,
    size = "sm",
    startIcon,
    width,
    wired,

    ...rest
  } = props;

  const btnClasses = classNames({
    [classes.button]: true,
    [classes.leftAlign]: leftAlign,
    [classes.rightAlign]: rightAlign,
    [classes[size || "sm"]]: size || "sm",
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: isLoading || disabled, // either if isLoading is true or disabled is passed
    [classes.simple]: simple,
    [classes.rightSpacing]: rightSpacing,
    [classes.colored]: colored,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [classes.wired]: wired,
    [classes[className]]: className,
    // [className]: className,
  });

  return (
    <Button
      {...rest}
      // startIcon={<CircularProgress size={20} />}
      ref={ref}
      fullWidth={fullWidth}
      startIcon={
        isLoading ? (
          <CircularProgress
            className={classes.activityIndicator}
            size={
              {
                xs: 13,
                sm: 18,
                md: 21,
                lg: 25,
              }[size] || 19
            }
          />
        ) : (
          startIcon
        )
      }
      endIcon={endIcon}
      // classes={muiClasses}
      classes={{
        ...muiClasses,
        // startIcon: { backgroundColor: "yellow", height: 20, width: 20 },
        // label: classes.label,
        // root: classes.activityRoot,
        svg: classes.activityMain,
      }} // classes to change circular progress size
      className={btnClasses}
      style={{ width: width }}
    >
      {children}
    </Button>
  );
});

export default RegularButton;

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent",
    "gray",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  // use this to pass the classes props from Material-UI
  muiClasses: PropTypes.object,
  children: PropTypes.node,
};
