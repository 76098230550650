import { useCallback, useContext, useEffect, useMemo, useRef } from "react";

import Joyride from "react-joyride";
import { useNavigate } from "react-router-dom";
// custom components
import Button from "components/CustomButtons/Button.js";
// data
import { useMutation } from "@apollo/client";
import { TourContext } from "contexts/TourContext";
import { updateFirstUse } from "graphql/gqlUseTour";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { grayColor, primaryColor } from "assets/jss/material-dashboard-react";
// icons
import CloseIcon from "@material-ui/icons/Close";

const styles = {
  // content: {
  //   textAlign: "left",
  // },

  contentContainer: {
    padding: "15px 10px 20px 10px",
  },
  contentText: {
    textAlign: "left",
    margin: 0,
  },
  titleContainer: {
    // backgroundColor: "yellow",
    padding: "15px 10px 0px 10px",
  },
  titleText: {
    margin: 0,
  },
  body: {
    // height: "200px",
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "15px",
    color: grayColor[1],
    maxWidth: "100%",
    position: "relative",
    width: "700px",
  },
  footer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    // marginTop: "5px",
  },
  closeButton: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  skip: {
    display: "flex",
    flex: 1,
  },
};

const joyrideStyles = {
  // buttonBase: {
  //   color: "red",
  //   fontFamily: "Quicksand",
  //   fontSize: 18,
  // },
  options: {
    primaryColor: primaryColor[5], // Example background color
    zIndex: 10000,

    // spotlight: {
    //   backgroundColor: "yellow",
    // },
  },

  buttonNext: {
    fontSize: 14,
    fontFamily: "Quicksand",
  },
  buttonBack: {
    fontSize: 14,
    fontFamily: "Quicksand",
    color: primaryColor[4],
  },
  buttonSkip: {
    fontFamily: "Quicksand",
    // color: options.textColor,
    fontSize: 14,
  },
  buttonClose: {
    color: grayColor[3],
    height: 12,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.45)",
  },
};

const useStyles = makeStyles(styles);
export default function useTour(props) {
  // console.log(
  //   "%cStarts useTour",
  //   "background-color: orange; color: white",
  //   props
  // );
  const { session, setSession } = props;
  const { firstUse, userID } = session;
  // const [tourStepIndex, setTourStepIndex] = useState(0);

  const classes = useStyles();
  // const [run, setRun] = useState(true);
  const { tourState, setTourState } = useContext(TourContext);
  const { run, stepIndex, stepID } = tourState;
  const navigate = useNavigate();
  const jumpNextMenuButtonPressedRef = useRef(false);
  const backToMainButtonPressedRef = useRef(false);
  const closeButtonPressedRef = useRef(false);

  const [updateFirstUseMut] = useMutation(updateFirstUse);

  // const CustomTooltip = (props) => {
  //   console.log(
  //     "%cprops",
  //     "background-color: orange; color: white",
  //     props
  //   )(
  //     <div>
  //       <h3>{step.title}</h3>
  //       <div>{step.content}</div>
  //       <div>
  //         {step.index > 0 && <button onClick={backProps.onClick}>Back</button>}
  //         <button onClick={primaryProps.onClick}>
  //           {step.index === step.steps.length - 1 ? "Finish" : "Next"}
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  const CustomTooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    skipProps,
  }) => {
    const isEndTour = steps.length === index + 1;

    return (
      <div className={classes.body} {...tooltipProps}>
        <div className={classes.closeButton} />
        {step.title && (
          <div className={classes.titleContainer}>{step.title}</div>
        )}
        <div className={classes.contentContainer}>
          {!isEndTour && (
            <div className={classes.closeButton}>
              <Button
                size="md"
                color="gray"
                disabled={false}
                justIcon
                simple
                // onClick={closeProps.onClick}
                onClick={(e) => {
                  closeButtonPressedRef.current = true;
                  jumpNextMenuButtonPressedRef.current = false;
                  backToMainButtonPressedRef.current = false;
                  primaryProps.onClick(e);
                }}
              >
                <CloseIcon style={{ fontSize: 50 }} />
              </Button>
            </div>
          )}

          {step.content}
        </div>
        <div className={classes.footer}>
          {/* {!isEndTour && (
            <div className={classes.skip}>
              <Button
                color="gray"
                simple
                onClick={(e) => {
                  closeButtonPressedRef.current = false;
                  jumpNextMenuButtonPressedRef.current = false;
                  backToMainButtonPressedRef.current = false;
                  skipProps.onClick(e);
                }}
              >
                Skip
              </Button>
            </div>
          )} */}

          {index > 0 && (
            <Button
              style={{ marginRight: "10px" }}
              color="primary"
              simple
              rightSpacing
              // onClick={backProps.onClick}
              onClick={(e) => {
                closeButtonPressedRef.current = false;
                jumpNextMenuButtonPressedRef.current = false;
                backToMainButtonPressedRef.current = false;
                backProps.onClick(e);
              }}
            >
              Back
            </Button>
          )}
          {continuous && step?.data?.jumpNextMenu && (
            <Button
              style={{ marginRight: "10px" }}
              color="primary"
              rightSpacing
              wired
              // onClick={handleCustomButtonClick}
              // onClick={primaryProps.onClick}
              onClick={(e) => {
                closeButtonPressedRef.current = false;
                jumpNextMenuButtonPressedRef.current = true;
                backToMainButtonPressedRef.current = false;
                primaryProps.onClick(e);
              }}
            >
              Jump next menu
            </Button>
          )}
          {continuous && step?.data?.backToMain && (
            <Button
              style={{ marginRight: "10px" }}
              color="primary"
              wired
              // onClick={handleCustomButtonClick}
              // onClick={primaryProps.onClick}
              onClick={(e) => {
                closeButtonPressedRef.current = false;
                jumpNextMenuButtonPressedRef.current = false;
                backToMainButtonPressedRef.current = true;
                backProps.onClick(e);
              }}
            >
              Back to main
            </Button>
          )}
          {continuous && (
            <Button
              color="primary"
              // onClick={primaryProps.onClick}
              onClick={(e) => {
                closeButtonPressedRef.current = false;
                jumpNextMenuButtonPressedRef.current = false;
                backToMainButtonPressedRef.current = false;
                isEndTour ? closeProps.onClick(e) : primaryProps.onClick(e);
              }}
            >
              {isEndTour ? "Finish" : "Next"}
            </Button>
          )}
          {!continuous && <Button onClick={closeProps.onClick}>Finish</Button>}
        </div>
      </div>
    );
  };

  const changeStep = (stepID, direction, tourActive = true) => {
    // get the previous and next step ID
    const getStep = () => {
      const currentIndex = steps.findIndex((item) => item.id === stepID);
      const previousStepID = steps[currentIndex - 1]?.id;
      const nextStepID = steps[currentIndex + 1]?.id;
      return { previous: previousStepID, next: nextStepID };
    };

    let newStepID;
    // if no direction is provided it means it , change to the stepID provided
    if (direction === undefined) {
      newStepID = stepID;
    } else {
      // first gets the stepID previous and next.  After, select based on the parameter provided
      newStepID = getStep(stepID)[direction];
    }
    console.log("moving to stepID: ", newStepID);

    setTourState({
      ...tourState,
      stepID: newStepID,
      tourActive: tourActive,
    });
  };

  const steps = [
    {
      id: "dashboard-main",
      title: <h3 className={classes.titleText}>Dashboard</h3>,
      content: (
        <h5 className={classes.contentText}>
          In the dashboard you can view all your metrics
        </h5>
      ),
      placement: "right",
      disableBeacon: true,
      target: "#tourDashboard", // the className used to link with the HTML element
      data: {
        next: () => {
          navigate("/app/meetings");
          changeStep("dashboard-main", "next");
        },
      },
      styles: {
        spotlight: {
          border: "solid 1px white",
        },
      },
    },
    {
      id: "meeting-main",
      title: <h3 className={classes.titleText}>Meetings</h3>,
      content: (
        <h5 className={classes.content}>
          In this section you can manage all your meetings. From here you can
          have a quick access to all your meetings and create new ones.
        </h5>
      ),
      placement: "right",
      disableBeacon: true,
      target: "#tourMeetinglist", // the className used to link with the HTML element
      data: {
        previous: () => {
          navigate("/app/dashboard");
          changeStep("meeting-main", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/calendar");
          changeStep("calendar-main");
        },
        next: () => {
          changeStep("meeting-main", "next");
        },
      },
      styles: {
        spotlight: {
          border: "solid 1px white",
        },
      },
    },

    {
      id: "meeting-line",

      title: <h3 className={classes.titleText}>Meetings list</h3>,
      content: (
        <h5 className={classes.content}>
          In the summary, you can quickly view relevant information about your
          meetings including rating and cost
        </h5>
      ),
      placement: "bottom",
      disableBeacon: true,
      target: "#tourLine", // the className used to link with the HTML element
      data: {
        previous: () => {
          changeStep("meeting-line", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/calendar");
          changeStep("calendar-main");
        },
        next: async () => {
          // Wait for the navigation to complete
          await new Promise((resolve) => {
            console.log("waiting for navigation to finish");
            navigate("/app/meeting", {
              state: { meetingID: 1 },
            }); // This will trigger a navigation change, even to the same location
            resolve();
          });

          changeStep("meeting-line", "next");
        },
      },
    },
    {
      id: "meeting-indicators",

      title: <h3 className={classes.titleText}>Meeting</h3>,
      content: (
        <h5 className={classes.content}>
          The indicator bar will change depending on the meeting status. Medals
          are awarded when completing different components of the meeting.
          Estimated meeting cost is calculated.
        </h5>
      ),
      placement: "bottom",
      disableBeacon: true,
      target: "#tourIndicators", // the className used to link with the HTML element
      // target: "#tour-element",
      data: {
        previous: () => {
          navigate("/app/meetings");
          changeStep("meeting-indicators", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/calendar");
          changeStep("calendar-main");
        },
        next: () => {
          changeStep("meeting-indicators", "next");
        },
      },
    },

    {
      id: "meeting-tabs",

      title: <h3 className={classes.titleText}>Meeting components</h3>,
      content: (
        <h5 className={classes.content}>
          Every meeting has different components. Some of them award medals.
          Although not mandatory, they contribute to a more productive and
          efficient meeting.
        </h5>
      ),
      placement: "bottom",
      disableBeacon: true,
      target: "#tourTabsRoot", // the className used to link with the HTML element
      data: {
        previous: () => {
          changeStep("meeting-tabs", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/calendar");
          changeStep("calendar-main");
        },
        next: () => {
          changeStep("meeting-tabs", "next");
        },
      },
    },
    {
      id: "meeting-details",

      title: <h3 className={classes.titleText}>Meeting details</h3>,
      content: (
        <h5 className={classes.content}>
          In the details section you will find all the regular information
          related to a meeting
        </h5>
      ),
      placement: "right",
      disableBeacon: true,
      target: "#Details", // the className used to link with the HTML element
      data: {
        previous: () => {
          changeStep("meeting-details", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/calendar");
          changeStep("calendar-main");
        },
        next: () => {
          changeStep("meeting-details", "next");
        },
      },
    },

    {
      id: "meeting-purpose",
      title: <h3 className={classes.titleText}>Meeting purpose</h3>,
      content: (
        <h5 className={classes.content}>
          You can define objectives when creating the meeting. Once meeting is
          finished, complete the results. Each award 1 medal if completed. Not
          ready for using using objectives and results? The account admin can
          hide this in settings
        </h5>
      ),
      placement: "right",
      disableBeacon: true,
      target: "#Purpose", // the className used to link with the HTML element
      data: {
        previous: () => {
          changeStep("meeting-purpose", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/calendar");
          changeStep("calendar-main");
        },
        next: () => {
          changeStep("meeting-purpose", "next");
        },
      },
    },

    {
      id: "meeting-content",
      title: <h3 className={classes.titleText}>Meeting content</h3>,
      content: (
        <h5 className={classes.content}>
          Create an agenda so the attendees can be prepared accordingly. Use
          topic durations for a improved timing control. Once the meeting has
          started, you can create notes for each topic. If set, awards 2 medals.
          Can also be hidden in settings if don&apos;t be used.
        </h5>
      ),
      placement: "right",
      disableBeacon: true,
      target: "#Content", // the className used to link with the HTML element
      data: {
        previous: () => {
          changeStep("meeting-content", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/calendar");
          changeStep("calendar-main");
        },
        next: () => {
          changeStep("meeting-content", "next");
        },
      },
    },
    {
      id: "meeting-actions",
      title: <h3 className={classes.titleText}>Meeting actions</h3>,
      content: (
        <h5 className={classes.content}>
          Define actions as part of this meeting. These actions will be
          incorporated automatically in the next related meeting for follow up.
          Actions will be added to owner&apos;s to do list.
        </h5>
      ),
      placement: "right",
      disableBeacon: true,
      target: "#Actions", // the className used to link with the HTML element
      data: {
        previous: () => {
          changeStep("meeting-actions", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/calendar");
          changeStep("calendar-main");
        },
        next: () => {
          changeStep("meeting-actions", "next");
        },
      },
    },

    {
      id: "meeting-parking",
      title: <h3 className={classes.titleText}>Meeting parking lot</h3>,
      content: (
        <h5 className={classes.content}>
          List here the topics that should be discussed but not as part of this
          meeting. Avoid spending time in discussion that don&apos;t contribute
          to your meeting&apos;s objectives
        </h5>
      ),
      placement: "right",
      disableBeacon: true,
      target: "#Parkinglot", // the className used to link with the HTML element
      data: {
        previous: () => {
          changeStep("meeting-parking", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/calendar");
          changeStep("calendar-main");
        },
        next: () => {
          changeStep("meeting-parking", "next");
        },
      },
    },
    {
      id: "meeting-feedback",
      title: <h3 className={classes.titleText}>Meeting feedback</h3>,
      content: (
        <h5 className={classes.content}>
          Once the meeting is completed, you can rate your attendees and your
          attendees can evaluate you easily.
        </h5>
      ),
      placement: "right",
      disableBeacon: true,
      target: "#Feedback", // the className used to link with the HTML element
      data: {
        previous: () => {
          changeStep("meeting-feedback", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/calendar");
          changeStep("calendar-main");
        },
        next: () => {
          navigate("/app/calendar");
          changeStep("meeting-feedback", "next");
        },
      },
    },

    // {
    //   id: "meeting-feedback-rate-attendees",
    //   title: <h3 className={classes.titleText}>Meetings</h3>,
    //   content: <h5 className={classes.content}>Rate attendees</h5>,
    //   placement: "right",
    //   disableBeacon: true,
    //   target: "#Feedback-rate-attendees", // the className used to link with the HTML element
    //   data: {
    //     previous: () => {
    //       changeStep("meeting-feedback-rate-attendees", "previous");
    //     },
    //     backToMain: async () => {
    //       navigate("/app/meetings");
    //       changeStep("meeting-main");
    //     },
    //     next: () => {
    //       changeStep("meeting-feedback-rate-attendees", "next");
    //     },
    //   },
    // },

    // {
    //   id: "meeting-feedback-rate-organizer",
    //   title: <h3 className={classes.titleText}>Meetings</h3>,
    //   content: <h5 className={classes.content}>Rate organizer</h5>,
    //   placement: "left",
    //   disableBeacon: true,
    //   target: "#Feedback-rate-organizer", // the className used to link with the HTML element
    //   data: {
    //     previous: () => {
    //       changeStep("meeting-feedback-rate-organizer", "previous");
    //     },
    //     backToMain: async () => {
    //       navigate("/app/meetings");
    //       changeStep("meeting-main");
    //     },
    //     next: () => {
    //       changeStep("meeting-feedback-rate-organizer", "next");
    //     },
    //   },
    // },

    {
      id: "calendar-main",
      title: <h3 className={classes.titleText}>Calendar</h3>,
      content: (
        <h5 className={classes.content}>
          In the calendar you can view all your activiy in a snapshot. If you
          connect your Google© or Microsoft© calendar, all your activities will
          be synced.
        </h5>
      ),
      // locale: { skip: <SkipButton /> },
      placement: "right",
      disableBeacon: true,
      targetAlreadyMounted: true, // don't have to wait until the new path is loaded, if path is changing
      target: "#tourCalendar", // the className used to link with the HTML element
      data: {
        previous: async () => {
          // Wait for the navigation to complete
          await new Promise((resolve) => {
            console.log("waiting for navigation to finish");
            navigate("/app/meeting", {
              state: { meetingID: 1 },
            }); // This will trigger a navigation change, even to the same location
            resolve();
          });

          changeStep("calendar-main", "previous");
        },
        next: () => {
          navigate("/app/actions");
          changeStep("calendar-main", "next");
        },
      },
      styles: {
        spotlight: {
          border: "solid 1px white",
        },
      },
    },

    {
      id: "actions-main",
      title: <h3 className={classes.titleText}>Actions</h3>,
      content: (
        <h5 className={classes.content}>
          Here you can monitor the status of all the actions of the meetings you
          created.
        </h5>
      ),
      // locale: { skip: <SkipButton /> },
      placement: "right",
      disableBeacon: true,
      targetAlreadyMounted: true, // don't have to wait until the new path is loaded, if path is changing
      target: "#tourActions",
      data: {
        previous: () => {
          navigate("/app/calendar");
          changeStep("actions-main", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/todo");
          changeStep("todo-main");
        },
        next: () => {
          changeStep("actions-main", "next");
        },
      },
    },
    {
      id: "actions-line",
      title: <h3 className={classes.titleText}>Actions</h3>,
      content: (
        <h5 className={classes.content}>
          For every action you can view the status and progress on the
          owner&apos;s side
        </h5>
      ),
      // locale: { skip: <SkipButton /> },
      placement: "bottom-end",
      disableBeacon: true,
      targetAlreadyMounted: true, // don't have to wait until the new path is loaded, if path is changing
      target: "#tourActionsLine",
      data: {
        previous: () => {
          changeStep("actions-line", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/todo");
          changeStep("todo-main");
        },
        next: () => {
          changeStep("actions-line", "next");
        },
      },
    },
    {
      id: "actions-details",
      title: <h3 className={classes.titleText}>Action timeline</h3>,
      content: (
        <h5 className={classes.content}>
          In the right panel, you can monitor the action progress with more
          detail
        </h5>
      ),
      // locale: { skip: <SkipButton /> },
      placement: "left",
      disableBeacon: true,
      targetAlreadyMounted: true, // don't have to wait until the new path is loaded, if path is changing
      target: "#tourActionsDetails",
      data: {
        previous: () => {
          changeStep("actions-details", "previous");
        },
        jumpNextMenu: async () => {
          navigate("/app/todo");
          changeStep("todo-main");
        },
        next: () => {
          navigate("/app/todo");
          changeStep("actions-details", "next");
        },
      },
    },
    {
      id: "todo-main",
      title: <h3 className={classes.titleText}>Tasks</h3>,
      content: (
        <h5 className={classes.content}>
          Manage your tasks with boards to show progress and priorities. Actions
          from meetings assigned to you will be added here.
        </h5>
      ),
      // locale: { skip: <SkipButton /> },
      placement: "right",
      disableBeacon: true,
      targetAlreadyMounted: true, // don't have to wait until the new path is loaded, if path is changing

      target: "#tourTodo",
      data: {
        previous: () => {
          navigate("/app/actions");
          changeStep("todo-main", "previous");
        },

        next: () => {
          changeStep("todo-main", "next");
        },
      },
    },
    {
      id: "todo-board",
      title: <h3 className={classes.titleText}>Tasks</h3>,
      content: (
        <h5 className={classes.content}>
          Every board contains a list of tasks that can be dragged accross
          boards to change its status. The right bar color indicates the
          priority.
        </h5>
      ),
      // locale: { skip: <SkipButton /> },
      placement: "right",
      disableBeacon: true,
      targetAlreadyMounted: true, // don't have to wait until the new path is loaded, if path is changing

      target: "#tourTodoBoard",
      data: {
        previous: () => {
          changeStep("todo-board", "previous");
        },

        next: () => {
          changeStep("todo-board", "next");
        },
      },
    },
    {
      id: "todo-from-meeting",
      title: <h3 className={classes.titleText}>Tasks</h3>,
      content: (
        <h5 className={classes.content}>
          If an action of meeting was assigned to you, an additional board will
          appear. If you accept the ownership, it will added as a new task in
          your to do board.
        </h5>
      ),
      // locale: { skip: <SkipButton /> },
      placement: "right",
      disableBeacon: true,
      targetAlreadyMounted: true, // don't have to wait until the new path is loaded, if path is changing

      target: "#tourTodoFromMeeting",
      data: {
        previous: () => {
          changeStep("todo-from-meeting", "previous");
        },
        next: () => {
          changeStep("todo-from-meeting", "next");
          //don't move step yet is it requires the destination to mount
          // setTourState({ ...tourState, moveToStepID: "tour-end" });
        },
      },
    },
    {
      id: "tour-end",
      // title: <h3 className={classes.titleText}>TO DO</h3>,
      content: (
        <h5 className={classes.content}>
          You can always come back to the profile menu and search for the option
          to run the tour again
        </h5>
      ),
      // locale: { skip: <SkipButton /> },
      placement: "bottom",
      disableBeacon: true,
      targetAlreadyMounted: true, // don't have to wait until the new path is loaded, if path is changing

      target: "#tourEnd",
      data: {
        previous: () => {
          changeStep("todo-main");
        },
        // next: () => {
        //   changeStep("todo-from-meeting", "next");
        // },
      },

      // disableOverlayClose: true,
      // hideCloseButton: true,
      // hideFooter: true,

      // spotlightClicks: true,
    },
  ];

  useEffect(() => {
    setTourState({
      ...tourState,
      steps,
    });
  }, []);

  const close = () => {
    changeStep("tour-end");
  };

  const handleCallback = useCallback(
    (data) => {
      // console.log("%cdata", "background-color: red; color: white", data);

      const {
        action,
        origin,
        index,
        step: {
          data: {
            next = undefined,
            previous = undefined,
            jumpNextMenu = undefined,
            backToMain = undefined,
          } = {},
          // targetAlreadyMounted = undefined,
        },
        type,
      } = data;

      // console.log("targetAlreadyMounted", targetAlreadyMounted);

      console.log(
        "%cData in handleCallback",
        "background-color: lightBlue; color: blue",
        data
      );

      // if (action === "skip") {
      //   console.log("%cskipped", "background-color: red; color: white");
      //   next();
      // }

      if (type === "step:after") {
        switch (action) {
          case "next":
            // check if it's the last step
            if (steps.length === index + 1) {
              console.log(
                "%cLast step",
                "background-color: brown; color: yellow"
              );

              // setTourState({
              //   ...tourState,
              //   // run: false,
              //   stepIndex: 0,
              //   tourActive: false,
              // });
              changeStep("meeting-main", false);
              setSession({ ...session, firstUse: false });
            } else {
              if (next === undefined) {
                console.log(
                  "%cERROR: No next was defined inside steps",
                  "background-color: red; color: white"
                );
              } else {
                if (jumpNextMenuButtonPressedRef.current) {
                  console.log(
                    "more details button was pressed.  Executes jumpNextMenu()"
                  );
                  jumpNextMenu();
                } else if (closeButtonPressedRef.current) {
                  close();
                } else {
                  console.log("next button was pressed, Executes next()");
                  next();
                }
              }
            }

            break;
          case "prev":
            // check if it's the first step
            console.log(
              "%cPrevious clicked",
              "background-color: blue; color: white",
              previous
            );

            if (index === 0) {
              console.log(
                "%cERROR: No previous step is possible.  Already in first step",
                "background-color: red; color: white"
              );
            } else {
              if (previous === undefined) {
                console.log(
                  "%cERROR: No previous was defined inside steps",
                  "background-color: red; color: white"
                );
              } else {
                // only navigates.  Change in stepindex is made in the destination after render is complete
                // with useEffect.
                previous();
              }
            }

            if (index === 0) {
              console.log(
                "%cERROR: No previous step is possible.  Already in first step",
                "background-color: red; color: white"
              );
            } else {
              if (previous === undefined) {
                console.log(
                  "%cERROR: No previous was defined inside steps",
                  "background-color: red; color: white"
                );
              } else {
                if (backToMainButtonPressedRef.current) {
                  backToMain();
                } else {
                  previous();
                }
              }
            }
            break;

          case "close":
            console.log("button close");
            if (origin === "overlay") return; // don't do anything if clicking in outside the tooltip

            console.log(
              "%cclose session",
              "background-color: brown; color: yellow",
              session
            );

            changeStep("dashboard-main", false); // resets back tour state to dashboard for next use

            setSession({ ...session, firstUse: false });

            navigate("/app/meetings");

            // updates the db
            updateFirstUseMut({ variables: { userID } });
            break;
          default:
            console.log(
              "%cERROR: not defined.  action is:",
              "background-color: red; color: white",
              action
            );
        }
      }
    },
    [session]
  );

  const tour = useMemo(() => {
    return (
      <Joyride
        callback={handleCallback}
        continuous
        run={firstUse}
        stepIndex={steps.findIndex((item) => item.id === stepID)}
        steps={steps}
        styles={joyrideStyles}
        tooltipComponent={CustomTooltip}
        disableScrolling
        // disableOverlayClose
        // disableCloseOnEsc
        // spotlightClicks
      />
    );
  }, [steps, handleCallback, firstUse]);

  // const tour = () => {
  //   return (
  //     <Joyride
  //       callback={handleCallback}
  //       continuous
  //       run={firstUse}
  //       showSkipButton={true}
  //       stepIndex={steps.findIndex((item) => item.id === stepID)}
  //       steps={steps}
  //       styles={joyrideStyles}
  //       tooltipComponent={CustomTooltip}
  //     />
  //   );
  // };

  return tour;
}
