import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

// custom components
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import AvatarGroup from "components/AvatarGroup/AvatarGroup";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import CustomHeader from "components/CustomHeader/CustomHeader.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import DateRangeComp from "components/DateRange";
import ErrorHandler from "components/Error/Error";
import FilterComponent from "components/FilterComponent";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { RatingIndicator } from "components/RatingIndicator";
import Snackbar from "components/Snackbar/Snackbar";
import SortComponent from "components/SortComponent";
import CustomDialog from "../../components/CustomDialog/CustomDialog";

// data
import { useQuery } from "@apollo/client";
import axios from "axios";
import config from "config";
import {
  axiosOptions,
  debounce,
  formatCurrency,
  formatDateLong,
  formatDateTime,
  formatTime,
  removeTime,
} from "functions/Common";
import useFetch from "functions/useFetch";
import { meetingMisc } from "graphql/gqlMeetingList";
import { tourData } from "utils/tourData";
import { SessionContext } from "../../contexts/SessionContext";

import { isEqual } from "lodash";
import { object, string } from "yup";
// errors
import {
  LogErrorComponent,
  logError,
  useDataForLog,
} from "functions/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

// icons
import TimerOffIcon from "@material-ui/icons/TimerOff";
import UpdateIcon from "@material-ui/icons/Update";

// style
import { makeStyles } from "@material-ui/core/styles";
import {
  dangerColor,
  grayBoxShadow,
  grayBoxShadowNarrow,
  grayColor,
  infoColor,
  primaryColor,
  successColor,
} from "assets/jss/material-dashboard-react";
import "./MeetingList.css";

const styles = {
  accordion: {},
  alignCenter: {
    textAlign: "center",
  },
  alignLeft: {
    textAlign: "left",
    paddingLeft: "15px",
  },
  alignRight: {
    textAlign: "right",
    // paddingRight: "15px",
  },
  attendees: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },

  emptyContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: grayColor[5],
  },
  groupedMeetingsSelectorContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },

  groupedMeetingsSelectorTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
  },
  gridContainer: {
    flex: 1,
    // borderBottom: `1px solid ${grayColor[7]}`,
  },

  headerContainer: {
    ...grayBoxShadow,
    alignItems: "center",
    backgroundColor: "white",
    // backgroundColor: grayColor[8],
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // marginBottom: "15px",
    padding: "5px 38px 0px 20px",
    transition: "all 600ms ease-in-out",
    borderBottom: `1px solid ${grayColor[6]}`,
  },
  header: {
    fontSize: "12px",
    paddingBottom: "10px",
    fontWeight: 600,
  },
  loadingLabel: {
    fontStyle: "italic",
    color: grayColor[4],
    fontWeight: 400,
  },
  loadingContainer: {
    borderRadius: "5px",
    // width: "400px",
    // position: "absolute",
    bottom: "0px",
    // top: "0px",
    height: "30px",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    backgroundColor: grayColor[6],
    margin: "5px 0px",
  },
  meetingDateGroupContainer: {
    ...grayBoxShadowNarrow,
    backgroundColor: "white", //grayColor[7],
    borderRadius: "5px",
    padding: "0px 5px 0px 5px",
    // marginBottom: "10px",
  },
  meetingDateGroupTodayContainer: {
    backgroundColor: infoColor[8],
  },
  meetingDateGroupLabel: {
    fontWeight: 700,
    color: grayColor[2],
    margin: "40px 0px 15px 0px",
  },
  meetingsContainer: {
    // backgroundColor: "yellow",
    // padding: "5px",

    scrollbarGutter: "stable",
    overflowY: "auto",
    overflowAnchor: "none",
    height: "calc(100vh - 207px)",

    // scrollPaddingTop: "33px",
  },
  scrollPaddingUngrouped: { scrollPaddingTop: "33px" },
  scrollPaddingGrouped: { scrollPaddingTop: "65px" },
  meetingLineContainer: {
    alignItems: "center",
    cursor: "default",

    backgroundColor: "white", //grayColor[8],
    // backgroundColor: "yellow",
    // borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "3px 0px 3px 0px",

    padding: "5px 5px",
    // transition: "all 600ms ease-in-out",
    width: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
    "&:hover,&:focus": {
      backgroundColor: grayColor[9],
      // backgroundColor: primaryColor[10],
    },
  },
  meetingLineDivider: {
    borderBottom: `1px solid ${grayColor[7]}`,
  },
  organizerLabel: { flex: 1, textAlign: "right" },

  nameLabel: {
    // backgroundColor: "orange",
  },
  ratingContainer: {
    display: "flex",
    justifyContent: "center",
  },

  status: {
    borderRadius: "3px",
    padding: "8px",
    textAlign: "center",
    color: "white",
    fontSize: "11px",
  },

  statusPlanned: { backgroundColor: grayColor[4] },
  statusDraft: { backgroundColor: primaryColor[6] },
  statusSent: { backgroundColor: "#db4dff" },
  statusLate: { backgroundColor: "#fa914b" },
  statusOngoing: { backgroundColor: successColor[5], color: successColor[1] },
  "@keyframes statusOvertime": {
    from: {
      backgroundColor: dangerColor[1],
    },
    to: {
      backgroundColor: dangerColor[4],
    },
  },
  statusOvertime: {
    color: "white",

    animationName: "$statusOvertime",
    animationDuration: "700ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "cubic-bezier(0.5,0,0.5,1)",
  },

  statusCompleted: { backgroundColor: successColor[1] },
  statusCancelled: { backgroundColor: dangerColor[0] },

  todayContainer: {
    // alignItems: "center",

    // backgroundColor: grayColor[8],
    // backgroundColor: "yellow",
    borderRadius: 5,
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "space-between",
    marginBottom: "7px",
    padding: "10px 5px",
    // transition: "all 600ms ease-in-out",

    backgroundColor: infoColor[8],
    // padding: "10px",
  },
  todayLabel: {
    margin: "0px",
    padding: "0px 0px 5px 10px",
    color: infoColor[4],
  },
};

const useStyles = makeStyles(styles);

export default function MeetingList() {
  console.log("starts meeting list");
  // console.log("%cStarts Meeting list", "background-color: red; color: white");

  const generalData = useDataForLog(); // context data to populate error log
  try {
    const classes = useStyles();

    const { session, setSession } = useContext(SessionContext);
    const {
      accountID,
      firstUse,
      isOwner,
      languageID,
      stageID,
      userID,
      userName,
    } = session;

    const navigate = useNavigate();
    const location = useLocation();
    const snackBarInfo = location?.state?.snackBarInfo || null;
    const [showSnackbar, setShowSnackbar] = useState({
      show: false,
      messageType: "",
      message: "",
    });

    const [groupedByDate, setGroupedByDate] = useState(true);
    // const [updateInfo, setUpdateInfo] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [page, setPage] = useState({
      goingUpNumber: 0,
      goingDownNumber: 1,
      direction: "down",
      endDate: null,
      pageSizeUp: 10,
      pageSizeDown: 40,
    });
    // const [dateRange, setDateRange] = useState({
    //   from: addDaysToDate(new Date(), -5),
    //   to: addDaysToDate(new Date(), 30),
    // });
    const [parameterList, setParameterList] = useState({
      meetingGroupID: 0,
      filterID: 2,
      orderByID: 0,
      oneOnOneUserID: 0,
      // shouldUpdate: true,
      filters: [
        // {
        //   id: "123",
        //   field: 6,
        //   fieldType: "range",
        //   values: [12, ""],
        //   operator: null,
        // },
      ],
      sorts: [],
      direction: "down",
      dateGrouped: true,

      // searchTerm: "",
    });

    //counter to detect number of renders.
    const renderCountRef = useRef(firstUse ? 1 : 0); // if first use, count as first render, otherwise tour won't workg

    const fetchParameters = {
      url: `${config.url}/api/meeting-list`,
      method: "get",
      params: {
        userID: userID,
        languageID: languageID,
        meetingGroupID: parameterList.meetingGroupID,
        oneOnOneUserID: parameterList.oneOnOneUserID,
        filter: parameterList.filters.filter(
          (item) => item.filterLineStatus === "ready"
        ),
        sort: parameterList.sorts.filter(
          (item) => item.sortLineStatus === "ready"
        ),
        // startDate: dateRange.from.toISOString(),
        // endDate: dateRange.to.toISOString(),

        // searchTerm: parameterList.searchTerm,
      },
      pageSize: page.direction === "down" ? page.pageSizeDown : page.pageSizeUp,
      pageNumber:
        page.direction === "down" ? page.goingDownNumber : page.goingUpNumber, // page number is placed outside to also be able to trigger fetch when only the page number changes without other parameters changing
      direction: page.direction,
      endDate: page.endDate,
    };

    const { loadingFetch, errorFetch, dataFetch, hasMoreFetch } = useFetch({
      ...fetchParameters,
      params: JSON.stringify(fetchParameters.params), // has be sent as a string and parsed after, otherwise runs into loop because objects are sent a reference which changes every time
      skip: firstUse,
    });

    console.log("%cdata received", "background-color: blue; color: white", {
      loadingFetch,
      errorFetch,
      dataFetch,
      hasMoreFetch,
    });

    const [errorState, setErrorState] = useState({
      error: errorFetch,
      errorExplanation: "Couldn't retrieve meeting list",
      data: {
        errorLevel: "fatal",
        otherData: { axiosOptions: fetchParameters },
      },
    });

    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState({
      from: "",
      to: "",
    });
    const [modal, setModal] = useState({
      visible: false,
      name: "",
    });

    const [searchTerm, setSearchTerm] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    // console.log("data in meeting list", dataFetch);

    const previousDataRef = useRef();

    const todayRef = useRef();
    // const observerToday = useRef();
    // const todayObserverRef = useCallback((node) => {
    //   console.log("today is not visible");
    //   if (loadingFetch) return;
    //   if (todayObserverRef.current) todayObserverRef.current.disconnect();
    //   todayObserverRef.current = new IntersectionObserver((entries) => {
    //     console.log("today is visible");
    //     return node;
    //   });

    //   if (todayRef.current) todayObserverRef.current.observe(todayRef.current);
    // });

    const previousFirstMeetingDisplayedRef = useRef();
    // const meetingListRef = useRef();
    // const currentScrollRef = useRef();

    // this code allow to detect if the last meeting is displayed and if true
    const observerLast = useRef();
    const lastMeetingDisplayedRef = useCallback(
      (node) => {
        if (loadingFetch) return;
        if (firstUse) return; // don't execute because it's loading dummy data
        if (observerLast.current) observerLast.current.disconnect();

        const observerCallback = (entries) => {
          if (entries[0].isIntersecting && hasMoreFetch.down) {
            console.log(
              "%cis visible with page",
              "background-color: red; color: white",
              pageNumber
            );

            // setPageNumber(pageNumber + 1);
            setPage({
              ...page,
              goingDownNumber: page.goingDownNumber + 1,
              direction: "down",
            });

            // setDateRange({
            //   ...dateRange,
            //   from: addDaysToDate(dateRange.from, -7),
            // });
          }
        };

        const observer = new IntersectionObserver(observerCallback, {
          threshold: 1,
        });
        if (node) observer.observe(node);
      },
      [loadingFetch, hasMoreFetch.down]
    );

    const observerFirst = useRef();
    const firstMeetingDisplayedRef = useCallback(
      (node) => {
        if (loadingFetch) return;
        if (firstUse) return; // don't execute because it's loading dummy data
        if (observerFirst.current) observerFirst.current.disconnect();

        const observerCallback = (entries) => {
          // console.log("firstMeetingDisplayedRef", node);
          previousFirstMeetingDisplayedRef.current = node;
          if (entries[0].isIntersecting && hasMoreFetch.up) {
            console.log("el dataFetch", dataFetch);
            console.log(
              "%cfirst record is visible with page",
              "background-color: red; color: white",
              {
                ...page,
                goingUpNumber: page.goingUpNumber + 1,
                direction: "up",
                endDate: dataFetch[0]?.date,
              }
            );
            // ******************************************* uncomment *************************************************************************************************
            setPage({
              ...page,
              goingUpNumber: page.goingUpNumber + 1,
              direction: "up",
              endDate: dataFetch[0].date,
            });
          }
        };

        const observer = new IntersectionObserver(observerCallback, {
          threshold: 1,
        });

        if (node) observer.observe(node);
      },
      [loadingFetch, hasMoreFetch.up]
    );

    const { loading, data, error, refetch } = useQuery(meetingMisc, {
      variables: {
        accountID,
        userID,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      skip: firstUse,
    });

    // every time a fetch occurs, adds a new render
    renderCountRef.current =
      loadingFetch === false
        ? renderCountRef.current + 1
        : renderCountRef.current;

    useEffect(() => {
      // console.log(
      //   "%cuse effect data fetch",
      //   "background-color: lightBlue; color: red",
      //   {
      //     previousDataRef:
      //       previousDataRef?.current?.length > 0
      //         ? previousDataRef.current[0].date
      //         : dataFetch[0].date,
      //     dataFetch: dataFetch.length > 0 ? dataFetch[0].date : null,
      //   }
      // );
      previousDataRef.current = dataFetch;
      if (page.direction === "up") {
        previousFirstMeetingDisplayedRef.current?.scrollIntoView({
          block: "start",
        });
      }
    }, [dataFetch]);

    // when the first render occurs, move screen to today's date
    useEffect(() => {
      if (renderCountRef.current === 1) {
        // console.log(
        //   "%ctries to scroll to today",
        //   "background-color: red; color: yellow"
        // );

        todayRef.current?.scrollIntoView({ block: "center" });
      }
    }, [renderCountRef.current]);

    useEffect(() => {
      // this use effect is used to display snackbar after navigating to this page from another page
      if (snackBarInfo) {
        // data has to be stringified and parse because non primitive values can be sent accross useLocation
        const parsedSnackBarInfo = JSON.parse(snackBarInfo);
        const { messageType, message } = parsedSnackBarInfo;
        setShowSnackbar({
          show: true,
          messageType: messageType,
          message: message,
        });
      }
    }, [snackBarInfo]);

    // loads the dropdowns use for filter and sorting
    const meetingNew = (parentEventID) => {
      // console.log("props", props);
      // const { parent_event_id: parentEventID } = props || {};
      console.log("parentEventID", parentEventID);
      setIsLoading(true);

      const options = axiosOptions({
        url: `${config.url}/api/meeting-new`,
        method: "post",
        data: {
          userID: userID,
          languageID: languageID,
          parentEventID: parentEventID,
        },
      });

      axios(options)
        .then(async (response) => {
          setIsLoading(false);
          console.log("axios response", response.data);

          sessionStorage.setItem("meetingID", response.data.info.meeting_id);

          navigate("/app/meeting", {
            state: { meetingID: response.data.info.meeting_id },
          });
        })
        .catch((error) => {
          console.log("error is:", error);
          return <ErrorHandler error={error} />;
        });
    };

    const ModalToShow = () => {
      switch (modal.name) {
        case "notInPlanOwner": {
          return (
            <CustomDialog
              iconPreset="forbidden"
              center
              visibleStatus={modal}
              setVisibleStatus={setModal}
              title="Plan limit reached"
              mainText={`You can't create more than ${modal.data.meetingSlotsCapacity} meetings in the Basic plan.  Your account has to upgrade to have unlimited meetings and unlock all the features`}
              buttonActionLabel1="Cancel"
              // buttonActionColor1="danger"
              buttonActionFunction1={() => {
                setModal({ visible: false, name: "" });
              }}
              buttonActionLabel2="Upgrade your plan"
              buttonActionFunction2={() =>
                navigate({ pathname: "/app/account" })
              }
            />
          );
        }

        case "notInPlanNotOwner": {
          return (
            <CustomDialog
              iconPreset="forbidden"
              center
              visibleStatus={modal}
              setVisibleStatus={setModal}
              title="Plan limit reached"
              mainText={`You can't create more than ${modal.data.meetingSlotsCapacity} meetings in the Basic plan.  Contact your account owner to upgrade and have unlimited meetings`}
              buttonActionLabel1="OK"
              buttonActionFunction1={() => {
                setModal({ visible: false, name: "" });
              }}
            />
          );
        }

        // case "notInPlan": {
        //   let message;
        //   let title;
        //   let buttonLabel;
        //   switch (stageID) {
        //     case 0:
        //       title = "Plan limit reached";
        //       message =
        //         "You can't create new meetings in this month in the Basic plan.  Upgrade to have unlimited meetings and unlock all the features";
        //       buttonLabel = "Go to subscription";
        //       break;
        //     case 1:
        //       title = "Create meeting is currently not allowed";
        //       message = "Contact your Orquesti's organization administrator";
        //       buttonLabel = "Go to subscription";
        //       break;

        //     case 3:
        //       title = "Create meeting is currently not allowed";
        //       message = "Your trial has expired.  Renew your subscription.";
        //       buttonLabel = "Go to subscription";
        //       break;

        //     case 6:
        //       title = "Create meeting is currently not allowed";
        //       message = "You don't have an active subscription.";
        //       buttonLabel = "Go to subscription";
        //       break;
        //     case 20:
        //       title = "Plan limit";
        //       message =
        //         "You can't create more meetings in this month for your current plan";
        //       buttonLabel = "Upgrade your plan";
        //       break;
        //   }
        //   console.log("el stage id es", stageID);
        //   console.log("el mensaje es", message);
        //   return (
        //     <CustomDialog
        //       iconPreset="forbidden"
        //       center
        //       visibleStatus={modal}
        //       setVisibleStatus={setModal}
        //       title={title}
        //       mainText={message}
        //       buttonActionLabel1="Cancel"
        //       // buttonActionColor1="danger"
        //       buttonActionFunction1={() => {
        //         setModal({ visible: false, name: "" });
        //       }}
        //       buttonActionLabel2={buttonLabel}
        //       buttonActionFunction2={() =>
        //         // navigate({ pathname: "/app/account" })
        //         navigate("/app/account")
        //       }
        //     />
        //   );
        // }
        default:
          return null;
      }
    };

    if (error) {
      return (
        <ErrorHandler
          error={error}
          errorClear={() => setErrorState()}
          retryFunction={() => refetch()}
          data={{
            errorLevel: "fatal",
            generalData: { ...generalData },
            otherData: {
              userID: userID,
              languageID: languageID,
              meetingGroupID: parameterList.meetingGroupID,
              filterID: parameterList.filterID,
              orderByID: parameterList.orderByID,
              // searchTerm: parameterList.searchTerm,
              searchTerm: searchTerm,
            },
          }}
        />
      );
    }

    // console.log("%cdata", "background-color: red; color: white", data);

    const listsDropdown = data?.spMeetingListDropdown?.nodes[0];

    const MeetingHeader = () => {
      return (
        <div className={classes.headerContainer}>
          <GridContainer
            spacing={2}
            direction="row"
            classes={{ container: classes.gridContainer }}
          >
            <GridItem xs={3}>
              <h5 className={`${classes.header} ${classes.alignLeft}`}>
                Subject
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <h5 className={`${classes.header} ${classes.alignCenter}`}>
                {groupedByDate ? "Time" : "Date"}
              </h5>
            </GridItem>
            <GridItem xs={1}>
              <h5 className={`${classes.header} ${classes.alignCenter}`}>
                Group
              </h5>
            </GridItem>

            <GridItem xs={1}>
              <h5 className={`${classes.header} ${classes.alignCenter}`}>
                Status
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <h5 className={`${classes.header} ${classes.alignCenter}`}>
                Attendees
              </h5>
            </GridItem>

            <GridItem xs={1}>
              <h5 className={`${classes.header} ${classes.alignCenter}`}>
                Organizer
              </h5>
            </GridItem>

            <GridItem xs={1}>
              <h5 className={`${classes.header} ${classes.alignCenter}`}>
                Rating
              </h5>
            </GridItem>

            <GridItem xs={1}>
              <h5 className={`${classes.header} ${classes.alignCenter}`}>
                Cost
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      );
    };

    // lowest level of detail for each meeting
    const MeetingLine = forwardRef((props, ref) => {
      const { meeting, isLastMeeting } = props;

      // console.log("%cMeetingLine", "background-color: red; color: white", {
      //   meeting,
      //   isLastMeeting,
      // });

      let statusColor;
      switch (meeting.status_id) {
        case 0:
          statusColor = classes.statusPlanned;
          break;
        case 1:
          statusColor = classes.statusDraft;
          break;
        case 2:
          statusColor = classes.statusSent;
          break;
        case 3:
          statusColor = classes.statusOngoing;

          break;
        case 4:
          statusColor = classes.statusCompleted;
          break;
        case 5:
          statusColor = classes.statusCancelled;
          break;
        case 7:
          statusColor = classes.statusOvertime;
          break;
        case 8:
          statusColor = classes.statusLate;
          break;
        default:
      }

      return (
        <div
          ref={ref}
          className={`${classes.meetingLineContainer} ${
            !isLastMeeting && classes.meetingLineDivider
          }`}
          onClick={() => {
            console.log(
              "%cMeeting info",
              "background-color: yellow; color: red",
              {
                parentEventID: meeting.parent_event_id,
                startDateOccurrence: meeting.start_date,
                endDateOccurrence: meeting.end_date,
                tempMeetingID: meeting.temp_meeting_id,
              }
            );

            // if the meeting is null, it means it's one of the occurrences of a recurring meeting
            if (meeting.id === null) {
              navigate("/app/meeting", {
                state: {
                  parentEventID: meeting.parent_event_id,
                  startDateOccurrence: meeting.start_date,
                  endDateOccurrence: meeting.end_date,
                  tempMeetingID: meeting.temp_meeting_id,
                },
              });
            } else {
              // navigate({
              //   pathname: "/app/meeting",
              //   search: `id=${meeting.id}`,
              // });
              navigate("/app/meeting", {
                state: { meetingID: meeting.id },
              });
            }
          }}
        >
          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                data={{
                  location: "Meeting list line",
                  userID: userID,
                  meetingInfo: meeting,
                }}
              />
            )}
          >
            <GridContainer
              spacing={2}
              direction="row"
              classes={{ container: classes.gridContainer }}
              alignItems="center"
            >
              {/* title (show id if in development)*/}
              <GridItem xs={3}>
                {/* <h5 className={classes.nameLabel}>{`${
                  userID === 1 ? `${meeting.id}-` : ``
                } ${meeting.title}`}</h5> */}
                <h5 className={classes.nameLabel}>
                  {process.env.NODE_ENV === "development"
                    ? `${meeting.id}-${meeting.title}`
                    : meeting.title}
                </h5>

                {/* <h5 className={classes.nameLabel}>{meeting.title}</h5> */}
              </GridItem>

              {/* date */}
              <GridItem xs={2}>
                <h5 className={classes.alignCenter}>
                  <ErrorBoundary
                    FallbackComponent={(error) => (
                      <LogErrorComponent
                        error={error}
                        data={{
                          location: "Meeting list line",
                          userID: userID,
                          meetingInfo: meeting,
                        }}
                      />
                    )}
                  >
                    {groupedByDate
                      ? formatTime(meeting.date, {
                          meetingInfo: meeting,
                        })
                      : formatDateTime(meeting.date, {
                          meetingInfo: meeting,
                        })}
                  </ErrorBoundary>
                </h5>
              </GridItem>

              {/* meeting group */}
              <GridItem xs={1}>
                <h5 className={classes.alignCenter}>{meeting.meeting_group}</h5>
              </GridItem>

              {/* status */}
              <GridItem xs={1}>
                <h6 className={`${classes.status} ${statusColor}`}>
                  {meeting.status}
                  {/* {meeting.status_id === 3 &&
                    new Date() > new Date(meeting.end_date)
                      ? "Overtime"
                      : meeting.status_id === 2 &&
                        new Date() > new Date(meeting.start_date)
                      ? "Late"
                      : meeting.status} */}
                </h6>
              </GridItem>

              {/* attendees */}
              <GridItem xs={2}>
                <div className={classes.attendees}>
                  <AvatarGroup
                    summary
                    persons={meeting.attendees}
                    maxIcons={4}
                  />
                </div>
              </GridItem>

              {/* organizer */}
              <GridItem xs={1}>
                <h5 className={classes.organizerLabel}>
                  {meeting.organizer_name}
                </h5>
              </GridItem>

              {/* rating */}
              <GridItem xs={1}>
                <div className={classes.ratingContainer}>
                  {{
                    // Feedback available
                    1: <RatingIndicator rating={meeting.rating} size="sm" />,

                    // not applicable
                    2: (
                      <CustomTooltip
                        tooltipText={meeting.feedback_status_tooltip}
                        placement="bottom"
                      >
                        <h5 className={classes.costLabel}>-</h5>
                      </CustomTooltip>
                    ),

                    // pending
                    3: (
                      <CustomTooltip
                        tooltipText={meeting.feedback_status_tooltip}
                        placement="bottom"
                      >
                        <UpdateIcon
                          style={{ color: infoColor[5], fontSize: 20 }}
                        />
                      </CustomTooltip>
                    ),

                    // No feedback
                    4: (
                      <CustomTooltip
                        tooltipText={meeting.feedback_status_tooltip}
                        placement="bottom"
                      >
                        <HighlightOffIcon
                          style={{
                            color: dangerColor[1],
                            fontSize: 20,
                          }}
                        />
                      </CustomTooltip>
                    ),

                    // Expired
                    5: (
                      <CustomTooltip
                        tooltipText={meeting.feedback_status_tooltip}
                        placement="bottom"
                      >
                        <TimerOffIcon
                          style={{
                            color: dangerColor[1],
                            fontSize: 20,
                          }}
                        />
                      </CustomTooltip>
                    ),
                  }[meeting.feedback_status_id] || "-"}
                </div>
              </GridItem>

              {/* cost */}
              <GridItem xs={1}>
                <h5 className={classes.alignCenter}>
                  {meeting.cost === null
                    ? "-"
                    : formatCurrency.format(meeting.cost)}
                </h5>
              </GridItem>
            </GridContainer>
          </ErrorBoundary>
        </div>
      );
    });

    const MeetingListSub = () => {
      console.log("meeting list sub");
      // shows the previous data while loading to avoid blink with showing blank page when loading insted
      const latestData = () => {
        // console.log(
        //   "%cstarts latest data",
        //   "background-color: red; color: white",
        //   firstUse
        // );

        if (firstUse) {
          const tourDummyData = tourData("meetingList");

          return tourDummyData;
        } else {
          if (loadingFetch === true) {
            if (previousDataRef.current === undefined) {
              return dataFetch;
            } else {
              return previousDataRef.current;
            }
          } else {
            return dataFetch;
          }
        }
      };

      previousDataRef.current === undefined
        ? dataFetch
        : previousDataRef.current;

      // console.log(
      //   "%clatest data",
      //   "background-color: red; color: white",
      //   latestData()
      // );

      const filteredMeetings = latestData()?.filter((item) =>
        (item.title || "")
          ?.toLowerCase()
          .includes((searchTerm || "").toLowerCase())
      );

      // label to show while loading or if no more meetings are available
      const MeetingsLoading = () => {
        return (
          <div className={classes.loadingContainer}>
            <h6 className={classes.loadingLabel}>
              {loadingFetch
                ? "Loading more..."
                : // : hasMoreFetch.up && hasMoreFetch.down
                  // ? "Loading more..."
                  "No more meetings"}

              {/* {loadingFetch ? "Loading more..." : "No more meetings"} */}
            </h6>
          </div>
        );
      };

      // assigns specifi divs to meeting line based on it position (first, last)
      // in order to be able to detect when to retrieve more data
      const MeetingLineRefSelector = (props) => {
        const { meeting, isLastDate, isFirstDate, isLastMeeting } = props;
        const isLastFirstMeeting = () => {
          if (previousDataRef?.current?.length > 0) {
            if (previousDataRef.current[0].date === meeting.date) {
              return true;
            } else {
              return false;
            }
          }
        };

        if (isLastDate) {
          // if it's the last item, attaches a ref that will later allow to trigger more records when this element becomes visible on screen using observerFirst

          return (
            <MeetingLine
              ref={lastMeetingDisplayedRef}
              meeting={meeting}
              isLastMeeting={isLastMeeting} // allows to show or remove dividing lines
            />
          );
        } else if (isFirstDate) {
          // if it's the first item, attaches a ref that will later allow to trigger more records when this element becomes visible on screen using observerFirst

          return (
            <MeetingLine
              ref={firstMeetingDisplayedRef}
              meeting={meeting}
              isLastMeeting={isLastMeeting}
            />
          );
        } else if (isLastFirstMeeting()) {
          // if it's the first item, attaches a ref that will later allow to trigger more records when this element becomes visible on screen using observerFirst
          return (
            // <div style={{ backgroundColor: "green", padding: "5px" }}>
            <MeetingLine
              ref={previousFirstMeetingDisplayedRef}
              meeting={meeting}
              isLastMeeting={isLastMeeting}
            />
          );
        } else {
          return (
            <MeetingLine meeting={meeting} isLastMeeting={isLastMeeting} />
          );
        }
      };

      // encloses the current date with a highlighted div
      const MeetingToday = (props) => {
        const { currentDateConverted, todayDate, children } = props;
        return (
          <div id="tourLine" ref={todayRef} className={classes.todayContainer}>
            <h5 className={classes.todayLabel}>
              {currentDateConverted.toISOString() === todayDate.toISOString()
                ? "Today"
                : "No meetings for today"}
            </h5>
            {currentDateConverted.toISOString() === todayDate.toISOString() &&
              children}
          </div>
        );
      };

      // groups by date the data received
      const MeetingsGroupedByDate = () => {
        console.log(
          "%cFilteredMeetings",
          "background-color: blue; color: white",
          filteredMeetings
        );

        // creates groups based on dates
        const groupedData = filteredMeetings
          .reduce((groupedDates, meeting) => {
            const currentDate = removeTime(new Date(meeting.start_date));

            // evaluates if there's already a row with the date
            if (
              groupedDates.filter(
                (item) => item.date == currentDate.toISOString()
              ).length === 0
            ) {
              // console.log("data not found, createds empty group", {
              //   date: currentDate.toISOString(),
              //   meetings: [meeting],
              // });
              // group not found, therefore creates a new date group
              groupedDates.push({
                date: currentDate.toISOString(),
                meetings: [meeting],
              });
            } else {
              // group found

              // search the object to modify
              const index = groupedDates.findIndex(
                (item) => item.date == currentDate.toISOString()
              );
              // add the meeting to the meetings field of the corresponding date
              groupedDates[index].meetings.push(meeting);
            }

            return groupedDates;
          }, [])
          .sort((a, b) => {
            // sort descending
            const dateA = new Date(a);
            const dateB = new Date(b);
            return dateB - dateA;
          });
        // console.log("%cgrouped data", "background-color: red; color: white", {
        //   groupedData,
        //   filteredMeetings,
        // });

        // --------------  search if today exists or not in the list.  If not, adds the date group ---------------
        const todayGroup = {
          date: removeTime(new Date()).toISOString(),
          meetings: [],
        };

        let groupedDataWithToday = [];
        let todayAdded = false;

        // Check if the new event's start date already exists in any of the existing events
        const eventExists = groupedData.some(
          (event) => event.date === removeTime(new Date()).toISOString()
        );

        // Find the index where the new event should be inserted
        const index = groupedData.findIndex(
          (event) => new Date(event.date) < new Date()
        );

        if (!eventExists) {
          if (index === -1) {
            // If index is -1, it means the new event should be added at the end
            groupedData.push(todayGroup);
          } else {
            // Insert the new event at the correct index
            groupedData.splice(index, 0, todayGroup);
          }
        }

        return groupedData?.map((meetingDate, index) => {
          // if previously detected date, returns to false
          let currentDateFound = false;

          const currentDate = meetingDate.date;
          const previousDate = index === 0 ? null : groupedData[index - 1];
          const isLastDate = groupedData.length === index + 1;
          const isFirstDate = index === 0;

          const todayDate = removeTime(new Date());
          const currentDateConverted = removeTime(new Date(currentDate));
          const previousDateConverted =
            index === 0
              ? removeTime(new Date())
              : removeTime(new Date(previousDate.date));

          if (
            currentDateFound === false &&
            todayDate.toISOString() === currentDateConverted.toISOString()
          ) {
            currentDateFound = true;
          }

          // loops through all meetings in a specific date
          const MeetingAllPerDate = (props) => {
            const { meetingDate, isLastDate, isFirstDate } = props;

            return meetingDate.meetings.map((meeting, index2) => {
              const isLastMeeting = meetingDate.meetings.length === index2 + 1;
              return (
                <div key={meeting.id || meeting.temp_meeting_id}>
                  <MeetingLineRefSelector
                    meeting={meeting}
                    isLastDate={isLastDate}
                    isFirstDate={isFirstDate}
                    isLastMeeting={isLastMeeting}
                  />
                </div>
              );
            });
          };

          return (
            <div key={currentDate}>
              {isFirstDate && <MeetingsLoading />}
              {groupedByDate && (
                <h5 className={classes.meetingDateGroupLabel}>
                  {formatDateLong(currentDate)}
                </h5>
              )}

              <div
                className={`${classes.meetingDateGroupContainer} ${
                  currentDateFound === true &&
                  classes.meetingDateGroupTodayContainer
                }`}
              >
                {currentDateFound === true ? (
                  <MeetingToday
                    currentDateConverted={currentDateConverted}
                    todayDate={todayDate}
                  >
                    <MeetingAllPerDate
                      meetingDate={meetingDate}
                      isLastDate={isLastDate}
                      isFirstDate={isFirstDate}
                    />
                  </MeetingToday>
                ) : (
                  <MeetingAllPerDate
                    meetingDate={meetingDate}
                    isLastDate={isLastDate}
                    isFirstDate={isFirstDate}
                  />
                )}
              </div>
              {isLastDate && <MeetingsLoading />}
            </div>
          );
        });
      };
      console.log("filteredMeetings", filteredMeetings?.length);

      if (
        (filteredMeetings === undefined || filteredMeetings?.length === 0) &&
        !loadingFetch
      ) {
        return (
          <div className={classes.emptyContainer}>
            <h3>No meetings to display</h3>
          </div>
        );
      } else {
        // if (groupedByDate){} else
        return <MeetingsGroupedByDate />;
      }
    };

    const meetings = () => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <MeetingHeader />

          {/* builds the meeting list */}

          <ErrorBoundary
            FallbackComponent={(error) => (
              <LogErrorComponent
                error={error}
                data={{
                  location: "Meeting list",
                  userID: userID,
                }}
              />
            )}
          >
            <div
              // ref={meetingListRef}
              className={`${classes.meetingsContainer} ${classes.scrollPaddingGrouped}`}
              // style={{ display: firstRender === true ? "block" : "none" }} // hiding allows the meetings to be render, scrolled to current date and then display
            >
              <MeetingListSub />

              {/* <div>{errorFetch && "Error"}</div> */}
            </div>
          </ErrorBoundary>
        </div>
      );
    };

    const columnList = [
      { id: 1, menuDesc: "Date", fieldType: "range", canSort: true },
      { id: 2, menuDesc: "Group", fieldType: "list", canSort: true },
      { id: 3, menuDesc: "Status", fieldType: "list", canSort: true },
      { id: 4, menuDesc: "Attendees", fieldType: "list", canSort: false },
      { id: 5, menuDesc: "Organizer", fieldType: "list", canSort: true },
      { id: 6, menuDesc: "Rating", fieldType: "range", canSort: true },
      { id: 7, menuDesc: "Cost", fieldType: "range", canSort: true },
    ];

    // console.log("----------------------la data es", data);
    const filterValuesSelector = (filterLine, updateFiltersParent) => {
      // console.log(
      //   "%cruns filterValueSelector with filterLine:",
      //   "background-color: red; color: white",
      //   filterLine
      // );

      // use to change the type of selector used for displaying the filter values
      switch (filterLine.field) {
        // ---------------- date ---------------------
        case 1:
          return (
            <DateRangeComp
              height="30px"
              startDate={filterLine.values[0]}
              endDate={filterLine.values[1]}
              placeholder={"Select date range"}
              iconSize={14}
              sendInfoToParent={(item) => {
                // creates the new filter line
                const newLineFilter = {
                  ...filterLine,
                  values: [item[0].startDate, item[0].endDate],
                };
                // updates all the filters with the new value
                updateFiltersParent(newLineFilter);
              }}
            />
          );

        // ---------------- group ---------------------
        case 2: {
          // console.log(
          //   "%cfiltersLists",
          //   "background-color: red; color: white",
          //   listsDropdown
          // );

          const groupsListModified = listsDropdown?.groupsList?.map((item) => {
            // add the selected field
            const isSelected = filterLine?.values.includes(item.id);
            return { ...item, selected: isSelected };
          });

          return (
            <CustomMainInput
              dropDownList={groupsListModified}
              placeholder="Select group"
              dropDownFunction={(valuesSelected) => {
                // creates the new filter line
                const newLineFilter = {
                  ...filterLine,
                  values: valuesSelected,
                };
                console.log(
                  "%cvalues selected",
                  "background-color: gray; color: white",
                  newLineFilter
                );
                // updates all the filters with the new value
                updateFiltersParent(newLineFilter);
              }}
              value={filterLine.values}
              multiselect
              variant="dropdown"
            />
          );
        }
        // ---------------- status ----------------
        case 3: {
          const statusListModified = listsDropdown?.statusList?.map((item) => {
            // add the selected field
            const isSelected = filterLine?.values.includes(item.id);
            return { ...item, selected: isSelected };
          });
          return (
            <CustomMainInput
              dropDownList={statusListModified}
              placeholder="Select status"
              dropDownFunction={(valuesSelected) => {
                // creates the new filter line
                const newLineFilter = {
                  ...filterLine,
                  values: valuesSelected,
                };
                // updates all the filters with the new value
                updateFiltersParent(newLineFilter);
              }}
              value={filterLine.values}
              multiselect
              variant="dropdown"
            />
          );
        }
        // ---------------- attendees ----------------
        case 4: {
          const attendeesListModified = listsDropdown?.attendeesList?.map(
            (item) => {
              // add the selected field
              const isSelected = filterLine?.values.includes(item.id);
              return { ...item, selected: isSelected };
            }
          );
          return (
            <CustomMainInput
              dropDownList={attendeesListModified}
              placeholder="Select attendees"
              dropDownFunction={(valuesSelected) => {
                // creates the new filter line
                const newLineFilter = {
                  ...filterLine,
                  values: valuesSelected,
                };
                // updates all the filters with the new value
                updateFiltersParent(newLineFilter);
              }}
              value={filterLine.values}
              multiselect
              avatar
              includeSearch
              variant="dropdown"
            />
          );
        }
        // ---------------- organizer ----------------
        case 5: {
          const organizersListModified = listsDropdown?.organizersList?.map(
            (item) => {
              // add the selected field
              const isSelected = filterLine?.values.includes(item.id);
              return { ...item, selected: isSelected };
            }
          );
          return (
            <CustomMainInput
              dropDownList={organizersListModified}
              placeholder="Select organizer"
              dropDownFunction={(valuesSelected) => {
                // creates the new filter line
                const newLineFilter = {
                  ...filterLine,
                  values: valuesSelected,
                };
                // updates all the filters with the new value
                updateFiltersParent(newLineFilter);
              }}
              value={filterLine.values}
              multiselect
              avatar
              includeSearch
              variant="dropdown"
            />
          );
        }
        // ---------------- rating ---------------------
        case 6:
        case 7: {
          const validate = (fromValue, toValue, currentField) => {
            const obj = {
              from: fromValue,
              to: toValue,
            };
            const yupObj = object().shape({
              from: string()
                .nullable()
                .test(
                  "Must be higher than last range",
                  "Must be higher than last range",
                  (input, data) => {
                    console.log("dataParent", data);
                    const fromNumber =
                      input.length === 0 ? 0 : parseFloat(input);
                    const toNumber =
                      (data.parent.to || null) === null
                        ? 0
                        : parseFloat(data.parent.to);
                    console.log("data", {
                      from: fromNumber,
                      to: toNumber,
                      input: input,
                      evaluation: fromNumber > toNumber,
                    });
                    if (currentField === "from") {
                      if (fromNumber < toNumber) {
                        console.log("false");
                        return true;
                      } else {
                        console.log("true");
                        return false;
                      }
                    } else {
                      return true;
                    }
                  }
                ),
              to: string()
                .nullable()
                .test(
                  "Must be higher than initial range",
                  "Must be higher than initial range",
                  (input, data) => {
                    // const fromNumber = parseFloat(data.parent.from);
                    const fromNumber =
                      (data.parent.from || null) === null
                        ? 0
                        : parseFloat(data.parent.from);

                    const toNumber = input.length === 0 ? 0 : parseFloat(input);
                    console.log("data", {
                      from: fromNumber,
                      to: toNumber,
                      input: input,
                      evaluation: fromNumber > toNumber,
                    });
                    if (currentField === "to") {
                      if (fromNumber < toNumber) {
                        console.log("false");
                        return true;
                      } else {
                        console.log("true");
                        return false;
                      }
                    } else {
                      return true;
                    }
                  }
                ),
            });
            yupObj
              .validate(obj)
              .then(function (value) {
                // if validation is successful
                console.log("successful validation");
                // onChange(fromValue, toValue);

                const newLineFilter = {
                  ...filterLine,
                  values: [
                    fromValue === null ? null : parseFloat(fromValue), //e.target.value === "" ? "" : parseFloat(e.target.value),
                    toValue === null ? null : parseFloat(toValue),
                  ],
                  // error: hasError ? errorDetails : undefined, // removes any previous error
                };

                console.log("new filter line", newLineFilter);
                // updates all the filters with the new value
                updateFiltersParent(newLineFilter);

                // removes any previous errors
                setValues({
                  from: fromValue,
                  to: toValue,
                  toError: undefined,
                  fromError: undefined,
                });

                // // creates the new filter line
                // const newLineFilter = {
                //   ...filterLine,
                //   values: [
                //     fromValue === null ? null : parseFloat(fromValue), //e.target.value === "" ? "" : parseFloat(e.target.value),
                //     toValue === null ? null : parseFloat(toValue),
                //   ],
                //   error: undefined, // removes any previous error
                // };

                // console.log("new filter line", newLineFilter);
                // // updates all the filters with the new value
                // updateFiltersParent(newLineFilter);
              })
              .catch(function (err) {
                // onChange(fromValue, toValue, true);

                // const errorDetails = () => {
                //   if (currentField === "from") {
                //     return { from: err.message };
                //   } else if (currentField === "to") {
                //     return { to: err.message };
                //   }
                // };

                console.log("UNsuccessful validation", {
                  errorPath: err.path,
                  from: fromValue,
                  to: toValue,
                  errorDetails: err.message,
                });

                setValues({
                  ...values,
                  from: fromValue,
                  to: toValue,
                  [`${currentField}Error`]: err.message,
                });

                // console.log("values", { fromValue, toValue });

                // const errorDetails = () => {
                //   if (err.path === "from") {
                //     return { from: err.message };
                //   } else if (err.path === "to") {
                //     return { to: err.message };
                //   }
                // };

                // // creates the new filter line
                // const newLineFilter = {
                //   ...filterLine,
                //   error: errorDetails(),
                //   values: [
                //     fromValue === null ? null : parseFloat(fromValue), //e.target.value === "" ? "" : parseFloat(e.target.value),
                //     toValue === null ? null : parseFloat(toValue),
                //   ],
                // };

                // console.log("new filter line", newLineFilter);
                // // updates all the filters with the new value
                // updateFiltersParent(newLineFilter);
              });
          };

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // height: "100%",
              }}
            >
              <CustomMainInput
                // error={filterLine?.error?.from}
                error={values.fromError}
                key={`input-${filterLine.id}`}
                height="30px"
                name="from"
                onChange={(e) => {
                  const fromValue = e.target.value;
                  const toValue = values.to || null;
                  // const toValue = filterLine.values[1] || null;

                  setValues({ ...values, from: fromValue });
                  // onChange(fromValue, toValue, "from");

                  debounce(() => {
                    validate(fromValue, toValue, "from");
                  }, 300);

                  // const newLineFilter = {
                  //   ...filterLine,
                  //   values: [
                  //     fromValue === null ? null : parseFloat(fromValue), //e.target.value === "" ? "" : parseFloat(e.target.value),
                  //     toValue === null ? null : parseFloat(toValue),
                  //   ],
                  //   error: undefined, // removes any previous error
                  // };

                  // console.log("new filter line", newLineFilter);
                  // // updates all the filters with the new value
                  // updateFiltersParent(newLineFilter);
                }}
                placeholder="from"
                value={String(values.from || "")}
                variant="input"
              />

              <CustomMainInput
                height="30px"
                // value={format(new Date(item.dueDate), "dd/MM/yyyy")}
                value="and"
                variant="noInput"
              />
              <CustomMainInput
                // error={filterLine?.error?.to}
                error={values.toError}
                height="30px"
                // label="Title"
                name="to"
                // onBlur={fprops.handleBlur("title")}
                onChange={(e) => {
                  // const fromValue = filterLine.values[0] || null;
                  const fromValue = values.from || null;
                  const toValue = e.target.value;

                  setValues({ ...values, to: toValue });

                  // onChange(fromValue, toValue, "to");

                  // const fromValue = filterLine.values[0] || null;
                  // const toValue = e.target.value;

                  debounce(() => {
                    validate(fromValue, toValue, "to");
                  }, 300);

                  // const newLineFilter = {
                  //   ...filterLine,
                  //   values: [
                  //     fromValue === null ? null : parseFloat(fromValue), //e.target.value === "" ? "" : parseFloat(e.target.value),
                  //     toValue === null ? null : parseFloat(toValue),
                  //   ],
                  //   error: undefined, // removes any previous error
                  // };

                  // console.log("new filter line", newLineFilter);
                  // // updates all the filters with the new value
                  // updateFiltersParent(newLineFilter);
                }}
                placeholder="to"
                value={String(values.to || "")}
                variant="input"
              />
            </div>
          );
        }

        default:
          return null;
      }
    };

    const GroupedMeetingsSelector = () => {
      return (
        // <div className={classes.groupedMeetingsSelectorContainer}>
        //   <div className={classes.groupedMeetingsSelectorTitle}>Activa</div>
        <CustomSwitch
          title={<h6>Grouped by date</h6>}
          checked={groupedByDate}
          onChange={() => setGroupedByDate(!groupedByDate)}
        />
        // </div>
      );
    };

    console.log("renderCountRef", renderCountRef.current);

    return (
      <>
        <Card fullscreen transparentBackground>
          <CustomHeader
            fullscreen
            // groupList={data && data.spMeetingGroupsPerUser.nodes}
            oneOnOneList={data && data.spMeeting1On1ListPerUser.nodes}
            headerColor="white"
            headerVariant="list"
            parametersSelected={parameterList}
            filterPopper={
              <FilterComponent
                key="parentKey"
                filterValuesSelector={filterValuesSelector}
                filters={parameterList.filters}
                columnList={columnList}
                listsDropdown={listsDropdown}
                // updateInfo={updateInfo}
                sendInfoToParent={(updatedFilters) => {
                  // console.log(
                  //   "%cSend to parent info",
                  //   "background-color: blue; color: white",
                  //   updatedFilters
                  // );

                  // reduce the filters to only the one ready, not the one in process (lacking an element)
                  const currentFilters = parameterList.filters.filter(
                    (item) => item.filterLineStatus === "ready"
                  );
                  const newFilters = updatedFilters.filter(
                    (item) => item.filterLineStatus === "ready"
                  );

                  const filtersHaveChanged = !isEqual(
                    currentFilters,
                    newFilters
                  );
                  // console.log("filters have changed", {
                  //   filtersHaveChanged,
                  //   currentFilters,
                  //   newFilters,
                  // });

                  filtersHaveChanged &&
                    setPage({
                      ...page,
                      goingUpNumber: 1,
                      goingDownNumber: 1,
                      direction: "down",
                    });

                  setParameterList({
                    ...parameterList,
                    filters: updatedFilters,
                  });
                }}
              />
            }
            sortPopper={
              <SortComponent
                columnList={columnList.filter((item) => item.canSort === true)}
                rightElements={<GroupedMeetingsSelector />}
                listsDropdown={listsDropdown}
                sorts={parameterList.sorts}
                sendInfoToParent={(updatedSorts) => {
                  // console.log(
                  //   "%cSend to parent info",
                  //   "background-color: blue; color: white",
                  //   updatedSorts
                  // );

                  // reduce the filters to only the one ready, not the one in process (lacking an element)
                  const currentSorts = parameterList.filters.filter(
                    (item) => item.sortLineStatus === "ready"
                  );
                  const newSorts = updatedSorts.filter(
                    (item) => item.sortLineStatus === "ready"
                  );

                  const sortsHaveChanged = !isEqual(currentSorts, newSorts);
                  // console.log("sorts have changed", {
                  //   sortsHaveChanged,
                  //   currentSorts,
                  //   newSorts,
                  // });

                  sortsHaveChanged &&
                    setPage({
                      ...page,
                      goingUpNumber: 1,
                      goingDownNumber: 1,
                      direction: "down",
                    });

                  setParameterList({
                    ...parameterList,
                    sorts: updatedSorts,
                  });
                }}
              />
            }
            rightAlignItems={
              <>
                <div style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => {
                      todayRef.current?.scrollIntoView({
                        block: "center",
                        behavior: "smooth",
                      });
                    }}
                    color="primary"
                  >
                    Today
                  </Button>
                </div>
                <Button
                  // isLoading={isLoading && loadingFetch}
                  onClick={() => {
                    // const hasAccess =
                    //   data?.spUsersSubscription?.nodes[0]?.hasAccess || true;
                    const inPlanLimits =
                      data?.spPlanLimits?.nodes[0]?.createMeeting || false;

                    // if (hasAccess && inPlanLimits) {
                    //   meetingNew();
                    // } else {
                    //   setModal({ visible: true, name: "notInPlan" });
                    // }

                    if (inPlanLimits === true) {
                      meetingNew();
                    } else {
                      if (isOwner) {
                        setModal({
                          visible: true,
                          name: "notInPlanOwner",
                          data: {
                            meetingSlotsCapacity:
                              data.spPlanLimits?.nodes[0]?.meetingSlotsCapacity,
                          },
                        });
                      } else {
                        setModal({
                          visible: true,
                          name: "notInPlanNotOwner",
                          data: {
                            meetingSlotsCapacity:
                              data.spPlanLimits?.nodes[0]?.meetingSlotsCapacity,
                          },
                        });
                      }
                    }
                  }}
                  color="primary"
                  // startIcon={<CancelOutlinedIcon />}
                >
                  New meeting
                </Button>
              </>
            }
            sendInfoToParent={(item) => {
              // console.log("se presionó", item);

              switch (item.parameter) {
                case "1on1":
                  setParameterList({
                    ...parameterList,
                    oneOnOneUserID: item.value,
                    shouldUpdate: true,
                  });

                  break;

                case "search":
                  setSearchTerm(item.value);

                  break;
                default:
              }
            }}
          />
          <CardBody fullscreen>
            {renderCountRef.current > 0 && meetings()}
            {loadingFetch ||
              (isLoading && <ActivityIndicator fullscreen withShade />)}
          </CardBody>
        </Card>
        <ModalToShow />
        <Snackbar
          messageType={showSnackbar.messageType}
          message={showSnackbar.message}
          open={showSnackbar.show}
          close={() => setShowSnackbar({ ...showSnackbar, show: false })}
        />
      </>
    );
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
