import axios from "axios";
import config from "../config";

const apiClient = axios.create({
  baseURL: `${config.url}/api`,
});

export async function getSignedUrl({ key, contentType }) {
  const response = await apiClient.post("/fileUpload", {
    key,
    contentType,
  });

  return response.data;
}

export async function uploadFileToSignedUrl(
  signedUrl,
  file,
  contentType,
  onProgress,
  fileLink
) {
  const result = await axios
    .put(signedUrl, file, {
      onUploadProgress: onProgress,
      headers: {
        "Content-Type": contentType,
      },
    })
    .then(() => {
      console.log("%cfinished adding", "background-color: blue; color: white");

      return fileLink;
    })
    .catch((err) => {
      console.error(err.response);
    });
  return result;
}
export const s3AddFile = async (props) => {
  try {
    const { currentFileUrl, file, contentType, key } = props;

    //************  deletes previous file, if any ****************
    if (currentFileUrl && currentFileUrl.length > 0) {
      s3DeleteFile({ imageUrl: currentFileUrl });
    }

    // *************** upload file *******************

    // start by sending request of a signed url to AWS
    return await getSignedUrl({ key, contentType })
      .then(async (response) => {
        // once the url is received, starts uploading the file
        return await uploadFileToSignedUrl(
          response.data.signedUrl, // signedUrl
          file, // file
          contentType, // contentType
          null, // onProgress,
          response.data.fileLink // fileLink
        );
      })
      .catch((err) => {
        console.log("data NO recibida exitosamente:", err);
      });
  } catch (err) {
    console.log("error adding file", err);
  }
};

export const s3DeleteFile = async (props) => {
  try {
    const { imageUrl } = props;
    console.log("los props son", props);
    console.log("starts file delete");

    const response = await apiClient.post("/fileDelete", {
      filename: imageUrl,
    });

    return response.data;
  } catch (err) {
    console.log("error adding file", err);
  }
};
