import React, { useState } from "react";
import Button from "components/CustomButtons/Button";
import MenuList from "@material-ui/core/MenuList";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
// styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
const useStyles = makeStyles(styles);

export default function CustomDropDown(props) {
  const classes = useStyles();
  const {
    color,
    icon: Icon,
    items,
    title,
    startIcon: StartIcon,
    wired,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const itemClick = (clickFunction) => {
    clickFunction();
    handleClose();
  };

  return (
    <div>
      {/* {Icon ? (
        <IconButton color="gray">{Icon}</IconButton>
      ) : (
        <Button
          color="primary"
          wired
          startIcon={StartIcon}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {title}
        </Button>
      )} */}

      <Button
        color={color || "primary"}
        wired={wired}
        justIcon={Boolean(Icon)}
        simple={Boolean(Icon)}
        startIcon={!Icon && StartIcon}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        // size="sm"
      >
        {Icon ? Icon : title}
        {/* <Hidden mdUp implementation="css">
    <p className={classes.linkText}>Perfile</p>
  </Hidden> */}
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: "50px" }}
        className={classes.menu}
      >
        {items.map((prop) => {
          return (
            <MenuList key={prop.id} className={classes.menuList}>
              <MenuItem
                className={classes.dropdownItem}
                onClick={() =>
                  // call a function itemClick that runs the prop function AND closes the menu
                  itemClick(prop.click)
                }
              >
                {prop.menuDesc}
              </MenuItem>
              {prop.dividingLineBelow && <Divider light />}
            </MenuList>
          );
        })}
      </Menu>
    </div>
  );
}
