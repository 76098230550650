import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { object, string } from "yup";
// material ui components
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
// icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// core components
import Dialog from "components/CustomDialog/CustomDialog";
import Button from "../../components/CustomButtons/Button.js";
// data
import ErrorHandler from "components/Error/Error";
import config from "../../config";
import axios from "axios";
import { axiosOptions } from "functions/Common.js";
// styles
import { makeStyles } from "@material-ui/core/styles";
import {
  infoColor,
  dangerColor,
} from "../../assets/jss/material-dashboard-react";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",

    // marginTop: "15%",
    // marginBottom: "15%",
    paddingLeft: "20%",
    paddingRight: "20%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.warning.dark,
  },
  title: {
    color: infoColor[4],
    margin: "10px 0px",
    textAlign: "center",
  },
  helperError: {
    color: dangerColor[1],
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // height: "100%",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  container: {
    height: "100%",
    backgroundColor: "white",
  },
}));

export default function PasswordRecovery() {
  const { t } = useTranslation(["main"]);
  const classes = useStyles();
  const navigate = useNavigate();
  const [modal, setModal] = useState({ visible: false, name: "" });

  const handlePassRecovery = (values, actions) => {
    actions.setSubmitting(true);

    // const email = values.currentEmail.toLowerCase();
    const urlToClick = `${config.url}/auth/receiveEmailPassReset`;

    const emailUser = values.currentEmail.toLowerCase(); // email.toLowerCase();

    const options = axiosOptions({
      url: `${config.url}/auth/sendEmailPassReset`,
      method: "post",
      data: {
        email: emailUser,
        urlToClick: urlToClick,
        origin: "WebApp",
      },
    });

    console.log(options);
    axios(options)
      .then(
        // Successful fetch
        (response) => {
          if (response.data.code === 0) {
            console.log("En Email Pass Reset: " + response.data.message);
            setModal({ visible: true, name: "pass_email_sent" });
            actions.setSubmitting(false);
          } else {
            setModal({ visible: true, name: "not_defined" });
            actions.setSubmitting(false);
          }
        }
      )
      // Unsuccessful fetch
      .catch((err) => {
        setModal({ visible: true, name: "connection_error" });
        actions.setSubmitting(false);
      });
  };

  const ModalToShow = () => {
    switch (modal.name) {
      case "pass_email_sent":
        return (
          <Dialog
            center
            iconPreset="success"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title={t("PassRecovery.ConfirmationTitle")}
            mainText={t("PassRecovery.ConfirmationMessage")}
            buttonActionLabel1="OK"
            buttonActionFunction1={() => navigate("/")}
          />
        );
      case "not_defined": // incorrect credentials
        return (
          <Dialog
            iconPreset="error"
            visibleStatus={modal}
            setVisibleStatus={setModal}
            title={t("PassRecovery.UserNotExists")}
            buttonActionLabel1={t("Common.Return")}
            buttonActionFunction1={() => {
              setModal({ visible: false, name: "" });
            }}
          />
        );

      case "connection_error": // connection problems
        return (
          <ErrorHandler error={{ message: "Failed to fetch-signed out" }} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* {redirect ? <Redirect from="/passwordrecovery" to="/" exact /> : null} */}

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <h3 className={classes.title}>{t("PassRecovery.Title")}</h3>

        <Formik
          initialValues={{ currentEmail: "" }}
          onSubmit={(values, actions) => handlePassRecovery(values, actions)}
          validationSchema={object().shape({
            currentEmail: string()
              .required("Required")
              .email("Enter a valid email")
              .max(100, "Max 50 digits"),
          })}
        >
          {(fprops) => (
            <div className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    FormHelperTextProps={{ className: classes.helperError }}
                    fullWidth
                    helperText={
                      fprops.touched.currentEmail && fprops.errors.currentEmail
                    }
                    id="email"
                    label={t("PassRecovery.Email")}
                    margin="normal"
                    name="currentEmail"
                    onBlur={fprops.handleBlur("currentEmail")}
                    onChange={fprops.handleChange("currentEmail")}
                    required={true}
                    value={fprops.values.currentEmail}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    onClick={fprops.handleSubmit}
                    isLoading={fprops.isSubmitting}
                  >
                    {fprops.isSubmitting
                      ? t("Common.Loading")
                      : t("PassRecovery.RecoverButton")}
                  </Button>
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
            </div>
          )}
        </Formik>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link href="/" variant="body2">
              <h5>
                {t("SignUp.HasAccount")} {t("SignUp.SignIn")}
              </h5>
            </Link>
          </Grid>
        </Grid>
      </div>
      <ModalToShow />
    </>
  );
}
