import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";

// coreComponents
import HelpDeskNew from "./HelpDeskNew";
import HelpDeskHistory from "./HelpDeskHistory";

// data
import { SessionContext } from "../../contexts/SessionContext";
import { HelpDeskContext } from "contexts/HelpDeskContext";

// errors
import { logError, useDataForLog } from "functions/ErrorBoundary";

// icons

// style
import { makeStyles } from "@material-ui/core/styles";

import {
  grayColor,
  primaryColor,
  defaultFont,
} from "assets/jss/material-dashboard-react";

const styles = {};
const useStyles = makeStyles(styles);

export default function HelpDeskModals(props) {
  const [errorState, setErrorState] = useState();
  const generalData = useDataForLog(); // context data to populate error log
  try {
    const {} = props;

    const classes = useStyles();
    // const [records, setRecords] = useState([]);
    const { session, setSession } = useContext(SessionContext);
    const { languageID, userID } = session;

    const { helpDesk, setHelpDesk, modal, setModal } =
      useContext(HelpDeskContext);
    const {} = helpDesk;

    switch (modal.name) {
      case "new-ticket": {
        return (
          <>
            <HelpDeskNew
              visibleStatus={modal}
              setVisibleStatus={setModal}
              sendInfoToParent={(info) => {
                refetch();
              }}
            />
            {/* <ModalToShowArchived /> */}
          </>
        );
      }

      case "view-ticket": {
        return (
          <>
            <HelpDeskHistory
              visibleStatus={modal}
              setVisibleStatus={setModal}
              sendInfoToParent={(info) => {
                refetch();
              }}
              ticketID={modal.data.ticketID}
            />
            {/* <ModalToShowArchived /> */}
          </>
        );
      }

      default:
        return null;
    }
  } catch (error) {
    logError(error, {
      errorLevel: "fatal",
      generalData: { ...generalData },
      otherData: {},
    });
  }
}
