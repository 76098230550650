import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import classNames from "classnames";

const styles = {
  colorWithShade: {
    zIndex: 2,
    color: grayColor[7],
  },
  colorWithoutShade: {
    zIndex: 2,
    color: grayColor[4],
  },

  backgroundShade: {
    zIndex: 1,
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "black",
    top: 0,
  },

  activityContainer: {
    // zIndex: 999,
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    top: "0px",
    zIndex: 100,
  },
  opacityWithoutShade: {
    opacity: 0,
  },
  opacityWithShade: {
    opacity: 0.2,
  },
};
const useStyles = makeStyles(styles);
export default function ActivityIndicator(props) {
  const {
    fullscreen,
    withShade,
    show = true,
    size = 30,
    color = grayColor[4],
  } = props;
  const [showSpinner, setShowSpinner] = useState(false);

  const classes = useStyles();
  // const fullscreen = true;
  // const withShade = true;

  const backgroundClasses = classNames({
    [classes.backgroundShade]: true,
    [classes.opacityWithoutShade]: withShade === undefined || !withShade,
    [classes.opacityWithShade]: withShade,
  });

  const circularClasses = classNames({
    [classes.colorWithoutShade]: withShade === undefined || !withShade,
    [classes.colorWithShade]: withShade,
  });

  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), 300);

    return () => clearTimeout(timer);
  });

  if (show === true && showSpinner) {
    return fullscreen ? (
      <div className={classes.activityContainer}>
        <CircularProgress className={circularClasses} />;
        <div className={backgroundClasses} />
      </div>
    ) : (
      <CircularProgress
        size={size}
        style={{ color: color }}
        // classes={{ root: classes.root, svg: classes.main }}
        {...props}
      />
    );
  } else {
    return null;
  }
}
