import { gql } from "@apollo/client";

export const invoice = gql`
  query invoice($invoiceID: Int!) {
    allDocumentHeaders(condition: { id: $invoiceID }) {
      nodes {
        address1
        city
        claveNumerica
        countryByCountryId {
          name
        }
        created
        invoiceName
        legalNumber
        phone
        pobox
        state
        documentDetailsByDocumentHeaderId {
          nodes {
            id
            description
            baseAmount
            discountAmount
            taxAmount
          }
        }
        companyInfoByInvoicedFrom {
          address
          city
          countryByCountryId {
            name
          }
          email
          legalNumber
          invoiceName
          phone
          pobox
          state
        }
      }
    }
  }
`;
