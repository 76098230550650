import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";

export const FeedbackContext = createContext();

export const FeedbackInfo = (props) => {
  const endDateFinalRef = useRef(null);
  const hoursForFeedbackRef = useRef(null);
  const infoAttendeesRef = useRef();

  const initialState = {
    endDateFinalRef,
    hoursForFeedbackRef,
    infoAttendeesRef,
    // endDateFinal: null,
    dateOrganizerGiveRating: null,
    expiredFeedbackTime: null,
    // hoursForFeedback: null,
    canViewRating: null,
    refetchFeedback: null, // function to refetch feedback info
    infoOrganizer: [],
    modal: {
      visible: false,
      name: "",
      data: {},
    },
  };

  const [feedback, setFeedbackNotMemo] = useState(initialState);

  const [modal, setModal] = useState({
    visible: false,
    name: "",
    data: {},
  });

  const setFeedback = useCallback((newValue) => {
    setFeedbackNotMemo(newValue);
  }, []);

  const memoizedValues = useMemo(
    () => ({
      feedback,
      setFeedback,
      modal,
      setModal,
    }),
    [feedback, setFeedback]
  );

  return (
    <FeedbackContext.Provider
      value={{ feedback, setFeedback, modal, setModal }}
    >
      {props.children}
    </FeedbackContext.Provider>
  );
};
export default FeedbackInfo;
