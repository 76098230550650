import React from "react";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { grayColor } from "assets/jss/material-dashboard-react.js";

const styles = {
  tooltipContainer: {
    // marginLeft: 10,
  },
};

const useStyles = makeStyles(styles);

export default function CustomTooltip(props) {
  const classes = useStyles();
  const { tooltipText, children, size = 10, arrow = true } = props;
  const placement = props.placement || "right";
  // console.log("tooltip of", { tooltipText, children });

  // return tooltipText.length > 0 && tooltipText ? (
  //   <div className={classes.tooltipContainer}>
  //     <Tooltip title={tooltipText} placement={placement} arrow>
  //       {children === undefined ? (
  //         <HelpOutlineIcon style={{ fontSize: size, color: grayColor[4] }} />
  //       ) : (
  //         children
  //       )}
  //     </Tooltip>
  //   </div>
  // ) : (
  //   children
  // );

  return tooltipText !== null ? (
    <div className={classes.tooltipContainer}>
      <Tooltip
        title={tooltipText}
        placement={placement}
        arrow={arrow}
        PopperProps={{ style: { marginTop: -12 } }}
        // PopperProps={{
        //   popperOptions: {
        //     modifiers: [
        //       {
        //         name: "offset",
        //         options: {
        //           offset: [0, 40], // Adjust the distance here [horizontal, vertical]
        //         },
        //       },
        //     ],
        //   },
        // }}
      >
        {children === undefined ? (
          <HelpOutlineIcon style={{ fontSize: size, color: grayColor[4] }} />
        ) : (
          children
        )}
      </Tooltip>
    </div>
  ) : (
    <>{children}</>
  );
}
