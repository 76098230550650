import React, { memo, useEffect, useState, useMemo } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import CustomTooltip from "./CustomTooltip/CustomTooltip";
import Button from "components/CustomButtons/Button.js";

// icons
import CloseIcon from "@material-ui/icons/Close";

// styles
import { grayColor } from "assets/jss/material-dashboard-react";

const styles = {
  errorLabel: {
    textAlign: "center",
  },
  img: {
    display: "block",
    width: "auto",
    height: "100%",

    // "&:hover": { filter: "blur(5px)" },
  },

  thumbCloseButton: {
    position: "absolute",
    top: -2,
    right: 0,
  },

  thumb: {
    padding: "5px 5px 0px 0px",
    position: "relative",
    // backgroundColor: "yellow",
  },

  thumbInner1: {
    display: "inline-flex",
    borderRadius: 2,
    border: `1px solid ${grayColor[7]}`,
    // marginBottom: 8,

    padding: 4,
    boxSizing: "border-box",
    // position: "relative",
  },
  thumbCantShow: {
    border: "3px solid red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: grayColor[6],

    // color: "white",
  },

  thumbInner2: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    borderRadius: "5px",
  },
};

const useStyles = makeStyles(styles);

export const Thumbnail = ({
  tooltipText = "hola",
  error = "Se dio un error",
  index,
  path,
  cantShow = false,
  sendInfoToParent,
  widthAndHeight = 100,
  hasRightMargin = true,
}) => {
  // console.log("render timer with props", props);

  // console.log("file", file);
  // const [thumbHover, setThumbHover] = useState(false);
  const classes = useStyles();

  return (
    <div
      className={classes.thumb}
      style={{ marginRight: hasRightMargin ? 8 : 0 }}
    >
      <CustomTooltip
        tooltipText={tooltipText}
        placement="bottom-start"
        arrow={false}
      >
        <div
          className={`${classes.thumbInner1} ${
            error.length > 0 && classes.thumbCantShow
          }`}
          style={{ width: widthAndHeight, height: widthAndHeight }}
        >
          <div className={classes.thumbInner2}>
            {error.length > 0 ? (
              <h6 className={classes.errorLabel}>{error}</h6>
            ) : (
              <img
                src={path}
                className={classes.img}
                //   onMouseOver={() => setThumbHover(true)}
                //   onMouseOut={() => setThumbHover(false)}

                // Revoke data uri after image is loaded
                // onLoad={() => {
                //   URL.revokeObjectURL(file.preview);
                // }}
              />
            )}
          </div>
          {/* <pre>
            {JSON.stringify(
              {
                thumbHover,
              },
              null,
              2
            )}
          </pre> */}
        </div>
      </CustomTooltip>
      <div className={classes.thumbCloseButton}>
        <Button
          size="xs"
          // color="white"
          className="close"
          justIcon
          onClick={
            () => {
              // console.log("close", index);

              // const newArray = [...selectedFilesState];
              // newArray.splice(index, 1);
              // // recalculates if the errors associated with too many files
              // const newSelectedFiles = updateMaxFilesError(newArray);
              // //   console.log("newArray", newArray);
              // setSelectedFilesState(newSelectedFiles);

              sendInfoToParent(index);
            }
            // const =
          }
        >
          <CloseIcon />
        </Button>
      </div>
    </div>
  );
};
