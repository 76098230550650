import React from "react";

// @material-ui/core
import { Avatar } from "@material-ui/core";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "assets/jss/material-dashboard-react";

const styles = {
  dropDownCustomContainer: {
    minWidth: "250px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    pointerEvents: "none",
  },
  dropDownTextsContainer: {
    display: "flex",
    flexDirection: "column",
    // marginBottom: "5px",
    margin: "5px 0px 5px 15px",
  },
  dropDownMainText: {
    fontSize: "14px",
    margin: "0px",
  },
  dropDownSubText: {
    color: grayColor[3],
    fontSize: "12px",
    margin: "3px 0px 0px 0px",
    lineHeight: "10px",
  },
};

const useStyles = makeStyles(styles);

export const DropDownContacts = (props) => {
  const { name, email, imagePath } = props;
  const classes = useStyles();

  return (
    <div className={classes.dropDownCustomContainer}>
      <Avatar
        className={classes.avatar}
        alt={name}
        src={imagePath}
        style={{ height: "35px", width: "35px" }}
      />

      <div className={classes.dropDownTextsContainer}>
        <h5 className={classes.dropDownMainText}>{name || email}</h5>
        <h6 className={classes.dropDownSubText}>{email}</h6>
      </div>
    </div>
  );
};
