import React, { useState } from "react";
// material-ui components
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { grayColor } from "assets/jss/material-dashboard-react.js";

const styles = {};

const useStyles = makeStyles(styles);

export default function CustomPopper(props) {
  const classes = useStyles();
  const { children, triggerComponent } = props;
  const placement = props.placement || "right";
  const [showPopper, setShowPopper] = useState(null);

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleOpenPopper = (event) => {
    console.log("Tries to open popper", { target: event.currentTarget });
    setShowPopper(event.currentTarget);
  };

  const handleClosePopper = () => {
    console.log("Tires to close popper");
    setShowPopper(null);
  };

  return (
    <>
      {triggerComponent(handleOpenPopper)}

      <Popper
        open={Boolean(showPopper)}
        anchorEl={showPopper}
        transition
        // disablePortal
        style={{ zIndex: 9999 }}
        placement="bottom-start"
        // modifiers={{
        //   flip: {
        //     enabled: true,
        //   },
        //   preventOverflow: {
        //     enabled: true,
        //     boundariesElement: "scrollParent",
        //   },
        // }}
        // className={
        //   classNames({ [classes.popperClose]: !openNotification }) +
        //   " " +
        //   classes.popperNav
        // }
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="randomID"
            // style={{
            //   transformOrigin: placement === "bottom" ? "bottom" : "bottom",
            // }}
          >
            <ClickAwayListener onClickAway={handleClosePopper}>
              <Paper elevation={10}>{children}</Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
}
