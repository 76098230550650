import { grayColor } from "assets/jss/material-dashboard-react.js";

const medalStyle = () => ({
  indicatorNumber: {
    position: "absolute",

    lineHeight: "1em",
    fontSize: 12,
    fontWeight: 700,
    margin: 0,
    paddingBottom: 10,
    // paddingRight: 2,
    // marginTop: 4,
    // marginBottom: 4,
    // paddingLeft: 4,
    // paddingRight: 4,
    color: grayColor[2],
    // color: "white",
  },
  alert: { color: "red" },
  indicatorGroup: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    paddingleft: -2,
    marginRight: -2,
  },
});

export default medalStyle;
