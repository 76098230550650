import { gql } from "@apollo/client";

export const updateMeeting = gql`
  mutation meetingUpdate(
    $meetingID: Int
    $attendees: [String]
    $objectives: [MeetingObjectiveTypeInput] = {}
    $actions: [MeetingActionTypeInput] = {}
    $topics: [MeetingParkingLotTypeInput] = {}
    $feedback: [MeetingFeedbackTypeInput] = {}
    $feedbackToOrganizer: [MeetingFeedbackToOrganizerTypeInput] = {}
    $userID: Int
    $comment: String
  ) {
    spMeetingUpdate(
      input: {
        meetingId1: $meetingID
        attendees1: $attendees
        objectives1: $objectives
        actions1: $actions
        parkingLot1: $topics
        feedback1: $feedback
        feedbackToOrganizer1: $feedbackToOrganizer
        userId1: $userID
        comment1: $comment
      }
    ) {
      clientMutationId
      results {
        meetingCost
      }
    }
  }
`;

export const meetingGeneral = gql`
  query meetingGeneral(
    $meetingID: Int!
    $userID: Int!
    $accountID: Int!
    $parentEventID: Int
  ) {
    spMeetingStatus(meetingId1: $meetingID, parentEventId1: $parentEventID) {
      nodes {
        statusId
      }
    }
    spMeetingGeneral(
      meetingId1: $meetingID
      userId1: $userID
      parentEventId1: $parentEventID
    ) {
      nodes {
        isOrganizer
        meetingCost
        finalRating
        checkedIn
        arrivalDate
        departDate
        willAttend
        canConnectToSocket
        withObjectives
        withAgenda
        withDurations
      }
    }
    spMeetingDetails(meetingId1: $meetingID) {
      nodes {
        attendees
        recurrenceTypeId
      }
    }
    meetingById(id: $meetingID) {
      checkinCode
      createdBy
      endDate
      endDateFinal
      endDateReminder
      externalMeetingId
      id
      location
      meetingTypeId
      privateAgenda
      startDate
      startDateFinal
      title
      userCalendarId
    }

    spMeetingFeedback(meetingId1: $meetingID) {
      nodes {
        canViewRating
      }
    }
    spMeetingRating(meetingId1: $meetingID) {
      nodes {
        id: ratingElementId
        desc
        value: rating
        showInMeetingStatus
        tooltip
      }
    }
    spSettings(accountId1: $accountID, userId1: $userID, settingId1: [7, 8]) {
      nodes {
        id
        desc
        value
      }
    }
  }
`;

export const meetingCost = gql`
  query meetingCost($meetingID: Int!) {
    spMeetingCost(meetingId1: $meetingID) {
      nodes {
        cost
      }
    }
  }
`;

export const meetingRating = gql`
  query meetingRating($meetingID: Int!) {
    spMeetingRating(meetingId1: $meetingID) {
      nodes {
        id: ratingElementId
        desc
        value: rating
        showInMeetingStatus
      }
    }
  }
`;
