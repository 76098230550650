import { gql } from "@apollo/client";

export const userProfile = gql`
  query spUserProfile($userID: Int!) {
    spUserProfile(userId1: $userID) {
      nodes {
        email
        firstName
        imageUrl
        lastName
      }
    }
  }
`;

export const updateUserProfile = gql`
  mutation updateUserProfile(
    $userID: Int!
    $firstName: String
    $lastName: String
    $imageURL: String
  ) {
    updateUserById(
      input: {
        userPatch: {
          lastName: $lastName
          firstName: $firstName
          imageUrl: $imageURL
        }
        id: $userID
      }
    ) {
      clientMutationId
    }
  }
`;

// export const updateUserURI = gql`
//   mutation updateUserProfile($userID: Int!, $imageURL: String) {
//     updateUserById(input: { userPatch: { imageUrl: $imageURL }, id: $userID }) {
//       clientMutationId
//     }
//   }
// `;
