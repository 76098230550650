import React, { useState } from "react";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  InputLabel,
  Box,
} from "@material-ui/core";

// styles
import {
  grayColor,
  primaryColor,
  infoColor,
} from "assets/jss/material-dashboard-react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: primaryColor[4], //"#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: primaryColor[2], //"#106ba3",
    },
  },
};

const useStyles = makeStyles(styles);

export const RadioButtonsGroup = (props) => {
  const { items, sendInfoToParent, initialValue } = props;

  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const handleChange = (event) => {
    sendInfoToParent(parseInt(event.target.value));
    setSelectedValue(parseInt(event.target.value));
  };

  const CustomRadioButton = (props) => {
    const classes = useStyles();

    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={
          <span className={`${classes.icon} ${classes.checkedIcon}`} />
        }
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup value={selectedValue} onChange={handleChange}>
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<CustomRadioButton />}
            label={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {item.label}
                {item.control}
              </div>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
