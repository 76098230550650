import { forwardRef, useCallback, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// core components
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import ErrorHandler from "components/Error/Error";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
// data
import { useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import config from "config";
import { AccountContext } from "contexts/AccountContext";
import { axiosOptions } from "functions/Common";
import {
  // addAccess,
  // cancelAccess,
  changeAccess,
  usersSubscription,
} from "graphql/gqlAccountUsers";

import { SessionContext } from "../../contexts/SessionContext";
import { SocketContext } from "../../contexts/SocketContext";
// error
// errors
// style
import { makeStyles } from "@material-ui/core/styles";
import {
  dangerColor,
  grayBoxShadow,
  grayColor,
  infoColor,
} from "assets/jss/material-dashboard-react";

//icons

const styles = {
  alignCenter: {
    textAlign: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "yellow",
  },
  alignLeft: {
    textAlign: "left",
    paddingLeft: "15px",
  },
  alignRight: {
    textAlign: "right",
    // backgroundColor: "yellow",
    // paddingRight: "15px",
  },

  buttonContainer: {
    paddingRight: "10px",
  },

  exceededSlotLabel: {
    backgroundColor: dangerColor[6],
    color: dangerColor[2],
    padding: "10px",
    borderRadius: "5px",
  },
  headerGridContainer: {
    flex: 1,
    // borderBottom: `1px solid ${grayColor[7]}`,
  },

  headerContainer: {
    ...grayBoxShadow,
    alignItems: "center",
    backgroundColor: "white",
    // backgroundColor: grayColor[8],
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // marginBottom: "15px",
    padding: "7px 0px 0px 0px",
    transition: "all 600ms ease-in-out",
    borderBottom: `1px solid ${grayColor[6]}`,
  },
  header: {
    fontSize: "12px",
    paddingBottom: "10px",
    fontWeight: 600,
  },

  loadingLabel: {
    fontStyle: "italic",
    color: "white", //grayColor[5],
    fontWeight: 400,
  },
  loadingContainer: {
    // width: "400px",
    // position: "absolute",
    borderRadius: "5px",
    bottom: "0px",
    // top: "0px",
    height: "30px",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    backgroundColor: grayColor[5],
    margin: "0px",
  },
  slotsUsageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: `1px solid ${infoColor[4]}`,
    borderRadius: "5px",
    // height: "100%",
    padding: "0px 10px 0px 5px",
    marginRight: "10px",
  },
  slotsUsageIndicatorNumber: {
    color: infoColor[4],
    margin: "0px 0px 0px 5px",
    fontWeight: 600,
  },
  slotsUsageIndicatorLabel: {
    marginLeft: "5px",
    color: infoColor[4],
  },
  userLine: {
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${grayColor[7]}`,
    padding: "7px",
    // maxWidth: "500px",
    flex: 1,
    // backgroundColor: "yellow",
    // padding: "60px 7px",
  },
  usersListContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },
  userLineDivider: {
    borderBottom: `1px solid ${grayColor[7]}`,
  },
  usersHeader: {
    // backgroundColor: "yellow",
    // borderBottom: `1px solid ${grayColor[7]}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
};

const useStyles = makeStyles(styles);

export default function AccountUsers(props) {
  console.log("starts AccountUsers", props);
  const {} = props;

  const classes = useStyles();
  const navigate = useNavigate();

  // const [showSnackbar, setShowSnackbar] = useState({
  //   show: false,
  //   messageType: "",
  //   message: "",
  // });
  const formRef = useRef();

  // const { session } = useContext(SessionContext);
  // const { accountID, email, languageID, stageID, userName, userID } = session;

  const { showSnackbar, setShowSnackbar } = useContext(AccountContext);
  // const { slotsFree, slotsPlan, slotsUsed } = account;

  return (
    <>
      <CardBody fullscreen>
        <div
          style={{
            height: "100%",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <UsersTopHeader />
          <UsersListHeader />
          <UsersList />
        </div>
      </CardBody>

      <Snackbar
        messageType={showSnackbar.messageType}
        message={showSnackbar.message}
        open={showSnackbar.show}
        close={() => setShowSnackbar({ ...showSnackbar, show: false })}
      />
    </>
  );
}

const TableTemplate = ({ name, email, hasAccess, resendInvitation }) => {
  const classes = useStyles();
  return (
    <GridContainer
      spacing={2}
      direction="row"
      classes={{ container: classes.headerGridContainer }}
    >
      <GridItem xs={5} sm={4} md={3}>
        {name}
      </GridItem>
      <GridItem xs={5} sm={4} md={3}>
        {email}
      </GridItem>
      <GridItem xs={1} sm={2} lg={1}>
        {hasAccess}
      </GridItem>
      <GridItem xs={1} sm={2} lg={1}>
        {resendInvitation}
      </GridItem>
    </GridContainer>
  );
};

const UsersListHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.headerContainer}>
      <TableTemplate
        name={
          <h5 className={`${classes.header} ${classes.alignLeft}`}>Name</h5>
        }
        email={
          <h5 className={`${classes.header} ${classes.alignLeft}`}>Email</h5>
        }
        hasAccess={
          <h5 className={`${classes.header} ${classes.alignCenter}`}>
            Has access
          </h5>
        }
      />
    </div>
  );
};

const UsersTopHeader = () => {
  const classes = useStyles();
  const InviteUsersButton = () => {
    const { session } = useContext(SessionContext);
    const { stageID } = session;

    const { account, setAccount, modal, setModal } = useContext(AccountContext);
    const { slotsFree, slotsPlan, slotsUsed } = account;

    return (
      <div className={classes.buttonContainer}>
        <Button
          onClick={() => {
            if (slotsPlan === slotsUsed) {
              setModal({ visible: true, name: "planExceeded" });
            } else {
              setModal({
                visible: true,
                name: "inviteUsers",
              });
            }
            // setModal({
            //   visible: true,
            //   name: stageID === 20 ? "planLimit" : "inviteUsers",
            // })
          }}
          color="primary"
          disabled={[3, 6].includes(stageID)}
        >
          Add users
        </Button>
      </div>
    );
  };

  const SlotsUsage = () => {
    const { session } = useContext(SessionContext);
    const { stageID } = session;

    const { account, setAccount } = useContext(AccountContext);
    const { slotsFree, slotsPlan, slotsUsed } = account;

    // if (loading) {
    //   return <ActivityIndicator fullscreen />;
    // }

    // Will show only used slots in these stages:
    // Stage ID: 2  =>  in trial period ***inactive***
    // Stage ID: 3  =>  trial period expired, no subscription created ***inactive***

    // Will show used and available slots in these stages:
    // Stage ID: 4  =>  active subscription
    // Stage ID: 5  =>  subscription in renewal period
    // Stage ID: 6  =>  subscription expired
    // Stage ID: 20  => in basic plan

    // Won't display slots usage in these stages:
    // Stage ID: 0  =>  no subscription
    // Stage ID: 1  =>  under third party subscription

    return (
      <>
        {[2, 3, 4, 5, 6, 20].includes(stageID) && (
          <div className={classes.slotsUsageContainer}>
            {[2, 3].includes(stageID) && (
              <>
                <h6 className={classes.slotsUsageIndicatorLabel}>
                  Used slots{" "}
                </h6>
                <h5 className={classes.slotsUsageIndicatorNumber}>
                  {slotsUsed}
                </h5>
              </>
            )}

            {[4, 5, 6, 20].includes(stageID) && (
              <>
                <h6 className={classes.slotsUsageIndicatorLabel}>
                  Available slots{" "}
                </h6>
                <h5 className={classes.slotsUsageIndicatorNumber}>
                  {slotsPlan - slotsUsed}
                </h5>

                <h6 className={classes.slotsUsageIndicatorLabel}>
                  {" "}
                  Total slots
                </h6>
                <h5 className={classes.slotsUsageIndicatorNumber}>
                  {slotsPlan}
                </h5>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const ExceededSlotsAlert = () => {
    const { account, setAccount } = useContext(AccountContext);
    const { slotsFree } = account;

    return (
      <>
        {slotsFree < 0 && (
          <h5 className={classes.exceededSlotLabel}>
            <span style={{ fontWeight: "bold" }}>Slots usage exceeded.</span>
            <br />
            {`Uncheck ${
              slotsFree * -1
            } user access to meet the slots capacity of your plan. All users are not currently under your subscription and limited by the Basic Plan`}
          </h5>
        )}
      </>
    );
  };

  return (
    <>
      <ExceededSlotsAlert />
      <div className={classes.usersHeader}>
        <SlotsUsage />
        <InviteUsersButton />
      </div>
    </>
  );
};

const UsersList = () => {
  const classes = useStyles();
  return (
    <div className={classes.usersListContainer}>
      <UserLines />
    </div>
  );
};

const UserLines = () => {
  const { session } = useContext(SessionContext);
  const { userID } = session;

  const { account, setAccount, userQueryRef } = useContext(AccountContext);

  const previousDataRef = useRef();

  const { loading, error, data, refetch, fetchMore } = useQuery(
    usersSubscription,
    {
      variables: { ownerID: userID, cursor: null, pageSize: 30 },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (data !== undefined) {
      userQueryRef.current = {
        data: data.spUsersSubscription.nodes,
        refetch: () => refetch(),
      };
    }
  }, [data]);

  let latestData;
  if (loading === true) {
    if (previousDataRef.current === undefined) {
      latestData = undefined;
    } else {
      latestData = previousDataRef.current;
    }
  } else {
    previousDataRef.current = data;
    latestData = data;
  }

  if (error) {
    return <ErrorHandler error={error} btnActionFunction2={() => refetch()} />;
  }

  if (latestData !== undefined) {
    return latestData.spUsersSubscription.nodes.map((user, index) => {
      const isLastUser =
        latestData.spUsersSubscription.nodes.length === index + 1;

      return (
        <div
          key={user.email}
          // style={{ backgroundColor: "red", display: "flex", flex: 1 }}
        >
          <UserLineRefSelector
            data={data}
            fetchMore={fetchMore}
            isLastUser={isLastUser}
            user={user}
            loading={loading}
          />
          {isLastUser && <UsersLoading loading={loading} />}
        </div>
      );
    });
  }
};

const UserLine = forwardRef((props, ref) => {
  const classes = useStyles();
  const { user, isLastUser } = props;

  const { session } = useContext(SessionContext);
  const { accountID, email, userName } = session;

  const { socket } = useContext(SocketContext);

  const {
    account,
    setAccount,
    modal,
    setModal,
    showSnackbar,
    setShowSnackbar,
  } = useContext(AccountContext);
  const { slotsFree, slotsPlan, slotsUsed } = account;

  const [changeAccessMut] = useMutation(changeAccess, {
    onCompleted: (data) => {
      // console.log("data", data);
      const { code, userId: userIDAccess } =
        data.spUsersSubscriptionChangeAccess.results[0];
      switch (code) {
        case 0: // not enough slots.  Can't add access
          console.log("not enough slots");
          setModal({ visible: true, name: "planExceeded" });
          break;
        case 2: // access revoked
          console.log("access was revoked");

          socket.emit("removed_from_account", { userID: userIDAccess });
          // sendInfoToParent();

          setAccount({ ...account, slotsFree: slotsFree + 1 });

          // refetch();
          break;
        case 1: // access granted
          console.log("grant access");
          socket.emit("add_to_account", { userID: userIDAccess });

          // sendInfoToParent();
          setAccount({ ...account, slotsFree: slotsFree - 1 });

          // refetch();
          break;

        // default
      }
    },
  });

  const resendInvitation = (email) => {
    const options = axiosOptions({
      url: `${config.url}/api/invitation-send`,
      method: "post",
      data: {
        // emails: ["lau@yll.com"],
        emails: [email],
        accountID: accountID,
        invitedBy: userName,
        mode: "resend",
      },
    });

    axios(options)
      .then(async (response) => {
        console.log(" la response es", response);
        const { code } = response.data;

        switch (code) {
          case 0: // slots exceeded
            setShowSnackbar({
              show: true,
              messageType: "error",
              message: "Invitations not sent.  Slots exceeded",
            });
            break;
          case 1: // new user suscriptions were created (can have emails excluded because already invited)
            setShowSnackbar({
              show: true,
              messageType: "success",
              message: "Invitations were sent",
            });

            break;
          case 2: // no user subscriptions (can have emails excluded because already invited)
            setShowSnackbar({
              show: true,
              messageType: "error",
              message: "No new invitations were sent",
            });
            break;

          default:
            return null;
        }

        setModal({ visible: false, name: "" });
      })
      .catch((error) => {
        console.log("error is:", error);
        // refetch();

        setModal({ visible: false, name: "" });

        setShowSnackbar({
          show: true,
          messageType: "error",
          message: "There was an error.  Verify if all the emails were added.",
        });
        // return <ErrorHandler  error={error}    />;
      });
  };

  return (
    <div
      key={user.email}
      ref={ref}
      className={`${classes.userLine} ${
        !isLastUser && classes.userLineDivider
      }`}
    >
      <TableTemplate
        // name={
        //   user.isUser ? (
        //     <h5>{user.name}</h5>
        //   ) : (
        //     <h6 style={{ color: grayColor[5] }}>No user yet</h6>
        //   )
        // }
        name={<h5>{user.name}</h5>}
        email={<h5>{user.email}</h5>}
        hasAccess={
          <div className={classes.alignCenter}>
            <CustomSwitch
              checked={user.hasAccess}
              onChange={(e) => {
                if (user.email === email) {
                  // console.log("owner");
                  setModal({ visible: true, name: "cantRemoveOwner" });

                  return;
                }

                console.log("customswitch value", e.target.checked);
                const newCheckValue = e.target.checked;

                if (newCheckValue) {
                  // adding access
                  if (slotsPlan <= slotsUsed) {
                    setModal({ visible: true, name: "planExceeded" });
                  } else {
                    changeAccessMut({
                      variables: {
                        accountID: accountID,
                        email: user.email,
                      },
                    });
                  }
                } else {
                  // revoking access
                  changeAccessMut({
                    variables: {
                      accountID: accountID,
                      email: user.email,
                    },
                  });
                }
              }}
            />
          </div>
        }
        resendInvitation={
          user.hasAccess && (
            <div className={classes.alignCenter}>
              <Button
                size="xs"
                wired
                onClick={() => {
                  setModal({
                    visible: true,
                    name: "confirmInvitationSend",
                    data: {
                      email: user.email,
                      sendFunction: () => resendInvitation(user.email),
                    },
                  });
                }}
                color="primary"
                // disabled={[3, 6].includes(stageID)}
              >
                Resend invitation
              </Button>
            </div>
          )
        }
      />
    </div>
  );
});

const UserLineRefSelector = (props) => {
  const { data, fetchMore, user, isLastUser, loading } = props;
  const lastCursorRef = useRef();
  const hasMoreDataRef = useRef(true); // expects to have more data, if not, will not return anything

  const observerLast = useRef();

  const lastUserDisplayedRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerLast.current) observerLast.current.disconnect();

      const observerCallback = (entries) => {
        if (entries[0].isIntersecting) {
          // execute this when the ref attached to the div is displayed on screen
          // console.log(
          //   "%clast user is visible ",
          //   "background-color: red; color: white"
          // );
          const lastCursor =
            lastCursorRef.current === undefined
              ? data.spUsersSubscription.pageInfo.endCursor
              : lastCursorRef.current;

          if (hasMoreDataRef.current) {
            fetchMore({
              variables: {
                cursor: lastCursor,
              },
              // this defines how the new information should be combined with the previous
              updateQuery: (previousResult, { fetchMoreResult }) => {
                // const hasMoreResults =
                //   fetchMoreResult.spUsersSubscription.pageInfo.hasNextPage;

                if (hasMoreDataRef.current) {
                  // after fetching more check if has next page updates ref for next fetchMore
                  hasMoreDataRef.current =
                    fetchMoreResult.spUsersSubscription.pageInfo.hasNextPage;

                  lastCursorRef.current =
                    fetchMoreResult.spUsersSubscription.pageInfo.endCursor;

                  // combines the previous result with the new result
                  const newResult = {
                    ...previousResult,
                    spUsersSubscription: {
                      ...previousResult.spUsersSubscription,
                      nodes: [
                        ...previousResult.spUsersSubscription.nodes,

                        ...fetchMoreResult.spUsersSubscription.nodes,
                      ],

                      // ...fetchMoreResult.usersSubscription.spUsersSubscription
                      //   .nodes[0],
                    },
                  };
                  return newResult;
                } else {
                  hasMoreDataRef.current = false;
                  return previousResult;
                }
              },
            });
          }
        }
      };

      const observer = new IntersectionObserver(observerCallback, {
        threshold: 1,
      });
      if (node) observer.observe(node);
    },
    [loading]
  );

  if (isLastUser) {
    // if it's the last item, attaches a ref that will later allow to trigger more records when this element becomes visible on screen using observerFirst
    // if (isLastUser) {
    //   console.log("%cisLastUser", "background-color: blue; color: white", {
    //     isLastUser,
    //     user,
    //   });
    // }
    // console.log(
    //   "%cattach ref to last user",
    //   "background-color: red; color: white"
    // );

    return (
      <UserLine
        ref={lastUserDisplayedRef}
        user={user}
        isLastUser={isLastUser} // allows to show or remove dividing lines
      />
    );
  } else {
    return <UserLine user={user} isLastUser={isLastUser} />; // allows to show or remove dividing lines />;
  }
};

const UsersLoading = (props) => {
  const classes = useStyles();
  const { loading } = props;

  return (
    <div className={classes.loadingContainer}>
      <h6 className={classes.loadingLabel}>
        {loading ? "Loading more..." : "No more users"}
      </h6>
    </div>
  );
};
