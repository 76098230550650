import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Google(props) {
  return (
    <SvgIcon style={{ fontSize: props.size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <defs>
          <image
            id="image14"
            width="24"
            height="24"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAxUlEQVRIie2WOxIBQRBAX88OshUQEeAMTuBzCYFyElVbJXUDkaLKKXCCPQMSEQEhdlqyCSXbkc07wHtdnXQLYAALlIESIBRDgSfwAF4WsI3uKG4PkolATV1mitjFRE7hetwmy3O6uVug3OlPx5Vqc15w8g9avWl2TjcLA0SqGvuUAwhaBawBjGqxtfwidxrv4m9CIARCIARCwFfAiUTOtzh3OgNkInL3HVDkRn70H4fdbNUeJiJK3cvRFy6n/WwNPIU/vy1vRis/cDPIS4UAAAAASUVORK5CYII="
          ></image>
        </defs>
        <g>
          {/* <use xlinkHref="#image14"></use> */}
          {/* <path
            fill="#FFF"
            fillRule="evenodd"
            d="M2.61 2.086h18.78c.29 0 .524.234.524.523v18.782c0 .289-.234.523-.523.523H2.609a.523.523 0 01-.523-.523V2.609c0-.289.234-.523.523-.523zm0 0"
          ></path> */}
          <path
            fill="#4285F4"
            fillRule="evenodd"
            d="M16.508 12.105c0-.332-.031-.652-.086-.96H12v1.816h2.527a2.16 2.16 0 01-.937 1.418v1.18h1.52c.886-.817 1.398-2.024 1.398-3.454zm0 0"
          ></path>
          <path
            fill="#34A853"
            fillRule="evenodd"
            d="M12 16.695c1.27 0 2.332-.422 3.11-1.136l-1.52-1.18c-.422.281-.957.45-1.59.45-1.223 0-2.258-.825-2.629-1.938H7.805v1.218A4.691 4.691 0 0012 16.695zm0 0"
          ></path>
          <path
            fill="#FBBC05"
            fillRule="evenodd"
            d="M9.371 12.89a2.818 2.818 0 010-1.78V9.89H7.805c-.32.633-.5 1.352-.5 2.11 0 .758.18 1.477.5 2.11zm0 0"
          ></path>
          <path
            fill="#EA4335"
            fillRule="evenodd"
            d="M12 9.172a2.55 2.55 0 011.797.703l1.344-1.348C14.328 7.77 13.266 7.305 12 7.305A4.691 4.691 0 007.805 9.89l1.566 1.218C9.742 9.996 10.777 9.172 12 9.172zm0 0"
          ></path>
        </g>
      </svg>
    </SvgIcon>
  );
}
