import React from "react";
import { makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomMainInput from "components/CustomMainInput/CustomMainInput";
import { Field } from "formik";
// data

// icons

// data
import { mixed, object, string, number } from "yup";
import valid from "card-validator"; // import statement

// styles
import styles from "assets/jss/material-dashboard-react/views/paymentStyle";
const useStyles = makeStyles(styles);

const PaymentCard = () => {
  const classes = useStyles();
  // const { languageID } = session;
  const currentYear = new Date().getFullYear();

  const expiryYears = Array(19)
    .fill()
    .map((element, index) => ({
      id: index === 0 ? 0 : index + currentYear,
      menuDesc: index === 0 ? "Select" : index + currentYear,
    }));

  const expiryMonths = Array(13)
    .fill()
    .map((element, index) => ({
      id: index,
      menuDesc: index === 0 ? "Select" : index,
    }));

  // console.log("expiryYears", expiryYears);
  const spacing = 4;
  return (
    <React.Fragment>
      <GridContainer spacing={spacing} direction="row" alignItems="flex-start">
        <GridItem item xs={12} sm={6}>
          <Field name="cardName">
            {({ field, form, meta }) => (
              // <CustomInput
              //   labelText="Card name *"
              //   name="cardName"
              //   {...field}
              //   // value={form.values.cardName}
              //   // onChange={field.onChange}
              //   // onBlur={field.onBlur}
              //   formControlProps={{ fullWidth: true }}
              //   helperText={meta.touched && meta.error && meta.error}
              // />

              <CustomMainInput
                {...field} // retrieves all other, onBlur, onChange, etc
                error={meta.touched ? meta.error : ""}
                height="40px"
                label="Card name"
                name="cardName"
                variant="input"
              />
            )}
          </Field>
        </GridItem>
        <GridItem item xs={12} sm={6}>
          <Field name="cardNumber">
            {({ field, form, meta }) => {
              // console.log("meta", meta);
              return (
                // <CustomInput
                //   labelText="Card number *"
                //   name="cardNumber"
                //   {...field}
                //   // value={form.values.cardNumber}
                //   // onChange={field.onChange}
                //   // onBlur={field.onBlur}
                //   formControlProps={{ fullWidth: true }}
                //   // helperText={meta.touched && meta.error && meta.error}
                //   helperText={meta.error}
                //   type="number"
                // />

                <CustomMainInput
                  {...field} // retrieves all other, onBlur, onChange, etc
                  error={meta.touched ? meta.error : ""}
                  height="40px"
                  label="Card number"
                  name="cardNumber"
                  variant="input"

                  // onBlur={fprops.handleBlur("invoiceName")}
                  // onChange={fprops.handleChange("invoiceName")}
                  // value={fprops.values.invoiceName}
                />
              );
            }}
          </Field>
        </GridItem>
        <GridItem item xs={6} sm={3}>
          <Field name="cardExpiryMonth">
            {({ field, form, meta }) => (
              // <CustomNativeSelect
              //   label="Expiry month *"
              //   name="cardExpiryMonth"
              //   items={expiryMonths}
              //   formControlProps={{ fullWidth: true }}
              //   {...field}
              //   // onChange={field.onChange}
              //   // value={form.values.cardExpiryMonth}
              //   helperText={meta.touched ? meta.error : ""}
              // />

              <CustomMainInput
                {...field} // retrieves all other, onBlur, onChange, etc
                error={meta.touched ? meta.error : ""}
                dropDownList={expiryMonths}
                // onChangeDropDownList={(item) => refetch()}

                dropDownFunction={(item) =>
                  // fprops.setFieldValue("invoiceCountryId", item.id)
                  form.setFieldValue("cardExpiryMonth", item.id)
                }
                height="40px"
                label="Expiry month"
                name="cardExpiryMonth"
                // placeholder="Select"

                variant="dropdown"
              />
            )}
          </Field>
        </GridItem>
        <GridItem item xs={6} sm={3}>
          <Field name="cardExpiryYear">
            {({ field, form, meta }) => (
              // <CustomSelect
              //   label="Expiry year *"
              //   name="cardExpiryYear"
              //   items={expiryYears}
              //   formControlProps={{ fullWidth: true }}
              //   {...field}
              //   // onChange={field.onChange}
              //   // value={form.values.cardExpiryYear}
              //   helperText={meta.touched ? meta.error : ""}
              // />

              <CustomMainInput
                {...field} // retrieves all other, onBlur, onChange, etc
                error={meta.touched ? meta.error : ""}
                dropDownList={expiryYears}
                // onChangeDropDownList={(item) => refetch()}

                dropDownFunction={(item) =>
                  // fprops.setFieldValue("invoiceCountryId", item.id)
                  form.setFieldValue("cardExpiryYear", item.id)
                }
                height="40px"
                label="Expiry year"
                name="cardExpiryYear"
                // placeholder="Select"

                variant="dropdown"
              />
            )}
          </Field>
        </GridItem>
        <GridItem item xs={12} sm={2}>
          <Field name="cardCVV">
            {({ field, form, meta }) => (
              // <CustomInput
              //   labelText="CVV *"
              //   name="cardCVV"
              //   {...field}
              //   // value={form.values.cardCVV}
              //   // onChange={field.onChange}
              //   // onBlur={field.onBlur}
              //   formControlProps={{ fullWidth: true }}
              //   helperText={meta.touched ? meta.error : ""}
              // />
              <CustomMainInput
                {...field} // retrieves all other, onBlur, onChange, etc
                error={meta.touched ? meta.error : ""}
                height="40px"
                label="CVV"
                name="cardCVV"
                variant="input"
                tooltipText="hola"

                // onBlur={fprops.handleBlur("invoiceName")}
                // onChange={fprops.handleChange("invoiceName")}
                // value={fprops.values.invoiceName}
              />
            )}
          </Field>
        </GridItem>
        <GridItem item xs={12} sm={6}>
          <h5 className={classes.cardStorageLabel}>
            {`For security reasons, we don't store card information`}
          </h5>
        </GridItem>
      </GridContainer>

      {/* <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox color="secondary" name="saveCard" value="yes" />}
          label="Remember credit card details for next time"
        />
      </Grid> */}
    </React.Fragment>
  );
};

PaymentCard.label = "Card info";
// PaymentForm.initialValues = {
//   cardName: "",
//   cardNumber: "",
//   expMonth: "",
//   expYear: "",
//   cvv: "",
// };
PaymentCard.validationSchema = object().shape({
  cardName: string()
    .required("Required")
    .max(150, "Digits limit is 150.")
    .test(
      "test-name", // this is used internally by yup
      "Invalid name", // validation message
      (value) => valid.cardholderName(value).isValid
    ), // return true false based on validation
  cardNumber: number()
    .required("Required")
    .test(
      "test-number", // this is used internally by yup
      "Invalid card number", // validation message
      (value) => valid.number(value).isValid
    ), // return true false based on validation
  cardExpiryMonth: mixed()
    .required("Select a month")
    .notOneOf([0], "Select a month"),
  cardExpiryYear: mixed()
    .required("Select a year")
    .notOneOf([0], "Select a year"),
  cardCVV: string()
    .required("Required")
    .test(
      "test-cvv", // this is used internally by yup
      "Invalid code", // validation message
      (value) => valid.cvv(value).isValid
    ), // return true false based on validation
});
export default PaymentCard;
