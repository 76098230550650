import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { tourData } from "utils/tourData";
import { SessionContext } from "./SessionContext";

export const TodoContext = createContext();

export const TodoInfo = (props) => {
  const { session, setSession } = useContext(SessionContext);
  const { firstUse } = session;
  // const initialTodoDataRef = useRef(tourData("todo"));
  const kanbanRef = useRef();
  // console.log(
  //   "%ctodoInfo",
  //   "background-color: yellow; color: red",
  //   tourData("todo")
  // );
  const initialDataRef = useRef(firstUse ? tourData("todo") : []);

  const initialState = {
    originalData: [], // used to restore currentData to this data when filter is removed
    currentData: initialDataRef === null ? [] : initialDataRef.current, // data used for all, could be filtered or not
    updateInfo: new Date(), // used to reforece rerender of todo
    updateArchived: new Date(), // used to reforece rerender ot todo archived
  };

  const [todo, setTodoNotMemo] = useState(initialState);
  const [modal, setModal] = useState({
    visible: false,
    name: "",
    data: {},
  });
  const [openArchived, setOpenArchived] = useState(false);

  const setTodo = useCallback((newValue) => {
    setTodoNotMemo(newValue);
  }, []);

  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    messageType: "",
    message: "",
  });

  const memoizedValues = useMemo(
    () => ({
      todo,
      setTodo,
      showSnackbar,
      setShowSnackbar,
    }),
    [todo, setTodo, showSnackbar, setShowSnackbar]
  );

  return (
    <TodoContext.Provider
      value={{
        ...memoizedValues,
        kanbanRef,
        modal,
        setModal,
        openArchived,
        setOpenArchived,
        initialDataRef,
      }}
    >
      {props.children}
    </TodoContext.Provider>
  );
};
export default TodoInfo;
