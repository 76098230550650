import classNames from "classnames";
import format from "date-fns/format";
import PropTypes from "prop-types";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// data
import axios from "axios";
import config from "config";
import { SessionContext } from "contexts/SessionContext";
import parseISO from "date-fns/parseISO";
import timeList from "../../utils/timeList.json";
// custom components
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import Button from "components/CustomButtons/Button.js";
import { CustomCheckbox } from "components/CustomCheckbox/CustomCheckbox";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomDialog from "../../components/CustomDialog/CustomDialog";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// import Chip from "@material-ui/core/Chip";
// import MenuList from "@material-ui/core/MenuList";
// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
// import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  Chip,
  Divider,
  Menu,
  MenuItem,
  MenuList,
} from "@material-ui/core";

import AvatarGroup from "components/AvatarGroup/AvatarGroup";
import DatePicker from "react-datepicker";

// errors
import { logError, useDataForLog } from "functions/ErrorBoundary";

// icons
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditIcon from "@material-ui/icons/Edit";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Search from "@material-ui/icons/Search";
import ccv from "../../assets/img/temp/cvv.jpg";

// style
import {
  dangerColor,
  grayColor,
  primaryColor,
  successColor,
} from "assets/jss/material-dashboard-react";
import styles from "assets/jss/material-dashboard-react/components/customMainInputStyle.js";
import "react-datepicker/dist/react-datepicker.css";
import {
  addDaysToDate,
  axiosOptions,
  convertToTimezone,
} from "../../functions/Common";

const useStyles = makeStyles(styles);

const CustomMainInput = forwardRef((props, refID) => {
  // console.log(
  //   "%cstarts the customMainInput",
  //   "background-color: red; color: white"
  // );

  const generalData = useDataForLog(); // context data to populate error log
  try {
    const { session, setSession } = useContext(SessionContext);
    const [modal, setModal] = useState({
      visible: false,
      name: "",
      data: {},
    });
    const classes = useStyles();

    const {
      alignRight,

      avatar,
      autoFocus,
      calendarLockDate,
      canEdit = false,
      chipColor,
      clearable,
      color = "gray",
      content,
      disabled,
      dropDownFunction,
      dropDownInputRender,
      error,
      errorInput,
      filtered = false,
      hideContextualMenu,
      height,
      hideCheckSelected,
      iconStart,
      iconEnd,
      includeSearch,
      isLoading,
      justIcon,
      // key,
      label,
      multiselect,
      name,
      // onRemovingSelection,
      onClick,
      onChange,
      onChangeInput,
      // onChangeDropDownList,
      onBlur,
      onBlurInput,
      onDeleteDate,
      // onEnter,
      onKeyDown,
      onFocus,
      onAddChip,
      onRemoveChip,
      onSelected,
      onScrollingStopped,
      placeholder,
      refID,
      removeHover = false, // used to remove hover conditionally
      rightMargin = true,
      sendInfoToParent,

      showChipIcon = false,
      tooltipText,
      variant = "input",
      value,
      width,
      valueInput,
      validateInput, // function that validates input, used in chipsVariant
      ...rest
    } = props;

    // set default value when null
    const dropDownList = props.dropDownList || [];
    // const dropDownList = props.dropDownList || [
    //   {
    //     id: 0,
    //     menuDesc: "No data",
    //   },
    // ];
    // console.log("************** dropDownList", dropDownList);
    const [dropDownListState, setDropDownListState] = useState(dropDownList);
    // console.log("************** dropDownListState", dropDownListState);

    useEffect(() => {
      setDropDownListState(dropDownList);
    }, [props.dropDownList]);

    const chiplist = props.chiplist || [];

    // const dropDownListMinusSelected = dropDownList.filter((item) => {
    //   if (item.id !== value) {
    //     return item;
    //   }
    // });
    // console.log("dropDownListMinusSelected", dropDownListMinusSelected);

    // if dropdown and multiselect, adds a selected field to dropdown list

    // const [dropDownListState, setDropDownListState] = useState(
    //   // dropDownListMinusSelected
    //   dropDownList
    // );

    // useEffect(() => {
    //   setDropDownListState(dropDownList);
    // }, [props.dropDownList]);

    const labelClasses = classNames({
      [classes.label]: true,
      [classes[error ? "red" : color]]: error ? "red" : color,
    });

    const mainContainerClasses = classNames({
      [classes.mainContainer]: true,
      [classes.mainContainerFiltered]: filtered,
      [classes.mainContainerInput]:
        variant === "input" || variant === "multiline",
      [classes.mainContainerChip]: variant === "chip",
      [classes[error ? "red" : color]]: error ? "red" : color,
      [classes.disabled]: !justIcon ? disabled : false, // avoid apply gray background when justIcon
    });

    const inputVariant = (props) => {
      // console.log("%cremoveHover", "background-color: red; color: white", {
      //   removeHover,
      //   value,
      // });
      // console.log("los props son", props);
      const customType = props === undefined ? "text" : props;
      // const customType = "date";

      const iconStartContainerClasses = classNames({
        [classes.iconStartContainer]: true,
        [classes[color]]: color,
      });

      const iconEndContainerClasses = classNames({
        [classes.iconEndContainer]: true,
        [classes[color]]: color,
      });

      const inputClasses = classNames({
        [classes.input]: true,
        [classes[error ? "red" : color]]: errorInput ? "red" : color,
        // [classes.red]: true,
        [classes.alignRight]: alignRight,
        [classes.inputHover]: removeHover,
      });

      // const handleKeyPress = (event) => {
      //   if (event.key === "Enter") {
      //     // Do something when Enter is pressed
      //     onEnter();
      //   }
      // };

      return (
        <>
          {iconStart && (
            <div className={iconStartContainerClasses}>{iconStart}</div>
          )}
          <div className={classes.inputContainer}>
            <input
              // key={key}
              autoFocus={autoFocus}
              autoComplete="off"
              className={inputClasses}
              disabled={disabled}
              // style={{
              //   height: "100%",
              //   width: "100%",
              // }}
              onKeyDown={onKeyDown}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              onFocus={onFocus}
              placeholder={placeholder}
              ref={refID}
              type={customType}
              value={value}
              {...rest}
            />
          </div>
          {iconEnd && (
            <div className={iconEndContainerClasses}>
              {error ? <CancelIcon fontSize="small" /> : iconEnd}
            </div>
          )}
        </>
      );
    };

    const inputDateVariant = (props) => {
      const iconStartContainerClasses = classNames({
        [classes.iconStartContainer]: true,
        [classes[color]]: color,
      });

      const iconEndContainerClasses = classNames({
        [classes.iconEndContainer]: true,
        [classes[color]]: color,
      });

      const inputClasses = classNames({
        [classes.input]: true,
        [classes[error ? "red" : color]]: error ? "red" : color,
        [classes.alignRight]: alignRight,
        [classes.inputHover]: removeHover,
      });
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          {iconStart && (
            <div className={iconStartContainerClasses}>{iconStart}</div>
          )}
          {/* <div className={classes.inputContainer}> */}
          <DatePicker
            popperProps={{ strategy: "fixed" }}
            popperClassName={classes.datePickerPopper}
            wrapperClassName={classes.datePickerWrapper}
            disabled={disabled}
            selected={value === null || value === "" ? "" : parseISO(value)}
            onKeyDown={(e) => {
              // avoids user to clear the field and enter anything
              e.preventDefault();
            }}
            todayButton="Today"
            dateFormat="dd/MM/yyyy"
            // onSelect={onBlur} //when day is clicked
            className={inputClasses}
            onChange={onChange}
            // onBlur={onBlur}
            name={name}
            // value={parseISO(value)}
            value={value === null || value === "" ? "" : parseISO(value)}
            ref={refID}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            onFocus={onFocus}
            autoComplete="off"
            shouldCloseOnSelect={true}
            excludeDateIntervals={
              calendarLockDate === undefined
                ? [
                    {
                      start: new Date("0001-01-01"),
                      end: addDaysToDate(new Date(), -1),
                    },
                  ]
                : [
                    {
                      start: new Date("0001-01-01"),
                      end: convertToTimezone(calendarLockDate),
                    },
                  ]
            }
          />

          {/* <input
              type={"date"}
              placeholder={placeholder}
              // style={{
              //   height: "100%",
              //   width: "100%",
              // }}
              className={inputClasses}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              value={value}
              ref={refID}
              autoFocus={autoFocus}
              onFocus={onFocus}
              autoComplete="off"
              {...rest}
            /> */}
          {/* </div> */}

          {clearable && !disabled && value !== null && value !== "" && (
            // <Button
            //   color="gray"
            //   justIcon
            //   onClick={() => {
            //     sendInfoToParent();
            //   }}
            // >
            //   <CancelIcon fontSize="small" />
            // </Button>
            <CancelIcon
              style={{
                color: primaryColor[5],
                fontSize: 17,
                marginRight: "5px",
              }}
              // fontSize={35}
              onClick={() => {
                onDeleteDate();
              }}
            />
          )}

          {iconEnd && (
            <div className={iconEndContainerClasses}>
              {error ? <CancelIcon fontSize="small" /> : iconEnd}
            </div>
          )}
        </div>
      );
    };

    // use when there's no input and requires on click event
    const noInputVariant = (type) => {
      const iconStartContainerClasses = classNames({
        [classes.iconStartContainer]: true,
        [classes[color]]: color,
      });

      const iconEndContainerClasses = classNames({
        [classes.iconEndContainer]: true,
        [classes[color]]: color,
      });

      const noInputLabelClasses = classNames({
        [classes.noInputLabel]: true,
        [classes[color]]: color,
        // [classes.disabled]: true,
      });

      return (
        <div ref={refID} className={classes.noInputContainer} onClick={onClick}>
          {iconStart && (
            <div className={iconStartContainerClasses}>{iconStart}</div>
          )}

          <h6 className={noInputLabelClasses}>{value || placeholder}</h6>

          {iconEnd && (
            <div className={iconEndContainerClasses}>
              {error ? <CancelIcon fontSize="small" /> : iconEnd}
            </div>
          )}
        </div>
      );
    };

    // const inputTimeVariantOld = () => {
    //   const iconStartContainerClasses = classNames({
    //     [classes.iconStartContainer]: true,
    //     [classes[color]]: color,
    //   });

    //   const iconEndContainerClasses = classNames({
    //     [classes.iconEndContainer]: true,
    //     [classes[color]]: color,
    //   });

    //   const inputClasses = classNames({
    //     [classes.input]: true,
    //     [classes[error ? "red" : color]]: error ? "red" : color,
    //   });
    //   return (
    //     <>
    //       {iconStart && (
    //         <div className={iconStartContainerClasses}>{iconStart}</div>
    //       )}
    //       <div className={classes.inputContainer}>
    //         <input
    //           type="time"
    //           placeholder={placeholder}
    //           // style={{
    //           //   height: "100%",
    //           //   width: "100%",
    //           // }}
    //           step="300"
    //           disabled={disabled}
    //           className={inputClasses}
    //           onChange={onChange}
    //           onBlur={onBlur}
    //           name={name}
    //           value={value || ""}
    //           ref={refID}
    //           onFocus={onFocus}
    //           {...rest}
    //         />
    //       </div>
    //       {error && iconEnd && (
    //         <div className={iconEndContainerClasses}>
    //           {error ? <CancelIcon fontSize="small" /> : iconEnd}
    //         </div>
    //       )}
    //     </>
    //   );
    // };

    const multilineVariant = () => {
      const iconStartContainerClasses = classNames({
        [classes.iconStartContainer]: true,
        [classes[color]]: color,
      });

      const iconEndContainerClasses = classNames({
        [classes.iconEndContainer]: true,
        [classes[color]]: color,
      });

      const inputClasses = classNames({
        [classes.input]: true,
        [classes[error ? "red" : color]]: error ? "red" : color,
        [classes.inputHover]: removeHover,
      });
      return (
        <>
          {iconStart && (
            <div className={iconStartContainerClasses}>{iconStart}</div>
          )}
          <div className={classes.inputContainer}>
            <textarea
              ref={refID}
              placeholder={placeholder}
              style={{
                height: "100%",
                width: "100%",
              }}
              disabled={disabled}
              className={inputClasses}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              value={value}
            />
          </div>
          {/* {(error || iconEnd) && (
            <div className={iconEndContainerClasses}>
              {error ? <CancelIcon fontSize="small" /> : iconEnd}
            </div>
          )} */}
          {iconEnd && <div className={iconEndContainerClasses}>{iconEnd}</div>}
        </>
      );
    };

    const chipsVariant = () => {
      // console.log(
      //   "-------------------------------------- starts the chip variant input -------------------------------------"
      // );
      // console.log("el chiplist es", chipList);
      const chips = () => {
        const chipClasses = classNames({
          [classes.chip]: true,
          [classes[disabled ? "grayDark" : chipColor]]: disabled
            ? "grayDark"
            : chipColor,
        });

        return (
          <>
            {chiplist.map((item, index) => {
              // console.log(
              //   "%cChip",
              //   "background-color: yellow; color: red",
              //   item
              // );

              const iconClasses = classNames({
                [classes.iconCheckContainer]: true,
                // [classes.iconCheckContainerTrue]: item.icon1 === 1,
                // [classes.iconCheckContainerFalse]: item.icon1 === 2,
                // [classes.iconCheckContainerNull]: item.icon1 === 3,
                [classes.iconCheckDoubleContainer]:
                  (item.icon2 || null) !== null,
              });

              return (
                <Chip
                  key={index}
                  name={item.name}
                  icon={
                    // if the icon1 is null or undefined dont' show the icon (e.g. when just adding an email)
                    chiplist.length > 0 &&
                    showChipIcon &&
                    (item.icon1 || null) !== null ? (
                      <div className={iconClasses}>
                        {{
                          1: (
                            <CustomTooltip
                              tooltipText={item.iconTooltip1} //"Confirmed"
                              placement="bottom-start"
                            >
                              <CheckIcon
                                style={{
                                  fontSize: 20,
                                  color: successColor[1],
                                  padding: "2px 0px",
                                }}
                              />
                            </CustomTooltip>
                          ),
                          2: (
                            <CustomTooltip
                              tooltipText={item.iconTooltip1} //"Declined"
                              placement="bottom-start"
                            >
                              <Clear
                                style={{
                                  fontSize: 20,
                                  color: dangerColor[1],
                                  padding: "2px 0px",
                                }}
                              />
                            </CustomTooltip>
                          ),
                          3: (
                            <CustomTooltip
                              tooltipText={item.iconTooltip1} //"No response"
                              placement="bottom-start"
                            >
                              <h6
                                style={{
                                  fontSize: 12,
                                  color: grayColor[3],
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0px",
                                }}
                              >
                                ?
                              </h6>
                            </CustomTooltip>
                          ),
                        }[item?.icon1] || null}
                        {{
                          1: (
                            <CustomTooltip
                              tooltipText={item.iconTooltip2} // Attended
                              placement="bottom-start"
                            >
                              <CheckIcon
                                style={{
                                  fontSize: 20,
                                  color: successColor[1],
                                  padding: "2px 0px",
                                }}
                              />
                            </CustomTooltip>
                          ),
                          2: (
                            <CustomTooltip
                              tooltipText={item.iconTooltip2} //"Not attended"
                              placement="bottom-start"
                            >
                              <Clear
                                style={{
                                  fontSize: 20,
                                  color: dangerColor[1],
                                  padding: "2px 0px",
                                }}
                              />
                            </CustomTooltip>
                          ),
                          3: (
                            <CustomTooltip
                              tooltipText={item.iconTooltip2} //"No response"
                              placement="bottom-start"
                            >
                              <h6
                                style={{
                                  fontSize: 12,
                                  color: grayColor[3],
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0px",
                                }}
                              >
                                ?
                              </h6>
                            </CustomTooltip>
                          ),
                        }[item?.icon2] || null}
                      </div>
                    ) : null
                  }
                  // disabled
                  label={
                    <CustomTooltip
                      tooltipText={
                        item.name !== item.email && item.email.length > 0
                          ? item.email
                          : ""
                      } // only show email in tooltip if not already showing and if email is not null
                      placement="bottom-start"
                    >
                      <h6>{item.name || item.email}</h6>
                    </CustomTooltip>
                  }
                  onDelete={disabled ? null : () => onRemoveChip(index)}
                  variant="outlined"
                  // color={chipColor}
                  size="small"
                  className={chipClasses}
                  classes={{
                    deleteIcon: classes.chipIcon,
                  }}
                />
              );
            })}
          </>
        );
      };

      const chipInput = () => {
        // console.log("el dropDownList", dropDownList);
        const [focusedIndex, setFocusedIndex] = useState(-1);
        // const [isFocused, setIsFocused] = useState(false);
        const resultContainer = useRef < HTMLDivElement > null;
        const [showResults, setShowResults] = useState(false);
        const [defaultValue, setDefaultValue] = useState(value);

        const [localStateValue, setLocalStateValue] = useState(value);
        const [localStateError, setLocalStateError] = useState("");

        const [inputValidationError, setInputValidationError] = useState("");
        // console.log("el dropdownLIst es", {
        //   dropDownList: dropDownList,
        //   shouldShow: dropDownList.length > 0,
        //   showResults: showResults,
        //   value: value,
        //   isLoading: isLoading,
        // });

        // dropDownList.length > 0 ? setShowResults(true) : setShowResults(false);

        const handleSelection = (selectedIndex) => {
          console.log("handle Selection");
          setLocalStateValue("");
          resetSearchComplete();

          const selectedItem = dropDownList[selectedIndex];

          // console.log("handleSelection", selectedItem);

          // removes any error left on the input
          setInputValidationError("");

          onSelected &&
            onSelected({
              id: null,
              name: selectedItem.name,
              email: selectedItem.email,
            });
          console.log("resetSearchComplet");
        };

        const resetSearchComplete = useCallback(() => {
          // console.log("ejectura el resetSerachCompete");
          setFocusedIndex(-1);
          // onChangeInput(""); // se eliminó porque cuando hay un error y hace un onBlur, contenido del error se elimina pero el error no
          setShowResults(false);
        }, []);

        const handleKeyDownInput = async (e) => {
          // console.log("handleKeyDownInput with value", e);
          const { key } = e;
          let nextIndexCount = 0;

          // move down
          if (key === "ArrowDown" && dropDownList.length > 0) {
            nextIndexCount = (focusedIndex + 1) % dropDownList.length;
            setFocusedIndex(nextIndexCount);
          }

          // move up
          if (key === "ArrowUp" && dropDownList.length > 0) {
            nextIndexCount =
              (focusedIndex + dropDownList.length - 1) % dropDownList.length;
            setFocusedIndex(nextIndexCount);
          }

          // hide search results
          if (key === "Escape") {
            console.log("pressed Escape", { focusedIndex, value });
            setDefaultValue(value);
            // sendInfoToParent(null);

            setInputValidationError(""); // removes any input error
            setLocalStateValue("");
            resetSearchComplete();
          }

          // select the current item
          if (["Enter", "Tab", ","].includes(e.key)) {
            // console.log("pressed enter", { focusedIndex, value });
            e.preventDefault();

            console.log("localValueState", localStateValue);

            if (focusedIndex === -1 && value.length > 0) {
              // if there's nothing selected in the list AND the input has something
              // if the input is empty, it means the info should be deleted in handlSelection
              // if ((value && error === "Must have at least one") || !error) {
              //   onAddChip({ id: null, name: value, email: value });
              // }
              // sendInfoToParent(localStateValue);

              const validationResult = await validateInput(e.target.value);
              if (validationResult === true) {
                console.log(
                  "%cSe valido correctamente",
                  "background-color: red; color: white"
                );
                setInputValidationError(""); // removes any previous input error
                onAddChip(localStateValue); // add the the chip
                setLocalStateValue(""); // removes the data in the input
              } else {
                console.log(
                  "%cFalló la validación",
                  "background-color: red; color: white",
                  validationResult
                );
                setInputValidationError(validationResult);
              }
            } else {
              console.log(
                "%chandleSelection",
                "background-color: purple; color: white"
              );

              handleSelection(focusedIndex);
            }
            // console.log("oculta resultados despues de enter");
            setShowResults(false);
          }
        };

        const handleChange = (value) => {
          // if there was a previous error, removes if the field is empty
          value.length === 0 && setInputValidationError("");
          value.length > 0 && setShowResults(true);

          onChangeInput && onChangeInput(value);
        };

        useEffect(() => {
          if (!resultContainer.current) return;

          resultContainer.current.scrollIntoView({
            block: "center",
          });
        }, [focusedIndex]);

        useEffect(() => {
          if (value && value.length <= 0) {
            // console.log("oculta resultados despues useEffect");
            setShowResults(false);
          }
        }, [value]);

        useEffect(() => {
          // dropDownList.length > 0 ? setShowResults(true) : setShowResults(false);

          if (isLoading === false) {
            dropDownList.length > 0
              ? setShowResults(true)
              : setShowResults(false);
          }
        }, [dropDownList]);

        const handleFocus = () => {
          value?.length > 0 && !showResults && setShowResults(true);
        };

        const handleBlur = async (e) => {
          // console.log("ejectuva on blur", e.target.value);
          // onBlur(e);

          const validationResult = await validateInput(e.target.value);
          if (validationResult === true) {
            // console.log(
            //   "%cSe valido correctamente",
            //   "background-color: red; color: white"
            // );
            setInputValidationError("");
          } else {
            // console.log(
            //   "%cFalló la validación",
            //   "background-color: red; color: white",
            //   validationResult
            // );
            setInputValidationError(validationResult);
          }

          // setLocalStateValue("");
          // e.target.value === "" && setLocalStateError("");
          resetSearchComplete();

          // validates the attendee input field on blue
          // const isValid = await validateInput();
          // isValid === true
          //   ? setLocalStateError("")
          //   : setLocalStateError(isValid.message);
        };

        const iconStartContainerClasses = classNames({
          [classes.iconStartContainer]: true,
          [classes.iconOnlyContainer]: value === undefined,
          [classes[color]]: color,
        });

        const iconEndContainerClasses = classNames({
          [classes.iconEndContainer]: true,
          [classes[color]]: color,
        });

        const inputClasses = classNames({
          [classes.input]: true,
          [classes[errorInput ? "red" : color]]: errorInput ? "red" : color,
        });

        return (
          <div
            tabIndex={1}
            onKeyDown={handleKeyDownInput}
            onBlur={(e) => {
              // triggers the onBlur depending on whether clicked outside of this div or not
              if (!e.currentTarget.contains(e.relatedTarget)) {
                // clicked outside
                // console.log("executes blur");
                handleBlur(e);
              } else {
                // console.log("NOT executes blur");
              }
            }}
            onFocus={handleFocus}
          >
            <div className={classes.dropDownContainer}>
              {iconStart && (
                <div className={iconStartContainerClasses}>{iconStart}</div>
              )}
              <div className={classes.inputContainer}>
                <input
                  type="text"
                  placeholder={placeholder}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  disabled={disabled}
                  className={inputClasses}
                  // onChange={onChangeInput}
                  onChange={(e) => {
                    // onChange(e);
                    handleChange(e.target.value);
                    setLocalStateValue(e.target.value);
                    // if there is something in the input box, automatically
                    // selects first item in preparation for a possible Enter key down
                    // if (defaultValue !== "") {
                    //   setFocusedIndex(0);
                    // }
                  }}
                  // onBlur={(e) => {
                  //   onBlurInput(e);
                  //   // resetSearchComplete();
                  //   // showList(e);
                  // }}
                  name={name}
                  value={localStateValue}
                  ref={refID}
                  autoComplete="off"
                  autoFocus={autoFocus}
                  // onFocus={() => {
                  //   // console.log("ejectuva on focus");
                  //   onFocus(value);
                  //   // if there is something in the input box, automatically
                  //   // selects first item in preparation for a possible Enter key down
                  //   // if (value !== "") {
                  //   //   setFocusedIndex(0);
                  //   // }
                  // }}
                  // {...rest}
                />
                {/* {JSON.stringify(
                  focusedIndex,
  
                  null,
                  2
                )} */}
              </div>
              {iconEnd && (
                <div className={iconEndContainerClasses}>
                  {error ? <CancelIcon fontSize="small" /> : iconEnd}
                </div>
              )}
            </div>

            <div className={classes.errorContainer}>
              <p className={classes.errorLabel}>{inputValidationError}</p>
            </div>

            {/* Search Results Container */}
            {showResults && dropDownInputRender !== undefined && (
              <div
                className={classes.dropDownListCustom}
                // onKeyDown={handleKeyDownList}
                // onClick={(e) => {
                //   // e.stopPropagation();
                //   console.log("-------------------------------onClick custom");
                // }}
              >
                {dropDownList.map((item, index) => (
                  <div
                    key={index}
                    // onClick={(e) => {
                    //   // e.stopPropagation();
                    //   console.log("-------------------------------onClick", e);
                    //   handleSelection(index);
                    // }}
                    onMouseDown={(e) => {
                      handleSelection(index);
                    }}
                    // ref={index === focusedIndex ? resultContainer : null}
                    style={{
                      zIndex: -1,

                      backgroundColor:
                        index === focusedIndex ? "rgba(0,0,0,0.1)" : "",
                    }}
                    className={classes.dropDownItemCustom}
                  >
                    {dropDownInputRender(item)}
                  </div>
                ))}

                {isLoading === true && (
                  <div className={classes.activityListContainer}>
                    <ActivityIndicator />
                  </div>
                )}
              </div>
            )}
          </div>
        );
      };

      return (
        <div style={{ width: "100%" }}>
          {chips()}
          {!disabled && chipInput()}
        </div>
      );
    };

    // const searchComponent = (
    //   searchTerm,
    //   setSearchTerm,
    //   dropDownListStateLocal = dropDownListState,
    //   setDropDownListStateLocal = setDropDownListState
    // ) => {
    //   console.log("search component", props);
    //   return (
    //     <CustomMainInput
    //       // autoFocus
    //       placeholder="Search"
    //       iconStart={<Search />}
    //       onBlur={(info) => console.log("blur")}
    //       onChange={(info) => {
    //         console.log("search on change", info.target.value);
    //         let finalList = [];
    //         if (info.target.value.length > 0) {
    //           console.log(
    //             "%chas content",
    //             "background-color: red; color: white"
    //           );

    //           finalList = dropDownListStateLocal.filter((item) =>
    //             (item.menuDesc || "")
    //               .toLowerCase()
    //               .includes((info.target.value || "").toLowerCase())
    //           );
    //         } else {
    //           console.log(
    //             "%cempty content",
    //             "background-color: blue; color: white"
    //           );
    //           finalList = dropDownList;
    //         }

    //         console.log("finalList", finalList);

    //         setSearchTerm(info.target.value);
    //         setDropDownListStateLocal(finalList);
    //       }}
    //       // onBlur={fprops.handleBlur("date")}
    //       value={searchTerm}
    //       variant="input"
    //     />
    //   );
    // };

    const dropdownVariant = () => {
      // label === "Repeat" &&
      //   console.log("el value on loading es", { label, name, value });
      const [searchTerm, setSearchTerm] = useState("");

      const [editMode, setEditMode] = useState(false);
      const [selected, setSelected] = useState({
        id: value === null ? null : value,
        desc:
          value === null
            ? ""
            : dropDownList.filter((item) => item.id === value)[0]?.menuDesc,
      });

      useEffect(() => {
        setSelected({
          id: value === null ? null : value,
          desc:
            value === null
              ? ""
              : dropDownList.filter((item) => item.id === value)[0]?.menuDesc,
        });
      }, [
        value,
        // dropDownList
      ]);

      const [anchorEl, setAnchorEl] = useState(null);

      const showList = (event) => {
        // if disabled, don't display the list
        !disabled && setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        // console.log("se hace el close");
        setAnchorEl(null);
        setSearchTerm("");
        setDropDownListState(dropDownList);
        if (editMode) {
          setEditMode(false);
        }
      };

      const itemClick = (item, index) => {
        // console.log("item", item);
        // return null;

        const { id, buttonDesc, menuDesc, click } = item;
        // console.log("item es", { itemClicked: item.id, currentItem: value });

        if (multiselect) {
          const newArray = [...dropDownListState];

          const newDropDownListState = newArray.map((item) => {
            if (item.id === id) {
              return { ...item, selected: !item.selected };
            } else {
              return item;
            }
          });
          setDropDownListState(newDropDownListState);
        } else {
          if (!editMode) {
            // updates the dropdown values
            dropDownFunction !== undefined && dropDownFunction(item, index);
            // executes the function specific for the selected item
            if (click !== undefined) {
              click();
            }

            handleClose();
            setSelected({ id: id, desc: buttonDesc || menuDesc }); // updates the selection
            // if clicking again in the currently seleted item, removes the selection
            // if (item.id === value) {
            //   setSelected({ id: null, desc: "" });
            // } else {
            //   setSelected({ id: id, desc: buttonDesc || menuDesc }); // updates the selection
            // }
          }
        }
      };

      const iconStartContainerClasses = classNames({
        [classes.iconStartContainer]: true,
        [classes.iconOnlyContainer]: value === undefined,
        [classes[color]]: color,
      });

      const iconEndContainerClasses = classNames({
        [classes.iconEndContainer]: true,
        [classes[color]]: color,
      });

      const dropDownClasses = classNames({
        [classes.dropDownLabel]: true,
        [classes.dropDownPlaceholder]: value?.length === 0 && !selected.desc, //in some cases the value is an array e.g. in the filters
        [classes.alignRight]: alignRight,
        [classes[color]]: color,
      });

      const ListInEditMode = () => {
        const inputNewRef = useRef(null);
        const inputMenuItemRefs = useRef([]); // useRef(null);
        const [addGroupFocused, setAddGroupFocused] = useState(false);
        const [menuItemHover, setMenuItemHover] = useState(false);
        const [menuItemFocus, setMenuItemFocus] = useState(null);
        const [newGroupLabel, setNewGroupLabel] = useState("");
        const [inputTempLabel, setInputTempLabel] = useState("");

        const addNew = () => {
          const newArray = [...dropDownListState];

          const newArray2 = newArray.map((item) => ({
            id: item.id,
            desc: item.menuDesc,
          }));
          console.log("new array1", newArray2);
          newArray2.splice(newArray2.length, 0, {
            id: null, // newArray2.length + 100,
            desc: newGroupLabel,
          });

          console.log("new array2", newArray2);
          // setDropDownListState(newArray);

          const options = axiosOptions({
            url: `${config.url}/api/meeting-groups-updates`,
            method: "post",
            data: {
              userID: session.userID,
              groupsData: newArray2,
            },
          });
          axios(options)
            .then(async (response) => {
              // if (200 === response.status) {
              // }
              console.log("axios response", response.data.info);
              const newArray = response.data.info.map((item) => ({
                id: item.id,
                menuDesc: item.desc,
              }));
              // setDropDownListState(newArray);
              const newGroup = response.data.info.filter(
                (item) => item.new_group === true
              );
              console.log("el new group es", newGroup);

              console.log("dropDown", {
                id: newGroup[0].id,
                menuDesc: newGroup[0].desc,
                action: "add",
                newList: newArray,
              });
              dropDownFunction({
                id: newGroup[0].id,
                menuDesc: newGroup[0].desc,
                action: "add",
                newList: newArray,
              });
              setNewGroupLabel("");
              //  setShowSnackbar({
              //     show: true,
              //     messageType: "success",
              //     message: ,
              //  });
              // onChangeDropDownList();
            })
            .catch((error) => {
              logError(error, {
                // generalData: { ...generalData },
                otherData: { axiosOptions: options },
              });
            });
        };

        const handleEdit = (prop) => {
          console.log("la info es", {
            dropDownListState,
            inputTempLabel,
            itemID: prop.id,
          });

          const newArray = dropDownListState.map((item) => ({
            id: item.id,
            desc: item.id === prop.id ? inputTempLabel : item.menuDesc,
          }));

          console.log("newArray", newArray);

          // save info in db
          const options = axiosOptions({
            url: `${config.url}/api/meeting-groups-updates`,
            method: "post",
            data: {
              userID: session.userID,
              groupsData: newArray,
            },
          });
          axios(options)
            .then(async (response) => {
              // if (200 === response.status) {
              // }
              console.log("axios response", response.data.info);
              const newArray = response.data.info.map((item) => ({
                id: item.id,
                menuDesc: item.desc,
              }));

              // send info to parent
              dropDownFunction({
                id: prop.id,
                menuDesc: prop.menuDesc,
                action: "edit",
                newList: newArray,
              });

              setDropDownListState(newArray);
              setMenuItemFocus(null);
              //  setShowSnackbar({
              //     show: true,
              //     messageType: "success",
              //     message: ,
              //  });
              // onChangeDropDownList();
            })
            .catch((error) => {
              logError(error, {
                generalData: { ...generalData },
                otherData: { axiosOptions: options },
              });
            });
        };

        return (
          <div>
            {/* -------------- new item -------------- */}
            <div className={classes.dropDownMenuItemContainerNew}>
              <GridContainer
                // spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
              >
                <GridItem xs={1}>
                  {newGroupLabel !== "" ? (
                    <Button
                      color="primary"
                      justIcon
                      onClick={(item) => {
                        console.log("clear button");

                        setNewGroupLabel("");
                        setAddGroupFocused(false);
                      }}
                    >
                      <Clear />
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      justIcon
                      onClick={(item) => {
                        inputNewRef.current.focus();
                        setAddGroupFocused(true);
                      }}
                    >
                      {/* <AddIcon /> */}
                    </Button>
                  )}
                </GridItem>
                <GridItem xs={9}>
                  <input
                    ref={inputNewRef}
                    className={classes.inputMenu}
                    placeholder={placeholder}
                    name={name}
                    onFocus={() => {
                      setAddGroupFocused(true);
                      setMenuItemFocus(null);
                    }}
                    // onBlur={() => {
                    //   console.log("blur input");

                    //   setTimeout(setAddGroupFocused(false), 1000);
                    // }}
                    onChange={(e) => {
                      setNewGroupLabel(e.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        console.log("se presionó enter");
                        addNew();
                        setEditMode(false);
                      }
                    }}
                    value={newGroupLabel}

                    // {...rest}
                  />
                </GridItem>
                <GridItem xs={1}>
                  <div style={{ width: "30px" }}>
                    {addGroupFocused && newGroupLabel !== "" && (
                      <Button
                        // disabled={!addGroupFocused || newGroupLabel.length === 0}
                        // size="md"
                        color="primary"
                        justIcon
                        onClick={addNew}
                      >
                        {/* <CheckIcon /> */}
                        <AddIcon />
                      </Button>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            </div>

            <div
              style={{
                maxHeight: "225px",
                overflowY: "auto",
                scrollbarGutter: "stable",
              }}
            >
              {dropDownListState.map((prop, i) => {
                if (prop.show || true) {
                  return (
                    <div
                      key={prop.id}
                      className={classes.dropDownMenuItemContainer}
                      onMouseOver={() => setMenuItemHover(prop.id)}
                      onMouseOut={() => setMenuItemHover(null)}
                    >
                      <GridContainer
                        // spacing={1}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                      >
                        <GridItem xs={1}>
                          {menuItemHover === prop.id && (
                            <Button
                              color="danger"
                              justIcon
                              onClick={(item) => {
                                setModal({
                                  visible: true,
                                  name: "confirm-delete-label",
                                  data: {
                                    groupID: prop.id,
                                    groupName: prop.menuDesc,
                                  },
                                });
                              }}
                            >
                              <DeleteOutlineIcon />
                            </Button>
                          )}
                        </GridItem>
                        <GridItem xs={9}>
                          <input
                            // ref={inputMenuItemRef}
                            ref={(el) => (inputMenuItemRefs.current[i] = el)}
                            className={classes.inputMenu}
                            onFocus={() => {
                              setInputTempLabel(prop.menuDesc);
                              setMenuItemFocus(prop.id);
                              setAddGroupFocused(false);
                            }}
                            onChange={(e) => {
                              setInputTempLabel(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleEdit({
                                  id: prop.id,
                                  menuDesc: inputTempLabel,
                                });
                              }
                            }}
                            value={
                              menuItemFocus === prop.id
                                ? inputTempLabel
                                : prop.menuDesc
                            }
                            name={name}
                            // {...rest}
                          />
                        </GridItem>
                        <GridItem xs={1}>
                          {menuItemFocus === prop.id ? (
                            <Button
                              // disabled={
                              //   !addGroupFocused || newGroupLabel.length === 0
                              // }

                              color="primary"
                              justIcon
                              onClick={() => handleEdit(prop)}
                            >
                              <CheckIcon />
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              justIcon
                              onClick={(item) => {
                                // console.log(
                                //   "tries to focus",
                                //   inputMenuItemRefs.current
                                // );

                                inputMenuItemRefs.current[i].focus();
                              }}
                            >
                              <EditIcon />
                            </Button>
                          )}
                        </GridItem>
                      </GridContainer>

                      {prop.dividingLineBelow && (
                        <Divider className={classes.divider} light />
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        );
      };

      const ListNotInEditMode = () => {
        const hasItemList = dropDownListState.length > 0;

        const EmptyItemList = () => {
          return (
            <h5 className={classes.dropDownEmptyListLabel}>No group defined</h5>
          );
        };

        const NonEmptyItemList = () => {
          return (
            <>
              {dropDownListState.map((item) => {
                if (item.show || true) {
                  return (
                    <div key={item.id}>
                      <MenuItem
                        className={classes.dropDownItem}
                        onClick={() =>
                          // call a function itemClick that runs the prop function AND closes the menu
                          itemClick(item)
                        }
                      >
                        {/* show checkbox when multiselect is provided */}
                        {multiselect && (
                          <CustomCheckbox value={item.selected} />
                        )}
                        {avatar && (
                          <Avatar
                            className={classes.avatar}
                            alt={item.desc}
                            src={item.imagePath}
                          />
                        )}
                        <h5 className={classes.dropDownMenuItemLabel}>
                          {item.menuDesc}
                        </h5>
                      </MenuItem>
                      {/* {item.dividingLineBelow && <Divider light />} */}
                    </div>
                  );
                }
              })}

              {canEdit && selected.id !== null && (
                <div>
                  <Divider className={classes.divider} light />
                  <MenuItem
                    className={classes.dropDownItem}
                    onClick={() => {
                      // onRemovingSelection();
                      itemClick({ id: null, menuDesc: "" });
                      // setSelected({ id: null, desc: "" });
                      // handleClose();
                    }}
                  >
                    <h5
                      className={classes.dropDownMenuItemLabel}
                      style={{ color: "red" }}
                    >
                      Remove selection
                    </h5>
                  </MenuItem>
                </div>
              )}
            </>
          );
        };

        return (
          <div
            style={{
              maxHeight: "240px",
              overflowY: "auto",
            }}
          >
            {/* for some reason, if this h6 is not present, the input loses focus when pressing the letter a */}

            {/* <MenuItem className={classes.dropDownItem}> */}

            {hasItemList ? <NonEmptyItemList /> : <EmptyItemList />}
          </div>
        );
      };

      const TextContent = () => {
        if (!justIcon) {
          if (multiselect) {
            // console.log(
            //   "%cDropDownList",
            //   "background-color: red; color: white",
            //   dropDownList
            // );

            // if avatar is defined, show the avatar icons grouped
            if (avatar !== undefined) {
              const itemsSelectedList = dropDownList.filter(
                (item) => item.selected
              );

              if (itemsSelectedList.length > 0) {
                return (
                  <div style={{ marginLeft: "7px" }}>
                    <AvatarGroup
                      persons={itemsSelectedList}
                      maxIcons={4}
                      iconSize="sm"
                    />
                  </div>
                );
              } else {
                return <h6 className={dropDownClasses}>{placeholder}</h6>;
              }
            } else {
              // builds the string of items selected
              const itemsSelectedString = dropDownList.reduce(
                (finalString, item) => {
                  if (item.selected) {
                    // return finalString + "," + item.menuDesc;
                    return `${finalString}${
                      finalString.length > 0 ? ", " : ""
                    }${item.menuDesc}`;
                  } else {
                    return finalString;
                  }
                },
                ""
              );
              // console.log(
              //   "%citemsSelectedString",
              //   "background-color: red; color: white",
              //   { itemsSelectedString, dropDownListState }
              // );

              return (
                <h6 className={dropDownClasses}>
                  {value.length > 0 ? itemsSelectedString : placeholder}
                </h6>
              );
            }
          } else {
            return (
              <h6 className={dropDownClasses}>
                {selected.id !== null ? selected.desc : placeholder}
              </h6>
            );
          }
        }
      };

      return (
        <>
          <div onClick={showList} className={classes.dropDownMainContainer}>
            {iconStart && (
              <div className={iconStartContainerClasses}>{iconStart}</div>
            )}
            <TextContent />
            {!disabled && !justIcon && (
              <div className={iconEndContainerClasses}>
                <KeyboardArrowDownIcon />
              </div>
            )}
          </div>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onFocus={onFocus}
            // onBlur={onBlur}
            onBlur={(e) => {
              onBlur !== undefined && onBlur();
            }}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            style={{
              padding: "0px",
              marginTop: "5px",
            }}
          >
            <MenuList className={classes.dropDownList}>
              {/* ----can't be a fragment, must be a div, otherwise generates MenuList error */}
              <div>
                {includeSearch && (
                  <div className={classes.searchContainer}>
                    <CustomMainInput
                      // autoFocus
                      placeholder="Search"
                      iconStart={<Search />}
                      onBlur={(info) => console.log("blur")}
                      onChange={(info) => {
                        console.log("search on change", info.target.value);
                        let finalList = [];
                        if (info.target.value.length > 0) {
                          console.log(
                            "%chas content",
                            "background-color: red; color: white"
                          );

                          finalList = dropDownList.filter((item) =>
                            (item.menuDesc || "")
                              .toLowerCase()
                              .includes((info.target.value || "").toLowerCase())
                          );
                        } else {
                          console.log(
                            "%cempty content",
                            "background-color: blue; color: white"
                          );
                          finalList = dropDownList;
                        }

                        console.log("finalList", finalList);

                        setSearchTerm(info.target.value);
                        setDropDownListState(finalList);
                      }}
                      // onBlur={fprops.handleBlur("date")}
                      value={searchTerm}
                      variant="input"
                    />
                  </div>
                )}
                {editMode ? <ListInEditMode /> : <ListNotInEditMode />}
                {multiselect && (
                  <div style={{ margin: "5px 10px" }}>
                    <Button
                      color="primary"
                      size="xs"
                      fullWidth
                      onClick={(item) => {
                        console.log("confirm button");
                        // extracts the values selected
                        let values = dropDownListState.reduce(
                          (selectedArray, item) => {
                            if (item.selected === true) {
                              selectedArray.push(item.id);
                            }
                            return selectedArray;
                          },
                          []
                        );
                        console.log("values after confirm", values);

                        dropDownFunction !== undefined &&
                          dropDownFunction(values);
                        handleClose();
                        // setSelected({ id: id, desc: buttonDesc || menuDesc }); // updates the selection
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                )}
                {canEdit && (
                  <>
                    {editMode ? (
                      <div>
                        <Divider className={classes.divider} light />
                        <MenuItem
                          className={classes.dropDownItem}
                          onClick={() =>
                            // call a function itemClick that runs the prop function AND closes the menu
                            setEditMode(false)
                          }
                        >
                          <h5
                            className={classes.dropDownMenuItemLabel}
                            style={{ color: primaryColor[4] }}
                          >
                            Exit edit groups
                          </h5>
                        </MenuItem>
                      </div>
                    ) : (
                      <div>
                        <Divider className={classes.divider} light />
                        <MenuItem
                          className={classes.dropDownItem}
                          onClick={() =>
                            // call a function itemClick that runs the prop function AND closes the menu
                            setEditMode(true)
                          }
                        >
                          <h5
                            className={classes.dropDownMenuItemLabel}
                            style={{ color: primaryColor[4] }}
                          >
                            Edit list
                          </h5>
                        </MenuItem>
                      </div>
                    )}
                  </>
                )}
              </div>
            </MenuList>
          </Menu>
        </>
      );
    };

    const inputDropdownVariant = () => {
      // console.log("%cremoveHover", "background-color: red; color: white", {
      //   removeHover,
      //   value,
      // });

      try {
        const iconStartContainerClasses = classNames({
          [classes.iconStartContainer]: true,
          [classes.iconOnlyContainer]: value === undefined,
          [classes[color]]: color,
        });

        const iconEndContainerClasses = classNames({
          [classes.iconEndContainer]: true,
          [classes[color]]: color,
        });

        const inputClasses = classNames({
          [classes.input]: true,
          [classes[error ? "red" : color]]: error ? "red" : color,
          [classes.inputHover]: removeHover,
        });
        // const [selected, setSelected] = useState({
        //   id: null,
        //   desc: "",
        // });

        const [focusedIndex, setFocusedIndex] = useState(-1);
        // const [isFocused, setIsFocused] = useState(false);
        const resultContainer = useRef < HTMLDivElement > null;
        const [showResults, setShowResults] = useState(false);
        const [defaultValue, setDefaultValue] = useState(value);
        const [menuTopPosition, setMenuTopPosition] = useState(0);
        const [isInputFocused, setIsInputFocused] = useState(false);
        const [hasSelectedItem, setHasSelectedItem] = useState(false);
        const triggerRef = useRef(null);
        const menuRef = useRef(null);
        const inputRef = useRef(null);

        // console.log(
        //   "%cstarts input dropdown variant",
        //   "background-color: red; color: white",
        //   hasSelectedItem
        // );

        // console.log(
        //   "%cshowResults",
        //   "background-color: red; color: white",
        //   showResults
        // );

        const resetSearchComplete = useCallback(() => {
          setFocusedIndex(-1);
          setShowResults(false);
        }, []);

        const handleSelection = (selectedIndex) => {
          // console.log("el default value es", defaultValue);
          const selectedItem = dropDownList[selectedIndex];
          // console.log("handleSelection", selectedItem);

          // executes the function specific for the selected item
          if (selectedItem.click !== undefined) {
            selectedItem.click();
          }

          // if (value === "") {
          //   const emptyItem = {
          //     email: "",
          //     imagePath: "",
          //     name: "",
          //   };
          //   onSelected && onSelected(emptyItem);
          // } else {
          //   onSelected && onSelected(selectedItem);
          // }
          // removes any possible error left on the input

          onSelected && onSelected(selectedItem);

          resetSearchComplete();
          setHasSelectedItem(true);
          // setSelected({ id: id, desc: desc1 || desc2 }); // updates the selection
          // handleClose();
        };

        const handleKeyDownDiv = (e) => {
          // console.log("handleKeyDownInput with value", value);
          const { key } = e;
          let nextIndexCount = 0;

          // move down
          if (key === "ArrowDown") {
            nextIndexCount = (focusedIndex + 1) % dropDownList.length;
            setFocusedIndex(nextIndexCount);
          }

          // move up
          if (key === "ArrowUp") {
            nextIndexCount =
              (focusedIndex + dropDownList.length - 1) % dropDownList.length;
            setFocusedIndex(nextIndexCount);
          }

          // hide search results
          if (key === "Escape") {
            setDefaultValue(value);
            sendInfoToParent(null); // returs the original value
            // sendInfoToParent({ name: , email });

            // resetSearchComplete();
            console.log("%cref", "background-color: red; color: white", {
              refID,
              inputRef,
            });

            // force blur to remove focused format
            refID === undefined
              ? inputRef.current.blur()
              : refID.current.blur();
          }

          // select the current item
          if (key === "Enter") {
            e.preventDefault();

            if (dropDownList.length === 0 && value.length > 0) {
              // if there are no results from the list AND the input has something
              // it means a value not in list has been typed in triggers blur for validation in parent
              console.log("No hay nada selected", value);
              onBlur && onBlur(value);
            } else {
              handleSelection(focusedIndex);
              // force blur to remove focused format
              // refID === undefined
              //   ? inputRef.current.blur()
              //   : refID.current.blur();
            }
          }
        };

        const handleKeyDownInput = (e) => {
          // when pressing ArrowDown or ArrowUp when selecting from the list,
          // avoid moving the cursor to beginning or end of the input
          // console.log("handleKeyDownInput with value", value);
          const { key } = e;

          // move down
          // if (key === "ArrowDown") {
          //   e.preventDefault();
          // }

          // move up
          if (key === "ArrowUp") {
            e.preventDefault();
          }
        };

        const handleChange = (e) => {
          // console.log("handleChange with value", e.target.value);
          // setDefaultValue(e.target.value);
          // sendInfoToParent(e.target.value);

          // if (e.target.value.length > 0 && showResults === false)
          setShowResults(true);

          onChange && onChange(e.target.value);
        };

        // useEffect(() => {
        //   if (menuTopPosition !== 0) {
        //     console.log("data", {
        //       top: triggerRef?.current?.getBoundingClientRect().top,
        //       menuTopPosition: menuTopPosition - 34,
        //     });
        //   }
        // }, [triggerRef?.current?.getBoundingClientRect()]);

        useEffect(() => {
          // console.log("run use effect on hasSelectedItem", hasSelectedItem);
          // if (hasSelectedItem === true) {
          refID === undefined ? inputRef.current.blur() : refID.current.blur();
          // }
        }, [hasSelectedItem]);

        useEffect(() => {
          if (!resultContainer.current) return;

          resultContainer.current.scrollIntoView({
            block: "center",
          });
        }, [focusedIndex]);

        // useEffect(() => {
        //   //   if (defaultValue.length <= 0) setShowResults(false);
        //   // }, [defaultValue]);

        //   if (value?.length <= 0) setShowResults(false);
        // }, [value]);

        const handleFocus = () => {
          // console.log("ejectuva on focus1", {
          //   value,
          //   showResults,
          //   dropDownList,
          //   menuTopPosition,
          // });

          const triggerRect = triggerRef.current.getBoundingClientRect();
          const windowHeight = window.innerHeight;

          const lineHeight = 45;
          const numberOfLines = dropDownList.length;
          const adjustment = 35;

          const menuHeight = lineHeight * numberOfLines + adjustment;

          let topPosition = triggerRect.top + triggerRect.height;
          if (topPosition + menuHeight > windowHeight) {
            // Display menu on top if reaching the bottom of the screen
            topPosition = triggerRect.top + window.scrollY - menuHeight;
          }
          // store the position to be used in case of scrolling

          setMenuTopPosition(topPosition);

          if (!disabled) {
            setShowResults(true);
          }
        };

        const handleBlur = (e) => {
          // this is used to detect when the user directly clicks on the contextual menu

          if (!e.currentTarget.contains(e.relatedTarget)) {
            // console.log(
            //   "%cexecutes blur",
            //   "background-color: red; color: white"
            // );
            // console.log("on blur hasSelectedItem", hasSelectedItem);
            if (hasSelectedItem === true) {
              // console.log("NOT clicked outside");
              setHasSelectedItem(false);
            } else {
              // when clicked outside, hasSelectedItem is by default false
              // console.log("Clicked outside");
              // // console.log("ejectuva on blur", value);
              if (!disabled) {
                resetSearchComplete();
                onBlur && onBlur(e.target.value);
              }
            }
          } else {
            console.log("NOT executes blur");
          }

          // setHasSelectedItem(false);
          // console.log('%c', 'background-color: red; color: white', )
          // triggers the onBlur depending on whether clicked outside of this div or not
          // if (!e.currentTarget.contains(e.relatedTarget)) {
          //   console.log(
          //     "%cexecutes blur",
          //     "background-color: red; color: white"
          //   );
          //   handleBlur(e);
          // } else {
          //   console.log("NOT executes blur");
          // }
        };

        const ContextualMenu = () => {
          // console.log(
          //   "%chideContextualMenu &&",
          //   "background-color: red; color: white",
          //   hideContextualMenu
          // );

          return (
            <>
              {dropDownList.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={(event) => {
                      console.log("onClick");

                      handleSelection(index);
                    }}
                    // ref={index === focusedIndex ? resultContainer : null}
                    style={{
                      backgroundColor:
                        index === focusedIndex ? "rgba(0,0,0,0.1)" : "",
                    }}
                    className={classes.dropDownItemCustom}
                  >
                    {/* <h4>{item.name}</h4> */}
                    {dropDownInputRender(item)}
                  </div>
                );
              })}
            </>
          );
        };

        return (
          <div
            ref={triggerRef}
            disabled={disabled}
            tabIndex={1}
            onKeyDown={handleKeyDownDiv}
            onBlur={handleBlur}
            onFocus={handleFocus}
            // className={classes.dropDownMainContainer}
            // onClick={() => {
            //   console.log("onClick parent");
            // }}
            style={{ width: "100%" }}
          >
            <div className={classes.dropDownContainer}>
              {iconStart && (
                <div className={iconStartContainerClasses}>{iconStart}</div>
              )}
              <div className={classes.inputContainer}>
                <input
                  type="text"
                  placeholder={placeholder}
                  onKeyDown={handleKeyDownInput}
                  disabled={disabled}
                  className={inputClasses}
                  onChange={(e) => {
                    // onChange(e);
                    handleChange(e);
                    // if there is something in the input box, automatically
                    // selects first item in preparation for a possible Enter key down
                    // if (defaultValue !== "") {
                    //   setFocusedIndex(0);
                    // }
                  }}
                  // onBlur={(e) => {
                  //   console.log(
                  //     "%cinput blur",
                  //     "background-color: yellow; color: red"
                  //   );
                  // }}
                  name={name}
                  value={value || ""}
                  ref={refID || inputRef}
                  autoFocus={autoFocus}
                  autoComplete="off"
                  onFocus={() => {
                    // console.log("ejectuva on focus input drop down", {
                    //   valorEs: value,
                    // });
                    onFocus(value);
                    // if there is something in the input box, automatically
                    // selects first item in preparation for a possible Enter key down
                    if (value !== "") {
                      setFocusedIndex(0);
                    }
                  }}
                  {...rest}
                />
              </div>
              {iconEnd && (
                <div className={iconEndContainerClasses}>
                  {error ? <CancelIcon fontSize="small" /> : iconEnd}
                </div>
              )}
            </div>

            {/* Search Results Container */}
            {/* {showResults &&
              dropDownInputRender !== undefined &&
              props.dropDownList.length > 0 && ( */}

            {removeHover === false &&
              showResults &&
              dropDownInputRender !== undefined &&
              props.dropDownList.length > 0 && (
                <div
                  ref={menuRef}
                  style={{ top: menuTopPosition }}
                  className={classes.dropDownListCustom}
                  // onKeyDown={handleKeyDownList}
                >
                  <ContextualMenu />
                </div>
              )}
          </div>
        );
      } catch (error) {
        logError(error, {
          generalData: { ...generalData },
          otherData: { props },
        });
      }
    };

    const inputTimeVariant = () => {
      // console.log("%cValue", "background-color: purple; color: white", value);

      const generateTimeList = (step) => {
        const dt = new Date(1970, 0, 1);
        const list = [];
        while (dt.getDate() === 1) {
          const timeToAdd = {
            value: format(dt, "hh:mm aaa"),
            // desc: format(dt, "h:mm aaa"),
            desc: format(dt, "hh:mm aaa"),
          };

          list.push(timeToAdd);
          dt.setMinutes(dt.getMinutes() + step);
        }
        // console.log("el time list es", JSON.stringify(list));
        return list;
      };

      const [localValueState, setLocalValueState] = useState(value);

      // const timeList = generateTimeList(15); // generate times with 15 minutes blocks

      // console.log("timelist", timeList);

      const [anchorEl, setAnchorEl] = useState(null);
      const [selectedIndex, setSelectedIndex] = useState(1);
      const [selectedItem, setSelectedItem] = useState({
        value: value,
        // desc: value,
      });
      // console.log("%cTime value", "background-color: yellow; color: red", {
      //   localValueState,
      //   value,
      // });

      // use to refresh the local value state because the input is rendered several times but only keeps the initial value
      useEffect(() => {
        setLocalValueState(value);
        setSelectedItem({ value: value });
      }, [value]);

      const handleSelection = (selectedIndex) => {
        const selectedTime = timeList[selectedIndex];
        // setSelectedIndex(timeList[selectedIndex]);
        // console.log(
        //   "%cSelectedTime",
        //   "background-color: red; color: white",
        //   selectedTime
        // );
        // updates the value in the local state
        // setLocalValueState(selectedTime.value);

        //update the selected item to be able to detect which item on the list is selected
        setSelectedItem(selectedTime);
        // console.log("handleSelection", selectedTime);

        setSelectedIndex(selectedIndex);

        // evaluates if fill is left null
        if (value === "") {
          // ----------------------------- pending -------------------------------------
          // const emptyItem = {
          //   email: "",
          //   imagePath: "",
          //   name: "",
          // };
          // onSelected && onSelected(emptyItem);
        } else {
          // const { email, name } = selectedItem;
          // setDefaultValue(name || email);
          // sendInfoToParent({ name, email });

          onSelected && onSelected(selectedTime);
        }
        setAnchorEl(null);
      };

      const handleKeyDownInput = async (e) => {
        const { key } = e;
        // console.log("handleKeyDownInput with value", key);
        let nextIndexCount = 0;

        // reverts to original value
        if (key === "Escape") {
          // setLocalValueState(value);
          setSelectedItem({ value: value });
        }

        if (key === "Enter") {
          onChange(selectedItem.value);
        }
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleChange = (e) => {
        console.log("handleChange with value", e.target.value);
        // setDefaultValue(e.target.value);
        // sendInfoToParent(e.target.value);

        // if (e.target.value.length > 0 && showResults === false)
        // setShowResults(true);

        onChange && onChange(e.target.value);
      };

      const inputClasses = classNames({
        [classes.input]: true,
        [classes[error ? "red" : color]]: error ? "red" : color,
      });

      return (
        <>
          {/* <List component="nav" aria-label="Device settings">
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="lock-menu"
              aria-label="when device is locked"
              onClick={handleClickListItem}
            >
              <ListItemText
                primary="When device is locked"
                secondary={timeList[selectedIndex].value}
              />
            </ListItem>
          </List> */}

          <input
            // ref={refID}
            onKeyDown={handleKeyDownInput}
            type="text"
            placeholder={placeholder}
            disabled={disabled}
            className={inputClasses}
            onChange={(e) => {
              // console.log("%cOnChange", "background-color: red; color: white");

              // console.log("valor", e.target.value);
              // handleChange(e);
              // setLocalValueState(e.target.value);
              setSelectedItem({ value: e.target.value });
            }}
            onBlur={(e) => {
              // console.log("triggers on blur");
              setLocalValueState(value);
              setSelectedItem({ value: value });
            }}
            name={name}
            // value={localValueState}
            value={selectedItem.value}
            autoFocus={autoFocus}
            autoComplete="off"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            // onFocus={() => {
            //   console.log("ejectuva on focus input drop down", {
            //     valorEs: value,
            //   });
            //   // onFocus(value);
            //   // if there is something in the input box, automatically
            //   // selects first item in preparation for a possible Enter key down
            //   if (value !== "") {
            //     setFocusedIndex(0);
            //   }
            // }}
            // {...rest}
          />

          <Menu
            // id="lock-menu"
            // anchorEl={anchorEl}
            // keepMounted
            // open={Boolean(anchorEl)}
            // onClose={handleClose}
            className={classes.menuTimes}
            id="long-menu"
            anchorEl={anchorEl}
            onClose={handleClose}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(anchorEl)}
            PaperProps={{
              style: {
                paddingTop: "15px",
                marginTop: "10px",
                maxHeight: "300px",
                width: "150px",
              },
            }}
          >
            {timeList.map((item, index) => {
              // console.log("item", {
              //   index: index,
              //   selectedIndex: selectedIndex,
              //   indexEqual: index === selectedIndex,
              //   itemValue: item.value,
              //   selectedItem: selectedItem?.value,
              //   valueEqual: item.value === selectedItem?.value,
              // });

              return (
                <MenuItem
                  key={item.value}
                  disabled={index === 0}
                  // selected={index === selectedIndex}
                  selected={item.value === selectedItem?.value}
                  onClick={() => handleSelection(index)}
                >
                  {item.desc}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      );
    };

    const description = () => {
      return (
        <div className={classes.descriptionContainer}>
          <div className={classes.labelGroup}>
            {label &&
              (tooltipText ? (
                <>
                  <p className={labelClasses}>{label}</p>
                  <CustomTooltip
                    tooltipText={<img src={ccv} alt="..." />}
                    placement="bottom-start"
                  >
                    <HelpOutlineIcon
                      style={{ fontSize: 10, color: grayColor[4] }}
                    />
                  </CustomTooltip>
                </>
              ) : (
                <p className={labelClasses}>{label}</p>
              ))}
          </div>

          {/* {variant === "chip" && (
            <div className={classes.iconAddChip}>
              <AddCircleOutlineIcon
                fontSize="medium"
                onClick={() => alert("add to chip list")}
              />
            </div>
          )} */}
        </div>
      );
    };

    const ModalToShow = () => {
      const [isLoading, setIsLoading] = useState(false);

      const removeGroup = () => {
        console.log(
          "%cGroup to deltee",
          "background-color: red; color: white",
          modal.data
        );

        const newArray = [...dropDownListState];
        const index = newArray.findIndex(
          (item) => item.id === modal.data.groupID
        );
        newArray.splice(index, 1);

        const newArray2 = newArray.map((item) => ({
          id: item.id,
          desc: item.menuDesc,
        }));

        console.log("new array2", newArray2);
        // setDropDownListState(newArray);

        const options = axiosOptions({
          url: `${config.url}/api/meeting-groups-updates`,
          method: "post",
          data: {
            userID: session.userID,
            groupsData: newArray2,
          },
        });
        axios(options)
          .then(async (response) => {
            // if (200 === response.status) {
            // }
            console.log("axios response", response.data.info);
            const newArray = response.data.info.map((item) => ({
              id: item.id,
              menuDesc: item.desc,
            }));
            // updates the list
            // setDropDownListState(newArray);
            // onChangeDropDownList();

            // send info to parent to remove the item if it was selected
            dropDownFunction({
              id: modal.data.groupID,
              menuDesc: modal.data.groupName,
              action: "removed",
              newList: newArray,
            });
            setModal({ visible: false, name: "" });
          })
          .catch((error) => {
            logError(error, {
              generalData: { ...generalData },
              otherData: { axiosOptions: options },
            });
          });
      };

      switch (modal.name) {
        case "confirm-delete-label":
          return (
            <CustomDialog
              center
              iconPreset="question"
              visibleStatus={modal}
              setVisibleStatus={setModal}
              title={`Do you want to remove the group ${modal.data.groupName}?`}
              mainText="We’ll remove it from all of your meetings. Your meetings won’t be deleted."
              buttonActionLabel1="Return"
              buttonActionFunction1={() => {
                setModal({ visible: false, name: "" });
              }}
              buttonActionDisabled1={isLoading}
              buttonActionLabel2="Remove group"
              buttonActionColor2="danger"
              buttonActionFunction2={removeGroup}
              // buttonActionLoading2={isLoading}
              // center
            />
          );

        default:
          return null;
      }
    };

    return (
      <div
        className={`${classes.topContainer} ${
          rightMargin && classes.rightMargin
        }`}
      >
        {label && description()}
        <div
          tabIndex={0}
          style={{
            // height: "30px",
            maxWidth: width === undefined ? "100%" : width,
            width: width === undefined ? "100%" : width,
            // height: label === undefined ? "100%" : "calc(100% - 18px)", // substracts 18px which is the height of the label
            height:
              height !== undefined
                ? height
                : label === undefined
                ? "100%"
                : "calc(100% - 18px)", // substracts 18px which is the height of the label
            // pointerEvents: disabled ? "none" : "auto",
          }}
          className={mainContainerClasses}

          // deleteIcon={}
        >
          {/* inputVariant() */}
          {variant === "input" && inputVariant()}
          {/* {variant === "inputDate" && inputVariant("date")} */}
          {variant === "inputDate" && inputDateVariant()}
          {variant === "inputTime" && inputTimeVariant()}
          {/* {variant === "inputTime2" && inputTimeVariant2()}
          {variant === "inputTime3" && inputTimeVariant3()} */}
          {variant === "multiline" && multilineVariant()}
          {variant === "chip" && chipsVariant()}
          {variant === "dropdown" && dropdownVariant()}

          {/* {variant === "dropdownEditable" && dropdownEditableVariant()} */}
          {variant === "inputDropdown" && inputDropdownVariant()}
          {/* {variant === "inputDropdown" && <InputDropdownVariant />} */}

          {variant === "noInput" && noInputVariant()}
          {variant === "clean" && content}
        </div>
        <div className={classes.errorContainer}>
          <p className={classes.errorLabel}>{error}</p>
        </div>
        <ModalToShow />
      </div>
    );
  } catch (error) {
    logError(error, { generalData: { ...generalData }, otherData: { props } });
  }
});

export default CustomMainInput;
CustomMainInput.propTypes = {
  height: PropTypes.string,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.string,
  success: PropTypes.bool,
  helperText: PropTypes.string,
  type: PropTypes.string,
  tooltipText: PropTypes.string,
};
